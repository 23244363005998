import { Chapter } from 'components/Chapter/Chapter';
import { ReactElement } from 'react';
import { Tutorials } from './Tutorials/Tutorials';
import { RouteComponentProps } from 'react-router-dom';
import { DocFrame } from '../Docs/DocFrame/DocFrame';

export type TutorialsDocProps = RouteComponentProps;

export const TutorialsDoc = (props: TutorialsDocProps): ReactElement => {

    return (
        <DocFrame title="Tutorials" {...props}>
            <Chapter spacing={24}>
                <Chapter.Paragraph id="general">
                    <Tutorials
                        component="General"
                        items={[{
                            title: "Component Overview",
                            description: "This tutorial gives an overview of the main components of " +
                            "the platform and how they fit together. Datasets, models, workflows, and " +
                            "dashboards are covered.",
                            url: "https://www.youtube.com/embed/OVYlyhyT8BU",
                            duration: "46:36",
                            date: "2023-06-28"
                        },{
                            title: "Useful Functions",
                            description: "This tutorial talks about several functions " +
                            "and how they can be used within your models to perform certain " +
                            "operations in order to create datasets. Open-ended ranges will " +
                            "also be discussed and how they can be used when working with dynamic datasets.",
                            url: "https://xxx",
                            duration: "00:00"
                        }]}
                    />
                </Chapter.Paragraph>
                <Chapter.Paragraph id="model">
                    <Tutorials
                        component="Models"
                        items={[{
                            title: "Creating A Model",
                            description: "This tutorial goes through the steps to create a simple model. " +
                            "The main components of a model are covered, including formulas, variables, parameters, queries, " +
                            "and pivots.",
                            url: "https://xxx",
                            duration: "00:00"
                        }, {
                            title: "Creating An Applet",
                            description: "This tutorial explores building an applet, using various widgets and input controls. " +
                            "The use of variables to create interactive visualizations is illustrated.",
                            url: "https://xxx",
                            duration: "00:00"
                        }]}
                    />
                </Chapter.Paragraph>
                <Chapter.Paragraph id="dataset">
                    <Tutorials
                        component="Datasets"
                        items={[{
                            title: "Using A REST API To Create A Dataset",
                            description: "This tutorial goes through the steps to create a connection to query " +
                            "a 3rd-party REST API. Topics include the use of variables, configuring processors, creating " +
                            "a schema, and testing.",
                            url: "https://xxx",
                            duration: "00:00"
                        }]}
                    />
                </Chapter.Paragraph>
                <Chapter.Paragraph id="workflow">
                    <Tutorials
                        component="Workflows"
                        items={[{
                            title: "Creating A Simple Workflow",
                            description: "This tutorial will go through the steps to create a simple workflow. This includes " +
                            "setting up a trigger, configuring processors, and writing the data to an internal data store.",
                            url: "https://xxx",
                            duration: "00:00"
                        }]}
                    />
                </Chapter.Paragraph>
                <Chapter.Paragraph id="dashboard">
                    <Tutorials
                        component="Dashboards"
                        items={[{
                            title: "Working With Dashboards",
                            description: "This tutorial will illustrate how applets are added to a dashboard and how " +
                            "to lay them out.",
                            url: "https://xxx",
                            duration: "00:00"
                        }]}
                    />
                </Chapter.Paragraph>
            </Chapter>
        </DocFrame>
    );

}
