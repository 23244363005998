import { ReactElement, Ref, useRef, useState } from 'react';
import { Form, FormInstance } from 'antd';
import { Address, AddressContact } from '@methodset/entity-client-ts';
import { ListEditor } from '../ListEditor/ListEditor';
import { AddressEditor } from '../AddressEditor/AddressEditor';
import './RealEstateEditor.less';

export type ChangeCallback = (addresses: AddressContact[]) => void;

export type RealEstateEditorProps = {
    formRef: React.RefObject<FormInstance>,
    withForm?: boolean,
    id: string,
    addresses?: AddressContact[],
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    addresses: [] as AddressContact[]
}

export const RealEstateEditor = (props: RealEstateEditorProps): ReactElement => {

    const [editAddress, setEditAddress] = useState<AddressContact | undefined>();

    const handleAddressChange = (address: AddressContact): void => {
        setEditAddress(address);
    }

    const handleAddressEdit = (address: AddressContact): void => {
        setEditAddress(address);
    }

    const handleAddressesSave = (addresses: AddressContact[]): void => {
        setEditAddress(undefined);
        props.onChange(addresses);
    }

    const renderItem = (address: Address): ReactElement => {
        return (
            <div className="x-realestateeditor-item">
                <div>{address.street1}</div>
                <div>{address.city}, {address.state} {address.zipCode}</div>
            </div>
        )
    }

    const buildView = (): ReactElement => {
        return (
            <ListEditor
                formRef={props.formRef}
                id="real-estate"
                name="Address"
                items={props.addresses}
                editItem={editAddress}
                render={renderItem}
                onEdit={(address) => handleAddressEdit(address)}
                onSave={(addresses) => handleAddressesSave(addresses)}
            >
                <AddressEditor
                    formRef={props.formRef}
                    id={props.id}
                    address={editAddress}
                    onChange={(address) => handleAddressChange(address)}
                />
            </ListEditor>
        )
    }

    return (
        <>
            {props.withForm &&
                <Form ref={props.formRef}>
                    {buildView()}
                </Form>
            }
            {!props.withForm &&
                buildView()
            }
        </>
    )

}

RealEstateEditor.defaultProps = defaultProps;
