import React, { ReactElement } from 'react';
import { CloseCircleOutlined, DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { Button, Divider, FormInstance } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Justification } from 'components/Spacer/Spacer';
import classNames from 'classnames';
import './FormDivider.less';

export type MoveUpCallback = (index: number, data?: any) => void;
export type MoveDownCallback = (index: number, data?: any) => void;
export type DeleteCallback = (index: number, data?: any) => void;

export type FormDividerProps = {
    formRef: React.RefObject<FormInstance>,
    className?: string,
    data?: any,
    label?: string,
    inlineLabel?: string,
    info?: string,
    inline?: boolean,
    colon?: boolean,
    fill?: boolean,
    extra?: ReactElement,
    bold?: boolean,
    italic?: boolean,
    size?: "small" | "medium" | "large",
    justification?: Justification,
    index?: number,
    required?: boolean,
    labelCol?: object,
    wrapperCol?: object,
    onMoveUp?: MoveUpCallback,
    onMoveDown?: MoveDownCallback,
    onDelete?: DeleteCallback
} & typeof defaultProps;

const defaultProps = {
    index: 0,
    inline: false,
    colon: true,
    bold: false,
    italic: false,
    size: "medium",
    justification: "center"
}

export const FormDivider = (props: FormDividerProps): ReactElement => {
    const hasOps = props.onMoveUp || props.onMoveDown || props.onDelete;
    return (
        <div className={classNames("x-formdivider", props.className, { "x-formdivider-fill": props.fill })}>
            <FormItem
                formRef={props.formRef}
                labelCol={props.labelCol}
                wrapperCol={props.wrapperCol}
                className={
                    classNames(
                        "x-formdivider-label",
                        { "x-formdivider-bold": props.bold },
                        { "x-formdivider-italic": props.italic }
                    )
                }
                name={props.label}
                colon={props.colon}
                label={!props.inline ? props.label : undefined}
                info={props.info}
                valuePropName="defaultValue"
                rules={[{
                    // This forces the required asterisk to display.
                    // A dummy value is added into the item to prevent
                    // the validator from failing.
                    required: props.required
                }]}
            >
                <div
                    className="x-formdivider-content"
                    defaultValue={0}
                >
                    <Divider
                        className={
                            classNames(
                                'x-formdivider-divider',
                                { 'x-formdivider-bold': props.bold },
                                { 'x-formdivider-italic': props.italic }
                            )
                        }
                    >
                        {props.inline && !props.inlineLabel &&
                            <label>{props.label}</label>
                        }
                        {!props.inline && props.inlineLabel &&
                            <label>{props.inlineLabel}</label>
                        }
                    </Divider>
                    {(hasOps || props.extra) &&
                        <span className={
                            classNames(
                                'x-formdivider-actions',
                                `x-formdivider-${props.size}`
                            )
                        }>
                            {props.onMoveUp &&
                                <Button
                                    className="x-formdivider-btn"
                                    type="link"
                                    icon={<UpCircleOutlined />}
                                    onClick={() => props.onMoveUp!(props.index, props.data)}
                                />
                            }
                            {props.onMoveDown &&
                                <Button
                                    className="x-formdivider-btn"
                                    type="link"
                                    icon={<DownCircleOutlined />}
                                    onClick={() => props.onMoveDown!(props.index, props.data)}
                                />
                            }
                            {props.onDelete &&
                                <Button
                                    className="x-formdivider-btn"
                                    type="link"
                                    icon={<CloseCircleOutlined />}
                                    onClick={() => props.onDelete!(props.index, props.data)}
                                />
                            }
                            {props.extra}
                        </span>
                    }
                </div>
            </FormItem>
        </div >
    );
};

FormDivider.defaultProps = defaultProps;
