import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { Col, Form, FormInstance, Input, Row, Select, Switch } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { AuthenticationHeader, BranchFlow, Component, ConfigurationSpec, ConverterMap, Flow, FlowType, FlowUtils, Link, LoopFlow, ParallelFlow, PipeFlow } from '@methodset/endpoint-client-ts';
import { ParallelEditor } from './ParallelEditor/ParallelEditor';
import { PipeEditor } from './PipeEditor/PipeEditor';
import { EditorHeader } from '../../EditorHeader/EditorHeader';
import { BranchEditor } from './BranchEditor/BranchEditor';
import { LoopEditor } from './LoopEditor/LoopEditor';
import { ComponentMap } from '../Flows';
import { v4 as uuid } from "uuid";
import update from 'immutability-helper';
import './FlowEditor.less';

const FLOW_NAMES: { [key in FlowType]: string } = {
    [FlowType.PIPE]: "Pipe",
    [FlowType.PARALLEL]: "Parallel",
    [FlowType.LOOP]: "For Loop",
    [FlowType.BRANCH]: "If... Else"
};

// A graph of flow nodes to find leaf nodes.
export interface GraphNode {
    id: string,
    flow?: Flow;
    nextNodes: GraphNode[];
}
export type OutputComponents = {
    isArray: boolean,
    components: Component[]
}

// A mapping of state id to graph node.
export type NodeMap = { [key: string]: GraphNode }

// A state in the state machine graph.
export type State = Component | Flow;

// Used by concrete flow types.
export type ChangeCallback = (flow: Flow) => void;
export type CreateCallback = (flow: Flow) => void;
export type EditCallback = (link: Link) => void;

export type CancelCallback = () => void;
export type DoneCallback = (flow: Flow, index: number) => void;

export type FlowEditorProps = typeof FlowEditor.defaultProps & {
    // Class to style the form.
    className?: string,
    // All input/output type conversions.
    converterMap: ConverterMap,
    // The flow to edit.
    flow?: Flow,
    // The index of the component.
    index: number,
    // The full set of flows.
    flows: Flow[],
    // The type of flows to display.
    types?: FlowType[],
    // Map of component ids to components.
    componentMap: ComponentMap,
    // Available components.
    components: Component[],
    // Set of variable.
    configurationSpecs: ConfigurationSpec[],
    // The authentications.
    authentications: AuthenticationHeader[],
    // Called when the flow edit is done.
    onDone: DoneCallback
    // Called when the flow edit is canceled.
    onCancel: CancelCallback,
}

export type FlowEditorState = {
    // The type of flow to the next component.
    flow: Flow,
    // The link being edited.
    link?: Link,
    // True if setting up the starting flow.
    isStart: boolean,
    // The list of prev ids already in use.
    prevIds: string[],
    // The list of next ids already in use.
    nextIds: string[],
    // // The output components for wiring.
    outputComponents: OutputComponents
}

export class FlowEditor extends PureComponent<FlowEditorProps, FlowEditorState> {

    static defaultProps = {
        types: [FlowType.PIPE, FlowType.PARALLEL, FlowType.LOOP, FlowType.BRANCH]
    }

    private formRef = React.createRef<FormInstance>();

    constructor(props: FlowEditorProps) {
        super(props);
        this.state = {
            flow: props.flow ? props.flow : {} as Flow,
            isStart: !this.hasStartFlow(),
            prevIds: [],
            nextIds: [],
            outputComponents: this.findOutputComponents(props.flow)
        };
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleFlowChange = this.handleFlowChange.bind(this);
        this.handleStartChange = this.handleStartChange.bind(this);
        this.handlePrevChange = this.handlePrevChange.bind(this);
        this.handleEditDone = this.handleEditDone.bind(this);
        this.handleEditError = this.handleEditError.bind(this);
        this.handleEditCancel = this.handleEditCancel.bind(this);
    }

    private handleTypeChange(type: FlowType): void {
        let flow;
        switch (type) {
            case FlowType.PIPE:
                flow = PipeEditor.newFlow()
                break;
            case FlowType.LOOP:
                flow = LoopEditor.newFlow()
                break;
            case FlowType.PARALLEL:
                flow = ParallelEditor.newFlow()
                break;
            case FlowType.BRANCH:
                flow = BranchEditor.newFlow()
                break;
            default:
                return;
        }
        flow.id = uuid();
        flow.name = this.generateName(type);
        this.setState({
            flow: flow,
            prevIds: this.prevIds(flow),
            nextIds: this.nextIds(flow)
        });
    }

    private handleNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const name = e.target.value;
        const flow = update(this.state.flow, {
            name: { $set: name }
        });
        this.setState({ flow: flow });
    }

    private handleFlowChange(flow: Flow): void {
        this.setState({
            flow: flow,
            prevIds: this.prevIds(flow),
            nextIds: this.nextIds(flow)
        });
    }

    private handleStartChange(isStart: boolean): void {
        const flow = update(this.state.flow, {
            prevId: { $set: undefined }
        });
        this.setState({
            isStart: isStart,
            flow: flow,
            prevIds: this.prevIds(flow)
        });
    }

    private handlePrevChange(id: string): void {
        const flow = update(this.state.flow, {
            prevId: { $set: id }
        });
        const outputComponents = this.findOutputComponents(flow);
        this.setState({
            flow: flow,
            prevIds: this.prevIds(flow),
            outputComponents: outputComponents
        });
    }

    private handleEditDone(): void {
        this.props.onDone(this.state.flow, this.props.index);
    }

    private handleEditError(e: any): void {
    }

    private handleEditCancel(): void {
        this.props.onCancel();
    }

    private prevIds(flow: Flow): string[] {
        const ids: string[] = [];
        for (let i = 0; i < this.props.flows.length; i++) {
            if (i !== this.props.index) {
                const flow = this.props.flows[i];
                if (flow.prevId) {
                    ids.push(flow.prevId);
                }
            }
        }
        if (flow.prevId) {
            ids.push(flow.prevId);
        }
        return ids;
    }

    private nextIds(flow: Flow): string[] {
        let ids: string[] = [];
        for (let i = 0; i < this.props.flows.length; i++) {
            // Add transitions not being edited.
            if (i !== this.props.index) {
                const flow = this.props.flows[i];
                ids = ids.concat(FlowUtils.nextIds(flow));
            }
        }
        // Add transition being edited.
        ids = ids.concat(FlowUtils.nextIds(flow));
        return ids;
    }

    /**
     * Tells if a flow has been setup as the starting flow.
     * 
     * @returns True if there exists a starting flow.
     */
    private hasStartFlow(): boolean {
        for (let i = 0; i < this.props.flows.length; i++) {
            if (i !== this.props.index) {
                // Check transitions not being edited.
                const flow = this.props.flows[i];
                if (!flow.prevId) {
                    return true;
                }
            }
        }
        return false;
    }

    private generateName(type: FlowType): string {
        const names: { [key: string]: boolean } = {};
        for (let flow of this.props.flows) {
            const base = FLOW_NAMES[flow.type];
            if (flow.name && flow.name.startsWith(base)) {
                names[flow.name] = true;
            }
        }
        const base = FLOW_NAMES[type];
        let i = 1;
        while (true) {
            const name = `${base} ${i}`;
            if (!names[name]) {
                return name;
            }
            i++;
        }
    }

    private hasName(name: string): boolean {
        return this.props.flows.findIndex((flow, index) => index !== this.props.index && flow.name === name) !== -1;
    }

    private findOutputComponents(flow: Flow | undefined): OutputComponents {
        if (!flow || !flow.prevId) {
            return {
                isArray: false,
                components: []
            };
        }

        const prevId = flow.prevId;
        const prevFlow = this.props.flows.find(flow => flow.id === prevId);
        // If the previous flow is not found, it must be a component or a pipe flow.
        // Those will have a non-array output.
        if (!prevFlow) {
            const component = this.props.componentMap[prevId]
            return {
                isArray: false,
                components: [component!]
            }
        }

        const hasArrayOutputs = FlowUtils.hasArrayOutputs(prevFlow);

        // For each flow element, create a graph node.
        const nodeMap: NodeMap = {};
        for (const flow of this.props.flows) {
            // Add a node for each flow.
            const graphNode = {
                id: flow.id,
                flow: flow,
                nextNodes: []
            } as GraphNode;
            nodeMap[graphNode.id] = graphNode;
        }

        // Build the reachability graph.
        const entries = Object.entries(nodeMap);
        // Attach the next node to the node that has it as the prev. If the node
        // has any next nodes, there is no need to go into any sub-flows because
        // the outputs will have already been combined (i.e., array of outputs
        // from parallel).
        entries.forEach(([key, node]) => {
            // Skip the node that is the target. Since we are trying to find the 
            // output components of the prev and if this node is the next, 
            // we would not find the sub-flows.
            if (node.id !== flow.id) {
                const prevId = node.flow!.prevId;
                if (prevId) {
                    let prevNode = nodeMap[prevId];
                    if (!prevNode) {
                        // Prev of a pipe may not have a node.
                        prevNode = {
                            id: prevId,
                            nextNodes: []
                        }
                        //node.nextNodes.push(graphNode);
                        nodeMap[prevNode.id] = prevNode;
                    }
                    // Add the node as a next of the prev.
                    prevNode.nextNodes.push(node);
                }
            }
        });

        // Go through the nodes again and find ones that do not yet have any 
        // next nodes, but may have start nodes. If so, attach the start
        // nodes so they can be traversed to find leaves. If the node already
        // has next nodes, there is no need to go into any sub-flows because
        // the outputs will have already been combined (i.e., array of outputs
        // from parallel).
        entries.forEach(([key, node]) => {
            if (node.nextNodes.length === 0) {
                const flow = node.flow!;
                const startIds = FlowUtils.startIds(flow);
                for (const startId of startIds) {
                    let startNode = nodeMap[startId];
                    if (!startNode) {
                        // Start node that is a component.
                        startNode = {
                            id: startId,
                            nextNodes: []
                        }
                        nodeMap[startNode.id] = startNode;
                    }
                    node.nextNodes.push(startNode);
                }
            }
        });
        // Get the flows for all the leaf nodes.
        const rootNode = nodeMap[flow.prevId];
        const components = this.collectComponents(rootNode, []);
        return {
            isArray: hasArrayOutputs,
            components: components
        }
    }

    private collectComponents(node: GraphNode, components: Component[]): Component[] {
        if (node.nextNodes.length > 0) {
            for (const nextNode of node.nextNodes) {
                // Traverse each branch.
                this.collectComponents(nextNode, components);
            }
        } else {
            // At a leaf node. If it is a component, collect it.
            // It's output can be used for the next's input.
            const component = this.props.componentMap[node.id];
            if (component) {
                components.push(component!);
            }
        }
        return components;
    }

    private buildStates(): State[] {
        // Pipe flow types are replaced with their components (configured processors).
        let states: State[] = this.props.flows.filter(flow => flow.type !== FlowType.PIPE);
        return states.concat(this.props.components);
    }

    private parentFlow(): Flow | undefined {
        return this.props.flows.find(flow => flow.id === this.state.flow.prevId);
    }

    public render(): ReactElement {
        const states = this.buildStates();
        return (
            <Form
                ref={this.formRef}
                onFinish={this.handleEditDone}
                onFinishFailed={this.handleEditError}
            >
                <EditorHeader
                    title="Flow Editor"
                    onCancel={this.handleEditCancel}
                />
                <Row gutter={Globals.FORM_GUTTER_COL}>
                    <Col span={12}>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            label="Type"
                            name="flow-type"
                            info="The type of flow."
                            rules={[{
                                required: true,
                                message: "Please select a flow type."
                            }]}
                        >
                            <Select
                                allowClear={true}
                                placeholder="Select a flow."
                                value={this.state.flow.type}
                                onChange={this.handleTypeChange}
                            >
                                {this.props.types.includes(FlowType.PIPE) &&
                                    <Select.Option value={FlowType.PIPE}>{FLOW_NAMES[FlowType.PIPE]}</Select.Option>
                                }
                                {this.props.types.includes(FlowType.PARALLEL) &&
                                    <Select.Option value={FlowType.PARALLEL}>{FLOW_NAMES[FlowType.PARALLEL]}</Select.Option>
                                }
                                {this.props.types.includes(FlowType.LOOP) &&
                                    <Select.Option value={FlowType.LOOP}>{FLOW_NAMES[FlowType.LOOP]}</Select.Option>
                                }
                                {this.props.types.includes(FlowType.BRANCH) &&
                                    <Select.Option value={FlowType.BRANCH}>{FLOW_NAMES[FlowType.BRANCH]}</Select.Option>
                                }
                            </Select>
                        </FormItem>
                        {this.state.flow?.type &&
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                label="Name"
                                name="flow-name"
                                info="The name of the flow to identity it when building the workflow. It must be unique across all flow elements."
                                rules={[{
                                    required: true,
                                    message: "Please enter a flow name."
                                }, {
                                    validator: (rule: any, name: string) => {
                                        return !this.hasName(name) ? Promise.resolve() : Promise.reject("Name is already in use.");
                                    }
                                }]}
                            >
                                <Input
                                    placeholder="Enter a flow name."
                                    value={this.state.flow.name}
                                    onChange={this.handleNameChange}
                                />
                            </FormItem>
                        }
                        {!this.hasStartFlow() &&
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                label="Start of Workflow"
                                name="start"
                                info="True if this flow starts the workflow."
                                valuePropName="checked"
                            >
                                <Switch
                                    checked={this.state.isStart}
                                    disabled={!this.state.flow.type}
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    onChange={this.handleStartChange}
                                />
                            </FormItem>
                        }
                        {!this.state.isStart && this.state.flow.type &&
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                label="From"
                                name="from"
                                info="The process or flow to transition from."
                                rules={[{
                                    required: true,
                                    message: "Please select a process or flow."
                                }]}
                            >
                                <Select
                                    allowClear={true}
                                    placeholder="Select a processor or flow."
                                    value={this.state.flow.prevId}
                                    onChange={this.handlePrevChange}
                                >
                                    {states.map(state => (
                                        <Select.Option
                                            key={state.id}
                                            value={state.id}
                                            disabled={this.state.prevIds.includes(state.id) || this.state.flow.id === state.id}
                                        >
                                            {state.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </FormItem>
                        }
                    </Col>
                    <Col span={24}>
                        {this.state.flow?.type === FlowType.PIPE &&
                            <PipeEditor
                                formRef={this.formRef}
                                index={this.props.index}
                                prevIds={this.state.prevIds}
                                nextIds={this.state.nextIds}
                                parentFlow={this.parentFlow()}
                                flow={this.state.flow as PipeFlow}
                                outputComponents={this.state.outputComponents}
                                components={this.props.components}
                                componentMap={this.props.componentMap}
                                converterMap={this.props.converterMap}
                                onChange={this.handleFlowChange}
                            />
                        }
                        {this.state.flow?.type === FlowType.PARALLEL &&
                            <ParallelEditor
                                formRef={this.formRef}
                                prevIds={this.state.prevIds}
                                nextIds={this.state.nextIds}
                                index={this.props.index}
                                parentFlow={this.parentFlow()}
                                flow={this.state.flow as ParallelFlow}
                                states={states}
                                outputComponents={this.state.outputComponents}
                                componentMap={this.props.componentMap}
                                converterMap={this.props.converterMap}
                                onChange={this.handleFlowChange}
                            />
                        }
                        {this.state.flow?.type === FlowType.LOOP &&
                            <LoopEditor
                                formRef={this.formRef}
                                index={this.props.index}
                                prevIds={this.state.prevIds}
                                nextIds={this.state.nextIds}
                                parentFlow={this.parentFlow()}
                                flow={this.state.flow as LoopFlow}
                                states={states}
                                outputComponents={this.state.outputComponents}
                                configurationSpecs={this.props.configurationSpecs}
                                componentMap={this.props.componentMap}
                                converterMap={this.props.converterMap}
                                authentications={this.props.authentications}
                                onChange={this.handleFlowChange}
                            />
                        }
                        {this.state.flow?.type === FlowType.BRANCH &&
                            <BranchEditor
                                formRef={this.formRef}
                                prevIds={this.state.prevIds}
                                nextIds={this.state.nextIds}
                                index={this.props.index}
                                parentFlow={this.parentFlow()}
                                flow={this.state.flow as BranchFlow}
                                states={states}
                                outputComponents={this.state.outputComponents}
                                componentMap={this.props.componentMap}
                                converterMap={this.props.converterMap}
                                configurationSpecs={this.props.configurationSpecs}
                                onChange={this.handleFlowChange}
                            />
                        }
                    </Col>
                </Row>
            </Form >
        )
    }

}
