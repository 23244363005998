import { String } from '@methodset/commons-core-ts';
import { Calculator, CellComponents, Coordinate, FormulaError, VariableComponents, Range, RangeComponents, ReferenceParser, RefType, Cell, DataConverter, DataType, DimensionType, CoreUtils, ErrorType } from "@methodset/calculator-ts";
import { Globals } from "constants/Globals";
import { Widget } from '@methodset/application-client-ts';

export type VectorItem = {
    id: string,
    label: string,
}

export class WidgetUtils {

    public static rangeVectors(calculator: Calculator, rangeId: string | undefined, hasHeaders: boolean = false, rowLayout: boolean = false): VectorItem[] {
        const vectors: VectorItem[] = [];
        if (!rangeId) {
            return vectors;
        }
        const range = Range.fromUid(calculator, rangeId);
        const sheetName = CoreUtils.sheetLabel(range.sheet.name);
        if (!rowLayout) {
            const row = range.upper.row;
            const rowId1 = Coordinate.toRowId(row);
            const rowId2 = range.lower.rowId;
            const col1 = range.upper.col;
            const col2 = range.lower.col;
            const headers: string[] = [];
            if (hasHeaders) {
                const rows = range.sheet.rows;
                if (rows) {
                    rows.forEach(row => {
                        row.cells.forEach(cell => {
                            headers.push(cell.displayValue);
                        }, col1, col2);
                    }, row, row);
                }
            }
            for (let c = col1; c <= col2; c++) {
                const colId = Coordinate.toColumnId(c);
                const header = headers[c - col1];
                const id = `${sheetName}${colId}${rowId1}:${colId}${rowId2}`;
                const range = `${colId}${rowId1}:${colId}${rowId2}`;
                const label = header ? `${range} - ${header}` : range;
                vectors.push({
                    id: id,
                    label: label
                });
            }
        } else {
            const col = range.upper.col;
            const colId1 = Coordinate.toRowId(col);
            const colId2 = range.lower.colId;
            const row1 = range.upper.row;
            const row2 = range.lower.row;
            const headers: string[] = [];
            if (hasHeaders) {
                const columns = range.sheet.columns;
                if (columns) {
                    columns.forEach(col => {
                        col.cells.forEach(cell => {
                            headers.push(cell.displayValue);
                        }, row1, row2);
                    }, col, col);
                }
            }
            for (let r = row1; r <= row2; r++) {
                const rowId = Coordinate.toRowId(r);
                const header = headers[r - row1];
                const id = `${sheetName}${colId1}${rowId}:${colId2}${rowId}`;
                const range = `${colId1}${rowId}:${colId2}${rowId}`;
                const label = header ? `${range} - ${header}` : range;
                vectors.push({
                    id: id,
                    label: label
                });
            }
        }
        return vectors;
    }

    public static valueOrDefault(value: any, calculator: Calculator, variableId: string, useDefault: boolean | undefined): any {
        if (!CoreUtils.isEmpty(value)) {
            return value;
        }
        if (!useDefault) {
            return undefined;
        }
        // Get the default value from the cell.
        const variable = calculator.variables.get(variableId, false);
        if (!variable || !variable.cell) {
            return undefined;
        }
        const cell = variable.cell;
        if ((variable.type === DataType.DATE || variable.type === DataType.TIME) && cell.hasFormula()) {
            return cell.formula;
        }
        return variable.cell ? variable.cell.value : undefined;
    }

    public static toKey(id: string, version: number | undefined | null, key?: string): string {
        if (CoreUtils.isEmpty(version)) {
            version = Globals.SNAPSHOT_VERSION;
        }
        return key ? `${id}:${version}:${key}` : `${id}:${version}`;
    }

    public static parseKey(key: string): [string, number | undefined, string | undefined] {
        const parts = key.split(":");
        if (parts.length === 1) {
            return [parts[0], undefined, undefined];
        } else if (parts.length === 2) {
            return [parts[0], parseInt(parts[1]), undefined];
        } else {
            return [parts[0], parseInt(parts[1]), parts[2]];
        }
    }

    public static replaceCellRefs = (calculator: Calculator, text: string | undefined | null): string => {
        if (!text) {
            return "";
        }
        const refs = ReferenceParser.extract(text);
        for (const ref of refs) {
            let cell;
            let value;
            try {
                const element = ReferenceParser.parse(ref);
                if (!element) {
                    value = ErrorType.INTERNAL_ERROR;
                } else {
                    if (element.type === RefType.CELL) {
                        const components = element.components as CellComponents;
                        const sheet = calculator.sheets.get(components.sheetId);
                        cell = sheet.getCell(components.cellId, false);
                    } else if (element.type === RefType.VARIABLE) {
                        const components = element.components as VariableComponents;
                        const variable = calculator.variables.get(components.variableId, false);
                        cell = variable?.cell;
                    } else {
                        value = ErrorType.INVALID_SYNTAX;
                    }
                }
                if (cell) {
                    // Use the formatted value if available.
                    value = cell.formattedValue;
                    if (CoreUtils.isEmpty(value)) {
                        // Fall back to the regular value if no formatted value.
                        value = cell.value;
                    }
                    if (FormulaError.isError(value)) {
                        // Show the error type.
                        value = value.type;
                    } else if (CoreUtils.isEmpty(value)) {
                        // Show "not available" if no value.
                        value = "N/A";
                    }
                } else {
                    value = ErrorType.INTERNAL_ERROR;
                }
            } catch (e) {
                value = ErrorType.INTERNAL_ERROR;
            }
            text = ReferenceParser.replace(text, ref, value);
        }
        return !!text ? text : "";
    }

    public static buildRange = (calculator: Calculator, rangeId: string | undefined, throwIfInvalid: boolean = false): Range | undefined => {
        if (!rangeId) {
            return undefined;
        }
        try {
            const ref = ReferenceParser.parse(rangeId, throwIfInvalid);
            if (ref && ref.type === RefType.RANGE) {
                const components = ref.components as RangeComponents;
                const sheet = calculator.sheets.get(components.sheetId);
                return Range.fromId(sheet, components.rangeId);
            } else {
                return undefined;
            }
        } catch (e) {
            return undefined;
        }
    }

    public static buildVector = (calculator: Calculator, rangeId: string | undefined, index: number, throwIfInvalid: boolean = false): Range | undefined => {
        if (!rangeId) {
            return undefined;
        }
        try {
            // 1-based to 0-based.
            index -= 1;
            const ref = ReferenceParser.parse(rangeId, throwIfInvalid);
            if (ref && ref.type === RefType.RANGE) {
                const components = ref!.components as RangeComponents;
                const sheet = calculator.sheets.get(components.sheetId);
                let upper = Coordinate.fromCellId(components.upperId);
                let lower = Coordinate.fromCellId(components.lowerId);
                if (index < 0 || index > lower.col - upper.col) {
                    return undefined;
                }
                index += upper.col;
                upper = Coordinate.fromRowCol(upper.row, index);
                lower = Coordinate.fromRowCol(lower.row, index);
                return Range.fromCoordinates(sheet, upper, lower);
            }
        } catch (e) {
            return undefined;
        }
    }

    public static findValue = (calculator: Calculator, cellId: string | undefined): any => {
        if (!cellId) {
            return cellId;
        }
        let value;
        try {
            const ref = ReferenceParser.parse(cellId);
            if (ref) {
                const cell = this.findCell(calculator, cellId);
                if (cell) {
                    value = cell.formattedValue ? cell.formattedValue : cell.value;
                } else {
                    value = ErrorType.INTERNAL_ERROR;
                }
                // if (ref.type === RefType.CELL) {
                //     const components = ref.components as CellComponents;
                //     const sheet = calculator.sheets.get(components.sheetId);
                //     const cell = sheet.getCell(components.cellId, false);
                //     if (cell) {
                //         value = cell.formattedValue ? cell.formattedValue : cell.value;
                //     } else {
                //         value = Globals.ERROR_REF;
                //     }
                // } else if (ref.type === RefType.VARIABLE) {
                //     const variables = calculator.variables;
                //     const components = ref.components as VariableComponents;
                //     const variable = variables.get(components.variableId, false);
                //     const cell = variable?.cell;
                //     if (cell) {
                //         value = cell.formattedValue ? cell.formattedValue : cell.value;
                //     } else {
                //         value = Globals.ERROR_REF;
                //     }
                // }
            }
        } catch (e) {
            value = ErrorType.INTERNAL_ERROR;
        }
        if (!CoreUtils.isEmpty(value)) {
            if (FormulaError.isError(value)) {
                const error = value as FormulaError;
                value = error.type;
            } else if (!String.isString(value)) {
                value = value.toString();
            }
        }
        return value;
    }

    public static findCell = (calculator: Calculator, cellId: string | undefined): Cell | undefined => {
        if (!cellId) {
            return undefined;
        }
        try {
            const ref = ReferenceParser.parse(cellId);
            if (ref) {
                if (ref.type === RefType.CELL) {
                    const components = ref.components as CellComponents;
                    const sheet = calculator.sheets.get(components.sheetId);
                    return sheet.getCell(components.cellId, false);
                } else if (ref.type === RefType.VARIABLE) {
                    const variables = calculator.variables;
                    const components = ref.components as VariableComponents;
                    const variable = variables.get(components.variableId, false);
                    return variable?.cell;
                }
            }
            return undefined;
        } catch (e) {
            return undefined;
        }
    }

    public static isHidden(calculator: Calculator, widget: Widget): boolean {
        const refId = widget.configuration.hideId;
        const cell = WidgetUtils.findCell(calculator, refId);
        if (cell && !CoreUtils.isEmpty(cell.value)) {
            const doHide = DataConverter.convert(DataType.BOOLEAN, cell.value);
            if (!!doHide) {
                return true;
            }
        }
        return false;
    }

}
