import { ReactElement } from 'react';

export type MonoProps = {
    className?: string,
    children: string | ReactElement
};

export const Mono = (props: MonoProps): ReactElement => {

    return (
        <span
            className={props.className}
            style={{ fontFamily: "monospace" }}
        >
            {props.children}
        </span>
    )
}
