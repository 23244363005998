import { ReactElement } from 'react';
import { FontColorsOutlined } from '@ant-design/icons';
import { ColorType } from '@methodset/application-client-ts';
import { Button, Dropdown, Menu } from 'antd';
import { CoreUtils } from 'utils/CoreUtils';
import classNames from 'classnames';
import './ColorStyle.less';

export type ChangeCallback = (color: ColorType) => void;

export type ColorStyleProps = {
    color?: ColorType,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    color: ColorType.DARKGRAY
}

export const ColorStyle = (props: ColorStyleProps): ReactElement => {

    const handleSelect = (info: any) => {
        props.onChange(info.key);
    }

    const menu = (
        <Menu onClick={handleSelect}>
            {Object.entries(ColorType).map(([key, value]) =>
                <Menu.Item key={key}>
                    {CoreUtils.toCapital(value)}
                </Menu.Item>
            )}
        </Menu>
    );

    return (
        <div className="x-colorstyle">
            <Dropdown
                overlay={menu}
                trigger={['click']}
            >
                <Button
                    className={classNames(
                        { [`x-widgetviewer-color-${CoreUtils.toLower(props.color)}`]: !!props.color }
                    )}
                >
                    <FontColorsOutlined />
                </Button>
            </Dropdown>
        </div>

    )
}

ColorStyle.defaultProps = defaultProps;
