import { ReactElement, useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { StatusType } from 'constants/StatusType';
import { Notification, NotificationHeader } from '@methodset/application-client-ts';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { NotificationViewer } from '../Alerts/AlertSetup/NotificationViewer/NotificationViewer';
import { Calculator } from '@methodset/calculator-ts';
import axios from 'axios';
import applicationService from 'services/ApplicationService';
import './NotificationItem.less';

export type CloseCallback = () => void;

export type NotificationItemProps = {
    header: NotificationHeader,
    onClose: CloseCallback
}

export const NotificationItem = (props: NotificationItemProps): ReactElement => {

    // The loading status of headers.
    const [status, setStatus] = useState<StatusType>(StatusType.INIT);
    // The notification header being worked on (read).
    const [notification, setNotification] = useState<Notification | undefined>();

    useEffect(() => {
        if (status !== StatusType.READY) {
            loadData();
        }
    }, []);

    const handleRetryLoad = (): void => {
        loadData();
    }

    const readNotificationRequest = (notificationId: string): Promise<any> => {
        const request = {
            notificationId: notificationId,
        }
        return applicationService.readNotification(request,
            (response: any) => readNotificationResponse(response),
            undefined, true
        );
    }

    const readNotificationResponse = (response: any): void => {
        const notification = response.data.notification;
        setNotification(notification);
    }

    const loadData = (): void => {
        const requests = [];
        requests.push(readNotificationRequest(props.header.id));
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        return (
            <LoadSkeleton
                direction="vertical"
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load notification."
                onRetry={handleRetryLoad}
            >
                <LoadSkeleton.Input length="long" />
                <LoadSkeleton.Input length="medium" />
            </LoadSkeleton>
        );
    }

    const buildNotificationView = (): ReactElement => {
        let panel;
        let calculator;
        const snapshot = notification!.snapshot;
        if (snapshot) {
            panel = snapshot.panel;
            calculator = Calculator.deserialize(snapshot.calculator);
            calculator.execute();
        }
        return (
            <div>
                <NotificationViewer
                    message={notification!.text}
                    panel={panel}
                    calculator={calculator}
                />
            </div>
        )
    }

    let view;
    if (status === Globals.STATUS_LOADING) {
        view = buildLoadingView(true);
    } else if (status === Globals.STATUS_FAILED) {
        view = buildLoadingView(false);
    } else if (status === Globals.STATUS_READY) {
        view = buildNotificationView();
    }

    return (
        <Modal
            centered
            className="x-notificationitem"
            title={props.header.subject}
            visible={true}
            closable={false}
            footer={(
                <Button type="primary" onClick={props.onClose}>Close</Button>
            )}
        >
            {view}
        </Modal>

    );

}
