import { PureComponent, ReactElement } from 'react';
import { EditOutlined, LinkOutlined, LockOutlined, PlusOutlined, SyncOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { InlineText } from 'components/InlineText/InlineText';
import { DashboardProperties, Properties } from './DashboardProperties/DashboardProperties';
import { Tip } from 'components/Tip/Tip';
import { Dashboard, InputLink } from '@methodset/application-client-ts';
import { DashboardLinks } from './DashboardConfiguration/DashboardLinks';
import { ItemMap } from '../DashboardItem';
import { InlineMessage, OpMessage } from 'components/InlineMessage/InlineMessage';
import update from 'immutability-helper';
import classNames from 'classnames';
import './DashboardHeading.less';

export type AddCallback = () => void;
export type SaveCallback = () => void;
export type RefreshCallback = () => void;
export type ChangeCallback = (value: InputLink[] | Dashboard, type: ChangeType) => void;
export enum ChangeType {
    PROPERTIES,
    LINKS
}

export type DashboardHeadingProps = typeof DashboardHeading.defaultProps & {
    className?: string,
    message?: OpMessage,
    isSaving: boolean,
    showSave: boolean,
    itemMap: ItemMap,
    dashboard: Dashboard,
    onAdd: AddCallback,
    onSave?: SaveCallback,
    onChange: ChangeCallback
}

export type DashboardHeadingState = {
    isEditingProperties: boolean,
    isEditingLinks: boolean
}

export class DashboardHeading extends PureComponent<DashboardHeadingProps, DashboardHeadingState> {

    static defaultProps = {
        showSave: false
    }

    constructor(props: DashboardHeadingProps) {
        super(props);
        this.state = {
            isEditingProperties: false,
            isEditingLinks: false
        };
        this.handleAppletAdd = this.handleAppletAdd.bind(this);
        this.handleLinksEdit = this.handleLinksEdit.bind(this);
        this.handleLinksChange = this.handleLinksChange.bind(this);
        this.handleLinksCancel = this.handleLinksCancel.bind(this);
        this.handlePropertiesEdit = this.handlePropertiesEdit.bind(this);
        this.handlePropertiesChange = this.handlePropertiesChange.bind(this);
        this.handlePropertiesCancel = this.handlePropertiesCancel.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
    }

    private handleAppletAdd(): void {
        this.props.onAdd();
    }

    private handleLinksEdit(): void {
        this.setState({ isEditingLinks: true });
    }

    private handleLinksChange(inputLinks: InputLink[]): void {
        this.props.onChange(inputLinks, ChangeType.LINKS);
        this.setState({ isEditingLinks: false });
    }

    private handleLinksCancel(): void {
        this.setState({ isEditingLinks: false });
    }

    private handleNameChange(name: string): void {
        const dashboard = update(this.props.dashboard, {
            name: { $set: name }
        });
        this.props.onChange(dashboard, ChangeType.PROPERTIES);
    }

    private handlePropertiesEdit(): void {
        this.setState({ isEditingProperties: true });
    }

    private handlePropertiesChange(properties: Properties): void {
        const dashboard = update(this.props.dashboard, {
            name: { $set: properties.name },
            description: { $set: properties.description }
        });
        this.props.onChange(dashboard, ChangeType.PROPERTIES);
        this.setState({ isEditingProperties: false });
    }

    private handlePropertiesCancel(): void {
        this.setState({ isEditingProperties: false });
    }

    public render(): ReactElement {
        return (
            <div className={classNames('x-dashboardheading', this.props.className)}>
                <div className="x-dashboardheading-title">
                    <InlineText
                        className={classNames('x-dashboardheading-text')}
                        value={this.props.dashboard.name}
                        onChange={this.handleNameChange}
                    />
                </div>
                <div className="x-dashboardheading-buttons">
                    <Space>
                        <InlineMessage message={this.props.message} />
                        {this.props.showSave && this.props.onSave &&
                            <Tip title="Save dashboard changes.">
                                <Button onClick={this.props.onSave}>Save</Button>
                            </Tip>
                        }
                        <Tip title="Add applets to the dashboard.">
                            <Button icon={<PlusOutlined />} onClick={this.handleAppletAdd}>Add Applet</Button>
                        </Tip>
                        <Tip title="Link applet input parameters.">
                            <Button icon={<LinkOutlined />} onClick={this.handleLinksEdit} />
                        </Tip>
                        <Tip title="Edit dashboard properties.">
                            <Button icon={<EditOutlined />} onClick={this.handlePropertiesEdit} />
                        </Tip>
                    </Space>
                </div>
                {this.state.isEditingLinks &&
                    <DashboardLinks
                        itemMap={this.props.itemMap}
                        inputLinks={this.props.dashboard.inputLinks}
                        onChange={this.handleLinksChange}
                        onCancel={this.handleLinksCancel}
                    />
                }
                {this.state.isEditingProperties &&
                    <DashboardProperties
                        visible={this.state.isEditingProperties}
                        isSaving={this.props.isSaving}
                        properties={{
                            name: this.props.dashboard.name,
                            description: this.props.dashboard.description
                        }}
                        onChange={this.handlePropertiesChange}
                        onCancel={this.handlePropertiesCancel}
                    />
                }
            </div>
        )
    }

}
