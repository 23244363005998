import { ReferenceParser } from "@methodset/calculator-ts";
import { ValueWidgetConfiguration, Widget } from '@methodset/application-client-ts';

export class ValueWidgetSync {

    public static parser = (widget: Widget): string[] => {
        const configuration = widget.configuration as ValueWidgetConfiguration;
        const refs: string[] = [];
        refs.push(...ReferenceParser.extract(configuration.hideId, true));
        refs.push(...ReferenceParser.extract(configuration.label));
        refs.push(...ReferenceParser.extract(configuration.description));
        refs.push(...ReferenceParser.extract(configuration.valueId, true));
        refs.push(...ReferenceParser.extract(configuration.changeId, true));
        return refs;
    }

    public static updater = (widget: Widget, target: string, replacement: string): void => {
        const configuration = widget.configuration as ValueWidgetConfiguration;
        configuration.hideId = ReferenceParser.update(configuration.hideId, target, replacement, true);
        configuration.label = ReferenceParser.update(configuration.label, target, replacement);
        configuration.description = ReferenceParser.update(configuration.description, target, replacement);
        configuration.valueId = ReferenceParser.update(configuration.valueId, target, replacement, true);
        configuration.changeId = ReferenceParser.update(configuration.changeId, target, replacement, true);
    }

}
