import { ReactElement } from 'react';
import { FormInstance, Input } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { ListLoop, Loop } from '@methodset/endpoint-client-ts';
import update from 'immutability-helper';

export type ChangeCallback = (loop: Loop) => void;

export type ListLoopEditorProps = {
    formRef: React.RefObject<FormInstance>,
    loop: ListLoop,
    onChange: ChangeCallback
}

export const ListLoopEditor = (props: ListLoopEditorProps): ReactElement => {

    const handleValuesChange = (values: string): void => {
        const loop = update(props.loop, {
            values: { $set: values }
        });
        props.onChange(loop);
    }

    return (
        <div>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Values"
                name="values"
                info="A comma-separated list of values to loop over."
                rules={[{
                    required: true,
                    message: "Enter a list of values."
                }]}
            >
                <Input
                    placeholder="Enter a list of values."
                    value={props.loop.values}
                    onChange={(e) => handleValuesChange(e.target.value)}
                />
            </FormItem>
        </div>
    )
}
