import { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Request } from '../Request/Request';
import { RequestType } from '@methodset/entity-client-ts';

export type WaitlistProps = RouteComponentProps;

export const Waitlist = (props: WaitlistProps): ReactElement => {

    return (
        <Request
            {...props}
            type={RequestType.WAITLIST}
            requestText={
                <>
                    <p>
                        Thank you for your interest in the platform. We are not currently open to the public,
                        but please enter your information if you would like to be notified when the platform
                        becomes generally available.
                    </p>
                </>
            }
            responseText="Thank you for submitting your request. A member of our team will reach out to you."
        />
    )

}