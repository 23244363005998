import { ReactElement } from 'react';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import { Documents } from './Documents/Documents';
import { ModelsDoc } from './Documents/Docs/ModelsDoc/ModelsDoc';
import { OverviewDoc } from './Documents/Docs/OverviewDoc/OverviewDoc';
import { DashboardsDoc } from './Documents/Docs/DashboardsDoc/DashboardsDoc';
import { DatasetsDoc } from './Documents/Docs/DatasetsDoc/DatasetsDoc';
import { WorkflowsDoc } from './Documents/Docs/WorkflowsDoc/WorkflowsDoc';
import { OrganizationsDoc } from './Documents/Docs/OrganizationsDoc/OrganizationsDoc';
import { CredentialsDoc } from './Documents/Docs/CredentialsDoc/CredentialsDoc';
import { GroupsDoc } from './Documents/Docs/GroupsDoc/GroupsDoc';
import { AccountsDoc } from './Documents/Docs/AccountsDoc/AccountsDoc';
import { ApiDoc } from './Documents/Docs/ApisDoc/ApisDoc';
import { VersioningDoc } from './Documents/Docs/VersioningDoc/VersioningDoc';
import { FunctionsDoc } from './Documents/Docs/FunctionsDoc/FunctionsDoc';
import { FunctionDoc } from './Documents/Docs/FunctionsDoc/FunctionDoc/FunctionDoc';
import { TutorialsDoc } from './Documents/TutorialsDoc/TutorialsDoc';
import { PageNotFound } from 'containers/Components/PageNotFound/PageNotFound';
import { Display } from 'components/Display/Display';
import './Documentation.less';

export type DocumentationProps = RouteComponentProps;

export const Documentation = (props: DocumentationProps): ReactElement => {

    return (
        <Layout className="x-documentation">
            <Layout>
                <Display className="x-documentation-side" xs="hide" md="show">
                    <Layout.Sider>
                        <Documents {...props} />
                    </Layout.Sider>
                </Display>
                <Layout.Content className="x-documentation-content">
                    <Switch>
                        <Route exact path={RouteBuilder.MAIN_DOCUMENTATION_OVERVIEW} component={OverviewDoc} />
                        <Route exact path={RouteBuilder.MAIN_DOCUMENTATION_DASHBOARDS} component={DashboardsDoc} />
                        <Route exact path={RouteBuilder.MAIN_DOCUMENTATION_DATASETS} component={DatasetsDoc} />
                        <Route exact path={RouteBuilder.MAIN_DOCUMENTATION_MODELS} component={ModelsDoc} />
                        <Route exact path={RouteBuilder.MAIN_DOCUMENTATION_WORKFLOWS} component={WorkflowsDoc} />
                        <Route exact path={RouteBuilder.MAIN_DOCUMENTATION_ORGANIZATIONS} component={OrganizationsDoc} />
                        <Route exact path={RouteBuilder.MAIN_DOCUMENTATION_CREDENTIALS} component={CredentialsDoc} />
                        <Route exact path={RouteBuilder.MAIN_DOCUMENTATION_GROUPS} component={GroupsDoc} />
                        <Route exact path={RouteBuilder.MAIN_DOCUMENTATION_ACCOUNTS} component={AccountsDoc} />
                        <Route exact path={RouteBuilder.MAIN_DOCUMENTATION_APIS} component={ApiDoc} />
                        <Route exact path={RouteBuilder.MAIN_DOCUMENTATION_VERSIONING} component={VersioningDoc} />
                        <Route exact path={RouteBuilder.MAIN_DOCUMENTATION_FUNCTIONS} component={FunctionsDoc} />
                        <Route exact path={RouteBuilder.MAIN_DOCUMENTATION_FUNCTION} component={FunctionDoc} />
                        <Route exact path={RouteBuilder.MAIN_DOCUMENTATION_TUTORIALS} component={TutorialsDoc} />
                        <Route component={PageNotFound} />
                    </Switch>
                </Layout.Content>
            </Layout>
        </Layout>
    );

}
