import { Chapter } from 'components/Chapter/Chapter';
import { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DocFrame } from '../DocFrame/DocFrame';

export type CredentialsDocProps = RouteComponentProps;

export const CredentialsDoc = (props: CredentialsDocProps): ReactElement => {

    return (
        <DocFrame title="Credentials" {...props}>
            <Chapter>
                Credentials allow you to store in a single location usernames, passwords, and API keys to
                services and providers you haves access to. Credentials can then be imported in queries
                when accessing those data sources. This allows you to manage all your credentials in
                a single place so that they can be shared and do not have to be configured into each
                access point.
            </Chapter>
        </DocFrame>
    );

}
