import { Calculator, DataType, NumberFormat, Variable } from '@methodset/calculator-ts';
import { ReactElement, useEffect, useState } from 'react';
import { Justify } from 'components/Justify/Justify';
import { Button, Popover, Select, Switch } from 'antd';
import { DecimalInput } from 'components/DecimalInput/DecimalInput';
import { DateTimeSelector, DateTimeSelectorUtils } from 'components/DateTimeSelector/DateTimeSelector';
import { CoreUtils } from '@methodset/commons-core-ts';
import { TextInput } from 'components/TextInput/TextInput';
import { Spacer } from 'components/Spacer/Spacer';
import { CloseCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import './InputEditor.less';

export type ChangeCallback = (value: any, variable: Variable) => void;

export type InputEditorProps = {
    className?: string,
    inputClassName?: string,
    calculator: Calculator,
    value: any,
    variableId: string,
    doEmbed?: boolean,
    useDefault?: boolean,
    useNull?: boolean,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    useDefault: true,
    useNull: true
}

export const InputEditor = (props: InputEditorProps): ReactElement => {

    const [isEditing, setIsEditing] = useState<boolean>(false);

    const handleChange = (variable: Variable, value: any): void => {
        // Need to differentiate between the user clearing a value,
        // which will override any default value, or the user accepting
        // the default cell value. In both cases, the value in the 
        // configuration will be undefined. If the user explicitly 
        // cleared a value, it should overwrite the default value.
        if (value === undefined && props.useNull) {
            // The value was cleared. Set it to null to 
            // indicate as such, as opposed to undefined
            // which means that the default is being used.
            value = null;
        }
        props.onChange(value, variable);
    }

    const buildView = (): ReactElement => {
        const variable = props.calculator.variables.get(props.variableId, false);
        if (!variable) {
            return (
                <Spacer size="sm">
                    <CloseCircleOutlined className="x-inputeditor-error" />
                    {`Variable ${props.variableId} does not exist.`}
                </Spacer>
            );
        }

        let element;
        if (variable.options) {
            // Option values are stored as strings.
            // TODO: enable typed inputs for option values
            let value = !CoreUtils.isEmpty(props.value) ? props.value.toString() : props.value;
            if (CoreUtils.isEmpty(value) && variable.options.length > 0) {
                // If value is empty and there are options, default to the first.
                value = variable.options[0].value;
                props.onChange(value, variable);
            }
            element = (
                <Select
                    className={props.inputClassName}
                    allowClear={false}
                    value={value}
                    //defaultValue={variable.options.length > 0 ? variable.options[0].value : undefined}
                    onChange={(value) => handleChange(variable, value)}
                >
                    {variable.options.map(option => (
                        <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                    ))}
                </Select>
            )
        } else {
            switch (variable.type) {
                case DataType.TEXT: {
                    element = (
                        <TextInput
                            className={props.inputClassName}
                            value={props.value}
                            onChange={(value) => handleChange(variable, value)}
                        />
                    )
                    break;
                }
                case DataType.NUMBER: {
                    element = (
                        <DecimalInput
                            className={props.inputClassName}
                            //fill={true}
                            justification="left"
                            value={props.value}
                            onChange={(value) => handleChange(variable, value)}
                        />
                    )
                    break;
                }
                case DataType.BOOLEAN: {
                    return (
                        <Switch
                            className={props.inputClassName}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={props.value}
                            onChange={(value) => handleChange(variable, value)}
                        />
                    )
                }
                case DataType.TIME:
                case DataType.DATE: {
                    if (props.doEmbed) {
                        return (
                            <DateTimeSelector
                                className={props.inputClassName}
                                value={props.value}
                                syntax="formula"
                                showTime={variable.type === DataType.TIME}
                                onChange={(value) => handleChange(variable, value)}
                            />
                        )
                    } else {
                        const selector = (
                            <Spacer direction="vertical" size="lg" alignment="top">
                                <DateTimeSelector
                                    className={classNames("x-inputeditor-popup", props.inputClassName)}
                                    value={props.value}
                                    syntax="formula"
                                    showTime={variable.type === DataType.TIME}
                                    onChange={(value) => handleChange(variable, value)}
                                />
                                <Justify justification="right">
                                    <Button type="primary" onClick={() => setIsEditing(false)}>Close</Button>
                                </Justify>
                            </Spacer>
                        )
                        return (
                            <Popover
                                content={selector}
                                trigger="click"
                                placement="bottomLeft"
                                visible={isEditing}
                                onVisibleChange={(visible) => setIsEditing(visible)}
                            >
                                <span className="x-inputeditor-date">
                                    {DateTimeSelectorUtils.toString(props.value, "formula")}
                                </span>
                            </Popover>
                        )
                    }
                }
                default: {
                    element = (
                        <TextInput
                            className={props.inputClassName}
                            value={props.value}
                            onChange={(value) => handleChange(variable, value)}
                        />
                    )
                }
            }

        }

        const format = variable.cell.format;
        if (format && format.type === "number") {
            const numberFormat = format as NumberFormat;
            return (
                <Spacer size="xs">
                    {numberFormat?.prefix &&
                        <span className="x-inputeditor-prefix">{numberFormat?.prefix}</span>
                    }
                    {element}
                    {numberFormat?.postfix &&
                        <span className="x-inputeditor-postfix">{numberFormat?.postfix}</span>
                    }
                </Spacer>
            )
        } else {
            return element;
        }
    }

    return (
        <span className={props.className}>
            {buildView()}
        </span>
    )

}

InputEditor.defaultProps = defaultProps;
