import { ReactElement } from 'react';
import { FormInstance } from 'antd';
import { ItemSelect } from 'components/ItemSelect/ItemSelect';
import { ProcessorHeader, ProcessorType } from '@methodset/endpoint-client-ts';
import { ProcessorView } from '../ProcessorView/ProcessorView';
import { CoreUtils } from 'utils/CoreUtils';
import endpointService from 'services/EndpointService';
import './ProcessorList.less';

export type ChangeCallback = (header: ProcessorHeader) => void;

export type ProcessorListProps = {
    formRef: React.RefObject<FormInstance>,
    // List of processor headers, optional if cached in parent.
    headers?: ProcessorHeader[],
    // Types of processors to include in selection list.
    types?: ProcessorType[],
    // The selected processor.
    processorId?: string,
    // Called when the processor is selected.
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    types: CoreUtils.enumToKeys(ProcessorType),
}

export const ProcessorList = (props: ProcessorListProps): ReactElement => {

    const handleProcessorChange = (header: ProcessorHeader): void => {
        props.onChange(header);
    }

    const readProcessorHeadersRequest = (): Promise<any> => {
        if (props.headers) {
            const headers = processHeaders(props.headers);
            return Promise.resolve(headers);
        }
        const request = {};
        return endpointService.readProcessorHeaders(request,
            (response: any) => readProcessorHeadersResponse(response),
            undefined, true
        );
    }

    const readProcessorHeadersResponse = (response: any): ProcessorHeader[] => {
        let headers: ProcessorHeader[] = response.data.headers;
        return processHeaders(headers);
    }

    const processHeaders = (headers: ProcessorHeader[]): ProcessorHeader[] => {
        headers = headers.filter(header => props.types.includes(header.type));
        headers.sort((a, b) => a.name.localeCompare(b.name));
        return headers;
    }

    return (
        <div className="x-processorlist">
            <ItemSelect<ProcessorHeader>
                formRef={props.formRef}
                selectedId={props.processorId}
                columns={1}
                group={true}
                access={false}
                skeleton={["medium", "fill"]}
                label={"processor"}
                view={ProcessorView}
                loader={readProcessorHeadersRequest}
                onChange={handleProcessorChange}
            />
        </div>
    );

}

ProcessorList.defaultProps = defaultProps;
