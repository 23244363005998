import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { FormInstance, Input } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { ApiCredentials, Credentials, CredentialsType } from '@methodset/endpoint-client-ts';
import update from 'immutability-helper';
import classNames from 'classnames';
import './ApiCredentialsEditor.less';

export type ChangeCallback = (credentials: Credentials) => void;

export type ApiCredentialsEditorProps = typeof ApiCredentialsEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    className?: string,
    credentials: ApiCredentials,
    onChange: ChangeCallback
}

export class ApiCredentialsEditor extends PureComponent<ApiCredentialsEditorProps> {

    static defaultProps = {
        credentials: {
            type: CredentialsType.API
        } as ApiCredentials
    }

    constructor(props: ApiCredentialsEditorProps) {
        super(props);
        this.handleKeyChange = this.handleKeyChange.bind(this);
    }

    private handleKeyChange(e: ChangeEvent<HTMLInputElement>): void {
        const key = e.target.value;
        const credentials = update(this.props.credentials, {
            key: { $set: key }
        });
        this.props.onChange(credentials);
    }

    public render(): ReactElement {
        return (
            <div className={classNames('x-apicredentialseditor', this.props.className)}>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="API Key"
                    name="key"
                    rules={[{
                        required: true,
                        message: 'Please enter an API key.'
                    }]}
                >
                    <Input.Password
                        placeholder="Enter an API key."
                        value={this.props.credentials.key}
                        onChange={this.handleKeyChange}
                    />
                </FormItem>
            </div>
        );
    }

}
