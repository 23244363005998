import { PureComponent, ReactElement } from 'react';
import { Modal, Tabs } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Calculator, ElementChangeType, Sheet, SheetChangeEvent } from '@methodset/calculator-ts';
import { NameEditor } from './SheetTab/NameEditor/NameEditor';
import { DirectionType, SheetTab } from './SheetTab/SheetTab';
import { SheetItem } from './SheetItem/SheetItem';
import { ModelContext } from 'context/ModelContext';
import classNames from 'classnames';
import update from 'immutability-helper';
import './Sheets.less';

export type SheetsProps = {
    className?: string,
    // Need to pass calculator in props so that the 
    // tabs can be initialized in the constructor.
    calculator: Calculator
};

export type SheetsState = {
    isRenaming: boolean,
    activeSheet?: Sheet,
    tabHeight: number,
    tabs: ReactElement[]
}

export class Sheets extends PureComponent<SheetsProps, SheetsState> {

    static contextType = ModelContext;

    private sheetItems: Map<Sheet, SheetItem> = new Map();
    private isCreating: boolean = false;
    private tabElement?: Element | null;

    constructor(props: SheetsProps) {
        super(props);
        this.handleSheetMount = this.handleSheetMount.bind(this);
        this.handleSheetUnmount = this.handleSheetUnmount.bind(this);
        this.handleFitClick = this.handleFitClick.bind(this);
        this.handleMoveClick = this.handleMoveClick.bind(this);
        this.handleRenameClick = this.handleRenameClick.bind(this);
        this.handleRenameCancel = this.handleRenameCancel.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleTabAdd = this.handleTabAdd.bind(this);
        this.handleSheetsChange = this.handleSheetsChange.bind(this);
        this.handleSizeChange = this.handleSizeChange.bind(this);
        // Tabs must be created after functions are bound.
        this.state = {
            isRenaming: false,
            tabHeight: 400,
            tabs: this.createTabs(props.calculator),
        };
    }

    private handleSheetMount(item: SheetItem, sheet: Sheet): void {
        this.sheetItems.set(sheet, item);
        if (this.isCreating) {
            this.activateSheet(sheet);
            this.isCreating = false;
        }
    }

    private handleSheetUnmount(item: SheetItem, sheet: Sheet): void {
        this.sheetItems.delete(sheet);
    }

    private handleFitClick(sheet: Sheet): void {
        const table = this.sheetItems.get(sheet);
        table?.handleSheetFit();
    }

    private handleMoveClick(sheet: Sheet, direction: DirectionType): void {
        const sheets = this.context.calculator.sheets;
        const index = sheets.index(sheet.id);
        if (direction === DirectionType.LEFT) {
            sheets.move(sheet, index - 1);
        } else if (direction === DirectionType.RIGHT) {
            sheets.move(sheet, index + 1);
        }
    }

    private handleRenameClick(): void {
        this.setState({ isRenaming: true });
    }

    private handleRenameCancel(): void {
        this.setState({ isRenaming: false });
    }

    private handleNameChange(name: string): void {
        const sheet = this.state.activeSheet!;
        if (name !== sheet.name) {
            sheet.name = name;
        }
        this.setState({ isRenaming: false });
    }

    private handleDeleteClick(sheet: Sheet): void {
        const that = this;
        Modal.confirm({
            title: 'Confirm Delete',
            content: 'Are you sure you want to delete the sheet?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                const calculator = that.context.calculator;
                calculator.sheets.remove(sheet.id);
            }
        });
    }

    private handleTabChange(key: string): void {
        const calculator = this.context.calculator;
        const sheet = calculator.sheets.find(key);
        this.activateSheet(sheet);
    }

    private handleTabAdd(): void {
        const calculator = this.context.calculator;
        calculator.sheets.add();
    }

    private activateSheet(sheet: Sheet): void {
        // console.log(`Activate: ${sheet.id}`);
        this.setState({ activeSheet: sheet });
        const item = this.sheetItems.get(sheet);
        item?.onSheetActivate();
    }

    private handleSheetsChange(event: SheetChangeEvent): void {
        const calculator = this.context.calculator;
        if (event.type === ElementChangeType.ADD) {
            const sheet = event.sheet;
            const tab = this.createTab(sheet);
            const tabs = update(this.state.tabs, {
                $push: [tab]
            });
            // Tells to activate the tab after is has mounted.
            this.activateSheet(sheet);
            this.isCreating = true;
            this.setState({ tabs: tabs });
        } else if (event.type === ElementChangeType.REMOVE) {
            const sheets = calculator.sheets;
            let sheet;
            if (sheets.length === 0) {
                // Final remaining tab, active the add tab.
                sheet = null;
            } else if (event.index === sheets.length) {
                // Last tab in list, activate previous tab.
                sheet = sheets.get(event.index - 1);
            } else {
                // Not last tab in list, activate next tab.
                sheet = sheets.get(event.index);
            }
            const tabs = update(this.state.tabs, {
                $splice: [[event.index, 1]]
            });
            this.setState({ tabs: tabs });
            this.activateSheet(sheet);
        } else if (event.type === ElementChangeType.MOVE) {
            const tab = this.state.tabs[event.prev!];
            const tabs = update(this.state.tabs, {
                $splice: [[event.prev!, 1], [event.index, 0, tab]]
            });
            // Update the index in each tab.
            this.setState({ tabs: tabs });
            this.activateSheet(event.sheet);
        }
    }

    private handleSizeChange(): void {
        if (!this.tabElement) {
            this.tabElement = document.getElementById("calculator");
        }
        if (this.tabElement) {
            const clientRect = this.tabElement.getBoundingClientRect();
            const tableHeight = window.innerHeight - clientRect.top - 10;  // padding
            // Queue the resizing of the tab to avoid a resize loop.
            // See https://github.com/juggle/resize-observer/blob/master/README.md#resize-loop-detection
            setTimeout(() => this.setState({ tabHeight: tableHeight }), 0);
        }
    }

    private createTabs(calculator: Calculator): ReactElement[] {
        const tabs: ReactElement[] = [];
        const sheets = calculator.sheets;
        sheets.forEach((sheet: Sheet, index: number) => {
            const tab = this.createTab(sheet);
            tabs.push(tab);
        });
        return tabs;
    }

    private createTab(sheet: Sheet): ReactElement {
        const tab = (
            <SheetTab
                sheet={sheet}
                //index={index}
                onFit={this.handleFitClick}
                onMove={this.handleMoveClick}
                onRename={this.handleRenameClick}
                onDelete={this.handleDeleteClick}
            />
        );
        return (
            <Tabs.TabPane
                key={sheet.uuid}
                id={sheet.uuid}
                tab={tab}
                closable={false}
            >
                <SheetItem
                    sheet={sheet}
                    onMount={this.handleSheetMount}
                    onUnmount={this.handleSheetUnmount}
                />
            </Tabs.TabPane>
        );
    }

    public componentDidMount(): void {
        const calculator = this.context.calculator;
        const sheets = calculator.sheets;
        sheets.addCallback("Change", this.handleSheetsChange);
        if (sheets.length === 0) {
            // Add a default sheet.
            calculator.sheets.add();
        } else {
            const sheet = sheets.get(0);
            this.activateSheet(sheet);
        }
        window.addEventListener("resize", this.handleSizeChange);
        this.context.addCallback("FormulaEditorResize", this.handleSizeChange);
        this.handleSizeChange();
    }

    public componentWillUnmount(): void {
        const calculator = this.context.calculator;
        const sheets = calculator.sheets;
        sheets.removeCallback("Change", this.handleSheetsChange);
        window.removeEventListener("resize", this.handleSizeChange);
        this.context.removeCallback("FormulaEditorResize", this.handleSizeChange);
    }

    public render(): ReactElement {
        return (
            <div id="calculator" className={classNames('x-sheets', this.props.className)}>
                <Tabs
                    className="x-sheets-tabs"
                    type="editable-card"
                    style={{ height: this.state.tabHeight }}
                    hideAdd={false}
                    activeKey={this.state.activeSheet?.uuid}
                    onTabClick={this.handleTabChange}
                    onEdit={this.handleTabAdd}
                >
                    {this.state.tabs}
                </Tabs>
                {this.state.isRenaming && this.state.activeSheet &&
                    <NameEditor
                        name={this.state.activeSheet.name}
                        onChange={this.handleNameChange}
                        onCancel={this.handleRenameCancel}
                    />
                }
            </div>
        );
    }

}
