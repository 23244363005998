import React, { Component, ReactElement } from 'react';
import { DatePicker, FormInstance } from 'antd';
import { Select } from 'antd';
import { DaysOfWeekSelector } from 'components/DaysOfWeekSelector/DaysOfWeekSelector';
import { FormItem } from 'components/FormItem/FormItem';
import { TimezoneSelector } from 'components/TimeZoneSelector/TimeZoneSelector';
import { Constants } from 'components/Constants';
import { Cron, FrequencyType, TimeInterval } from '@methodset/schedule-client-ts';
import { CoreUtils } from 'utils/CoreUtils';
import { ExcludeField } from '../ScheduleEditor';
import moment from 'moment';
import update from 'immutability-helper';
import './IntervalEditor.less';

export type IntervalPeriod = FrequencyType.HOUR | FrequencyType.MINUTE;
export type ChangeCallback = (cron: Cron) => void;

export type IntervalEditorProps = typeof IntervalEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    period: IntervalPeriod
    cron: Cron,
    layout: any,
    excludes?: ExcludeField[],
    onChange: ChangeCallback
}

export class IntervalEditor extends Component<IntervalEditorProps> {

    static defaultProps = {
        cron: {
            frequencyType: undefined as any,
            daysOfWeek: [] as number[],
            timeInterval: {
                period: undefined as any,
            } as TimeInterval,
            timeZone: CoreUtils.systemTimeZone()
        } as Cron,
        excludes: [] as ExcludeField[]
    }

    constructor(props: IntervalEditorProps) {
        super(props);
        this.handleDaysOfWeekChange = this.handleDaysOfWeekChange.bind(this);
        this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
        this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
        this.handleTimezoneChange = this.handleTimezoneChange.bind(this);
        this.handleIntervalChange = this.handleIntervalChange.bind(this);
    }

    private handleDaysOfWeekChange(daysOfWeek: number[]): void {
        const cron = update(this.props.cron, {
            daysOfWeek: { $set: daysOfWeek }
        });
        this.props.onChange(cron);
    }

    private handleStartTimeChange(startDate: moment.Moment | null): void {
        const cron = update(this.props.cron, {
            startDate: { $set: CoreUtils.toStringTime(startDate, Constants.DATE_TIME_LOCAL_ISO_FORMAT) }
        });
        this.props.onChange(cron);
    }

    private handleEndTimeChange(endDate: moment.Moment | null): void {
        const cron = update(this.props.cron, {
            endDate: { $set: CoreUtils.toStringTime(endDate, Constants.DATE_TIME_LOCAL_ISO_FORMAT) }
        });
        this.props.onChange(cron);
    }

    private handleTimezoneChange(timeZone: string): void {
        const cron = update(this.props.cron, {
            timeZone: { $set: timeZone }
        });
        this.props.onChange(cron);
    }

    private handleIntervalChange(period: number): void {
        const cron = update(this.props.cron, {
            timeInterval: {
                period: { $set: period }
            }
        });
        this.props.onChange(cron);
    }

    private buildIntervalOptions(values: number[]): ReactElement[] {
        return values.map((value, index) => (
            <Select.Option key={index} value={values[index]}>{value}</Select.Option>
        ));
    }

    public componentDidMount(): void {
        if (this.props.cron === IntervalEditor.defaultProps.cron) {
            // Update if cron set with defaults.
            this.props.onChange(this.props.cron);
        }
    }

    public shouldComponentUpdate(nextProps: IntervalEditorProps): boolean {
        if (nextProps.period !== this.props.cron.frequencyType) {
            // Set default if frequency changes externally.
            let cron = IntervalEditor.defaultProps.cron;
            cron = update(cron, {
                frequencyType: { $set: nextProps.period }
            });
            this.props.onChange(cron);
        }
        return true;
    }

    public render(): ReactElement {
        return (
            <div className="x-intervaleditor">
                <FormItem
                    {...this.props.layout}
                    formRef={this.props.formRef}
                    label="Days of Week"
                    name="daysofweek"
                    info="The days of week."
                    rules={[{
                        required: true,
                        message: 'Please enter one or more months.'
                    }]}
                >
                    <DaysOfWeekSelector
                        value={this.props.cron.daysOfWeek}
                        onChange={this.handleDaysOfWeekChange}
                    />
                </FormItem>
                {!this.props.excludes.includes("time_zone") &&
                    <FormItem
                        {...this.props.layout}
                        formRef={this.props.formRef}
                        label="Time Zone"
                        name="timezone"
                        info="The time zone."
                        rules={[{
                            required: true,
                            message: 'Please enter a time zone.'
                        }]}
                    >
                        <TimezoneSelector
                            className="x-intervaleditor-zone"
                            value={this.props.cron.timeZone}
                            onChange={this.handleTimezoneChange}
                        />
                    </FormItem>
                }
                {!this.props.excludes.includes("start_time") &&
                    <FormItem
                        {...this.props.layout}
                        formRef={this.props.formRef}
                        label="Start Date"
                        name="startdate"
                        info="The date when the schedule will start. Leave empty to start immediately."
                    // rules={[{
                    //     required: true,
                    //     message: 'Please enter a start date.'
                    // }]}
                    >
                        <DatePicker
                            className="x-intervaleditor-time"
                            use12Hours
                            showTime
                            placeholder="Select a date."
                            format={Constants.DATE_TIME_DISPLAY_FORMAT}
                            value={CoreUtils.toMomentTime(this.props.cron.startDate, Constants.DATE_TIME_LOCAL_ISO_FORMAT)}
                            onChange={this.handleStartTimeChange}
                        />
                    </FormItem>
                }
                {!this.props.excludes.includes("end_time") &&
                    <FormItem
                        {...this.props.layout}
                        formRef={this.props.formRef}
                        label="End Date"
                        name="enddate"
                        info="The date when the schedule will end. Leave empty to run forever."
                    // rules={[{
                    //     required: true,
                    //     message: 'Please enter an end date.'
                    // }]}
                    >
                        <DatePicker
                            className="x-intervaleditor-time"
                            use12Hours
                            showTime
                            placeholder="Select a date."
                            format={Constants.DATE_TIME_DISPLAY_FORMAT}
                            value={CoreUtils.toMomentTime(this.props.cron.endDate, Constants.DATE_TIME_LOCAL_ISO_FORMAT)}
                            onChange={this.handleEndTimeChange}
                        />
                    </FormItem>
                }
                <FormItem
                    {...this.props.layout}
                    formRef={this.props.formRef}
                    label="Interval"
                    name="interval"
                    info="The time interval."
                    rules={[{
                        required: true,
                        message: 'Please enter an interval.'
                    }]}
                    postfix={
                        <span>{this.props.period === FrequencyType.HOUR ? "hours" : "minutes"}</span>
                    }
                >
                    <Select
                        className="x-intervaleditor-interval"
                        value={this.props.cron.timeInterval!.period}
                        onChange={this.handleIntervalChange}
                    >
                        {
                            this.props.period === FrequencyType.HOUR ?
                                this.buildIntervalOptions([1, 2, 3, 4, 6, 8, 12]) :
                                this.buildIntervalOptions([1, 2, 3, 4, 5, 6, 10, 12, 15, 20, 30])
                        }
                    </Select>
                </FormItem>
            </div>
        )
    }

}
