import React, { PureComponent, ReactElement } from 'react';
import { Checkbox, Col, FormInstance, Row, Select, Switch } from 'antd';
import { Calculator, RefType } from '@methodset/calculator-ts';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { FilterWidgetConfiguration, WidgetType } from '@methodset/application-client-ts';
import { RefEditor } from 'containers/Components/Widgets/RefEditor/RefEditor';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FormGroup } from 'components/FormGroup/FormGroup';
import { Info } from 'components/Info/Info';
import { WidgetUtils } from 'utils/WidgetUtils';
import { VariableSelector } from '../../Selectors/VariableSelector';
import { TextInput } from 'components/TextInput/TextInput';
import update from 'immutability-helper';
import './FilterWidgetEditor.less';

export type EditCallback = (isEditing: boolean) => void;
export type ChangeCallback = (configuration: FilterWidgetConfiguration, isEditing?: boolean) => void;

export type FilterWidgetEditorProps = typeof FilterWidgetEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    defaults: ReactElement,
    configuration?: FilterWidgetConfiguration,
    calculator: Calculator,
    onEdit: EditCallback,
    onChange: ChangeCallback
}

export class FilterWidgetEditor extends PureComponent<FilterWidgetEditorProps> {

    static DefaultConfiguration = {
        type: WidgetType.FILTER,
        height: 300,
        delimiter: ';'
    } as FilterWidgetConfiguration;

    static defaultProps = {
        configuration: FilterWidgetEditor.DefaultConfiguration
    }

    constructor(props: FilterWidgetEditorProps) {
        super(props);
        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.handleLabelChange = this.handleLabelChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleVariableChange = this.handleVariableChange.bind(this);
        this.handleHeadersChange = this.handleHeadersChange.bind(this);
        this.handleLayoutChange = this.handleLayoutChange.bind(this);
        this.handleDelimiterChange = this.handleDelimiterChange.bind(this);
    }

    private handleRangeChange(rangeId: string | undefined): void {
        let configuration = update(this.props.configuration, {
            rangeId: { $set: rangeId as any }
        });
        if (rangeId !== this.props.configuration.rangeId) {
            configuration = update(configuration, {
                labelRangeId: { $set: undefined as any },
                valueRangeId: { $set: undefined as any }
            });
        }
        this.props.onChange(configuration);
    }

    private handleLabelChange(labelRangeId: string | undefined): void {
        const configuration = update(this.props.configuration, {
            labelRangeId: { $set: labelRangeId! }
        });
        this.props.onChange(configuration);
    }

    private handleValueChange(valueRangeId: string | undefined): void {
        const configuration = update(this.props.configuration, {
            valueRangeId: { $set: valueRangeId! }
        });
        this.props.onChange(configuration);
    }

    private handleVariableChange(variableId: string): void {
        const configuration = update(this.props.configuration, {
            variableId: { $set: variableId }
        });
        this.props.onChange(configuration);
    }

    private handleHeadersChange(e: CheckboxChangeEvent): void {
        const hasHeaders = e.target.checked;
        const configuration = update(this.props.configuration, {
            hasHeaders: { $set: hasHeaders }
        });
        this.props.onChange(configuration);
    }

    private handleLayoutChange(e: CheckboxChangeEvent): void {
        const rowLayout = e.target.checked;
        const configuration = update(this.props.configuration, {
            rowLayout: { $set: rowLayout }
        });
        this.props.onChange(configuration);
    }

    private handleDelimiterChange(delimiter: string | undefined): void {
        const configuration = update(this.props.configuration, {
            delimiter: { $set: delimiter as any }
        });
        this.props.onChange(configuration);
    }

    public componentDidMount(): void {
        if (this.props.configuration === FilterWidgetEditor.DefaultConfiguration) {
            this.props.onChange(this.props.configuration);
        }
    }

    public render(): ReactElement {
        const vectors = WidgetUtils.rangeVectors(this.props.calculator, this.props.configuration.rangeId, this.props.configuration.hasHeaders);
        return (
            <Row gutter={Globals.FORM_GUTTER_ROW}>
                <Col span={12}>
                    {this.props.defaults}
                </Col>
                <Col span={12}>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        required={true}
                        label="Data Range"
                        name="range"
                        info="The range that includes the data that can be added to the dataset."
                    >
                        <RefEditor
                            formRef={this.props.formRef}
                            index={0}
                            calculator={this.props.calculator}
                            refTypes={[RefType.RANGE]}
                            refId={this.props.configuration.rangeId}
                            onChange={this.handleRangeChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        required={true}
                        label="Label Range"
                        name="label"
                        info="The range for the labels. Must be one dimensional range, i.e., a column or row."
                    >
                        <Select
                            value={this.props.configuration.labelRangeId}
                            allowClear={true}
                            onChange={this.handleLabelChange}
                        >
                            {vectors.map(vector => (
                                <Select.Option key={vector.id} value={vector.id}>{vector.label}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        required={true}
                        label="Value Range"
                        name="value"
                        info="The range for the values. Must be one dimensional range, i.e., a column or row."
                    >
                        <Select
                            value={this.props.configuration.valueRangeId}
                            allowClear={true}
                            onChange={this.handleValueChange}
                        >
                            {vectors.map(vector => (
                                <Select.Option key={vector.id} value={vector.id}>{vector.label}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Variable"
                        name="variable"
                        info="The variable to change when a selection is chosen."
                        valuePropName="variableId"
                        rules={[{
                            required: true,
                            message: 'Please select a variable.'
                        }]}
                    >
                        <VariableSelector
                            calculator={this.props.calculator}
                            variableId={this.props.configuration.variableId}
                            onChange={this.handleVariableChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Delimiter"
                        name="delimiter"
                        info="The character that will be used to separate the filter items when it is written to the variable."
                        rules={[{
                            required: true,
                            message: 'Please enter a delimiter.'
                        }]}
                    >
                        <TextInput
                            placeholder="Enter a delimiter."
                            value={this.props.configuration.delimiter}
                            onChange={this.handleDelimiterChange}
                        />
                    </FormItem>
                    <FormGroup>
                        <Checkbox
                            checked={this.props.configuration.hasHeaders}
                            onChange={this.handleHeadersChange}
                        >
                            <Info
                                label="Range Includes Headers"
                                info="True if the data range has header values in the first row or column. These will be used to label the value datasets."
                                bold={true}
                            />
                        </Checkbox>
                        <Checkbox
                            checked={this.props.configuration.rowLayout}
                            onChange={this.handleLayoutChange}
                        >
                            <Info
                                label="Range Layout as Rows"
                                info="True if the data is layed out in rows, false if the data is layed out in columns."
                                bold={true}
                            />
                        </Checkbox>
                    </FormGroup>
                </Col>
            </Row>
        );
    }

}
