import { ReactElement, useState } from 'react';
import { Button } from 'antd';
import { ButtonShape, ButtonType } from 'antd/lib/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import classNames from 'classnames';
import './Toggle.less';

export type ChangeCallback = (value: string) => void;

export type ToggleProps = {
    className?: string,
    labels?: (string | ReactElement)[],
    value: string,
    values: string[],
    disabled?: boolean,
    type?: ButtonType,
    shape?: ButtonShape,
    size?: SizeType,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    type: "dashed",
    shape: "circle",
    size: "middle",
    disabled: false
}

export const Toggle = (props: ToggleProps): ReactElement => {

    const findIndex = (value: string | number): number => {
        return props.values.findIndex(value => value === props.value);
    }

    const [index, setIndex] = useState<number>(findIndex(props.value));

    const handleClick = (): void => {
        let idx = findIndex(props.value) + 1;
        if (idx === props.values.length) {
            idx = 0;
        }
        setIndex(idx);
        props.onChange(props.values[idx]);
    }

    return (
        <Button
            className={classNames("x-toggle", props.className)}
            type={props.type}
            shape={props.shape}
            size={props.size}
            disabled={props.disabled}
            onClick={handleClick}
        >
            {props.labels ? props.labels[index] : props.values[index]}
        </Button>
    )
}

Toggle.defaultProps = defaultProps;

