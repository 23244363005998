import { ReactElement } from 'react';
import classNames from 'classnames';
import './Justify.less';

export type Justification = "left" | "center" | "right";
export type JustifyProps = {
    className?: string,
    children: any,
    justification: Justification,
    fill?: boolean
} & typeof defaultProps;

const defaultProps = {
    justification: "left",
    fill: false
}

export const Justify = (props: JustifyProps): ReactElement => {
    return (
        <div className={
            classNames(
                'x-justify',
                `x-justify-${props.justification}`,
                {"x-justify-fill": props.fill},
                props.className
            )}
        >
            {props.children}
        </div>
    )
}

Justify.defaultProps = defaultProps;

