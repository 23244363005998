import { ReactElement, useContext, useState } from 'react';
import { DeleteOutlined, EditOutlined, LayoutOutlined, DownOutlined, AlertOutlined, BookOutlined } from '@ant-design/icons';
import { ModelContext } from 'context/ModelContext';
import { Applet, Application, ApplicationType } from '@methodset/application-client-ts';
import { ColumnsType } from 'antd/lib/table';
import { Globals } from 'constants/Globals';
import { Link, RouteComponentProps } from 'react-router-dom';
import { CoreUtils } from 'utils/CoreUtils';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Tag } from 'antd';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { ItemSpec, MenuButton } from 'components/MenuButton/MenuButton';
import { Version } from '@methodset/commons-core-ts';
import { ModuleType, PackHeader } from '@methodset/library-client-ts';
import { v4 as uuid } from "uuid";
import { Calculator } from '@methodset/calculator-ts';
import { VersionPackager } from 'containers/Console/VersionEditor-REMOVE/VersionPackager/VersionPackager';
import applicationService from 'services/ApplicationService';
import modelService from 'services/ModelService';
import update from 'immutability-helper';
import './ApplicationList.less';

const colorMap = CoreUtils.toColormap(CoreUtils.enumToKeys(ApplicationType));

type MatchParams = {
    modelId: string,
    applicationId: string
}

export type ApplicationListProps = RouteComponentProps<MatchParams> & {
}

export const ApplicationList = (props: ApplicationListProps): ReactElement => {

    // The model context.
    const context = useContext(ModelContext);
    // The application being packaged.
    const [application, setApplication] = useState<Application | undefined>();

    const handlePackageShow = (application: Application): void => {
        setApplication(application);
    }

    const handlePackageDone = (header: PackHeader): void => {
        let applications = context.applications;
        const applicationId = header.moduleId;
        const index = applications.findIndex(application => application.id === applicationId);
        if (index !== -1) {
            applications = update(applications, {
                [index]: {
                    version: { $set: header.version }
                }
            });
            context.saveApplications(applications);
        }
        setApplication(undefined);
    }

    const handlePackageCancel = (): void => {
        setApplication(undefined);
    }

    const handleApplicationEdit = (application: Application): void => {
        const modelId = props.match.params.modelId;
        const url = RouteBuilder.application(modelId, application);
        props.history.push(url);
    }

    const handleApplicationDelete = (application: Application): void => {
        //let applications = context.applications;
        //const applicationId = application.id;
        deleteApplicationRequest(application.id);

        // // Remove the application.
        // const index = applications.findIndex(application => application.id === applicationId);
        // if (index === -1) {
        //     return;
        // }
        // applications = update(applications, {
        //     $splice: [[index, 1]]
        // });

        // // Remove all references to the application.
        // for (let i = 0; i < applications.length; i++) {
        //     let application = applications[i];
        //     if (application.type === ApplicationType.APPLET) {
        //         let applet = application as Applet;
        //         applet = Applet.removeDependency(applet, applicationId);
        //         applications = update(applications, {
        //             [i]: { $set: applet }
        //         });
        //     }
        // }

        // // TODO: remove application from dao

        // context.saveApplications(applications);
    }

    const handleAppletCreate = (): void => {
        const modelId = props.match.params.modelId;
        props.history.push(RouteBuilder.applet(modelId, uuid()));
    }

    const handleAlertCreate = (): void => {
        const modelId = props.match.params.modelId;
        props.history.push(RouteBuilder.alert(modelId, uuid()));
    }

    const handleModelSave = (): Promise<any> => {
        const model = context.model!;
        const calculator = context.calculator!;
        const request = {
            modelId: model.id,
            name: model.name,
            description: model.description,
            keywords: model.keywords,
            autoSave: model.autoSave,
            //applications: model.applications,
            calculator: Calculator.serialize(calculator)
        };
        return modelService.updateModel(request,
            (response: any) => saveModelResponse(response),
            undefined, true
        );
    }

    const saveModelResponse = (response: any): void => {
        // noop
    }

    const deleteApplicationRequest = (applicationId: string): Promise<any> => {
        const request = {
            applicationId: applicationId
        };
        return applicationService.archiveApplication(request,
            (response: any) => deleteApplicationResponse(response)
        );
    }

    const deleteApplicationResponse = (response: any): void => {
        const applicationId = response.data.applicationId;
        let applications = context.applications;
        const index = applications.findIndex(application => application.id === applicationId);
        if (index !== -1) {
            applications = update(applications, {
                $splice: [[index, 1]]
            });
        }

        // Remove all references to the application.
        for (let i = 0; i < applications.length; i++) {
            let application = applications[i];
            if (application.type === ApplicationType.APPLET) {
                let applet = application as Applet;
                applet = Applet.removeDependency(applet, applicationId);
                applications = update(applications, {
                    [i]: { $set: applet }
                });
            }
        }

        context.saveApplications(applications);
        context.saveApplication(undefined);
    }

    const buildColumns = (): ColumnsType<any> => {
        let i = 0;
        //const model: Model = context.model!;
        const modelId = props.match.params.modelId;
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
            ellipsis: true,
            width: Globals.TABLE_WIDTH_LARGE,
            render: (value: string, application: Application) => (
                <Link to={RouteBuilder.application(modelId, application)}>{application.name}</Link>
            ),
            sorter: (a: any, b: any) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'description',
            title: 'Description',
            ellipsis: true,
            dataIndex: 'description',
            render: (value: string, application: Application) => {
                return (
                    <span>{application.description ?? Globals.EMPTY_FIELD}</span>
                );
            },
            sorter: (a: any, b: any) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'type',
            title: 'Type',
            align: 'center',
            width: Globals.TABLE_WIDTH_MEDIUM,
            render: (value: string, application: Application) => {
                return (
                    <Tag color={colorMap[application.type]}>{CoreUtils.toProper(application.type)}</Tag>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.type, b.type),
            sortDirections: ['ascend', 'descend']
        }, {
            key: 'version',
            title: 'Latest Version',
            align: 'center',
            width: Globals.TABLE_WIDTH_MEDIUM,
            render: (value: string, application: Application) => {
                return (
                    <span>
                        {CoreUtils.toVersion(application.version)}
                    </span>
                );
            },
            sorter: (a: any, b: any) => CoreUtils.compareNumbers(a.version, b.version),
            sortDirections: ['descend', 'ascend']
        }];
        return columns;
    }

    const items: ItemSpec[] = [{
        icon: <LayoutOutlined />,
        label: "New applet",
        onSelect: () => handleAppletCreate()
    }, {
        icon: <AlertOutlined />,
        label: "New alert",
        onSelect: () => handleAlertCreate()
    }];

    const extra = (
        <MenuButton
            label="New Application"
            items={items}
            icon={<DownOutlined />}
        />
    );

    const actions = [{
        icon: <EditOutlined />,
        label: (application: Application) => `Edit ${application.type.toLowerCase()}`,
        callback: handleApplicationEdit
    }, {
        icon: <BookOutlined />,
        label: "Package version...",
        callback: handlePackageShow
    }, {
        icon: <DeleteOutlined />,
        label: (application: Application) => `Delete ${application.type.toLowerCase()}`,
        confirm: (application: Application) => `Are you sure you want to delete the ${application.type.toLowerCase()}?`,
        callback: handleApplicationDelete
    }];

    const columns = buildColumns();
    const data = context.applications;
    return (
        <>
            <ItemTable
                columns={columns}
                items={data}
                actions={actions}
                extra={extra}
                loadingRows={4}
            />
            {application &&
                <VersionPackager
                    id={application.id}
                    name={application.name}
                    description={application.description}
                    moduleId={application.id}
                    moduleType={ModuleType.APPLICATION}
                    categoryType={application.categoryType}
                    onBefore={handleModelSave}
                    onPackage={handlePackageDone}
                    onCancel={handlePackageCancel}
                />
            }
        </>
    );

}
