import { ReactElement } from 'react';
import { Select } from 'antd';
import { AppletPanel, Widget } from '@methodset/application-client-ts';

export type ChangeCallback = (widgetId: string) => void;

export type WidgetSelectorProps = {
    className?: string,
    panel: AppletPanel,
    widget?: Widget,
    widgetId?: string,
    onChange: ChangeCallback
};

export const WidgetSelector = (props: WidgetSelectorProps): ReactElement => {

    return (
        <Select
            className={props.className}
            allowClear={true}
            placeholder="Select a widget."
            value={props.widgetId}
            onChange={(widgetId) => props.onChange(widgetId)}
        >
            {props.panel.widgets.filter(w => w.id !== props.widget?.id).map(w => (
                <Select.Option key={w.id} value={w.id}>{w.name}</Select.Option>
            ))}
        </Select>
    )
}
