import { ReactElement } from 'react';
import { TextInput } from 'components/TextInput/TextInput';
import classNames from 'classnames';
import './InlineText.less';

export type ChangeCallback = (value: any) => void;

export type InlineTextProps = {
    className?: string,
    value?: string,
    onChange: ChangeCallback
}

/**
 * A text field that can be edited inline.
 */
export const InlineText = (props: InlineTextProps): ReactElement => {
    return (
        <TextInput
            className={classNames('x-inlinetext', props.className)}
            bodyClassName={props.className}
            value={props.value}
            onChange={props.onChange}
        />
    );
}

