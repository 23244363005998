import { ReactElement } from 'react';
import { Spacer } from 'components/Spacer/Spacer';
import { Label } from 'components/Label/Label';
import { Select, Switch } from 'antd';
import { Globals } from 'constants/Globals';
import { CoreUtils } from 'utils/CoreUtils';
import { Width } from '@methodset/application-client-ts';
import './WidthEditor.less';

export type ChangeCallback = (width: Width | undefined) => void;

export type WidthEditorProps = {
    value?: number | string,
    onChange: ChangeCallback
};

export const WidthEditor = (props: WidthEditorProps): ReactElement => {

    const handleFillChange = (fill: boolean): void => {
        const width = fill ? "FILL" : undefined;
        props.onChange(width);
    }

    const handleSpanChange = (span: number): void => {
        props.onChange(span);
    }

    const isFit = (width: string | number | undefined): boolean => {
        return width === "FIT";
    }

    const isFill = (width: string | number | undefined): boolean => {
        return width === "FILL";
    }

    const toSpan = (width: string | number | undefined): number | undefined => {
        return CoreUtils.isNumber(width) ? width : undefined;
    }

    return (
        <Spacer className="x-widtheditor-item" size="lg">
            <Label label="Fill" bold={false}>
                <Switch
                    checked={isFill(props.value)}
                    onChange={(checked) => handleFillChange(checked)}
                />
            </Label>
            <Label label="Span" bold={false}>
                <Select
                    className="x-widtheditor-span"
                    allowClear={true}
                    value={toSpan(props.value)}
                    onChange={handleSpanChange}
                >
                    {Array.from(Array(Globals.LAYOUT_COLUMNS).keys()).map(index => (
                        <Select.Option key={index} value={index + 1}>{index + 1}</Select.Option>
                    ))}
                </Select>
            </Label>
        </Spacer>
    )
}
