import { PureComponent, ReactElement } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Modal, Space, message } from 'antd';
import './InitEmail.less';

export type CloseCallback = () => void;

export type InitEmailProps = {
    className?: string,
    name: string,
    address: string,
    onClose: CloseCallback
}

export class InitEmail extends PureComponent<InitEmailProps> {

    public render(): ReactElement {
        return (
            <Modal
                centered
                title="Workflow Email Address"
                visible={true}
                width={720}
                onCancel={this.props.onClose}
                footer={(
                    <Space>
                        <CopyToClipboard text={this.props.address}
                            onCopy={() => message.success("Copied email address!", 2)}
                        >
                            <Button>
                                Copy Email
                            </Button>
                        </CopyToClipboard>
                        <Button type="primary" onClick={this.props.onClose}>Close</Button>
                    </Space>
                )}
            >
                <p>
                    Initiate the "{this.props.name}" workflow by sending
                    an email to the following address:
                </p>
                <div style={{textAlign: "center"}}><strong>{this.props.address}</strong></div>
            </Modal>
        );
    }

}
