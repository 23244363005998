import { PureComponent, ReactElement } from 'react';
import { Select } from 'antd';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { StatusType } from 'constants/StatusType';
import { LoadSelect } from 'components/LoadSelect/LoadSelect';
import { Version } from '@methodset/commons-core-ts';
import axios from 'axios';
import classNames from 'classnames';
import endpointService from 'services/EndpointService';
import './QueryVersionPicker.less';

export type FilterFunction = (version: number, count: number) => boolean;
export type ChangeCallback = (version: number) => void;

export type QueryVersionPickerState = {
    status: StatusType,
    versions: number[]
}

export type QueryVersionPickerProps = typeof QueryVersionPicker.defaultProps & {
    className?: string,
    queryId?: string,
    version?: number,
    filter?: FilterFunction,
    onChange: ChangeCallback
}

export class QueryVersionPicker extends PureComponent<QueryVersionPickerProps, QueryVersionPickerState> {

    static defaultProps = {
    }

    constructor(props: QueryVersionPickerProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            versions: []
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleVersionChange = this.handleVersionChange.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleVersionChange(version: number): void {
        this.props.onChange(version);
    }

    private readQueryVersionsRequest(): Promise<any> {
        if (!this.props.queryId) {
            return Promise.resolve(true);
        }
        const request = {
            queryId: this.props.queryId
        };
        return endpointService.readQueryVersions(request,
            (response: any) => this.readQueryVersionsResponse(response),
            undefined, true
        );
    }

    private readQueryVersionsResponse(response: any): void {
        let versions = response.data.versions;
        if (!versions || versions.length === 0) {
            versions = [Version.EDITOR];
        } else {
            versions.sort((a: number, b: number) => b - a);
        }
        if (!CoreUtils.isEmpty(this.props.version) && !versions.includes(this.props.version)) {
            // Sanity check, unset version if not in list of available versions.
            this.props.onChange(undefined as any);
        }
        this.setState({ versions: versions });
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readQueryVersionsRequest());
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    private buildModelView(): ReactElement {
        return (
            <LoadSelect
                placeholder="Select a version."
                value={this.props.version}
                status={this.state.status}
                onChange={this.handleVersionChange}
                onRetry={this.handleRetryLoad}
            >
                {this.state.versions.filter((version) => this.props.filter ? this.props.filter(version, this.state.versions.length) : true).map(version => (
                    <Select.Option key={version} value={version}>{CoreUtils.toVersion(version)}</Select.Option>
                ))}
            </LoadSelect>
        );
    }

    public componentDidMount(): void {
        if (this.props.queryId) {
            this.loadData();
        } else {
            this.setState({ status: StatusType.READY });
        }
    }

    public componentDidUpdate(prevProps: QueryVersionPickerProps): void {
        if (prevProps.queryId !== this.props.queryId) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        const view = this.buildModelView();
        return (
            <div className={classNames('x-queryversionpicker', this.props.className)}>
                {view}
            </div>
        );
    }

}
