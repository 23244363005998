import { Button, Space } from 'antd';
import { ReactElement } from 'react';
import './EditorHeader.less';

export type CancelCallback = () => void;
export type DoneCallback = () => void;
export type ErrorCallback = () => void;

export type EditorHeaderProps = {
    className?: string,
    title: string,
    onCancel?: CancelCallback,
    onDone?: DoneCallback
} & typeof defaultProps;

const defaultProps = {
}

export const EditorHeader = (props: EditorHeaderProps): ReactElement => {
    return (
        <div className="x-editorheader">
            <span className="x-editorheader-title">{props.title}</span>
            <Space className="x-editorheader-actions">
                <Button onClick={props.onCancel}>Cancel</Button>
                <Button type="primary" htmlType="submit">Done</Button>
            </Space>

        </div>
    )
}

EditorHeader.defaultProps = defaultProps;
