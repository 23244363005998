import { PureComponent, ReactElement } from 'react';
import { Button, Space, Tag } from 'antd';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { ColumnsType } from 'antd/lib/table';
import { CoreUtils } from 'utils/CoreUtils';
import { AuthenticationHeader, Component, Configuration, ConfigurationSpec, Data, ProcessorHeader, ProcessorType } from '@methodset/endpoint-client-ts';
import { ProcessorEditor } from './ProcessorEditor/ProcessorEditor';
import { ButtonLink } from 'components/ButtonLink/ButtonLink';
import { DeleteOutlined, DownOutlined, EditOutlined, UpOutlined } from '@ant-design/icons';
import { RestUtils } from 'utils/RestUtils';
import { DataPreview } from '../DataPreview/DataPreview';
import { ConfigurationDialog } from '../ConfigurationDialog/ConfigurationDialog';
import { Globals } from 'constants/Globals';
import endpointService from 'services/EndpointService';
import update from 'immutability-helper';
import './Processors.less';

const COLOR_MAP = CoreUtils.toColormap(CoreUtils.enumToKeys(ProcessorType));

export type EditCallback = (isEditing: boolean) => void;
export type UpdateCallback = (configuration: Configuration) => void;
export type ChangeCallback = (components: Component[], removed?: Component) => void;

export type ProcessorsProps = typeof Processors.defaultProps & {
    className?: string
    headers: ProcessorHeader[],
    components: Component[],
    variableSpecs: ConfigurationSpec[],
    configuration?: Configuration,
    authentications: AuthenticationHeader[],
    allowAssignment?: boolean,
    allowTest?: boolean,
    serialFlow: boolean,
    // Called when editing is starting and ending.
    onEdit?: EditCallback,
    onUpdate: UpdateCallback,
    onChange: ChangeCallback
}

export type ProcessorsState = {
    // The index of the edited component.
    index: number | null,
    // Component being edited.
    component: Component | null,
    // If data preview is being loaded.
    isRunning: boolean,
    // True when showing the query input dialog.
    showQuery: boolean,
    // The data to preview.
    previewData: Data | null,
    // Error message on loading test records.
    error: Error | null
}

export class Processors extends PureComponent<ProcessorsProps, ProcessorsState> {

    static defaultProps = {
        allowAssignment: true,
        allowTest: true,
        serialFlow: false
    }

    constructor(props: ProcessorsProps) {
        super(props);
        this.state = {
            index: null,
            component: null,
            isRunning: false,
            showQuery: false,
            previewData: null,
            error: null
        };
        this.handleProcessorCreate = this.handleProcessorCreate.bind(this);
        this.handleProcessorDelete = this.handleProcessorDelete.bind(this);
        this.handleProcessorEdit = this.handleProcessorEdit.bind(this);
        this.handleProcessorMoveUp = this.handleProcessorMoveUp.bind(this);
        this.handleProcessorMoveDown = this.handleProcessorMoveDown.bind(this);
        this.handleProcessorsTest = this.handleProcessorsTest.bind(this);
        this.handleEditorDone = this.handleEditorDone.bind(this);
        this.handleEditorCancel = this.handleEditorCancel.bind(this);
        this.handleQueryExecute = this.handleQueryExecute.bind(this);
        this.handleQueryCancel = this.handleQueryCancel.bind(this);
        this.handlePreviewClose = this.handlePreviewClose.bind(this);
    }

    private handleProcessorCreate(): void {
        this.setState({
            component: null,
            index: -1
        });
        if (this.props.onEdit) {
            this.props.onEdit(true);
        }
    }

    private handleProcessorDelete(component: any, index: number): void {
        const components = update(this.props.components, {
            $splice: [[index, 1]]
        });
        this.props.onChange(components, component);
    }

    private handleProcessorEdit(component: Component, index: number): void {
        this.setState({
            component: component,
            index: index
        });
        if (this.props.onEdit) {
            this.props.onEdit(true);
        }
    }

    private handleProcessorMoveUp(component: Component, index: number): void {
        const components = update(this.props.components, {
            $splice: [[index, 1], [index - 1, 0, component]]
        });
        this.props.onChange(components);
    }

    private handleProcessorMoveDown(component: Component, index: number): void {
        const components = update(this.props.components, {
            $splice: [[index, 1], [index + 1, 0, component]]
        });
        this.props.onChange(components);
    }

    private handleProcessorsTest(): void {
        if (this.props.variableSpecs.length !== 0) {
            this.setState({ showQuery: true });
        } else {
            this.loadTestDataRequest({});
        }
    }

    private handleEditorDone(component: Component, index: number): void {
        let components;
        if (index !== -1) {
            components = update(this.props.components, {
                [index]: { $set: component }
            });
        } else {
            components = update(this.props.components, {
                $push: [component]
            });
        }
        this.props.onChange(components);
        this.setState({
            component: null,
            index: null
        });
        if (this.props.onEdit) {
            this.props.onEdit(false);
        }
    }

    private handleEditorCancel(): void {
        this.setState({
            component: null,
            index: null
        });
        if (this.props.onEdit) {
            this.props.onEdit(false);
        }
    }

    private handleQueryExecute(configuration: Configuration): void {
        this.props.onUpdate(configuration);
        this.setState({
            isRunning: true,
            showQuery: false,
            error: null
        });
        this.loadTestDataRequest(configuration);
    }

    private handleQueryCancel(): void {
        this.setState({ showQuery: false });
    }

    private handlePreviewClose(): void {
        this.setState({ previewData: null });
    }

    private loadTestDataRequest(configuration: Configuration): Promise<any> {
        this.setState({
            isRunning: true,
            error: null
        });
        const request = {
            components: this.props.components,
            configurationSpecs: this.props.variableSpecs,
            configuration: configuration
        };
        return endpointService.loadTestQueryOutput(request,
            (response: any) => this.loadTestDataResponse(response),
            (response: any) => this.loadTestDataException(response),
            true
        );
    }

    private loadTestDataResponse(response: any): void {
        const previewData = response.data.data;
        this.setState({
            isRunning: false,
            previewData: previewData,
            error: null
        });
    }

    private loadTestDataException(response: any): void {
        const error = RestUtils.getError(response);
        this.setState({
            isRunning: false,
            error: error
        });
    }

    private buildColumns(): ColumnsType<any> {
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            width: 200,
            render: (_, component: Component, index: number) => {
                return (
                    <ButtonLink
                        onClick={() => this.handleProcessorEdit(component, index)}
                    >
                        {component.name}
                    </ButtonLink>
                )
            }
        }, {
            key: 'description',
            title: 'Description',
            ellipsis: true,
            render: (component: Component) => {
                return (
                    <span>{component.description ? component.description : Globals.EMPTY_FIELD}</span>
                );
            }
        }, {
            key: 'type',
            title: 'Type',
            align: 'center',
            width: 150,
            render: (component: Component) => {
                return (
                    <Tag color={COLOR_MAP[component.type]}>
                        {component.type}
                    </Tag>
                );
            }
        }];
        return columns;
    }

    private buildData(): any {
        return this.props.components;
    }

    public render(): ReactElement {
        const actions = [{
            icon: <EditOutlined />,
            label: "Edit processor",
            callback: this.handleProcessorEdit
        }, {
            icon: <DeleteOutlined />,
            label: "Delete processor",
            confirm: "Are you sure you want to delete the processor?",
            callback: this.handleProcessorDelete
        }, {
            icon: <UpOutlined />,
            label: "Move up",
            disabled: (component: any, index: number) => {
                return index === 0;
            },
            callback: this.handleProcessorMoveUp
        }, {
            icon: <DownOutlined />,
            label: "Move down",
            disabled: (component: any, index: number) => {
                return index === this.props.components.length - 1;
            },
            callback: this.handleProcessorMoveDown
        }];
        return (
            <div>
                {CoreUtils.isEmpty(this.state.index) &&
                    <div>
                        <ItemTable
                            className="x-processors"
                            size="small"
                            pagination={false}
                            columns={this.buildColumns()}
                            items={this.buildData()}
                            actions={actions}
                        />
                        <Space className="x-processors-add">
                            <Button onClick={this.handleProcessorCreate}>
                                Add Processor
                            </Button>
                            {this.props.allowTest &&
                                <Button
                                    loading={this.state.isRunning}
                                    disabled={this.props.components.length === 0}
                                    onClick={this.handleProcessorsTest}
                                >
                                    Test Processors
                                </Button>
                            }
                            {this.state.error &&
                                <div className="x-processor-error">{this.state.error.message}</div>
                            }
                        </Space>
                    </div>
                }
                {!CoreUtils.isEmpty(this.state.index) &&
                    <ProcessorEditor
                        types={[
                            ProcessorType.READER,
                            ProcessorType.WRITER,
                            ProcessorType.PARSER,
                            ProcessorType.TRANSFORMER,
                            ProcessorType.FORMATTER,
                            ProcessorType.MERGER
                        ]}
                        variableSpecs={this.props.variableSpecs}
                        headers={this.props.headers}
                        components={this.props.components}
                        component={this.state.component}
                        index={this.state.index!}
                        allowAssignment={this.props.allowAssignment}
                        onDone={this.handleEditorDone}
                        onCancel={this.handleEditorCancel}
                    />
                }
                {this.state.showQuery &&
                    <ConfigurationDialog
                        title="Input Variables"
                        configuration={this.props.configuration}
                        configurationSpecs={this.props.variableSpecs}
                        authentications={this.props.authentications}
                        optionalLabel="Optional Variables"
                        onChange={this.handleQueryExecute}
                        onCancel={this.handleQueryCancel}
                    />
                }
                {this.state.previewData != null &&
                    <DataPreview
                        data={this.state.previewData}
                        onClose={this.handlePreviewClose}
                    />
                }
            </div>

        );
    }

}
