import { FC, ReactElement } from 'react';
import { Dropdown, Menu } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, DeleteOutlined, FormatPainterOutlined } from '@ant-design/icons';
import { Format, LocationType, Sheet } from '@methodset/calculator-ts';
import { FormatMenu } from '../FormatMenu/FormatMenu';
import './ColumnHeader.less';

export type ColumnHeaderProps = {
    id: string,
    sheet: Sheet
}

export const ColumnHeader: FC<ColumnHeaderProps> = (props: ColumnHeaderProps): ReactElement => {

    const handleColumnLeftInsert = (): void => {
        props.sheet.insertColumn(props.id, LocationType.LEFT);
    }

    const handleColumnRightInsert = (): void => {
        props.sheet.insertColumn(props.id, LocationType.RIGHT);
    }

    const handleColumnFormat = (format: Format | undefined): void => {
        const column = props.sheet.getColumn(props.id);
        column.format = format;
    }

    const handleColumnDelete = (): void => {
        props.sheet.deleteColumn(props.id);
    }

    const menu = (): ReactElement => {
        const column = props.sheet.getColumn(props.id, false);
        return (
            <Menu>
                <Menu.Item key="insert-left" icon={<ArrowLeftOutlined />} onClick={handleColumnLeftInsert}>
                    <span>Insert column to left</span>
                </Menu.Item>
                <Menu.Item key="insert-right" icon={<ArrowRightOutlined />} onClick={handleColumnRightInsert}>
                    <span>Insert column to right</span>
                </Menu.Item>
                <Menu.SubMenu key="format" icon={<FormatPainterOutlined />} title="Format column">
                    <FormatMenu
                        format={column ? column.format : undefined}
                        onChange={handleColumnFormat}
                    />
                </Menu.SubMenu>
                <Menu.Item key="delete" icon={<DeleteOutlined />} onClick={handleColumnDelete}>
                    <span>Delete column</span>
                </Menu.Item>
            </Menu>
        );
    }

    return (
        <Dropdown overlay={menu} trigger={['contextMenu']}>
            <div className="x-columnheader">
                <span>{props.id}</span>
            </div>
        </Dropdown>
    );

}
