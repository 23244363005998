import { FC, ReactElement, useState } from 'react';
import { Alert, AlertState, ApplicationRef, Inputs, InstallationHeader } from '@methodset/application-client-ts';
import { Calculator } from '@methodset/calculator-ts';
import { RestUtils } from 'utils/RestUtils';
import { AlertSetup, ButtonSpec } from '../AlertSetup/AlertSetup';
import { TextMessage } from 'components/TextMessage/TextMessage';
import { IdUtils, Version } from '@methodset/commons-core-ts';
import update from 'immutability-helper';
import applicationService from 'services/ApplicationService';
import './AlertConfiguration.less';

export type CloseCallback = () => void;

export type AlertConfigurationProps = {
    header: InstallationHeader,
    onClose: CloseCallback
}

export const AlertConfiguration: FC<AlertConfigurationProps> = (props: AlertConfigurationProps): ReactElement => {

    // True when the alert is being saved.
    const [isSaving, setIsSaving] = useState<boolean>(false);
    // The alert being edited.
    const [alert, setAlert] = useState<Alert>(null as any);
    // The calculator used for the setup.
    const [calculator, setCalculator] = useState<Calculator>(null as any);
    // The state of the alert.
    const [state, setState] = useState<AlertState>(null as any);
    // The error, if one has occurred.
    const [error, setError] = useState<Error>();

    const handleInputsChange = (inputs: Inputs): void => {
        const updated = update(state, {
            conditions: { $set: inputs.conditions },
            configuration: { $set: inputs.configuration }
        });
        setState(updated);
    }

    const handleSave = (): Promise<any> => {
        return updateStateRequest();
    }

    const handleSetupCancel = (): void => {
        props.onClose();
    }

    const updateStateRequest = (): Promise<any> => {
        //const summary = InstructionsUtils.buildText(alert, calculator, state.configuration, state.conditions);
        //const summary = createSummary();
        // const updated = update(state, {
        //     summary: { $set: summary }
        // });
        setIsSaving(true);
        setError(undefined);
        const request = {
            installationId: props.header.id,
            state: state
        }
        return applicationService.updateInstallationState(request,
            (response: any) => updateStateResponse(response),
            (response: any) => updateStateException(response),
            true
        );
    }

    const updateStateResponse = (response: any): void => {
        setIsSaving(false);
        props.onClose();
    }

    const updateStateException = (response: any): void => {
        const error = RestUtils.getError(response);
        setError(error);
        setIsSaving(false);
        // If state update fails, the workflow will be disabled.
        const updated = update(state, {
            isEnabled: { $set: false }
        });
        setState(updated);
    }

    const loadApplicationRequest = (): Promise<any> => {
        //const applicationRef = props.applicationRef;
        const applicationId = props.header.applicationId;
        const request = {
            applicationId: applicationId,
            //version: applicationRef.version,
            version: IdUtils.isEditId(applicationId) ? Version.EDITOR : Version.LATEST,
            //modelId: applicationRef.modelId
        };
        return applicationService.loadApplication(request,
            (response: any) => loadApplicationResponse(response),
            undefined, true
        );
    }

    const loadApplicationResponse = (response: any): void => {
        const alert = response.data.application as Alert;
        const calculator = Calculator.deserialize(response.data.calculator);
        calculator.httpHeaders = RestUtils.getHttpHeaders();
        calculator.context.requestKey = 0;
        calculator.execute();
        setAlert(alert);
        setCalculator(calculator);
    }

    const readAlertStateRequest = (): Promise<any> => {
        const request = {
            installationId: props.header.id
        };
        return applicationService.readAlertState(request,
            (response: any) => readAlertStateResponse(response),
            undefined, true
        );
    }

    const readAlertStateResponse = (response: any): void => {
        let state = response.data.state as AlertState;
        // Add default values if not present.
        if (!state.configuration) {
            state.configuration = {};
        }
        if (!state.conditions) {
            state.conditions = [];
        }
        setState(state);
    }

    // TODO: store in installation?
    // const createSummary = (): string => {
    //     const conditions = state.conditions;
    //     const summary: string[] = [];
    //     const instructions = alert.instructions!;
    //     const callback = (section: Section, index: number): void => {
    //         switch (section.type) {
    //             case SectionType.TEXT: {
    //                 const part = section as TextSection;
    //                 summary.push(part.content);
    //                 break;
    //             }
    //             case SectionType.INPUT: {
    //                 const part = section as InputSection;
    //                 summary.push(state.configuration[part.variableId]);
    //                 break;
    //             }
    //             case SectionType.CONDITION: {
    //                 const part = section as ConditionSection;

    //                 const variable = calculator.variables.get(part.variableId, false);

    //                 let index = conditions.findIndex(condition => condition.variableId === part.variableId && condition.opType === part.opType);
    //                 if (index !== -1) {
    //                     const condition = InstructionsUtils.findCondition(part.variableId, part.opType, state.conditions);
    //                     if (condition) {
    //                         summary.push(OpType.label(condition.opType!));
    //                         // TODO: format (see FormattedInput)
    //                         summary.push(condition.value);
    //                     }
    //                 }
    //                 break;
    //             }
    //             default:
    //         }
    //     }
    //     InstructionsUtils.visitSections(instructions, callback);
    //     return summary.join(" ");
    // }

    // const generateCondition = (condition: Condition, template: Condition): string[] => {
    //     const parts = [];
    //     if (template?.opType) {
    //         parts.push(OpType.label(template.opType));
    //     } else {
    //         parts.push(condition.opType);
    //     }
    //     if (!condition.variableId || !CoreUtils.isEmpty(template?.value)) {
    //         // TODO: format (see FormattedInput)
    //         parts.push(template?.value);
    //     } else {
    //         parts.push(condition.value);
    //     }
    //     return parts;
    // }

    const buttons: ButtonSpec[] = [{
        role: "close",
        label: "Cancel",
    }, {
        role: "test"
    }, {
        role: "done"
    }, {
        role: "custom",
        label: "Save",
        primary: true,
        visible: (isResult) => !isResult,
        loading: () => isSaving,
        onClick: handleSave
    }];
    const extra = !!error ? (
        <TextMessage
            className="x-alertconfiguration-extra"
            type="error"
            size="lg"
            message={error.message}
        />
    ) : !state?.isEnabled ? (
        <TextMessage
            className="x-alertconfiguration-extra"
            type="warning"
            size="lg"
            message="This alert is not running. Please save your changes to enable it."
        />
    ) : undefined;
    const inputs = state ? {
        conditions: state.conditions ?? [],
        configuration: state.configuration ?? {}
    } : undefined;

    return (
        <div className="x-alertconfiguration">
            <AlertSetup
                title="Alert Setup"
                alert={alert}
                calculator={calculator}
                inputs={inputs}
                buttons={buttons}
                extra={extra}
                loaders={[
                    loadApplicationRequest,
                    readAlertStateRequest
                ]}
                onChange={handleInputsChange}
                onClose={handleSetupCancel}
            />
        </div>
    );

}
