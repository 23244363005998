import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { Button, Form, FormInstance, Modal, Result } from 'antd';
import { Alert, Inputs, LogicalType, OpType } from '@methodset/application-client-ts';
import { Configuration } from '@methodset/model-client-ts';
import { Date, String, Time } from '@methodset/commons-core-ts';
import { AlertInputs } from './AlertInputs/AlertInputs';
import { Calculator, DataConverter, DataType, DateFormatType, Formatter, FormulaError, TimeFormatType } from '@methodset/calculator-ts';
import { CoreUtils } from 'utils/CoreUtils';
import { TextMessage } from 'components/TextMessage/TextMessage';
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { NotificationViewer } from './NotificationViewer/NotificationViewer';
import { WidgetUtils } from 'utils/WidgetUtils';
import axios from 'axios';
import './AlertSetup.less';

export type ActivateFunction = (isResult: boolean) => boolean;
export type ClickCallback = () => Promise<any> | void;
export type ButtonRole = "close" | "test" | "done" | "custom";
export type ButtonSpec = {
    role: ButtonRole,
    label?: string,
    primary?: boolean,
    loading?: ActivateFunction,
    visible?: ActivateFunction,
    onClick?: ClickCallback
}

export type LoadFunction = () => Promise<any>;
export type ChangeCallback = (inputs: Inputs) => void;
export type CloseCallback = () => void;

/**
 * Must pass in either as set of loaders or inputs/alert/calculator.
 */
export type AlertSetupProps = {
    // The title of the setup dialog.
    title: string,
    // The inputs to the alert.
    inputs?: Inputs,
    // The alert to setup.
    alert?: Alert,
    // The calculator for the model.
    calculator?: Calculator,
    // The buttons to display on the dialog.
    buttons: ButtonSpec[],
    // Any extra UI elements to display on the dialog.
    extra?: ReactElement | ReactElement[],
    // Loading functions to execute before the final dialog is displayed.
    // These can be used to load the alert and calculator and then pass
    // them as props when available.
    loaders?: LoadFunction[],
    // Called when inputs change.
    onChange: ChangeCallback,
    // Called with the dialog closes.
    onClose: CloseCallback
}

export const AlertSetup: FC<AlertSetupProps> = (props: AlertSetupProps): ReactElement => {

    // The form reference.
    const formRef = useRef<FormInstance>(null);
    // The data loading status.
    const [status, setStatus] = useState<string>(Globals.STATUS_INIT);
    // The stored configuration during testing.
    const configuration = useRef<Configuration | undefined>();
    // True when the alert is being executed.
    const [isExecuting, setIsExecuting] = useState<boolean>(false);
    // The result of the alert conditions.
    const [result, setResult] = useState<boolean | undefined>();
    // The snapshot of the calculator data after running a test.
    const [snapshot, setSnapshot] = useState<Calculator | undefined>();
    // An operation error.
    const [error, setError] = useState<Error | undefined>();

    useEffect(() => {
        loadData();
    }, []);

    const handleRetryLoad = (): void => {
        loadData();
    }

    const handleInputsChange = (inputs: Inputs): void => {
        setError(undefined);
        props.onChange(inputs);
    }

    const handleClose = (): void => {
        props.onClose();
    }

    const handleTest = (): void => {
        setError(undefined);
        formRef.current?.validateFields().then(values => {
            testAlert();
        }).catch((e: Error) => {
            setError(new Error("Please fill in all values."));
        });
    }

    const handleDone = (): void => {
        setResult(undefined);
        setSnapshot(undefined);
    }

    const handleCustom = (callback?: ClickCallback): void => {
        setError(undefined);
        formRef.current?.validateFields().then(values => {
            if (callback) {
                const p = callback();
                if (CoreUtils.isPromise(p)) {
                    p.then(() => {
                        // noop
                    }).catch((e: Error) => {
                        setError(new Error("Please fill in all values."));
                    });
                }
            }
        }).catch(e => {
        });
    }

    const handleExecutionComplete = (): void => {
        const calculator = props.calculator!;
        const snapshot = calculator.snapshot();
        setSnapshot(snapshot);
        const formula = createFormula();
        const value = calculator.evaluateFormula(formula);
        setResult(value);
        restoreVariables();
        calculator.removeCallback("ExecutionComplete", handleExecutionComplete);
        setIsExecuting(false);
    }

    const executeFormula = (): void => {
        const calculator = props.calculator!;
        const snapshot = calculator.snapshot();
        setSnapshot(snapshot);
        const formula = createFormula();
        const value = calculator.evaluateFormula(formula);
        setResult(value);
        setIsExecuting(false);
    }

    const testAlert = (): void => {
        setIsExecuting(true);
        const originals = saveVariables();
        if (!originals) {
            executeFormula();
        } else {
            const calculator = props.calculator!;
            calculator.addCallback("ExecutionComplete", handleExecutionComplete);
            installInputs();
        }
    }

    const saveVariables = (): boolean => {
        const inputs = props.inputs!;
        const entries = Object.entries(inputs.configuration);
        if (entries.length === 0) {
            return false;
        }
        let count = 0;
        const calculator = props.calculator!;
        const originals: Configuration = {};
        const variables = calculator.variables;
        for (const [key, value] of entries) {
            const variable = variables.get(key, false);
            if (variable && variable.cell) {
                const cell = variable.cell;
                if (cell.value !== value) {
                    if (cell.hasFormula()) {
                        originals[key] = cell.formula;
                    } else {
                        originals[key] = cell.value;
                    }
                    count++;
                }
            }
        }
        // If there are configurations, but none of the values are 
        // different, the calculator will not execute anything and
        // there will no completion callback.
        if (count > 0) {
            configuration.current = originals;
            return true;
        } else {
            return false;
        }
    }

    const installInputs = (): void => {
        const inputs = props.inputs!;
        const calculator = props.calculator!;
        calculator.suspend();
        const entries = Object.entries(inputs.configuration);
        const variables = calculator.variables;
        for (const [key, value] of entries) {
            const variable = variables.get(key, false);
            if (variable && variable.cell) {
                const cell = variable.cell;
                cell.value = value;
            }
        }
        calculator.execute();
    }

    const restoreVariables = (): void => {
        const calculator = props.calculator!;
        calculator.suspend();
        const entries = Object.entries(configuration.current!);
        const variables = calculator.variables;
        for (const [key, value] of entries) {
            const variable = variables.get(key, false);
            if (variable && variable.cell) {
                const cell = variable.cell;
                if (CoreUtils.isFormula(value)) {
                    cell.formula = value;
                } else {
                    cell.value = value;
                }
            }
        }
        calculator.execute();
        configuration.current = undefined;
    }

    const createFormula = (): string => {
        const parts = ["="];
        const inputs = props.inputs!;
        const conditions = inputs.conditions;
        for (let i = 0; i < conditions.length; i++) {
            const condition = conditions[i];
            const logical = i > 0 ? (condition.logicalType === LogicalType.AND ? " && " : " || ") : "";
            const value = String.isString(condition.value!) ? `'${condition.value}'` : condition.value;
            const part = `${logical}${condition.variableId} ${OpType.symbol(condition.opType!)} ${value}`;
            parts.push(part);
        }
        return parts.join("");
    }

    const variableName = (variableId: string): string => {
        const calculator = props.calculator!;
        const variable = calculator.variables.get(variableId, false);
        return variable ? variable.name : "<Variable not found>";
    }

    const buildResultView = (): ReactElement => {
        const inputs = props.inputs!;
        const alert = props.alert!;
        if (FormulaError.isError(result)) {
            return (
                <Result
                    status="error"
                    title="Alert did not execute successfully."
                    subTitle={`The conditions resulted in an error of type ${result.type}: ${result.message}`}
                />
            )
        } else {
            try {
                const value = DataConverter.convert(DataType.BOOLEAN, result);
                let content;
                if (value) {
                    const message = WidgetUtils.replaceCellRefs(snapshot!, alert.message);
                    content = (
                        <NotificationViewer
                            message={message}
                            panel={alert.panel}
                            calculator={snapshot}
                        />
                    )
                } else {
                    content = (
                        <div className="x-alertsetup-conditions">
                            {inputs.conditions.map((condition, index) => (
                                <span key={index}>
                                    {index > 0 &&
                                        <span>{LogicalType.name(condition.logicalType!).toLowerCase()}&nbsp;</span>
                                    }
                                    <span>
                                        {variableName(condition.variableId)}&nbsp;
                                        {OpType.symbol(condition.opType!)}&nbsp;
                                        {condition.value}&nbsp;
                                    </span>
                                </span>
                            ))}
                            <span>
                                <span>evaluated to&nbsp;</span>
                                <span className={`x-alertsetup-eval-${value ? "true" : "false"}`}>
                                    {`${value ? "true" : "false"}`}&nbsp;
                                </span>
                                <span>on {Formatter.format(Date.today(), DateFormatType.MEDIUM)} at {Formatter.format(Time.now(), TimeFormatType.MEDIUM)}.</span>
                            </span>
                        </div>
                    )
                }
                return (
                    <Result
                        status="success"
                        title={`Alert executed successfully!`}
                        extra={content}
                    />
                )
            } catch (e) {
                return (
                    <Result
                        status="error"
                        title="Alert did not execute successfully."
                        subTitle="The conditions did not result in a boolean expression."
                    />
                )
            }
        }
    }

    const buildSetupView = (): ReactElement => {
        const alert = props.alert!;
        const calculator = props.calculator!;
        return (
            <div>
                <AlertInputs
                    formRef={formRef}
                    isBuild={true}
                    inputs={props.inputs}
                    alert={alert}
                    calculator={calculator}
                    onChange={handleInputsChange}
                />
                <TextMessage
                    className="x-alertsetup-error"
                    type="error"
                    message={error?.message}
                />
                {props.extra}
            </div>
        )
    }

    const buttonSpec = (spec: ButtonSpec): ButtonSpec => {
        switch (spec.role) {
            case "close":
                return {
                    role: spec.role,
                    label: spec.label ?? "Close",
                    primary: spec.primary ?? false,
                    visible: spec.visible ? spec.visible : () => CoreUtils.isEmpty(result),
                    onClick: spec.onClick ?? handleClose
                }
            case "test":
                return {
                    role: spec.role,
                    label: spec.label ?? "Test",
                    primary: spec.primary ?? false,
                    visible: spec.visible ? spec.visible : () => CoreUtils.isEmpty(result),
                    loading: spec.loading ? spec.loading : () => isExecuting,
                    onClick: spec.onClick ?? handleTest
                }
            case "done":
                return {
                    role: spec.role,
                    label: spec.label ?? "Done",
                    primary: spec.primary ?? true,
                    visible: spec.visible ? spec.visible : () => !CoreUtils.isEmpty(result),
                    onClick: spec.onClick ?? handleDone
                }
            default:
                return {
                    role: spec.role,
                    label: spec.label ?? "UNDEFINED",
                    primary: spec.primary,
                    visible: spec.visible,
                    loading: spec.loading,
                    onClick: () => handleCustom(spec.onClick)
                }
        }
    }

    const loadData = (): void => {
        if (props.inputs && props.alert && props.calculator) {
            setStatus(Globals.STATUS_READY);
            return;
        } else if (!props.loaders || props.loaders.length === 0) {
            setStatus(Globals.STATUS_FAILED);
            return;
        }
        const requests = [];
        for (const loader of props.loaders) {
            requests.push(loader());
        }
        setStatus(Globals.STATUS_LOADING);
        axios.all(requests).then(axios.spread((...responses) => {
            if (RestUtils.isOk(...responses)) {
                setStatus(Globals.STATUS_READY);
            } else {
                setStatus(Globals.STATUS_FAILED);
            }
        }));
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        return (
            <LoadSkeleton
                direction="vertical"
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load alert."
                onRetry={handleRetryLoad}
            >
                <LoadSkeleton.Input length="medium" />
                <LoadSkeleton.Input length="short" />
                <LoadSkeleton.Input length="long" />
                <LoadSkeleton.Input length="medium" />
            </LoadSkeleton>
        );
    }

    const buildAlertView = (): ReactElement => {
        return (
            <Form className="x-alertsetup-form" ref={formRef}>
                {CoreUtils.isEmpty(result) ? buildSetupView() : buildResultView()}
            </Form>
        )
    }

    let view;
    if (status === Globals.STATUS_LOADING) {
        view = buildLoadingView(true);
    } else if (status === Globals.STATUS_FAILED) {
        view = buildLoadingView(false);
    } else if (status === Globals.STATUS_READY) {
        view = buildAlertView();
    }

    return (
        <Modal
            className="x-alertsetup"
            centered
            title={props.title}
            width={650}
            visible={true}
            closable={false}
            footer={(
                <>
                    {props.buttons.map((button, index) => {
                        const spec = buttonSpec(button);
                        return (
                            <Button
                                key={index}
                                type={!!spec.primary ? "primary" : "default"}
                                style={{ display: !spec.visible || spec.visible(!CoreUtils.isEmpty(result)) ? "inline-block" : "none" }}
                                loading={spec.loading && spec.loading(!CoreUtils.isEmpty(result))}
                                onClick={spec.onClick}
                            >
                                {spec.label}
                            </Button>
                        )
                    })}
                </>
            )}
        >
            {view}
        </Modal>
    );

}
