import { Ref, useRef } from 'react';
import { Calculator, Variable } from '@methodset/calculator-ts';
import { ReactElement, useState } from 'react';
import { InputFlowWidgetConfiguration } from '@methodset/application-client-ts';
import { Configuration } from '@methodset/model-client-ts';
import { Justification, Justify } from 'components/Justify/Justify';
import { Button, Form, FormInstance, Modal } from 'antd';
import { CoreUtils } from '@methodset/commons-core-ts';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { ConfigurationCallback } from '../WidgetViewer/WidgetViewer';
import { InputEditor } from '../InputWidgetViewer/InputEditor/InputEditor';
import update from 'immutability-helper';
import './InputFlowWidgetViewer.less';
import { WidgetUtils } from 'utils/WidgetUtils';

export type InputFlowWidgetViewerProps = {
    configuration: InputFlowWidgetConfiguration,
    calculator: Calculator,
    // modelId: string,
    // version?: number,
    appletConfiguration: Configuration,
    onUpdate: ConfigurationCallback,
} & typeof defaultProps;

const defaultProps = {
    appletConfiguration: {} as Configuration
}

export const InputFlowWidgetViewer = (props: InputFlowWidgetViewerProps): ReactElement => {

    const [configuration, setConfiguration] = useState<Configuration>(props.appletConfiguration);
    const [index, setIndex] = useState<number>(0);
    const [editing, setEditing] = useState<boolean>(false);

    const formRef: Ref<FormInstance> = useRef(null);

    const findVariable = (): Variable | undefined => {
        const variableIds = props.configuration.variableIds;
        const variableId = variableIds[index];
        return props.calculator.variables.get(variableId, false);
    }

    const handleInputsNext = (): void => {
        formRef.current!.validateFields().then(values => {
            const position = index + 1;
            setIndex(position);
        }).catch(e => {
        });
    }

    const handleInputsPrev = (): void => {
        const position = index - 1;
        setIndex(position);
    }

    const handleInputsEdit = (): void => {
        setEditing(true);
    }

    const handleEditCancel = (): void => {
        setConfiguration(props.appletConfiguration);
        setIndex(0);
        setEditing(false);
    }

    const handleEditDone = (): void => {
        const variableIds = props.configuration.variableIds;
        if (!variableIds) {
            return;
        }
        // Suspend calculator updates while setting variables.
        props.calculator.suspend();
        variableIds.forEach(variableId => {
            const variable = props.calculator.variables.get(variableId, false);
            if (variable && variable.cell) {
                let value = configuration[variable.id];
                if (!!props.configuration.useDefaults) {
                    if (value === null) {
                        // User cleared the value.
                        value = undefined;
                    } else if (value === undefined) {
                        // Use default value.
                        return;
                    }
                }
                const cell = variable.cell;
                if (CoreUtils.isFormula(value)) {
                    cell.formula = value;
                } else {
                    cell.value = value;
                }
            }
        });
        // Unsuspend and execute with the new variable values.
        props.calculator.execute();
        setIndex(0);
        setEditing(false);
        props.onUpdate(configuration);
    }

    const handleInputChange = (value: any, variable: Variable): void => {
        let updated;
        if (CoreUtils.isEmpty(value)) {
            updated = update(configuration, {
                $unset: [variable.id]
            });
        } else {
            updated = update(configuration, {
                [variable.id]: { $set: value }
            });
        }
        setConfiguration(updated);
    }

    const buildInput = (): ReactElement => {
        const variableIds = props.configuration.variableIds;
        if (variableIds.length === 0) {
            return <div>{`ERROR: No variables configured as inputs.`}</div>;
        }
        const variableId = variableIds[index];
        const original = configuration[variableId];
        const value = WidgetUtils.valueOrDefault(original, props.calculator, variableId, props.configuration.useDefaults);
        return (
            <InputEditor
                calculator={props.calculator}
                value={value}
                variableId={variableId}
                useDefault={!!props.configuration.useDefaults}
                doEmbed={true}
                onChange={(value, variable) => handleInputChange(value, variable)}
            />
        )
    }

    const buildView = (): ReactElement => {
        const variable = findVariable();
        const justification = props.configuration.justification ?
            props.configuration.justification.toLowerCase() as Justification :
            undefined;
        const atHead = index === 0;
        const atTail = index === props.configuration.variableIds.length - 1;
        return (
            <div>
                <Justify justification={justification}>
                    <Button onClick={() => handleInputsEdit()}>Edit Inputs</Button>
                </Justify>
                {editing &&
                    <Modal
                        centered
                        title="Inputs"
                        width={Globals.DIALOG_WIDTH}
                        onCancel={() => handleEditCancel()}
                        visible={true}
                        footer={(
                            <>
                                {!atHead &&
                                    <Button onClick={() => handleInputsPrev()}>Prev</Button>
                                }
                                {!atTail &&
                                    <Button onClick={() => handleInputsNext()}>Next</Button>
                                }
                                {atTail &&
                                    <Button type="primary" onClick={() => handleEditDone()}>Done</Button>
                                }
                            </>
                        )}
                    >
                        {!variable &&
                            <div>No variables configured as inputs.</div>
                        }
                        {variable &&
                            <Form ref={formRef}>
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={formRef}
                                    label={variable.name}
                                    name={variable.id}
                                    info={variable.description}
                                    infoLocation="under"
                                    rules={[{
                                        required: variable.isRequired,
                                        message: "Please enter a value."
                                    }]}
                                >
                                    {buildInput()}
                                </FormItem>
                            </Form>
                        }
                    </Modal>
                }
            </div>
        );
    }

    return buildView();

}

InputFlowWidgetViewer.defaultProps = defaultProps;
