import { ReactElement } from 'react';
import classNames from 'classnames';
import './Image.less';

export type ClickCallback = () => void;

export type ImageProps = {
    className?: string,
    src: string,
    alt?: string,
    onClick?: ClickCallback
}

/**
 * Adds a hover hand if the image is clickable (has a click callback).
 */
export const Image = (props: ImageProps): ReactElement => {
    return (
        <img 
            className={classNames('x-image', props.className, {'x-image-hover': props.onClick})} 
            src={props.src} 
            alt={props.alt} 
            onClick={props.onClick} 
        />
    )
}
