import { ReactElement } from 'react';
import { Select } from 'antd';
import { Calculator } from '@methodset/calculator-ts';

export type ChangeCallback = (variableId: string) => void;

export type VariableSelectorProps = {
    className?: string,
    calculator: Calculator,
    variableId?: string,
    onChange: ChangeCallback
};

export const VariableSelector = (props: VariableSelectorProps): ReactElement => {
    const variables = props.calculator.variables;
    return (
        <Select
            className={props.className}
            allowClear={true}
            placeholder="Select a variable."
            value={props.variableId}
            onChange={(variable) => props.onChange(variable)}
        >
            {variables.map(variable => (
                <Select.Option key={variable.id} value={variable.id}>{variable.name}</Select.Option>
            ))}
        </Select>
    )
}
