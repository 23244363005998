import { ReactElement, useEffect, useRef } from 'react';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { AvatarProps, PageHeader, Pagination, Table, TablePaginationConfig } from 'antd';
import { Globals } from 'constants/Globals';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { ItemSpec, MenuButton } from 'components/MenuButton/MenuButton';
import { Spacer } from 'components/Spacer/Spacer';
import { useHistory, useLocation } from 'react-router-dom';
import { StatusType } from 'constants/StatusType';
import { CoreUtils } from 'utils/CoreUtils';
import { Pager, PagerProps } from 'components/Pager/Pager';
import classNames from 'classnames';
import './ItemTable.less';

// Function to return the key of the row.
export type RowKeyFn = (record: any) => string;
export type PaginationType = false | TablePaginationConfig | PagerProps;

export type ChangeCallback = (page: number) => void;
export type LoadCallback = () => void;

export type LabelFunction = (item: any, index: number) => string;
export type ConfirmFunction = (item: any, index: number) => string | undefined;
export type DisabledFunction = (item: any, index: number) => boolean;
export type HiddenFunction = (item: any, index: number) => boolean;
export type ActionCallback = (item: any, index: number) => void;

export interface ActionSpec {
    icon?: ReactElement;
    label: string | LabelFunction;
    confirm?: string | ConfirmFunction;
    children?: ActionSpec[];
    disabled?: DisabledFunction;
    hidden?: HiddenFunction;
    callback?: ActionCallback;
}

export type ItemTableProps = {
    // Classname to style the element.
    className?: string,
    // Image to place to the left of the header title.
    icon?: AvatarProps,
    // The title in the header.
    title?: string,
    // Optional sub-title in the header.
    subtitle?: string,
    // The status of the initial data load.
    status?: StatusType,
    // The field in the record that contains the item id.
    rowKey?: string | RowKeyFn,
    // Optional actions to place in the table.
    actions?: ActionSpec[],
    // Size of table.
    size?: SizeType,
    // A pagination object to configure paging.
    pagination?: PaginationType,
    // An extra component to place into the right side of header.
    extra?: ReactElement | ReactElement[],
    // The table column definition.
    columns: ColumnsType<any>,
    // The table data. If undefined, no table will be rendered.
    items?: any[],
    // Show the arrow to return to the previous page.
    showBack?: boolean,
    // The number of rows to display when loading.
    loadingRows?: number,
    // Shows the number of items.
    showCount?: boolean,
    // A function to decide if the actions need to be disabled.
    disabled?: DisabledFunction,
    // Called if the table is changed (new data loaded).
    onChange?: ChangeCallback,
    // Called if the table is being re-loaded (after a fail).
    onLoad?: LoadCallback
} & typeof defaultProps;

export type ItemTableState = {
    pagination: TablePaginationConfig
}

const defaultProps = {
    loading: false,
    showBack: true,
    pagination: {
        showSizeChanger: false
    } as TablePaginationConfig,
    status: Globals.STATUS_READY,
    loadingRows: 8,
    showCount: true
}

export const ItemTable = (props: ItemTableProps): ReactElement => {

    const isPager = (pagination: PaginationType): boolean => {
        return !CoreUtils.isEmpty((pagination as any).page);
    }

    const pagination = useRef<false | TablePaginationConfig | undefined>(isPager(props.pagination) ? undefined : props.pagination);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (isPager(props.pagination)) {
            props.pagination.onChange = handlePageChange
        }
    }, []);

    const handlePageChange = (page: number): void => {
        changePage(page);
    }

    const handleTableChange = (pagination: TablePaginationConfig): void => {
        const page = pagination.current;
        if (page) {
            changePage(page);
        }
    }

    const changePage = (page: number): void => {
        const pathname = page > 1 ? `${location.pathname}?page=${page}` : location.pathname;
        history.push(pathname);
        if (props.onChange) {
            props.onChange(page);
        }
    }

    const buildData = (): any[] => {
        return props.items ?? [];
    }

    const buildColumns = (): ColumnsType<any> => {
        if (props.actions) {
            const actions: ColumnType<any> = {
                key: "actions",
                title: "Actions",
                align: "center",
                width: Globals.TABLE_WIDTH_SMALL,
                render: (value: any, record: any, index: number) => {
                    if (props.disabled && props.disabled(record, index)) {
                        return <span>{Globals.EMPTY_FIELD}</span>
                    } else {
                        return buildActions(record, index);
                    }
                }
            }
            props.columns.push(actions);
        }
        return props.columns;
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        return (
            <LoadSkeleton
                count={props.loadingRows}
                spacer="lg"
                status={isLoading ? "loading" : "failed"}
                onRetry={props.onLoad}
            >
                <LoadSkeleton.Input length="medium" />
                <LoadSkeleton.Input length="fill" />
                <LoadSkeleton.Input length="short" />
                <LoadSkeleton.Input length="short" />
                <LoadSkeleton.Input length="medium" />
                <LoadSkeleton.Input length="short" />
            </LoadSkeleton>
        );
    }

    const buildTableView = (): ReactElement => {
        const columns = buildColumns();
        const data = buildData();
        const rowKey = !props.rowKey ? "id" : typeof props.rowKey === "function" ?
            props.rowKey : (record: any) => record[props.rowKey as string];
        return (
            <div>
                {props.items &&
                    <>
                        <Table
                            size={props.size}
                            expandable={{ childrenColumnName: "__NULL__" }}
                            pagination={isPager(props.pagination) ? false : pagination.current}
                            columns={columns}
                            dataSource={data}
                            rowKey={rowKey}
                            onChange={handleTableChange}
                        />
                        {isPager(props.pagination) &&
                            <Pager {...(props.pagination as PagerProps)} />
                        }
                    </>
                }
            </div>
        );
    }

    const buildItems = (actions: ActionSpec[], index: number): ItemSpec[] => {
        const items = actions.map((action: ActionSpec) => {
            return {
                icon: action.icon,
                label: typeof action.label === "function" ? (record: any) => (action.label as LabelFunction)(record, index) : action.label,
                confirm: typeof action.confirm === "function" ? (record: any) => (action.confirm as LabelFunction)(record, index) : action.confirm,
                children: action.children ? buildItems(action.children, index) : undefined,
                disabled: action.disabled ? (record: any) => action.disabled!(record, index) : undefined,
                hidden: action.hidden ? (record: any) => action.hidden!(record, index) : undefined,
                onSelect: action.callback ? (record: any) => action.callback!(record, index) : undefined
            }
        });
        return items;
    }

    const buildActions = (record: any, index: number): ReactElement | null => {
        const actions = props.actions;
        if (!actions) {
            return null;
        }
        const items = buildItems(actions, index);
        return <MenuButton data={record} items={items} />;
    }

    const buildExtra = (): ReactElement | undefined => {
        if (props.showCount && props.status && props.status === StatusType.READY) {
            const count = props.items ? props.items.length : 0;
            const items = (
                <span className="x-itemtable-count">{count} {`Item${count !== 1 ? "s" : ""}`}</span>
            )
            if (props.extra) {
                if (Array.isArray(props.extra)) {
                    return (
                        <Spacer>
                            {items}
                            {props.extra.map(value => value)}
                        </Spacer>
                    )
                } else {
                    return (
                        <Spacer>
                            {items}
                            {props.extra}
                        </Spacer>
                    )
                }
            } else {
                return items;
            }
        } else {
            if (Array.isArray(props.extra)) {
                return (
                    <Spacer>
                        {props.extra.map(value => value)}
                    </Spacer>
                )
            } else {
                return props.extra;
            }
        }

    }

    const query = location.search;
    const params = new URLSearchParams(query);
    const page = params.get("page");
    if (pagination.current) {
        let number = page ? parseInt(page) : 1;
        if (Number.isNaN(number) || number < 1) {
            number = 1;
        }
        pagination.current.current = number;
    }

    const extra = buildExtra();

    let view;
    if (props.status === StatusType.LOADING) {
        view = buildLoadingView(true);
    } else if (props.status === StatusType.FAILED) {
        view = buildLoadingView(false);
    } else if (props.status === StatusType.READY) {
        view = buildTableView();
    }
    return (
        <div className={classNames("x-itemtable", props.className)}>
            {!!props.title &&
                <PageHeader
                    className="x-itemtable-header"
                    avatar={props.icon}
                    title={props.title}
                    subTitle={props.subtitle}
                    extra={extra}
                    onBack={props.showBack ? () => history.goBack() : undefined}
                />
            }
            {!props.title && props.extra &&
                <Spacer className="x-itemtable-header" justification="right">
                    {extra}
                </Spacer>
            }
            <div className="x-itemtable-body">
                {view}
            </div>
        </div>
    );

}

ItemTable.defaultProps = defaultProps;
