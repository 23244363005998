import { ReactElement, useEffect, useState } from 'react';
import { FormInstance } from 'antd';
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { StatusType } from 'constants/StatusType';
import { Query, QueryHeader } from '@methodset/endpoint-client-ts';
import { FormItem } from 'components/FormItem/FormItem';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { QueryList } from './QueryList/QueryList';
import { IdUtils, Version } from '@methodset/commons-core-ts';
import { CoreUtils } from 'utils/CoreUtils';
import { QueryVersionPicker } from 'containers/Console/ConfigurationEditor/QuerySelector/QueryVersionPicker/QueryVersionPicker';
import { Pack } from '@methodset/library-client-ts';
import endpointService from 'services/EndpointService';
import libraryService from 'services/LibraryService';
import axios from 'axios';
import './QueryPicker.less';

export type ChangeCallback = (queryId: string, version: number) => void;
export type LoadCallback = (query: Query, pack?: Pack) => void;

export type QueryPickerProps = {
    formRef: React.RefObject<FormInstance>,
    queryId?: string,
    version?: number,
    hideId?: boolean,
    loadPack?: boolean,
    onChange: ChangeCallback,
    onLoad: LoadCallback
} & typeof defaultProps;

const defaultProps = {
    loadPack: false
}

export const QueryPicker = (props: QueryPickerProps): ReactElement => {

    const [status, setStatus] = useState<string>(StatusType.INIT);

    useEffect(() => {
        loadData(props.queryId, props.version);
    }, []);

    const handleRetryLoad = (): void => {
        loadData(props.queryId, props.version);
    }

    const handleQuerySelect = (header: QueryHeader): void => {
        loadData(header.id, header.version);
        props.onChange(header.id, header.version);
    }

    const handleVersionChange = (version: number): void => {
        loadData(props.queryId, version);
        props.onChange(props.queryId!, version);
    }

    const loadQueryRequest = (queryId: string, version: number): Promise<any> => {
        const request = {
            queryId: queryId,
            version: version
        };
        return endpointService.loadQuery(request,
            (response: any) => loadQueryResponse(response),
            undefined, true
        );
    }

    const loadQueryResponse = (response: any): Query => {
        const query: Query = response.data.query;
        return query;
    }

    const readPackRequest = (packId: string, version: number): Promise<any> => {
        // Unpublished queries do not have packs.
        if (!props.loadPack || version === Version.EDITOR) {
            return Promise.resolve(undefined);
        }
        const request = {
            packId: packId,
            version: version
        }
        return libraryService.readPack(request,
            (response: any) => readPackResponse(response),
            undefined, true
        );
    }

    const readPackResponse = (response: any): Pack => {
        const pack: Pack = response.data.pack;
        return pack;
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        return (
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
            >
                <LoadSpinner
                    className="x-querypicker-loading"
                    status={isLoading ? "loading" : "failed"}
                    loadingMessage="Loading query..."
                    failedMessage="Failed to load query."
                    onRetry={handleRetryLoad}
                />
            </FormItem>
        );
    }

    const loadData = (queryId: string | undefined, version: number | undefined): void => {
        if (!CoreUtils.isDefined(queryId, version)) {
            setStatus(StatusType.READY);
            return;
        }
        const requests = [];
        requests.push(loadQueryRequest(queryId!, version!));
        requests.push(readPackRequest(queryId!, version!));
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((query, pack) => {
            if (RestUtils.isOk(query, pack)) {
                props.onLoad(query, pack);
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
    }

    let loadingView;
    if (status === StatusType.LOADING) {
        loadingView = buildLoadingView(true);
    } else if (status === StatusType.FAILED) {
        loadingView = buildLoadingView(false);
    } else {
        loadingView = <></>;
    }

    const accessType = IdUtils.toAccessType(props.queryId);
    const extra = accessType ? (
        <span className="x-querypicker-access">
            {CoreUtils.toProper(accessType)}
        </span>
    ) : undefined;

    return (
        <div className="x-querypicker">
            {!props.hideId &&
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={props.formRef}
                    label="Dataset"
                    name="dataset"
                    info="The dataset to query."
                    extra={extra}
                    valuePropName="queryId"
                    rules={[{
                        required: true,
                        message: 'Please select a dataset.'
                    }]}
                >
                    <QueryList
                        formRef={props.formRef}
                        queryId={props.queryId}
                        onChange={handleQuerySelect}
                    />
                </FormItem>
            }
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Version"
                name="version"
                info="The dataset version."
                valuePropName="version"
                rules={[{
                    required: true,
                    message: `Please select a version.`
                }]}
            >
                <QueryVersionPicker
                    queryId={props.queryId}
                    version={props.version}
                    onChange={handleVersionChange}
                />
            </FormItem>
            {loadingView}
        </div>
    );

}

QueryPicker.defaultProps = defaultProps;
