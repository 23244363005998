import { Globals } from 'constants/Globals';
import { CoreUtils } from 'utils/CoreUtils';
import { FailureHandler, RestUtils, SuccessHandler } from 'utils/RestUtils';

class ScheduleService {

    public baseUrl = CoreUtils.apiUrl('schedule-service', 14012);

    public warm(): Promise<any> {
        const url = `${this.baseUrl}/warm`;
        return RestUtils.get(url, undefined, undefined, undefined, undefined, false);
    }

    public createSchedule(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/schedules`;
        return RestUtils.post(url, request, onSuccess, onFailure, ownStatus, Globals.MESSAGE_SAVING);
    }

    public readSchedule(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/schedules/${request.scheduleId}`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public readSchedules(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/schedules`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public updateSchedule(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/schedules/${request.scheduleId}`;
        return RestUtils.put(url, request, onSuccess, onFailure, ownStatus, Globals.MESSAGE_SAVING);
    }

    public deleteSchedule(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/schedules/${request.scheduleId}`;
        return RestUtils.delete(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_DELETING);
    }

}

export default new ScheduleService();
