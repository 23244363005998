import { ReactElement } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Request } from '../Request/Request';
import { RequestType } from '@methodset/entity-client-ts';
import { RouteBuilder } from 'utils/RouteBuilder';

export type ServicesProps = RouteComponentProps;

export const Services = (props: ServicesProps): ReactElement => {

    return (
        <Request
            {...props}
            type={RequestType.SERVICE}
            requestText={
                <>
                    <p>
                        We provide custom services to setup and manage your financial backend
                        functionality. Please fill out the form if you would like to discuss how we can help you
                        build and automate your process.
                    </p>
                    <p>
                        The platform will be available for self-service usage in the future, allowing you to
                        build your own solutions. If you would like to be notified when the platform is
                        generally available, please add your name to our <Link to={RouteBuilder.MAIN_WAITLIST}>waitlist</Link>.
                    </p>
                </>
            }
            responseText="Thank you for submitting your request. A member of our team will reach out to you."
        />
    )

}