import { ReactElement, useEffect, useRef, useState } from 'react';
import { RestUtils } from 'utils/RestUtils';
import { StatusType } from 'constants/StatusType';
import { Installation, InstallationHeader } from '@methodset/application-client-ts';
import { Button } from 'antd';
import { Globals } from 'constants/Globals';
import { ApplicationType } from '@methodset/application-client-ts';
import { DeleteOutlined, EditOutlined, InfoCircleOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { ColumnsType } from 'antd/lib/table';
import { CoreUtils } from 'utils/CoreUtils';
import { AlertConfiguration } from './AlertConfiguration/AlertConfiguration';
import { PackHeader } from '@methodset/library-client-ts';
import { ButtonLink } from 'components/ButtonLink/ButtonLink';
import { AlertInstaller } from 'containers/Console/ApplicationInstaller/AlertInstaller/AlertInstaller';
import { AccessType } from '@methodset/commons-core-ts';
import axios from 'axios';
import applicationService from 'services/ApplicationService';
import libraryService from 'services/LibraryService';
import update from 'immutability-helper';
import './Alerts.less';
import { AlertAbout } from './AlertAbout/AlertAbout';

// Types of modals that can be displayed.
type ModalType = "none" | "edit" | "install" | "about";

interface TableItem {
    //pack: PackHeader;
    installation: InstallationHeader;
}

export type AlertsProps = {}

export const Alerts = (props: AlertsProps): ReactElement => {

    // All available packs.
    //const packHeaders = useRef<PackHeader[]>();
    // All installed applications.
    //const installationHeaders = useRef<InstallationHeader[]>();

    // Data load status
    const [status, setStatus] = useState<StatusType>(StatusType.INIT);
    // The data to display in the table.
    const [headers, setHeaders] = useState<InstallationHeader[]>([]);
    //const [items, setItems] = useState<TableItem[]>([]);
    // Application being edited.
    const [header, setHeader] = useState<InstallationHeader | null>(null);
    // Type of modal to display.
    const [modalType, setModalType] = useState<ModalType>("none");

    useEffect(() => {
        loadData();
    }, []);

    const handleRetryLoad = (): void => {
        loadData();
    }

    const handleAlertsRefresh = (): void => {
        loadData();
    }

    const handleAlertAdd = (): void => {
        setModalType("install");
    }

    const handleAlertInstall = (installation: Installation): void => {
        // const item = buildItem(header, installation);
        // const updated = update(items, {
        //     $push: [item]
        // });
        // setItems(updated);

        const updated = update(headers, {
            $push: [installation]
        });
        setHeaders(updated);

    }

    const handleAlertEdit = (header: InstallationHeader): void => {
        setModalType("edit");
        setHeader(header);
    }

    const handleAlertAbout = (header: InstallationHeader): void => {
        setModalType("about");
        setHeader(header);
    }

    const handleModalClose = (): void => {
        setModalType("none");
        setHeader(null);
    }

    const handleAlertUninstall = (header: InstallationHeader): void => {
        removeAlertRequest(header);
    }

    const removeAlertRequest = (header: InstallationHeader): Promise<any> => {
        const request = {
            installationId: header.id
        };
        return applicationService.uninstallAlert(request,
            (response: any) => removeAlertResponse(response),
            undefined, false
        );
    }

    const removeAlertResponse = (response: any): void => {
        const installationId = response.data.installationId;
        const index = headers.findIndex(header => header.id === installationId);
        if (index !== -1) {
            const updated = update(headers, {
                $splice: [[index, 1]]
            });
            setHeaders(updated);
        }
    }

    // const readPackHeadersRequest = (): Promise<any> => {
    //     const request = {
    //         accessType: AccessType.GROUP,
    //     }
    //     return libraryService.readPackHeaders(request,
    //         (response: any) => readPackHeadersResponse(response),
    //         undefined, true
    //     );
    // }

    // const readPackHeadersResponse = (response: any): void => {
    //     packHeaders.current = response.data.headers;
    // }

    const readInstallationHeadersRequest = (): Promise<any> => {
        const request = {
            applicationType: ApplicationType.ALERT
        }
        return applicationService.readInstallationHeaders(request,
            (response: any) => readInstallationHeadersResponse(response),
            undefined, true
        );
    }

    const readInstallationHeadersResponse = (response: any): void => {
        //installationHeaders.current = response.data.headers;
        const headers = response.data.headers;
        setHeaders(headers);
    }

    const buildColumns = (): ColumnsType<any> => {
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            width: Globals.TABLE_WIDTH_LARGE,
            render: (header: InstallationHeader) => {
                return <ButtonLink type="link" onClick={() => handleAlertEdit(header)}>{header.name}</ButtonLink>
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend']
        }, {
            key: 'description',
            title: 'Description',
            ellipsis: true,
            render: (header: InstallationHeader) => (
                <span>{header.description ?? Globals.EMPTY_FIELD}</span>
            ),
            sorter: (a: any, b: any) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend']
            // }, {
            //     key: 'version',
            //     title: 'Version',
            //     align: 'center',
            //     width: Globals.TABLE_WIDTH_MEDIUM,
            //     render: (header: InstallationHeader) => (
            //         <span>{CoreUtils.toVersion(header.pack.version)}</span>
            //     ),
            //     sorter: (a: any, b: any) => CoreUtils.compareNumbers(a.version, b.version),
            //     sortDirections: ['descend', 'ascend']
        }, {
            key: 'itime',
            title: 'Install Time',
            width: Globals.TABLE_WIDTH_MEDIUM,
            align: 'center',
            render: (header: InstallationHeader) => {
                return (
                    <span>
                        {CoreUtils.toTime(header.createTime)}
                    </span>
                );
            },
            sorter: (a, b) => CoreUtils.compareNumbers(a.createTime, b.createTime),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend'
        }];
        return columns;
    }

    // const buildItems = (): void => {
    //     const map = new Map();
    //     packHeaders!.current!.forEach(header => map.set(header.id, header));
    //     const items: TableItem[] = [];
    //     installationHeaders!.current!.forEach(installation => {
    //         const pack = map.get(installation.packInfo.packId);
    //         if (pack) {
    //             const item = buildItem(pack, installation);
    //             items.push(item);
    //         }
    //     });
    //     setItems(items);
    // }

    // const buildItem = (pack: PackHeader, installation: InstallationHeader): TableItem => {
    //     return {
    //         pack: pack,
    //         installation: installation
    //     }
    // }

    const loadData = (): void => {
        const requests = [];
        //requests.push(readPackHeadersRequest());
        requests.push(readInstallationHeadersRequest());
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                //buildItems();
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
    }

    const columns = buildColumns();
    const actions = [{
        icon: <EditOutlined />,
        label: "Edit alert",
        callback: handleAlertEdit
    }, {
        icon: <DeleteOutlined />,
        label: "Remove alert",
        confirm: "Are you sure you want to remove the alert?",
        callback: handleAlertUninstall
    }, {
        icon: <InfoCircleOutlined />,
        label: "About...",
        callback: handleAlertAbout
    }];

    return (
        <>
            <ItemTable
                className="x-alerts"
                title="Alerts"
                status={status}
                columns={columns}
                items={headers}
                actions={actions}
                extra={[
                    <Button key="1" icon={<PlusOutlined />} onClick={handleAlertAdd}>Add Alert</Button>,
                    <Button key="2" icon={<SyncOutlined />} onClick={handleAlertsRefresh}></Button>
                ]}
                showCount={true}
                onLoad={handleRetryLoad}
            />
            {modalType === "edit" && header &&
                <AlertConfiguration
                    header={header}
                    onClose={handleModalClose}
                />
            }
            {modalType === "install" &&
                <AlertInstaller
                    onInstall={handleAlertInstall}
                    onClose={handleModalClose}
                />
            }
            {modalType === "about" && header &&
                <AlertAbout
                    header={header}
                    onClose={handleModalClose}
                />
            }
        </>
    );

}
