import { ReactElement } from 'react';
import './PageNotFound.less';

export type PageNotFoundProps = {}
 
export const PageNotFound = (props: PageNotFoundProps): ReactElement => {
    return (
        <div className="x-pagenotfound">
            Oops! Page not found.
        </div>
    );
}
