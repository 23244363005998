export type EmitterEvent = {
    type: string,
    data: any
}

export type EventCallback = (event: EmitterEvent) => void;

export class EventEmitter {

    private map: Map<String, EventCallback[]>;

    public constructor() {
        this.map = new Map();
    }

    public addCallback(type: string, callback: EventCallback): void {
        let callbacks = this.map.get(type);
        if (!callbacks) {
            callbacks = [];
            this.map.set(type, callbacks);
        }
        callbacks.push(callback);
    }

    public removeCallback(type: string, callback: EventCallback): void {
        let callbacks = this.map.get(type);
        if (!callbacks) {
            return;
        }
        const index = callbacks.findIndex(cb => cb === callback);
        if (index === -1) {
            return;
        }
        callbacks.splice(index, 1);
        if (callbacks.length === 0) {
            this.map.delete(type);
        }
    }

    public sendEvent(type: string, data: any): void {
        let callbacks = this.map.get(type);
        if (!callbacks) {
            return;
        }
        const event: EmitterEvent = {
            type: type,
            data: data
        }
        callbacks.forEach(callback => callback(event));
    }

}