import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { Col, Collapse, Input, Row, FormInstance, Form, Switch, Modal } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { AuthenticationHeader, Component, ConverterMap, Configuration, ConfigurationSpec, Flow, FlowUtils, IoType, ProcessorHeader, Provider } from '@methodset/endpoint-client-ts';
import { ScheduleTrigger, Trigger, TriggerType, Workflow, WorkflowType } from '@methodset/workflow-client-ts';
import { Specs } from 'containers/Console/Specs/Specs';
import { Processors } from 'containers/Console/Processors/Processors';
import { Flows } from 'containers/Console/Flows/Flows';
import { TriggerEditor } from './TriggerEditor/TriggerEditor';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { TimezoneSelector } from 'components/TimeZoneSelector/TimeZoneSelector';
import { CoreUtils } from 'utils/CoreUtils';
import update from 'immutability-helper';
import classNames from 'classnames';
import axios from 'axios';
import endpointService from 'services/EndpointService';
import workflowService from 'services/WorkflowService';
import './WorkflowEditor.less';

export type EditCallback = (isEditing: boolean) => void;
export type TouchCallback = () => void;
export type SavingCallback = (workflow: Workflow, isTesting: boolean) => void;
export type SaveCallback = (workflow: Workflow, isTesting: boolean) => void;
export type ErrorCallback = (error: Error) => void;
export type AfterSaveCallback = (data?: any) => void;

export type WorkflowEditorProps = typeof WorkflowEditor.defaultProps & {
    // Class to style the form.
    className?: string,
    // The id of the workflow to load and edit.
    workflowId?: string,
    // Called when a subsection is being edited.
    onEdit: EditCallback,
    // Called when the workflow is changed.
    onTouch: TouchCallback,
    // Called when the workflow is being saved.
    onSaving: SavingCallback,
    // Called when the workflow is done being saved.
    onSave: SaveCallback,
    // Called when there is an error processing the workflow.
    onError: ErrorCallback
}

export type WorkflowEditorState = {
    // Status of loading workflow.
    status: string,
    // The list of data providers.
    providers: Provider[],
    // Tells if the workflow has unsaved changes.
    isDirty: boolean,
    // The processor headers.
    processors: ProcessorHeader[],
    // The workflow being edited.
    workflow: Workflow,
    // A map of valid input-to-output type conversion.
    converterMap: ConverterMap,
    // The list of user authentications.
    authentications: AuthenticationHeader[],
    // Configuration (variable values) needed to run processors.
    configuration: Configuration,
    // True to share data for all users.
    useDelegate: boolean
}

export class WorkflowEditor extends PureComponent<WorkflowEditorProps, WorkflowEditorState> {

    static defaultProps = {
    }

    // Reference to properties tab.
    private propertiesRef = React.createRef<FormInstance>();
    // Reference to trigger tab.
    private triggerRef = React.createRef<FormInstance>();

    constructor(props: WorkflowEditorProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            providers: [],
            isDirty: false,
            processors: [],
            authentications: [] as AuthenticationHeader[],
            workflow: {
                id: undefined as any,
                version: undefined as any,
                type: WorkflowType.ASYNCHRONOUS,
                name: undefined as any,
                description: undefined as any,
                isEnabled: false,
                triggerType: undefined as any,
                timeZone: CoreUtils.systemTimeZone(),
                stateMachineArn: undefined as any,
                trigger: {} as ScheduleTrigger,
                components: [] as Component[],
                flows: [] as Flow[],
                configurationSpecs: [] as ConfigurationSpec[],
                delegate: undefined,
                testInputs: [] as any,
                updateTime: undefined as any,
                createTime: undefined as any
            },
            configuration: {},
            converterMap: {},
            useDelegate: true
        };
        this.handleSubEdit = this.handleSubEdit.bind(this);
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleTimeZoneChange = this.handleTimeZoneChange.bind(this);
        this.handleVariablesChange = this.handleVariablesChange.bind(this);
        this.handleComponentsChange = this.handleComponentsChange.bind(this);
        this.handleFlowsChange = this.handleFlowsChange.bind(this);
        this.handleConfigurationChange = this.handleConfigurationChange.bind(this);
        this.handleUseDelegateChange = this.handleUseDelegateChange.bind(this);
        this.handleTriggerChange = this.handleTriggerChange.bind(this);
        this.handleWorkflowSave = this.handleWorkflowSave.bind(this);
        this.saveWorkflow = this.saveWorkflow.bind(this);
    }

    private handleSubEdit(isEditing: boolean): void {
        this.props.onEdit(isEditing);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleNameChange(e: ChangeEvent<HTMLInputElement>) {
        const name = e.target.value;
        const workflow = update(this.state.workflow, {
            name: { $set: name },
        });
        this.setState({
            workflow: workflow,
            isDirty: true
        });
        this.props.onTouch();
    }

    private handleDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        const description = e.target.value;
        const workflow = update(this.state.workflow, {
            description: { $set: description }
        });
        this.setState({
            workflow: workflow,
            isDirty: true
        });
        this.props.onTouch();
    }

    private handleUseDelegateChange(useDelegate: boolean): void {
        let message;
        if (useDelegate) {
            message = "WARNING: By enabling this option, any data that this workflow creates will be stored in a single instance " +
            "and shared with other users. Enable this option when the stored data is supposed to be accessed by users of different " +
            "groups and organizations, or the public if the workflow is deployed to the public environment.";
        } else {
            message = "WARNING: By disabling this option, any data that this workflow creates will be stored separately for each " +
            "user. Disable this option when stored data is specific to each user and is not to be shared among users of different " +
            "groups and organizations, or the public if the workflow is deployed to the public environment.";
        }
        const self = this;
        const modal = Modal.confirm({
            title: `${useDelegate ? "Enable" : "Disabled"} Single Instance`,
            content: message,
            cancelText: "Cancel",
            okText: "Ok",
            onCancel: () => {
                modal.destroy()
            },
            onOk: () => {
                self.handleEnableDelegate(useDelegate)
                modal.destroy();
            }
        });
    }

    private handleEnableDelegate(useDelegate: boolean): void {
        this.setState({ useDelegate: useDelegate });
        this.props.onTouch();
    }

    private handleTimeZoneChange(timeZone: string): void {
        let trigger = this.state.workflow.trigger;
        if (trigger && trigger.type === TriggerType.SCHEDULE) {
            const scheduleTrigger = trigger as ScheduleTrigger;
            trigger = update(scheduleTrigger as ScheduleTrigger, {
                cron: {
                    timeZone: { $set: timeZone }
                }
            });
        }
        const workflow = update(this.state.workflow, {
            trigger: { $set: trigger },
            timeZone: { $set: timeZone }
        });
        this.setState({
            workflow: workflow,
            isDirty: true
        });
        this.props.onTouch();
    }

    private handleComponentsChange(components: Component[], removed?: Component): void {
        let workflow;
        if (removed) {
            workflow = this.removeFlows(this.state.workflow, removed.id);
            workflow = update(workflow, {
                components: { $set: components }
            });
        } else {
            workflow = update(this.state.workflow, {
                components: { $set: components }
            });
        }
        this.setState({
            workflow: workflow,
            isDirty: true
        });
        this.props.onTouch();
    }

    private handleFlowsChange(flows: Flow[]): void {
        const workflow = update(this.state.workflow, {
            flows: { $set: flows }
        });
        this.setState({
            workflow: workflow,
            isDirty: true
        });
        this.props.onTouch();
    }

    private handleVariablesChange(configurationSpecs: ConfigurationSpec[]): void {
        const workflow = update(this.state.workflow, {
            configurationSpecs: { $set: configurationSpecs }
        })
        this.setState({
            workflow: workflow,
            isDirty: true
        });
        this.props.onTouch();
    }

    private handleConfigurationChange(configuration: Configuration): void {
        this.setState({
            configuration: configuration,
            isDirty: true
        });
        this.props.onTouch();
    }

    private handleTriggerChange(trigger: Trigger): void {
        if (trigger && trigger.type === TriggerType.SCHEDULE) {
            const scheduleTrigger = trigger as ScheduleTrigger;
            if (scheduleTrigger.cron) {
                trigger = update(scheduleTrigger, {
                    cron: {
                        timeZone: { $set: this.state.workflow.timeZone }
                    }
                });
            }
        }
        let workflow = this.state.workflow;
        workflow = update(workflow, {
            triggerType: { $set: trigger.type },
            trigger: { $set: trigger }
        });
        this.setState({
            workflow: workflow,
            isDirty: true
        });
        this.props.onTouch();
    }

    private handleWorkflowSave(callback: AfterSaveCallback): void {
        this.saveWorkflow(true, callback);
    }

    private removeFlows(workflow: Workflow, componentId: string): Workflow {
        // Remove any flows that reference the component.
        let i = 0;
        while (i < workflow.flows.length) {
            const flow = workflow.flows[i];
            const nextIds = FlowUtils.nextIds(flow);
            if (nextIds.includes(componentId)) {
                // Check if the component is in the downstream.
                workflow = update(workflow, {
                    flows: {
                        $splice: [[i, 1]]
                    }
                });
            } else {
                const prevId = flow.prevId;
                if (prevId === componentId) {
                    // Check if the component is in the upstream.
                    workflow = update(workflow, {
                        flows: {
                            $splice: [[i, 1]]
                        }
                    });
                } else {
                    i++;
                }
            }
        }
        return workflow;
    }

    public saveWorkflow(isTesting: boolean, callback?: AfterSaveCallback): void {
        if (!this.hasStartTransition()) {
            this.props.onError(new Error("Workflow does not have a starting flow."))
            return;
        }
        // Validate the properties and trigger sections. Variables, processors and 
        // transitions have already been validated when they were edited (panels only 
        // show the results of sub-editors).
        this.propertiesRef.current?.validateFields().then(values => {
            this.triggerRef.current?.validateFields().then(values => {
                const workflow = this.state.workflow;
                if (workflow.id) {
                    this.updateWorkflowRequest(workflow, isTesting, callback);
                } else {
                    this.createWorkflowRequest(workflow, isTesting, callback);
                }
            }).catch(e => {
                this.props.onError(new Error("Please fill in all fields in the 'Schedule' section."));
            });
        }).catch(e => {
            this.props.onError(new Error("Please fill in all fields in the 'Properties' section."));
        });
    }

    private hasStartTransition(): boolean {
        for (const transition of this.state.workflow.flows) {
            if (!transition.prevId) {
                return true;
            }
        }
        return false;
    }

    private readProvidersRequest(): Promise<any> {
        if (this.state.providers) {
            return Promise.resolve(true);
        }
        const request = {};
        return endpointService.readProviders(request,
            (response: any) => this.readProvidersResponse(response),
            undefined, true
        );
    }

    private readProvidersResponse(response: any): void {
        const providers = response.data.providers;
        this.setState({ providers: providers });
    }

    private readConverterMapRequest(): Promise<any> {
        const request = {
        };
        return endpointService.readConverterMap(request,
            (response: any) => this.readConverterMapResponse(response),
            undefined, true
        );
    }

    private readConverterMapResponse(response: any): any {
        const converterMap = response.data.converterMap;
        for (const [key, value] of Object.entries(converterMap)) {
            const types = value as IoType[];
            const typeSet = new Set();
            for (const type of types) {
                typeSet.add(type);
            }
            converterMap[key] = typeSet;
        }
        this.setState({ converterMap: converterMap });
    }

    private readAuthenticationHeadersRequest(): Promise<any> {
        const request = {};
        return endpointService.readAuthenticationHeaders(request,
            (response: any) => this.readAuthenticationHeadersResponse(response),
            undefined, true
        );
    }

    private readAuthenticationHeadersResponse(response: any): any {
        const authentications = response.data.headers;
        this.setState({ authentications: authentications });
    }

    private readProcessorHeadersRequest(): Promise<any> {
        const request = {};
        return endpointService.readProcessorHeaders(request,
            (response: any) => this.readProcessorHeadersResponse(response),
            undefined, true
        );
    }

    private readProcessorHeadersResponse(response: any): void {
        let processors = response.data.headers;
        this.setState({ processors: processors });
    }

    private readWorkflowRequest(): Promise<any> {
        const workflowId = this.props.workflowId;
        if (!workflowId || workflowId === 'create') {
            return Promise.resolve(true);
        }
        const request = {
            workflowId: workflowId,
            version: 0
        };
        return workflowService.readWorkflow(request,
            (response: any) => this.readWorkflowResponse(response),
            undefined, true
        );
    }

    private readWorkflowResponse(response: any): void {
        const workflow: Workflow = response.data.workflow;
        this.setState({ 
            workflow: workflow,
            useDelegate: !!workflow.delegate
        });
    }

    private createWorkflowRequest(workflow: Workflow, isTesting: boolean, callback?: AfterSaveCallback): Promise<any> {
        this.props.onSaving(workflow, isTesting);
        const request = {
            type: WorkflowType.ASYNCHRONOUS,
            name: workflow.name,
            description: workflow.description,
            isEnabled: workflow.isEnabled,
            timeZone: workflow.timeZone,
            trigger: workflow.trigger,
            components: workflow.components,
            flows: workflow.flows,
            configurationSpecs: workflow.configurationSpecs,
            useDelegate: this.state.useDelegate
        };
        return workflowService.createWorkflow(request,
            (response: any) => this.createWorkflowResponse(response, isTesting, callback),
            (response: any) => this.saveException(response),
            true
        );
    }

    private createWorkflowResponse(response: any, isTesting: boolean, callback?: AfterSaveCallback): void {
        const workflow = response.data.workflow;
        if (callback) {
            callback(workflow.id);
        }
        this.setState({
            workflow: workflow,
            isDirty: false
        });
        this.props.onSave(workflow, isTesting);
    }

    private updateWorkflowRequest(workflow: Workflow, isTesting: boolean, callback?: AfterSaveCallback): Promise<any> {
        this.props.onSaving(workflow, isTesting);
        const request = {
            workflowId: workflow.id,
            //type: WorkflowType.ASYNCHRONOUS,
            name: workflow.name,
            description: workflow.description,
            //isEnabled: workflow.isEnabled,
            timeZone: workflow.timeZone,
            trigger: workflow.trigger,
            components: workflow.components,
            flows: workflow.flows,
            configurationSpecs: workflow.configurationSpecs,
            useDelegate: this.state.useDelegate
        };
        return workflowService.updateWorkflow(request,
            (response: any) => this.updateWorkflowResponse(response, isTesting, callback),
            (response: any) => this.saveException(response),
            true
        );
    }

    private updateWorkflowResponse(response: any, isTesting: boolean, callback?: AfterSaveCallback): void {
        const workflow = response.data.workflow;
        if (callback) {
            callback(workflow.id);
        }
        this.setState({
            workflow: workflow,
            isDirty: false
        });
        this.props.onSave(workflow, isTesting);
    }

    private saveException(response: any): void {
        const message = RestUtils.getErrorMessage(response);
        this.props.onError(new Error(message));
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSkeleton
                count={4}
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load workflow."
                onRetry={this.handleRetryLoad}
            >
                <LoadSkeleton.Input length="fill" />
            </LoadSkeleton>
        );
    }

    private buildFormView(): ReactElement {
        return (
            <Collapse defaultActiveKey={["properties"]}>
                <Collapse.Panel key="properties" header="Properties" forceRender={true}>
                    <Form ref={this.propertiesRef}>
                        <Row gutter={Globals.FORM_GUTTER_ROW}>
                            <Col span={12}>
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.propertiesRef}
                                    label="Name"
                                    name="name"
                                    info="The name of the workflow."
                                    rules={[{
                                        required: true,
                                        message: 'Please enter a name.'
                                    }]}
                                >
                                    <Input
                                        placeholder="Workflow name."
                                        value={this.state.workflow.name}
                                        onChange={this.handleNameChange}
                                    />
                                </FormItem>
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.propertiesRef}
                                    label="Description"
                                    name="description"
                                    info="A description of the workflow."
                                    rules={[{
                                        required: false,
                                        message: 'Please enter a description.'
                                    }]}
                                >
                                    <Input.TextArea
                                        placeholder="Workflow description."
                                        rows={3}
                                        value={this.state.workflow.description}
                                        onChange={this.handleDescriptionChange}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.propertiesRef}
                                    label="Single Data Instance"
                                    name="delegate"
                                    info="When enabled, any data that this workflow creates will be stored in a single instance and will be shared by other users. Otherwise, each user will be given their own personal copy of data."
                                >
                                    <Switch
                                        checked={this.state.useDelegate}
                                        onChange={this.handleUseDelegateChange}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Collapse.Panel>
                <Collapse.Panel key="variables" header="Variables" forceRender={true}>
                    <Specs
                        providers={this.state.providers}
                        variableSpecs={this.state.workflow.configurationSpecs}
                        onEdit={this.handleSubEdit}
                        onChange={this.handleVariablesChange}
                    />
                </Collapse.Panel>
                <Collapse.Panel key="schedule" header="Schedule" forceRender={true}>
                    <Form ref={this.triggerRef}>
                        <Row gutter={Globals.FORM_GUTTER_ROW}>
                            <Col span={12}>
                                {/* <ScheduleEditor
                                    formRef={this.triggerRef}
                                    trigger={this.state.workflow.trigger as ScheduleTrigger}
                                    onChange={this.handleTriggerChange}
                                /> */}
                                <TriggerEditor
                                    formRef={this.triggerRef}
                                    trigger={this.state.workflow.trigger}
                                    components={this.state.workflow.components}
                                    transitions={this.state.workflow.flows}
                                    onChange={this.handleTriggerChange}
                                />
                            </Col>
                            <Col span={12}>
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.triggerRef}
                                    label="Time Zone"
                                    name="timezone"
                                    info="The time zone in which the workflow will run."
                                    rules={[{
                                        required: true,
                                        message: 'Please enter a time zone.'
                                    }]}
                                >
                                    <TimezoneSelector
                                        value={this.state.workflow.timeZone}
                                        onChange={this.handleTimeZoneChange}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Collapse.Panel>
                <Collapse.Panel key="processors" header="Processors" forceRender={true}>
                    <Processors
                        headers={this.state.processors}
                        components={this.state.workflow.components}
                        variableSpecs={this.state.workflow.configurationSpecs}
                        configuration={this.state.configuration}
                        authentications={this.state.authentications}
                        allowAssignment={true}
                        allowTest={false}
                        onEdit={this.handleSubEdit}
                        onUpdate={this.handleConfigurationChange}
                        onChange={this.handleComponentsChange}
                    />
                </Collapse.Panel>
                <Collapse.Panel key="flows" header="Flows" forceRender={true}>
                    <Flows
                        workflowId={this.state.workflow.id}
                        isDirty={this.state.isDirty}
                        headers={this.state.processors}
                        components={this.state.workflow.components}
                        flows={this.state.workflow.flows}
                        converterMap={this.state.converterMap}
                        configurationSpecs={this.state.workflow.configurationSpecs}
                        authentications={this.state.authentications}
                        onEdit={this.handleSubEdit}
                        onSave={this.handleWorkflowSave}
                        onChange={this.handleFlowsChange}
                    />
                </Collapse.Panel>
            </Collapse>
        );
    }

    private loadData() {
        const requests = [];
        requests.push(this.readWorkflowRequest());
        requests.push(this.readProvidersRequest());
        requests.push(this.readConverterMapRequest());
        requests.push(this.readProcessorHeadersRequest());
        requests.push(this.readAuthenticationHeadersRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1, r2, r3, r4, r5) => {
            if (RestUtils.isOk(r1, r2, r3, r4, r5)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    public componentDidMount() {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData();
        }
    }

    public render() {
        let view;
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === Globals.STATUS_READY) {
            view = this.buildFormView();
        }
        return (
            <div className={classNames('x-workfloweditor', this.props.className)}>
                {view}
            </div>
        );
    }

}
