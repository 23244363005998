import { PureComponent, ReactElement } from 'react';
import { BookOutlined, DeleteOutlined, EditOutlined, SyncOutlined } from '@ant-design/icons';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Tag } from 'antd';
import { Globals } from 'constants/Globals';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { RouteBuilder } from 'utils/RouteBuilder';
import { ColumnsType } from 'antd/lib/table';
import { CoreUtils } from 'utils/CoreUtils';
import { RestUtils } from 'utils/RestUtils';
import { ModelHeader } from '@methodset/model-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { ModuleType, PackHeader } from '@methodset/library-client-ts';
import { StatusType } from 'constants/StatusType';
import { PermissionUtils } from 'utils/PermissionUtils';
import { EntityContext } from 'context/EntityContext';
import { AccessType } from '@methodset/commons-core-ts';
import { VersionPackager } from '../VersionEditor-REMOVE/VersionPackager/VersionPackager';
import axios from 'axios';
import update from 'immutability-helper';
import modelService from 'services/ModelService';
import './Models.less';

//type EditMode = EditorMode | "none";

interface ProcessData {
    //mode: EditMode,
    model?: ModelHeader,
    error?: Error,
    isProcessing?: boolean
}

export type ModelsProps = RouteComponentProps & {
    className?: string
}

export type ModelsState = {
    status: StatusType,
    headers: ModelHeader[],
    process: ProcessData
}

export class Models extends PureComponent<ModelsProps, ModelsState> {

    static contextType = EntityContext;

    constructor(props: ModelsProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            headers: [],
            process: {
                //mode: "none",
                isProcessing: false
            }
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
        this.handleModelCreate = this.handleModelCreate.bind(this);
        this.handleModelEdit = this.handleModelEdit.bind(this);
        this.handleModelDelete = this.handleModelDelete.bind(this);
        this.handleShowPackage = this.handleShowPackage.bind(this);
        //handleShowRevert = this.handleShowRevert.bind(this);
        //this.handleShowDiscard = this.handleShowDiscard.bind(this);
        this.handlePackageDone = this.handlePackageDone.bind(this);
        //this.handleVersionRevert = this.handleVersionRevert.bind(this);
        this.handleVersionReset = this.handleVersionReset.bind(this);
        this.handlePackageCancel = this.handlePackageCancel.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleRefreshClick(): void {
        this.loadData();
    }

    private handleModelCreate(): void {
        if (!PermissionUtils.checkLimit(this.context, "component.model.count", "model", this.state.headers.length)) {
            return;
        }
        const url = RouteBuilder.model("create", "calculator");
        this.props.history.push(url);
    }

    private handleModelEdit(model: ModelHeader): void {
        const url = RouteBuilder.model(model.id, "calculator")
        this.props.history.push(url);
    }

    private handleModelDelete(model: ModelHeader): void {
        this.deleteModelRequest(model.id);
    }

    private handleShowPackage(model: ModelHeader): void {
        const process = update(this.state.process, {
            //mode: { $set: "publish" },
            model: { $set: model }
        });
        this.setState({ process: process });
    }

    // private handleShowRevert(model: ModelHeader): void {
    //     const process = update(this.state.process, {
    //         mode: { $set: "revert" },
    //         model: { $set: model }
    //     });
    //     this.setState({ process: process });
    // }

    // private handleShowDiscard(model: ModelHeader): void {
    //     const process = update(this.state.process, {
    //         mode: { $set: "reset" },
    //         model: { $set: model }
    //     });
    //     this.setState({ process: process });
    // }

    private handlePackageDone(header: PackHeader): void {
        const response = {
            data: {
                header: header
            }
        }
        this.versionResponse(response);
    }

    // private handleVersionRevert(header: PackHeader): void {
    //     const response = {
    //         data: {
    //             header: header
    //         }
    //     }
    //     this.versionResponse(response);
    // }

    // private handleVersionRevert(version: number): void {
    //     const model = this.state.process.model!;
    //     this.revertVersionRequest(model.id, version);
    // }

    private handleVersionReset(): void {
        const model = this.state.process.model!;
        this.resetVersionRequest(model.id);
    }

    private handlePackageCancel(): void {
        const version = update(this.state.process, {
            //mode: { $set: "none" },
            model: { $set: undefined },
            isProcessing: { $set: false }
        });
        this.setState({ process: version });
    }

    private deleteModelRequest(modelId: string): Promise<any> {
        const request = {
            modelId: modelId
        };
        return modelService.archiveModel(request,
            (response: any) => this.deleteModelResponse(response)
        );
    }

    private deleteModelResponse(response: any): void {
        const modelId = response.data.modelId;
        const index = this.state.headers.findIndex(model => model.id === modelId);
        this.setState({
            headers: update(this.state.headers, {
                $splice: [[index, 1]]
            })
        });
    }

    private resetVersionRequest(modelId: string): Promise<any> {
        const version = update(this.state.process, {
            error: { $set: undefined },
            isProcessing: { $set: true }
        });
        this.setState({ process: version });
        const request = {
            modelId: modelId
        };
        return modelService.resetVersion(request,
            (response: any) => this.versionResponse(response),
            (response: any) => this.versionException(response),
            true
        );
    }

    // private revertVersionRequest(modelId: string, version: number): Promise<any> {
    //     const updated = update(this.state.process, {
    //         error: { $set: undefined },
    //         isProcessing: { $set: true }
    //     });
    //     this.setState({ process: updated });
    //     const request = {
    //         packId: modelId,
    //         version: version
    //     };
    //     return libraryService.revertPack(request,
    //         (response: any) => this.versionResponse(response),
    //         (response: any) => this.versionException(response),
    //         true
    //     );
    // }

    private versionResponse(response: any): void {
        const header = response.data.header;
        const modelId = header.moduleId;
        const index = this.state.headers.findIndex(model => model.id === modelId);
        this.setState({
            headers: update(this.state.headers, {
                [index]: {
                    version: { $set: header.version },
                    updateTime: { $set: header.updateTime }
                }
            })
        });
        const process = update(this.state.process, {
            //mode: { $set: "none" },
            model: { $set: undefined },
            isProcessing: { $set: false }
        });
        this.setState({ process: process });
    }

    private versionException(response: any): void {
        const error = new Error(RestUtils.getErrorMessage(response));
        const version = update(this.state.process, {
            error: { $set: error },
            isProcessing: { $set: false }
        });
        this.setState({ process: version });
    }

    private readModelsRequest(): Promise<any> {
        const request = {
            accessType: AccessType.UNPUBLISHED,
            useLatest: true
        };
        return modelService.readModelHeaders(request,
            (response: any) => this.readModelsResponse(response),
            undefined, true
        );
    }

    private readModelsResponse(response: any): void {
        const headers = response.data.headers;
        this.setState({ headers: headers });
    }

    private buildColumns(): ColumnsType<any> {
        let i = 0;
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
            ellipsis: true,
            width: Globals.TABLE_WIDTH_LARGE,
            render: (value: string, record: any) => (
                <Link to={RouteBuilder.model(record.id, "calculator")}>{value}</Link>
            ),
            sorter: (a: any, b: any) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'description',
            title: 'Description',
            ellipsis: true,
            dataIndex: 'description',
            render: (value: string, record: any) => {
                return (
                    <span>{record.description ? record.description : Globals.EMPTY_FIELD}</span>
                );
            },
            sorter: (a: any, b: any) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'tags',
            title: 'Tags',
            align: 'center',
            dataIndex: 'keywords',
            render: (value: string[], record: any) => {
                if (record.keywords) {
                    return record.keywords.map((keyword: string, index: number) => (
                        <Tag key={index} color={Globals.TAG_COLORS[i++ % Globals.TAG_COLORS.length]}>{keyword}</Tag>
                    ));
                } else {
                    return Globals.EMPTY_FIELD;
                }
            },
            sorter: (a: any, b: any) => CoreUtils.compareStrings(a.categoryType, b.categoryType),
            sortDirections: ['ascend', 'descend']
        }, {
            key: 'version',
            title: 'Latest Version',
            align: 'center',
            dataIndex: 'version',
            width: Globals.TABLE_WIDTH_MEDIUM,
            render: (value: number, record: any) => {
                return (
                    <span>
                        {CoreUtils.toVersion(record.version)}
                    </span>
                );
            },
            sorter: (a: any, b: any) => CoreUtils.compareNumbers(a.version, b.version),
            sortDirections: ['descend', 'ascend']
        }, {
            key: 'utime',
            title: 'Last Updated',
            align: 'center',
            dataIndex: 'updateTime',
            width: Globals.TABLE_WIDTH_MEDIUM,
            render: (value: any, record: any) => {
                return (
                    <span>
                        {CoreUtils.toTime(record.updateTime)}
                    </span>
                );
            },
            sorter: (a: any, b: any) => CoreUtils.compareNumbers(a.updateTime, b.updateTime),
            sortDirections: ['descend', 'ascend']
        }];
        return columns;
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readModelsRequest());
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== StatusType.READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        const actions = [{
            icon: <EditOutlined />,
            label: "Edit model",
            callback: this.handleModelEdit
        }, {
            icon: <BookOutlined />,
            label: "Package version...",
            callback: this.handleShowPackage
            //}, {
            //     icon: <UndoOutlined />,
            //     label: "Revert version",
            //     disabled: (model: Model) => model.version < 2,
            //     callback: this.handleShowRevert
            // }, {
            // icon: <ScissorOutlined />,
            // label: "Discard changes",
            // callback: this.handleShowDiscard
        }, {
            icon: <DeleteOutlined />,
            label: "Delete model",
            confirm: "Are you sure you want to delete the model?",
            callback: this.handleModelDelete
        }];
        //     icon: <NumberOutlined />,
        //     label: "Versioning",
        //     children: [{
        //         icon: <BookOutlined />,
        //         label: "Publish version",
        //         callback: this.handleShowPublish
        //     }, {
        //         icon: <UndoOutlined />,
        //         label: "Revert version",
        //         disabled: (model: Model) => model.version < 2,
        //         callback: this.handleShowRevert
        //     }, {
        //         icon: <ScissorOutlined />,
        //         label: "Discard changes",
        //         callback: this.handleShowDiscard
        //     }]
        // }];
        const columns = this.buildColumns();
        const data = this.state.headers;
        return (
            <>
                <ItemTable
                    title="Models"
                    status={this.state.status}
                    columns={columns}
                    items={data}
                    extra={
                        <Spacer>
                            <Button onClick={this.handleModelCreate}>New Model</Button>
                            <Button icon={<SyncOutlined />} onClick={this.handleRefreshClick}></Button>
                        </Spacer>
                    }
                    actions={actions}
                    onLoad={this.handleRetryLoad}
                />
                {this.state.process.model &&
                    <VersionPackager
                        id={this.state.process.model.id}
                        name={this.state.process.model.name}
                        description={this.state.process.model.description}
                        moduleId={this.state.process.model.id}
                        moduleType={ModuleType.MODEL}
                        onPackage={this.handlePackageDone}
                        onCancel={this.handlePackageCancel}
                    />
                }
                {/* 
                {EditorValues.includes(this.state.process.mode) && this.state.process.model &&
                    <VersionEditor
                        id={this.state.process.model.id}
                        type={PackType.MODEL}
                        name={this.state.process.model.name}
                        description={this.state.process.model.description}
                        details={{
                            type: PackType.MODEL,
                            modelId: this.state.process.model.id,
                            version: 0 // snapshot version
                        } as ModelDetails}
                        mode={this.state.process.mode as EditorMode }
                        version={this.state.process.model.version}
                        error={this.state.process.error}
                        isProcessing={this.state.process.isProcessing}
                        onPackage={this.handleVersionPackage}
                        //onRevert={this.handleVersionRevert}
                        onReset={this.handleVersionReset}
                        onCancel={this.handleVersionCancel}
                    />
                } */}
            </>
        );
    }

}
