import { Chapter } from 'components/Chapter/Chapter';
import { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DocFrame } from '../DocFrame/DocFrame';

export type ApisDocProps = RouteComponentProps;

export const ApiDoc = (props: ApisDocProps): ReactElement => {

    return (
        <DocFrame title="APIs" {...props}>
            <Chapter>
                An API provides access to a dataset through a REST query. For example, a dataset may be created and stored 
                in a managed database via a workflow or a dataset may be created via a model that includes complex logic to 
                compute the final output. These datasets can then be retrieved through a secure API to be used in external 
                applications, such as Excel, a web page, or a mobile app. This allows you to use the platform as a backend 
                to prepare and calculate data for import into client-facing applications, so that all the processing logic 
                is separated out from the front end and maintained independently.
            </Chapter>
        </DocFrame>
    );

}
