import { FC, ReactElement } from 'react';
import classNames from 'classnames';
import './BlockCell.less';

export type BlockCellProps = {
    className?: string,
    value: any
}

export const BlockCell: FC<BlockCellProps> = (props: BlockCellProps): ReactElement => {

    return (
        <div className={classNames("x-blockcell", props.className)}>
            {props.value}
            <div className="x-blockcell-mark"></div>
        </div>
    );

}
