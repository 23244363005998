import { ReactElement } from 'react';
import { Select } from 'antd';
import { ColorType } from '@methodset/application-client-ts';

export type ChangeCallback = (value: ColorType) => void;

export type ColorSelectorProps = {
    value?: ColorType,
    onChange: ChangeCallback
};

export const ColorSelector = (props: ColorSelectorProps): ReactElement => {
    return (
        <Select
            allowClear={true}
            placeholder="Select a color."
            value={props.value}
            onChange={(value) => props.onChange(value)}
        >
            <Select.Option key="black" value={ColorType.BLACK}>Black</Select.Option>
            <Select.Option key="white" value={ColorType.WHITE}>White</Select.Option>
            <Select.Option key="lightgray" value={ColorType.LIGHTGRAY}>Light Gray</Select.Option>
            <Select.Option key="gray" value={ColorType.GRAY}>Gray</Select.Option>
            <Select.Option key="darkgray" value={ColorType.DARKGRAY}>Dark Gray</Select.Option>
            <Select.Option key="blue" value={ColorType.BLUE}>Blue</Select.Option>
            <Select.Option key="red" value={ColorType.RED}>Red</Select.Option>
            <Select.Option key="green" value={ColorType.GREEN}>Green</Select.Option>
            <Select.Option key="purple" value={ColorType.PURPLE}>Purple</Select.Option>
            <Select.Option key="pink" value={ColorType.PINK}>Pink</Select.Option>
            <Select.Option key="orange" value={ColorType.ORANGE}>Orange</Select.Option>
            <Select.Option key="yellow" value={ColorType.YELLOW}>Yellow</Select.Option>
        </Select>
    )
}
