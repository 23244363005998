import { FC, ReactElement } from 'react';
import { Popconfirm } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import './Confirm.less';

export type ConfirmCallback = (data?: any) => void;

export type ConfirmProps = {
    placement?: TooltipPlacement,
    children?: any,
    data?: any,
    message: string,
    label?: string,
    icon?: ReactElement,
    disabled?: boolean,
    onConfirm: ConfirmCallback,
}

export const Confirm: FC<ConfirmProps> = (props: ConfirmProps): ReactElement => {

    const handleConfirmClick = (): void => {
        props.onConfirm(props.data);
    }

    return (
        <div className="x-confirm">
            <Popconfirm
                placement={props.placement}
                title={props.message}
                disabled={props.disabled}
                onConfirm={handleConfirmClick}
                okText={props.label ? props.label : "Ok"}
                cancelText="Cancel"
            >
                {props.children}
            </Popconfirm>
        </div>
    )

}
