import { PureComponent, ReactElement } from 'react';
import { Button } from 'antd';
import { Applet, MenuItem } from '@methodset/application-client-ts';
import { MenuItemEditor } from './MenuItemEditor/MenuItemEditor';
import { Spacer } from 'components/Spacer/Spacer';
import update from 'immutability-helper';
import './MenuItems.less';

export type ChangeCallback = (items: MenuItem[]) => void;

export type MenuItemsProps = typeof MenuItems.defaultProps & {
    applet: Applet,
    menu?: MenuItem[],
    onChange: ChangeCallback
}

export class MenuItems extends PureComponent<MenuItemsProps> {

    static defaultProps = {
        menu: [] as MenuItem[]
    }

    constructor(props: MenuItemsProps) {
        super(props);
        this.handleItemAdd = this.handleItemAdd.bind(this);
        this.handleItemChange = this.handleItemChange.bind(this);
        this.handleItemRemove = this.handleItemRemove.bind(this);
    }

    private handleItemAdd(): void {
        const item = {} as MenuItem;
        const items = update(this.props.menu, {
            $push: [item]
        });
        this.props.onChange(items);
    }

    private handleItemRemove(index: number): void {
        const items = update(this.props.menu, {
            $splice: [[index, 1]]
        });
        this.props.onChange(items);
    }

    private handleItemChange(item: MenuItem, index: number): void {
        const items = update(this.props.menu, {
            [index]: { $set: item }
        });
        this.props.onChange(items);
    }

    public render(): ReactElement {
        return (
            <Spacer className="x-menuitems" direction="vertical" alignment="top" fill>
                {this.props.menu.map((item, index) => (
                    <MenuItemEditor
                        key={index}
                        index={index}
                        applet={this.props.applet}
                        item={item}
                        usedIds={this.props.menu.map(item => item.panelId)}
                        onChange={this.handleItemChange}
                        onRemove={this.handleItemRemove}
                    />
                ))}
                <div className="x-menuitems-add">
                    <Button
                        disabled={this.props.menu.length === this.props.applet.panels.length}
                        onClick={this.handleItemAdd}
                    >
                        Add Item
                    </Button>
                </div>
            </Spacer>
        );
    }
}
