import { ReactElement } from 'react';
import { Spacer } from 'components/Spacer/Spacer';
import { Radio, RadioChangeEvent } from 'antd';
import { IntegerInput } from 'components/IntegerInput/IntegerInput';
import { CoreUtils } from 'utils/CoreUtils';
import './HeightEditor.less';

export type ChangeCallback = (height: number | undefined) => void;

export type HeightEditorProps = {
    value?: number,
    onChange: ChangeCallback
};

export const HeightEditor = (props: HeightEditorProps): ReactElement => {

    const handleTypeChange = (e: RadioChangeEvent): void => {
        const type = e.target.value;
        if (type === "default") {
            props.onChange(undefined);
        } else if (type == "fixed") {
            props.onChange(300);
        }
    }

    const handleHeightChange = (height: number): void => {
        props.onChange(height);
    }

    return (
        <Spacer size="none">
            <Radio.Group
                className="x-heighteditor"
                value={CoreUtils.isEmpty(props.value) ? "default" : "fixed"}
                onChange={handleTypeChange}
            >
                <Spacer className="x-heighteditor-item" size="lg">
                    <Radio value="default">Detault</Radio>
                    <Radio value="fixed">Fixed</Radio>
                </Spacer>
            </Radio.Group>
            <IntegerInput
                className="x-heighteditor-px"
                fill={true}
                justification="right"
                units="px"
                disabled={CoreUtils.isEmpty(props.value)}
                natural={true}
                placeholder="height"
                value={props.value}
                onChange={handleHeightChange}
            />
        </Spacer>
    )

}
