import { FC, ReactElement } from 'react';
import { Dropdown, Menu } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, FormatPainterOutlined } from '@ant-design/icons';
import { Format, LocationType, Sheet } from '@methodset/calculator-ts';
import { FormatMenu } from '../FormatMenu/FormatMenu';
import './RowHeader.less';

export type RowHeaderProps = {
    sheet: Sheet,
    data: any
}

export const RowHeader: FC<RowHeaderProps> = (props: RowHeaderProps): ReactElement => {

    const id = props.data.row;

    const handleRowAboveInsert = (): void => {
        props.sheet.insertRow(id, LocationType.ABOVE);
    }

    const handleRowBelowInsert = (): void => {
        props.sheet.insertRow(id, LocationType.BELOW);
    }

    const handleRowFormat = (format: Format | undefined): void => {
        const row = props.sheet.getRow(id);
        row.format = format;
    }

    const handleRowDelete = (): void => {
        props.sheet.deleteRow(id);
    }

    const menu = (): ReactElement => {
        const row = props.sheet.getRow(id, false);
        return (
            <Menu>
                <Menu.Item key="insert-above" icon={<ArrowUpOutlined />} onClick={handleRowAboveInsert}>
                    <span>Insert row above</span>
                </Menu.Item>
                <Menu.Item key="insert-below" icon={<ArrowDownOutlined />} onClick={handleRowBelowInsert}>
                    <span>Insert row below</span>
                </Menu.Item>
                <Menu.SubMenu key="format" icon={<FormatPainterOutlined />} title="Format row">
                    <FormatMenu
                        format={row ? row.format : undefined}
                        onChange={handleRowFormat}
                    />
                </Menu.SubMenu>
                <Menu.Item key="delete" icon={<DeleteOutlined />} onClick={handleRowDelete}>
                    <span>Delete row</span>
                </Menu.Item>
            </Menu>
        );
    }

    return (
        <Dropdown overlay={menu} trigger={['contextMenu']}>
            <div className="x-rowheader">
                {id}
            </div>
        </Dropdown>
    );

}