import { ReactElement } from 'react';
import { ConverterMap, Flow, FlowType, IoMap, Link, ParallelFlow } from '@methodset/endpoint-client-ts';
import { Button, Col, FormInstance, Row, Select } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { DeleteOutlined } from '@ant-design/icons';
import { ChangeCallback, OutputComponents, State } from '../FlowEditor';
import { IoMapEditor } from '../IoMapEditor/IoMapEditor';
import { Justify } from 'components/Justify/Justify';
import { ComponentMap } from '../../Flows';
import update from 'immutability-helper';
import './ParallelEditor.less';

export type ParallelEditorProps = {
    formRef: React.RefObject<FormInstance>,
    index: number,
    // Ids uses as prevs.
    prevIds: string[],
    // Ids used as nexts.
    nextIds: string[],
    parentFlow?: Flow,
    flow: ParallelFlow,
    states: State[],
    outputComponents: OutputComponents,
    componentMap: ComponentMap,
    converterMap: ConverterMap,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
}

const newFlow = (): ParallelFlow => {
    return {
        type: FlowType.PARALLEL,
        prevId: undefined,
        startLinks: [{
            ioMap: {} as IoMap
        }] as Link[]
    } as ParallelFlow;
}

export const ParallelEditor = (props: ParallelEditorProps): ReactElement => {

    const handleStartChange = (index: number, startId: string): void => {
        const flow = update(props.flow, {
            startLinks: {
                [index]: {
                    id: { $set: startId }
                }
            }
        });
        props.onChange(flow);
    }

    const handleStartLinkChange = (link: Link, index: number): void => {
        const flow = update(props.flow, {
            startLinks: {
                [index]: { $set: link }
            }
        });
        props.onChange(flow);
    }

    const handleStartDelete = (index: number): void => {
        const flow = update(props.flow, {
            startLinks: {
                $splice: [[index, 1]]
            }
        });
        props.onChange(flow);
    }

    const handleStartAdd = (): void => {
        const flow = update(props.flow, {
            startLinks: {
                $push: [{
                    ioMap: {} as IoMap
                } as Link]
            }
        });
        props.onChange(flow);
    }

    return (
        <div className="x-paralleleditor">
            <Row gutter={Globals.FORM_GUTTER_COL}>
                {props.flow.startLinks?.map((startLink, index) => (
                    <>
                        <Col span={12}>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={props.formRef}
                                label={`Branch ${index + 1}`}
                                name={`branch-${index + 1}`}
                                info="The processor or flow to execute as one of the branches."
                                postfix={(
                                    <Button
                                        className={index === 0 ? "x-paralleleditor-hide" : ""}
                                        icon={<DeleteOutlined />}
                                        onClick={() => handleStartDelete(index)}
                                    />
                                )}
                                rules={[{
                                    required: true,
                                    message: "Please select a processor or flow to branch to."
                                }]}
                            >
                                <Select
                                    placeholder="Select a branch."
                                    value={startLink.id}
                                    onChange={(value) => handleStartChange(index, value)}
                                >
                                    {props.states.map(state => (
                                        <Select.Option
                                            key={state.id}
                                            value={state.id}
                                            disabled={props.nextIds.includes(state.id) || props.flow.id === state.id}
                                        >
                                            {state.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            {!!props.flow.prevId && !!startLink.id &&
                                <IoMapEditor
                                    formRef={props.formRef}
                                    visible={true}
                                    parentFlow={props.parentFlow}
                                    link={startLink}
                                    index={index}
                                    componentMap={props.componentMap}
                                    converterMap={props.converterMap}
                                    outputComponents={props.outputComponents}
                                    onChange={(link) => handleStartLinkChange(link, index)}
                                />
                            }
                        </Col>
                    </>
                ))}
            </Row>
            <Row gutter={Globals.FORM_GUTTER_COL}>
                <Col span={12}>
                    <Justify justification="right">
                        <Button onClick={() => handleStartAdd()}>Add Branch</Button>
                    </Justify>
                </Col>
            </Row>
        </div>
    )
}

ParallelEditor.defaultProps = defaultProps;
ParallelEditor.newFlow = newFlow;
