import { PureComponent, ReactElement } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { SyncOutlined } from '@ant-design/icons';
import { QueryHeader } from '@methodset/endpoint-client-ts';
import { Button, Space } from 'antd';
import { Globals } from 'constants/Globals';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { RouteBuilder } from 'utils/RouteBuilder';
import { ColumnsType } from 'antd/lib/table';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import classNames from 'classnames';
import endpointService from 'services/EndpointService';
import axios from 'axios';
import './Apis.less';
import { StatusType } from 'constants/StatusType';

export type ApisProps = RouteComponentProps & {
    className?: string
}

export type ApisState = {
    status: StatusType,
    headers: QueryHeader[]
}

export class Apis extends PureComponent<ApisProps, ApisState> {

    constructor(props: ApisProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            headers: [],
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
        this.handleKeysEdit = this.handleKeysEdit.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleRefreshClick(): void {
        this.loadData();
    }

    private handleKeysEdit(): void {
        this.props.history.push(RouteBuilder.CONSOLE_API_KEYS);
    }

    private readApiQueryHeadersRequest(): Promise<any> {
        const request = {};
        return endpointService.readApiQueryHeaders(request,
            (response: any) => this.readApiQueryHeadersResponse(response),
            undefined, true
        );
    }

    private readApiQueryHeadersResponse(response: any): void {
        const headers = response.data.headers;
        this.setState({ headers: headers });
    }

    buildColumns(): ColumnsType<any> {
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            width: Globals.TABLE_WIDTH_LARGE,
            render: (record) => {
                return <Link to={RouteBuilder.api(record.id, record.version)}>{record.name}</Link>
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'description',
            title: 'Description',
            render: (record) => {
                return (
                    <span>{record.description ? record.description : Globals.EMPTY_FIELD}</span>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.description, b.description),
            sortDirections: ['ascend', 'descend']
        }, {
            key: 'publisher',
            title: 'Publisher',
            //align: 'center',
            render: (record) => {
                return (
                    <span>{record.publisher ? record.publisher : Globals.EMPTY_FIELD}</span>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.publisher, b.publisher),
            sortDirections: ['ascend', 'descend']
        }, {
            key: 'version',
            title: 'Latest Version',
            width: Globals.TABLE_WIDTH_MEDIUM,
            align: 'center',
            render: (record) => {
                return (
                    <span>
                        {CoreUtils.toVersion(record.version)}
                    </span>
                );
            },
            sorter: (a: any, b: any) => CoreUtils.compareNumbers(a.version, b.version),
            sortDirections: ['descend', 'ascend']
        }, {
            key: 'utime',
            title: 'Last Updated',
            width: Globals.TABLE_WIDTH_MEDIUM,
            align: 'center',
            render: (record) => {
                return (
                    <span>
                        {CoreUtils.toTime(record.updateTime)}
                    </span>
                );
            },
            sorter: (a, b) => CoreUtils.compareNumbers(a.updateTime, b.updateTime),
            sortDirections: ['descend', 'ascend']
        }];
        return columns;
    }

    private buildData(): any {
        return this.state.headers;
    }

    private loadData() {
        const requests = [];
        requests.push(this.readApiQueryHeadersRequest());
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    public componentDidMount() {
        if (this.state.status !== StatusType.READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        const columns = this.buildColumns();
        const data = this.buildData();
        return (
            <ItemTable
                className={classNames('x-apis', this.props.className)}
                title="APIs"
                status={this.state.status}
                columns={columns}
                items={data}
                extra={
                    <Space>
                        <Button onClick={this.handleKeysEdit}>API Keys</Button>
                        <Button icon={<SyncOutlined />} onClick={this.handleRefreshClick}></Button>
                    </Space>
                }
                onLoad={this.handleRetryLoad}
            />
        );
    }

}
