import { PureComponent, ReactElement } from 'react';
import { Label } from 'components/Label/Label';
import { CellChangeEvent, Format, Parameter, Variable } from '@methodset/calculator-ts';
import { DeleteOutlined, EditOutlined, FormatPainterOutlined } from '@ant-design/icons';
import { ParameterCell } from './ParameterCell/ParameterCell';
import { VariableEditor, VariableData } from '../VariableEditor/VariableEditor';
import { ModelContext } from 'context/ModelContext';
import { MenuButton } from 'components/MenuButton/MenuButton';
import { Spacer } from 'components/Spacer/Spacer';
import { FormatMenu } from '../../Sheets/SheetItem/FormatMenu/FormatMenu';
import './ParameterItem.less';

export type DeleteCallback = (parameter: Parameter, index: number) => void;

export type ParameterItemState = {
    isEditing: boolean
}

export type ParameterItemProps = {
    className?: string,
    index: number,
    parameter: Parameter,
    onDelete: DeleteCallback
};

export class ParameterItem extends PureComponent<ParameterItemProps, ParameterItemState> {

    static contextType = ModelContext;

    private parameter: Parameter;

    constructor(props: ParameterItemProps) {
        super(props);
        this.state = {
            isEditing: false
        };
        this.parameter = this.props.parameter;
        this.handleParameterChange = this.handleParameterChange.bind(this);
        this.handleParameterRemove = this.handleParameterRemove.bind(this);
        this.handleParameterEdit = this.handleParameterEdit.bind(this);
        this.handleParameterFormat = this.handleParameterFormat.bind(this);
        this.handlePropertiesChange = this.handlePropertiesChange.bind(this);
        this.handlePropertiesCancel = this.handlePropertiesCancel.bind(this);
    }

    private handleParameterChange(event: CellChangeEvent): void {
        const param = event.cell as Parameter;
        const prev = event.prev as Parameter;
        const activeCell = this.context.active.cell;
        if (activeCell) {
            if (param.variable!.id !== prev.variable!.id && activeCell.variableId === prev.variable!.id) {
                // Update the variable name for all listeners.
                this.context.setFocusedCell(param.sheet, param.id, param.variable!.id);
            }
        }
        this.forceUpdate();
    }

    private handleParameterRemove(): void {
        this.props.onDelete(this.parameter, this.props.index);
    }

    private handleParameterEdit(): void {
        this.setState({
            isEditing: true
        });
    }

    private handleParameterFormat(format: Format | undefined): void {
        this.parameter.format = format;
    }

    private handlePropertiesChange(data: VariableData): void {
        const variable = Variable.to(data.id, data.name, data.description, data.type, data.isRequired, data.isInternal, data.options);
        this.parameter.variable = variable;
        this.setState({ isEditing: false });
    }

    private handlePropertiesCancel(): void {
        this.setState({ isEditing: false });
    }

    public render(): ReactElement {
        const items = [{
            icon: <EditOutlined />,
            label: "Edit",
            onSelect: this.handleParameterEdit
        }, {
            icon: <FormatPainterOutlined />,
            label: "Format",
            menu: (
                <FormatMenu
                    format={this.parameter.format}
                    onChange={this.handleParameterFormat}
                />
            )
        }, {
            icon: <DeleteOutlined />,
            label: "Remove",
            onSelect: this.handleParameterRemove
        }];
        const variable = this.props.parameter.variable!;
        return (
            <div className="x-parameteritem">
                <Label
                    placement="top"
                    label={this.parameter.variable.name}
                    info={this.parameter.variable.description}
                    extra={this.parameter.variable.id}
                    nowrap={false}
                >
                    <Spacer fill>
                        <ParameterCell
                            className="x-parameteritem-cell"
                            cell={this.parameter}
                            onChange={this.handleParameterChange}
                        />
                        <MenuButton items={items} />
                    </Spacer>
                </Label>
                {this.state.isEditing &&
                    <VariableEditor
                        data={{
                            id: variable.id,
                            name: variable.name,
                            description: variable.description,
                            type: variable.type,
                            isRequired: variable.isRequired,
                            isInternal: variable.isInternal,
                            options: variable.options
                        }}
                        title="Parameter Editor"
                        onChange={this.handlePropertiesChange}
                        onCancel={this.handlePropertiesCancel}
                    />
                }
            </div>
        );
    }
}
