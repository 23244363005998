import React, { ChangeEvent, Component, ReactElement } from "react";
import { ICellEditorParams } from 'ag-grid-community';
import { Input } from "antd";
import { ModelContext } from "context/ModelContext";
import { EmitterEvent } from "utils/EventEmitter";
import { CoreUtils } from "utils/CoreUtils";
import classNames from "classnames";
import './CellEditor.less';

export type CellEditorProps = ICellEditorParams & {
}

export type CellEditorState = {
    value: any
}

export class CellEditor extends Component<CellEditorProps, CellEditorState> {

    static contextType = ModelContext;

    private inputRef = React.createRef<Input>();

    constructor(props: CellEditorProps) {
        super(props);
        this.state = {
            value: this.findValue(props)
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormulaChangeEvent = this.handleFormulaChangeEvent.bind(this);
    }

    private findValue(props: CellEditorProps): any {
        // User started the editor by entering a key.
        if (props.eventKey) {
            return props.eventKey;
        }
        const colId = props.column.getColId();
        const data = props.data[colId];
        if (!data) {
            return undefined;
        }
        // Get the value to edit from the cell.
        const cell = data.cell;
        if (cell) {
            return cell.formula ? cell.formula : cell.value;
        } else {
            return undefined;
        }
    }

    private handleInputChange(e: ChangeEvent<HTMLInputElement>): void {
        const value = e.target.value;
        this.setState({ value: value });
        const data = {
            value: value
        }
        this.context.sendEvent("CellEditChange", data);
    }

    private handleFormulaChangeEvent(event: EmitterEvent): void {
        const value = event.data.value;
        this.setState({ value: value });
    }

    public getValue(): any {
        return this.state.value;
    }

    public componentDidMount(): void {
        // If the editor started because the formula editor was activated,
        // do not focus this editor and allow the formula editor to continue
        // to have focus.
        this.context.addCallback("FormulaEditChange", this.handleFormulaChangeEvent);
        if (this.props.charPress !== "FormulaEditor") {
            this.inputRef.current!.focus();
            if (this.state.value) {
                const data = {
                    value: this.state.value
                }
                this.context.sendEvent("CellEditChange", data)
            }
        } else {
            const value = this.props.eventKey;
            this.setState({value: value});
        }
    }

    public componentWillUnmount(): void {
        this.context.removeCallback("FormulaEditChange", this.handleFormulaChangeEvent);
    }

    public render(): ReactElement {
        // TODO: if cell variable has type, restrict input
        return (
            <Input
                className={classNames({ "x-celleditor-hilite": CoreUtils.isFormula(this.state.value) })}
                ref={this.inputRef}
                value={this.state.value}
                onChange={this.handleInputChange}
                style={{ width: "100%", height: "100%" }}
            />
        );
    }

}