import React, { PureComponent, ReactElement } from 'react';
import { Button, Modal } from 'antd';
import { ModelContext } from 'context/ModelContext';
import { QueryBuilder } from './QueryBuilder/QueryBuilder';
import { Cell, CellComponents, Formula, FormulaComposer, ParamItem, ParamType, QueryUtils, Range, RangeComponents, ReferenceParser, RefType, Sheet, Variable, VariableComponents } from '@methodset/calculator-ts';
import { Globals } from 'constants/Globals';
import { Configuration, ConfigurationSpec, ConfigurationType, Data, IoType, Query } from '@methodset/endpoint-client-ts';
import { Options, RestUtils } from 'utils/RestUtils';
import { Boolean, CoreUtils, IdUtils, Number, String, Version } from '@methodset/commons-core-ts';
import { ConfigurationUtils } from 'utils/ConfigurationUtils';
import { WorkflowSchedule } from '@methodset/workflow-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { TextMessage } from 'components/TextMessage/TextMessage';
import { Pack } from '@methodset/library-client-ts';
import workflowService from 'services/WorkflowService';
import './QueryDialog.less';

export type ChangeCallback = (formula: string) => void;
export type CancelCallback = () => void;

export type QueryDialogProps = {
    className?: string,
    cell: Cell,
    formula?: string,
    onChange: ChangeCallback,
    onCancel: CancelCallback
}

export type QueryDialogState = {
    isInstalling: boolean,
    error?: Error,
}

export class QueryDialog extends PureComponent<QueryDialogProps, QueryDialogState> {

    static contextType = ModelContext;

    private builderRef = React.createRef<QueryBuilder>();
    private queryId?: string;
    private version?: number;
    private configuration?: Configuration;
    private options?: Options;

    constructor(props: QueryDialogProps) {
        super(props);
        this.state = {
            isInstalling: false
        };
        this.handleQueryTouch = this.handleQueryTouch.bind(this);
        this.handleQueryChange = this.handleQueryChange.bind(this);
        this.handleOkClick = this.handleOkClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
    }

    private handleQueryTouch(): void {
        this.setState({ error: undefined });
    }

    private queryCount(queryId: string): number {
        let count = 0;
        const calculator = this.context.calculator;
        const sheets = calculator.sheets;
        sheets.forEach((sheet: Sheet) => {
            const fnCells = sheet.tracker.fnCellsWith("QUERY");
            for (const fnCell of fnCells) {
                const formula = fnCell.cell?.formula;
                if (formula && formula.includes(queryId)) {
                    count += 1;
                }
            }
        });
        return count;
    }

    private handleQueryChange(query: Query, pack: Pack, configuration: Configuration, options: Options): void {
        const calculator = this.context.calculator;
        let formula;
        try {
            formula = this.buildFormula(query.id, query.version, configuration, options);
        } catch (e) {
            this.setState({ error: e as Error });
            return;
        }
        // Check if formula can be parsed. Since an overall parse error 
        // would prevent it from being edited, check here so that it can
        // be fixed before the edit dialog is dismissed.
        if (!calculator.executor.formulaEvaluator.isSyntaxValid(formula)) {
            const error = new Error("Syntax error in formula.");
            this.setState({ error: error });
            return;
        }
        // Check if there are any other references to this query in the model.
        // If there are (count > 0), do not add another reference since one
        // already exists. Otherwise, add a reference for any schedules that
        // need to be added for workflows that generate data that the query
        // requires. Also only install schedules if the query is not running
        // in edit, in which case the workflow is already running (scheduled).
        const isEdit = IdUtils.isEditId(query.id);
        const count = this.queryCount(query.id);
        if (!isEdit && count === 0 && CoreUtils.hasSize(pack.schedules)) {
            const referenceId = calculator.id;
            this.installSchedulesRequest(referenceId, pack.schedules, formula)
        } else {
            this.props.onChange(formula);
        }
    }

    private installSchedulesRequest(referenceId: string, schedules: WorkflowSchedule[], formula: string): Promise<any> {
        this.setState({
            error: undefined,
            isInstalling: true
        });
        const request = {
            referenceId: referenceId,
            schedules: schedules
        };
        return workflowService.installSchedules(request,
            (response: any) => this.installSchedulesResponse(response, formula),
            (response: any) => this.installSchedulesException(response),
            false
        );
    }

    private installSchedulesResponse(response: any, formula: string): void {
        this.props.onChange(formula);
        this.setState({ isInstalling: false });
    }

    private installSchedulesException(response: any): void {
        const error = RestUtils.getError(response);
        this.setState({
            error: error,
            isInstalling: false
        });
    }

    private handleOkClick(): void {
        this.builderRef.current!.submit();
    }

    private handleCancelClick(): void {
        this.props.onCancel();
    }

    private parseQueryFormula(): void {
        this.configuration = {};
        this.options = {};
        if (this.queryId || !this.props.formula) {
            return;
        }
        let formula: Formula;
        const calculator = this.context.calculator;
        try {
            // Store a reference to the root formula.
            formula = calculator.executor.formulaDecomposer.parseFormula(this.props.cell, this.props.formula);
            if (!formula.matches("QUERY")) {
                // Invalid query function, skip.
                return;
            }
        } catch (e) {
            // Could not parse, skip
            return;
        }

        let args = formula.args;
        this.queryId = args[0];

        if (String.isString(args[0]) && Number.isNumber(args[1])) {
            this.version = args[1];
            this.parseParameters(args, 2, this.configuration, this.options);
        }
    }

    private parseParameters(params: any[], index: number, configuration: Configuration, options: Options): void {
        for (let i = index; i < params.length; i++) {
            const item = QueryUtils.parseParam(params, i);
            if (item.type === ParamType.CONFIGURATION) {
                this.addConfiguration(item, configuration);
                i++;
            } else if (item.type === ParamType.OPTION) {
                this.addOption(item, options);
            }
        }
    }

    private addConfiguration(item: ParamItem, configuration: Configuration): void {
        if (CoreUtils.isEmpty(item.name) || CoreUtils.isEmpty(item.value)) {
            return;
        }
        const name = item.name!;
        let value = item.value!;
        let data: Data;

        if (Variable.isVariable(value)) {
            data = {
                type: IoType.EXPRESSION,
                //value: value.id
                value: `\${${value.id}}`
            }
        } else if (Cell.isCell(value)) {
            value = this.props.cell.sheet === value.sheet ? value.id : value.uid;
            data = {
                type: IoType.EXPRESSION,
                //value: value.uid
                value: `\${${value}}`
            }
        } else if (Range.isRange(value)) {
            value = this.props.cell.sheet === value.sheet ? value.id : value.uid;
            data = {
                type: IoType.EXPRESSION,
                //value: value.uid
                value: `\${${value}}`
            }
        } else if (Array.isArray(value)) {
            data = {
                type: IoType.LIST,
                value: value
            }
        } else if (String.isString(value) && value.startsWith("[") && value.endsWith("]")) {
            // Parse if items if the value is an array.
            value = value.substring(1, value.length - 1);
            if (value.length === 0) {
                return;
            }
            const items = value.split(/[, ]+/);
            data = {
                type: IoType.LIST,
                value: items
            }
        } else if (Number.isNumber(value)) {
            data = {
                type: IoType.NUMBER,
                value: value
            }
        } else if (Boolean.isBoolean(value)) {
            data = {
                type: IoType.BOOLEAN,
                value: value
            }
        } else {
            data = {
                type: IoType.TEXT,
                value: value.toString()
            }
        }
        configuration[name] = data;
    }

    private addOption(item: ParamItem, options: Options): void {
        if (CoreUtils.isEmpty(item.name) || CoreUtils.isEmpty(item.value)) {
            return;
        }
        const name = item.name!;
        const value = item.value!;
        options[name] = value;
    }

    private buildVariableSpecs(): ConfigurationSpec[] {
        const calculator = this.context.calculator;
        const variables = calculator.variables;
        const configurationSpecs: ConfigurationSpec[] = [];
        variables.forEach((variable: Variable) => {
            const configurationSpec = ConfigurationUtils.createConfigurationSpec(
                ConfigurationType.TEXT,
                variable.id,
                variable.name,
                variable.description
            );
            configurationSpecs.push(configurationSpec!);
        });
        return configurationSpecs;
    }

    private buildFormula(queryId: string, version: number, configuration: Configuration, options: Options): string {
        const args: any[] = [queryId, version];
        const configParams = this.buildConfigParams(configuration);
        args.push(...configParams);
        const optionParams = this.buildOptionParams(options);
        args.push(...optionParams);
        let formula = new Formula("QUERY", args);
        const calculator = this.context.calculator;
        const composer = new FormulaComposer(calculator);
        return composer.buildFormula(this.props.cell, formula);
    }

    private buildConfigParams(configuration: Configuration): any[] {
        let args = [];
        const values = Object.entries(configuration);
        for (const [key, data] of values) {
            let value = data.value;
            if (CoreUtils.isEmpty(value)) {
                continue;
            }
            if (data.type === IoType.EXPRESSION) {
                if (!value.startsWith("${") || !value.endsWith("}")) {
                    throw new Error(`Expression value must be enclosed in brackets, i.e., \${value}, where value is a cell, range, or variable reference.`);
                }
                value = value.substring(2, value.length - 1);
                let ref;
                try {
                    const sheetId = this.props.cell.sheet.id;
                    ref = ReferenceParser.parse(value, true, sheetId);
                } catch (e) {
                    throw new Error(`Invalid expression syntax '\${${value}}'.`);
                }
                if (!ref) {
                    continue;
                }
                if (ref.type === RefType.VARIABLE) {
                    const components = ref.components as VariableComponents;
                    value = Variable.to(components.variableId);
                } else if (ref.type === RefType.CELL) {
                    const calculator = this.context.calculator;
                    const components = ref.components as CellComponents;
                    const sheet = calculator.sheets.get(components.sheetId);
                    value = Cell.fromId(sheet, components.cellId);
                } else if (ref.type === RefType.RANGE) {
                    const calculator = this.context.calculator;
                    const components = ref.components as RangeComponents;
                    const sheet = calculator.sheets.get(components.sheetId);
                    value = Range.fromId(sheet, components.rangeId);
                } else {
                    throw new Error(`Invalid expression value '\${${value}}'.`);
                }
            }
            args.push(key);
            args.push(value);
        }
        return args;
    }

    private buildOptionParams(options: Options): any[] {
        const args = [];
        const values = Object.entries(options);
        for (const [key, value] of values) {
            if (CoreUtils.isEmpty(value)) {
                continue;
            }
            args.push(`${key}:${value}`);
        }
        return args;
    }

    public render(): ReactElement {
        // Need to parse here because context is not available in constructor.
        // Could put in componentDidMount(), but would require maintaining state.
        // TODO: bind and call in ctor
        this.parseQueryFormula();
        return (
            <Modal
                className="x-querydialog"
                centered
                title="Dataset Query Editor"
                visible={true}
                width={Globals.DIALOG_WIDTH_DOUBLE}
                onCancel={this.props.onCancel}
                footer={(
                    <Spacer justification="right">
                        <Button onClick={this.props.onCancel}>Cancel</Button>
                        <Button type="primary" onClick={this.handleOkClick} loading={this.state.isInstalling}>Run</Button>
                    </Spacer>
                )}
            >
                <QueryBuilder
                    ref={this.builderRef}
                    result="formula"
                    queryId={this.queryId}
                    version={this.version}
                    configuration={this.configuration}
                    options={this.options}
                    variableSpecs={this.buildVariableSpecs()}
                    variablesOnly={false}
                    excludes={["format", "api_key"]}
                    wideLayout={true}
                    onChange={this.handleQueryChange}
                    onTouch={this.handleQueryTouch}
                />
                {this.state.error &&
                    <TextMessage type="error" message={this.state.error.message} />
                }
            </Modal>
        );
    }

}
