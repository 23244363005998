import React, { ChangeEvent, PureComponent } from 'react';
import { Form, FormInstance, Switch } from 'antd';
import { Input } from 'antd';
import { Modal } from 'antd';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { ModelContext } from 'context/ModelContext';
import classNames from 'classnames';
import update from 'immutability-helper';
import './DashboardProperties.less';

export type ChangeCallback = (properties: Properties) => void;
export type CancelCallback = () => void;

export type Properties = {
    name: string,
    description?: string
}

export type DashboardPropertiesProps = {
    className?: string,
    isSaving: boolean,
    visible: boolean,
    properties: Properties,
    onChange: ChangeCallback,
    onCancel: CancelCallback
};

export type DashboardPropertiesState = {
    properties: Properties
};

export class DashboardProperties extends PureComponent<DashboardPropertiesProps, DashboardPropertiesState> {

    static contextType = ModelContext;

    private formRef = React.createRef<FormInstance>();

    constructor(props: DashboardPropertiesProps) {
        super(props);
        this.state = {
            properties: props.properties
        };
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleFormFinish = this.handleFormFinish.bind(this);
    }

    private handleNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const name = e.target.value;
        const properties = update(this.state.properties, {
            name: { $set: name }
        });
        this.setState({ properties: properties });
    }

    private handleDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        const description = e.target.value;
        const properties = update(this.state.properties, {
            description: { $set: description }
        });
        this.setState({ properties: properties });
    }

    private handleOk() {
        this.formRef.current?.submit();
    }

    private handleCancel(): void {
        this.props.onCancel();
    }

    private handleFormFinish(): void {
        this.props.onChange(this.state.properties);
    }

    render() {
        return (
            <Modal
                className={classNames('x-dashboardproperties', this.props.className)}
                centered
                width={Globals.DIALOG_WIDTH}
                title="Properties Editor"
                okButtonProps={{loading: this.props.isSaving}}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                visible={this.props.visible}
            >
                <Form
                    ref={this.formRef}
                    onFinish={this.handleFormFinish}
                >
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        label="Name"
                        name="name"
                        info="The name of the model."
                        rules={[{
                            required: true,
                            message: 'Please enter a name.'
                        }]}
                    >
                        <Input
                            placeholder="Model name."
                            value={this.state.properties.name}
                            onChange={this.handleNameChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        label="Description"
                        name="description"
                        info="The description of the model."
                    >
                        <Input.TextArea
                            placeholder="Dashboard description."
                            rows={5}
                            value={this.state.properties.description}
                            onChange={this.handleDescriptionChange}
                        />
                    </FormItem>
                </Form>
            </Modal>
        );
    }

}
