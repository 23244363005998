import { PureComponent, ReactElement } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { Image } from 'components/Image/Image';
import { RouteBuilder } from 'utils/RouteBuilder';
import { EntityContext } from 'context/EntityContext';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Spacer } from 'components/Spacer/Spacer';
import { Display } from 'components/Display/Display';
import { MoreOutlined } from '@ant-design/icons';
import { HashLink } from 'react-router-hash-link';
import authService from 'services/AuthService';
import logo from 'images/methodset_blue_logo.png';
import gears from 'images/gears_32x32.png';
import './Header.less';

export type HeaderProps = typeof Header.defaultProps & RouteComponentProps & {
    //showActions: boolean
}
export class Header extends PureComponent<HeaderProps> {

    static contextType = EntityContext;
    static defaultProps = {
        //showActions: true
    };

    constructor(props: HeaderProps) {
        super(props);
        this.handleHomeClick = this.handleHomeClick.bind(this);
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
    }

    private handleHomeClick(): void {
        const isLoggedIn = this.isLoggedIn();
        if (isLoggedIn) {
            this.props.history.push(RouteBuilder.CONSOLE);
        } else {
            this.props.history.push(RouteBuilder.HOME);
        }
    }

    private handleLogoutClick(): void {
        authService.logoutUser(
            () => this.handleLogout(),
            () => this.handleLogout()
        );
    }

    private handleLogout(): void {
        authService.clearUser();
        this.context.clearUser();
        this.props.history.push(RouteBuilder.HOME);
    }

    private isLoggedIn(): boolean {
        return this.context.user ? true : false;
    }

    private dropMenu(): ReactElement {
        const isLoggedIn = this.isLoggedIn();
        return (
            <Menu className="x-header-action">
                {/* <Menu.Item>
                    <HashLink to={RouteBuilder.homeSection("pricing")}>Pricing</HashLink>
                </Menu.Item> */}
                <Menu.Item>
                    <Link to={RouteBuilder.MAIN_DOCUMENTATION_OVERVIEW}>Docs</Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to={RouteBuilder.MAIN_DEMO}>Demo</Link>
                </Menu.Item>
                {!isLoggedIn &&
                    <Menu.Item>
                        {/* <Link to={RouteBuilder.user(this.props, RouteBuilder.USER_LOGIN)}>Login</Link> */}
                        <Link to={RouteBuilder.USER_LOGIN}>Login</Link>
                    </Menu.Item>
                }
                {isLoggedIn &&
                    <Menu.Item>
                        <Link to="#" onClick={this.handleLogoutClick}>Logout</Link>
                    </Menu.Item>
                }
            </Menu>
        )
    }

    private navMenu(): ReactElement {
        const isLoggedIn = this.isLoggedIn();
        return (
            <Spacer className="x-header-action" size="xl">
                {/* <HashLink to={RouteBuilder.homeSection("pricing")}>Pricing</HashLink> */}
                <Link to={RouteBuilder.MAIN_DOCUMENTATION_OVERVIEW}>Docs</Link>
                <Link to={RouteBuilder.MAIN_DEMO}>Demo</Link>
                {!isLoggedIn &&
                    <Link to={RouteBuilder.USER_LOGIN}>Login</Link>
                }
                {isLoggedIn &&
                    <Link to="#" onClick={this.handleLogoutClick}>Logout</Link>
                }
            </Spacer>
        )
    }

    public render(): ReactElement {
        return (
            <Spacer className="x-header" justification="between">
                <span>
                    <Image src={gears} className="x-header-gears" alt="gears" />
                    <Image src={logo} className="x-header-logo" alt="logo" onClick={this.handleHomeClick} />
                </span>
                <div>
                    <Display xs="hide" md="show">
                        {this.navMenu()}
                    </Display>
                    <Display xs="show" md="hide">
                        <Dropdown overlay={this.dropMenu()} trigger={['click']}>
                            <Button shape="circle" size="middle">
                                <MoreOutlined />
                            </Button>
                        </Dropdown>
                    </Display>
                </div>
            </Spacer>
        );
    }

}
