import { ReactElement, useState } from 'react';
import { Avatar, Button, Col, Row } from 'antd';
import { Spacer } from 'components/Spacer/Spacer';
import { CoreUtils } from 'utils/CoreUtils';
import { Link } from 'react-router-dom';
import { Globals } from 'constants/Globals';
import { Label } from 'components/Label/Label';
import { ModelHeader } from '@methodset/model-client-ts';
import './ModelView.less';

export type SelectCallback = (header: ModelHeader) => void;

export type ModelViewProps = {
    index?: number,
    header: ModelHeader,
    onSelect: SelectCallback
} & typeof defaultProps;

const defaultProps = {
    index: 0
}

export const ModelView = (props: ModelViewProps): ReactElement => {

    const [show, setShow] = useState<boolean>(false);

    const handleMoreToggle = () => {
        setShow(!show);
    }

    const handleModelSelect = () => {
        props.onSelect(props.header);
    }

    const buildSummary = (): string => {
        const description = props.header.description;
        if (!description) {
            return "<no description>";
        }
        const index = description.indexOf(".");
        return index == -1 ? description : description.substring(0, index + 1);
    }

    const buildLogo = (): string => {
        return props.header.name[0];
    }

    const color = Globals.BOLD_COLORS[props.index % Globals.BOLD_COLORS.length];
    const summary = buildSummary();
    const logo = buildLogo();
    return (
        <div className="x-modelview">
            <Row>
                <Spacer direction="vertical" size="sm">
                    <div className="x-modelview-wrap">
                        <Spacer alignment="top">
                            <div>
                                <Avatar
                                    size={40}
                                    shape="square"
                                    style={{ color: "white", backgroundColor: color }}
                                >
                                    {logo}
                                </Avatar>
                            </div>
                            <div>
                                <div className="x-modelview-name">{props.header.name}</div>
                                <div className="x-modelview-publisher">{props.header.publisher}</div>
                            </div>
                        </Spacer>
                        <div className="x-modelview-description">
                            {!show && <div className="x-modelview-summary">{summary}</div>}
                            {show && <div>{props.header.description}</div>}
                        </div>
                        <Link to="#" onClick={() => handleMoreToggle()}>{show ? "Show less" : "Show more"}</Link>
                    </div>
                </Spacer>
            </Row>
            <Row justify="space-between" align="bottom">
                <Col>
                    <Label label="Version">{CoreUtils.toVersion(props.header.version)}</Label>
                    <Label label="Publisher">{props.header.publisher ?? "Unpublished"}</Label>
                    <Label label="Updated">{CoreUtils.toTime(props.header.updateTime)}</Label>
                </Col>
                <Col>
                    <Button onClick={() => handleModelSelect()}>
                        Select
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

ModelView.defaultProps = defaultProps;
