import { ReactElement, Ref, useRef, useState } from 'react';
import { Form, FormInstance } from 'antd';
import { Pension } from '@methodset/entity-client-ts';
import { PensionEditor } from './PensionEditor/PensionEditor';
import { ListEditor } from '../ListEditor/ListEditor';
import './PensionsEditor.less';

export type ChangeCallback = (pensions: Pension[]) => void;

export type PensionsEditorProps = {
    formRef: React.RefObject<FormInstance>,
    withForm?: boolean,
    id: string,
    pensions?: Pension[],
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    pensions: [] as Pension[]
}

export const PensionsEditor = (props: PensionsEditorProps): ReactElement => {

    const [editPension, setEditPension] = useState<Pension | undefined>();

    const handlePensionChange = (pension: Pension): void => {
        setEditPension(pension);
    }

    const handlePensionEdit = (pension: Pension): void => {
        setEditPension(pension);
    }

    const handlePensionsSave = (pensions: Pension[]): void => {
        setEditPension(undefined);
        pensions.sort((a, b) => a.income.startDate < b.income.startDate ? -1 : 1);
        props.onChange(pensions);
    }

    const renderItem = (pension: Pension): ReactElement => {
        return (
            <div className="x-pensionseditor">
                <div className="x-pensionseditor-left">
                    <div className="x-pensionseditor-name">{pension.income.name}</div>
                    <div className="x-pensionseditor-bottom">{pension.income.source}</div>
                </div>
                <div className="x-pensionseditor-right">
                    <div className="x-pensionseditor-top">${pension.income.amount ? pension.income.amount.toLocaleString("en-US") : 0}</div>
                    <div className="x-pensionseditor-bottom">{pension.income.startDate} to {pension.income.endDate ? pension.income.endDate : "End"}</div>
                </div>
            </div>
        )
    }

    const buildView = (): ReactElement => {
        return (
            <ListEditor
                formRef={props.formRef}
                id={props.id}
                name="Pension"
                items={props.pensions}
                editItem={editPension}
                render={renderItem}
                onEdit={(address) => handlePensionEdit(address)}
                onSave={(addresses) => handlePensionsSave(addresses)}
            >
                <PensionEditor
                    formRef={props.formRef}
                    id="pension"
                    pension={editPension}
                    onChange={(pensions) => handlePensionChange(pensions)}
                />
            </ListEditor>
        )
    }

    return (
        <>
            {props.withForm &&
                <Form ref={props.formRef}>
                    {buildView()}
                </Form>
            }
            {!props.withForm &&
                buildView()
            }
        </>
    )

}

PensionsEditor.defaultProps = defaultProps;