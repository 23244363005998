import { ReactElement } from 'react';
import { Empty } from 'antd';
import { Calculator, Column, DimensionType, Format, FormulaError, Matrix, Range, Row, Vector } from '@methodset/calculator-ts';
import { AreaChartWidgetConfiguration, BarChartWidgetConfiguration, CandleDataset, CandleWidgetConfiguration, CandlestickChartWidgetConfiguration, CartesianWidgetConfiguration, ChartDataset, ColumnChartWidgetConfiguration, DonutChartWidgetConfiguration, LineChartWidgetConfiguration, PieChartWidgetConfiguration, PolarWidgetConfiguration, WidgetConfiguration, WidgetType } from '@methodset/application-client-ts';
import { BarChartView } from './BarChartView/BarChartView';
import { LineChartView } from './LineChartView/LineChartView';
import { PieChartView } from './PieChartView/PieChartView';
import { ColumnChartView } from './ColumnChartView/ColumnChartView';
import { WidgetUtils } from 'utils/WidgetUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { AreaChartView } from './AreaChartView/AreaChartView';
import { DonutChartView } from './DonutChartView/DonutChartView';
import { Boolean, Date, Number, Time } from '@methodset/commons-core-ts';
import { CandlestickChartView } from './CandlestickChartView/CandlestickChartView';
import { NoData } from 'components/NoData/NoData';
import './ChartWidgetViewer.less';

export type Point = { x: any, y: any };
export type LabelType = "numeric" | "category" | "datetime";

// NOTE: the variable names match ApexAxisChartSeries
// so that it can be used directly in ReactApexChart.
export type Series = {
    name: string,
    data: Point[]
}

export type CartesianData = {
    xFormat?: Format,
    yFormat?: Format,
    labelType: LabelType,
    seriesList: Series[]
}

export type PolarData = {
    xFormat?: Format,
    yFormat?: Format,
    series: Series
}

export type CandleData = {
    xFormat?: Format,
    yFormat?: Format,
    series: Series
}

export type ChartWidgetViewerProps = {
    configuration: WidgetConfiguration,
    calculator: Calculator
}

export const ChartWidgetViewer = (props: ChartWidgetViewerProps): ReactElement => {

    const hasCartesianData = (data: CartesianData): boolean => {
        const series = data.seriesList;
        return series && series.length > 0 && series[0].data.length > 0;
    }

    const hasPolarData = (data: PolarData): boolean => {
        const series = data.series;
        return series && series.data.length > 0;
    }

    const hasCandleData = (data: CandleData): boolean => {
        const series = data.series;
        return series && series.data.length > 0;
    }

    const forceTextLabels = (seriesList: Series[]): void => {
        for (const series of seriesList) {
            const data = series.data;
            for (let i = 0; i < data.length; i++) {
                const point = data[i];
                if (Number.isNumber(point.x)) {
                    point.x = point.x.toString();
                }
            }
        }
    }

    const buildCartesianData = (configuration: CartesianWidgetConfiguration): CartesianData => {
        const datasets = configuration.datasets;
        let xFormat;
        let yFormat;
        const seriesList: Series[] = [];
        for (let i = 0; i < datasets.length; i++) {
            const dataset = datasets[i];
            addCartesianSeries(dataset, seriesList);
            if (i === 0) {
                xFormat = findFormat(props.calculator, dataset.labelRangeId);
                yFormat = findFormat(props.calculator, dataset.valueRangeId);
            }
        }
        const labelType = findLabelType(seriesList, xFormat);
        if (labelType === "category") {
            forceTextLabels(seriesList);
        }
        const chartData: CartesianData = {
            xFormat: xFormat,
            yFormat: yFormat, // per series?
            seriesList: seriesList,
            labelType: labelType
        };
        return chartData;
    }

    const buildPolarData = (configuration: PolarWidgetConfiguration): PolarData => {
        const dataset = configuration.dataset;
        const series = addPolarSeries(dataset);
        const xFormat = findFormat(props.calculator, dataset.labelRangeId);
        const yFormat = findFormat(props.calculator, dataset.valueRangeId);
        const chartData: PolarData = {
            xFormat: xFormat,
            yFormat: yFormat,
            series: series
        };
        return chartData;
    }

    const buildCandleData = (configuration: CandleWidgetConfiguration): CandleData => {
        const dataset = configuration.dataset;
        const series = addCandleSeries(dataset);
        const xFormat = findFormat(props.calculator, dataset.timeRangeId);
        const yFormat = findFormat(props.calculator, dataset.openRangeId);
        const chartData: CandleData = {
            xFormat: xFormat,
            yFormat: yFormat,
            series: series
        };
        return chartData;
    }

    const findLabelType = (seriesList: Series[], format: Format | undefined): LabelType => {
        if (format) {
            switch (format.type) {
                case "text":
                    return "category";
                case "number":
                    return "numeric";
                case "date":
                case "time":
                case "datetime":
                    return "datetime";
                default:
            }
        }
        enum DataType { DATE, TIME, NUMBER, BOOLEAN };
        let type = null;
        let root = null;
        for (const series of seriesList) {
            const points = series.data;
            for (let i = 0; i < points.length; i++) {
                const value = points[i].x;
                if (CoreUtils.isEmpty(value)) {
                    continue;
                } else if (Date.isDate(value)) {
                    type = DataType.DATE;
                } else if (Time.isTime(value)) {
                    type = DataType.TIME;
                } else if (Number.isNumber(value)) {
                    type = DataType.NUMBER;
                } else if (Boolean.isBoolean(value)) {
                    type = DataType.BOOLEAN;
                } else {
                    return "category";
                }
                if (!root) {
                    root = type;
                } else if (root !== type) {
                    return "category";
                }
            }
        }
        if (type === DataType.DATE || type === DataType.TIME) {
            return "datetime";
        } else if (type === DataType.NUMBER) {
            return "numeric";
        } else {
            return "category";
        }
    }

    const findFormat = (calculator: Calculator, rangeId: string): Format | undefined => {
        const range = Range.fromUid(calculator, rangeId);
        const sheet = range.sheet;
        const upper = range.upper;
        const lower = range.lower;
        let dimension: Row | Column;
        if (upper.col === lower.col) {
            dimension = sheet.getColumn(upper.colId);
        } else if (upper.row === lower.row) {
            dimension = sheet.getRow(upper.rowId);
        } else {
            // This is a multi-dimensional range. First check the column 
            // for a format, and if none, use the first row.
            dimension = sheet.getColumn(upper.colId);
            if (!dimension.format) {
                dimension = sheet.getRow(upper.rowId);
            }
        }
        return dimension.format;
    }

    const addCartesianSeries = (dataset: ChartDataset, seriesList: Series[]): void => {
        const yRange = Range.fromUid(props.calculator, dataset.valueRangeId);
        const yMatrix = Matrix.fromRange(yRange);

        const xRange = Range.fromUid(props.calculator, dataset.labelRangeId);
        const xVector = Vector.fromRange(xRange);
        const xValues = xVector.values();

        // A cartesian series can be a matrix (multiple vectors) so that multiple 
        // columns/rows can be used. This is needed for dynamic ranges that may 
        // change and whose column/row count are unknown ahead of time.
        const start = dataset.hasHeaders ? 1 : 0
        if (xVector.dimensionType === DimensionType.COLUMN) {
            for (let c = 0; c < yMatrix.columns; c++) {
                const points: Point[] = [];
                for (let r = start; r < xValues.length; r++) {
                    const point: Point = {
                        x: toValue(xValues[r]),
                        y: toValue(yMatrix.at(r, c))
                    }
                    points.push(point);
                }
                const name = dataset.hasHeaders ? yMatrix.at(0, c) : dataset.name;
                const series: Series = {
                    name: name,
                    data: points
                }
                seriesList.push(series);
            }
        } else if (xVector.dimensionType === DimensionType.ROW) {
            for (let r = 0; r < yMatrix.rows; r++) {
                const points: Point[] = [];
                for (let c = start; c < xValues.length; c++) {
                    const point: Point = {
                        x: toValue(xValues[c]),
                        y: toValue(yMatrix.at(r, c))
                    }
                    points.push(point);
                }
                const name = dataset.hasHeaders ? yMatrix.at(r, 0) : dataset.name;
                const series: Series = {
                    name: name,
                    data: points
                }
                seriesList.push(series);
            }
        }
    }

    const addPolarSeries = (dataset: ChartDataset): Series => {
        const yRange = Range.fromUid(props.calculator, dataset.valueRangeId);
        const yVector = Vector.fromRange(yRange);
        const yValues = yVector.values();

        const xRange = Range.fromUid(props.calculator, dataset.labelRangeId);
        const xVector = Vector.fromRange(xRange);
        const xValues = xVector.values();

        const points: Point[] = [];
        for (let i = 0; i < xValues.length; i++) {
            const point = {
                x: xValues[i],
                y: toValue(yValues[i])
            }
            points.push(point);
        }
        const series: Series = {
            name: "Series",
            data: points
        }
        return series;
    }

    const addCandleSeries = (dataset: CandleDataset): Series => {
        const oRange = Range.fromUid(props.calculator, dataset.openRangeId);
        const oVector = Vector.fromRange(oRange);
        const oValues = oVector.values();

        const hRange = Range.fromUid(props.calculator, dataset.highRangeId);
        const hVector = Vector.fromRange(hRange);
        const hValues = hVector.values();

        const lRange = Range.fromUid(props.calculator, dataset.lowRangeId);
        const lVector = Vector.fromRange(lRange);
        const lValues = lVector.values();

        const cRange = Range.fromUid(props.calculator, dataset.closeRangeId);
        const cVector = Vector.fromRange(cRange);
        const cValues = cVector.values();

        const tRange = Range.fromUid(props.calculator, dataset.timeRangeId);
        const tVector = Vector.fromRange(tRange);
        const tValues = tVector.values();

        const points: Point[] = [];
        for (let i = 0; i < tValues.length; i++) {
            const point = {
                x: toValue(tValues[i]),
                y: [toValue(oValues[i]), toValue(hValues[i]), toValue(lValues[i]), toValue(cValues[i])]
            }
            points.push(point);
        }
        const series: Series = {
            name: "Series",
            data: points
        }
        return series;
    }

    const toValue = (value: any): any => {
        if (value === undefined) {
            return null;
        } else if (FormulaError.isError(value)) {
            const error = value as FormulaError;
            return error.type;
        } else if (Date.isDate(value)) {
            return value.toIso();
        } else if (Time.isTime(value)) {
            return value.toMillis();
        } else {
            return value;
        }
    }

    const buildCartesianConfiguration = (): CartesianWidgetConfiguration => {
        const configuration = CoreUtils.clone(props.configuration) as CartesianWidgetConfiguration;
        configuration.xAxis.label = WidgetUtils.replaceCellRefs(props.calculator, configuration.xAxis.label);
        configuration.yAxis.label = WidgetUtils.replaceCellRefs(props.calculator, configuration.yAxis.label);
        const datasets = configuration.datasets;
        for (const dataset of datasets) {
            dataset.name = WidgetUtils.replaceCellRefs(props.calculator, dataset.name);
        }
        return configuration;
    }

    const buildPolarConfiguration = (): PolarWidgetConfiguration => {
        const configuration = CoreUtils.clone(props.configuration) as PolarWidgetConfiguration;
        configuration.dataset.name = WidgetUtils.replaceCellRefs(props.calculator, configuration.dataset.name);
        return configuration;
    }

    const buildCandleConfiguration = (): CandleWidgetConfiguration => {
        const configuration = CoreUtils.clone(props.configuration) as CandleWidgetConfiguration;
        configuration.xAxis.label = WidgetUtils.replaceCellRefs(props.calculator, configuration.xAxis.label);
        configuration.yAxis.label = WidgetUtils.replaceCellRefs(props.calculator, configuration.yAxis.label);
        return configuration;
    }

    const buildEmptyChart = (): ReactElement => {
        return (
            <div className="x-chartwidgetviewer-empty">
                <NoData text="No chart data." />
            </div>
        );
    }
    const chartView = (): ReactElement => {
        let chartView;
        const configuration = props.configuration;
        try {
            const type = configuration.type;
            if (type === WidgetType.AREA_CHART) {
                const config = buildCartesianConfiguration();
                const data = buildCartesianData(config);
                chartView = hasCartesianData(data) ? <AreaChartView configuration={config as AreaChartWidgetConfiguration} data={data} /> : buildEmptyChart();
            } else if (type === WidgetType.BAR_CHART) {
                const config = buildCartesianConfiguration();
                const data = buildCartesianData(config);
                chartView = hasCartesianData(data) ? <BarChartView configuration={config as BarChartWidgetConfiguration} data={data} /> : buildEmptyChart();
            } else if (type === WidgetType.COLUMN_CHART) {
                const config = buildCartesianConfiguration();
                const data = buildCartesianData(config);
                chartView = hasCartesianData(data) ? <ColumnChartView configuration={config as ColumnChartWidgetConfiguration} data={data} /> : buildEmptyChart();
            } else if (type === WidgetType.LINE_CHART) {
                const config = buildCartesianConfiguration();
                const data = buildCartesianData(config);
                chartView = hasCartesianData(data) ? <LineChartView configuration={config as LineChartWidgetConfiguration} data={data} /> : buildEmptyChart();
            } else if (type === WidgetType.PIE_CHART) {
                const config = buildPolarConfiguration();
                const data = buildPolarData(config);
                chartView = hasPolarData(data) ? <PieChartView configuration={config as PieChartWidgetConfiguration} data={data} /> : buildEmptyChart();
            } else if (type === WidgetType.DONUT_CHART) {
                const config = buildPolarConfiguration();
                const data = buildPolarData(config);
                chartView = hasPolarData(data) ? <DonutChartView configuration={config as DonutChartWidgetConfiguration} data={data} /> : buildEmptyChart();
            } else if (type === WidgetType.CANDLESTICK_CHART) {
                const config = buildCandleConfiguration();
                const data = buildCandleData(config);
                chartView = hasCandleData(data) ? <CandlestickChartView configuration={config as CandlestickChartWidgetConfiguration} data={data} /> : buildEmptyChart();
            } else {
                chartView = (
                    <div>Unsupported chart type '{type}'.</div>
                );
            }
        } catch (e) {
            const error = e as Error;
            chartView = (
                <div>Chart error: {error.message}</div>
            );
        }
        return chartView;
    }

    return (
        <div className="x-chartwidgetviewer">
            {chartView()}
        </div>
    );
}
