import { ReactElement, useContext } from 'react';
import { Select } from 'antd';
import { Applet } from '@methodset/application-client-ts';

export type ChangeCallback = (applicationId: string) => void;

export type PanelSelectorProps = {
    className?: string,
    applet: Applet,
    panelId?: string,
    usedIds?: string[],
    onChange: ChangeCallback
};

export const PanelSelector = (props: PanelSelectorProps): ReactElement => {

    const isUsed = (panelId: string): boolean => {
        if (!props.usedIds) {
            return false;
        }
        return props.usedIds.findIndex(id => id === panelId) !== -1;
    }

    return (
        <Select
            className={props.className}
            allowClear={true}
            placeholder="Select a panel."
            value={props.panelId}
            onChange={(panelId) => props.onChange(panelId)}
        >
            {props.applet.panels.map(panel => (
                <Select.Option 
                    key={panel.id}
                    value={panel.id}
                    disabled={isUsed(panel.id)}
                >
                    {panel.name}
                </Select.Option>
            ))}
        </Select>
    )
}
