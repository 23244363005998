import { PureComponent, ReactElement } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { BookOutlined, DeleteOutlined, EditOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { Globals } from 'constants/Globals';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { RouteBuilder } from 'utils/RouteBuilder';
import { ColumnsType } from 'antd/lib/table';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { QueryHeader, Query } from '@methodset/endpoint-client-ts';
//import { EditorMode } from '../VersionEditor-REMOVE/VersionEditor.tsx-REMOVE';
import { ModuleType, PackHeader } from '@methodset/library-client-ts';
import { StatusType } from 'constants/StatusType';
import { PermissionUtils } from 'utils/PermissionUtils';
import { EntityContext } from 'context/EntityContext';
import { AccessType } from '@methodset/commons-core-ts';
import axios from 'axios';
import classNames from 'classnames';
import endpointService from 'services/EndpointService';
import update from 'immutability-helper';
import './Datasets.less';
import { VersionPackager } from '../VersionEditor-REMOVE/VersionPackager/VersionPackager';

//type EditMode = EditorMode | "none";

interface ProcessData {
    //mode: EditMode,
    query?: Query,
    error?: Error,
    isProcessing?: boolean
}

export type QueriesProps = RouteComponentProps & {
    className?: string
}

export type QueriesState = {
    status: StatusType,
    headers: QueryHeader[],
    process: ProcessData
}

export class Queries extends PureComponent<QueriesProps, QueriesState> {

    static contextType = EntityContext;

    constructor(props: QueriesProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            headers: [],
            process: {
                mode: "none",
                isProcessing: false
            } as ProcessData
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
        this.handleQueryCreate = this.handleQueryCreate.bind(this);
        this.handleQueryEdit = this.handleQueryEdit.bind(this);
        this.handleQueryDelete = this.handleQueryDelete.bind(this);
        this.handleShowPackage = this.handleShowPackage.bind(this);
        //this.handleShowDiscard = this.handleShowDiscard.bind(this);
        this.handlePackageDone = this.handlePackageDone.bind(this);
        this.handleVersionReset = this.handleVersionReset.bind(this);
        this.handlePackageCancel = this.handlePackageCancel.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleRefreshClick(): void {
        this.loadData();
    }

    private handleQueryCreate(): void {
        if (!PermissionUtils.checkLimit(this.context, "component.dataset.count", "dataset", this.state.headers.length)) {
            return;
        }
        const url = RouteBuilder.query('create');
        this.props.history.push(url);
    }

    private handleQueryEdit(query: QueryHeader): void {
        const url = RouteBuilder.query(query.id);
        this.props.history.push(url);
    }

    private handleQueryDelete(query: QueryHeader): void {
        this.deleteQueryRequest(query.id);
    }

    private handleShowPackage(query: Query): void {
        const process = update(this.state.process, {
            //mode: { $set: "publish" },
            query: { $set: query }
        });
        this.setState({ process: process });
    }

    // private handleShowDiscard(query: Query): void {
    //     const version = update(this.state.process, {
    //         mode: { $set: "reset" },
    //         query: { $set: query }
    //     });
    //     this.setState({ process: version });
    // }

    private handlePackageDone(header: PackHeader): void {
        const response = {
            data: {
                header: header
            }
        }
        this.versionResponse(response);
    }

    private handleVersionReset(): void {
        const query = this.state.process.query!;
        this.resetVersionRequest(query.id);
    }

    private handlePackageCancel(): void {
        const version = update(this.state.process, {
            //mode: { $set: "none" },
            query: { $set: undefined },
            isProcessing: { $set: false }
        });
        this.setState({ process: version });
    }

    private readQueryHeadersRequest(): Promise<any> {
        const request = {
            accessType: AccessType.UNPUBLISHED,
            useLatest: true
        };
        return endpointService.readQueryHeaders(request,
            (response: any) => this.readQueryHeadersResponse(response),
            undefined, true
        );
    }

    private readQueryHeadersResponse(response: any): void {
        const headers = response.data.headers;
        this.setState({ headers: headers });
    }

    private deleteQueryRequest(queryId: string): Promise<any> {
        const request = {
            queryId: queryId
        };
        return endpointService.archiveQuery(request,
            (response: any) => this.deleteQueryResponse(response)
        );
    }

    private deleteQueryResponse(response: any): void {
        const queryId = response.data.queryId;
        const index = this.state.headers.findIndex(header => header.id === queryId);
        this.setState({
            headers: update(this.state.headers, {
                $splice: [[index, 1]]
            })
        });
    }

    private resetVersionRequest(queryId: string): Promise<any> {
        const version = update(this.state.process, {
            error: { $set: undefined },
            isProcessing: { $set: true }
        });
        this.setState({ process: version });
        const request = {
            queryId: queryId
        };
        return endpointService.resetVersion(request,
            (response: any) => this.versionResponse(response),
            (response: any) => this.versionException(response),
            true
        );
    }

    private versionResponse(response: any): void {
        const header = response.data.header;
        const queryId = header.moduleId;
        const index = this.state.headers.findIndex(query => query.id === queryId);
        this.setState({
            headers: update(this.state.headers, {
                [index]: {
                    version: { $set: header.version },
                    updateTime: { $set: header.updateTime }
                }
            })
        });
        const process = update(this.state.process, {
            //mode: { $set: "none" },
            query: { $set: undefined },
            isProcessing: { $set: false }
        });
        this.setState({ process: process });
    }

    private versionException(response: any): void {
        const error = new Error(RestUtils.getErrorMessage(response));
        const version = update(this.state.process, {
            error: { $set: error },
            isProcessing: { $set: false }

        });
        this.setState({ process: version });
    }

    private buildColumns(): ColumnsType<any> {
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            ellipsis: true,
            width: Globals.TABLE_WIDTH_LARGE,
            render: (query: QueryHeader) => {
                return <Link to={RouteBuilder.query(query.id)}>{query.name}</Link>
            },
            sorter: (a: any, b: any) => {
                const diff = CoreUtils.compareStrings(a.name, b.name);
                return diff === 0 ? CoreUtils.compareStrings(a.connectorType, b.connectorType) : diff;
            },
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'description',
            title: 'Description',
            ellipsis: true,
            render: (query: QueryHeader) => {
                return (
                    <span>{query.description ? query.description : Globals.EMPTY_FIELD}</span>
                );
            }
        }, {
            key: 'version',
            title: 'Latest Version',
            align: 'center',
            width: Globals.TABLE_WIDTH_MEDIUM,
            render: (query: QueryHeader) => {
                return (
                    <span>
                        {CoreUtils.toVersion(query.version)}
                    </span>
                );
            },
            sorter: (a: any, b: any) => CoreUtils.compareNumbers(a.version, b.version),
            sortDirections: ['descend', 'ascend']
        }, {
            key: 'utime',
            title: 'Last Updated',
            align: 'center',
            width: Globals.TABLE_WIDTH_MEDIUM,
            render: (query: QueryHeader) => {
                return (
                    <span>
                        {CoreUtils.toTime(query.updateTime)}
                    </span>
                );
            },
            sorter: (a: any, b: any) => CoreUtils.compareNumbers(a.updateTime, b.updateTime),
            sortDirections: ['descend', 'ascend']
        }];
        return columns;
    }

    private buildData(): any {
        return this.state.headers;
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readQueryHeadersRequest());
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== StatusType.READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        const actions = [{
            icon: <EditOutlined />,
            label: "Edit dataset",
            callback: this.handleQueryEdit
        }, {
            icon: <BookOutlined />,
            label: "Package version...",
            callback: this.handleShowPackage
            // }, {
            //     icon: <ScissorOutlined />,
            //     label: "Discard changes",
            //     callback: this.handleShowDiscard
        }, {
            icon: <DeleteOutlined />,
            label: "Delete dataset",
            confirm: "Are you sure you want to delete the dataset?",
            callback: this.handleQueryDelete
        }];
        const columns = this.buildColumns();
        const data = this.buildData();
        return (
            <>
                <ItemTable
                    className={classNames('x-queries', this.props.className)}
                    title="Dataset"
                    status={this.state.status}
                    columns={columns}
                    items={data}
                    extra={
                        <Space>
                            <Button onClick={this.handleQueryCreate}>New Dataset</Button>
                            <Button icon={<SyncOutlined />} onClick={this.handleRefreshClick}></Button>
                        </Space>
                    }
                    actions={actions}
                    onLoad={this.handleRetryLoad}
                />
                {this.state.process.query &&
                    <VersionPackager
                        id={this.state.process.query.id}
                        name={this.state.process.query.name}
                        description={this.state.process.query.description}
                        moduleId={this.state.process.query.id}
                        moduleType={ModuleType.QUERY}
                        onPackage={this.handlePackageDone}
                        onCancel={this.handlePackageCancel}
                    />
                }
                {/* {EditorValues.includes(this.state.process.mode) && this.state.process.query &&
                    <VersionEditor
                        id={this.state.process.query.id}
                        type={PackType.QUERY}
                        name={this.state.process.query.name}
                        description={this.state.process.query.description}
                        details={{
                            type: PackType.QUERY,
                            queryId: this.state.process.query.id,
                            version: 0
                        } as QueryDetails}
                        mode={this.state.process.mode as EditorMode}
                        version={this.state.process.query.version}
                        error={this.state.process.error}
                        isProcessing={this.state.process.isProcessing}
                        onPackage={this.handleVersionPackage}
                        onReset={this.handleVersionReset}
                        onCancel={this.handleVersionCancel}
                    />
                } */}
            </>
        );
    }

}
