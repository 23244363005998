import { ReactElement } from 'react';
import { Button, FormInstance, Select } from 'antd';
import { Assignment, ConfigurationSpec, DatasetLocator, IoType, KeyLocator, OutputSpec, RecordsLocator } from '@methodset/endpoint-client-ts';
import { FormItem } from 'components/FormItem/FormItem';
import { Spacer } from 'components/Spacer/Spacer';
import { ArrowLeftOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { RecordsLocatorEditor } from './RecordsLocatorEditor/RecordsLocatorEditor';
import { DatasetLocatorEditor } from './DatasetLocatorEditor/DatasetLocatorEditor';
import { Justify } from 'components/Justify/Justify';
import update from 'immutability-helper';
import './AssignmentsEditor.less';

export type ChangeCallback = (assignments: Assignment[]) => void;

export type AssignmentsEditorProps = {
    formRef: React.RefObject<FormInstance>,
    outputSpecs: OutputSpec[],
    variableSpecs: ConfigurationSpec[],
    assignments?: Assignment[],
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    assignments: [] as Assignment[]
}

export const AssignmentsEditor = (props: AssignmentsEditorProps): ReactElement => {

    const handleVariableKeyChange = (key: string, index: number): void => {
        const assignments = update(props.assignments, {
            [index]: {
                key: { $set: key }
            }
        });
        props.onChange(assignments);
    }

    const handleOutputKeyChange = (key: string, index: number): void => {
        const outputSpec = props.outputSpecs.find(outputSpec => outputSpec.key === key)!;
        const locator = {
            type: outputSpec.type,
            key: key
        };
        const assignments = update(props.assignments, {
            [index]: {
                locator: { $set: locator }
            }
        });
        props.onChange(assignments);
    }

    const handleAssignmentAdd = (): void => {
        const assignments = update(props.assignments, {
            $push: [{
                locator: {} as KeyLocator
            } as Assignment]
        });
        props.onChange(assignments);
    }

    const handleAssignmentRemove = (index: number): void => {
        const assignments = update(props.assignments, {
            $splice: [[index, 1]]
        });
        props.onChange(assignments);
    }

    const handleLocatorChange = (locator: KeyLocator, index: number): void => {
        const assignments = update(props.assignments, {
            [index]: {
                locator: { $set: locator }
            }
        });
        props.onChange(assignments);
    }

    const typesMatch = (variableKey: string, outputSpec: OutputSpec): boolean => {
        if (!variableKey) {
            return false;
        }
        // Primitive types must match with spec. All other types can contain nested values and is unknown.
        if (outputSpec.type !== IoType.NUMBER && outputSpec.type !== IoType.TEXT && outputSpec.type !== IoType.BOOLEAN) {
            return true;
        }
        const variableSpec = props.variableSpecs.find(spec => spec.key === variableKey);
        return variableSpec?.ioType === outputSpec.type;
    }

    const inUse = (key: string): boolean => {
        return props.assignments.findIndex(assignment => assignment.key === key) !== -1;
    }

    return (
        <div className="x-assignmentseditor">
            {props.assignments.map((assignment, index) => (
                <div key={index}>
                    <Spacer alignment="top">
                        <FormItem
                            formRef={props.formRef}
                            name={`variable-${index}`}
                            noLabel={true}
                            noError={true}
                            rules={[{
                                required: true,
                                message: "Select a variable."
                            }]}
                        >
                            <Select
                                key={assignment.key}
                                allowClear={true}
                                value={assignment.key}
                                onChange={(value) => handleVariableKeyChange(value, index)}
                            >
                                {props.variableSpecs.map(spec => (
                                    <Select.Option
                                        key={spec.key}
                                        value={spec.key}
                                        disabled={inUse(spec.key)}
                                    >
                                        {spec.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </FormItem>
                        <span className="x-assignmentseditor-eq"><ArrowLeftOutlined /></span>
                        <FormItem
                            formRef={props.formRef}
                            name={`output-key-${index}`}
                            noLabel={true}
                            noError={true}
                            rules={[{
                                required: true,
                                message: "Select an output."
                            }]}
                            postfix={
                                <Button
                                    icon={<CloseCircleOutlined />}
                                    onClick={() => handleAssignmentRemove(index)}
                                />
                            }
                        >
                            <Select
                                key={assignment.locator.key}
                                allowClear={true}
                                value={assignment.locator.key}
                                onChange={(value) => handleOutputKeyChange(value, index)}
                            >
                                {props.outputSpecs.map(spec => (
                                    <Select.Option
                                        key={spec.key}
                                        value={spec.key}
                                        disabled={!typesMatch(assignment.key, spec)}
                                    >
                                        {spec.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </FormItem>
                    </Spacer>
                    <Spacer>
                        {assignment.locator.type === IoType.RECORDS &&
                            <RecordsLocatorEditor
                                formRef={props.formRef}
                                index={index}
                                locator={assignment.locator as RecordsLocator}
                                onChange={(locator) => handleLocatorChange(locator, index)}
                            />
                        }
                        {assignment.locator.type === IoType.DATASET &&
                            <DatasetLocatorEditor
                                formRef={props.formRef}
                                index={index}
                                locator={assignment.locator as DatasetLocator}
                                onChange={(locator) => handleLocatorChange(locator, index)}
                            />
                        }
                    </Spacer>
                </div>
            ))}
            <Justify justification="left">
                <Button onClick={() => handleAssignmentAdd()}>Add Assignment</Button>
            </Justify>
        </div >
    )
}

AssignmentsEditor.defaultProps = defaultProps;