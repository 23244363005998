import { LinkOutlined } from '@ant-design/icons';
import { Italic } from 'components/Italic/Italic';
import { Chapter } from 'components/Chapter/Chapter';
import { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DocFrame } from '../DocFrame/DocFrame';

export type DashboardsDocProps = RouteComponentProps;

export const DashboardsDoc = (props: DashboardsDocProps): ReactElement => {

    return (
        <DocFrame title="Dashboards" {...props}>
            <Chapter>
                <Chapter.Paragraph>
                    Dashboards provide a means to visually monitor and track data in real-time. A dashboard
                    is composed of <Italic>applets</Italic>, which are self-contained views of the underlying
                    model data. An applet is made up of <Italic>widgets</Italic>, which are the smallest
                    visual element to display a piece of data. For example, an applet may be composed of
                    a chart widget, a divider widget, and an value change widget. Applets can also include
                    input widgets, which allow a user to change a variable linked to the underlying model
                    in order to affect the output. For example, an applet displaying a chart with timeseries
                    data may have an input that allows the date range to be changed. A change in the range
                    can be linked to the start and end date variables used in the model that are themselves
                    tied to the query used to retrieve data from its source. A variable can also be used to
                    change how a calculation is performed or can be used to select from different sets of data
                    that exist in the model.
                </Chapter.Paragraph>
                <Chapter.Section title="Layout">
                    <Chapter.Paragraph>
                        After applets are published, they become available to be added a dashboard. From an existing
                        dashboard, applets may be chosen and added. They will be initially added to a default location,
                        but can be resized and repositioned by the user. A dashboard maintains a 12-column grid system
                        into which an applet can be placed. When positioning an applet, the location of the upper-left
                        corner of the applet is specified, along with its "span", which is the width representing a
                        value from 1 to 12, with the later being the full width of the dashboard. Note that the height
                        is determined by the applet itself. So if two applet are stacked on top of one another, the
                        top applet could be placed at row 1/column 1, and the bottom applet placed at row 2/column 1.
                        To place applets next to each other, you can specify the same row, but adjust the spans so that
                        the sum for both applets is less than or equal to 12, the full width of the dashboard. If the
                        span of an applet exceeds the available space on the row, it will be moved to the next
                        row.
                    </Chapter.Paragraph>
                </Chapter.Section>
                <Chapter.Section title="Linking">
                    <Chapter.Paragraph>
                        Sometimes there is a desire to have some or all the data on a dashboard to be linked.
                        For example, by changing a date range or a stock ticker, all the applets should update
                        to reflect the changed input. If different applets have inputs that represent the same
                        value, they can be linked together so that when one changes, they are all changed at the
                        same time. When in a dashboard, look for the <LinkOutlined /> button at the top. Clicking
                        this will allow you to link inputs among different applets so that the values are synchronized.
                        Remember that these inputs are tied to model variables that can change the results in some
                        way.
                    </Chapter.Paragraph>
                </Chapter.Section>
            </Chapter>
        </DocFrame>
    );

}
