import { ReactElement, useState } from 'react';
import { Spacer } from 'components/Spacer/Spacer';
import { BlackoutInterval, UnitType } from '@methodset/application-client-ts';
import { Select } from 'antd';
import { IntegerInput } from 'components/IntegerInput/IntegerInput';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import update from 'immutability-helper';
import './BlackoutEditor.less';

export type ChangeCallback = (interval?: BlackoutInterval) => void;

export type BlackoutEditorProps = {
    interval?: BlackoutInterval,
    onChange: ChangeCallback
}

const DefaultInterval = {
    duration: 1,
    unitType: UnitType.DAY
}

export const BlackoutEditor = (props: BlackoutEditorProps): ReactElement => {

    const handleEnableChange = (e: CheckboxChangeEvent): void => {
        const enabled = e.target.checked;
        props.onChange(enabled ? DefaultInterval : undefined);
    }

    const handleDurationChange = (duration: number): void => {
        const interval = update(props.interval, {
            duration: { $set: duration }
        });
        props.onChange(interval);
    }

    const handleUnitChange = (unitType: UnitType): void => {
        const interval = update(props.interval, {
            unitType: { $set: unitType }
        });
        props.onChange(interval);
    }

    return (
        <Spacer className="x-blackouteditor" fill>
            <Checkbox
                checked={!!props.interval}
                onChange={handleEnableChange}
            />
            <IntegerInput
                natural={true}
                disabled={!props.interval}
                value={props.interval?.duration}
                onChange={handleDurationChange}
            />
            <Select
                allowClear={true}
                disabled={!props.interval}
                value={props.interval?.unitType}
                onChange={handleUnitChange}
            >
                {UnitType.entries().map(([key, value]) =>
                    <Select.Option key={key} value={key}>{value}</Select.Option>
                )}
            </Select>
        </Spacer>
    );

}
