import { Button } from 'antd';
import { message } from 'antd';
import './MessageSpinner.less';

export type CloseCallback = () => void;
export type HideCallback = () => void;

class MessageSpinner {

    private hasError: boolean = false;
    private refCount: number = 0;
    private hideFunc: HideCallback | null;

    constructor() {
        this.hasError = false;
        this.refCount = 0;
        this.hideFunc = null;
        this.handleClose = this.handleClose.bind(this);
    }

    public show(text: string): void {
        // Set the message if not displayed or clear if new
        // operation after an error.
        if (this.refCount === 0 || this.hasError) {
            // Hide error message if one is displayed.
            if (this.hideFunc && this.hasError) {
                this.hideFunc();
            }
            this.hideFunc = message.loading(text, 0);
        }
        if (this.hasError) {
            this.refCount = 1;
            this.hasError = false;
        } else {
            this.refCount++;
        }
    }

    public hide(): void {
        if (this.refCount > 0 && !this.hasError) {
            this.refCount--;
        }
        if (this.refCount === 0 && this.hideFunc) {
            this.hideFunc();
            this.hideFunc = null;
        }
    }

    public error(text: string): void {
        if (this.hasError) {
            return;
        }
        if (this.hideFunc) {
            this.hideFunc();
        }
        const content = (
            <span className="x-messagespinner">
                <span>{text}</span>
                <Button 
                    className="x-messagespinner-close"
                    type="link"
                    size="small"
                    onClick={this.handleClose}
                >
                    Close
                </Button>
            </span>
        );
        this.hideFunc = message.error(content, 0);
        this.refCount = 1;
        this.hasError = true;
    }

    public handleClose(): void {
        this.refCount = 1;
        this.hasError = false;
        this.hide();
    }

}

export default new MessageSpinner();
