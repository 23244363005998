import { ReferenceParser } from "@methodset/calculator-ts";
import { PolarWidgetConfiguration, Widget } from '@methodset/application-client-ts';

export class PolarWidgetSync {

    public static parser = (widget: Widget): string[] => {
        const configuration = widget.configuration as PolarWidgetConfiguration;
        const refs: string[] = [];
        const dataset = configuration.dataset;
        refs.push(...ReferenceParser.extract(configuration.hideId, true));
        refs.push(...ReferenceParser.extract(dataset.name));
        refs.push(...ReferenceParser.extract(dataset.labelRangeId, true));
        refs.push(...ReferenceParser.extract(dataset.valueRangeId, true));
        return refs;
    }

    public static updater = (widget: Widget, target: string, replacement: string): void => {
        const configuration = widget.configuration as PolarWidgetConfiguration;
        const dataset = configuration.dataset;
        configuration.hideId = ReferenceParser.update(configuration.hideId, target, replacement, true);
        dataset.name = ReferenceParser.update(dataset.name, target, replacement);
        dataset.labelRangeId = ReferenceParser.update(dataset.labelRangeId, target, replacement, true);
        dataset.valueRangeId = ReferenceParser.update(dataset.valueRangeId, target, replacement, true);
    }

}
