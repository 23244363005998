import { PureComponent, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner'
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { ChangeType, DashboardHeading } from './DashboardHeading/DashboardHeading';
import { Applet } from '@methodset/application-client-ts';
import { Configuration } from '@methodset/model-client-ts';
import { Dashboard, DashboardApplet, InputKey, InputLink, Installation } from '@methodset/application-client-ts';
import { Calculator } from '@methodset/calculator-ts';
import { AlertOutlined, DeleteOutlined, DragOutlined } from '@ant-design/icons';
import { ItemSpec } from 'components/MenuButton/MenuButton';
import { RouteBuilder } from 'utils/RouteBuilder';
import { CoreUtils } from 'utils/CoreUtils';
import { Item, ItemLayout, ViewItem } from 'containers/Console/Models/ModelItem/ModelApplications/ApplicationItem/ModelApplet/AppletEditor/ItemLayout/ItemLayout';
import { AppletViewer } from './AppletViewer/AppletViewer';
import { ItemPosition } from 'containers/Console/Models/ModelItem/ModelApplications/ApplicationItem/ModelApplet/AppletEditor/ItemPosition/ItemPosition';
import { StatusType } from 'constants/StatusType';
import { AuthenticationHeader, Provider } from '@methodset/endpoint-client-ts';
import { EntityContext } from 'context/EntityContext';
import { NoData } from 'components/NoData/NoData';
import { message } from 'antd';
import { AppletInstaller } from 'containers/Console/ApplicationInstaller/AppletInstaller/AppletInstaller';
import { AlertInstaller } from 'containers/Console/ApplicationInstaller/AlertInstaller/AlertInstaller';
import axios from 'axios';
import endpointService from 'services/EndpointService';
import applicationService from 'services/ApplicationService';
import update from 'immutability-helper';
import './DashboardItem.less';
import { IdUtils } from '@methodset/commons-core-ts';

// Info for item placed on the dashboard.
export interface AppletItem {
    version: number;
    installation: Installation;
    applet: Applet;
    calculator: Calculator;
}
// Map of applet to item (info).
export type ItemMap = { [key: string]: AppletItem };

// A function to execute after an API response.
type ResponseFunction = (response?: any) => void;
// Types of modals that can be displayed.
type ModalType = "none" | "applets" | "alerts" | "position";

type MatchParams = {
    dashboardId: string
}

export type DashboardItemProps = RouteComponentProps<MatchParams> & {
    className?: string
}

export type DashboardItemState = {
    // Data load status.
    status: StatusType,
    // The list of data providers.
    providers: Provider[],
    // The dashboard.
    dashboard?: Dashboard,
    // Type of modal to display.
    modalType: ModalType,
    //message?: OpMessage,
    // Map of applets.
    itemMap: ItemMap,
    // Installation when editing.
    installation?: Installation,
    // The columns of applet setups used for applet layout.
    columnSetups: DashboardApplet[][],
    // Authentication headers for credentials check.
    authentications: AuthenticationHeader[],
    // The maximum row number in the dashboard.
    maxRow: number,
    // True when dashboard is saving to storage.
    isSaving: boolean
}

// The number of columns in the dashboard grid layout.
const NUMBER_COLUMNS = Globals.LAYOUT_COLUMNS;
// The number of rows in the dashboard grid layout.
const NUMBER_ROWS = Globals.LAYOUT_ROWS;

export class DashboardItem extends PureComponent<DashboardItemProps, DashboardItemState> {

    static contextType = EntityContext;

    constructor(props: DashboardItemProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            providers: [],
            modalType: "none",
            itemMap: {},
            installation: undefined,
            columnSetups: this.emptyColumnSetups(),
            authentications: [],
            maxRow: 0,
            isSaving: false,
        };

        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleAppletLoad = this.handleAppletLoad.bind(this);
        this.handleConfigurationChange = this.handleConfigurationChange.bind(this);
        this.handleAuthenticationAdd = this.handleAuthenticationAdd.bind(this);
        this.handleAppletsShow = this.handleAppletsShow.bind(this);
        this.handleInstallerClose = this.handleInstallerClose.bind(this);
        this.handleAppletInstall = this.handleAppletInstall.bind(this);
        this.handleAppletUninstall = this.handleAppletUninstall.bind(this);
        this.handleAlertsConfigure = this.handleAlertsConfigure.bind(this);
        //this.handleAlertInstall = this.handleAlertInstall.bind(this);
        this.handleAlertsClose = this.handleAlertsClose.bind(this);
        this.handlePositionEdit = this.handlePositionEdit.bind(this);
        this.handlePositionCancel = this.handlePositionCancel.bind(this);
        this.handlePositionChange = this.handlePositionChange.bind(this);
        this.handleDashboardSave = this.handleDashboardSave.bind(this);
        this.handleDashboardChange = this.handleDashboardChange.bind(this);
        this.handleUninstallSetup = this.handleUninstallSetup.bind(this);
        this.handleUninstallComplete = this.handleUninstallComplete.bind(this);
        this.handleUninstallError = this.handleUninstallError.bind(this);
        this.handleAppletRemove = this.handleAppletRemove.bind(this);
        this.handleInstallationDelete = this.handleInstallationDelete.bind(this);
    }

    private handleAppletUninstall(installation: Installation): void {
        this.removeAppletRequest(installation);
    }

    private handleUninstallSetup(installation: Installation): void {
        const name = installation.name;
        const config = {
            key: installation.id,
            duration: 0,
            content: `Uninstalling "${name}"...`
        }
        message.loading(config);
    }

    private handleUninstallComplete(installation: Installation): void {
        message.destroy(installation.id);
    }

    private handleUninstallError(error: Error, installation: Installation): void {
        message.destroy(installation.id);
        const name = installation.name;
        const config = {
            key: installation.id,
            duration: 5,
            content: `Failed to uninstall "${name}".`
        }
        message.error(config);
    }

    private handleAppletRemove(data: any, installation: Installation): Promise<any> {
        const func = (response: any) => {
            let itemMap = update(this.state.itemMap, {
                $unset: [installation.id]
            });
            this.setState({ itemMap: itemMap });
        }
        let dashboard = this.state.dashboard!;
        // Remove any links that belong to the applet.
        let inputLinks = dashboard.inputLinks;
        for (let i = 0; i < inputLinks.length; i++) {
            const inputLink = inputLinks[i];
            const linkedKeys = inputLink.linkedKeys;
            for (let j = 0; j < linkedKeys.length; j++) {
                const linkedKey = linkedKeys[j];
                if (linkedKey.installationId === installation.id) {
                    inputLinks = update(inputLinks, {
                        [i]: {
                            linkedKeys: {
                                $splice: [[j, 1]]
                            }
                        }
                    });
                }
            }
            const inputKey = inputLink.inputKey;
            if (inputKey.installationId === installation.id || linkedKeys.length === 0) {
                inputLinks = update(inputLinks, {
                    $splice: [[i, 1]]
                });
                i--;
            }
        }
        const index = dashboard.applets.findIndex(applet => applet.installationId === installation.id);
        dashboard = update(dashboard, {
            applets: {
                $splice: [[index, 1]]
            },
            inputLinks: { $set: inputLinks }
        });
        return this.updateDashboardRequest(dashboard, func);
    }

    private handleInstallationDelete(data: any, installation: Installation): Promise<any> {
        return this.deleteInstallationRequest(data, installation);
    }

    // private handlePackUninstall(data: any, installation: Installation): Promise<any> {
    //     return this.uninstallPackRequest(data, installation);
    // }

    private deleteInstallationRequest(data: any, installation: Installation): Promise<any> {
        const request = {
            installationId: installation.id
        }
        return applicationService.deleteInstallation(request,
            (response: any) => this.deleteInstallationResponse(response),
            (response: any) => this.handleResponseException(response),
            true
        );
    }

    private deleteInstallationResponse(response: any): void {
        // noop
    }

    private handleResponseException(response: any): void {
        throw RestUtils.getError(response);
    }

    // private uninstallPackRequest(data: any, installation: Installation): Promise<any> {
    //     const packInfo = installation.packInfo;
    //     const request = {
    //         packId: packInfo.packId,
    //         version: packInfo.version,
    //         instanceId: installation.id,
    //         accessType: packInfo.accessType
    //     }
    //     return libraryService.uninstallPack(request,
    //         (response: any) => this.uninstallPackResponse(response, installation),
    //         (response: any) => this.handleResponseException(response),
    //         true
    //     );
    // }

    // private uninstallPackResponse(response: any, installation: Installation): void {
    //     // noop
    // }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleAppletLoad(installation: Installation, applet: Applet, calculator: Calculator): void {
        let itemMap;
        let appletItem = this.state.itemMap[installation.id];
        if (appletItem) {
            // Applet version has been upgraded. The version value
            // has already been update on install. 
            itemMap = update(this.state.itemMap, {
                [installation.id]: {
                    installation: { $set: installation },
                    applet: { $set: applet },
                    calculator: { $set: calculator }
                }
            });
        } else {
            // Initial applet load.
            appletItem = {
                version: 0,
                installation: installation,
                applet: applet,
                calculator: calculator
            }
            itemMap = update(this.state.itemMap, {
                [installation.id]: { $set: appletItem }
            });
        }

        const dashboard = this.state.dashboard!;
        itemMap = this.copyLinkedVariables(itemMap, dashboard.inputLinks, installation);
        for (const inputLink of dashboard.inputLinks) {
            // Refresh models that are linked from this applet and are loaded.
            if (installation.id === inputLink.inputKey.installationId) {
                for (const linkedKey of inputLink.linkedKeys) {
                    const item = itemMap[linkedKey.installationId];
                    if (item) {
                        this.refreshModel(item);
                    }
                }
            }
            // Refresh this model that has a link to it, and is loaded.
            for (const linkedKey of inputLink.linkedKeys) {
                if (installation.id === linkedKey.installationId) {
                    // Check if the source exists and updated a link value
                    let item = itemMap[inputLink.inputKey.installationId];
                    if (item) {
                        item = itemMap[installation.id];
                        this.refreshModel(item);
                    }
                }
            }
        }
        this.setState({ itemMap: itemMap });
    }

    private handleConfigurationChange(installation: Installation, configuration: Configuration): void {
        let dashboard = this.state.dashboard!
        const appletItem = this.state.itemMap[installation.id];
        if (!appletItem) {
            return;
        }
        let itemMap = update(this.state.itemMap, {
            [installation.id]: {
                installation: {
                    state: {
                        configuration: { $set: configuration }
                    }
                }
            }
        });
        itemMap = this.copyLinkedVariables(itemMap, dashboard.inputLinks, installation);
        this.refreshModels(itemMap);
        this.setState({ itemMap: itemMap });
    }

    private handleAuthenticationAdd(installation: Installation, header: AuthenticationHeader): void {
        const authentications = update(this.state.authentications, {
            $push: [header]
        });
        this.setState({ authentications: authentications });
    }

    private handleAppletsShow(): void {
        this.setState({ modalType: "applets" });
    }

    private handleInstallerClose(): void {
        this.setState({ modalType: "none" });
    }

    private handlePositionEdit(installation: Installation): void {
        this.setState({
            modalType: "position",
            installation: installation
        });
    }

    private handlePositionChange(item: Item): void {
        const func = (response: any) => {
            this.setState({
                modalType: "none",
                installation: undefined
            });
        }
        const installation = this.state.installation!;
        let dashboard = this.state.dashboard!
        const index = dashboard.applets.findIndex(applet => applet.installationId === installation.id);
        if (index === -1) {
            return;
        }
        dashboard = update(dashboard, {
            applets: {
                [index]: {
                    row: { $set: item.row },
                    col: { $set: item.col },
                    span: { $set: item.span }
                }
            }
        });
        this.updateDashboardRequest(dashboard, func);
    }

    private handlePositionCancel(): void {
        this.setState({
            modalType: "none",
            installation: undefined
        });
    }

    private handleDashboardSave(): void {
        const dashboard = this.state.dashboard!;
        this.updateDashboardRequest(dashboard);
    }

    private handleDashboardChange(value: InputLink[] | Dashboard, type: ChangeType): void {
        let dashboard = this.state.dashboard!;
        if (type === ChangeType.LINKS) {
            const inputLinks = value as InputLink[];
            dashboard = update(dashboard, {
                inputLinks: { $set: inputLinks }
            });
        } else if (type === ChangeType.PROPERTIES) {
            dashboard = value as Dashboard;
        }
        const func = (response: any) => {
            const dashboard = response.data.dashboard;
            const itemMap = this.copyLinkedVariables(this.state.itemMap, dashboard.inputLinks);
            this.refreshModels(itemMap);
            this.setState({
                itemMap: itemMap,
                modalType: "none",
                installation: undefined
            });
        }
        this.updateDashboardRequest(dashboard, func);
    }

    private handleAlertsConfigure(installation: Installation): void {
        this.setState({
            modalType: "alerts",
            installation: installation
        });
    }

    private handleAlertsInstall(installation: Installation): void {
        // TODO?
    }

    private handleAlertsClose(): void {
        this.setState({
            modalType: "none",
            installation: undefined
        });
    }

    private handleAppletInstall(dashboard: Dashboard): void {
        this.setupColumns(dashboard.applets);
        this.setState({ dashboard: dashboard });
    }

    private emptyColumnSetups(): DashboardApplet[][] {
        let size = NUMBER_COLUMNS;
        const columnSetups = [];
        while (size--) {
            columnSetups.push([]);
        }
        return columnSetups;
    }

    private setupColumns(applets: DashboardApplet[]): void {
        // Add the applet refs into their respective columns.
        const columnSetups = this.emptyColumnSetups();
        //const appletStates = dashboard.appletStates;
        for (const applet of applets) {
            // Sanitize data.
            if (CoreUtils.isEmpty(applet.col)) {
                applet.col = 0;
            } else if (applet.col > NUMBER_COLUMNS - 1) {
                applet.col = NUMBER_COLUMNS - 1;
            }
            if (CoreUtils.isEmpty(applet.row)) {
                applet.row = 0;
            } else if (applet.row > NUMBER_ROWS - 1) {
                applet.col = NUMBER_ROWS - 1;
            }
            if (CoreUtils.isEmpty(applet.span)) {
                applet.span = 6;
            } else if (applet.span > columnSetups.length) {
                applet.span = columnSetups.length;
            }
            const col = applet.col;
            columnSetups[col].push(applet);
        }
        // Sort the columns by index.
        for (const columnRef of columnSetups) {
            columnRef.sort((a, b) => a.row - b.row);
        }
        // Shift refs that have the same coordinate.
        for (const columnRef of columnSetups) {
            this.shiftIdenticalLocations(columnRef);
        }
        // Find the maximum row index containing a ref in all the rows.
        let maxRow = 0;
        for (const columnRef of columnSetups) {
            for (const appletRef of columnRef) {
                if (appletRef.row > maxRow) {
                    maxRow = appletRef.row;
                }
            }
        }
        this.setState({
            columnSetups: columnSetups,
            maxRow: maxRow
        });
    }

    private shiftIdenticalLocations(columnSetups: DashboardApplet[]): void {
        if (columnSetups.length === 0) {
            return;
        }
        let prev = columnSetups[0].row;
        for (let i = 1; i < columnSetups.length; i++) {
            if (columnSetups[i].row <= prev) {
                columnSetups[i].row = prev + 1;
            }
            prev = columnSetups[i].row;
        }
    }

    private refreshModels(itemMap: ItemMap): void {
        for (const appletItem of Object.values(itemMap)) {
            this.refreshModel(appletItem);
        }
    }

    private refreshModel(appletItem: AppletItem): void {
        const calculator = appletItem.calculator;
        const configuration = appletItem.installation.state!.configuration;
        this.executeCalculator(calculator, configuration);
    }

    private executeCalculator(calculator: Calculator, configuration: Configuration | undefined): void {
        // Suspend calculator updates while setting parameters.
        calculator.suspend();
        if (configuration) {
            this.overrideVariables(configuration, calculator);
        }
        // Unsuspend and execute with the new parameter values.
        calculator.execute();
    }

    private overrideVariables(configuration: Configuration, calculator: Calculator): void {
        const variables = calculator.variables;
        for (const [key, value] of Object.entries(configuration)) {
            const variable = variables.get(key, false);
            if (variable && variable.cell) {
                const cell = variable.cell;
                // Sanity check, cannot overwrite formula.
                if (!CoreUtils.isFormula(value)) {
                    cell.value = value;
                }
            }
        }
    }

    private readProvidersRequest(): Promise<any> {
        if (this.state.providers) {
            return Promise.resolve(true);
        }
        const request = {};
        return endpointService.readProviders(request,
            (response: any) => this.readProvidersResponse(response),
            undefined, true
        );
    }

    private readProvidersResponse(response: any): void {
        const providers = response.data.providers;
        this.setState({ providers: providers });
    }

    private initDashboardRequest(): Promise<any> {
        const dashboardId = this.props.match.params.dashboardId;
        if (dashboardId === "create") {
            return this.createDashboardRequest();
        } else {
            return this.readDashboardRequest(dashboardId);
        }
    }

    private createDashboardRequest(): Promise<any> {
        const request = {
            name: "New Dashboard"
        };
        return applicationService.createDashboard(request,
            (response: any) => this.createDashboardResponse(response),
            undefined, true
        );
    }

    private createDashboardResponse(response: any): void {
        const dashboard = response.data.dashboard;
        this.setState({ dashboard: dashboard });
        // Change the URL to include the new dashboard id.
        this.props.history.push(RouteBuilder.dashboard(dashboard.id));
    }

    private updateDashboardRequest(dashboard: Dashboard, func?: ResponseFunction, throwOnError: boolean = false): Promise<any> {
        this.setState({ isSaving: true });
        const request = {
            dashboardId: dashboard.id,
            name: dashboard.name,
            description: dashboard.description,
            applets: dashboard.applets,
            inputLinks: dashboard.inputLinks
        };
        return applicationService.updateDashboard(request,
            (response: any) => this.updateDashboardResponse(response, func),
            (response: any) => this.updateDashboardException(response, throwOnError),
            true
        );
    }

    private updateDashboardResponse(response: any, func?: ResponseFunction): void {
        if (func) {
            func(response);
        }
        let dashboard = response.data.dashboard;
        this.setupColumns(dashboard.applets);
        this.setState({
            dashboard: dashboard,
            isSaving: false
        });
    }

    private updateDashboardException(response: Response, throwOnError: boolean): void {
        const error = RestUtils.getError(response);
        console.log(`Error saving dashboard: ${error.message}`);
        this.setState({ isSaving: false });
        if (throwOnError) {
            throw error;
        } else {
            // TODO: display error
        }
    }

    private readDashboardRequest(dashboardId: string): Promise<any> {
        const request = {
            dashboardId: dashboardId
        };
        return applicationService.readDashboard(request,
            (response: any) => this.readDashboardResponse(response),
            undefined, true
        );
    }

    private readDashboardResponse(response: any): void {
        const dashboard = response.data.dashboard;
        this.setupColumns(dashboard.applets);
        this.setState({ dashboard: dashboard });
    }

    private readAuthenticationHeadersRequest(): Promise<any> {
        const request = {};
        return endpointService.readAuthenticationHeaders(request,
            (response: any) => this.readAuthenticationHeadersResponse(response),
            undefined, true
        );
    }

    private readAuthenticationHeadersResponse(response: any): void {
        const headers = response.data.headers;
        this.setState({ authentications: headers });
    }

    private removeAppletRequest(installation: Installation): Promise<any> {
        const request = {
            dashboardId: this.state.dashboard!.id,
            installationId: installation.id
        };
        return applicationService.uninstallApplet(request,
            (response: any) => this.removeAppletResponse(response),
            undefined, false
        );
    }

    private removeAppletResponse(response: any): void {
        const installationId = response.data.installationId;
        const applets = this.state.dashboard!.applets;
        const index = applets.findIndex(applet => applet.installationId === installationId);
        if (index !== -1) {
            const dashboard = update(this.state.dashboard!, {
                applets: {
                    $splice: [[index, 1]]
                }
            });
            this.setupColumns(dashboard.applets);
            this.setState({ dashboard: dashboard });
        }
    }

    private findDashboardApplet(installationId: string): DashboardApplet | undefined {
        const dashboard = this.state.dashboard!;
        return dashboard.applets.find(applet => applet.installationId === installationId);
    }

    private copyLinkedVariables(itemMap: ItemMap, inputLinks: InputLink[], installation?: Installation): ItemMap {
        for (const inputLink of inputLinks) {
            const inputKey = inputLink.inputKey;
            const sourceItem = itemMap[inputKey.installationId];
            if (!sourceItem) {
                continue;
            }
            const sourceConfiguration = sourceItem.installation.state!.configuration;
            if (!installation || installation.id === inputKey.installationId) {
                // Find the specs that will be linked from the source.
                // Those specs need to be removed so that the user is
                // not able to set them anymore. Values will come from
                // the "from" link.
                const linkedKeys = inputLink.linkedKeys;
                for (const linkedKey of linkedKeys) {
                    itemMap = this.copyConfiguration(itemMap, inputKey, linkedKey, sourceConfiguration);
                }
            }
            const linkedKeys = inputLink.linkedKeys;
            for (const linkedKey of linkedKeys) {
                if (installation && installation.id !== linkedKey.installationId) {
                    continue;
                }
                itemMap = this.copyConfiguration(itemMap, inputKey, linkedKey, sourceConfiguration);
            }
        }
        return itemMap;
    }

    private copyConfiguration(itemMap: ItemMap, inputKey: InputKey, linkedKey: InputKey, configuration: Configuration): ItemMap {
        const targetItem = itemMap[linkedKey.installationId];
        if (!targetItem) {
            return itemMap;
        }
        const targetKey = linkedKey.specKey;
        const sourceKey = inputKey.specKey;
        itemMap = update(itemMap, {
            [linkedKey.installationId]: {
                installation: {
                    state: {
                        configuration: {
                            [targetKey]: { $set: configuration[sourceKey] }
                        }
                    }
                }
            }
        });
        return itemMap;
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.initDashboardRequest());
        requests.push(this.readProvidersRequest());
        requests.push(this.readAuthenticationHeadersRequest());
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1, r2, r3) => {
            if (RestUtils.isOk(r1, r2, r3)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSpinner
                className="x-dashboarditem-loading"
                loadingMessage="Loading dashboard..."
                failedMessage="Failed to load dashboard."
                status={isLoading ? "loading" : "failed"}
                onRetry={this.handleRetryLoad}
            />
        )
    }

    private buildAppletView(applet: DashboardApplet): ReactElement {
        const actionItems: ItemSpec[] = [{
            icon: <AlertOutlined />,
            label: "Add Alert...",
            disabled: (installation: Installation) => !installation,
            onSelect: (installation: Installation) => this.handleAlertsConfigure(installation)
        }, {
            icon: <DragOutlined />,
            label: "Position...",
            onSelect: this.handlePositionEdit
        }, {
            icon: <DeleteOutlined />,
            label: "Remove",
            confirm: "Are you sure you want to remove the applet?",
            onSelect: this.handleAppletUninstall
        }];
        //const item = this.state.itemMap[applet.installationId];
        //const version = item ? item.version : 0;
        return (
            <AppletViewer
                //key={version}
                key={applet.installationId}
                providers={this.state.providers}
                installationId={applet.installationId}
                actionItems={actionItems}
                authentications={this.state.authentications}
                onLoad={this.handleAppletLoad}
                onConfiguration={this.handleConfigurationChange}
                onAuthentication={this.handleAuthenticationAdd}
            />
        )
    }

    private buildViewItems(applets: DashboardApplet[]): ViewItem[] {
        return applets.map(applet => this.buildViewItem(applet));
    }

    private buildViewItem(applet: DashboardApplet): ViewItem {
        const element = this.buildAppletView(applet);
        const viewItem = {
            id: applet.installationId,
            row: applet.row,
            col: applet.col,
            span: applet.span,
            element: element
        }
        return viewItem;
    }

    private buildDashboardView(): ReactElement {
        const dashboard = this.state.dashboard!;
        const applets = dashboard.applets;
        return (
            <>
                <DashboardHeading
                    dashboard={this.state.dashboard!}
                    itemMap={this.state.itemMap}
                    isSaving={this.state.isSaving}
                    onAdd={this.handleAppletsShow}
                    onSave={this.handleDashboardSave}
                    onChange={this.handleDashboardChange}
                />
                <div className="x-dashboarditem-applets">
                    {(!applets || applets.length === 0) &&
                        <NoData
                            className="x-dashboarditem-empty"
                            text="No applets."
                        />
                    }
                    {applets && applets.length > 0 &&
                        <ItemLayout items={this.buildViewItems(applets)} />
                    }
                </div>
                {this.state.modalType === "applets" &&
                    <AppletInstaller
                        dashboard={this.state.dashboard!}
                        columnSetups={this.state.columnSetups}
                        onInstall={this.handleAppletInstall}
                        onClose={this.handleInstallerClose}
                    />
                }
                {this.state.modalType === "alerts" &&
                    <AlertInstaller
                        moduleId={this.state.installation!.moduleId}
                        accessType={IdUtils.toAccessType(this.state.installation?.applicationId)}
                        onInstall={this.handleAlertsInstall}
                        onClose={this.handleAlertsClose}
                    />
                }
                {/* {this.state.modalType === "alerts" &&
                    <ApplicationPacks
                        installation={this.state.installation!}
                        applicationType={ApplicationType.ALERT}
                        onClose={this.handleAlertsClose}
                    />
                } */}
                {this.state.modalType === "position" &&
                    <ItemPosition
                        type="applet"
                        rows={Math.max(Globals.LAYOUT_ROWS, this.state.maxRow + 1)}
                        cols={Globals.LAYOUT_COLUMNS}
                        items={this.state.dashboard!.applets}
                        item={this.findDashboardApplet(this.state.installation!.id)!}
                        onChange={this.handlePositionChange}
                        onCancel={this.handlePositionCancel}
                    />
                }
            </>
        )
    }

    public componentDidMount(): void {
        if (this.state.status !== StatusType.READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === StatusType.LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === StatusType.FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === StatusType.READY) {
            view = this.buildDashboardView();
        }
        return (
            <div id="dashboarditem" className="x-dashboarditem">
                {view}
            </div>
        )
    }

}
