import { PureComponent, ReactElement } from 'react';
import { DeleteOutlined, EditOutlined, SyncOutlined } from '@ant-design/icons';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Space, Tag } from 'antd';
import { Globals } from 'constants/Globals';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { RouteBuilder } from 'utils/RouteBuilder';
import { YodleeProvider } from '@methodset/endpoint-client-ts';
import { ColumnsType } from 'antd/lib/table';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { StatusType } from 'constants/StatusType';
import axios from 'axios';
import classNames from 'classnames';
import update from 'immutability-helper';
import endpointService from 'services/EndpointService';
import './Accounts.less';

export type AccountsProps = RouteComponentProps & {
    className?: string
}

export type AccountsState = {
    status: StatusType,
    providers: YodleeProvider[]
}

// NOTE: A Yodlee "provider" is a linked account to a financial institution.
export class Accounts extends PureComponent<AccountsProps, AccountsState> {

    constructor(props: AccountsProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            providers: []
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
        this.handleAccountCreate = this.handleAccountCreate.bind(this);
        this.handleAccountEdit = this.handleAccountEdit.bind(this);
        this.handleAccountDelete = this.handleAccountDelete.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleRefreshClick(): void {
        this.loadData();
    }

    private handleAccountCreate(): void {
        const url = RouteBuilder.account("create");
        this.props.history.push(url);
    }

    private handleAccountEdit(provider: YodleeProvider): void {
        const url = RouteBuilder.account(provider.id)
        this.props.history.push(url);
    }

    private handleAccountDelete(provider: YodleeProvider): void {
        this.deleteYodleeProviderRequest(provider.id);
    }

    private readYodleeProvidersRequest(): Promise<any> {
        const request = {};
        return endpointService.readYodleeProviders(request,
            (response: any) => this.readYodleeProvidersResponse(response),
            undefined, true
        );
    }

    private readYodleeProvidersResponse(response: any): void {
        const providers = response.data.providers;
        this.setState({ providers: providers });
    }

    private deleteYodleeProviderRequest(providerId: string): Promise<any> {
        const request = {
            providerId: providerId
        };
        return endpointService.deleteYodleeProvider(request,
            (response: any) => this.deleteYodleeProviderResponse(response)
        );
    }

    private deleteYodleeProviderResponse(response: any): void {
        const providerId = response.data.providerId;
        const index = this.state.providers.findIndex(provider => provider.id === providerId);
        this.setState({
            providers: update(this.state.providers, {
                $splice: [[index, 1]]
            })
        });
    }

    private buildColumns(): ColumnsType<any> {
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
            render: (value, record) => {
                return <Link to={RouteBuilder.account(record.id)}>{value}</Link>
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'utime',
            title: 'Last Updated',
            width: Globals.TABLE_WIDTH_MEDIUM,
            align: 'center',
            dataIndex: 'updateTime',
            render: (value, record) => {
                return (
                    <span>
                        {CoreUtils.toTime(value)}
                    </span>
                );
            },
            sorter: (a, b) => CoreUtils.compareNumbers(a.updateTime, b.updateTime),
            sortDirections: ['descend', 'ascend']
        }];
        return columns;
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readYodleeProvidersRequest());
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== StatusType.READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        const actions = [{
            icon: <EditOutlined />,
            label: "Edit account link",
            callback: this.handleAccountEdit
        }, {
            icon: <DeleteOutlined />,
            label: "Remove account link",
            confirm: "Are you sure you want to remove the account link?",
            callback: this.handleAccountDelete
        }];
        const columns = this.buildColumns();
        const data = this.state.providers;
        return (
            <ItemTable
                className={classNames('x-accounts', this.props.className)}
                title="Accounts"
                status={this.state.status}
                columns={columns}
                items={data}
                extra={
                    <Space>
                        <Button onClick={this.handleAccountCreate}>Link Account</Button>
                        <Button icon={<SyncOutlined />} onClick={this.handleRefreshClick}></Button>
                    </Space>
                }
                actions={actions}
                onLoad={this.handleRetryLoad}
            />
        );
    }

}
