import { FC, ReactElement } from 'react';
import { Button, Modal } from 'antd';
import { Globals } from 'constants/Globals';
import { Label } from 'components/Label/Label';
import { Spacer } from 'components/Spacer/Spacer';
import { CoreUtils } from 'utils/CoreUtils';
import { InstallationHeader } from '@methodset/application-client-ts';
import { IdUtils } from '@methodset/commons-core-ts';
import './AlertAbout.less';

export type CloseCallback = () => void;

export type AlertAboutProps = {
    header: InstallationHeader,
    onClose: CloseCallback
}

export const AlertAbout: FC<AlertAboutProps> = (props: AlertAboutProps): ReactElement => {

    // TODO: add trigger schedule, publisher, category, version
    return (
        <Modal
            className="x-alertabout"
            centered
            title="Alert Information"
            width={Globals.DIALOG_WIDTH_STANDARD}
            onCancel={() => props.onClose()}
            visible={true}
            footer={(
                <Button type="primary" onClick={() => props.onClose()}>Close</Button>
            )}
        >
            <Spacer direction="vertical">
                <Label label="Name" alignment="top">{props.header.name}</Label>
                <Label label="Description" alignment="top">{props.header.description ?? "N/A"}</Label>
                {/* <Label label="Publisher" alignment="top">{props.header.publisher ?? "N/A"}</Label>
                <Label label="Category" alignment="top">{CoreUtils.toProper(props.header.categoryType)}</Label>
                <Label label="Version" alignment="top">{CoreUtils.toVersion(props.application.version)}</Label> */}
                <Label label="Access" alignment="top">{CoreUtils.toProper(IdUtils.toAccessType(props.header.applicationId))}</Label>
                <Label label="Installed" alignment="top">{CoreUtils.toTime(props.header.createTime)}</Label>
                </Spacer>
        </Modal>
    )

}
