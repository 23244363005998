import { Calculator, ErrorType } from "@methodset/calculator-ts";
import { Alert, Condition, OpType } from "@methodset/application-client-ts";
import { Configuration } from "@methodset/model-client-ts";

export enum SectionType {
    TEXT,
    INPUT,
    CONDITION
}
export interface Section {
    type: SectionType;
    match: string;
}
export interface TextSection extends Section {
    content: string;
}
export interface InputSection extends Section {
    variableId: string
}
export interface ConditionSection extends Section {
    variableId: string;
    opType?: OpType;
}
export type SectionCallback = (section: Section, index: number) => void;

export class InstructionsUtils {

    // Extracts variables of the form ${...} from a string.
    private static readonly VARIABLE_REGEX = /\${([^{}]*)}/g;
    // Extracts sections from a string.
    private static readonly SECTION_REGEX = /[.,;]/g;

    private static isDuplicate(variableId: string, conditions: Condition[]): boolean {
        let count = 0;
        for (const condition of conditions) {
            if (condition.variableId === variableId) {
                count += 1;
            }
        }
        return count > 1;
    }

    public static findCondition(variableId: string, opType: OpType | undefined, conditions: Condition[]): Condition | undefined {
        return conditions.find(condition => condition.variableId === variableId && condition.opType === opType);
    }

    public static validateInstructions(instructions: string | undefined, inputIds: string[], conditions: Condition[]): string | undefined {
        if (!instructions) {
            return instructions;
        }
        const parts: string[] = [];
        const callback = (section: Section): void => {
            switch (section.type) {
                case SectionType.TEXT: {
                    const part = section as TextSection;
                    parts.push(part.match);
                    break;
                }
                case SectionType.INPUT: {
                    const part = section as InputSection;
                    const index = inputIds.findIndex(inputId => inputId === part.variableId);
                    if (index === -1) {
                        parts.push(ErrorType.INVALID_REFERENCE);
                    } else {
                        parts.push(part.match);
                    }
                    break;
                }
                case SectionType.CONDITION: {
                    const part = section as ConditionSection;
                    const condition = InstructionsUtils.findCondition(part.variableId, part.opType, conditions);
                    if (condition) {
                        parts.push(part.match);
                    } else {
                        parts.push(ErrorType.INVALID_REFERENCE);
                    }
                    break;
                }
                default:
            }
        }
        InstructionsUtils.visitSections(instructions, callback);
        return parts.join("");
    }

    public static visitSections(instructions: string, callback: SectionCallback): void {
        let position = 0;
        let match = InstructionsUtils.VARIABLE_REGEX.exec(instructions);
        let index = 0;
        while (match) {
            if (position !== match.index) {
                const content = instructions.substring(position, match.index);
                const section = {
                    type: SectionType.TEXT,
                    content: content,
                    match: content
                }
                callback(section, index++);
            }
            position = match.index + match[0].length;
            const ref = match[1];
            if (ref.startsWith("input")) {
                const variableId = ref.substring("input".length + 1);
                const section = {
                    type: SectionType.INPUT,
                    variableId: variableId,
                    match: match[0]
                }
                callback(section, index++);
            } else if (ref.startsWith("condition")) {
                let variableId = ref.substring("condition".length + 1);
                let opType: OpType | undefined;
                let index = variableId.indexOf(".");
                if (index !== -1) {
                    opType = OpType.type(variableId.substring(index + 1));
                    variableId = variableId.substring(0, index);
                }
                const section = {
                    type: SectionType.CONDITION,
                    variableId: variableId,
                    opType: opType,
                    match: match[0]
                }
                callback(section, index++);
            }
            match = InstructionsUtils.VARIABLE_REGEX.exec(instructions);
        }

        const content = instructions.substring(position);
        const section = {
            type: SectionType.TEXT,
            content: content,
            match: content
        }
        callback(section, index++);
    }

    public static buildText(alert: Alert, calculator: Calculator, configuration: Configuration, conditions: Condition[]): string {
        const summary: string[] = [];
        const instructions = alert.instructions!;
        const callback = (section: Section, index: number): void => {
            switch (section.type) {
                case SectionType.TEXT: {
                    const part = section as TextSection;
                    summary.push(part.content);
                    break;
                }
                case SectionType.INPUT: {
                    const part = section as InputSection;
                    let value = configuration[part.variableId];
                    const variable = calculator.variables.get(part.variableId, false);
                    if (variable?.options) {
                        const option = variable.options.find(option => option.value === value);
                        if (option) {
                            value = option.label;
                        }
                    }
                    summary.push(value);
                    break;
                }
                case SectionType.CONDITION: {
                    const part = section as ConditionSection;
                    let index = conditions.findIndex(condition => condition.variableId === part.variableId && condition.opType === part.opType);
                    if (index !== -1) {
                        const condition = InstructionsUtils.findCondition(part.variableId, part.opType, conditions);
                        if (condition) {
                            summary.push(OpType.label(condition.opType!));
                            // TODO: format (see FormattedInput)
                            summary.push(condition.value);
                        }
                    }
                    break;
                }
                default:
            }
        }
        InstructionsUtils.visitSections(instructions, callback);
        return summary.join(" ");
    }

}