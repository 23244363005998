import React, { PureComponent, ReactElement } from 'react';
import { Form, FormInstance, Modal, Select } from 'antd';
import { Configuration, ConfigurationSpec, Stringer, StringerType } from '@methodset/endpoint-client-ts';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { ConfigurationEditor } from 'containers/Console/ConfigurationEditor/ConfigurationEditor';
import { StatusType } from 'constants/StatusType';
import { RestUtils } from 'utils/RestUtils';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { Spacer } from 'components/Spacer/Spacer';
import axios from 'axios';
import update from 'immutability-helper';
import endpointService from 'services/EndpointService';
import './FormatDialog.less';

export type ChangeCallback = (stringer: Stringer | undefined) => void;
export type CancelCallback = () => void;

export type FormatDialogProps = typeof FormatDialog.defaultProps & {
    stringer?: Stringer,
    onChange: ChangeCallback,
    onCancel: CancelCallback
}

export type FormatDialogState = {
    status: string,
    stringer?: Stringer,
    specs: ConfigurationSpec[]
}

export class FormatDialog extends PureComponent<FormatDialogProps, FormatDialogState> {

    private formRef = React.createRef<FormInstance>();

    static defaultProps = {
        //stringer: {}
    }

    constructor(props: FormatDialogProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            stringer: props.stringer,
            specs: []
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleOkClick = this.handleOkClick.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleConfigurationChange = this.handleConfigurationChange.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleOkClick(): void {
        this.formRef.current!.validateFields().then(values => {
            this.props.onChange(this.state.stringer);
        }).catch(e => {
        });
    }

    private handleConfigurationChange(configuration: Configuration): void {
        const stringer = update(this.state.stringer, {
            configuration: { $set: configuration }
        });
        this.setState({ stringer: stringer });
    }

    private handleTypeChange(type: StringerType): void {
        let stringer;
        if (type) {
            stringer = {
                type: type,
                configuration: {}
            } as Stringer;
        } else {
            stringer = undefined;
        }
        this.setState({ stringer: stringer });
        this.readStringerSpecsRequest(type);
    }

    private readStringerSpecsRequest(stringerType: StringerType | undefined): Promise<any> {
        if (!stringerType) {
            return Promise.resolve(true);
        } else {
            this.setState({ status: StatusType.LOADING });
        }
        const request = {
            stringerType: stringerType
        };
        return endpointService.readStringerSpecs(request,
            (response: any) => this.readStringerSpecsResponse(response),
            (response: any) => this.readStringerSpecsException(response),
            true
        );
    }

    private readStringerSpecsResponse(response: any): void {
        const specs = response.data.specs;
        this.setState({
            specs: specs,
            status: StatusType.READY
        });
    }

    private readStringerSpecsException(response: any): void {
        this.setState({ status: StatusType.FAILED });
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSkeleton
                count={2}
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load formatter."
                onRetry={this.handleRetryLoad}
            >
                <LoadSkeleton.Input length="fill" />
            </LoadSkeleton>
        );
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readStringerSpecsRequest(this.state.stringer?.type));
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        this.loadData();
    }

    private buildConfigurationView(): ReactElement {
        if (this.state.stringer) {
            return (
                <ConfigurationEditor
                    formRef={this.formRef}
                    configuration={this.state.stringer.configuration}
                    configurationSpecs={this.state.specs}
                    authentications={[]}
                    showEmpty={false}
                    showExpressions={false}
                    onChange={this.handleConfigurationChange}
                />
            )
        } else {
            return <></>;
        }
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === StatusType.LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === StatusType.FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === StatusType.READY) {
            view = this.buildConfigurationView();
        }
        return (
            <Modal
                className="x-formatdialog"
                centered
                title="Formatting"
                visible={true}
                width={Globals.DIALOG_WIDTH}
                okText="Ok"
                onOk={this.handleOkClick}
                onCancel={this.props.onCancel}
            >
                <Form ref={this.formRef}>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        noError={true}
                        name="type"
                        label="Type"
                        info={StringerType.description(this.state.stringer?.type)}
                    >
                        <Select
                            placeholder="Formatter type."
                            allowClear={true}
                            value={this.state.stringer?.type}
                            onChange={this.handleTypeChange}
                        >
                            {StringerType.entries().map(([key, value]) =>
                                <Select.Option key={key} value={key}>{value.name}</Select.Option>
                            )}
                        </Select>
                    </FormItem>
                    {this.state.stringer?.type &&
                        <Spacer className="x-formatdialog-example">
                            <span>Example:</span>
                            <span>{StringerType.example(this.state.stringer.type)}</span>
                        </Spacer>
                    }
                    {view}
                </Form>
            </Modal>
        );

    }

}
