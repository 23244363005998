import { FC, ReactElement, useState } from 'react';
import { Button, Modal } from 'antd';
import { Globals } from 'constants/Globals';
import { Spacer } from 'components/Spacer/Spacer';
import { Label } from 'components/Label/Label';
import { CoreUtils } from 'utils/CoreUtils';
import { PackHeader, PackType } from '@methodset/library-client-ts';
import { QueryAbout } from './QueryAbout/QueryAbout';
import { WorkflowAbout } from './WorkflowAbout/WorkflowAbout';
import './PackAbout.less';

export type CloseCallback = () => void;

export type PackAboutProps = {
    packHeader: PackHeader,
    onClose: CloseCallback
}

export const PackAbout: FC<PackAboutProps> = (props: PackAboutProps): ReactElement => {

    const header = props.packHeader;
    const type = header.packType;
    const defaultView = (
        <>
            <Label label="Type" alignment="top">{CoreUtils.toProper(header.packType)}</Label>
            <Label label="Name" alignment="top">{header.name}</Label>
            <Label label="Description" alignment="top">{header.description}</Label>
            <Label label="Version" alignment="top">{CoreUtils.toVersion(header.version)}</Label>
            <Label label="Publisher" alignment="top">{header.publisher}</Label>
            <Label label="Environment" alignment="top">{CoreUtils.toProper(header.environmentType)}</Label>
            <Label label="Access" alignment="top">{CoreUtils.toProper(header.accessType)}</Label>
        </>
    )

    let custom;
    if (type === PackType.QUERY) {
        custom = <QueryAbout packHeader={props.packHeader} defaultView={defaultView} />
    } else if (type === PackType.WORKFLOW) {
        custom = <WorkflowAbout packHeader={props.packHeader} defaultView={defaultView} />
    }
    return (
        <Modal
            className="x-packinformation"
            centered
            title="Package Information"
            width={Globals.DIALOG_WIDTH_STANDARD}
            onCancel={() => props.onClose()}
            visible={true}
            footer={(
                <Button type="primary" onClick={() => props.onClose()}>Close</Button>
            )}
        >
            <Spacer direction="vertical">
                {!!custom &&
                    custom
                }
                {!custom &&
                    defaultView
                }
            </Spacer>
        </Modal>
    )

}
