import { PureComponent, ReactElement } from 'react';
import { BookOutlined, DeleteOutlined, EditOutlined, ExportOutlined, InfoCircleOutlined, SyncOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Radio, RadioChangeEvent, Space, Tag } from 'antd';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { ColumnsType } from 'antd/lib/table';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { StatusType } from 'constants/StatusType';
import { PackHeader, PackType } from '@methodset/library-client-ts';
import { Globals } from 'constants/Globals';
import { PackDeployer, DeployType } from './PackDeployer/PackDeployer';
import { AccessType, EnvironmentType } from '@methodset/commons-core-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { EntityContext } from 'context/EntityContext';
import { PermissionsHelper } from '@methodset/entity-client-ts';
import { Link, RouteComponentProps } from 'react-router-dom';
import { PackAbout } from './PackAbout/PackAbout';
import { RouteBuilder } from 'utils/RouteBuilder';
import { PackReverter } from './PackReverter/PackReverter';
import axios from 'axios';
import update from 'immutability-helper';
import libraryService from 'services/LibraryService';
import './Packs.less';

export type PacksProps = RouteComponentProps & {}

export type PacksState = {
    status: StatusType,
    headers: PackHeader[],
    showInfo: boolean,
    isReverting?: boolean,
    deployType?: DeployType,
    packHeader?: PackHeader,
    accessType: AccessType
}

export class Packs extends PureComponent<PacksProps, PacksState> {

    private colorMap = CoreUtils.toColormap(CoreUtils.enumToKeys(PackType));
    static contextType = EntityContext;

    constructor(props: PacksProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            headers: [],
            showInfo: false,
            isReverting: false,
            accessType: AccessType.GROUP
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
        this.handlePackInfo = this.handlePackInfo.bind(this);
        this.handleInfoClose = this.handleInfoClose.bind(this);
        this.handlePackRelease = this.handlePackRelease.bind(this);
        this.handlePackPublish = this.handlePackPublish.bind(this);
        this.handlePackRevert = this.handlePackRevert.bind(this);
        this.handlePackDelete = this.handlePackDelete.bind(this);
        this.handleDeployDone = this.handleDeployDone.bind(this);
        this.handleDeployCancel = this.handleDeployCancel.bind(this);
        this.handleRevertDone = this.handleRevertDone.bind(this);
        this.handleRevertCancel = this.handleRevertCancel.bind(this);
        this.handleAccessChange = this.handleAccessChange.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleRefreshClick(): void {
        this.loadData();
    }

    private handleComponentEdit(packHeader: PackHeader): void {
        const url = RouteBuilder.edit(packHeader);
        this.props.history.push(url);
    }

    private handlePackInfo(packHeader: PackHeader): void {
        this.setState({
            packHeader: packHeader,
            showInfo: true
        });
    }

    private handleInfoClose(): void {
        this.setState({
            packHeader: undefined,
            showInfo: false
        });
    }

    private handlePackRelease(packHeader: PackHeader): void {
        this.setState({
            packHeader: packHeader,
            deployType: "environment"
        });
    }

    private handlePackPublish(packHeader: PackHeader): void {
        this.setState({
            packHeader: packHeader,
            deployType: "access"
        });
    }

    private handleDeployDone(packHeader: PackHeader): void {
        this.setState({
            packHeader: undefined,
            deployType: undefined
        });
    }

    private handleDeployCancel(): void {
        this.setState({
            packHeader: undefined,
            deployType: undefined
        });
    }

    private handlePackRevert(packHeader: PackHeader): void {
        this.setState({
            packHeader: packHeader,
            isReverting: true
        });
    }

    private handleRevertDone(packHeader: PackHeader): void {
        const index = this.state.headers.findIndex(header => header.id === packHeader.id);
        if (index !== -1) {
            const headers = update(this.state.headers, {
                [index]: { $set: packHeader }
            });
            this.setState({
                headers: headers,
                packHeader: undefined,
                isReverting: false
            });
        } else {
            this.setState({
                packHeader: undefined,
                isReverting: false
            });
        }
    }

    private handleRevertCancel(): void {
        this.setState({
            packHeader: undefined,
            isReverting: false
        });
    }

    private handlePackDelete(packHeader: PackHeader): Promise<any> {
        const request = {
            packId: packHeader.id,
            //accessType: AccessType.GROUP
        }
        return libraryService.deletePack(request,
            (response: any) => this.deletePackResponse(response),
            undefined, false
        );
    }

    private handleAccessChange(e: RadioChangeEvent): void {
        const accessType = e.target.value;
        this.setState({ accessType: accessType }, () => this.loadData());
    }

    private deletePackResponse(response: any): void {
        const packId = response.data.packId;
        const index = this.state.headers.findIndex(header => header.id === packId);
        if (index !== -1) {
            const headers = update(this.state.headers, {
                $splice: [[index, 1]]
            });
            this.setState({ headers: headers });
        }
    }

    private readPackHeadersRequest(): Promise<any> {
        const request = {
            accessType: this.state.accessType
        }
        return libraryService.readPackHeaders(request,
            (response: any) => this.readPackHeadersResponse(response),
            undefined, true
        );
    }

    private readPackHeadersResponse(response: any): void {
        const headers = response.data.headers;
        this.setState({ headers: headers });
    }

    private buildColumns(): ColumnsType<any> {
        const environmentType = this.context.user?.environmentType;
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            render: (header: PackHeader) => {
                if (environmentType === EnvironmentType.DEVELOPMENT) {
                    return <Link to={RouteBuilder.edit(header)}>{header.name}</Link>;
                } else {
                    return <span>{header.name}</span>;
                }
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'type',
            title: 'Type',
            align: 'center',
            width: Globals.TABLE_WIDTH_MEDIUM,
            render: (header: PackHeader) => (
                <Tag color={this.colorMap![header.packType]}>{CoreUtils.toProper(header.packType)}</Tag>
            ),
            sorter: (a, b) => CoreUtils.compareStrings(a.packType, b.packType),
            sortDirections: ['ascend', 'descend']
        }, {
            key: 'version',
            title: 'Version',
            align: 'center',
            width: Globals.TABLE_WIDTH_MEDIUM,
            render: (header: PackHeader) => (
                <span>{CoreUtils.toVersion(header.version)}</span>
            ),
            sorter: (a: any, b: any) => CoreUtils.compareNumbers(a.version, b.version),
            sortDirections: ['descend', 'ascend']
        }, {
            key: 'access',
            title: 'Access',
            align: 'center',
            width: Globals.TABLE_WIDTH_MEDIUM,
            render: (header: PackHeader) => (
                <span>{CoreUtils.toProper(header.accessType)}</span>
            ),
            sorter: (a, b) => CoreUtils.compareStrings(a.accessType, b.accessType),
            sortDirections: ['ascend', 'descend']
        }, {
            key: 'utime',
            title: 'Last Updated',
            align: 'center',
            width: Globals.TABLE_WIDTH_MEDIUM,
            render: (header: PackHeader) => (
                <span>{CoreUtils.toTime(header.updateTime)}</span>
            ),
            sorter: (a, b) => CoreUtils.compareNumbers(a.updateTime, b.updateTime),
            sortDirections: ['descend', 'ascend']
        }];
        return columns;
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readPackHeadersRequest());
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== StatusType.READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        const columns = this.buildColumns();
        const data = this.state.headers;
        const perms = PermissionsHelper.to(this.context.permissions);
        const environmentType = this.context.user?.environmentType;
        const actions = [{
            icon: <EditOutlined />,
            label: (header: PackHeader) => `Edit ${header.packType.toLowerCase()}`,
            hidden: () => environmentType !== EnvironmentType.DEVELOPMENT,
            callback: this.handleComponentEdit
        }, {
            icon: <BookOutlined />,
            label: "Publish package...",
            disabled: (header: PackHeader) => !PackType.isPublishable(header.packType) || this.state.accessType === AccessType.PUBLIC,
            callback: this.handlePackPublish
        }, {
            icon: <ExportOutlined />,
            label: "Release package...",
            //disabled: (header: PackHeader) => !PackType.isReleasable(header.packType) || this.state.accessType !== AccessType.GROUP || environmentType === EnvironmentType.DEVELOPMENT,
            disabled: (header: PackHeader) => !PackType.isReleasable(header.packType) || this.state.accessType !== AccessType.GROUP,
            callback: this.handlePackRelease
        }, {
            icon: <UndoOutlined />,
            label: "Rollback package...",
            callback: this.handlePackRevert
        }, {
            icon: <DeleteOutlined />,
            label: "Delete package",
            confirm: "Are you sure you want to delete the package?",
            callback: this.handlePackDelete
        }, {
            icon: <InfoCircleOutlined />,
            label: "Package information...",
            callback: this.handlePackInfo
        }];
        // Allow user to switch to organization or public if they have access.
        // When in the development environment, group access only (default).
        const extra = (
            <Spacer>
                {/* {(perms.get("access.organization") || perms.get("access.public")) && environmentType !== EnvironmentType.DEVELOPMENT && */}
                {(perms.get("access.organization") || perms.get("access.public")) &&
                    <Radio.Group
                        buttonStyle="solid"
                        value={this.state.accessType}
                        onChange={this.handleAccessChange}
                    >
                        {AccessType.array().filter(type => perms.get("access", type)).map(type => (
                            <Radio.Button value={type}>
                                {CoreUtils.toProper(type)}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                }
                <Button icon={<SyncOutlined />} onClick={this.handleRefreshClick}></Button>
            </Spacer>
        );
        return (
            <>
                <ItemTable
                    className="x-packs"
                    title="Packages"
                    status={this.state.status}
                    columns={columns}
                    items={data}
                    extra={extra}
                    actions={actions}
                    onLoad={this.handleRetryLoad}
                />
                {this.state.packHeader && this.state.deployType &&
                    <PackDeployer
                        deployType={this.state.deployType}
                        packHeader={this.state.packHeader}
                        onCancel={this.handleDeployCancel}
                        onRelease={this.handleDeployDone}
                    />
                }
                {this.state.packHeader && this.state.isReverting &&
                    <PackReverter
                        packHeader={this.state.packHeader}
                        onCancel={this.handleRevertCancel}
                        onRevert={this.handleRevertDone}
                    />
                }
                {this.state.packHeader && this.state.showInfo &&
                    <PackAbout
                        packHeader={this.state.packHeader}
                        onClose={this.handleInfoClose}
                    />
                }
            </>
        );
    }

}
