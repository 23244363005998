import { PureComponent, ReactElement } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { HttpRequestHeader } from 'antd/lib/upload/interface';
import classNames from 'classnames';
import './FileUploader.less';

export type BeforeCallback = (file: any) => void;
export type ChangeCallback = (file: any) => void;

export type FileUploaderProps = typeof FileUploader.defaultProps & {
    className?: string,
    url: string,
    info?: string,
    data?: any,
    headers?: HttpRequestHeader,
    method?: "post" | "put" | "patch",
    message: string | ReactElement<any, any>,
    onBefore: BeforeCallback,
    onChange: ChangeCallback
}

export class FileUploader extends PureComponent<FileUploaderProps> {

    static defaultProps = {
        info: "Click or drag a file to this area.",
        method: "post"
    }

    constructor(props: FileUploaderProps) {
        super(props);
        this.handleBeforeUpload = this.handleBeforeUpload.bind(this);
    }

    private handleBeforeUpload(file: any): void {
        return this.props.onBefore(file);
    }

    public render(): ReactElement {
        return (
            <div className={classNames('x-fileuploader', this.props.className)}>
                <Upload.Dragger
                    className="x-fileuploader-uploader"
                    maxCount={1}
                    showUploadList={true}
                    data={this.props.data}
                    action={this.props.url}
                    headers={this.props.headers}
                    method={this.props.method}
                    progress={{
                        showInfo: true,
                        strokeWidth: 8,
                        format: (percent) => {
                            return `${parseFloat(percent!.toFixed(2))}%`
                        }
                    }}
                    beforeUpload={this.handleBeforeUpload}
                    onChange={this.props.onChange}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{this.props.info}</p>
                    <p className="ant-upload-hint">
                        {this.props.message}
                    </p>
                </Upload.Dragger>
            </div>
        );
    }
}
