import React, { Component, ReactElement } from 'react';
import { DeleteOutlined, EnvironmentOutlined, ExclamationCircleOutlined, HomeOutlined, InfoCircleOutlined, LockOutlined, LogoutOutlined, MailOutlined, MenuOutlined, UsergroupAddOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, Menu, message, Modal } from 'antd';
import { Image } from 'components/Image/Image';
import { RouteBuilder } from 'utils/RouteBuilder';
import { RouteComponentProps } from 'react-router-dom';
import { EntityContext } from 'context/EntityContext';
import { RestUtils } from 'utils/RestUtils';
import { InviteDialog } from './InviteDialog/InviteDialog';
import { Spacer } from 'components/Spacer/Spacer';
import { OrganizationDialog } from './OrganizationEditor/OrganizationEditor';
import { CoreUtils } from 'utils/CoreUtils';
import { EnvironmentDialog } from './EnvironmentDialog/EnvironmentDialog';
import { EnvironmentType } from '@methodset/commons-core-ts';
import authService from 'services/AuthService';
import entityService from 'services/EntityService';
import logo from 'images/methodset_blue_logo.png';
import gears from 'images/gears_32x32.png';
import update from 'immutability-helper';
import './NavBar.less';

export type NavBarProps = RouteComponentProps & {
    onToggleMenu: any
}

export type NavBarState = {
    modal: any,
    showInvite: boolean,
    showOrganization: boolean,
    showEnvironment: boolean
}

export class NavBar extends Component<NavBarProps, NavBarState> {

    static contextType = EntityContext;

    constructor(props: NavBarProps) {
        super(props);
        this.state = {
            modal: {},
            showInvite: false,
            showOrganization: false,
            showEnvironment: false
        }
        this.handleHomeClick = this.handleHomeClick.bind(this);
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.handleOrganizationClick = this.handleOrganizationClick.bind(this);
        this.handleOrganizationDone = this.handleOrganizationDone.bind(this);
        this.handleEnvironmentClick = this.handleEnvironmentClick.bind(this);
        this.handleEnvironmentDone = this.handleEnvironmentDone.bind(this);
        this.handleProfileClick = this.handleProfileClick.bind(this);
        this.handlePasswordClick = this.handlePasswordClick.bind(this);
        this.handleEmailClick = this.handleEmailClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleOkClick = this.handleOkClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleConfirmChange = this.handleConfirmChange.bind(this);
        this.handleInviteClick = this.handleInviteClick.bind(this);
        this.handleInviteClose = this.handleInviteClose.bind(this);
    }

    private handleHomeClick(): void {
        const isLoggedIn = this.context.user ? true : false;
        if (isLoggedIn) {
            this.props.history.push(RouteBuilder.CONSOLE);
        } else {
            this.props.history.push(RouteBuilder.HOME);
        }
    }

    private handleLogoutClick(): void {
        authService.logoutUser(
            () => this.handleLogout(),
            () => this.handleLogout()
        );
    }

    private handleLogout(): void {
        authService.clearUser();
        this.context.clearUser();
        this.props.history.push(RouteBuilder.HOME);
    }

    private handleOrganizationClick(): void {
        this.setState({ showOrganization: true });
    }

    private handleOrganizationDone(): void {
        this.setState({ showOrganization: false });
    }

    private handleEnvironmentClick(): void {
        this.setState({ showEnvironment: true });
    }

    private handleEnvironmentDone(): void {
        this.setState({ showEnvironment: false });
    }

    private handleProfileClick(): void {
        this.props.history.push(RouteBuilder.MAIN_EDIT_PROFILE);
    }

    private handlePasswordClick(): void {
        this.props.history.push(RouteBuilder.MAIN_CHANGE_PASSWORD);
        //this.props.history.push(RouteBuilder.user(this.props, RouteBuilder.USER_CHANGE_PASSWORD));
    }

    private handleEmailClick(): void {
        this.props.history.push(RouteBuilder.MAIN_CHANGE_EMAIL);
        //this.props.history.push(RouteBuilder.user(this.props, RouteBuilder.USER_CHANGE_EMAIL));
    }

    private handleDeleteClick(): void {
        const config = {
            title: "Delete Your Account",
            okText: "Delete Account",
            okButtonProps: {
                disabled: true
            },
            cancelText: "Cancel",
            closable: true,
            icon: (
                <ExclamationCircleOutlined className="x-navbar-confirm-icon" />
            ),
            content: (
                <div>
                    <div>
                        Are you sure you want to delete
                        your account? You will lose access
                        to all your data.
                    </div>
                    <div className="x-navbar-confirm-info">
                        Please enter the word "confirm" to
                        acknowledge that you want to delete
                        your account.
                    </div>
                    <Input
                        className="x-navbar-confirm-input"
                        value={this.state.modal.confirm}
                        onChange={this.handleConfirmChange}
                    />
                </div>
            ),
            onOk: this.handleOkClick,
            onCancel: this.handleCancelClick
        };
        const ref = Modal.confirm(config);
        const modal = update(this.state.modal, {
            ref: { $set: ref }
        });
        this.setState({ modal: modal });
    }

    private handleOkClick(): Promise<any> {
        if (this.state.modal.confirm !== 'confirm') {
            return Promise.reject();
        }
        const request = {};
        return entityService.deleteUser(request,
            (response: any) => this.deleteUserResponse(response),
            (response: any) => this.deleteUserException(response),
            true
        );
    }

    private handleCancelClick(): void {
        this.setState({ modal: {} });
    }

    private handleConfirmChange(e: any): void {
        const confirm = e.target.value;
        const disabled = confirm !== 'confirm';
        this.state.modal.ref.update({
            okButtonProps: {
                disabled: disabled
            }
        });
        const modal = update(this.state.modal, {
            confirm: { $set: confirm }
        });
        this.setState({ modal: modal });
    }

    private handleInviteClick(): void {
        this.setState({ showInvite: true });
    }

    private handleInviteClose(): void {
        this.setState({ showInvite: false });
    }

    private readGroupRequest(): Promise<any> {
        const user = this.context.user;
        const request = {
            groupId: user.groupId
        };
        return entityService.readGroup(request,
            (response: any) => this.readGroupResponse(response),
            (error: any) => this.readGroupException(error),
            true
        );
    }

    private readGroupResponse(response: any): void {
        const group = response.data.group;
        this.context.saveGroup(group);
    }

    private readGroupException(error: any): void {
        // TODO: need way to reload groups
        message.error("Failed to load groups.");
    }

    private deleteUserResponse(response: any): void {
        message.success("Your account has been deleted.");
        this.setState({ modal: {} });
        authService.clearUser();
        this.context.clearUser();
        this.props.history.push(RouteBuilder.HOME);
    }

    private deleteUserException(response: any): void {
        Modal.error({
            title: 'Delete Error',
            content: RestUtils.getErrorMessage(response),
        });
    }

    public componentDidMount(): void {
        this.readGroupRequest();
    }

    public render(): ReactElement {
        const user = this.context.user;
        const group = this.context.group;
        const userMenu = (
            <Menu>
                {user &&
                    <>
                        <Menu.Item key="user">
                            <span className="x-navbar-label">
                                Welcome {user.firstName} {user.lastName}
                            </span>
                        </Menu.Item>
                        <Menu.Divider />
                    </>
                }
                <Menu.Item
                    key="invite"
                    icon={<UsergroupAddOutlined />}
                    onClick={this.handleInviteClick}
                >
                    Invite user
                </Menu.Item>
                <Menu.Item
                    key="profile"
                    icon={<UserOutlined />}
                    onClick={this.handleProfileClick}
                >
                    Edit profile
                </Menu.Item>
                <Menu.Item
                    key="organization"
                    icon={<HomeOutlined />}
                    onClick={this.handleOrganizationClick}
                >
                    Edit organization
                </Menu.Item>
                <Menu.Item
                    key="environment"
                    icon={<EnvironmentOutlined />}
                    onClick={this.handleEnvironmentClick}
                >
                    Switch environment
                </Menu.Item>
                <Menu.Item
                    key="password"
                    icon={<LockOutlined />}
                    onClick={this.handlePasswordClick}
                >
                    Change password
                </Menu.Item>
                <Menu.Item
                    key="email"
                    icon={<MailOutlined />}
                    onClick={this.handleEmailClick}
                >
                    Change email
                </Menu.Item>
                <Menu.Item
                    key="delete"
                    icon={<DeleteOutlined />}
                    onClick={this.handleDeleteClick}
                >
                    Delete account
                </Menu.Item>
                <Menu.SubMenu
                    key="info"
                    icon={<InfoCircleOutlined />}
                    title="Account Info"
                >
                    <Menu.Item key="ids">
                        <>
                            <div className="x-navbar-label">Organization Id:</div>
                            <div className="x-navbar-id">{user?.organizationId}</div>
                            <div className="x-navbar-label">Group Id:</div>
                            <div className="x-navbar-id">{user?.groupId}</div>
                            <div className="x-navbar-label">User Id:</div>
                            <div className="x-navbar-id">{user?.id}</div>
                        </>
                    </Menu.Item>
                </Menu.SubMenu>
                {user &&
                    <React.Fragment>
                        <Menu.Divider />
                        <Menu.Item
                            key="logout"
                            icon={<LogoutOutlined />}
                            onClick={this.handleLogoutClick}
                        >
                            Logout
                        </Menu.Item>
                    </React.Fragment>
                }
            </Menu>
        );
        return (
            <div className="x-navbar">
                <div>
                    <Spacer size="md">
                        <Button
                            type="link"
                            icon={<MenuOutlined />}
                            onClick={this.props.onToggleMenu}
                        />
                        <span>
                            <Image src={gears} className="x-navbar-gears" alt="gears" />
                            <Image src={logo} className="x-navbar-logo" alt="logo" onClick={this.handleHomeClick} />
                        </span>

                    </Spacer>
                </div>
                <div>
                    <Spacer size="lg">
                        {group &&
                            <Spacer>
                                <span>{group.name}</span>
                                {user &&
                                    <span>({CoreUtils.toLower(EnvironmentType.abbreviation(user.environmentType))})</span>
                                }
                            </Spacer>
                        }
                        {user &&
                            <Dropdown overlay={userMenu} trigger={['click']}>
                                <Button className="x-navbar-profile" shape="round">
                                    <UserOutlined />
                                </Button>
                            </Dropdown>
                        }
                    </Spacer>
                </div>
                {this.state.showInvite &&
                    <InviteDialog onClose={this.handleInviteClose} />
                }
                {this.state.showOrganization &&
                    <OrganizationDialog onClose={this.handleOrganizationDone} />
                }
                {this.state.showEnvironment &&
                    <EnvironmentDialog onClose={this.handleEnvironmentDone} />
                }
            </div >
        );
    }

}
