import { ReactElement, Ref, useRef, useState } from 'react';
import { Form, FormInstance } from 'antd';
import { Person, PersonType } from '@methodset/entity-client-ts';
import { ListEditor } from '../ListEditor/ListEditor';
import { PersonEditor } from '../PersonEditor/PersonEditor';
import './ChildrenEditor.less';

export type ChangeCallback = (children: Person[]) => void;

export type ChildrenEditorProps = {
    formRef: React.RefObject<FormInstance>,
    withForm?: boolean,
    id: string,
    children?: Person[],
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    children: [] as Person[]
}

export const ChildrenEditor = (props: ChildrenEditorProps): ReactElement => {

    const [editChild, setEditChild] = useState<Person | undefined>();

    const handleChildChange = (child: Person): void => {
        setEditChild(child);
    }

    const handleChildEdit = (child: Person): void => {
        setEditChild(child);
    }

    const handleChildrenSave = (children: Person[]): void => {
        setEditChild(undefined);
        children.sort((a, b) => a.dateOfBirth < b.dateOfBirth ? 1 : -1);
        props.onChange(children);
    }

    const renderItem = (child: Person): ReactElement => {
        return (
            <div className="x-childreneditor-item">
                <div>{child.firstName} {child.lastName}</div>
                <div>{child.dateOfBirth}</div>
            </div>
        )
    }

    const buildView = (): ReactElement => {
        return (
            <ListEditor
                formRef={props.formRef}
                id={props.id}
                name="Child"
                items={props.children}
                editItem={editChild}
                render={renderItem}
                onEdit={(address) => handleChildEdit(address)}
                onSave={(addresses) => handleChildrenSave(addresses)}
            >
                <PersonEditor
                    formRef={props.formRef}
                    type={PersonType.CHILD}
                    id="child"
                    person={editChild}
                    onChange={(child) => handleChildChange(child)}
                />
            </ListEditor>
        )
    }

    return (
        <>
            {props.withForm &&
                <Form ref={props.formRef}>
                    {buildView()}
                </Form>
            }
            {!props.withForm &&
                buildView()
            }
        </>
    )

}

ChildrenEditor.defaultProps = defaultProps;