import React, { ChangeEvent, KeyboardEvent, PureComponent, ReactElement } from "react";
import { Button, Form, FormInstance, Input, message, Select } from "antd";
import { CalculatorError, Cell, CoreUtils, Formula, FormulaComposer, Range, Sheet } from '@methodset/calculator-ts';
import { Spec, SpecsEditor, SpecType } from "./PivotSpecs/SpecsEditor";
import { ActiveCell, ModelContext } from "context/ModelContext";
import { FormItem } from "components/FormItem/FormItem";
import { RowIndex, RowSpec } from "./PivotSpecs/RowEditor/RowEditor";
import { ValueIndex, ValueSpec } from "./PivotSpecs/ValueEditor/ValueEditor";
import { FilterIndex, FilterSpec } from "./PivotSpecs/FilterEditor/FilterEditor";
import { FormulaEditor } from "../../FormulaEditor/FormulaEditor";
import { KeyCode, KeyUtils } from "utils/KeyUtils";
import { ColumnIndex, ColumnSpec } from "./PivotSpecs/ColumnEditor/ColumnEditor";
import { Globals } from "constants/Globals";
import update from 'immutability-helper';
import './PivotEditor.less';

export type CloseCallback = () => void;

export interface PivotSpecs {
    sheetId?: string,
    rangeId?: string,
    rowSpecs: RowSpec[]
    columnSpecs: ColumnSpec[]
    valueSpecs: ValueSpec[]
    filterSpecs: FilterSpec[]
}

export type PivotEditorProps = {
    cell: ActiveCell,
    formulaEditor?: FormulaEditor,
    onClose: CloseCallback
}

export type PivotEditorState = {
    headers: any[],
    range?: Range,
    isEditing: boolean,
    pivotSpecs: PivotSpecs
}

export class PivotEditor extends PureComponent<PivotEditorProps, PivotEditorState> {

    static contextType = ModelContext;

    private formRef = React.createRef<FormInstance>();
    private inputRef = React.createRef<Input>();

    constructor(props: PivotEditorProps) {
        super(props);
        this.state = {
            headers: [],
            isEditing: false,
            pivotSpecs: this.emptySpecs()
        }
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleRangeSubmit = this.handleRangeSubmit.bind(this);
        this.handleSheetChange = this.handleSheetChange.bind(this);
        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.handleSpecChange = this.handleSpecChange.bind(this);
        this.handleRangeComplete = this.handleRangeComplete.bind(this);
    }

    private emptySpecs(): PivotSpecs {
        // Default to the current sheet.
        return {
            sheetId: this.props.cell.sheet.id,
            rowSpecs: [],
            columnSpecs: [],
            valueSpecs: [],
            filterSpecs: []
        };
    }

    private handleKeyDown(e: KeyboardEvent<HTMLInputElement>): void {
        if (KeyUtils.is(e, KeyCode.ENTER)) {
        //if (e.code === KeyCode.ENTER) {
            this.inputRef.current?.blur();
        }
    }

    private handleRangeSubmit(): void {
        this.formRef.current?.submit();
    }

    private handleSheetChange(sheetId: string): void {
        const pivotSpecs = update(this.state.pivotSpecs, {
            sheetId: { $set: sheetId }
        });
        this.setState({ pivotSpecs: pivotSpecs }, () => {
            if (this.state.pivotSpecs.rangeId) {
                this.handleRangeSubmit();
            }
        });
    }

    private handleRangeChange(e: ChangeEvent<HTMLInputElement>): void {
        const rangeId = e.target.value;
        const pivotSpecs = update(this.state.pivotSpecs, {
            rangeId: { $set: rangeId }
        });
        this.setState({ pivotSpecs: pivotSpecs });
    }

    private handleSpecChange(type: SpecType, specs: Spec[]): void {
        let pivotSpecs: PivotSpecs;
        if (type === SpecType.ROWS) {
            pivotSpecs = update(this.state.pivotSpecs, {
                rowSpecs: { $set: specs as RowSpec[] }
            });
        } else if (type === SpecType.COLUMNS) {
            pivotSpecs = update(this.state.pivotSpecs, {
                columnSpecs: { $set: specs as ColumnSpec[] }
            });
        } else if (type === SpecType.VALUES) {
            pivotSpecs = update(this.state.pivotSpecs, {
                valueSpecs: { $set: specs as ValueSpec[] }
            });
        } else if (type === SpecType.FILTERS) {
            pivotSpecs = update(this.state.pivotSpecs, {
                filterSpecs: { $set: specs as FilterSpec[] }
            });
        } else {
            return;
        }
        this.updateSpecs(pivotSpecs);
    }

    private handleRangeComplete(): void {
        this.updateSpecs(this.state.pivotSpecs, false);
    }

    private updateSpecs(pivotSpecs: PivotSpecs, updateState: boolean = true): void {
        if (updateState) {
            this.setState({ pivotSpecs: pivotSpecs });
        }
        const sheet = this.props.cell.sheet;
        const cell = sheet.getCell(this.props.cell.id);
        const formula = this.buildFormula(cell, this.state.range, pivotSpecs)
        cell.formula = formula;
    }

    private initSpecs(): void {
        const sheet = this.props.cell.sheet;
        const cell = sheet.getCell(this.props.cell.id, false);
        if (!cell) {
            return;
        }
        if (!cell.formula) {
            return;
        }
        const calculator = this.context.calculator;
        try {
            const formula = calculator.executor.formulaDecomposer.parseFormula(cell, cell.formula);
            if (!formula.matches("PIVOT")) {
                return;
            }
            const range = formula.args[0];
            const headers = range ? range.headers() : [];
            const rowSpecs = formula.args[1];
            const columnSpecs = formula.args[2];
            const valueSpecs = formula.args[3];
            const filterSpecs = formula.args[4];

            this.validateSpecs("Row", rowSpecs, 4);
            this.validateSpecs("Column", columnSpecs, 3);
            this.validateSpecs("Value", valueSpecs, 2);
            this.validateSpecs("Filter", filterSpecs, 2);

            const specs: PivotSpecs = {
                sheetId: range?.sheet.id,
                rangeId: range?.id,
                rowSpecs: rowSpecs,
                columnSpecs: columnSpecs,
                valueSpecs: valueSpecs,
                filterSpecs: filterSpecs
            };
            this.setState({
                pivotSpecs: specs,
                headers: headers,
                range: range
            });
        } catch (e) {
            const text = e instanceof Error ? e.message : e;
            message.error(`Error parsing pivot formula: ${text}`);
        }
    }

    private validateSpecs(name: string, specs: Spec[], length: number): void {
        if (!Array.isArray(specs)) {
            throw new Error(`${name} specs is not an array of spec.`);
        }
        for (let i = 0; i < specs.length; i++) {
            const spec = specs[i];
            if (!Array.isArray(spec)) {
                throw new Error(`${name} spec ${i + 1} is not an array.`);
            }
            if (spec.length !== length) {
                throw new Error(`${name} spec ${i + 1} is not of length ${length}.`);
            }
        }
    }

    private buildFormula(cell: Cell, range: Range | undefined, specs: PivotSpecs): string | undefined {
        if (!range) {
            return undefined;
        }
        const args: any[] = [range];

        const rowSpecs = this.buildRowSpecs(specs.rowSpecs)
        const columnSpecs = this.buildColumnSpecs(specs.columnSpecs)
        const valueSpecs = this.buildValueSpecs(specs.valueSpecs)
        const filterSpecs = this.buildFilterSpecs(specs.filterSpecs)

        args.push(rowSpecs);
        args.push(columnSpecs);
        args.push(valueSpecs);
        args.push(filterSpecs);

        const formula = new Formula("PIVOT", args);
        const calculator = this.context.calculator;
        const composer = new FormulaComposer(calculator);
        return composer.buildFormula(cell, formula);
    }

    private buildRowSpecs(specs: RowSpec[]): any[] {
        const args: any[] = [];
        if (specs.length === 0) {
            return args;
        }
        specs.forEach((spec) => {
            args.push([spec[RowIndex.COLUMN_INDEX], spec[RowIndex.SORT_ORDER], spec[RowIndex.SHOW_TOTALS], spec[RowIndex.REPEAT_LABELS]]);
        });
        return args;
    }

    private buildColumnSpecs(specs: ColumnSpec[]): any[] {
        const args: any[] = [];
        if (specs.length === 0) {
            return args;
        }
        specs.forEach((spec) => {
            args.push([spec[ColumnIndex.COLUMN_INDEX], spec[ColumnIndex.SORT_ORDER], spec[ColumnIndex.SHOW_TOTALS]]);
        });
        return args;
    }

    private buildValueSpecs(specs: ValueSpec[]): any[] {
        const args: any[] = [];
        if (specs.length === 0) {
            return args;
        }
        specs.forEach((spec) => {
            args.push([spec[ValueIndex.COLUMN_INDEX], spec[ValueIndex.FUNCTION]]);
        });
        return args;
    }

    private buildFilterSpecs(specs: FilterSpec[]): any[] {
        const args: any[] = [];
        if (specs.length === 0) {
            return args;
        }
        specs.forEach((spec) => {
            args.push([spec[FilterIndex.COLUMN_INDEX], CoreUtils.toList(spec[FilterIndex.VALUES])]);
        });
        return args;
    }

    public componentDidMount(): void {
        this.initSpecs();
    }

    public render(): ReactElement {
        const calculator = this.context.calculator;
        return (
            <div>
                <div className="x-pivoteditor-specs">
                    <Form ref={this.formRef} onFinish={this.handleRangeComplete}>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            label="Sheet"
                            name="sheet"
                            rules={[{
                                required: true,
                                message: 'Please select a sheet that contains the range.'
                            }]}
                        >
                            <Select
                                placeholder="Select a sheet."
                                value={this.state.pivotSpecs.sheetId}
                                //defaultValue={this.props.cell.sheet.id}
                                onChange={this.handleSheetChange}
                            >
                                {calculator.sheets.map((sheet: Sheet) => (
                                    <Select.Option key={sheet.id} value={sheet.name}>{sheet.name}</Select.Option>
                                ))}
                            </Select>
                        </FormItem>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            label="Range"
                            name="range"
                            required={true}
                            rules={[{
                                validator: (rule: any, rangeId: string) => {
                                    try {
                                        const calculator = this.context.calculator;
                                        const sheet = calculator.sheets.get(this.state.pivotSpecs.sheetId);
                                        const range = Range.fromId(sheet, rangeId);
                                        const headers = range.headers();
                                        this.setState({
                                            headers: headers,
                                            range: range
                                        });
                                        return Promise.resolve();
                                    } catch (e) {
                                        let message;
                                        if (e instanceof CalculatorError) {
                                            message = e.message;
                                        } else {
                                            message = "Invalid range.";
                                        }
                                        this.setState({
                                            headers: [],
                                            range: undefined
                                        });
                                        return Promise.reject(message);
                                    }
                                }
                            }]}
                        >
                            <Input
                                id="range"
                                ref={this.inputRef}
                                placeholder="Please enter a range."
                                autoComplete="off"
                                value={this.state.pivotSpecs.rangeId}
                                onBlur={this.handleRangeSubmit}
                                onKeyDown={this.handleKeyDown}
                                onChange={this.handleRangeChange} />
                        </FormItem>
                    </Form>
                    <SpecsEditor
                        headers={this.state.headers}
                        type={SpecType.ROWS}
                        range={this.state.range}
                        specs={this.state.pivotSpecs.rowSpecs}
                        onChange={this.handleSpecChange}
                    />
                    <SpecsEditor
                        headers={this.state.headers}
                        type={SpecType.COLUMNS}
                        range={this.state.range}
                        specs={this.state.pivotSpecs.columnSpecs}
                        onChange={this.handleSpecChange}
                    />
                    <SpecsEditor
                        headers={this.state.headers}
                        type={SpecType.VALUES}
                        range={this.state.range}
                        specs={this.state.pivotSpecs.valueSpecs}
                        onChange={this.handleSpecChange}
                    />
                    <SpecsEditor
                        headers={this.state.headers}
                        type={SpecType.FILTERS}
                        range={this.state.range}
                        specs={this.state.pivotSpecs.filterSpecs}
                        onChange={this.handleSpecChange}
                    />
                </div>
                <div className="x-pivoteditor-close">
                    <Button type="primary" onClick={this.props.onClose}>
                        Close
                    </Button>
                </div>
            </div>
        )
    }

}
