import { ChangeEvent, PureComponent, ReactElement } from 'react';
import { FormInstance, Input } from 'antd';
import { Spacer } from 'components/Spacer/Spacer';
import { Tuple } from '@methodset/commons-core-ts';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import classNames from 'classnames';
import update from 'immutability-helper';
import './TupleEditor.less';

export type ChangeCallback = (tuple: Tuple<string, string>) => void;

export type TupleEditorProps = typeof TupleEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    className?: string,
    tuple?: Tuple<string, string>,
    index: number,
    onChange: ChangeCallback
}

export class TupleEditor extends PureComponent<TupleEditorProps> {

    static defaultProps = {
        tuple: {
            key: undefined as any,
            value: undefined as any
        } as Tuple<string, string>
    }

    constructor(props: TupleEditorProps) {
        super(props);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
    }

    private handleNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const name = e.target.value;
        const tuple = update(this.props.tuple, {
            key: { $set: name }
        });
        this.props.onChange(tuple);
    }

    private handleValueChange(e: ChangeEvent<HTMLInputElement>): void {
        const value = e.target.value;
        const tuple = update(this.props.tuple, {
            value: { $set: value }
        });
        this.props.onChange(tuple);
    }

    public render(): ReactElement {
        return (
            <div className={classNames('x-tupleeditor', this.props.className)}>
                <Spacer>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        name={`tuple-name-${this.props.index}`}
                        noStyle={true}
                        rules={[{
                            required: true,
                            message: "Enter a name."
                        }]}
                    >
                        <Input
                            value={this.props.tuple.key}
                            placeholder="Name."
                            onChange={this.handleNameChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        name={`tuple-value-${this.props.index}`}
                        noStyle={true}
                        rules={[{
                            required: true,
                            message: "Enter a value."
                        }]}
                    >
                        <Input
                            value={this.props.tuple.value}
                            placeholder="Value."
                            onChange={this.handleValueChange}
                        />
                    </FormItem>
                </Spacer>
            </div>
        );
    }

}
