import { ReactElement } from 'react';
import { Tutorial } from './Tutorial/Tutorial';
import './Tutorials.less';
import { Spacer } from 'components/Spacer/Spacer';

export interface TutorialItem {
    title: string,
    description: string,
    url: string,
    duration?: string,
    date?: string
}

export type TutorialsProps = {
    component: string,
    items: TutorialItem[]
}

export const Tutorials = (props: TutorialsProps): ReactElement => {

    return (
        <div className="x-tutorials">
            <div className="x-tutorials-name">{props.component}</div>
            <Spacer direction="vertical" size="xl">
                {props.items.map((item, index) => (
                    <Tutorial
                        key={index}
                        title={item.title}
                        description={item.description}
                        url={item.url}
                        duration={item.duration}
                        date={item.date}
                    />
                ))}
            </Spacer>
        </div>
    );

}

