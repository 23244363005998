import { ReactElement } from 'react';
import { NumberInput, NumberInputProps } from 'components/NumberInput/NumberInput';
import { Alignment } from 'components/Spacer/Spacer';
import { Justification } from 'components/FilterInput/FilterInput';
import './DecimalInput.less';

export type DecimalInputProps = Omit<NumberInputProps, "tester" | "converter"> & {
    alignment?: Alignment,
    justification?: Justification,
} & typeof defaultProps;

const defaultProps = {
    alignment: "middle",
    justification: "right"
}

export const DecimalInput = (props: DecimalInputProps): ReactElement => {

    const tester = (value: string) => /^(-)?[0-9]*(\.)?[0-9]*$/.test(value);
    const converter = (value: string) => parseFloat(value);

    return (
        <NumberInput
            {...props}
            tester={tester}
            converter={converter}
        />
    );

}

DecimalInput.defaultProps = defaultProps;
