import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, FormInstance, Input } from 'antd';
import { FormFrame } from 'components/FormFrame/FormFrame';
import { FormItem } from 'components/FormItem/FormItem';
import { RouteBuilder } from 'utils/RouteBuilder';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { EntityContext } from 'context/EntityContext';
import { RestUtils } from 'utils/RestUtils';
import authService from 'services/AuthService';
import entityService from 'services/EntityService';
import update from 'immutability-helper';
import './Login.less';

interface NewPasswordData {
    cognitoUser: CognitoUser,
    userAttributes: any,
    requiredAttributes: any
};

interface FormData {
    emailAddress?: string,
    password?: string,
    newPassword?: string,
    confirmPassword?: string
}

export type LoginProps = RouteComponentProps & {
    className?: string
}

export type LoginState = {
    isSubmitting: boolean,
    error?: Error,
    formData: FormData,
    newPasswordData?: NewPasswordData
}

export class Login extends PureComponent<LoginProps, LoginState> {

    static contextType = EntityContext;

    private formRef = React.createRef<FormInstance>();

    constructor(props: LoginProps) {
        super(props);
        this.state = {
            isSubmitting: false,
            error: undefined,
            formData: {},
            newPasswordData: undefined
        };
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
        this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
        this.handleNewPasswordSubmit = this.handleNewPasswordSubmit.bind(this);
    }

    private handleEmailChange(e: ChangeEvent<HTMLInputElement>): void {
        const emailAddress = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                emailAddress: { $set: emailAddress }
            })
        });
    }

    private handlePasswordChange(e: ChangeEvent<HTMLInputElement>): void {
        const password = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                password: { $set: password }
            })
        });
    }

    private handleNewPasswordChange(e: ChangeEvent<HTMLInputElement>): void {
        const newPassword = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                newPassword: { $set: newPassword }
            })
        });
    }

    private handleConfirmPasswordChange(e: ChangeEvent<HTMLInputElement>): void {
        const confirmPassword = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                confirmPassword: { $set: confirmPassword }
            })
        });
    }

    private handleLoginSubmit(): void {
        const username = this.state.formData.emailAddress;
        const password = this.state.formData.password;
        if (username && password) {
            this.loginUserRequest(username, password);
        }
    }

    private handleNewPasswordSubmit(): void {
        const newPassword = this.state.formData.newPassword;
        if (newPassword) {
            this.newPasswordRequest(newPassword);
        }
    }

    private loginUserRequest(username: string, password: string): void {
        this.setState({
            error: undefined,
            isSubmitting: true
        });
        this.context.clearUser();
        authService.clearUser();
        authService.loginUser(username, password,
            (response: any) => this.loginUserResponse(response),
            (error: any) => this.loginUserException(error),
            (cognitoUser: CognitoUser, userAttributes: any, requiredAttributes: any) => this.requiresNewPassword(cognitoUser, userAttributes, requiredAttributes)
        );
    }

    private loginUserResponse(response: CognitoUserSession): void {
        authService.readUser().then(user => {
            this.context.saveUser(user);
            this.readPermissionsRequest();
            //this.props.history.push(RouteBuilder.CONSOLE);
        }).catch(e => {
            this.context.clearUser();
            this.props.history.push(RouteBuilder.USER_LOGIN);
            //this.props.history.push(RouteBuilder.user(this.props, RouteBuilder.USER_LOGIN));
        });
    }

    private loginUserException(error: any): void {
        if (error.code === "PasswordResetRequiredException") {
            error.message = "You must change your password. Please reset it now."
        } else if (error.code === "NotAuthorizedException") {
            error.message = "Invalid login credentials."
        }
        this.setState({
            error: RestUtils.getError(error),
            isSubmitting: false
        });
    }

    private readPermissionsRequest(): Promise<any> {
        const request = {};
        return entityService.readPermissions(request,
            (response: any) => this.readPermissionsResponse(response),
            (response: any) => this.readPermissionsException(response),
            true
        );
    }

    private readPermissionsResponse(response: any): void {
        const permissions = response.data.permissions;
        this.context.savePermissions(permissions);
        this.props.history.push(RouteBuilder.CONSOLE);
    }

    private readPermissionsException(response: any): void {
        this.setState({
            error: RestUtils.getError(response),
            isSubmitting: false
        });
    }

    private requiresNewPassword(cognitoUser: CognitoUser, userAttributes: any, requiredAttributes: any): void {
        const newPasswordData = {
            cognitoUser: cognitoUser,
            userAttributes: userAttributes,
            requiredAttributes: requiredAttributes
        };
        this.setState({
            newPasswordData: newPasswordData,
            error: undefined,
            isSubmitting: false
        });
    }

    private newPasswordRequest(newPassword: string): void {
        this.setState({
            error: undefined,
            isSubmitting: true
        });
        const cognitoUser = this.state.newPasswordData!.cognitoUser;
        const userAttributes = this.state.newPasswordData!.userAttributes;
        authService.newPassword(cognitoUser, newPassword, userAttributes,
            (response: any) => this.newPasswordResponse(response),
            (error: any) => this.newPasswordException(error)
        );
    }

    private newPasswordResponse(response: any): void {
        this.setState({
            error: undefined,
            newPasswordData: undefined,
            isSubmitting: false
        });
        this.props.history.push(RouteBuilder.CONSOLE);
    }

    private newPasswordException(error: Error): void {
        this.setState({
            error: error,
            isSubmitting: false
        });
    }

    // private invitationToken(): string | null {
    //     const query = this.props.location.search;
    //     const params = new URLSearchParams(query);
    //     return params.get("token");
    // }

    private buildNewPasswordForm(): ReactElement {
        // This form cannot be a separate page because the cognito user needs to be
        // stored for the authentication challenge.
        return (
            <FormFrame
                ref={this.formRef}
                title="New Password"
                error={this.state.error}
                hideFooter={true}
                onOk={this.handleNewPasswordSubmit}
            >
                <div className="x-login-instruct">You are required to change your password. Please enter a new password below.</div>
                <FormItem
                    formRef={this.formRef}
                    name="new-password"
                    rules={[{
                        required: true,
                        message: 'Please enter a new password.'
                    }, {
                        validator: (rule: any, value: any) => {
                            const message = authService.validatePassword(this.state.formData.newPassword);
                            return message ? Promise.reject(message) : Promise.resolve();
                        }
                    }]}
                >
                    <Input.Password
                        id="new-password"
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="New password."
                        size="large"
                        value={this.state.formData.newPassword}
                        onChange={this.handleNewPasswordChange}
                    />
                </FormItem>
                <FormItem
                    formRef={this.formRef}
                    name="confirm"
                    rules={[{
                        required: true,
                        message: 'Please enter the password again.'
                    },
                    ({ getFieldValue }: any) => ({
                        validator(rule: any, value: any) {
                            if (value && value !== getFieldValue('new-password')) {
                                return Promise.reject('The confirm password does not match.');
                            } else {
                                return Promise.resolve();
                            }
                        }
                    })
                    ]}
                >
                    <Input.Password
                        id="confirm"
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Confirm password."
                        size="large"
                        value={this.state.formData.confirmPassword}
                        onChange={this.handleConfirmPasswordChange}
                    />
                </FormItem>
                <Button
                    className="x-login-submit-new"
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={this.state.isSubmitting}
                >
                    Submit
                </Button>
            </FormFrame>
        );
    }

    private buildLoginForm(): ReactElement {
        return (
            <FormFrame
                ref={this.formRef}
                title="Login"
                error={this.state.error}
                hideFooter={true}
                onOk={this.handleLoginSubmit}
            >
                <FormItem
                    //{...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    name="email"
                    //label="Email"
                    noLabel={true}
                    rules={[{
                        required: true,
                        message: 'Please enter your email.'
                    }, {
                        type: 'email',
                        message: 'The email address is invalid.'
                    }]}
                >
                    <Input
                        id="email"
                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Email address."
                        size="large"
                        value={this.state.formData.emailAddress}
                        onChange={this.handleEmailChange}
                    />
                </FormItem>
                <FormItem
                    //{...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    name="password"
                    //label="Password"
                    noLabel={true}
                    rules={[{
                        required: true,
                        message: 'Please enter a password.'
                    }]}
                >
                    <Input.Password
                        id="password"
                        autoComplete="current-password"
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Password."
                        size="large"
                        value={this.state.formData.password}
                        onChange={this.handlePasswordChange}
                    />
                </FormItem>
                <div className="x-login-footer">
                    <span className="x-login-question">Forgot your password?</span>
                    <Link to={RouteBuilder.USER_RESET_PASSWORD}>Reset it here.</Link>
                    {/* <Link to={RouteBuilder.user(this.props, RouteBuilder.USER_RESET_PASSWORD)}>Reset it here.</Link> */}
                    <Button
                        className="x-login-submit"
                        type="primary"
                        size="large"
                        htmlType="submit"
                        loading={this.state.isSubmitting}
                    >
                        Login
                    </Button>
                    <span className="x-login-question">Don't have an account?</span>
                    <Link to={RouteBuilder.USER_REGISTER}>Register here.</Link>
                    {/* <Link to={RouteBuilder.user(this.props, RouteBuilder.USER_REGISTER)}>Register here.</Link> */}
                </div>
            </FormFrame>
        );
    }

    public render(): ReactElement {
        let view;
        if (this.state.newPasswordData) {
            view = this.buildNewPasswordForm();
        } else {
            view = this.buildLoginForm();
        }
        return (
            view
        );
    }

}
