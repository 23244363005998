import { FC, ReactElement } from "react"
import { Checkbox, Select } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Label } from "components/Label/Label";
import { SpecContent } from "../SpecContent/SpecContent";
import update from 'immutability-helper';
import './RowEditor.less';

export enum RowIndex {
    COLUMN_INDEX = 0,
    SORT_ORDER = 1,
    SHOW_TOTALS = 2,
    REPEAT_LABELS = 3,
}

export enum SortOrder {
    ASCENDING = "asc",
    DESCENDING = "desc"
}

export type RowSpec = [ number, SortOrder, boolean, boolean ];
export type ChangeCallback = (spec: RowSpec) => void;
export type DeleteCallback = (spec: RowSpec) => void;

type Props = {
    header: any,
    spec: RowSpec
    isLeaf: boolean,
    onChange: ChangeCallback,
    onDelete: DeleteCallback
}

export const RowEditor: FC<Props> = (props: Props): ReactElement => {

    const handleTotalsToggle = (e: CheckboxChangeEvent): void => {
        const checked = e.target.checked;
        const spec = update(props.spec, {
            [RowIndex.SHOW_TOTALS]: { $set: checked }
        });
        props.onChange(spec);
    }

    const handleRepeatToggle = (e: CheckboxChangeEvent): void => {
        const checked = e.target.checked;
        const spec = update(props.spec, {
            [RowIndex.REPEAT_LABELS]: { $set: checked }
        });
        props.onChange(spec);
    }

    const handleOrderChange = (sortOrder: SortOrder): void => {
        const spec = update(props.spec, {
            [RowIndex.SORT_ORDER]: { $set: sortOrder }
        });
        props.onChange(spec);
    }

    const handleSpecDelete = (): void => {
        props.onDelete(props.spec);
    }

    return (
        <SpecContent
            header={props.header}
            onDelete={handleSpecDelete}
        >
            <div>
                <Checkbox
                    key="totals"
                    checked={props.spec[RowIndex.SHOW_TOTALS]}
                    onChange={handleTotalsToggle}
                >
                    Show totals
                </Checkbox>
            </div>
            {!props.isLeaf &&
                <div>
                    <Checkbox
                        key="repeat"
                        checked={props.spec[RowIndex.REPEAT_LABELS]}
                        onChange={handleRepeatToggle}
                    >
                        Repeat row labels
                    </Checkbox>
                </div>
            }
            <div>
                <Label
                    label="Order"
                    placement="left"
                    bold={false}
                >
                    <Select
                        className="x-roweditor-order"
                        value={props.spec[RowIndex.SORT_ORDER]}
                        onChange={handleOrderChange}
                    >
                        <Select.Option key="asc" value="asc">Ascending</Select.Option>
                        <Select.Option key="desc" value="desc">Descending</Select.Option>
                    </Select>

                </Label>
            </div>
        </SpecContent>
    )

}
