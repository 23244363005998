import { PureComponent, ReactElement } from 'react';
import { Button } from 'antd';
import { OptionEditor } from './OptionEditor/OptionEditor';
import { ItemSpec } from 'components/MenuButton/MenuButton';
import { DeleteOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Spacer } from 'components/Spacer/Spacer';
import { Option } from '@methodset/calculator-ts';
import update from 'immutability-helper';
import './Options.less';

export type ChangeCallback = (options: Option[]) => void;

export type OptionsProps = typeof Options.defaultProps & {
    options: Option[],
    onChange: ChangeCallback
}

export class Options extends PureComponent<OptionsProps> {

    static defaultProps = {
        options: [] as Option[]
    }

    constructor(props: OptionsProps) {
        super(props);
        this.handleOptionAdd = this.handleOptionAdd.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
    }

    private handleOptionAdd(): void {
        const option = {} as Option;
        const options = update(this.props.options, {
            $push: [option]
        });
        this.props.onChange(options);
    }

    private handleOptionRemove(option: Option, index: number): void {
        const options = update(this.props.options, {
            $splice: [[index, 1]]
        });
        this.props.onChange(options);
    }

    private handleOptionChange(option: Option, index: number): void {
        const options = update(this.props.options, {
            [index]: { $set: option }
        });
        this.props.onChange(options);
    }

    private handleOptionMoveUp(option: Option, index: number): void {
        const options = update(this.props.options, {
            $splice: [[index, 1], [index - 1, 0, option]]
        });
        this.props.onChange(options);
    }

    private handleOptionMoveDown(option: Option, index: number): void {
        const options = update(this.props.options, {
            $splice: [[index, 1], [index + 1, 0, option]]
        });
        this.props.onChange(options);
    }

    private buildItems(index: number): ItemSpec[] {
        const items: ItemSpec[] = [{
            icon: <DeleteOutlined />,
            label: "Delete option",
            onSelect: (option: Option) => this.handleOptionRemove(option, index)
        }, {
            icon: <UpOutlined />,
            label: "Move up",
            disabled: () => index === 0,
            onSelect: (option: Option) => this.handleOptionMoveUp(option, index)
        }, {
            icon: <DownOutlined />,
            label: "Move down",
            disabled: () => index === this.props.options.length - 1,
            onSelect: (option: Option) => this.handleOptionMoveDown(option, index)
        }];
        return items;
    }

    public render(): ReactElement {
        return (
            <Spacer className="x-options" direction="vertical">
                {this.props.options.map((option, index) => (
                    <OptionEditor
                        key={index}
                        index={index}
                        items={this.buildItems(index)}
                        option={option}
                        onChange={this.handleOptionChange}
                    />
                ))}
                <div className="x-options-add">
                    <Button onClick={this.handleOptionAdd}>Add Option</Button>
                </div>
            </Spacer>
        );
    }
}
