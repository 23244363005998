import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Form, FormInstance, Input, Modal, Select } from 'antd';
import { ApiCredentialsEditor } from './ApiCredentialsEditor/ApiCredentialsEditor';
import { FormItem } from 'components/FormItem/FormItem';
import { WebCredentialsEditor } from './WebCredentialsEditor/WebCredentialsEditor';
import { Globals } from 'constants/Globals';
import { ApiCredentials, Authentication, AuthenticationHeader, Credentials, CredentialsType, Provider, WebCredentials } from '@methodset/endpoint-client-ts';
import { RestUtils } from 'utils/RestUtils';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { StatusType } from 'constants/StatusType';
import { QueryAdmission } from '@methodset/endpoint-client-ts';
import endpointService from 'services/EndpointService';
import axios from 'axios';
import update from 'immutability-helper';
import './AuthenticationItem.less';

export type ChangeCallback = (header: AuthenticationHeader) => void;
export type CancelCallback = () => void;

export type AuthenticationItemProps = {
    providers: Provider[],
    authenticationId?: string,
    admission?: QueryAdmission,
    onChange: ChangeCallback,
    onCancel: CancelCallback
}

export type AuthenticationItemState = {
    status: StatusType,
    authentication: Authentication,
    error?: string,
    loading: boolean,
    isSubmitting: boolean
}

export class AuthenticationItem extends PureComponent<AuthenticationItemProps, AuthenticationItemState> {

    private formRef = React.createRef<FormInstance>();

    constructor(props: AuthenticationItemProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            authentication: {} as Authentication,
            error: undefined,
            loading: false,
            isSubmitting: false
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleProviderChange = this.handleProviderChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleCredentialsChange = this.handleCredentialsChange.bind(this);
        if (this.props.admission) {
            const providerId = this.props.admission.providerId;
            const credentialsType = this.props.admission.credentialsType;
            this.state.authentication.providerId = providerId;
            this.state.authentication.credentialsType = credentialsType;
            if (providerId !== Provider.CUSTOM) {
                const provider = props.providers.find(provider => provider.id === providerId);
                this.state.authentication.name = this.buildName(providerId, credentialsType);
                this.state.authentication.description = provider?.description;
            }
        }
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleSave(): void {
        if (!this.state.isSubmitting) {
            this.saveAuthentication(this.state.authentication);
        }
    }

    private handleCancel(): void {
        this.props.onCancel();
    }

    private handleNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const name = e.target.value;
        const authentication = update(this.state.authentication, {
            name: { $set: name }
        });
        this.setState({ authentication: authentication });
    }

    private handleDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        const description = e.target.value;
        const authentication = update(this.state.authentication, {
            description: { $set: description }
        });
        this.setState({ authentication: authentication });
    }

    private handleProviderChange(providerId: string): void {
        let name = this.state.authentication.name;
        if (providerId !== Provider.CUSTOM && this.state.authentication.credentialsType) {
            name = this.buildName(providerId, this.state.authentication.credentialsType);
        } else {
            name = undefined as any;
        }
        const authentication = update(this.state.authentication, {
            providerId: { $set: providerId },
            name: { $set: name },
            description: { $set: undefined }
        });
        this.setState({ authentication: authentication });
    }

    private handleTypeChange(credentialsType: CredentialsType): void {
        let name = this.state.authentication.name;
        if (this.state.authentication.providerId && this.state.authentication.providerId !== Provider.CUSTOM) {
            name = this.buildName(this.state.authentication.providerId, credentialsType);
        }
        const authentication = update(this.state.authentication, {
            credentialsType: { $set: credentialsType },
            credentials: { $set: undefined as any },
            name: { $set: name }
        });
        this.setState({ authentication: authentication });
    }

    private buildName(providerId: string, credentialsType: CredentialsType): string {
        const provider = this.props.providers.find(provider => provider.id === providerId);
        return `${provider?.name} ${CredentialsType.name(credentialsType)}`;
    }

    private handleCredentialsChange(credentials: Credentials): void {
        const authentication = update(this.state.authentication, {
            credentials: { $set: credentials }
        })
        this.setState({ authentication: authentication });
    }

    private saveAuthentication(authentication: Authentication): void {
        if (authentication.id) {
            this.updateAuthenticationRequest();
        } else {
            this.createAuthenticationRequest();
        }
    }

    private createAuthenticationRequest(): Promise<any> {
        this.setState({ isSubmitting: true });
        const authentication = this.state.authentication;
        const request = {
            providerId: authentication.providerId,
            name: authentication.name,
            description: authentication.description,
            credentials: authentication.credentials,
        };
        return endpointService.createAuthentication(request,
            (response: any) => this.createAuthenticationResponse(response),
            (response: any) => this.saveException(response),
            true
        );
    }

    private createAuthenticationResponse(response: any): void {
        const authentication = response.data.authentication;
        this.setState({ isSubmitting: false });
        delete authentication.credentials;
        this.props.onChange(authentication);
    }

    private updateAuthenticationRequest(): Promise<any> {
        this.setState({ isSubmitting: true });
        const authentication = this.state.authentication;
        const request = {
            authenticationId: authentication.id,
            providerId: authentication.providerId,
            name: authentication.name,
            description: authentication.description,
            credentials: authentication.credentials,
        };
        return endpointService.updateAuthentication(request,
            (response: any) => this.updateAuthenticationResponse(response),
            (response: any) => this.saveException(response),
            true
        );
    }

    private updateAuthenticationResponse(response: any): void {
        const authentication = response.data.authentication;
        this.setState({ isSubmitting: false });
        //this.gotoAuthentications();
        this.props.onChange(authentication);
    }

    private saveException(response: any): void {
        this.setState({
            isSubmitting: false,
            error: RestUtils.getErrorMessage(response)
        });
    }

    private readAuthenticationRequest(): Promise<any> {
        const authenticationId = this.props.authenticationId;
        if (!authenticationId) {
            return Promise.resolve(true);
        }
        const request = {
            authenticationId: authenticationId
        };
        return endpointService.readAuthentication(request,
            (response: any) => this.readAuthenticationResponse(response),
            undefined, true
        );
    }

    private readAuthenticationResponse(response: any): void {
        const authentication = response.data.authentication;
        this.setState({ authentication: authentication });
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSkeleton
                direction="vertical"
                count={4}
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load credentials."
                onRetry={this.handleRetryLoad}
            >
                <LoadSkeleton.Input length="short" />
                <LoadSkeleton.Input length="fill" />
            </LoadSkeleton>
        )
    }

    private buildNameView(): ReactElement {
        if (this.props.admission) {
            return (
                <Input value={this.props.admission?.providerId} onFocus={(e) => { e.target.blur() }} />
            )
        } else {
            return (
                <Select
                    placeholder="Select a data source."
                    allowClear={true}
                    value={this.state.authentication.providerId}
                    onChange={this.handleProviderChange}
                >
                    {this.props.providers.map(provider => (
                        <Select.Option key={provider.id} value={provider.id}>{provider.name}</Select.Option>
                    ))}
                </Select>
            )
        }
    }

    private buildEditView(): ReactElement {
        const provider = this.props.providers.find(provider => provider.id === this.state.authentication.providerId);
        const url = provider?.url;
        return (
            <Form ref={this.formRef}>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="Source"
                    name="source"
                    rules={[{
                        required: true,
                        message: 'Please select a data source.'
                    }]}
                >
                    {this.buildNameView()}
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="Type"
                    name="type"
                    rules={[{
                        required: true,
                        message: 'Please select a credentials type.'
                    }]}
                >
                    <Select
                        placeholder="Select a credentials type."
                        allowClear={true}
                        value={this.state.authentication.credentialsType}
                        onChange={this.handleTypeChange}
                    >
                        {CredentialsType.array().map((value) =>
                            <Select.Option key={value} value={value}>{CredentialsType.name(value)}</Select.Option>
                        )}
                    </Select>
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="Name"
                    name="name"
                    rules={[{
                        required: true,
                        message: 'Please enter a name.'
                    }]}
                >
                    <Input
                        placeholder="Enter a name."
                        value={this.state.authentication.name}
                        onChange={this.handleNameChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="Description"
                    name="description"
                >
                    <Input.TextArea
                        placeholder="Enter a description."
                        rows={3}
                        value={this.state.authentication.description}
                        onChange={this.handleDescriptionChange}
                    />
                </FormItem>
                {this.state.authentication.credentialsType === 'API' &&
                    <ApiCredentialsEditor
                        formRef={this.formRef}
                        credentials={this.state.authentication.credentials as ApiCredentials}
                        onChange={this.handleCredentialsChange}
                    />
                }
                {this.state.authentication.credentialsType === 'WEB' &&
                    <WebCredentialsEditor
                        formRef={this.formRef}
                        credentials={this.state.authentication.credentials as WebCredentials}
                        onChange={this.handleCredentialsChange}
                    />
                }
                {url &&
                    <div>
                        For more information on getting access, please see the {provider.name} <Link to={{ pathname: url }} target="_blank" rel="noopener noreferrer">web site</Link>.
                    </div>
                }
                <div className="x-authenticationitem-error">{this.state.error}</div>
            </Form>
        );
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readAuthenticationRequest());
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== StatusType.READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === StatusType.LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === StatusType.FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === StatusType.READY) {
            view = this.buildEditView();
        }
        return (
            <div className="x-authenticationitem">
                <Modal
                    centered
                    title="Credentials"
                    width={Globals.DIALOG_WIDTH}
                    okButtonProps={{ loading: this.state.isSubmitting }}
                    onOk={this.handleSave}
                    onCancel={this.handleCancel}
                    visible={true}
                >
                    {view}
                </Modal>
            </div>
        )
    }


}
