import { CoreUtils } from 'utils/CoreUtils';
import { RestUtils } from 'utils/RestUtils';

class BrowserService {

    public baseUrl = CoreUtils.apiUrl('browser-service', 14013);

    public warm(): Promise<any> {
        const url = `${this.baseUrl}/warm`;
        return RestUtils.get(url, undefined, undefined, undefined, undefined, false);
    }

}

export default new BrowserService();
