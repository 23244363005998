import React, { ChangeEvent, PureComponent } from 'react';
import { Checkbox, Col, Form, FormInstance, Input, Modal, Row, Select } from 'antd';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { ModelContext } from 'context/ModelContext';
import { QueryPicker } from '../../Queries/QueryDialog/QueryBuilder/QueryPicker/QueryPicker';
import { DataType, Option, Variable } from '@methodset/calculator-ts';
import { CoreUtils } from 'utils/CoreUtils';
import { Options } from './Options/Options';
import { ConfigurationType, OptionConfigurationSpec, Query } from '@methodset/endpoint-client-ts';
import { FormGroup } from 'components/FormGroup/FormGroup';
import { Info } from 'components/Info/Info';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import update from 'immutability-helper';
import './VariableEditor.less';

export type ChangeCallback = (data: VariableData) => void;
export type CancelCallback = () => void;

export type VariableData = {
    id: string,
    name: string,
    description?: string,
    title?: string,
    type?: DataType,
    isRequired?: boolean,
    isInternal?: boolean,
    options?: Option[]
}

export type VariableEditorState = {
    data: VariableData,
    doSync: boolean,
    queryId?: string,
    version?: number,
    query?: Query,
    specKey?: string
}

export type VariableEditorProps = typeof VariableEditor.defaultProps & {
    data: VariableData,
    onChange: ChangeCallback,
    onCancel: CancelCallback
}

export class VariableEditor extends PureComponent<VariableEditorProps, VariableEditorState> {

    static defaultProps = {
        title: "Variable Editor"
    }

    static contextType = ModelContext;

    private formRef = React.createRef<FormInstance>();

    constructor(props: VariableEditorProps) {
        super(props);
        this.state = {
            data: props.data,
            doSync: false
        }
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleQueryChange = this.handleQueryChange.bind(this);
        //this.handleVersionChange = this.handleVersionChange.bind(this);
        this.handleQueryLoaded = this.handleQueryLoaded.bind(this);
        this.handleSpecKeyChange = this.handleSpecKeyChange.bind(this);
        this.handleIdChange = this.handleIdChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleRequiredToggle = this.handleRequiredToggle.bind(this);
        this.handleInternalToggle = this.handleInternalToggle.bind(this);
        this.handleSyncToggle = this.handleSyncToggle.bind(this);
        this.handleOptionsToggle = this.handleOptionsToggle.bind(this);
        this.handleOptionsChange = this.handleOptionsChange.bind(this);
        this.handleFormFinish = this.handleFormFinish.bind(this);
    }

    private handleQueryChange(queryId: string, version: number): void {
        this.setState({ 
            queryId: queryId,
            version: version
         });
    }

    // private handleVersionChange(version: number): void {
    //     this.setState({ version: version });
    // }

    private handleQueryLoaded(query: Query): void {
        this.setState({ query: query });
    }

    private handleSpecKeyChange(specKey: string): void {
        const query = this.state.query!;
        const spec = query.configurationSpecs.find(spec => spec.key === specKey) as OptionConfigurationSpec;
        if (!spec) {
            return;
        }
        const data = update(this.state.data, {
            options: { $set: spec.options }
        });
        this.setState({
            specKey: specKey,
            data: data
        });
    }

    private handleIdChange(e: ChangeEvent<HTMLInputElement>): void {
        let variableId = e.target.value;
        if (!CoreUtils.isVariableName(variableId)) {
            return;
        }
        let data = update(this.state.data, {
            id: { $set: variableId }
        });
        this.setState({ data: data });
    }

    private handleNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const name = e.target.value;
        const data = update(this.state.data, {
            name: { $set: name }
        });
        this.setState({ data: data });
    }

    private handleDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        const description = e.target.value;
        const data = update(this.state.data, {
            description: { $set: description }
        });
        this.setState({ data: data });
    }

    private handleTypeChange(type: DataType): void {
        const data = update(this.state.data, {
            type: { $set: type }
        });
        this.setState({ data: data });
    }

    private handleRequiredToggle(e: CheckboxChangeEvent): void {
        const isRequired = e.target.checked;
        const data = update(this.state.data, {
            isRequired: { $set: isRequired }
        });
        this.setState({ data: data });
    }

    private handleInternalToggle(e: CheckboxChangeEvent): void {
        const isInternal = e.target.checked;
        const data = update(this.state.data, {
            isInternal: { $set: isInternal }
        });
        this.setState({ data: data });
    }

    private handleSyncToggle(e: CheckboxChangeEvent): void {
        const doSync = e.target.checked;
        if (doSync) {
            this.setState({ doSync: doSync });
        } else {
            const data = update(this.state.data, {
                options: { $set: [] }
            });
            this.setState({
                data: data,
                doSync: doSync,
                queryId: undefined,
                version: undefined,
                query: undefined,
                specKey: undefined
            });
        }
    }

    private handleOptionsToggle(e: CheckboxChangeEvent): void {
        const hasOptions = e.target.checked;
        if (!hasOptions) {
            const data = update(this.state.data, {
                options: { $set: undefined }
            });
            this.setState({
                data: data,
                doSync: false,
                queryId: undefined,
                version: undefined,
                query: undefined,
                specKey: undefined
            });
        } else {
            const data = update(this.state.data, {
                options: { $set: [] }
            });
            this.setState({ data: data });
        }
    }

    private handleOptionsChange(options: Option[]): void {
        const data = update(this.state.data, {
            options: { $set: options }
        });
        this.setState({ data: data });
    }

    private handleOk(): void {
        this.formRef.current?.submit();
    }

    private handleCancel(): void {
        this.props.onCancel();
    }

    private handleFormFinish() {
        this.props.onChange(this.state.data);
    }

    public render() {
        return (
            <Modal
                className="x-variableditor"
                centered
                width={this.state.data.options ? Globals.DIALOG_WIDTH * 2 : Globals.DIALOG_WIDTH}
                title={this.props.title}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                visible={true}>
                <Form ref={this.formRef} onFinish={this.handleFormFinish}>
                    <Row gutter={Globals.FORM_GUTTER_COL}>
                        <Col span={this.state.data.options ? 12 : 24}>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                name="variable"
                                label="Variable"
                                info="Variable to use in formulas. Must start with a letter and only contain letters, numbers, or underscores."
                                rules={[{
                                    required: true,
                                    message: 'Please enter a variable name.'
                                }, {
                                    validator: (rule: any, value: string) => {
                                        if (!value) {
                                            // Required rule will be applied.
                                            return Promise.resolve();
                                        }
                                        if (this.props.data.id === value) {
                                            // Variable has not changed and is already validated.
                                            return Promise.resolve();
                                        }
                                        try {
                                            Variable.validate(value, this.context.calculator);
                                        } catch (e: any) {
                                            if (e instanceof Error) {
                                                return Promise.reject(e.message);
                                            } else {
                                                return Promise.reject("Invalid variable name.");
                                            }
                                        }
                                        return Promise.resolve();
                                    }
                                }]}
                            >
                                <Input
                                    id="variable"
                                    placeholder="Variable"
                                    value={this.state.data.id}
                                    onChange={this.handleIdChange}
                                />
                            </FormItem>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                name="name"
                                label="Name"
                                info="The friendly name of the parameter to help identify its meaning."
                                rules={[{
                                    required: true,
                                    message: 'Please enter a name.'
                                }]}
                            >
                                <Input
                                    id="name"
                                    placeholder="Name"
                                    value={this.state.data.name}
                                    onChange={this.handleNameChange}
                                />
                            </FormItem>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                name="description"
                                label="Description"
                                info="The description of the parameter."
                            >
                                <Input.TextArea
                                    id="description"
                                    placeholder="Description"
                                    rows={3}
                                    value={this.state.data.description}
                                    onChange={this.handleDescriptionChange}
                                />
                            </FormItem>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                name="type"
                                label="Type"
                                info="The type of the variable."
                            >
                                <Select
                                    placeholder="Variable type."
                                    value={this.state.data.type}
                                    allowClear={true}
                                    onChange={this.handleTypeChange}>
                                    <Select.Option value={DataType.TEXT}>Text</Select.Option>
                                    <Select.Option value={DataType.NUMBER}>Number</Select.Option>
                                    <Select.Option value={DataType.BOOLEAN}>Boolean</Select.Option>
                                    <Select.Option value={DataType.DATE}>Date</Select.Option>
                                    <Select.Option value={DataType.TIME}>Time</Select.Option>
                                </Select>
                            </FormItem>
                            <FormGroup errorSpace={false}>
                                <Checkbox
                                    checked={this.state.data.isRequired}
                                    onChange={this.handleRequiredToggle}
                                >
                                    <Info
                                        label="Value Required"
                                        info="Tells if the variable requires a value."
                                        bold={true}
                                    />
                                </Checkbox>
                                <Checkbox
                                    checked={this.state.data.isInternal}
                                    onChange={this.handleInternalToggle}
                                >
                                    <Info
                                        label="Internal Only"
                                        info="Tells if the variable is for internal use only and cannot be overridden."
                                        bold={true}
                                    />
                                </Checkbox>
                                <Checkbox
                                    checked={!!this.state.data.options}
                                    onChange={this.handleOptionsToggle}
                                >
                                    <Info
                                        label="Use Options"
                                        info="Define a set of options for the variable."
                                        bold={true}
                                    />
                                </Checkbox>
                                <Checkbox
                                    checked={!!this.state.doSync}
                                    disabled={!this.state.data.options}
                                    onChange={this.handleSyncToggle}
                                >
                                    <Info
                                        label="Sync Options"
                                        info="Sync with the options of an existing dataset query."
                                        bold={true}
                                    />
                                </Checkbox>
                            </FormGroup>
                        </Col>
                        <Col span={12}>
                            {this.state.doSync &&
                                <>
                                    <QueryPicker
                                        formRef={this.formRef}
                                        queryId={this.state.queryId}
                                        version={this.state.version}
                                        onChange={this.handleQueryChange}
                                        onLoad={this.handleQueryLoaded}
                                    />
                                    {/* <FormItem
                                        {...Globals.FORM_LAYOUT}
                                        formRef={this.formRef}
                                        label="Dataset"
                                        name="dataset"
                                        info="The dataset to use as a template."
                                        valuePropName="queryId"
                                        justification="center"
                                        rules={[{
                                            required: true,
                                            message: `Please select a dataset.`
                                        }]}
                                    >
                                        <QueryPicker
                                            queryId={this.state.queryId}
                                            onChange={this.handleQueryChange}
                                        />
                                    </FormItem>
                                    <FormItem
                                        {...Globals.FORM_LAYOUT}
                                        formRef={this.formRef}
                                        label="Version"
                                        name="version"
                                        info="The dataset version."
                                        valuePropName="version"
                                        rules={[{
                                            required: true,
                                            message: `Please select a dataset version.`
                                        }]}
                                    >
                                        <QueryVersionPicker
                                            queryId={this.state.queryId}
                                            version={this.state.version}
                                            onChange={this.handleVersionChange}
                                        />
                                    </FormItem> */}
                                    {/* <QueryLoader
                                        formRef={this.formRef}
                                        visible={!!this.state.query}
                                        queryId={this.state.queryId}
                                        version={this.state.version}
                                        onLoad={this.handleQueryLoaded}
                                    /> */}
                                    {this.state.query &&
                                        <>
                                            <FormItem
                                                {...Globals.FORM_LAYOUT}
                                                formRef={this.formRef}
                                                label="Option Field"
                                                name="field"
                                                info="The dataset's options field to use as the template for its values."
                                                rules={[{
                                                    required: true,
                                                    message: `Please select an options field.`
                                                }]}
                                            >
                                                <Select
                                                    placeholder="Reference field."
                                                    value={this.state.specKey}
                                                    onChange={this.handleSpecKeyChange}
                                                >
                                                    {this.state.query.configurationSpecs
                                                        .filter(spec => spec.type === ConfigurationType.OPTION)
                                                        .map(spec => (
                                                            <Select.Option key={spec.key} value={spec.key}>{spec.name}</Select.Option>
                                                        ))}
                                                </Select>
                                            </FormItem>
                                            {this.state.specKey &&
                                                <Options
                                                    options={this.state.data.options}
                                                    onChange={this.handleOptionsChange}
                                                />
                                            }
                                        </>
                                    }
                                </>
                            }
                            {!this.state.doSync && this.state.data.options &&
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.formRef}
                                    label="Options"
                                    info="The options for the value."
                                    name="options"
                                    valuePropName="options"
                                    rules={[{
                                        required: true,
                                        message: ''
                                    }, {
                                        validator: (rule: any, options: Option[]) => {
                                            if (options.length === 0) {
                                                return Promise.reject('Please enter at least one option field.');
                                            }
                                            for (let i = 0; i < options.length; i++) {
                                                const option = options[i];
                                                if (!option.value) {
                                                    return Promise.reject('Please enter all option fields.');
                                                }
                                            }
                                            return Promise.resolve();
                                        }
                                    }]}
                                >
                                    <Options
                                        options={this.state.data.options}
                                        onChange={this.handleOptionsChange}
                                    />
                                </FormItem>
                            }
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }

}
