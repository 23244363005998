import React, { PureComponent, ReactElement } from 'react';
import { Button, Checkbox, FormInstance, Input, Modal, Select } from 'antd';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { RestUtils } from 'utils/RestUtils';
import { RouteComponentProps } from 'react-router-dom';
import { EntityContext } from 'context/EntityContext';
import { Group, User } from '@methodset/entity-client-ts';
import { StatusType } from 'constants/StatusType';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import entityService from 'services/EntityService';
import axios from 'axios';
import './GroupAddMember.less';

export type CancelCallback = () => void;
export type AddedCallback = (user: User) => void;

export type GroupAddMemberProps = {
    group: Group,
    onAdded: AddedCallback,
    onCancel: CancelCallback
}

export type GroupAddMemberState = {
    status: StatusType,
    emailAddress?: string,
    userId?: string,
    users: User[],
    error: Error | undefined,
    isOutside: boolean,
    isAdding: boolean
}

export class GroupAddMember extends PureComponent<GroupAddMemberProps, GroupAddMemberState> {

    static contextType = EntityContext;

    private formRef = React.createRef<FormInstance>();

    constructor(props: GroupAddMemberProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            users: [],
            error: undefined,
            isOutside: false,
            isAdding: false
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleOutsideToggle = this.handleOutsideToggle.bind(this);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleOutsideToggle(e: CheckboxChangeEvent): void {
        const isOutside = e.target.checked;
        this.setState({ isOutside: isOutside });
    }

    private handleUserChange(userId: string): void {
        this.setState({ userId: userId });
    }

    private handleSaveClick(): void {
        if (this.state.isAdding) {
            return;
        }
        this.addGroupMemberRequest();
    }

    private handleCancelClick(): void {
        this.props.onCancel();
    }

    private handleEmailChange(e: any): void {
        const emailAddress = e.target.value;
        this.setState({ emailAddress: emailAddress });
    }

    private readUsersRequest(): Promise<any> {
        this.setState({
            error: undefined
        });
        const request = {}
        return entityService.readUsers(request,
            (response: any) => this.readUsersResponse(response),
            (response: any) => this.readUsersException(response),
            true
        );
    }

    private readUsersResponse(response: any): void {
        const users = response.data.users;
        this.setState({
            users: users,
            error: undefined
        });
    }

    private readUsersException(response: any): void {
        this.setState({
            error: RestUtils.getError(response)
        });
    }

    private addGroupMemberRequest(): Promise<any> {
        this.setState({
            isAdding: true,
            error: undefined
        });
        const request = {
            groupId: this.props.group.id,
            userId: this.state.userId,
            emailAddress: this.state.emailAddress
        }
        return entityService.addGroupMember(request,
            (response: any) => this.addGroupMemberResponse(response),
            (response: any) => this.addGroupMemberException(response),
            true
        );
    }

    private addGroupMemberResponse(response: any): void {
        const user = response.data.user;
        this.setState({
            error: undefined,
            isAdding: false
        });
        this.props.onAdded(user);
    }

    private addGroupMemberException(response: any): void {
        this.setState({
            error: RestUtils.getError(response),
            isAdding: false
        });
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSkeleton
                direction="vertical"
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load users."
                onRetry={this.handleRetryLoad}
            >
                <LoadSkeleton.Input length="fill" />
                <LoadSkeleton.Input length="short" />
                <LoadSkeleton.Input length="fill" />
                <LoadSkeleton.Input length="medium" />
            </LoadSkeleton>
        );
    }

    private loadData() {
        const requests = [];
        requests.push(this.readUsersRequest());
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    private isMember(user: User): boolean {
        return this.props.group.users.findIndex(u => u.id === user.id) !== -1;
    }

    public componentDidMount() {
        if (this.state.status !== StatusType.READY) {
            this.loadData();
        }
    }

    private buildFormView(): ReactElement {
        return (
            <div>
                {!this.state.isOutside &&
                    <div>
                        <div className="x-groupaddmember-describe">Select the name of the user you would like to add to your group.</div>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            label="User"
                            name="user"
                            rules={[{
                                required: true,
                                message: 'Please select a user.'
                            }]}
                        >
                        <Select
                            className="x-groupaddmember-select"
                            placeholder="User name."
                            value={this.state.userId}
                            onChange={this.handleUserChange}
                        >
                            {this.state.users.filter(user => !this.isMember(user)).map(user => (
                                <Select.Option key={user.id} value={user.id}>{`${user.firstName} ${user.lastName}`}</Select.Option>
                            ))}
                        </Select>
                        </FormItem>
                    </div>
                }
                {this.state.isOutside &&
                    <div>
                        <div className="x-groupaddmember-describe">Enter the email address of the user you would like to add to your group.</div>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            label="Email"
                            name="email"
                            rules={[{
                                required: true,
                                message: 'Please enter an email address.'
                            }, {
                                type: 'email',
                                message: 'The email address is invalid.'
                            }]}
                        >
                            <Input
                                id="email"
                                placeholder="Email address."
                                value={this.state.emailAddress}
                                onChange={this.handleEmailChange}
                            />
                        </FormItem>
                    </div>
                }
                <Checkbox
                    key="outside"
                    checked={this.state.isOutside}
                    onChange={this.handleOutsideToggle}
                >
                    Add a user from another organization.
                </Checkbox>
                {this.state.error &&
                    <div className="x-groupaddmember-error">{this.state.error.message}</div>
                }
            </div>
        );
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === StatusType.LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === StatusType.FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === StatusType.READY) {
            view = this.buildFormView();
        }
        return (
            <Modal
                centered
                title="Add Group Member"
                width={Globals.DIALOG_WIDTH}
                onCancel={this.handleCancelClick}
                visible={true}
                footer={(
                    <>
                        <Button onClick={this.handleCancelClick}>Cancel</Button>
                        <Button type="primary" loading={this.state.isAdding} onClick={this.handleSaveClick}>Add</Button>
                    </>
                )}
            >
                {view}
            </Modal>
        );
    }

}
