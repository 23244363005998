import { PureComponent, ReactElement } from 'react';
import { Button, Card, Row } from 'antd';
import { Applet, AppletPanel, ApplicationRef, ApplicationState, Installation, MenuItem } from '@methodset/application-client-ts';
import { Configuration } from '@methodset/model-client-ts';
import { Calculator } from '@methodset/calculator-ts';
import { InfoCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { StatusType } from 'constants/StatusType';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { RestUtils } from 'utils/RestUtils';
import { AuthenticationHeader, CredentialsType, Provider } from '@methodset/endpoint-client-ts';
import { QueryAdmission } from '@methodset/endpoint-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { AuthenticationItem } from 'containers/Console/Authentications/AuthenticationItem/AuthenticationItem';
import { AppletTitle } from './AppletTitle/AppletTitle';
import { PanelDialog } from './PanelDialog/PanelDialog';
import { NoData } from 'components/NoData/NoData';
import { ItemSpec, MenuButton } from 'components/MenuButton/MenuButton';
import { Globals } from 'constants/Globals';
import { AppletAbout } from '../AppletAbout/AppletAbout';
import { CoreUtils } from 'utils/CoreUtils';
import { PanelViewer } from './PanelViewer/PanelViewer';
import { InlineMessage, OpMessage } from 'components/InlineMessage/InlineMessage';
import { EntityContext } from 'context/EntityContext';
import { IdUtils, Version } from '@methodset/commons-core-ts';
import axios from 'axios';
import update from 'immutability-helper';
import applicationService from 'services/ApplicationService';
import './AppletViewer.less';

// A function to execute after an API response.
type ResponseFunction = (response?: any) => void;
// Types of modals that can be displayed.
type ModalType = "none" | "about";

// The loaded applet component.
interface AppletComponent {
    applet: Applet;
    panel: AppletPanel;
    calculator: Calculator;
    state: ApplicationState;
    admissions?: QueryAdmission[];
}

export type LoadCallback = (installation: Installation, applet: Applet, calculator: Calculator) => void;
export type ConfigurationCallback = (installation: Installation, configuration: Configuration) => void;
export type AuthenticationCallback = (installation: Installation, header: AuthenticationHeader) => void;

export type AppletViewerProps = {
    providers: Provider[],
    // Data used to load the applet. If the installation is a string,
    // it must be the installation id and the applet will be loaded.
    // Otherwise, the installation represents an applet panel 
    // to display (which has already been loaded).
    installationId: string,
    // Extra items to add to the action menu.
    actionItems?: ItemSpec[],
    // True if this is an embedded applet (no title).
    isEmbedded?: boolean,
    // The credentials, if available.
    authentications?: AuthenticationHeader[],
    onLoad?: LoadCallback,
    onConfiguration: ConfigurationCallback,
    onAuthentication?: AuthenticationCallback,
}

export type AppletViewerState = {
    // Loading status.
    status: StatusType,
    // Current error, if any.
    error?: Error,
    // Message to display in header.
    message?: OpMessage,
    // Type of modal to display.
    modalType: ModalType,
    // The loaded applet component.
    component: AppletComponent,
    // The menu to display, if any.
    menuItem?: MenuItem,
    // The application installation.
    installation?: Installation,
    // True if calculator is executing.
    isExecuting: boolean,
    // True if editing credentials.
    editAdmission?: QueryAdmission
}

export class AppletViewer extends PureComponent<AppletViewerProps, AppletViewerState> {

    static defaultProps = {
        isEmbedded: false
    }

    static contextType = EntityContext;

    constructor(props: AppletViewerProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            isExecuting: false,
            modalType: "none",
            component: { state: {} } as AppletComponent
        }
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleWidgetUpdate = this.handleWidgetUpdate.bind(this);
        this.handleMenuSelect = this.handleMenuSelect.bind(this);
        this.handleMenuClose = this.handleMenuClose.bind(this);
        this.handleAboutShow = this.handleAboutShow.bind(this);
        this.handleAboutClose = this.handleAboutClose.bind(this);
        this.handleAppletRefresh = this.handleAppletRefresh.bind(this);
        //this.handleAutoEdit = this.handleAutoEdit.bind(this);
        //this.handleAutoChange = this.handleAutoChange.bind(this);
        //handleAutoCancel = this.handleAutoCancel.bind(this);
        this.handleConfigurationChange = this.handleConfigurationChange.bind(this);
        //this.handleSnapshotSelect = this.handleSnapshotSelect.bind(this);
        //this.handleUpdateCheck = this.handleUpdateCheck.bind(this);
        this.handleAuthenticationAdd = this.handleAuthenticationAdd.bind(this);
        this.handleAuthenticationCancel = this.handleAuthenticationCancel.bind(this);
        this.handleExecutionInitiate = this.handleExecutionInitiate.bind(this);
        this.handleExecutionComplete = this.handleExecutionComplete.bind(this);
    }

    private handleAboutShow(): void {
        this.setState({ modalType: "about" });
    }

    private handleAboutClose(): void {
        this.setState({ modalType: "none" });
    }

    // private handleAutoEdit(): void {
    //     this.setState({ modalType: "auto" });
    // }

    // private handleAutoChange(autoUpdate: boolean): void {
    //     const state = update(this.state.component.state, {
    //         autoUpdate: { $set: autoUpdate }
    //     });
    //     this.updateStateRequest(state);
    // }

    // private handleAutoCancel(): void {
    //     this.setState({ modalType: "none" });
    // }

    private handleConfigurationChange(configuration: Configuration): void {
        this.handleWidgetUpdate(configuration);
    }

    // private handleSnapshotSelect(): void {
    //     const installation = this.state.installation!;
    //     const packInfo = update(installation.packInfo, {
    //         version: { $set: 0 }
    //     });
    //     const component = update(this.state.component, {
    //         state: {
    //             autoUpdate: { $set: false }
    //         }
    //     });
    //     const applicationRef = installation.applicationRef;
    //     this.updateInstallationRequest(applicationRef, packInfo, component.state);
    // }

    // private handleUpdateCheck(): void {
    //     this.readPackHeaderRequest(this.state.installation!.packInfo, true);
    // }

    // private readPackHeaderRequest(packInfo: PackInfo, checkUpdate: boolean = false): Promise<any> {
    //     const message: OpMessage = {
    //         text: "Checking updates...",
    //         type: "info"
    //     }
    //     this.setState({ message: message });
    //     const request = {
    //         packId: packInfo.packId,
    //         accessType: packInfo.accessType
    //     }
    //     return libraryService.readPackHeader(request,
    //         (response: any) => this.readPackHeaderResponse(response, checkUpdate),
    //         undefined, true
    //     );
    // }

    // private readPackHeaderResponse(response: any, checkUpdate: boolean): PackHeader {
    //     const packHeader = response.data.header;
    //     if (checkUpdate) {
    //         const packInfo = this.state.installation!.packInfo;
    //         if (packInfo && packHeader && packHeader.version > packInfo.version) {
    //             this.installPack(packHeader);
    //         } else {
    //             this.setState({ message: undefined });
    //         }
    //     } else {
    //         this.setState({ message: undefined });
    //     }
    //     return packHeader;
    // }

    // private installPackRequest(packHeader: PackHeader): Promise<any> {
    //     const message: OpMessage = {
    //         text: `Installing v${packHeader.version}...`,
    //         type: "info"
    //     }
    //     this.setState({ message: message });
    //     const request = {
    //         packId: packHeader.id,
    //         version: packHeader.version,
    //         instanceId: this.props.installationId,
    //         accessType: packHeader.accessType
    //     }
    //     return libraryService.installPack(request,
    //         (response: any) => this.installPackResponse(response),
    //         (response: any) => this.installPackException(response),
    //         true
    //     );
    // }

    // private installPackResponse(response: any): ApplicationRef {
    //     const header = response.data.header as PackHeader;
    //     const details = header.details as AppletDetails;
    //     const installation = update(this.state.installation!, {
    //         packInfo: {
    //             version: { $set: header.version },
    //             name: { $set: header.name },
    //             description: { $set: header.description },
    //             publisher: { $set: header.publisher },
    //             admissions: { $set: header.admissions },
    //             accessType: { $set: header.accessType },
    //             //installTime: { $set: Time.timestamp() }
    //         }
    //     });
    //     this.setState({ installation: installation });
    //     const applicationRef = {
    //         applicationId: details.applicationId,
    //         modelId: details.modelId,
    //         version: details.version
    //     }
    //     return applicationRef;
    // }

    // private installPackException(response: any): void {
    //     // If update to new pack version fails, skip and try on next open of applet.
    //     const error = RestUtils.getError(response);
    //     console.error(error.message);
    //     const message: OpMessage = {
    //         text: "Update failed.",
    //         type: "error"
    //     }
    //     this.setState({ message: message });
    // }

    // private updateInstallationRequest(applicationRef: ApplicationRef, packInfo: PackInfo, state: State): Promise<any> {
    //     const installation = this.state.installation!;
    //     const request = {
    //         installationId: installation.id,
    //         applicationRef: applicationRef,
    //         packInfo: packInfo,
    //         state: state
    //     }
    //     return modelService.updateInstallation(request,
    //         (response: any) => this.updateInstallationResponse(response),
    //         (response: any) => this.updateInstallationException(response),
    //         true
    //     );
    // }

    // private updateInstallationResponse(response: any): ApplicationRef {
    //     const installation = response.data.installation as Installation;
    //     const component = update(this.state.component, {
    //         state: { $set: installation.state! }
    //     });
    //     this.setState({
    //         installation: installation,
    //         component: component,
    //         modalType: "none"
    //     });
    //     return installation.applicationRef;
    // }

    // private updateInstallationException(response: any): void {
    //     const error = RestUtils.getError(response);
    //     console.error(error.message);
    //     const message: OpMessage = {
    //         text: "Update failed.",
    //         type: "error"
    //     }
    //     this.setState({ message: message });
    // }

    private updateStateRequest(state: ApplicationState, func?: ResponseFunction): Promise<any> {
        // const message: OpMessage = {
        //     text: "Saving...",
        //     type: "info"
        // }
        // this.setState({ message: message });
        const request = {
            installationId: this.props.installationId,
            state: state
        }
        return applicationService.updateInstallationState(request,
            (response: any) => this.updateStateResponse(response, func),
            (response: any) => this.updateStateException(response),
            true
        );
    }

    private updateStateResponse(response: any, func?: ResponseFunction): void {
        if (func) {
            func(response);
        }
        const state = response.data.state;
        const component = update(this.state.component, {
            state: { $set: state }
        });
        this.setState({
            modalType: "none",
            component: component,
            message: undefined
        });
    }

    private updateStateException(response: any): void {
        const error = RestUtils.getError(response);
        console.error(error.message);
        const message: OpMessage = {
            text: "Update failed",
            type: "error"
        }
        this.setState({ message: message });
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private isApplet(): boolean {
        return this.state.component.applet.id === this.state.component.panel.id;
    }

    private isPanel(): boolean {
        return !this.isApplet();
    }

    private findMissingAdmissions(): QueryAdmission[] {
        const components = this.state.component;
        if (!this.hasMissingAdmissions()) {
            return [];
        }
        const authentications = this.props.authentications!;
        const admissions = components.admissions!;
        return admissions.filter(admission => {
            const index = authentications.findIndex(authentication => {
                return authentication.providerId === admission.providerId &&
                    authentication.credentialsType === admission.credentialsType
            });
            return index === -1;
        });
    }

    private hasMissingAdmissions(): boolean {
        // If there are no admissions, they cannot be missing. Also if
        // there are no authentications, this could be embedded in another
        // applet. The parent will handle the credentials check.
        if (!this.props.authentications) {
            return false;
        } else if (this.isPanel()) {
            return false;
        } else if (!this.state.component.admissions || this.state.component.admissions.length === 0) {
            return false;
        }
        const authentications = this.props.authentications;
        const admissions = this.state.component.admissions;
        for (const admission of admissions) {
            const index = authentications.findIndex(authentication => {
                return authentication.providerId === admission.providerId &&
                    authentication.credentialsType === admission.credentialsType
            });
            if (index === -1) {
                return true;
            }
        }
        return false;
    }

    private handleAppletRefresh(): void {
        const calculator = this.state.component.calculator;
        if (calculator) {
            calculator.runQueries();
        }
    }

    private handleWidgetUpdate(configuration: Configuration): void {
        const func = (response: any) => {
            const installation = this.state.installation!;
            this.props.onConfiguration(installation, configuration);
        }
        const state = update(this.state.component.state, {
            configuration: { $set: configuration }
        });
        this.updateStateRequest(state, func);
    }

    private handleMenuSelect(menuItem: MenuItem): void {
        this.setState({ menuItem: menuItem });
    }

    private handleMenuClose(): void {
        this.setState({ menuItem: undefined });
    }

    private handleAuthenticationAdd(header: AuthenticationHeader): void {
        const installation = this.state.installation!;
        if (this.props.onAuthentication) {
            this.props.onAuthentication(installation, header);
        }
        if (!this.hasMissingAdmissions()) {
            if (this.props.onLoad) {
                this.props.onLoad(installation, this.state.component.applet, this.state.component.calculator);
            }
            // All credentials are setup, start the calculator.
            this.executeCalculator(this.state.component.calculator);
        }
        this.setState({ editAdmission: undefined });
    }

    private handleAuthenticationCancel(): void {
        this.setState({ editAdmission: undefined });
    }

    private handleExecutionInitiate(): void {
        this.setState({ isExecuting: true });
    }

    private handleExecutionComplete(): void {
        //this.props.calculator.printState(true);
        this.setState({ isExecuting: false });
        this.forceUpdate();
    }

    private handleCredentialsConfigure(admission: QueryAdmission): void {
        this.setState({ editAdmission: admission });
    }

    private readInstallationRequest(): Promise<any> {
        const request = {
            installationId: this.props.installationId
        };
        return applicationService.readInstallation(request,
            (response: any) => this.readInstallationResponse(response),
            undefined, true
        );
    }

    private readInstallationResponse(response: any): Installation {
        const installation: Installation = response.data.installation;
        const components = update(this.state.component, {
            state: { $set: installation.state! },
            admissions: { $set: installation.admissions }
        });
        this.setState({
            installation: installation,
            component: components,
        });
        return installation;
    }

    private loadApplicationRequest(applicationId: string): Promise<any> {
        //const environmentType = this.context.user?.environmentType;
        const request = {
            applicationId: applicationId,
            version: IdUtils.isEditId(applicationId) ? Version.EDITOR : Version.LATEST
            //version: IdUtils.isEditId(applicationRef.modelId, environmentType) ? Version.EDITOR : Version.LATEST,
            //version: Version.LATEST,
            //version: applicationRef.version,
            //modelId: applicationRef.modelId
            //version: environmentType === EnvironmentType.DEVELOPMENT ? Version.EDITOR : Version.LATEST,
        };
        return applicationService.loadApplication(request,
            (response: any) => this.loadApplicationResponse(response),
            undefined, true
        );
    }

    private loadApplicationResponse(response: any): void {
        const applet = response.data.application as Applet;
        const calculator = Calculator.deserialize(response.data.calculator);
        calculator.httpHeaders = RestUtils.getHttpHeaders();
        calculator.context.requestKey = 0;
        calculator.addCallback("ExecutionInitiate", this.handleExecutionInitiate);
        calculator.addCallback("ExecutionComplete", this.handleExecutionComplete);
        const components = update(this.state.component, {
            applet: { $set: applet },
            panel: { $set: applet },
            calculator: { $set: calculator }
        });
        this.setState({
            component: components,
            message: undefined
        });
        // Only execute the calculator when all credentials are configured.
        if (!this.hasMissingAdmissions()) {
            if (this.props.onLoad) {
                const installation = this.state.installation!;
                this.props.onLoad(installation, applet, calculator);
            }
            this.executeCalculator(calculator);
        }
        // Otherwise, user needs to setup credentials before calculator can start.
    }

    private executeCalculator(calculator: Calculator): void {
        const configuration = this.state.component.state.configuration;
        // Suspend calculator updates while setting parameters.
        calculator.suspend();
        if (configuration) {
            this.overrideVariables(configuration, calculator);
        }
        // Unsuspend and execute with the new parameter values.
        calculator.execute();
    }

    private overrideVariables(configuration: Configuration, calculator: Calculator): void {
        const variables = calculator.variables;
        for (const [key, value] of Object.entries(configuration)) {
            const variable = variables.get(key, false);
            if (variable && variable.cell) {
                const cell = variable.cell;
                // Sanity check, cannot overwrite formula.
                if (!CoreUtils.isFormula(value)) {
                    cell.value = value;
                }
            }
        }
    }

    private buildPanelView(): ReactElement {
        const panel = this.activePanel();
        const component = this.state.component
        return (
            <Row className="x-appletviewer-panel">
                <PanelViewer
                    applet={component.applet}
                    calculator={component.calculator}
                    panel={panel}
                    configuration={component.state.configuration}
                    onConfiguration={this.handleWidgetUpdate}
                />
            </Row>
        )
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        if (this.props.isEmbedded) {
            return (
                <div>
                    {this.buildSkeleton(isLoading)}
                </div>
            )
        } else {
            return (
                <Card
                    size="small"
                    bordered={true}
                    extra={
                        <Spacer>
                            <InlineMessage message={this.state.message} />
                            {this.buildActionsMenu()}
                        </Spacer>
                    }
                >
                    {this.buildSkeleton(isLoading)}
                </Card>
            )
        }
    }

    private buildSkeleton(isLoading: boolean): ReactElement {
        return (
            <LoadSkeleton
                count={4}
                status={isLoading ? "loading" : "failed"}
                failedMessage={this.state.error ? this.state.error.message : "Failed to load applet."}
                onRetry={this.handleRetryLoad}
            >
                <LoadSkeleton.Input length="fill" />
            </LoadSkeleton>
        )
    }

    private activePanel(): AppletPanel {
        const component = this.state.component;
        return component.panel ?? component.applet;
    }

    private buildActionsMenu(): ReactElement | undefined {
        //const environmentType = this.context.user?.environmentType;
        let items: ItemSpec[] = [{
            icon: <SyncOutlined />,
            label: "Refresh applet",
            disabled: !this.state.component.calculator,
            onSelect: this.handleAppletRefresh
        }, {
        //     icon: <DownloadOutlined />,
        //     label: "Update applet",
        //     onSelect: this.handleUpdateCheck
        // }, {
        //     icon: <CloudSyncOutlined />,
        //     label: "Auto Update...",
        //     onSelect: this.handleAutoEdit
        // }, {
        //     icon: <ExperimentOutlined />,
        //     label: "Development",
        //     children: [{
        //         label: "Use snapshot",
        //         disabled: () => this.state.installation!.packInfo.version === 0,
        //         onSelect: this.handleSnapshotSelect
        //     }],
        //     hidden: () => environmentType !== EnvironmentType.DEVELOPMENT
        // }, {
            icon: <InfoCircleOutlined />,
            label: "About...",
            disabled: !this.state.component.applet || !this.state.installation,
            onSelect: this.handleAboutShow
        }];
        // Splice in custom actions.
        if (this.props.actionItems && this.props.actionItems.length > 0) {
            const index = items.length - 1;
            items.splice(index, 0, ...this.props.actionItems);
        }
        const actions = (
            <MenuButton
                items={items}
                data={this.state.installation!}
                size={Globals.APPLET_MENU_SIZE}
                shape={Globals.APPLET_MENU_SHAPE}
            />
        );
        return actions;
    }

    private buildAppletView(): ReactElement {
        const component = this.state.component;
        const admissions = this.findMissingAdmissions();
        const panel = this.activePanel();
        const widgets = panel.widgets;
        const title = (
            <AppletTitle
                applet={component.applet}
                calculator={component.calculator}
                onSelect={this.handleMenuSelect}
            />
        )
        const extra = (
            <Spacer>
                {this.state.isExecuting &&
                    <SyncOutlined spin={true} />
                }
                <InlineMessage message={this.state.message} />
                {this.buildActionsMenu()}
            </Spacer>
        )
        const view = (
            <>
                {widgets.length === 0 &&
                    <NoData text="No widgets added." />
                }
                {widgets.length > 0 &&
                    <>
                        {admissions.length > 0 &&
                            this.buildConfigurationView(admissions)
                        }
                        {admissions.length === 0 &&
                            this.buildPanelView()
                        }
                    </>
                }
            </>
        )
        return (
            <>
                {!this.props.isEmbedded &&
                    <Card
                        className="x-appletviewer-card"
                        size="small"
                        bordered={true}
                        title={title}
                        extra={extra}
                    >
                        {view}
                    </Card>
                }
                {this.props.isEmbedded &&
                    <div>
                        {view}
                    </div>
                }
                {this.isApplet() && this.state.menuItem &&
                    <PanelDialog
                        viewId="dashboarditem"
                        applet={component.applet}
                        title={this.state.menuItem.label}
                        panelId={this.state.menuItem.panelId}
                        calculator={component.calculator}
                        configuration={component.state.configuration}
                        onChange={this.handleConfigurationChange}
                        onClose={this.handleMenuClose}
                    />
                }
            </>
        );
    }

    private buildConfigurationView(admissions: QueryAdmission[]): ReactElement {
        const providers = Provider.map(this.props.providers);
        return (
            <div>
                <div className="x-appletviewer-config">This applet requires credentials that need to be configured:</div>
                <Spacer direction="vertical">
                    {admissions.map(admission => (
                        <Spacer>
                            <Button onClick={() => this.handleCredentialsConfigure(admission)}>Configure</Button>
                            <span>{`${providers[admission.providerId].name} ${CredentialsType.name(admission.credentialsType)}`}</span>
                            {/* <span>{`${SourceType.name(admission.sourceType)} ${CredentialsType.name(admission.credentialsType)}`}</span> */}
                        </Spacer>
                    ))}
                </Spacer>
            </div>
        )
    }

    private loadData(): void {
        this.loadInstallation();
    }

    private loadInstallation(): void {
        const requests = [];
        requests.push(this.readInstallationRequest());
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                const installation = r1 as Installation;
                // const state = installation.state!;
                // if (state.autoUpdate) {
                //     this.checkUpdate(installation.packInfo);
                // } else {
                    this.loadApplication(installation.applicationId);
                // }
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    // private checkUpdate(packInfo: PackInfo): void {
    //     const requests = [];
    //     requests.push(this.readPackHeaderRequest(packInfo));
    //     this.setState({ status: StatusType.LOADING });
    //     axios.all(requests).then(axios.spread((r1) => {
    //         if (RestUtils.isOk(r1)) {
    //             const packHeader = r1 as PackHeader;
    //             if (packInfo && packHeader && packHeader.version > packInfo.version) {
    //                 this.installPack(packHeader);
    //             } else {
    //                 const details = packHeader.details as ApplicationDetails;
    //                 const applicationRef = {
    //                     applicationId: details.applicationId,
    //                     modelId: details.modelId,
    //                     version: details.version
    //                 }
    //                 this.loadApplication(applicationRef);
    //             }
    //         } else {
    //             this.setState({ status: StatusType.FAILED });
    //         }
    //     }));
    // }

    // private installPack(packHeader: PackHeader): void {
    //     const requests = [];
    //     requests.push(this.installPackRequest(packHeader));
    //     this.setState({ status: StatusType.LOADING });
    //     axios.all(requests).then(axios.spread((r1) => {
    //         if (RestUtils.isOk(r1)) {
    //             const applicationRef = r1 as ApplicationRef;
    //             const installation = this.state.installation!;
    //             const packInfo = installation.packInfo;
    //             const state = installation.state!;
    //             this.updateInstallation(applicationRef, packInfo, state);
    //         } else {
    //             this.setState({ status: StatusType.FAILED });
    //         }
    //     }));
    // }

    // private updateInstallation(applicationRef: ApplicationRef, packInfo: PackInfo, state: State): void {
    //     const requests = [];
    //     requests.push(this.updateInstallationRequest(applicationRef, packInfo, state));
    //     this.setState({ status: StatusType.LOADING });
    //     axios.all(requests).then(axios.spread((r1) => {
    //         if (RestUtils.isOk(r1)) {
    //             const applicationRef = r1 as ApplicationRef;
    //             this.loadApplication(applicationRef);
    //         } else {
    //             this.setState({ status: StatusType.FAILED });
    //         }
    //     }));
    // }

    private loadApplication(applicationId: string): void {
        const requests = [];
        requests.push(this.loadApplicationRequest(applicationId));
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== StatusType.READY) {
            this.loadData();
        }
    }

    public componentWillUnmount(): void {
        const components = this.state.component;
        if (components?.calculator) {
            components.calculator.removeCallback("ExecutionInitiate", this.handleExecutionInitiate);
            components.calculator.removeCallback("ExecutionComplete", this.handleExecutionComplete);
            components.calculator.close();
        }
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === StatusType.LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === StatusType.FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === StatusType.READY) {
            view = this.buildAppletView();
        }
        return (
            <div className="x-appletviewer">
                {view}
                {this.state.editAdmission &&
                    <AuthenticationItem
                        providers={this.props.providers}
                        admission={this.state.editAdmission}
                        onChange={this.handleAuthenticationAdd}
                        onCancel={this.handleAuthenticationCancel}
                    />
                }
                {/* {this.state.modalType === "auto" &&
                    <AutoUpdater
                        autoUpdate={this.state.component.state.autoUpdate}
                        onChange={this.handleAutoChange}
                        onCancel={this.handleAutoCancel}
                    />
                } */}
                {this.state.modalType === "about" && this.state.component.applet && this.state.installation &&
                    <AppletAbout
                        application={this.state.component.applet}
                        installation={this.state.installation}
                        onClose={this.handleAboutClose}
                    />
                }
            </div>
        )
    }

}
