import { ReactElement, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Location } from 'history';
import { ApiOutlined, ApartmentOutlined, CalculatorOutlined, DashboardOutlined, LockOutlined, MonitorOutlined, NodeIndexOutlined, TeamOutlined, BankOutlined, GiftOutlined, AppstoreOutlined, BookOutlined, AlertOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { RouteBuilder } from 'utils/RouteBuilder';
import { EntityContext } from 'context/EntityContext';
import { EnvironmentType } from '@methodset/commons-core-ts';
import { PermissionsHelper } from '@methodset/entity-client-ts';
import './Explorer.less';

// List of which menu items to display in each environment.
// This will be further filtered based on the user's plan.
const featureMap: Map<EnvironmentType, string[]> = new Map([
    [EnvironmentType.DEVELOPMENT, ["accounts", "authentications", "groups", "apis", "models", "datasets", "workflows", "monitor", "dashboards", "notifications", "packages", "documentation"]],
    [EnvironmentType.TEST, ["accounts", "authentications", "groups", "apis", "workflows", "monitor", "dashboards", "notifications", "packages"]],
    [EnvironmentType.PRODUCTION, ["accounts", "authentications", "groups", "apis", "workflows", "monitor", "dashboards", "notifications", "packages"]]
]);

export type ExplorerProps = RouteComponentProps & {}

export const Explorer = (props: ExplorerProps): ReactElement => {

    const context = useContext(EntityContext);
    const history = useHistory();
    const [key, setKey] = useState<string | undefined>();

    const checkKey = (location: Location<any>): false | void => {
        setKey(findKey(location));
    }

    useEffect(() => {
        const unregister = history.listen(checkKey);
        return () => {
            unregister();
        }
    }, []);

    const findKey = (location: Location<any>): string | undefined => {
        const path = location.pathname;
        const expr = `${RouteBuilder.CONSOLE}/([^/]+)`;
        const regex = new RegExp(expr);
        const matches = path.match(regex);
        return !!matches ? matches[1] : undefined;
    }

    // If user has just logged out, context user will be undefined. This page 
    // may get re-rendered prior to the user being re-directed to the home page.
    const environmentType = context.user?.environmentType;
    const features = environmentType ? featureMap.get(environmentType)! : [];
    const perms = PermissionsHelper.to(context.permissions);
    return (
        <Menu
            className="x-explorer"
            theme="light"
            mode="inline"
            selectedKeys={key ? [key] : undefined}
        >
            {features.includes("accounts") && perms.get("feature.account") &&
                <Menu.Item
                    key="accounts"
                    id="accounts"
                    icon={<BankOutlined />}
                    onClick={() => history.push(RouteBuilder.CONSOLE_ACCOUNTS)}
                >
                    <span>Accounts</span>
                </Menu.Item>
            }
            {features.includes("authentications") && perms.get("feature.authentication") &&
                <Menu.Item
                    key="authentications"
                    id="authentications"
                    icon={<LockOutlined />}
                    onClick={() => history.push(RouteBuilder.CONSOLE_CREDENTIALS)}
                >
                    <span>Credentials</span>
                </Menu.Item>
            }
            {features.includes("groups") && perms.get("feature.group") &&
                <Menu.Item
                    key="groups"
                    id="groups"
                    icon={<TeamOutlined />}
                    onClick={() => history.push(RouteBuilder.CONSOLE_GROUPS)}
                >
                    <span>Groups</span>
                </Menu.Item>
            }
            {features.includes("apis") && perms.get("feature.api") &&
                <Menu.Item
                    key="apis"
                    id="apis"
                    icon={<ApiOutlined />}
                    onClick={() => history.push(RouteBuilder.CONSOLE_APIS)}
                >
                    <span>APIs</span>
                </Menu.Item>
            }
            {features.includes("models") && perms.get("feature.model") &&
                <Menu.Item
                    key="models"
                    id="models"
                    icon={<CalculatorOutlined />}
                    onClick={() => history.push(RouteBuilder.CONSOLE_MODELS)}
                >
                    <span>Models</span>
                </Menu.Item>
            }
            {features.includes("datasets") && perms.get("feature.dataset") &&
                <Menu.Item
                    key="datasets"
                    id="datasets"
                    icon={<NodeIndexOutlined />}
                    onClick={() => history.push(RouteBuilder.CONSOLE_DATASETS)}
                >
                    <span>Datasets</span>
                </Menu.Item>
            }
            {features.includes("workflows") && perms.get("feature.workflow") &&
                <Menu.Item
                    key="workflows"
                    id="workflows"
                    icon={<ApartmentOutlined />}
                    onClick={() => history.push(RouteBuilder.CONSOLE_WORKFLOWS)}
                >
                    <span>Workflows</span>
                </Menu.Item>
            }
            {features.includes("monitor") && perms.get("feature.monitor") &&
                <Menu.Item
                    key="monitor"
                    id="monitor"
                    icon={<MonitorOutlined />}
                    onClick={() => history.push(RouteBuilder.CONSOLE_MONITOR)}
                >
                    <span>Monitor</span>
                </Menu.Item>
            }
            {features.includes("packages") && perms.get("feature.package") &&
                <Menu.Item
                    key="packages"
                    id="packages"
                    icon={<GiftOutlined />}
                    onClick={() => history.push(RouteBuilder.CONSOLE_PACKAGES)}
                >
                    <span>Packages</span>
                </Menu.Item>
            }
            <Menu.Divider />
            {features.includes("dashboards") && perms.get("feature.dashboard") &&
                <Menu.Item
                    key="dashboards"
                    id="dashboards"
                    icon={<DashboardOutlined />}
                    onClick={() => history.push(RouteBuilder.CONSOLE_DASHBOARDS)}
                >
                    <span>Dashboards</span>
                </Menu.Item>
            }
            {features.includes("notifications") && perms.get("feature.notification") &&
                <Menu.Item
                    key="notifications"
                    id="notifications"
                    icon={<AlertOutlined />}
                    onClick={() => history.push(RouteBuilder.CONSOLE_NOTIFICATIONS)}
                >
                    <span>Notifications</span>
                </Menu.Item>
            }
            {features.includes("documentation") &&
                <>
                    <Menu.Divider />
                    <Menu.Item
                        key="documentation"
                        id="documentation"
                        icon={<BookOutlined />}
                        onClick={() => window.open(RouteBuilder.MAIN_DOCUMENTATION_OVERVIEW, "_blank")}
                    >
                        <span>Documentation</span>
                    </Menu.Item>
                </>
            }
        </Menu>
    );

}
