import React, { PureComponent, ReactElement } from 'react';
import update from 'immutability-helper';

const isXxl = () => window.innerWidth >= 1400;
const isXl = () => window.innerWidth >= 1200;
const isLg = () => window.innerWidth >= 992;
const isMd = () => window.innerWidth >= 768;
const isSm = () => window.innerWidth >= 576;
const isXs = () => window.innerWidth >= 0;

export type ResizeCallback = () => void;

export interface SizeProps {
    className?: string,
    style?: object
}

export type ResponsiveBoxProps = {
    children: ReactElement,
    className?: string,
    style?: object,
    xs?: SizeProps,
    sm?: SizeProps,
    md?: SizeProps,
    lg?: SizeProps,
    xl?: SizeProps,
    xxl?: SizeProps,
    onResize?: ResizeCallback
}

/**
 * Creates one resize callback on the window. ResponsiveBox 
 * instances can then register their own onResize callbacks.
 */
class Resizer {

    private nextId: number = 1;
    private callbacks: { [key: number]: ResizeCallback } = {};

    constructor() {
        this.nextId = 1;
        this.callbacks = {};
        this.handleResize = this.handleResize.bind(this);
        window.onresize = this.handleResize;
    }

    public addCallback(callback: ResizeCallback): number {
        const id = this.nextId++;
        this.callbacks[id] = callback;
        return id;
    }

    public removeCallback(key: number): void {
        delete this.callbacks[key];
    }

    private handleResize(): void {
        for (const key in this.callbacks) {
            const callback = this.callbacks[key];
            callback();
        }
    }

};

export class ResponsiveBox extends PureComponent<ResponsiveBoxProps> {

    static resizer = new Resizer();

    private id?: number;

    constructor(props: ResponsiveBoxProps) {
        super(props);
        this.handleResize = this.handleResize.bind(this);
    }

    private handleResize(): void {
        this.forceUpdate();
        if (this.props.onResize) {
            this.props.onResize();
        }
    }

    private addProps(size: SizeProps, classNames: string[], styles: object): object {
        const { className, style } = size;
        if (className) {
            classNames.push(className);
        }
        styles = style ?
            update(styles, {
                $merge: style
            }) : styles;
        return styles;
    }

    private addChildProps(childClassName: string | undefined, childStyle: object | undefined, classNames: string[], styles: object): object {
        if (childClassName) {
            classNames.push(childClassName);
        }
        if (childStyle) {
            styles = update(styles, {
                $merge: childStyle
            });
        }
        return styles;
    }

    public componentDidMount(): void {
        this.id = ResponsiveBox.resizer.addCallback(this.handleResize);
    }

    public componentWillUnmount(): void {
        ResponsiveBox.resizer.removeCallback(this.id!);
    }

    public render(): ReactElement {
        const { xs, sm, md, lg, xl, xxl, children, onResize, className, style, ...rest } = this.props;
        const childProps = this.props.children.props;
        let classNames = this.props.className ? [this.props.className] : [];
        let styles = this.props.style ? this.props.style : {};
        styles = this.addChildProps(childProps.className, childProps.style, classNames, styles);
        if (xxl && isXxl()) {
            styles = this.addProps(xxl, classNames, styles);
        } else if (xl && isXl()) {
            styles = this.addProps(xl, classNames, styles);
        } else if (lg && isLg()) {
            styles = this.addProps(lg, classNames, styles);
        } else if (md && isMd()) {
            styles = this.addProps(md, classNames, styles);
        } else if (sm && isSm()) {
            styles = this.addProps(sm, classNames, styles)
        } else if (xs && isXs()) {
            styles = this.addProps(xs, classNames, styles);
        }
        const classes = classNames.join(' ');
        return React.cloneElement(children, { className: classes, style: styles, ...rest });
    }

};
