import { ReactElement } from 'react';
import { Col, Row } from 'antd';
import { Label } from 'components/Label/Label';
import classNames from 'classnames';
import './Chapter.less';

type ChildType = ReactElement | ReactElement[] | string | (ReactElement | string)[];

export type ChapterProps = {
    id?: string,
    className?: string,
    spacing?: number,
    children: ChildType
} & typeof defaultProps;

const defaultProps = {
    spacing: 20
}

/**
 * Defines a set of paragraphs. Each section can be a Section, Paragraph, or a plain element.
 */
export const Chapter = (props: ChapterProps): ReactElement => {

    return (
        <Row id={props.id} className={classNames("x-chapter", props.className)} gutter={[0, props.spacing]}>
            {!Array.isArray(props.children) &&
                <Col span={24}>
                    {props.children}
                </Col>
            }
            {Array.isArray(props.children) && props.children.map((child, index) => (
                <Col key={index} span={24}>
                    {child}
                </Col>
            ))}
        </Row>
    )
}

export type SectionProps = {
    id?: string,
    className?: string,
    title: string,
    children: ChildType
}

/**
 * Defines a paragraph section. A section will have a heading so distinguish it
 * from other paragraphs.
 */
const Section = (props: SectionProps): ReactElement => {
    return (
        <Label id={props.id} className={classNames("x-chapter-section", props.className)} label={props.title} placement="top">
            <Chapter>
                {props.children}
            </Chapter>
        </Label>
    )
}

export type ParagraphProps = {
    id?: string,
    className?: string,
    children: ChildType
}

/**
 * Defines a paragraph, which is a separated block of text.
 */
const Paragraph = (props: ParagraphProps): ReactElement => {
    return (
        <div id={props.id} className={classNames("x-chapter-paragraph", props.className)}>
            {props.children}
        </div>
    )
}

Chapter.defaultProps = defaultProps;
Chapter.Section = Section;
Chapter.Paragraph = Paragraph;
