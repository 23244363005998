import { PureComponent, ReactElement } from 'react';
import { Button, FormInstance, Input } from 'antd';
import { Spacer } from 'components/Spacer/Spacer';
import { DeleteOutlined } from '@ant-design/icons';
import { Calculator, RefType } from '@methodset/calculator-ts';
import { RefEditor } from 'containers/Components/Widgets/RefEditor/RefEditor';
import './InputVariableEditor.less';

export type ChangeCallback = (variableId: string, index: number) => void;
export type RemoveCallback = (index: number) => void;

export type InputVariableEditorProps = {
    formRef: React.RefObject<FormInstance>,
    index: number,
    usedIds?: string[],
    variableId: string,
    calculator: Calculator,
    onChange: ChangeCallback,
    onRemove: RemoveCallback
}

export class InputVariableEditor extends PureComponent<InputVariableEditorProps> {

    constructor(props: InputVariableEditorProps) {
        super(props);
        this.handleVariableChange = this.handleVariableChange.bind(this);
        this.handleVariableRemove = this.handleVariableRemove.bind(this);
    }

    private handleVariableChange(variableId: string | undefined): void {
        this.props.onChange(variableId!, this.props.index);
    }

    private handleVariableRemove(): void {
        this.props.onRemove(this.props.index);
    }

    public render(): ReactElement {
        return (
            <Spacer className="x-inputvariableeditor" fill>
                <RefEditor
                    formRef={this.props.formRef}
                    index={this.props.index}
                    allowClear={false}
                    internalOnly={true}
                    calculator={this.props.calculator}
                    refTypes={[RefType.VARIABLE]}
                    refId={this.props.variableId}
                    onChange={this.handleVariableChange}
                />
                <Button
                    className="x-inputvariableeditor-delete"
                    icon={<DeleteOutlined />}
                    onClick={this.handleVariableRemove}
                >
                </Button>
            </Spacer>
        );
    }

}
