import { ReactElement } from 'react';
import { Globals } from 'constants/Globals';
import { FormInstance, Input, Switch } from 'antd';
import { Income, Pension } from '@methodset/entity-client-ts';
import { FormItem } from 'components/FormItem/FormItem';
import { IntegerInput } from 'components/IntegerInput/IntegerInput';
import { IncomeEditor } from '../../IncomesEditor/IncomeEditor/IncomeEditor';
import update from 'immutability-helper';
import './PensionEditor.less';

export type ChangeCallback = (pension: Pension, index: number) => void;

export type PensionEditorProps = {
    formRef: React.RefObject<FormInstance>,
    id: string,
    index?: number,
    pension?: Pension,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    index: 0,
    pension: {
        costOfLivingAdjustments: false
    } as Pension
}

export const PensionEditor = (props: PensionEditorProps): ReactElement => {

    const handleIncomeChange = (income: Income): void => {
        const pension = update(props.pension, {
            income: { $set: income }
        });
        props.onChange(pension, props.index);
    }

    const handleAnnualContributionChange = (annualContribution: number): void => {
        const pension = update(props.pension, {
            annualContribution: { $set: annualContribution }
        });
        props.onChange(pension, props.index);
    }

    const handleCostOfLivingAdjustmentsChange = (costOfLivingAdjustments: boolean): void => {
        const pension = update(props.pension, {
            costOfLivingAdjustments: { $set: costOfLivingAdjustments }
        });
        props.onChange(pension, props.index);
    }

    return (
        <>
            <IncomeEditor
                formRef={props.formRef}
                id={props.id}
                index={props.index}
                income={props.pension.income}
                onChange={(income) => handleIncomeChange(income)}
            />
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Annual Contribution"
                name={`${props.id}-annual-contribution`}
            >
                <IntegerInput
                    fill={true}
                    placeholder="Annual contribution."
                    value={props.pension.annualContribution}
                    onChange={(value) => handleAnnualContributionChange(value)}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Cost of Living Adjustments"
                name={`${props.id}-cola`}
                valuePropName="checked"
                rules={[{
                    required: true,
                    message: 'Specify if there is a cost of living adjustment.'
                }]}
            >
                <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={props.pension.costOfLivingAdjustments}
                    onChange={(value) => handleCostOfLivingAdjustmentsChange(value)}
                />
            </FormItem>
        </>
    )
}

PensionEditor.defaultProps = defaultProps;
