import { ReactElement, useState } from 'react';
import { Avatar, Tag } from 'antd';
import { Spacer } from 'components/Spacer/Spacer';
import { CoreUtils } from 'utils/CoreUtils';
import { AppletDetails, CategoryType } from '@methodset/application-client-ts';
import { PackHeader } from '@methodset/library-client-ts';
import { Link } from 'react-router-dom';
import { Globals } from 'constants/Globals';
import classNames from 'classnames';
import './PackView.less';

export type DescriptionType = "full" | "summary" | "none";

export type PackViewProps = {
    index?: number,
    className?: string,
    packHeader: PackHeader,
    descriptionType?: DescriptionType
} & typeof defaultProps;

const defaultProps = {
    index: 0,
    descriptionType: "summary"
}

export const PackView = (props: PackViewProps): ReactElement => {

    const [show, setShow] = useState<boolean>(false);

    const handleMoreToggle = () => {
        setShow(!show);
    }

    const buildSummary = (): string => {
        const description = props.packHeader.description;
        if (!description) {
            return "<no description>";
        }
        const index = description.indexOf(".");
        return index == -1 ? description : description.substring(0, index + 1);
    }

    const buildLogo = (): string => {
        return props.packHeader.name[0];
    }

    const color = Globals.BOLD_COLORS[props.index % Globals.BOLD_COLORS.length];
    const details = props.packHeader.details as AppletDetails;
    const category = details.categoryType ? CategoryType[details.categoryType] : CategoryType[CategoryType.GENERAL];
    const summary = props.descriptionType === "summary" ? buildSummary() : null;
    const logo = buildLogo();
    return (
        <div className={classNames("x-packview", props.className)}>
            <Spacer direction="vertical" size="sm">
                <div className="x-packview-wrap">
                    <Spacer alignment="top">
                        <div>
                            <Avatar
                                size={40}
                                shape="square"
                                style={{ color: "white", backgroundColor: color }}
                            >
                                {logo}
                            </Avatar>
                        </div>
                        <div>
                            <div className="x-packview-name">{props.packHeader.name}</div>
                            <div className="x-packview-publisher">{props.packHeader.publisher}</div>
                        </div>
                    </Spacer>
                    {props.descriptionType === "full" &&
                        <>
                            {props.packHeader.description}
                        </>
                    }
                    {summary &&
                        <>
                            <div className="x-packview-description">
                                {!show && <div className="x-packview-summary">{summary}</div>}
                                {show && <div>{props.packHeader.description}</div>}
                            </div>
                            <Link to="#" onClick={() => handleMoreToggle()}>{show ? "Show less" : "Show more"}</Link>
                        </>
                    }
                </div>
                <Tag color="blue">{CoreUtils.toProper(category, "_")}</Tag>
            </Spacer>
        </div>
    )
}

PackView.defaultProps = defaultProps;
