import { ChangeEvent, PureComponent, ReactElement } from 'react';
import { EditOutlined, SyncOutlined, UserDeleteOutlined } from '@ant-design/icons';
import { Button, Space, Tag } from 'antd';
import { Globals } from 'constants/Globals';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { Group, User, UserStatus } from '@methodset/entity-client-ts';
import { RouteComponentProps } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils, Colormap } from 'utils/CoreUtils';
import { EntityContext } from 'context/EntityContext';
import { GroupAddMember } from './GroupAddMember/GroupAddMember';
import { GroupEditor } from './GroupEditor/GroupEditor';
import { Spacer } from 'components/Spacer/Spacer';
import axios from 'axios';
import classNames from 'classnames';
import entityService from 'services/EntityService';
import update from 'immutability-helper';
import './GroupItem.less';
import { StatusType } from 'constants/StatusType';

type MatchParams = {
    groupId: string
}

export type GroupItemState = {
    status: StatusType,
    group: Group,
    isEditing: boolean,
    isAdding: boolean,
    emailAddress?: string
}

export type GroupItemProps = RouteComponentProps<MatchParams> & {
    className?: string
}

export class GroupItem extends PureComponent<GroupItemProps, GroupItemState> {

    static contextType = EntityContext;

    private colorMap: Colormap;

    constructor(props: GroupItemProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            group: {
                users: [] as User[]
            } as Group,
            isEditing: false,
            isAdding: false
        };
        this.colorMap = CoreUtils.toColormap(CoreUtils.enumToKeys(UserStatus));
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
        this.handleEditPropertiesClick = this.handleEditPropertiesClick.bind(this);
        this.handleEditPropertiesDone = this.handleEditPropertiesDone.bind(this);
        this.handleEditPropertiesCancel = this.handleEditPropertiesCancel.bind(this);
        this.handleEnableChange = this.handleEnableChange.bind(this);
        this.handleAddMemberClick = this.handleAddMemberClick.bind(this);
        this.handleAddMemberCancel = this.handleAddMemberCancel.bind(this);
        this.handleAddMemberDone = this.handleAddMemberDone.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleRefreshClick(): void {
        this.loadData();
    }

    private handleEditPropertiesClick(): void {
        this.setState({isEditing: true});
    }

    private handleEditPropertiesDone(group: Group): void {
        if (group.id === this.context.group.id) {
            this.context.saveGroup(group);
        }
        group = update(group, {
            users: { $set: this.state.group.users }
        })
        this.setState({
            group: group,
            isEditing: false
        });
    }

    private handleEditPropertiesCancel(): void {
        this.setState({isEditing: false});
    }

    private handleEnableChange(e: CheckboxChangeEvent): void {
        const userId = e.target.id as string;
        const isEnabled = e.target.checked;
        this.updateUserEnabledRequest(userId, isEnabled);
    }

    private handleAddMemberClick(): void {
        this.setState({ isAdding: true });
    }

    private handleAddMemberCancel(): void {
        this.setState({ isAdding: false });
    }

    private handleAddMemberDone(user: User): void {
        const group = update(this.state.group, {
            users: {
                $push: [user]
            }
        });
        this.setState({
            group: group,
            isAdding: false
        });
    }

    private handleDeleteClick(user: any): void {
        const userId = user.id;
        const groupId = this.state.group.id;
        this.removeUserFromGroupRequest(groupId, userId);
    }

    private handleEmailChange(e: ChangeEvent<HTMLInputElement>): void {
        const emailAddress = e.target.value;
        this.setState({ emailAddress: emailAddress });
    }

    private removeUserFromGroupRequest(groupId: string, userId: string): Promise<any> {
        const request = {
            groupId: groupId,
            userId: userId
        };
        return entityService.removeGroupMember(request,
            (response: any) => this.removeUserFromGroupResponse(response),
            undefined, false
        );
    }

    private removeUserFromGroupResponse(response: any): void {
        const userId = response.data.userId;
        const index = this.state.group.users.findIndex(user => user.id === userId);
        const group = update(this.state.group, {
            users: {
                $splice: [[index, 1]]
            }
        });
        this.setState({ group: group });
    }

    private readGroupRequest(): Promise<any> {
        const groupId = this.props.match.params.groupId;
        const request = {
            groupId: groupId
        };
        return entityService.readGroup(request,
            (response: any) => this.readGroupResponse(response),
            undefined, true
        );
    }

    private readGroupResponse(response: any): void {
        const group = response.data.group;
        this.setState({ group: group });
    }

    private updateUserEnabledRequest(userId: string, isEnabled: boolean): Promise<any> {
        const request = {
            userId: userId,
            isEnabled: isEnabled
        };
        return entityService.updateUserEnabled(request,
            (response: any) => this.updateUserEnabledResponse(response),
            undefined, false
        );
    }

    private updateUserEnabledResponse(response: any): void {
        const userId = response.data.userId;
        const isEnabled = response.data.isEnabled;
        const index = this.state.group.users.findIndex(user => user.id === userId);
        const group = update(this.state.group, {
            users: {
                [index]: {
                    isEnabled: { $set: isEnabled }
                }
            }
        });
        this.setState({ group: group });
    }

    private buildData(): User[] {
        return this.state.group.users;
    }

    private buildColumns(): ColumnsType<any> {
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Member Name',
            render: (user) => {
                return (
                    <Spacer size="lg">
                        <span>{user.fullName}</span>
                        {user.id === this.state.group.creatorId &&
                            <Tag color="green">Owner</Tag>
                        }
                    </Spacer>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.lastName, b.lastName),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        // }, {
        //     key: 'enabled',
        //     title: 'Enabled',
        //     align: 'center',
        //     dataIndex: 'isEnabled',
        //     render: (value, record) => {
        //         return (
        //             <Checkbox
        //                 id={record.id}
        //                 checked={value}
        //             />
        //         );
        //     }
        }, {
            key: 'email',
            title: 'Email',
            render: (user) => {
                return (
                    <span>{user.emailAddress}</span>
                );
            }
        }, {
            key: 'status',
            title: 'Status',
            align: 'center',
            render: (user) => {
                return (
                    <Tag color={this.colorMap[user.userStatus]}>{CoreUtils.toProper(user.userStatus, '_', ' ')}</Tag>
                );
            }
        }, {
            key: 'ctime',
            title: 'Create Time',
            align: 'center',
            render: (user) => {
                return (
                    <span>
                        {CoreUtils.toTime(user.createTime)}
                    </span>
                );
            }
        }];
        return columns;
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readGroupRequest());
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== StatusType.READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        const actions = [{
            icon: <UserDeleteOutlined />,
            label: "Remove member",
            confirm: "Are you sure you want to remove the group member?",
            disabled: (user: User) => {
                return this.context.user?.id === user.id || this.context.user?.id !== this.state.group.creatorId;
            },
            callback: this.handleDeleteClick
        }];
        const columns = this.buildColumns();
        const data = this.buildData();
        return (
            <>
                <ItemTable
                    className={classNames('x-groupitem', this.props.className)}
                    title={this.state.group.name ? this.state.group.name : "Group"}
                    status={this.state.status}
                    columns={columns}
                    items={data}
                    extra={
                        <Space>
                            <Button onClick={this.handleAddMemberClick}>Add Member</Button>
                            <Button icon={<EditOutlined />} onClick={this.handleEditPropertiesClick}></Button>
                            <Button icon={<SyncOutlined />} onClick={this.handleRefreshClick}></Button>
                        </Space>
                    }
                    actions={actions}
                    onLoad={this.handleRetryLoad}
                />
                {this.state.isAdding &&
                    <GroupAddMember
                        group={this.state.group}
                        onAdded={this.handleAddMemberDone}
                        onCancel={this.handleAddMemberCancel}
                    />
                }
                {this.state.isEditing &&
                    <GroupEditor
                        group={this.state.group}
                        onDone={this.handleEditPropertiesDone}
                        onCancel={this.handleEditPropertiesCancel}
                    />
                }

            </>
        );
    }

}
