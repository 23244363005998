import { ReactElement } from 'react';
import { Calculator, NumberFormat } from '@methodset/calculator-ts';
import './FormattedInput.less';

export type FormattedInputProps = {
    className?: string,
    value?: any,
    useVariableValue?: boolean,
    variableId?: string,
    calculator: Calculator
}

export const FormattedInput = (props: FormattedInputProps): ReactElement => {

    const buildView = (): ReactElement => {
        let variable;
        let format;
        if (props.variableId) {
            variable = props.calculator.variables.get(props.variableId, false);
            format = variable?.cell.format;
        }
        const value = props.useVariableValue && variable && !variable.cell.isEmpty() ? variable.cell.value : props.value;
        if (format && format.type === "number") {
            const numberFormat = format as NumberFormat;
            return (
                <>
                    {numberFormat?.prefix &&
                        <span>{numberFormat?.prefix}</span>
                    }
                    <span>{value}</span>
                    {numberFormat?.postfix &&
                        <span>{numberFormat?.postfix}</span>
                    }
                </>
            )
        } else {
            return <>{value}</>;
        }
    }

    return (
        <span className={props.className}>
            {buildView()}
        </span>
    )

}
