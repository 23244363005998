import { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { Button } from 'antd';
import { Cell, ReferenceParser, Sheet } from '@methodset/calculator-ts';
import { ActiveCell, ModelContext } from 'context/ModelContext';
import { Label } from 'components/Label/Label';
import { EmitterEvent } from 'utils/EventEmitter';
import './CellAction.less';

export type AddCallback = (cell: Cell | undefined) => void;
export type EditCallback = (cell: Cell) => void;

export type ActionType = "edit" | "add" | "none";
export type TypeFunction = (cell?: Cell) => ActionType;

export type CellActionProps = {
    fn: TypeFunction,
    onAdd: AddCallback,
    onEdit: EditCallback
}

export const CellAction: FC<CellActionProps> = (props: CellActionProps): ReactElement => {

    const context = useContext(ModelContext);

    const [activeCell, setActiveCell] = useState<ActiveCell>();

    const handleCellFocusChangeEvent = (event: EmitterEvent): void => {
        setActiveCell(event.data);
    }

    useEffect(() => {
        setActiveCell(context.active.cell);
        context.addCallback!("CellFocusChange", handleCellFocusChangeEvent);
        return () => {
            context.removeCallback!("CellFocusChange", handleCellFocusChangeEvent);
        }
    }, []);

    const handleAddClick = (cell: Cell): void => {
        props.onAdd(cell);
    }

    const handleEditClick = (cell: Cell): void => {
        props.onEdit(cell);
    }

    const buildView = (): ReactElement => {
        if (!activeCell) {
            return (
                <span>No cell active.</span>
            )
        }
        const sheet = activeCell.sheet;
        const cellId = activeCell.id;
        const refId = activeCell.variableId ? activeCell.variableId : `${ReferenceParser.quote(sheet.id)}!${cellId}`;
        const cell = sheet.getCell(cellId, false);
        const action = props.fn(cell);
        return (
            <div className="x-cellaction-view">
                <div>{refId}</div>
                {action === "add" &&
                    <Button onClick={() => handleAddClick(cell)}>
                        Add
                    </Button>
                }
                {action === "edit" &&
                    <Button onClick={() => handleEditClick(cell)}>
                        Edit
                    </Button>
                }
                {action === "none" &&
                    <Button disabled={true}>
                        Add
                    </Button>
                }
            </div>
        )
    }

    return (
        <div className="x-cellaction">
            <Label label="Active Cell" placement="top">
                {buildView()}
            </Label>
        </div>
    )

}
