import { PureComponent, ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Col, Row } from 'antd';
import { ChangeEmail } from './ChangeEmail/ChangeEmail';
import { ChangeEmailConfirm } from './ChangeEmailConfirm/ChangeEmailConfirm';
import { ChangePassword } from './ChangePassword/ChangePassword';
import { EditProfile } from './EditProfile/EditProfile';
import { Login } from './Login/Login';
import { PageNotFound } from 'containers/Components/PageNotFound/PageNotFound';
import { Register } from './Register/Register';
import { RegisterConfirm } from './RegisterConfirm/RegisterConfirm';
import { RegisterResend } from './RegisterResend/RegisterResend';
import { ResetPassword } from './ResetPassword/ResetPassword';
import { ResetPasswordConfirm } from './ResetPasswordConfirm/ResetPasswordConfirm';
import { RouteBuilder } from 'utils/RouteBuilder';
//import { Organization } from './Organization/Organization';
import serviceWarmer from 'utils/ServiceWarmer';
import computer_gear_image from 'images/computer_gear.png';
import './User.less';

export type UserProps = {}

export class User extends PureComponent<UserProps> {

    constructor(props: UserProps) {
        super(props);
        serviceWarmer.start();
    }

    public componentWillUnmount(): void {
        serviceWarmer.stop();
    }

    public render(): ReactElement {
        return (
            <Row className="x-user" gutter={[0, 32]}>
                <Col
                    lg={{ span: 14, order: 1 }}
                    xs={{ span: 24, order: 2 }}
                >
                    <img className="x-user-content" src={computer_gear_image} alt="computer_gear" />
                </Col>
                <Col
                    lg={{ span: 10, order: 2 }}
                    xs={{ span: 24, order: 1 }}
                >
                    <div className="x-user-content">
                        <Switch>
                            <Route exact path={RouteBuilder.MAIN_LOGIN} component={Login} />
                            <Route exact path={RouteBuilder.MAIN_REGISTER} component={Register} />
                            <Route exact path={RouteBuilder.MAIN_REGISTER_CONFIRM} component={RegisterConfirm} />
                            <Route exact path={RouteBuilder.MAIN_REGISTER_RESEND} component={RegisterResend} />
                            {/* <Route exact path={RouteBuilder.MAIN_ORGANIZATION} component={Organization} /> */}
                            <Route exact path={RouteBuilder.MAIN_CHANGE_PASSWORD} component={ChangePassword} />
                            <Route exact path={RouteBuilder.MAIN_CHANGE_EMAIL} component={ChangeEmail} />
                            <Route exact path={RouteBuilder.MAIN_CHANGE_EMAIL_CONFIRM} component={ChangeEmailConfirm} />
                            <Route exact path={RouteBuilder.MAIN_RESET_PASSWORD} component={ResetPassword} />
                            <Route exact path={RouteBuilder.MAIN_RESET_PASSWORD_CONFIRM} component={ResetPasswordConfirm} />
                            <Route exact path={RouteBuilder.MAIN_EDIT_PROFILE} component={EditProfile} />
                            <Route component={PageNotFound} />
                        </Switch>
                    </div>
                </Col>
            </Row>
        );
    }

}
