import { ReactElement } from 'react';
import classNames from 'classnames';
import './TextMessage.less';

export type MessageType = "normal" | "warning" | "error";
export type MessageSize = "xxs" | "xs" | "xm" | "med" | "lg" | "xl" | "xxl";

export type TextMessageProps = {
    className?: string,
    type?: MessageType,
    size?: MessageSize,
    fill?: boolean,
    bold?: boolean,
    italic?: boolean,
    message?: string
} & typeof defaultProps;

const defaultProps = {
    type: "normal",
    size: "med",
    fill: false,
    bold: false,
    italic: false
}

export const TextMessage = (props: TextMessageProps): ReactElement => {

    return (
        <div
            className={
                classNames(
                    props.className,
                    `x-textmessage-${props.type}`,
                    `x-textmessage-${props.size}`,
                    { "x-textmessage-fill": props.fill },
                    { "x-textmessage-bold": props.bold },
                    { "x-textmessage-italic": props.italic }
                )
            }
        >
            {props.message}
        </div>
    )

}

TextMessage.defaultProps = defaultProps;