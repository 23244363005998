import { PureComponent, ReactElement } from 'react';
import { SyncOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Button, Select, Space, Tag } from 'antd';
import { Globals } from 'constants/Globals';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { Label } from 'components/Label/Label';
import { RouteBuilder } from 'utils/RouteBuilder';
import { StatisticsSet, TriggerType } from '@methodset/workflow-client-ts';
import { ColumnsType } from 'antd/lib/table';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils, Colormap } from 'utils/CoreUtils';
import { Spacer } from 'components/Spacer/Spacer';
import axios from 'axios';
import classNames from 'classnames';
import workflowService from 'services/WorkflowService';
import './Monitor.less';
import { StatusType } from 'constants/StatusType';

const COUNTS_MAP: { [key: string]: number[] } = {
    YEAR: [1],
    MONTH: Array.from({ length: 12 }, (_, i) => i + 1),
    WEEK: Array.from({ length: 4 }, (_, i) => i + 1),
    DAY: Array.from({ length: 31 }, (_, i) => i + 1),
    HOUR: Array.from({ length: 24 }, (_, i) => i + 1),
    MINUTE: [10, 20, 30, 40, 50]
};

type OffsetType = "PERIOD" | "ABSOLUTE";

enum PeriodType {
    YEAR = "YEAR",
    MONTH = "MONTH",
    WEEK = "WEEK",
    DAY = "DAY",
    HOUR = "HOUR",
    MINUTE = "MINUTE",
    SECOND = "SECOND"
}

export type MonitorState = {
    status: StatusType,
    statistics: StatisticsSet[],
    periodCount: number,
    periodType: PeriodType,
    periodOffset: OffsetType
}

export type MonitorProps = {
    className?: string
}

export class Monitor extends PureComponent<MonitorProps, MonitorState> {

    private colorMap: Colormap;

    constructor(props: MonitorProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            statistics: [] as StatisticsSet[],
            periodCount: 4,
            periodType: PeriodType.WEEK,
            periodOffset: "PERIOD"
        };
        this.colorMap = CoreUtils.toColormap([TriggerType.SCHEDULE, TriggerType.EMAIL, TriggerType.FILE]);
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleCountChange = this.handleCountChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleOffsetChange = this.handleOffsetChange.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleCountChange(periodCount: number): void {
        this.setState({ periodCount: periodCount });
    }

    private handleTypeChange(periodType: PeriodType): void {
        this.setState({
            periodType: periodType,
            periodCount: COUNTS_MAP[periodType as string][0]
        });
    }

    private handleOffsetChange(periodOffset: OffsetType): void {
        this.setState({ periodOffset: periodOffset });
    }

    private handleRefreshClick(): void {
        this.loadData();
    }

    private zoneOffset(): string {
        let offset = new Date().getTimezoneOffset();
        // Sign is opposite of convention.
        const sign = offset > 0 ? '-' : '+';
        offset = Math.abs(offset);
        const hours = offset / 60;
        const mins = offset % 60;
        const mzero = mins < 10 ? '0' : '';
        const hzero = hours < 10 ? '0' : '';
        return `${sign}${hzero}${hours}:${mzero}${mins}`;
    }

    private readStatisticsSetsRequest(): Promise<any> {
        const request = {
            periodCount: this.state.periodCount,
            periodType: this.state.periodType.toLowerCase(),
            periodOffset: this.state.periodOffset.toLowerCase(),
            zoneOffset: this.zoneOffset()
        };
        return workflowService.readStatisticsSets(request,
            (response: any) => this.readStatisticsSetsResponse(response),
            undefined, true
        );
    }

    private readStatisticsSetsResponse(response: any): void {
        const statistics = response.data.statisticsSets;
        this.setState({ statistics: statistics });
    }

    private buildColumns(): ColumnsType<any> {
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Workflow Name',
            dataIndex: 'workflowName',
            render: (value, record) => {
                return (
                    <Link to={RouteBuilder.executions(record.workflowId)}>{value}</Link>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.workflowName, b.workflowName),
            sortDirections: ['descend', 'ascend'],
        }, {
            key: 'type',
            title: 'Trigger',
            align: 'center',
            dataIndex: 'triggerType',
            render: (value, record) => {
                return (
                    <Tag color={this.colorMap[value]}>{CoreUtils.toProper(value, '_', ' ')}</Tag>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.triggerType, b.triggerType),
            sortDirections: ['ascend', 'descend']
        }, {
            key: 'started',
            title: 'Started',
            align: 'center',
            dataIndex: 'startedCount',
            render: (value, record) => {
                return <div className="x-monitor-started">{value}</div>
            }
        }, {
            key: 'succeeded',
            title: 'Succeeded',
            align: 'center',
            dataIndex: 'succeededCount',
            render: (value, record) => {
                return <div className="x-monitor-succeeded">{value}</div>
            }
        }, {
            key: 'failed',
            title: 'Failed',
            align: 'center',
            dataIndex: 'failedCount',
            render: (value, record) => {
                return <div className="x-monitor-failed">{value}</div>
            }
        }, {
            key: 'aborted',
            title: 'Aborted',
            align: 'center',
            dataIndex: 'abortedCount',
            render: (value, record) => {
                return <div className="x-monitor-aborted">{value}</div>
            }
        }, {
            key: 'timedout',
            title: 'Timed Out',
            align: 'center',
            dataIndex: 'timedOutCount',
            render: (value, record) => {
                return <div className="x-monitor-timedout">{value}</div>
            }
        }, {
            key: 'status',
            title: 'Last Status',
            align: 'center',
            dataIndex: 'lastStatusType',
            render: (value, record) => {
                return (
                    <span className={`x-monitor-${value.toLowerCase()}`}>{CoreUtils.toCapital(value)}</span>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.lastStatusType, b.lastStatusType),
            sortDirections: ['ascend', 'descend']
        }, {
            key: 'etime',
            title: 'Last Execution',
            align: 'center',
            dataIndex: 'lastExecutionTime',
            render: (value, record) => {
                return (
                    <span>
                        {CoreUtils.toTime(value)}
                    </span>
                );
            },
            sorter: (a, b) => CoreUtils.compareNumbers(a.lastExecutionTime, b.lastExecutionTime),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend'
        }];
        return columns;
    }

    private buildData(): StatisticsSet[] {
        return this.state.statistics;
    }

    private buildExtra() {
        return (
            <Spacer>
                <Label
                    className="x-monitor-label"
                    label="Time Window"
                    info="The window of time for which to get statistics. The end boundary is the current time. 
                          The start time is based on the time window and start boundary setting."
                    bold={false}
                    location="bottomRight"
                >
                    <Spacer>
                        <Select
                            className="x-monitor-count"
                            value={this.state.periodCount}
                            onChange={this.handleCountChange}
                        >
                            {COUNTS_MAP[this.state.periodType].map((i) => (
                                <Select.Option key={i} value={i}>{i}</Select.Option>
                            ))}
                        </Select>
                        <Select
                            className="x-monitor-type"
                            value={this.state.periodType}
                            onChange={this.handleTypeChange}
                        >
                            <Select.Option value={PeriodType.YEAR}>Years</Select.Option>
                            <Select.Option value={PeriodType.MONTH}>Months</Select.Option>
                            <Select.Option value={PeriodType.WEEK}>Weeks</Select.Option>
                            <Select.Option value={PeriodType.DAY}>Days</Select.Option>
                            <Select.Option value={PeriodType.HOUR}>Hours</Select.Option>
                            <Select.Option value={PeriodType.MINUTE}>Minutes</Select.Option>
                        </Select>
                    </Spacer>
                </Label>
                <Label
                    className="x-monitor-label"
                    label="Start Boundary"
                    info="Describes how to interpret the time window start boundary. 'Period' means to go back to the start 
                          of the period. For example '1 Day' means to go back to the start of the current day. '4 Weeks' 
                          means to go back to the beginning of the current week and then back 3 more weeks. 'Absolute' means to 
                          go back the full time window amount. For example, '1 Day' means to go back 24 hours and '4 Weeks'
                          means to go back 28 days."
                    bold={false}
                    location="bottomRight"
                >
                    <Select
                        className="x-monitor-offset"
                        value={this.state.periodOffset}
                        onChange={this.handleOffsetChange}
                    >
                        <Select.Option value="PERIOD">Period</Select.Option>
                        <Select.Option value="ABSOLUTE">Absolute</Select.Option>
                    </Select>
                </Label>
                <Button icon={<SyncOutlined />} onClick={this.handleRefreshClick} />
            </Spacer>
        );
    }

    private loadData() {
        const requests = [];
        requests.push(this.readStatisticsSetsRequest());
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        const columns = this.buildColumns();
        const data = this.buildData();
        const extra = this.buildExtra();
        return (
            <ItemTable
                className={classNames('x-monitor', this.props.className)}
                title="Monitor"
                rowKey="workflowId"
                status={this.state.status}
                columns={columns}
                items={data}
                extra={extra}
                onLoad={this.handleRetryLoad}
            />
        );
    }

}
