import { Globals } from 'constants/Globals';
import { CoreUtils } from 'utils/CoreUtils';
import { FailureHandler, RestUtils, SuccessHandler } from 'utils/RestUtils';

class ApplicationService {

    public baseUrl = CoreUtils.apiUrl('application-service', 14015);

    public warm(): Promise<any> {
        const url = `${this.baseUrl}/warm`;
        return RestUtils.get(url, undefined, undefined, undefined, undefined, false);
    }

    public createDashboard(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/dashboards`;
        return RestUtils.post(url, request, onSuccess, onFailure, ownStatus, Globals.MESSAGE_SAVING);
    }

    public readDashboardHeaders(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/dashboards`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public readDashboard(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/dashboards/${request.dashboardId}`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public updateDashboard(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/dashboards/${request.dashboardId}`;
        return RestUtils.put(url, request, onSuccess, onFailure, ownStatus, Globals.MESSAGE_SAVING);
    }

    public deleteDashboard(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/dashboards/${request.dashboardId}`;
        return RestUtils.delete(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_DELETING);
    }

    public readNotificationHeaders(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const parameters = {
            key: request.startKey,
            limit: request.maxRecords,
            reverse: request.reverseScan
        };
        const url = `${this.baseUrl}/notifications${RestUtils.buildQuery(parameters)}`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public readNotification(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/notifications/${request.notificationId}`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public updateNotificationState(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/notifications/${request.notificationId}`;
        return RestUtils.put(url, request, onSuccess, onFailure, ownStatus, Globals.MESSAGE_SAVING);
    }

    public deleteNotification(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/notifications/${request.notificationId}`;
        return RestUtils.delete(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_DELETING);
    }

    public createApplication(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/applications`;
        return RestUtils.post(url, request, onSuccess, onFailure, ownStatus, Globals.MESSAGE_CREATING);
    }

    public readApplicationHeaders(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const parameters = {
            type: CoreUtils.toLower(request.applicationType),
            module: request.moduleId
        };
        const url = `${this.baseUrl}/applications/access/${request.accessType.toLowerCase()}/headers${RestUtils.buildQuery(parameters)}`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public readModelApplications(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/applications/model/${request.modelId}`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public loadApplication(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/applications/${request.applicationId}/versions/${request.version}/load`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public updateApplication(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/applications/${request.application.id}`;
        return RestUtils.put(url, request, onSuccess, onFailure, ownStatus, Globals.MESSAGE_SAVING);
    }

    public deleteApplication(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/applications/${request.applicationId}/versions/${request.version}`;
        return RestUtils.delete(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_DELETING);
    }

    public archiveApplication(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/applications/${request.applicationId}`;
        return RestUtils.delete(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_DELETING);
    }

    public createBoard(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/boards`;
        return RestUtils.post(url, request, onSuccess, onFailure, ownStatus, Globals.MESSAGE_SAVING);
    }

    public readBoards(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/boards`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public updateBoard(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/boards`;
        return RestUtils.put(url, request, onSuccess, onFailure, ownStatus, Globals.MESSAGE_SAVING);
    }

    public deleteBoard(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/boards/${request.boardId}`;
        return RestUtils.delete(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_DELETING);
    }

    public loadBoard(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/boards/${request.boardId}/load`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    // public createInstallation(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
    //     const url = `${this.baseUrl}/installations`;
    //     return RestUtils.post(url, request, onSuccess, onFailure, ownStatus, Globals.MESSAGE_SAVING);
    // }

    // public upsertInstallation(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
    //     const url = `${this.baseUrl}/installations`;
    //     return RestUtils.put(url, request, onSuccess, onFailure, ownStatus, Globals.MESSAGE_SAVING);
    // }

    public readInstallations(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const parameters = {
            key: request.startKey,
            limit: request.maxRecords
        };
        const url = `${this.baseUrl}/installations/types/${CoreUtils.toLower(request.applicationType)}/full${RestUtils.buildQuery(parameters)}`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public readInstallationHeaders(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const parameters = {
            key: request.startKey,
            limit: request.maxRecords
        };
        const url = `${this.baseUrl}/installations/types/${CoreUtils.toLower(request.applicationType)}${RestUtils.buildQuery(parameters)}`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public readInstallation(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/installations/${request.installationId}`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    // public updateInstallation(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
    //     const url = `${this.baseUrl}/installations/${request.installationId}`;
    //     return RestUtils.put(url, request, onSuccess, onFailure, ownStatus, Globals.MESSAGE_SAVING);
    // }

    public readInstallationState(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/installations/${request.installationId}/state`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public updateInstallationState(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/installations/${request.installationId}/state`;
        return RestUtils.put(url, request, onSuccess, onFailure, ownStatus, Globals.MESSAGE_SAVING);
    }

    public deleteInstallation(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/installations/${request.installationId}`;
        return RestUtils.delete(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_DELETING);
    }

    public readAlertState(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/alerts/${request.installationId}/state`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    // public readDeploymentHeaders(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
    //     const parameters = {
    //         type: CoreUtils.toLower(request.applicationType)
    //     };
    //     const url = `${this.baseUrl}/applications/models/${request.modelId}/headers${RestUtils.buildQuery(parameters)}`;
    //     return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    // }

    public installApplet(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/dashboards/${request.dashboardId}/applets`;
        return RestUtils.post(url, request, onSuccess, onFailure, ownStatus, Globals.MESSAGE_ADDING);
    }

    public uninstallApplet(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/dashboards/${request.dashboardId}/applets/${request.installationId}`;
        return RestUtils.delete(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_REMOVING);
    }

    public installAlert(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/alerts`;
        return RestUtils.post(url, request, onSuccess, onFailure, ownStatus, Globals.MESSAGE_ADDING);
    }

    public uninstallAlert(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/alerts/${request.installationId}`;
        return RestUtils.delete(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_REMOVING);
    }

}

export default new ApplicationService();
