import { KeyboardEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { FilterInput, FilterInputProps, Justification, TestFunction } from 'components/FilterInput/FilterInput';
import { CoreUtils } from 'utils/CoreUtils';
import { Alignment } from 'components/Spacer/Spacer';
import classNames from 'classnames';
import './NumberInput.less';

export type ConvertFunction = (value: string) => number;
export type ChangeCallback = (value: number) => void;

export type NumberInputProps = Omit<FilterInputProps, "postfix" | "onKey" | "onBlur" | "onFocus" | "onChange"> & {
    fill?: boolean,
    min?: number,
    max?: number,
    units?: string,
    alignment?: Alignment,
    justification?: Justification,
    tester: TestFunction,
    converter: ConvertFunction,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
}

/**
 * This is a base class for IntegerInput and DecimalInput.
 */
export const NumberInput = (props: NumberInputProps): ReactElement => {

    const [text, setText] = useState<any>(props.value);
    const prevValue = useRef<any>(props.value);

    useEffect(() => {
        // If a new value is passed as props (changed externally),
        // sync with the external value by resetting the current.
        if (props.value !== prevValue.current) {
            prevValue.current = props.value;
            setText(props.value);
        }
    }, [props.value]);
    
    const handleKey = (e: KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'Escape') {
            // Reset to the original value.
            setText(props.value);
            sendValue(props.value);
            e.stopPropagation();
        } else if (e.key === 'Enter' || e.key === 'Tab') {
            sendValue(text);
        }
    }

    const handleBlur = (): void => {
        sendValue(text);
    }

    const handleChange = (value: string | undefined): void => {
        setText(value);
    }

    const sendValue = (value: string | undefined): void => {
        if (CoreUtils.isEmpty(value) || value === "" || value === "-" || value === "+") {
            props.onChange(undefined as any);
        } else {
            let num = props.converter(value!);
            if (CoreUtils.isNumber(props.min) && num < props.min!) {
                num = props.min as number;
                setText(num.toString());
            } else if (CoreUtils.isNumber(props.max) && num < props.max!) {
                num = props.max as number;
                setText(num.toString());
            }
            props.onChange(num);
        }
    }

    const { fill, units, value, className, converter, onChange, ...rest } = props;

    return (
        <div className={classNames("x-numberinput", props.className, {"x-numberinput-fill": fill})}>
            <FilterInput
                {...rest}
                value={text}
                postfix={units}
                onKey={(e) => handleKey(e)}
                onBlur={() => handleBlur()}
                onChange={(value) => handleChange(value)}
            />
        </div>
    );

}

NumberInput.defaultProps = defaultProps;
