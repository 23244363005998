import { MouseEvent, ReactElement, useState } from 'react';
import { Avatar, Tag } from 'antd';
import { Spacer } from 'components/Spacer/Spacer';
import { CoreUtils } from 'utils/CoreUtils';
import { Link } from 'react-router-dom';
import { Globals } from 'constants/Globals';
import { ApplicationHeader, CategoryType } from '@methodset/application-client-ts';
import classNames from 'classnames';
import './ApplicationView.less';
import { Label } from 'components/Label/Label';

export type DescriptionType = "full" | "summary" | "none";

export type ApplicationViewProps = {
    index?: number,
    className?: string,
    type?: DescriptionType,
    header: ApplicationHeader
} & typeof defaultProps;

const defaultProps = {
    index: 0,
    descriptionType: "summary"
}

export const ApplicationView = (props: ApplicationViewProps): ReactElement => {

    const [show, setShow] = useState<boolean>(false);

    const handleMoreToggle = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setShow(!show);
    }

    const buildSummary = (): string | null => {
        const description = props.header.description;
        if (!description) {
            return null;
        }
        const index = description.indexOf(".");
        return index == -1 ? description : description.substring(0, index + 1);
    }

    const buildLogo = (): string => {
        return props.header.name[0];
    }

    const color = Globals.BOLD_COLORS[props.index % Globals.BOLD_COLORS.length];
    const category = props.header.categoryType ? CategoryType[props.header.categoryType] : CategoryType[CategoryType.GENERAL];
    const summary = props.type === "summary" ? buildSummary() : null;
    const logo = buildLogo();
    return (
        <div className={classNames("x-applicationview", props.className)}>
            <Spacer direction="vertical" size="sm">
                <div className="x-applicationview-wrap">
                    <Spacer className="x-applicationview-head" alignment="top">
                        <div>
                            <Avatar
                                size={40}
                                shape="square"
                                style={{ color: "white", backgroundColor: color }}
                            >
                                {logo}
                            </Avatar>
                        </div>
                        <div>
                            <div className="x-applicationview-name">{props.header.name}</div>
                            <div className="x-applicationview-publisher">{props.header.publisher}</div>
                        </div>
                    </Spacer>
                    {props.type === "full" &&
                        <>
                            {props.header.description}
                        </>
                    }
                    {props.type === "summary" &&
                        <>
                            <div className="x-applicationview-description">
                                {!show && <div className="x-applicationview-summary">{summary}</div>}
                                {show && <div>{props.header.description}</div>}
                            </div>
                            <Link className="x-applicationview-link" to="#" onClick={(e) => handleMoreToggle(e)}>{show ? "Show less" : "Show more"}</Link>
                        </>
                    }
                </div>
                <Tag color="blue">{CoreUtils.toProper(category, "_")}</Tag>
                <div>
                    {props.type === "full" &&
                        <Label label="Version">{CoreUtils.toVersion(props.header.version)}</Label>
                    }
                    <Label label="Updated">{CoreUtils.toTime(props.header.updateTime)}</Label>
                </div>
            </Spacer>
        </div>
    )
}

ApplicationView.defaultProps = defaultProps;
