import React, { Component as ReactComponent, ReactElement } from 'react';
import { Col, FormInstance, Row } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { Component, ConverterMap, Flow, InputSpec, IoType, Link, OutputPath, PathType, Processor } from '@methodset/endpoint-client-ts';
import { ArrowRightOutlined, LinkOutlined } from '@ant-design/icons';
import { RestUtils } from 'utils/RestUtils';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { Label } from 'components/Label/Label';
import { Panel } from 'components/Panel/Panel';
import { ComponentMap } from '../../Flows';
import { OutputPathsEditor } from './OutputPathsEditor/OutputPathsEditor';
import { OutputComponents } from '../FlowEditor';
import endpointService from 'services/EndpointService';
import axios from 'axios';
import update from 'immutability-helper';
import './IoMapEditor.less';

// Maps component ids to processors.
export type CompProcMap = { [key: string]: Processor };

// Map of input key to compatible output keys.
export type KeyMap = { [key: string]: Set<string> };
export type KeySet = KeyMap[];

export type ChangeCallback = (link: Link) => void;

export type IoMapEditorProps = typeof IoMapEditor.defaultProps & {
    // Reference to form object.
    formRef: React.RefObject<FormInstance>,
    // Class to style the form.
    className?: string,
    // Index if there is more than one io-map editor.
    index?: number,
    // Show the editor.
    visible?: boolean,
    // The parent flow that contains the processes the mapping is part of.
    parentFlow?: Flow,
    // The link to edit.
    link: Link,
    // The map of converters.
    converterMap: ConverterMap,
    // A map of id to components.
    componentMap: ComponentMap;
    // The output components from which output can be selected.
    outputComponents: OutputComponents,
    // Called when map is changed.
    onChange: ChangeCallback
}

export type IoMapEditorState = {
    status: string,
    inputProcessor?: Processor,
    outputProcessors?: Processor[],
}

export class IoMapEditor extends ReactComponent<IoMapEditorProps, IoMapEditorState> {

    static defaultProps = {
        index: 0,
        visible: true
    }

    private keySet: KeySet | null = null;
    private compProcMap: CompProcMap = {};

    constructor(props: IoMapEditorProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleOutputPathsChange = this.handleOutputPathsChange.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData(this.props.link.id, this.props.outputComponents.components, false);
    }

    private handleOutputPathsChange(inputSpec: InputSpec, outputPaths: OutputPath[]): void {
        const inputKey = inputSpec.key;
        const pathType = this.findPathType(inputSpec);
        const link = update(this.props.link, {
            ioMap: {
                [inputKey]: {
                    pathType: { $set: pathType },
                    outputPaths: { $set: outputPaths }
                }
            }
        });
        this.props.onChange(link);
    }

    private buildCompProcMap(components: Component[]): CompProcMap {
        const map: CompProcMap = {};
        for (const component of components) {
            const processor = this.state.outputProcessors!.find(processor => processor.id === component.processor.id)!;
            map[component.id] = processor;
        }
        return map;
    }

    private buildKeySet(inputProcessor: Processor, outputProcessors: Processor[]): KeySet | null {
        if (!inputProcessor || !outputProcessors) {
            return null;
        }
        const keySet: KeySet = [];
        const inputSpecs = inputProcessor.inputSpecs;
        for (let i = 0; i < outputProcessors.length; i++) {
            const outputSpecs = outputProcessors[i].outputSpecs;
            const keyMap: KeyMap = {};
            for (let inputSpec of inputSpecs) {
                let keySet = new Set<string>();
                const inputTypes = inputSpec.types;
                for (let inputType of inputTypes) {
                    for (let outputSpec of outputSpecs) {
                        const outputType = outputSpec.type;
                        if (inputType === outputType) {
                            // Input and output types are the same.
                            keySet.add(outputSpec.key);
                        } else {
                            // Check if output type can be converted to the input type.
                            const typeSet = this.props.converterMap[inputType];
                            if (typeSet && typeSet.has(outputType)) {
                                keySet.add(outputSpec.key);
                            }
                        }
                    }
                }
                keyMap[inputSpec.key] = keySet;
            }
            keySet[i] = keyMap;
        }
        return keySet;
    }

    private findPathType(inputSpec: InputSpec): PathType {
        if (!this.props.outputComponents.isArray) {
            return PathType.KEY;
        } else {
            const ioType = inputSpec.types[0];
            return ioType === IoType.LIST ? PathType.ANCHOR_LIST : PathType.ANCHOR;
        }
    }

    private readInputProcessorRequest(componentId: string): Promise<any> {
        const component = this.props.componentMap[componentId];
        const request = {
            processorId: component.processor.id,
            version: component.processor.version
        };
        return endpointService.readProcessor(request,
            (response: any) => this.readProcessorResponse(response),
            undefined, true
        );
    }

    private readProcessorResponse(response: any): void {
        const inputProcessor = response.data.processor;
        this.setState({ inputProcessor: inputProcessor });
    }

    private readOutputProcessorsRequest(outputComponents: Component[]): Promise<any> {
        const processorRefs = [];
        for (const component of outputComponents) {
            processorRefs.push(component.processor);
        }
        const request = {
            processorRefs: processorRefs
        };
        return endpointService.readProcessors(request,
            (response: any) => this.readProcessorsResponse(response),
            undefined, true
        );
    }

    private readProcessorsResponse(response: any): void {
        const outputProcessors = response.data.processors;
        this.setState({ outputProcessors: outputProcessors });
    }


    private initIoMap(link: Link, inputProcessor: Processor, isInit: boolean): Link {
        // If this is the first render, use the supplied iomap, otherwise this
        // is due to a change, so clear out the iomap.
        let ioMap = isInit ? link.ioMap : {};
        // Check if any initializers need to be added for missing keys.
        const inputSpecs = inputProcessor.inputSpecs;
        for (const inputSpec of inputSpecs) {
            const key = inputSpec.key;
            let outputMapping = ioMap[key];
            if (!outputMapping) {
                let componentId;
                // Auto-wire if there is only one component.
                if (!this.props.outputComponents.isArray && this.props.outputComponents.components.length !== 0) {
                    const component = this.props.outputComponents.components[0];
                    componentId = component.id;
                }
                const outputPath: OutputPath = {
                    componentId: componentId,
                    outputKey: undefined as any
                }
                outputMapping = {
                    pathType: undefined as any,
                    outputPaths: [outputPath]
                }
                link = update(link, {
                    ioMap: {
                        [key]: { $set: outputMapping }
                    }
                });
            }
        }
        return link;
    }

    private loadData(inputComponentId: string, outputComponents: Component[], isInit: boolean) {
        const requests = [];
        requests.push(this.readInputProcessorRequest(inputComponentId));
        requests.push(this.readOutputProcessorsRequest(outputComponents));
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1, r2) => {
            if (RestUtils.isOk(r1, r2)) {
                this.compProcMap = this.buildCompProcMap(this.props.outputComponents.components);
                this.keySet = this.buildKeySet(this.state.inputProcessor!, this.state.outputProcessors!);
                const link = this.initIoMap(this.props.link, this.state.inputProcessor!, isInit);
                if (link !== this.props.link) {
                    this.props.onChange(link);
                }
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSpinner
                className="x-iomapeditor-loading"
                status={isLoading ? "loading" : "failed"}
                loadingMessage="Loading mappings..."
                failedMessage="Failed to load mappings."
                onRetry={this.handleRetryLoad}
            />
        );
    }

    private findOutputPaths(inputSpec: InputSpec): OutputPath[] {
        const ioMap = this.props.link.ioMap;
        const outputMapping = ioMap[inputSpec.key];
        if (!outputMapping) {
            return [];
        }
        return outputMapping.outputPaths;
    }

    private buildFormView(): ReactElement {
        const inputSpecs = this.state.inputProcessor?.inputSpecs;
        const outputProcessors = this.state.outputProcessors;
        if (!inputSpecs || inputSpecs.length === 0) {
            return <></>;
        }
        // Display the mapping (i.e., allow it to be configured) if:
        // 1) there is no default 1-1 mapping and therefore will be auto-wired
        // 2) there are input specs (size > 0), otherwise there is nothing to configure
        return (
            <Panel
                className="x-iomapeditor-panel"
                inverse={true}
                title={(
                    <Row gutter={8}>
                        <Col span={12}>
                            <span>Outputs</span>
                        </Col>
                        <Col>
                            <div className="x-iomapeditor-divide"></div>
                        </Col>
                        <Col>
                            <span>Inputs</span>
                        </Col>
                    </Row>
                )}>
                <div className="x-iomapeditor-link">
                    <LinkOutlined />
                </div>
                {inputSpecs && inputSpecs.map((inputSpec, index) => (
                    <Row key={index} gutter={[8, 0]}>
                        <Col span={12}>
                            {outputProcessors &&
                                <FormItem
                                    formRef={this.props.formRef}
                                    noLabel={true}
                                    noError={true}
                                    direction="vertical"
                                    valuePropName="outputPaths"
                                    rules={[{
                                        required: true,
                                        message: "Please select an output processor."
                                    }]}
                                >
                                    <OutputPathsEditor
                                        formRef={this.props.formRef}
                                        parentFlow={this.props.parentFlow}
                                        ioMap={this.props.link.ioMap}
                                        inputSpec={inputSpec}
                                        outputPaths={this.findOutputPaths(inputSpec)}
                                        keySet={this.keySet}
                                        outputComponents={this.props.outputComponents}
                                        compProcMap={this.compProcMap}
                                        onChange={(outputPaths) => this.handleOutputPathsChange(inputSpec, outputPaths)}
                                    />
                                </FormItem>
                            }
                        </Col>
                        <Col>
                            <div className="x-iomapeditor-divide">
                                <ArrowRightOutlined />
                            </div>
                        </Col>
                        <Col>
                            <Label
                                className="x-iomapeditor-label"
                                label={inputSpec.name}
                                info={inputSpec.description}
                                colon={false}
                                bold={false}
                            />
                        </Col>
                    </Row>
                ))}
            </Panel>
        );
    }

    public componentDidMount() {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData(this.props.link.id, this.props.outputComponents.components, true);
        }
    }

    public shouldComponentUpdate(nextProps: IoMapEditorProps): boolean {
        if (nextProps.link.id !== this.props.link.id || nextProps.outputComponents !== this.props.outputComponents) {
            this.loadData(nextProps.link.id, nextProps.outputComponents.components, false);
        }
        return true;
    }

    public render(): ReactElement {
        let view;
        if (!this.props.visible) {
            return <></>;
        }
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === Globals.STATUS_READY) {
            view = this.buildFormView();
        }
        return (
            <div className="x-iomapeditor">
                {view}
            </div>
        );
    }

}
