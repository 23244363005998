import { ReactElement } from 'react';
import { Button, Col, FormInstance, Input, InputNumber, Row, Select, Switch } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { Choice, BranchFlow, Condition, ConfigurationSpec, ConverterMap, Flow, FlowType, IoMap, OpType, Link } from '@methodset/endpoint-client-ts';
import { ChangeCallback, OutputComponents, State } from '../FlowEditor';
import { IoMapEditor } from '../IoMapEditor/IoMapEditor';
import { Justify } from 'components/Justify/Justify';
import { FormDivider } from 'components/FormDivider/FormDivider';
import { ComponentMap } from '../../Flows';
import update from 'immutability-helper';
import './BranchEditor.less';

const OP_TYPE: { [key in OpType]: "number" | "string" | "boolean" } = {
    [OpType.EQ]: "number",
    [OpType.NE]: "number",
    [OpType.LT]: "number",
    [OpType.GT]: "number",
    [OpType.LE]: "number",
    [OpType.GE]: "number",
    [OpType.IS]: "boolean",
    [OpType.EQUALS]: "string",
    [OpType.MATCHES]: "string"
};

export type BranchEditorProps = {
    formRef: React.RefObject<FormInstance>,
    index: number,
    // Ids used as prevs.
    prevIds: string[],
    // Ids used as nexts.
    nextIds: string[],
    parentFlow?: Flow,
    flow: BranchFlow,
    states: State[],
    outputComponents: OutputComponents,
    componentMap: ComponentMap,
    converterMap: ConverterMap,
    configurationSpecs: ConfigurationSpec[],
    // Called when the flow has changed.
    onChange: ChangeCallback,
} & typeof defaultProps;

const defaultProps = {
}

const newFlow = (): BranchFlow => {
    return {
        type: FlowType.BRANCH,
        prevId: undefined,
        choices: [{
            condition: {} as Condition,
            nextLink: {
                ioMap: {} as IoMap
            } as Link
        }] as Choice[],
    } as BranchFlow;
}

export const BranchEditor = (props: BranchEditorProps): ReactElement => {

    const handleVariableChange = (index: number, variable: string): void => {
        const flow = update(props.flow, {
            choices: {
                [index]: {
                    condition: {
                        variable: { $set: variable }
                    }
                }
            }
        });
        props.onChange(flow);
    }

    const handleOpChange = (index: number, opType: OpType): void => {
        const prevType = props.flow.choices[index].condition.opType;
        let flow = update(props.flow, {
            choices: {
                [index]: {
                    condition: {
                        opType: { $set: opType }
                    }
                }
            }
        });
        // If type of value changes, clear out.
        if (OP_TYPE[opType] !== OP_TYPE[prevType]) {
            flow = update(flow, {
                choices: {
                    [index]: {
                        condition: {
                            value: { $set: undefined }
                        }
                    }
                }
            });
        }
        props.onChange(flow);
    }

    const handleValueChange = (index: number, value: any): void => {
        const flow = update(props.flow, {
            choices: {
                [index]: {
                    condition: {
                        value: { $set: value }
                    }
                }
            }
        });
        props.onChange(flow);
    }

    const handleElseChange = (componentId: string): void => {
        const elseLink = {
            id: componentId,
            ioMap: {}
        }
        const flow = update(props.flow, {
            elseLink: { $set: elseLink }
        });
        props.onChange(flow);
    }

    const handleNextLinkChange = (nextLink: Link, index: number): void => {
        const flow = update(props.flow, {
            choices: {
                [index]: {
                    nextLink: { $set: nextLink }
                }
            }
        });
        props.onChange(flow);
    }

    const handleChoiceChange = (index: number, nextId: string): void => {
        const flow = update(props.flow, {
            choices: {
                [index]: {
                    nextLink: {
                        id: { $set: nextId }
                    }
                }
            }
        });
        props.onChange(flow);
    }

    const handleChoiceDelete = (index: number): void => {
        const flow = update(props.flow, {
            choices: {
                $splice: [[index, 1]]
            }
        });
        props.onChange(flow);
    }

    const handleChoiceAdd = (): void => {
        const choice: Choice = {
            condition: {} as Condition,
            nextLink: {
                ioMap: {} as IoMap
            } as Link
        };
        const flow = update(props.flow, {
            choices: {
                $push: [choice]
            }
        });
        props.onChange(flow);
    }

    return (
        <div className="x-brancheditor">
            <Row gutter={Globals.FORM_GUTTER_COL}>
                {props.flow.choices.map((choice, index) => (
                    <>
                        <Col span={12}>
                            <FormDivider
                                {...Globals.FORM_LAYOUT}
                                formRef={props.formRef}
                                label={index === 0 ? "If" : "Else If"}
                                index={index}
                                bold={true}
                                colon={false}
                                inline={true}
                                required={true}
                                onDelete={index > 0 ? () => handleChoiceDelete(index) : undefined}
                            />
                        </Col>
                        <Col span={12}>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={props.formRef}
                                label="Branch"
                                name={`branch-${index + 1}`}
                                info="The branch to execute if the condition is satisfied."
                                rules={[{
                                    required: true,
                                    message: "Please select a branch."
                                }]}
                            >
                                <Select
                                    placeholder="Select a branch."
                                    value={choice.nextLink.id}
                                    onChange={(value) => handleChoiceChange(index, value)}
                                >
                                    {props.states.map(state => (
                                        <Select.Option
                                            key={state.id}
                                            value={state.id}
                                            disabled={props.nextIds.includes(state.id) || props.flow.id === state.id}
                                        >
                                            {state.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </FormItem>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={props.formRef}
                                label="Variable"
                                name={`variable-${index + 1}`}
                                info="The variable to compare against."
                                rules={[{
                                    required: true,
                                    message: "Please select a variable."
                                }]}
                            >
                                <Select
                                    placeholder="Select a variable."
                                    value={choice.condition.variable}
                                    onChange={(value) => handleVariableChange(index, value)}
                                >
                                    {props.configurationSpecs.map(spec => (
                                        <Select.Option
                                            key={spec.key}
                                            value={spec.key}
                                        >
                                            {spec.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </FormItem>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={props.formRef}
                                label="Operator"
                                name={`operator-${index + 1}`}
                                info="The operator to use in the comparison."
                                rules={[{
                                    required: true,
                                    message: "Please select an operator."
                                }]}
                            >
                                <Select
                                    placeholder="Select an operator."
                                    value={choice.condition.opType}
                                    onChange={(value) => handleOpChange(index, value)}
                                >
                                    <Select.Option value={OpType.EQ}>==</Select.Option>
                                    <Select.Option value={OpType.NE}>!=</Select.Option>
                                    <Select.Option value={OpType.LT}>&lt;</Select.Option>
                                    <Select.Option value={OpType.GT}>&gt;</Select.Option>
                                    <Select.Option value={OpType.LE}>&lt;=</Select.Option>
                                    <Select.Option value={OpType.GE}>&gt;=</Select.Option>
                                    <Select.Option value={OpType.EQUALS}>Equals</Select.Option>
                                    <Select.Option value={OpType.MATCHES}>Matches</Select.Option>
                                    <Select.Option value={OpType.IS}>Is</Select.Option>
                                </Select>
                            </FormItem>
                            {OP_TYPE[choice.condition.opType] === "number" &&
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={props.formRef}
                                    label="Number Value"
                                    name={`value-${index + 1}`}
                                    info="The number value to compare against the variable."
                                    rules={[{
                                        required: true,
                                        message: "Enter a number value."
                                    }]}
                                >
                                    <InputNumber
                                        className="x-brancheditor-number"
                                        placeholder="Enter a value."
                                        value={choice.condition.value}
                                        onChange={(value) => handleValueChange(index, value)}
                                    />

                                </FormItem>
                            }
                            {OP_TYPE[choice.condition.opType] === "string" &&
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={props.formRef}
                                    label="Text Value"
                                    name={`value-${index + 1}`}
                                    info="The text value to compare against the variable."
                                    //valuePropName="checked"
                                    rules={[{
                                        required: true,
                                        message: "Enter a text value."
                                    }]}
                                >
                                    <Input
                                        placeholder="Enter a value."
                                        value={choice.condition.value}
                                        onChange={(e) => handleValueChange(index, e.target.value)}
                                    />

                                </FormItem>
                            }
                            {OP_TYPE[choice.condition.opType] === "boolean" &&
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={props.formRef}
                                    label="Boolean Value"
                                    name={`value-${index + 1}`}
                                    info="The boolean value to compare against the variable."
                                    valuePropName="checked"
                                    rules={[{
                                        required: true,
                                        message: "Enter a boolean value."
                                    }]}
                                >
                                    <Switch
                                        checked={choice.condition.value}
                                        checkedChildren="True"
                                        unCheckedChildren="False"
                                        onChange={(checked) => handleValueChange(index, checked)}
                                    />
                                </FormItem>
                            }
                        </Col>
                        <Col span={12}>
                            {props.outputComponents.components.length > 0 && !!choice.nextLink.id &&
                                <IoMapEditor
                                    formRef={props.formRef}
                                    visible={true}
                                    parentFlow={props.parentFlow}
                                    link={choice.nextLink}
                                    index={index}
                                    componentMap={props.componentMap}
                                    converterMap={props.converterMap}
                                    outputComponents={props.outputComponents}
                                    onChange={(nextLink) => handleNextLinkChange(nextLink, index)}
                                />
                            }
                        </Col>
                    </>
                ))}
                <Col span={12}>
                    <Justify className="x-brancheditor-branch" justification="right">
                        <Button onClick={() => handleChoiceAdd()}>Add Branch</Button>
                    </Justify>
                    <FormDivider
                        {...Globals.FORM_LAYOUT}
                        formRef={props.formRef}
                        label="Else"
                        bold={true}
                        colon={false}
                        inline={true}
                    />
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={props.formRef}
                        label="Branch"
                        name="else"
                        info="The branch to execute if no conditions are met."
                    >
                        <Select
                            placeholder="Select a branch."
                            value={props.flow.elseLink?.id}
                            onChange={(value) => handleElseChange(value)}
                        >
                            {props.states.map(state => (
                                <Select.Option
                                    key={state.id}
                                    value={state.id}
                                    disabled={props.nextIds.includes(state.id) || props.flow.id === state.id}
                                >
                                    {state.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                </Col>
            </Row>
        </div>
    )
}

BranchEditor.defaultProps = defaultProps;
BranchEditor.newFlow = newFlow;
