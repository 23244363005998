import { ReactElement } from 'react';
import { Checkbox, FormInstance } from 'antd';
import { Environment } from '@methodset/entity-client-ts';
import { EnvironmentType } from '@methodset/commons-core-ts';
import { CoreUtils } from 'utils/CoreUtils';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Spacer } from 'components/Spacer/Spacer';
import update from 'immutability-helper';
import './EnvironmentEditor.less';

export type ChangeCallback = (environments: Environment[]) => void;

export type EnvironmentEditorProps = {
    environments: Environment[],
    onChange: ChangeCallback
}

export const EnvironmentEditor = (props: EnvironmentEditorProps): ReactElement => {

    const handleEnvironmentChange = (e: CheckboxChangeEvent, type: string): void => {
        let environments;
        const checked = e.target.checked;
        if (checked) {
            environments = update(props.environments, {
                $push: [{
                    type: type as EnvironmentType
                }]
            });
        } else {
            const index = props.environments.findIndex(environment => environment.type === type);
            environments = update(props.environments, {
                $splice: [[index, 1]]
            });
        }
        props.onChange(environments);
    }

    const hasEnvironment = (type: string): boolean => {
        const index = props.environments.findIndex(environment => environment.type === type);
        return index !== -1;
    }

    return (
        <Spacer direction="vertical">
            {EnvironmentType.array().map(type => (
                <Checkbox
                    key={type}
                    className="x-environmenteditor-check"
                    checked={hasEnvironment(type)}
                    disabled={type === EnvironmentType.DEVELOPMENT}
                    onChange={(e) => handleEnvironmentChange(e, type)}
                >
                    {CoreUtils.toProper(type)}
                </Checkbox>
            ))}
        </Spacer>
    )
}
