import { Globals } from 'constants/Globals';
import { CoreUtils } from 'utils/CoreUtils';
import { FailureHandler, RestUtils, SuccessHandler } from 'utils/RestUtils';

class WorkflowService {

    public baseUrl = CoreUtils.apiUrl('workflow-service', 14010);

    public warm(): Promise<any> {
        const url = `${this.baseUrl}/warm`;
        return RestUtils.get(url, undefined, undefined, undefined, undefined, false);
    }

    public generateUploadUrl(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const parameters = {
            filename: request.fileName
        };
        const url = `${this.baseUrl}/workflows/${request.workflowId}/url${RestUtils.buildQuery(parameters)}`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public createWorkflow(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/workflows`;
        return RestUtils.post(url, request, onSuccess, onFailure, ownStatus, Globals.MESSAGE_CREATING);
    }

    public updateWorkflow(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/workflows/${request.workflowId}`;
        return RestUtils.put(url, request, onSuccess, onFailure, ownStatus, Globals.MESSAGE_SAVING);
    }

    public readWorkflowHeaders(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const parameters = {
            access: request.accessType ? request.accessType.toLowerCase() : undefined,
            latest: request.useLatest
        };
        const url = `${this.baseUrl}/workflows${RestUtils.buildQuery(parameters)}`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public readWorkflow(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/workflows/${request.workflowId}/versions/${request.version}`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public readWorkflowHeader(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/workflows/${request.workflowId}/versions/${request.version}/header`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    // TODO: remove - not used
    public readWorkflowEnabled(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/workflows/${request.workflowId}/enabled`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public updateWorkflowEnabled(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/workflows/${request.workflowId}/enabled`;
        const message = !!request.isEnabled ? "Enabling workflow..." : "Disabling workflow...";
        return RestUtils.put(url, request, onSuccess, onFailure, ownStatus, message);
    }
    public archiveWorkflow(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/workflows/${request.workflowId}/versions/${request.version}`;
        return RestUtils.delete(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_DELETING);
    }

    public readWorkflowVersions(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/workflows/${request.workflowId}/versions`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    // public publishVersion(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
    //     const url = `${this.baseUrl}/workflows/${request.workflowId}/versions`;
    //     return RestUtils.post(url, request, onSuccess, onFailure, ownStatus, "Publishing new version...");
    // }

    public resetVersion(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/workflows/${request.workflowId}/versions`;
        return RestUtils.put(url, request, onSuccess, onFailure, ownStatus, "Resetting version...");
    }

    public revertVersion(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/workflows/${request.workflowId}/versions/${request.version}`;
        return RestUtils.put(url, request, onSuccess, onFailure, ownStatus, "Reverting version...");
    }

    public activateVersion(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/workflows/${request.workflowId}/versions/${request.version}`;
        return RestUtils.post(url, request, onSuccess, onFailure, ownStatus, "Activating version...");
    }

    public readWorkflowEmail(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/workflows/${request.workflowId}/email`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public readStatisticsSets(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const parameters = {
            offset: request.periodOffset
        };
        const url = `${this.baseUrl}/workflows/statistics/${request.periodCount}/${request.periodType}/${request.zoneOffset}${RestUtils.buildQuery(parameters)}`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public readExecutions(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const parameters = {
            limit: request.maxResults,
            token: request.nextToken
        };
        const url = `${this.baseUrl}/workflows/${request.workflowId}/executions${RestUtils.buildQuery(parameters)}`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public rerunExecution(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/workflows/${request.workflowId}/executions/${request.executionId}/rerun`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public readExecutionHistory(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/workflows/${request.workflowId}/executions/${request.executionId}/history`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public testWorkflow(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/workflows/${request.workflowId}/test`;
        return RestUtils.post(url, request, onSuccess, onFailure, ownStatus, "Testing workflow...");
    }

    public installSchedules(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/workflows/schedules`;
        return RestUtils.post(url, request, onSuccess, onFailure, ownStatus, "Installing schedules...");
    }

    public uninstallSchedules(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/workflows/schedules`;
        return RestUtils.put(url, request, onSuccess, onFailure, ownStatus, "Uninstalling schedules...");
    }

}

export default new WorkflowService();
