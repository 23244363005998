import { ReactElement } from 'react';
import { DisplayType } from '@methodset/application-client-ts';
import { Button, Dropdown, Menu } from 'antd';
import { ArrowUpOutlined, BorderOutlined, PercentageOutlined, PlusOutlined } from '@ant-design/icons';
import { CoreUtils } from 'utils/CoreUtils';
import './DisplayStyle.less';

export type ChangeCallback = (display: DisplayType) => void;

export type DisplayStyleProps = {
    display?: DisplayType,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    display: DisplayType.NORMAL
}

export const DisplayStyle = (props: DisplayStyleProps): ReactElement => {

    const handleSelect = (info: any) => {
        props.onChange(info.key);
    }

    const menu = (
        <Menu onClick={handleSelect}>
            {Object.entries(DisplayType).map(([key, value]) =>
                <Menu.Item key={key}>
                    {CoreUtils.toCapital(value)}
                </Menu.Item>
            )}
        </Menu>
    );

    const iconView = (): ReactElement | undefined => {
        switch (props.display) {
            case DisplayType.NORMAL:
                return <BorderOutlined />
            case DisplayType.SIGN:
                return <PlusOutlined />
            case DisplayType.DIRECTION:
                return <ArrowUpOutlined />
            case DisplayType.PERCENT:
                return <PercentageOutlined />
            default:
                return undefined;
        }
    }

    return (
        <div className="x-displaystyle">
            <Dropdown
                overlay={menu}
                trigger={['click']}
            >
                <Button>
                    {iconView()}
                </Button>
            </Dropdown>
        </div>

    )
}

DisplayStyle.defaultProps = defaultProps;
