import { ReactElement } from 'react';
import { Spacer } from 'components/Spacer/Spacer';
import { Condition, LogicalType, OpType } from '@methodset/application-client-ts';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { Calculator } from '@methodset/calculator-ts';
import { InputEditor } from 'containers/Components/Widgets/InputWidgetViewer/InputEditor/InputEditor';
import { StaticInput } from 'components/StaticInput/StaticInput';
import { CoreUtils } from 'utils/CoreUtils';
import update from 'immutability-helper';
import './ConditionEditor.less';

export type ChangeCallback = (condition: Condition, index: number) => void;
export type RemoveCallback = (index: number) => void;

export type ConditionEditorProps = {
    index: number,
    condition: Condition,
    template?: Condition,
    calculator: Calculator,
    onChange: ChangeCallback,
    onRemove?: RemoveCallback
}

export const ConditionEditor = (props: ConditionEditorProps): ReactElement => {

    const handleVariableChange = (variableId: string | undefined): void => {
        const condition = update(props.condition, {
            variableId: { $set: variableId as any }
        });
        props.onChange(condition, props.index);
    }

    const handleOpChange = (opType: OpType): void => {
        const condition = update(props.condition, {
            opType: { $set: opType }
        });
        props.onChange(condition, props.index);
    }

    const handleValueChange = (value: any): void => {
        const condition = update(props.condition, {
            value: { $set: value }
        });
        props.onChange(condition, props.index);
    }

    const variableName = (variableId: string): string => {
        const variable = props.calculator.variables.get(variableId, false);
        return variable ? variable.name : "<Variable not found>";
    }

    const logical = props.condition.logicalType ? LogicalType.name(props.condition.logicalType) : "When";

    return (
        <Spacer className="x-conditioneditor" fill>
            <span className="x-conditioneditor-logical">
                {logical}
            </span>
            {props.template?.variableId &&
                <StaticInput
                    className="x-conditioneditor-variable"
                    value={variableName(props.template.variableId)}
                />
            }
            {!props.template?.variableId &&
                <Select
                    className="x-conditioneditor-variable"
                    placeholder="Select a variable."
                    value={props.condition.variableId}
                    onChange={handleVariableChange}
                >
                    {props.calculator.variables.map(variable => (
                        <Select.Option key={variable.id} value={variable.id}>
                            {variable.name}
                        </Select.Option>
                    ))}
                </Select>
            }
            {props.template?.opType &&
                <StaticInput
                    className="x-conditioneditor-op"
                    value={OpType.symbol(props.template.opType)}
                />
            }
            {!props.template?.opType &&
                <Select
                    className="x-conditioneditor-op"
                    allowClear={true}
                    value={props.condition.opType}
                    onChange={handleOpChange}
                >
                    {OpType.symbols().map(([key, value]) =>
                        <Select.Option key={key} value={key}>{value}</Select.Option>
                    )}
                </Select>
            }
            {(!props.condition.variableId || !CoreUtils.isEmpty(props.template?.value)) &&
                <StaticInput
                    className="x-conditioneditor-value"
                    value={props.template?.value}
                />
            }
            {(props.condition.variableId && CoreUtils.isEmpty(props.template?.value)) &&
                <InputEditor
                    className="x-conditioneditor-value"
                    calculator={props.calculator}
                    value={props.condition.value}
                    variableId={props.condition.variableId}
                    doEmbed={true}
                    onChange={handleValueChange}
                />
            }
            {props.onRemove && props.index === 0 && 
                <div className="x-conditioneditor-icon"></div>
            }
            {props.onRemove && props.index > 0 &&
                <Button
                    className="x-conditioneditor-remove"
                    icon={<DeleteOutlined />}
                    onClick={() => props.onRemove!(props.index)}
                />
            }
        </Spacer>
    );

}
