import { ChangeEvent, ReactElement, useRef, useState } from 'react';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { Button, Input, Select } from 'antd';
import { Calculator, Variable } from '@methodset/calculator-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { FormattedInput } from 'components/FormattedInput/FormattedInput';
import { VariableSelector } from 'containers/Components/Widgets/Selectors/VariableSelector';
import classNames from 'classnames';
import './MessageEditor.less';

export type ChangeCallback = (message: string) => void;

export type MessageEditorProps = {
    className?: string,
    message?: string,
    placeholder?: string,
    calculator: Calculator,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
}

export const MessageEditor = (props: MessageEditorProps): ReactElement => {

    const inputRef = useRef<TextAreaRef>(null);

    const [variableId, setVariableId] = useState<string | undefined>();

    const handleMessageChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        const message = e.target.value;
        props.onChange(message);
    }

    const handleVariableChange = (variableId: string): void => {
        setVariableId(variableId);
    }

    const handleTextAdd = (): void => {
        if (!variableId) {
            return;
        }
        const textArea = inputRef.current!.resizableTextArea!.textArea;
        const start = textArea.selectionStart;
        let pos = start ?? 0;
        let curr = textArea.value;
        let message = `${curr.slice(0, pos)}\${${variableId}}${curr.slice(pos)}`;
        textArea.value = message;
        pos += variableId.length + 3;
        textArea.setSelectionRange(pos, pos);
        props.onChange(message);
        inputRef.current?.focus();
    }

    const buildSample = (): ReactElement => {
        const defaultValue = 100;
        if (!variableId) {
            return (
                <span>{defaultValue}</span>
            )
        }
        return (
            <FormattedInput
                value={defaultValue}
                variableId={variableId}
                calculator={props.calculator}
                useVariableValue={true}
            />
        )
    }

    return (
        <div className={classNames("x-messageeditor", props.className)}>
            <Input.TextArea
                ref={inputRef}
                placeholder={props.placeholder}
                rows={3}
                value={props.message}
                onChange={handleMessageChange}
            />
            <Spacer className="x-messageeditor-insert">
                <span>Insert variable:</span>
                <VariableSelector
                    className="x-messageeditor-select"
                    calculator={props.calculator}
                    variableId={variableId}
                    onChange={handleVariableChange}
                />
                <Button onClick={handleTextAdd}>Add</Button>
            </Spacer>
            {variableId &&
                <div className="x-messageeditor-example">
                    Sample text: "{buildSample()}"
                </div>
            }
        </div>
    )

}

MessageEditor.defaultProps = defaultProps;
