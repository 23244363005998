import { PureComponent, ReactElement } from 'react';
import { Label } from 'components/Label/Label';
import { Calculator, Cell, Variable, VariableList } from '@methodset/calculator-ts';
import { DeleteOutlined, DownOutlined, EditOutlined, UpOutlined } from '@ant-design/icons';
import { MenuButton } from 'components/MenuButton/MenuButton';
import { Spacer } from 'components/Spacer/Spacer';
import './VariableItem.less';

export type EditCallback = (cell: Cell) => void;
export type RemoveCallback = (cell: Cell) => void;

export type VariableItemProps = {
    cell: Cell,
    calculator: Calculator,
    variables: Variable[],
    index: number,
    onEdit: EditCallback,
    onRemove: RemoveCallback
};

export class VariableItem extends PureComponent<VariableItemProps> {

    constructor(props: VariableItemProps) {
        super(props);
        this.handleVariableEdit = this.handleVariableEdit.bind(this);
        this.handleVariableRemove = this.handleVariableRemove.bind(this);
        this.handleVariableMoveUp = this.handleVariableMoveUp.bind(this);
        this.handleVariableMoveDown = this.handleVariableMoveDown.bind(this);
    }

    private handleVariableEdit(): void {
        this.props.onEdit(this.props.cell);
    }

    private handleVariableRemove(): void {
        this.props.onRemove(this.props.cell);
    }

    private handleVariableMoveUp(): void {
        const curr = this.props.variables[this.props.index];
        const prev = this.props.variables[this.props.index - 1];
        const variables = this.props.calculator.variables;
        const index = variables.index(prev.id);
        variables.move(curr.id, index);
    }

    private handleVariableMoveDown(): void {
        const curr = this.props.variables[this.props.index];
        const next = this.props.variables[this.props.index + 1];
        const variables = this.props.calculator.variables;
        const index = variables.index(next.id);
        variables.move(curr.id, index);
    }

    public render(): ReactElement {
        const items = [{
            icon: <EditOutlined />,
            label: "Edit",
            onSelect: this.handleVariableEdit
        }, {
            icon: <UpOutlined />,
            label: "Move up",
            disabled: () => {
                return this.props.index === 0;
            },
            onSelect: this.handleVariableMoveUp
        }, {
            icon: <DownOutlined />,
            label: "Move down",
            disabled: () => {
                return this.props.index === this.props.variables.length - 1;
            },
            onSelect: this.handleVariableMoveDown
        }, {
            icon: <DeleteOutlined />,
            label: "Remove",
            onSelect: this.handleVariableRemove
        }];
        const variable = this.props.cell.variable!;
        return (
            <div className="x-variableitem">
                <Label
                    placement="top"
                    label={variable.name}
                    info={variable.description}
                    extra={variable.id}
                    nowrap={false}
                    //extraLocation="under"
                >
                    <Spacer fill={true} justification="between">
                        <div className="x-variableitem-cell">{variable.cell.uid}</div>
                        <MenuButton items={items} />
                    </Spacer>
                </Label>
            </div>
        );
    }
}
