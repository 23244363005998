import { FC, ReactElement } from 'react';
import { Button, Modal } from 'antd';
import { Globals } from 'constants/Globals';
import { Label } from 'components/Label/Label';
import { Spacer } from 'components/Spacer/Spacer';
import { CoreUtils } from 'utils/CoreUtils';
import { Application, Installation } from '@methodset/application-client-ts';
import { IdUtils } from '@methodset/commons-core-ts';
import './AppletAbout.less';

export type CloseCallback = () => void;

export type AppletAboutProps = {
    application: Application,
    installation: Installation,
    onClose: CloseCallback
}

export const AppletAbout: FC<AppletAboutProps> = (props: AppletAboutProps): ReactElement => {

    return (
        <Modal
            className="x-appletabout"
            centered
            title="Applet Information"
            width={Globals.DIALOG_WIDTH_STANDARD}
            onCancel={() => props.onClose()}
            visible={true}
            footer={(
                <Button type="primary" onClick={() => props.onClose()}>Close</Button>
            )}
        >
            <Spacer direction="vertical">
                <Label label="Name" alignment="top">{props.application.name}</Label>
                <Label label="Description" alignment="top">{props.application.description ?? "N/A"}</Label>
                <Label label="Publisher" alignment="top">{props.application.publisher ?? "N/A"}</Label>
                <Label label="Category" alignment="top">{CoreUtils.toProper(props.application.categoryType)}</Label>
                <Label label="Version" alignment="top">{CoreUtils.toVersion(props.application.version)}</Label>
                <Label label="Access" alignment="top">{CoreUtils.toProper(IdUtils.toAccessType(props.application.id))}</Label>
                <Label label="Installed" alignment="top">{CoreUtils.toTime(props.installation.createTime)}</Label>
                </Spacer>
        </Modal>
    )

}
