import { ReactElement } from 'react';
import { FormInstance, Input } from 'antd';
import { RecordsLocator } from '@methodset/endpoint-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { FormItem } from 'components/FormItem/FormItem';
import { IntegerInput } from 'components/IntegerInput/IntegerInput';
import update from 'immutability-helper';
import './DatasetLocatorEditor.less';

export type ChangeCallback = (locator: RecordsLocator) => void;

export type DatasetLocatorEditorProps = {
    formRef: React.RefObject<FormInstance>,
    locator: RecordsLocator,
    index: number,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
}

export const DatasetLocatorEditor = (props: DatasetLocatorEditorProps): ReactElement => {

    const handleRowChange = (row: number): void => {
        const locator = update(props.locator, {
            row: { $set: row as any }
        });
        props.onChange(locator);
    }

    const handleColChange = (col: number | string): void => {
        const locator = update(props.locator, {
            col: { $set: col }
        });
        props.onChange(locator);
    }

    return (
        <Spacer className="x-datasetlocatoreditor">
            <FormItem
                formRef={props.formRef}
                className="x-datasetlocatoreditor-form"
                name={`dataset-locator-row-${props.index}`}
                label="Row Number"
                colon={false}
                rules={[{
                    required: true,
                    message: "Enter a row number."
                }]}
            >
                <IntegerInput
                    key={props.locator.row}
                    className="x-datasetlocatoreditor-item"
                    placeholder="Index (0-based)."
                    natural={true}
                    value={props.locator.row}
                    onChange={(value) => handleRowChange(value)}
                />
            </FormItem>
            <FormItem
                formRef={props.formRef}
                className="x-datasetlocatoreditor-form"
                name={`dataset-locator-field-${props.index}`}
                label="Column Name"
                colon={false}
                rules={[{
                    required: true,
                    message: "Enter a column name."
                }]}
            >
                <Input
                    key={`column-field-${props.index}`}
                    className="x-datasetlocatoreditor-item"
                    placeholder="Column name."
                    value={props.locator.col as string}
                    onChange={(e) => handleColChange(e.target.value)}
                />
            </FormItem>
        </Spacer >
    )
}

DatasetLocatorEditor.defaultProps = defaultProps;
