import { Calculator } from '@methodset/calculator-ts';
import { ReactElement } from 'react';
import { Applet, AppletPanel, SwitchOption, SwitchWidgetConfiguration } from '@methodset/application-client-ts';
import { CoreUtils } from 'utils/CoreUtils';
import { Configuration } from '@methodset/endpoint-client-ts';
import { ConfigurationCallback } from '../WidgetViewer/WidgetViewer';
import { PanelViewer } from 'containers/Console/Dashboards/DashboardItem/AppletViewer/PanelViewer/PanelViewer';
import './SwitchWidgetViewer.less';

export type SwitchWidgetViewerProps = {
    calculator: Calculator,
    configuration: SwitchWidgetConfiguration,
    applet: Applet,
    appletConfiguration: Configuration,
    onConfiguration: ConfigurationCallback
};

export const SwitchWidgetViewer = (props: SwitchWidgetViewerProps): ReactElement => {

    const variableValue = (): any => {
        const variables = props.calculator.variables;
        const variable = variables.get(props.configuration.variableId, false);
        if (!variable) {
            return undefined;
        }
        const value = variable.cell?.value;
        return !CoreUtils.isEmpty(value) ? value.toString() : undefined;
    }

    const activeOption = (): SwitchOption | undefined => {
        let value = variableValue();
        if (CoreUtils.isEmpty(value)) {
            return undefined;
        }
        return props.configuration.options.find(option => option.value === value);
    }

    const findPanel = (panelId: string | undefined): AppletPanel | undefined => {
        if (!panelId) {
            return undefined;
        }
        return props.applet.panels.find(panel => panel.id === panelId);
    }

    const buildAppletsView = (): ReactElement => {
        const option = activeOption();
        const panel = findPanel(option?.panelId);
        if (option && panel) {
            // const installation: AppletComponent = {
            //     applet: props.applet,
            //     panel: panel,
            //     calculator: props.calculator,
            //     configuration: props.appletConfiguration
            // }
            return (
                <PanelViewer
                    applet={props.applet}
                    calculator={props.calculator}
                    panel={panel}
                    configuration={props.appletConfiguration}
                    onConfiguration={props.onConfiguration}
                />
                // <AppletViewer
                //     key={props.applet.id}
                //     installation={installation}
                //     isEmbedded={true}
                //     onConfiguration={props.onUpdate}
                // />
            )
        } else {
            const value = variableValue();
            if (CoreUtils.isEmpty(value)) {
                return (
                    <div>{`ERROR: Applet variable '${props.configuration.variableId}' does not exist.`}</div>
                )
            } else {
                return (
                    <div>{`ERROR: Panel mapping does not exist for variable value '${value}'.`}</div>
                )
            }
        }
    }

    return buildAppletsView();

}
