import { PureComponent, ReactElement } from 'react';
import { Modal } from 'antd';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Globals } from 'constants/Globals';
import { YodleeContext } from '@methodset/endpoint-client-ts';
import { RouteComponentProps } from 'react-router-dom';
import { RestUtils } from 'utils/RestUtils';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { FastLink } from './FastLink/FastLink';
import endpointService from 'services/EndpointService';
import axios from 'axios';
import './AccountItem.less';

type MatchParams = {
    accountId: string
}

export type AccountItemState = {
    status: string,
    yodleeContext?: YodleeContext,
    accessToken?: string
}

export type AccountItemProps = RouteComponentProps<MatchParams> & {
}

export class AccountItem extends PureComponent<AccountItemProps, AccountItemState> {

    constructor(props: AccountItemProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
        };
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleClose(data: any): void {
        this.props.history.push(RouteBuilder.CONSOLE_ACCOUNTS);
        console.log(data);
        return;
    }

    private handleSuccess(data: any): void {
        console.log(data);
        return;
    }

    private handleError(data: any): void {
        console.log(data);
        return;
    }

    private readYodleeContextRequest(): Promise<any> {
        const request = {};
        return endpointService.readYodleeContext(request,
            (response: any) => this.readYodleeContextResponse(response),
            undefined, true
        );
    }

    private readYodleeContextResponse(response: any): void {
        const yodleeContext = response.data.yodleeContext;
        this.setState({ yodleeContext: yodleeContext });
    }

    private createYodleeAccessTokenRequest(): Promise<any> {
        const request = {};
        return endpointService.createYodleeAccessToken(request,
            (response: any) => this.createYodleeAccessTokenResponse(response),
            undefined, true
        );
    }

    private createYodleeAccessTokenResponse(response: any): void {
        const accessToken = response.data.accessToken;
        this.setState({
            accessToken: accessToken
        });
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSpinner
                className="x-accountitem-loading"
                status={isLoading ? "loading" : "failed"}
                onRetry={this.handleRetryLoad}
            />
        );
    }

    private buildLinkView(): ReactElement {
        const providerId = this.props.match.params.accountId;
        const providerAccountId = providerId && providerId !== "create" ? parseInt(providerId) : undefined;
        return (
            <Modal
                centered
                title="Link Account"
                visible={true}
                closable={false}
                width={Globals.DIALOG_WIDTH}
                footer={null}
            >
                <FastLink
                    fastLinkURL={this.state.yodleeContext!.fastLinkUrl}
                    accessToken={this.state.accessToken!}
                    providerAccountId={providerAccountId}
                    onSuccess={this.handleSuccess}
                    onError={this.handleError}
                    onClose={this.handleClose}
                />
            </Modal>
        );
    }
    private loadData(): void {
        const requests = [];
        requests.push(this.createYodleeAccessTokenRequest());
        requests.push(this.readYodleeContextRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1, r2) => {
            if (RestUtils.isOk(r1, r2)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === Globals.STATUS_READY) {
            view = this.buildLinkView();
        }
        return (
            <div>
                {view}
            </div>
        );
    }

}
