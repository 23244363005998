import React, { PureComponent, ReactElement } from 'react';
import { Col, FormInstance, Row, Select, Switch } from 'antd';
import { Calculator, RefType } from '@methodset/calculator-ts';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { DisplayType, RowLink, WatchlistWidgetConfiguration, WidgetType } from '@methodset/application-client-ts';
import { RefEditor } from 'containers/Components/Widgets/RefEditor/RefEditor';
import { VariableSelector } from 'containers/Components/Widgets/Selectors/VariableSelector';
import { CoreUtils } from 'utils/CoreUtils';
import { WidgetUtils } from 'utils/WidgetUtils';
import update from 'immutability-helper';
import './WatchlistWidgetEditor.less';

export type ChangeCallback = (configuration: WatchlistWidgetConfiguration) => void;

export type WatchlistWidgetEditorProps = typeof WatchlistWidgetEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    defaults: ReactElement,
    configuration?: WatchlistWidgetConfiguration,
    calculator: Calculator,
    onChange: ChangeCallback
}

export class WatchlistWidgetEditor extends PureComponent<WatchlistWidgetEditorProps> {

    static DefaultConfiguration = {
        type: WidgetType.WATCHLIST,
        changeType: DisplayType.DIRECTION
    } as WatchlistWidgetConfiguration;

    static defaultProps = {
        configuration: WatchlistWidgetEditor.DefaultConfiguration
    }

    constructor(props: WatchlistWidgetEditorProps) {
        super(props);
        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.handleHeadersChange = this.handleHeadersChange.bind(this);
        this.handleNameRangeChange = this.handleNameRangeChange.bind(this);
        this.handleSymbolRangeChange = this.handleSymbolRangeChange.bind(this);
        this.handleValueRangeChange = this.handleValueRangeChange.bind(this);
        this.handleChangeRangeChange = this.handleChangeRangeChange.bind(this);
        this.handleChangeTypeChange = this.handleChangeTypeChange.bind(this);
        this.handleTimeRangeChange = this.handleTimeRangeChange.bind(this);
        this.handleLinkToggle = this.handleLinkToggle.bind(this);
        this.handleLinkVariableChange = this.handleLinkVariableChange.bind(this);
        this.handleLinkRangeChange = this.handleLinkRangeChange.bind(this);
    }

    private handleRangeChange(rangeId: string | undefined): void {
        const configuration = update(this.props.configuration, {
            rangeId: { $set: rangeId as any }
        });
        this.props.onChange(configuration);
    }

    private handleHeadersChange(hasHeaders: boolean): void {
        const configuration = update(this.props.configuration, {
            hasHeaders: { $set: hasHeaders }
        });
        this.props.onChange(configuration);
    }

    private handleNameRangeChange(nameRangeId: string): void {
        const configuration = update(this.props.configuration, {
            nameRangeId: { $set: nameRangeId }
        });
        this.props.onChange(configuration);
    }

    private handleSymbolRangeChange(symbolRangeId: string): void {
        const configuration = update(this.props.configuration, {
            symbolRangeId: { $set: symbolRangeId }
        });
        this.props.onChange(configuration);
    }

    private handleValueRangeChange(valueRangeId: string): void {
        const configuration = update(this.props.configuration, {
            valueRangeId: { $set: valueRangeId }
        });
        this.props.onChange(configuration);
    }

    private handleChangeRangeChange(changeRangeId: string): void {
        const configuration = update(this.props.configuration, {
            changeRangeId: { $set: changeRangeId }
        });
        this.props.onChange(configuration);
    }

    private handleChangeTypeChange(changeType: DisplayType): void {
        const configuration = update(this.props.configuration, {
            changeType: { $set: changeType }
        });
        this.props.onChange(configuration);
    }

    private handleTimeRangeChange(timeRangeId: string): void {
        const configuration = update(this.props.configuration, {
            timeRangeId: { $set: timeRangeId }
        });
        this.props.onChange(configuration);
    }

    private handleLinkToggle(hasLink: boolean): void {
        const rowLink = hasLink ? {} as RowLink : undefined;
        const configuration = update(this.props.configuration, {
            rowLink: { $set: rowLink }
        });
        this.props.onChange(configuration);
    }

    private handleLinkVariableChange(variableId: string): void {
        const configuration = update(this.props.configuration, {
            rowLink: {
                variableId: { $set: variableId }
            }
        });
        this.props.onChange(configuration);
    }

    private handleLinkRangeChange(rangeId: string): void {
        const configuration = update(this.props.configuration, {
            rowLink: {
                rangeId: { $set: rangeId }
            }
        });
        this.props.onChange(configuration);
    }

    public componentDidMount(): void {
        if (this.props.configuration === WatchlistWidgetEditor.DefaultConfiguration) {
            this.props.onChange(this.props.configuration);
        }
    }

    public render(): ReactElement {
        const columns = WidgetUtils.rangeVectors(this.props.calculator, this.props.configuration.rangeId, this.props.configuration.hasHeaders);
        return (
            <Row gutter={Globals.FORM_GUTTER_ROW}>
                <Col span={8}>
                    {this.props.defaults}
                </Col>
                <Col span={8}>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        required={true}
                        label="Data Range"
                        name="range"
                        info="The range that includes the data that can be added to the watchlist."
                    >
                        <RefEditor
                            formRef={this.props.formRef}
                            index={0}
                            calculator={this.props.calculator}
                            refTypes={[RefType.RANGE]}
                            refId={this.props.configuration.rangeId}
                            onChange={this.handleRangeChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Includes Headers"
                        name="headers"
                        info="True if the data range has header values in the first row. These will be used to help identify the column contents."
                        valuePropName="checked"
                        required={true}
                    >
                        <Switch
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={this.props.configuration.hasHeaders}
                            onChange={this.handleHeadersChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Name Range"
                        name="name-range"
                        info="The range that contains the asset name."
                        rules={[{
                            required: true,
                            message: "Please select a range."
                        }]}
                    >
                        <Select
                            value={this.props.configuration.nameRangeId}
                            allowClear={true}
                            onChange={this.handleNameRangeChange}
                        >
                            {columns.map(column => (
                                <Select.Option key={column.id} value={column.id}>{column.label}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Symbol Range"
                        name="symbol-range"
                        info="The range that contains the asset symbol."
                    >
                        <Select
                            value={this.props.configuration.symbolRangeId}
                            allowClear={true}
                            onChange={this.handleSymbolRangeChange}
                        >
                            {columns.map(column => (
                                <Select.Option key={column.id} value={column.id}>{column.label}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Value Range"
                        name="value-range"
                        info="The range that contains the asset value."
                    >
                        <Select
                            value={this.props.configuration.valueRangeId}
                            allowClear={true}
                            onChange={this.handleValueRangeChange}
                        >
                            {columns.map(column => (
                                <Select.Option key={column.id} value={column.id}>{column.label}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Change Range"
                        name="change-range"
                        info="The range that contains the asset change."
                    >
                        <Select
                            value={this.props.configuration.changeRangeId}
                            allowClear={true}
                            onChange={this.handleChangeRangeChange}
                        >
                            {columns.map(column => (
                                <Select.Option key={column.id} value={column.id}>{column.label}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Time Range"
                        name="time-range"
                        info="The range that contains the asset time."
                    >
                        <Select
                            value={this.props.configuration.timeRangeId}
                            allowClear={true}
                            onChange={this.handleTimeRangeChange}
                        >
                            {columns.map(column => (
                                <Select.Option key={column.id} value={column.id}>{column.label}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                </Col>
                <Col span={8}>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Change Display Type"
                        name="display"
                        info="Sets the display type for the change value."
                    >
                        <Select
                            placeholder="Select a time zone."
                            value={this.props.configuration.changeType}
                            onChange={this.handleChangeTypeChange}
                        >
                            {Object.entries(DisplayType).map(([key, value]) =>
                                <Select.Option key={key} value={key}>{CoreUtils.toCapital(value)}</Select.Option>
                            )}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Add Row Link"
                        name="link"
                        info="Enable configuration of a link when a row is selected."
                        valuePropName="checked"
                    >
                        <Switch
                            checked={!!this.props.configuration.rowLink}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            onChange={this.handleLinkToggle}
                        />
                    </FormItem>
                    {!!this.props.configuration.rowLink &&
                        <>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.props.formRef}
                                label="Link Variable"
                                name="link-variable"
                                info="The variable to change when a row is selected."
                                valuePropName="variableId"
                                rules={[{
                                    required: true,
                                    message: "Please select a variable."
                                }]}
                            >
                                <VariableSelector
                                    calculator={this.props.calculator}
                                    variableId={this.props.configuration.rowLink.variableId}
                                    onChange={this.handleLinkVariableChange}
                                />
                            </FormItem>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.props.formRef}
                                label="Link Range"
                                name="link-range"
                                info="The range that contains the values that will be used to set the variable."
                                rules={[{
                                    required: true,
                                    message: "Please select a range."
                                }]}
                            >
                                <Select
                                    value={this.props.configuration.rowLink.rangeId}
                                    allowClear={true}
                                    onChange={this.handleLinkRangeChange}
                                >
                                    {columns.map(column => (
                                        <Select.Option key={column.id} value={column.id}>{column.id}</Select.Option>
                                    ))}
                                </Select>
                            </FormItem>
                        </>
                    }
                </Col>
            </Row>
        );
    }

}
