import { PureComponent, ReactElement } from 'react';
import { Card, Col } from 'antd';
import { Calculator } from '@methodset/calculator-ts';
import {
    Applet,
    AppletPanel,
    ChangeListWidgetConfiguration,
    ColumnWidgetConfiguration,
    DividerWidgetConfiguration,
    FilterWidgetConfiguration,
    InputFlowWidgetConfiguration,
    InputPanelWidgetConfiguration,
    InputWidgetConfiguration,
    ListWidgetConfiguration,
    Margin,
    PanelWidgetConfiguration,
    RowWidgetConfiguration,
    SelectWidgetConfiguration,
    SwitchWidgetConfiguration,
    TableWidgetConfiguration,
    TextWidgetConfiguration,
    ValueWidgetConfiguration,
    WatchlistWidgetConfiguration,
    Widget,
    WidgetConfiguration,
    WidgetType
} from '@methodset/application-client-ts';
import { TableWidgetViewer } from '../TableWidgetViewer/TableWidgetViewer';
import { TextWidgetViewer } from '../TextWidgetViewer/TextWidgetViewer';
import { ValueWidgetViewer } from '../ValueWidgetViewer/ValueWidgetViewer';
import { ChartWidgetViewer } from '../ChartWidgetViewer/ChartWidgetViewer';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { WatchlistWidgetViewer } from '../WatchlistWidgetViewer/WatchlistWidgetViewer';
import { ChangeListWidgetViewer } from '../ChangeListWidgetViewer/ChangeListWidgetViewer';
import { DividerWidgetViewer } from '../DividerWidgetViewer/DividerWidgetViewer';
import { CoreUtils } from 'utils/CoreUtils';
import { SelectWidgetViewer } from '../SelectWidgetViewer/SelectWidgetViewer';
import { SwitchWidgetViewer } from '../SwitchWidgetViewer/SwitchWidgetViewer';
import { InputWidgetViewer } from '../InputWidgetViewer/InputWidgetViewer';
//import { Configuration, VariableSpec } from '@methodset/endpoint-client-ts';
import { ListWidgetViewer } from '../ListWidgetViewer/ListWidgetViewer';
import { RowWidgetViewer } from '../RowWidgetViewer/RowWidgetViewer';
import { ColumnWidgetViewer } from '../ColumnWidgetViewer/ColumnWidgetViewer';
import { WidgetCallback } from 'containers/Console/Models/ModelItem/ModelApplications/ApplicationItem/ModelApplet/AppletEditor/ItemMenu/ItemMenu';
import { Globals } from 'constants/Globals';
import { PanelWidgetViewer } from '../PanelWidgetViewer/PanelWidgetViewer';
import { InputFlowWidgetViewer } from '../InputFlowWidgetViewer/InputFlowWidgetViewer';
import { InputPanelWidgetViewer } from '../InputPanelWidgetViewer/InputPanelWidgetViewer';
import { FilterWidgetViewer } from '../FilterWidgetViewer/FilterWidgetViewer';
import './WidgetViewer.less';
import { Configuration } from '@methodset/model-client-ts';

export type ConfigurationCallback = (configuration: Configuration) => void;

export type WidgetViewerProps = typeof WidgetViewer.defaultProps & {
    className?: string,
    applet: Applet,
    panel: AppletPanel,
    widget: Widget,
    calculator: Calculator,
    extra?: ReactElement,
    configuration: Configuration,
    showHeader?: boolean,
    onEdit?: WidgetCallback,
    onRemove?: WidgetCallback,
    onChange?: WidgetCallback,
    onUpdate: ConfigurationCallback
}

export type WidgetViewerState = {
    isRefreshing: boolean
}

export class WidgetViewer extends PureComponent<WidgetViewerProps, WidgetViewerState> {

    static defaultProps = {
        showHeader: true
    }

    constructor(props: WidgetViewerProps) {
        super(props);
        this.state = {
            isRefreshing: false
        }
        this.handleExecutionComplete = this.handleExecutionComplete.bind(this);
        this.handleQueryRefresh = this.handleQueryRefresh.bind(this);
    }

    private handleExecutionComplete(): void {
        if (this.state.isRefreshing) {
            this.setState({ isRefreshing: false });
        } else {
            this.forceUpdate();
        }
    }

    private handleQueryRefresh(): void {
        this.setState({ isRefreshing: true });
    }

    private buildLoadingView(): ReactElement {
        return (
            <LoadSkeleton
                className="x-widgetviewer-skeleton"
                count={4}
                status="loading"
            >
                <LoadSkeleton.Input length="fill" />
            </LoadSkeleton>
        );
    }

    public buildWidgetView(): ReactElement {
        // TODO: if hideId set, check value and hide if true
        // TODO: if errorId set, check value and show error in place of widget
        const type = this.props.widget.configuration!.type;
        switch (type) {
            case WidgetType.AREA_CHART:
            case WidgetType.BAR_CHART:
            case WidgetType.COLUMN_CHART:
            case WidgetType.LINE_CHART:
            case WidgetType.PIE_CHART:
            case WidgetType.DONUT_CHART:
            case WidgetType.CANDLESTICK_CHART:
                return (
                    <ChartWidgetViewer
                        calculator={this.props.calculator!}
                        configuration={this.props.widget.configuration as WidgetConfiguration}
                    />
                )
            case WidgetType.ROW:
                return (
                    <RowWidgetViewer
                        calculator={this.props.calculator!}
                        configuration={this.props.widget.configuration as RowWidgetConfiguration}
                        extra={this.props.extra}
                        applet={this.props.applet}
                        panel={this.props.panel}
                        widget={this.props.widget}
                        // modelId={this.props.modelId}
                        // version={this.props.version}
                        appletConfiguration={this.props.configuration}
                        showHeader={this.props.showHeader}
                        onEdit={this.props.onEdit}
                        onRemove={this.props.onRemove}
                        onChange={this.props.onChange}
                        onUpdate={this.props.onUpdate}
                    />
                )
            case WidgetType.COLUMN:
                return (
                    <ColumnWidgetViewer
                        calculator={this.props.calculator!}
                        configuration={this.props.widget.configuration as ColumnWidgetConfiguration}
                        extra={this.props.extra}
                        applet={this.props.applet}
                        panel={this.props.panel}
                        widget={this.props.widget}
                        // modelId={this.props.modelId}
                        // version={this.props.version}
                        appletConfiguration={this.props.configuration}
                        showHeader={this.props.showHeader}
                        onEdit={this.props.onEdit}
                        onRemove={this.props.onRemove}
                        onChange={this.props.onChange}
                        onUpdate={this.props.onUpdate}
                    />
                )
            case WidgetType.TABLE:
                return (
                    <TableWidgetViewer
                        calculator={this.props.calculator!}
                        configuration={this.props.widget.configuration as TableWidgetConfiguration}
                    />
                )
            case WidgetType.LIST:
                return (
                    <ListWidgetViewer
                        calculator={this.props.calculator!}
                        configuration={this.props.widget.configuration as ListWidgetConfiguration}
                    />
                )
            case WidgetType.TEXT:
                return (
                    <TextWidgetViewer
                        calculator={this.props.calculator!}
                        configuration={this.props.widget.configuration as TextWidgetConfiguration}
                    />
                )
            case WidgetType.VALUE:
                return (
                    <ValueWidgetViewer
                        calculator={this.props.calculator!}
                        configuration={this.props.widget.configuration as ValueWidgetConfiguration}
                    />
                )
            case WidgetType.CHANGE_LIST:
                return (
                    <ChangeListWidgetViewer
                        calculator={this.props.calculator!}
                        configuration={this.props.widget.configuration as ChangeListWidgetConfiguration}
                    />
                )
            case WidgetType.INPUT:
                return (
                    <InputWidgetViewer
                        calculator={this.props.calculator!}
                        configuration={this.props.widget.configuration as InputWidgetConfiguration}
                        appletConfiguration={this.props.configuration}
                        onUpdate={this.props.onUpdate}
                    />
                )
            case WidgetType.INPUT_FLOW:
                return (
                    <InputFlowWidgetViewer
                        calculator={this.props.calculator!}
                        configuration={this.props.widget.configuration as InputFlowWidgetConfiguration}
                        appletConfiguration={this.props.configuration}
                        onUpdate={this.props.onUpdate}
                    />
                )
            case WidgetType.INPUT_PANEL:
                return (
                    <InputPanelWidgetViewer
                        calculator={this.props.calculator!}
                        configuration={this.props.widget.configuration as InputPanelWidgetConfiguration}
                        appletConfiguration={this.props.configuration}
                        onUpdate={this.props.onUpdate}
                    />
                )
            case WidgetType.FILTER:
                return (
                    <FilterWidgetViewer
                        calculator={this.props.calculator!}
                        configuration={this.props.widget.configuration as FilterWidgetConfiguration}
                        onUpdate={this.props.onUpdate}
                    />
                )
            case WidgetType.PANEL:
                return (
                    <PanelWidgetViewer
                        calculator={this.props.calculator!}
                        configuration={this.props.widget.configuration as PanelWidgetConfiguration}
                        applet={this.props.applet}
                        appletConfiguration={this.props.configuration}
                        onConfiguration={this.props.onUpdate}
                    />
                )
            case WidgetType.SELECT:
                return (
                    <SelectWidgetViewer
                        calculator={this.props.calculator!}
                        configuration={this.props.widget.configuration as SelectWidgetConfiguration}
                    />
                )
            case WidgetType.SWITCH:
                return (
                    <SwitchWidgetViewer
                        calculator={this.props.calculator!}
                        configuration={this.props.widget.configuration as SwitchWidgetConfiguration}
                        applet={this.props.applet}
                        appletConfiguration={this.props.configuration}
                        onConfiguration={this.props.onUpdate}
                    />
                )
            case WidgetType.DIVIDER:
                return (
                    <DividerWidgetViewer
                        configuration={this.props.widget.configuration as DividerWidgetConfiguration}
                    />
                )
            case WidgetType.WATCHLIST:
                return (
                    <WatchlistWidgetViewer
                        calculator={this.props.calculator!}
                        configuration={this.props.widget.configuration as WatchlistWidgetConfiguration}
                        appletConfiguration={this.props.configuration}
                        onUpdate={this.props.onUpdate}
                    />
                )
            default:
                return (
                    <div>Unsupported widget type '{type}'.</div>
                )
        }
    }

    private buildRowView(configuration: RowWidgetConfiguration, view: ReactElement): ReactElement {
        // const gap = Globals.spacing(configuration.gap);
        // const alignment = CoreUtils.toLower(configuration.alignment, "_", "-") as any;
        // const justification = CoreUtils.toLower(configuration.justification, "_", "-") as any;
        // return (
        //     <Row gutter={[gap, gap]} justify={justification} align={alignment} wrap={false}>
        //         {view}
        //     </Row>
        // )
        return view;
    }

    private buildColumnView(configuration: ColumnWidgetConfiguration, view: ReactElement): ReactElement {
        // Hoist the column outside the card to maintain correct positioning.
        const span = !CoreUtils.isEmpty(configuration.span) ? configuration.span * Globals.LAYOUT_SCALE : undefined;
        const offset = !CoreUtils.isEmpty(configuration.offset) ? configuration.offset * Globals.LAYOUT_SCALE : undefined;
        return (
            <Col span={span} offset={offset}>
                {view}
            </Col>
        )
        //return view;
    }

    private buildMargin(margin: Margin): string {
        const t = Globals.spacing(margin[0]);
        const r = Globals.spacing(margin[1]);
        const b = Globals.spacing(margin[2]);
        const l = Globals.spacing(margin[3]);
        return `${t}px ${r}px ${b}px ${l}px`;
    }

    private buildView(view: ReactElement, isEdit: boolean, extra?: ReactElement): ReactElement {
        const widget = this.props.widget;
        let style = {} as any;
        if (widget.configuration.margin) {
            style.margin = this.buildMargin(widget.configuration.margin);
        }
        view = (
            <div className="x-widgetviewer" style={style}>
                {view}
            </div>
        )
        // view = (
        //     <div className={classNames({ "x-widgetviewer-fill": !!widget.configuration.fill })} style={style}>
        //         {view}
        //     </div>
        // )
        if (isEdit) {
            // Wrap with a card that has edit options.
            view = (
                <Card className="x-widgetviewer-card"
                    size="small"
                    extra={extra}
                    //title={`${widget.name} - ${CoreUtils.toProper(widget.configuration.type, "_")} Widget`}
                    title={widget.name}
                >
                    {view}
                </Card>
            );
        }
        const type = widget.configuration.type;
        if (type === WidgetType.ROW) {
            const configuration = widget.configuration as RowWidgetConfiguration;
            return this.buildRowView(configuration, view);
        } else if (type === WidgetType.COLUMN) {
            const configuration = widget.configuration as ColumnWidgetConfiguration;
            return this.buildColumnView(configuration, view);
        } else {
            return view;
        }
    }

    public componentDidMount(): void {
        this.props.calculator.addCallback("ExecutionComplete", this.handleExecutionComplete);
        this.props.calculator.addCallback("QueryRefresh", this.handleQueryRefresh);
    }

    public componentWillUnmount(): void {
        this.props.calculator.removeCallback("ExecutionComplete", this.handleExecutionComplete);
        this.props.calculator.removeCallback("QueryRefresh", this.handleQueryRefresh);
    }

    public render(): ReactElement {
        const isLoading = this.props.calculator.status !== Calculator.Status.READY || this.state.isRefreshing;
        const view = isLoading ? this.buildLoadingView() : this.buildWidgetView();
        if (this.props.showHeader) {
            const extra = (
                <div>
                    {this.props.extra}
                </div>
            );
            return this.buildView(view, true, extra);
        } else {
            return this.buildView(view, false);
        }
    }

}
