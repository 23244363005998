import { ReactElement, useEffect, useState } from 'react';
import { Select } from 'antd';
import { RestUtils } from 'utils/RestUtils';
import { WorkflowHeader } from '@methodset/workflow-client-ts';
import { AccessType, Version } from '@methodset/commons-core-ts';
import { StatusType } from 'constants/StatusType';
import { LoadSelect } from 'components/LoadSelect/LoadSelect';
import axios from 'axios';
import classNames from 'classnames';
import workflowService from 'services/WorkflowService';
import './WorkflowPicker.less';

export type ChangeCallback = (workflowId: string, version: number) => void;

export type WorkflowPickerProps = {
    className?: string,
    workflowId?: string,
    onChange: ChangeCallback
}

export const WorkflowPicker = (props: WorkflowPickerProps): ReactElement => {

    const [status, setStatus] = useState<StatusType>(StatusType.INIT);
    const [headers, setHeaders] = useState<WorkflowHeader[]>([]);

    useEffect(() => {
        loadData();
    }, []);

    const handleRetryLoad = (): void => {
        loadData();
    }

    const handleWorkflowChange = (workflowId: string, headers: WorkflowHeader[]): void => {
        const header = headers.find(header => header.id === workflowId);
        if (header) {
            props.onChange(header.id, header.version);
        } else {
            props.onChange(workflowId, Version.EDITOR);
        }
    }

    const readWorkflowHeadersRequest = (): Promise<any> => {
        const request = {
            accessType: AccessType.UNPUBLISHED,
            useLatest: true
        };
        return workflowService.readWorkflowHeaders(request,
            (response: any) => readWorkflowHeadersResponse(response),
            undefined, true
        );
    }

    const readWorkflowHeadersResponse = (response: any): WorkflowHeader[] => {
        const headers = response.data.headers;
        setHeaders(headers);
        return headers;
    }

    const loadData = (): void => {
        const requests = [];
        requests.push(readWorkflowHeadersRequest());
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((headers) => {
            if (RestUtils.isOk(headers)) {
                if (props.workflowId) {
                    handleWorkflowChange(props.workflowId, headers);
                }
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
    }

    return (
        <div className={classNames('x-workflowpicker', props.className)}>
            <LoadSelect
                placeholder="Select a workflow."
                value={props.workflowId}
                status={status}
                failMessage="Failed to load workflows."
                onChange={(workflowId) => handleWorkflowChange(workflowId, headers)}
                onRetry={handleRetryLoad}
            >
                {headers.map(header => (
                    <Select.Option
                        key={header.id}
                        value={header.id}
                    >
                        {header.name}
                    </Select.Option>
                ))}
            </LoadSelect>
        </div>
    );

}
