import { FC, ReactElement, Ref, useEffect, useRef, useState } from 'react';
import { Button, Form, FormInstance, Modal, Result, Select } from 'antd';
import { Globals } from 'constants/Globals';
import { Spacer } from 'components/Spacer/Spacer';
import { CoreUtils } from 'utils/CoreUtils';
import { PackHeader } from '@methodset/library-client-ts';
import { StatusType } from 'constants/StatusType';
import { RestUtils } from 'utils/RestUtils';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { TextMessage } from 'components/TextMessage/TextMessage';
import axios from 'axios';
import libraryService from 'services/LibraryService';
import './PackReverter.less';
import { FormItem } from 'components/FormItem/FormItem';

export type CancelCallback = () => void;
export type RevertCallback = (header: PackHeader) => void;

export type PackReverterProps = {
    packHeader: PackHeader,
    onRevert: RevertCallback,
    onCancel: CancelCallback
}

export const PackReverter: FC<PackReverterProps> = (props: PackReverterProps): ReactElement => {

    // Status of requests.
    const [status, setStatus] = useState<StatusType>(StatusType.INIT);
    // All pack versions.
    const [versions, setVersions] = useState<number[]>([]);
    // The selected version.
    const [version, setVersion] = useState<number>();
    // Pack header after successful reversion.
    const [packHeader, setPackHeader] = useState<PackHeader>();
    // True if reverting.
    const [isReverting, setIsReverting] = useState<boolean>(false);
    // Error when releasing.
    const [error, setError] = useState<Error>();

    const formRef: Ref<FormInstance> = useRef(null);

    useEffect(() => {
        loadData();
    }, []);

    const handleRetryLoad = (): void => {
        loadData();
    }

    const handleVersionChange = (version: number): void => {
        setVersion(version);
    }

    const handleRevert = (): void => {
        formRef.current!.validateFields().then(values => {
            revertPackRequest();
        }).catch(e => {
        });
    }

    const handleCancel = (): void => {
        props.onCancel();
    }

    const readPackVersionsRequest = (): Promise<any> => {
        const request = {
            packId: props.packHeader.id
        }
        return libraryService.readPackVersions(request,
            (response: any) => readPackVersionsResponse(response),
            undefined, true
        );
    }

    const readPackVersionsResponse = (response: any): void => {
        const versions = response.data.versions;
        setVersions(versions);
    }

    const revertPackRequest = (): Promise<any> => {
        setError(undefined);
        setIsReverting(true);
        const request = {
            packId: props.packHeader.id,
            version: version
        }
        return libraryService.revertPack(request,
            (response: any) => revertPackResponse(response),
            (response: any) => revertPackException(response),
            true
        );
    }

    const revertPackResponse = (response: any): void => {
        const packHeader = response.data.header;
        setPackHeader(packHeader);
        setIsReverting(false);
    }

    const revertPackException = (response: any): void => {
        const error = RestUtils.getError(response);
        setError(error);
        setIsReverting(false);
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        return (
            <LoadSpinner
                className="x-packreverter-loading"
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load versions."
                onRetry={handleRetryLoad}
            />
        );
    }

    const buildRevertView = (): ReactElement => {
        const message = `The last deployed pack is version ${props.packHeader.version}. Select an existing version that will be deployed again to become the latest version.`;
        return (
            <Form ref={formRef}>
                <Spacer direction="vertical">
                    <div>{message}</div>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={formRef}
                        name="version"
                        rules={[{
                            required: true,
                            message: "Select a version."
                        }]}
                    >
                        <Select
                            className="x-packreverter-versions"
                            placeholder="Select a version."
                            value={version}
                            onChange={handleVersionChange}
                        >
                            {versions.filter(version => version !== props.packHeader.version).map(version => (
                                <Select.Option key={version} value={version}>{CoreUtils.toVersion(version)}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                </Spacer>
            </Form>
        )
    }

    const buildSuccessView = (): ReactElement => {
        const message = `A new version ${packHeader!.version} has been deployed, which is a copy of version ${version}.`
        return (
            <Result
                status="success"
                title="Successfully rolled back package!"
                subTitle={message}
            />
        )
    }

    const loadData = (): void => {
        const requests = [];
        requests.push(readPackVersionsRequest());
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
    }

    let view;
    if (status === StatusType.LOADING) {
        view = buildLoadingView(true);
    } else if (status === StatusType.FAILED) {
        view = buildLoadingView(false);
    } else if (status === StatusType.READY) {
        view = !packHeader ? buildRevertView() : buildSuccessView();
    }

    return (
        <Modal
            className="x-packreverter"
            centered
            title="Rollback Package"
            width={Globals.DIALOG_WIDTH_STANDARD}
            onOk={handleRevert}
            onCancel={handleCancel}
            visible={true}
            footer={(
                <>
                    {!packHeader &&
                        <>
                            <Button disabled={isReverting} onClick={handleCancel}>Cancel</Button>
                            <Button type="primary" loading={isReverting} onClick={handleRevert}>Rollback</Button>
                        </>
                    }
                    {!!packHeader &&
                        <Button type="primary" onClick={() => props.onRevert(packHeader)}>Close</Button>
                    }
                </>
            )}
        >
            {view}
            <TextMessage className="x-packreverter-error" type="error" message={error?.message} />
        </Modal>
    )

}
