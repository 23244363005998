import { FC, ReactElement, Ref, useRef, useState } from 'react';
import { red } from '@ant-design/colors';
import { Cell } from '@methodset/calculator-ts';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import './ErrorCell.less';

export type ErrorCellProps = {
    className?: string,
    value: any,
    cell: Cell
}

interface Location {
    type: string;
    id: string;
}

export const ErrorCell: FC<ErrorCellProps> = (props: ErrorCellProps): ReactElement => {

    const ref: Ref<HTMLDivElement> = useRef(null);

    const [focus, setFocus] = useState<boolean>(false);

    const findLocation = (): Location | null => {
        const cell = props.cell.value?.cause?.cell;
        if (!cell || cell === props.cell) {
            return null;
        }
        const sheet = cell.sheet;
        if (sheet.isParametersSheet()) {
            return {
                type: "parameter",
                id: cell.uid
                //id: cell.variable.name
            }
        } else {
            return {
                type: "cell",
                id: sheet === props.cell.sheet ? cell.id : cell.uid
            }
        }
    }

    const location = findLocation();

    const title = (
        <div className="x-errorcell-message">
            <div className="x-errorcell-header">
                <div>
                    <span>Error</span>
                    {location &&
                        <span> in {location.type} {location.id}</span>
                    }
                </div>
            </div>
            <div>
                {props.cell.value.message}
            </div>
        </div>
    );
    return (
        <div className={classNames("x-errorcell", props.className)}>
            <Tooltip
                destroyTooltipOnHide={true}
                title={title}
                color={red[0]}
                visible={focus}
                placement="rightTop"
            >
                <div
                    ref={ref}
                    //onClick={() => setFocus(!focus)}
                    onMouseEnter={() => setFocus(true)}
                    onMouseLeave={() => setFocus(false)}
                >
                    {props.value}
                </div>
            </Tooltip>
            <div className="x-errorcell-mark"></div>
        </div>
    );

}
