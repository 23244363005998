import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { FormInstance, Input } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { Credentials, CredentialsType, WebCredentials } from '@methodset/endpoint-client-ts';
// import { Credentials } from 'types/endpoint/authentication/Credentials';
// import { WebCredentials } from 'types/endpoint/authentication/WebCredentials';
// import { CredentialsType } from 'types/endpoint/authentication/CredentialsType';
import update from 'immutability-helper';
import classNames from 'classnames';
import './WebCredentialsEditor.less';

export type ChangeCallback = (credentials: Credentials) => void;

export type WebCredentialsEditorProps = typeof WebCredentialsEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    className?: string,
    credentials: WebCredentials,
    onChange: ChangeCallback
}

export class WebCredentialsEditor extends PureComponent<WebCredentialsEditorProps> {

    static defaultProps = {
        credentials: {
            type: CredentialsType.WEB
        } as WebCredentials
    }

    constructor(props: WebCredentialsEditorProps) {
        super(props);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }

    private handleUsernameChange(e: ChangeEvent<HTMLInputElement>) {
        const username = e.target.value;
        const credentials = update(this.props.credentials, {
            username: { $set: username }
        });
        this.props.onChange(credentials);
    }

    private handlePasswordChange(e: ChangeEvent<HTMLInputElement>) {
        const password = e.target.value;
        const credentials = update(this.props.credentials, {
            password: { $set: password }
        });
        this.props.onChange(credentials);
    }

    public render(): ReactElement {
        return (
            <div className={classNames('x-webcredentialseditor', this.props.className)}>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Username"
                    name="username"
                    rules={[{
                        required: true,
                        message: 'Please enter a username.'
                    }]}
                >
                    <Input
                        placeholder="Enter a username."
                        value={this.props.credentials.username}
                        onChange={this.handleUsernameChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Password"
                    name="password"
                    rules={[{
                        required: true,
                        message: 'Please enter a password.'
                    }]}
                >
                    <Input.Password
                        placeholder="Enter a password."
                        value={this.props.credentials.password}
                        onChange={this.handlePasswordChange}
                    />
                </FormItem>
            </div>
        );
    }

}
