import { PureComponent, ReactElement } from 'react';
import { Globals } from 'constants/Globals';
import './Footer.less';

export class Footer extends PureComponent {

    public render(): ReactElement {
        return (
            <div className="x-footer">&copy; {(new Date().getFullYear())} {Globals.COMPANY_NAME}</div>
        );
    }

}
