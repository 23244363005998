import { FC, ReactElement, Ref, useRef, useState } from 'react';
import { Form, FormInstance, Modal, Switch } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { IntegerInput } from 'components/IntegerInput/IntegerInput';
import './RefreshInterval.less';

type Props = {
    interval: number
    onChange: (interval: number) => void,
    onCancel: () => void
}

export const RefreshInterval: FC<Props> = (props: Props): ReactElement => {

    const formRef: Ref<FormInstance> = useRef(null);
    const [refresh, setRefresh] = useState<boolean>(props.interval !== 0);
    const [interval, setInterval] = useState<number | undefined>(props.interval);

    const handleChange = (interval: number): void => {
        setInterval(interval);
    }

    const handleOk = (): void => {
        formRef.current?.submit();
    }

    const handleCancel = (): void => {
        props.onCancel();
    }

    const handleFormFinish = (): void => {
        props.onChange(interval ? interval : 0);
    }

    const handleRefreshChange = (checked: boolean): void => {
        if (!checked) {
            setInterval(0);
        }
        setRefresh(checked);
    }

    return (
        <Modal
            className="x-refreshinterval"
            centered
            title="Refresh Interval"
            width={300}
            onOk={handleOk}
            onCancel={handleCancel}
            visible={true}>
            <Form ref={formRef} onFinish={handleFormFinish}>
                <FormItem
                    formRef={formRef}
                    label="Automatic Refresh"
                    name="active"
                >
                    <Switch
                        checked={refresh}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                        onChange={(checked) => handleRefreshChange(checked)}
                    />
                </FormItem>
                <FormItem
                    formRef={formRef}
                    name="interval"
                    noLabel={true}
                    rules={[{
                        validator: (rule: any, name: string) => {
                            return (!refresh || (refresh && interval)) ? Promise.resolve() : Promise.reject("Enter a refresh interval.");
                        }
                    }]}
                >
                    <IntegerInput
                        placeholder="Refresh interval."
                        disabled={!refresh}
                        natural={true}
                        min={10}
                        value={interval}
                        justification="right"
                        prefix="Every"
                        units="seconds"
                        onChange={handleChange}
                    />
                </FormItem>
            </Form>
        </Modal>
    )

}
