import { ReactElement, Ref, useRef, useState } from 'react';
import { Form, FormInstance, List } from 'antd';
import { Income } from '@methodset/entity-client-ts';
import { IncomeEditor } from './IncomeEditor/IncomeEditor';
import { ListEditor, RenderFunction } from '../ListEditor/ListEditor';
import './IncomesEditor.less';

export type ChangeCallback = (incomes: Income[]) => void;

export type IncomesEditorProps = {
    formRef: React.RefObject<FormInstance>,
    withForm?: boolean,
    id: string,
    incomes?: Income[],
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    incomes: [] as Income[]
}

export const IncomesEditor = (props: IncomesEditorProps): ReactElement => {

    const [editIncome, setEditIncome] = useState<Income | undefined>();

    const handleIncomeChange = (income: Income): void => {
        setEditIncome(income);
    }

    const handleIncomeEdit = (income: Income): void => {
        setEditIncome(income);
    }

    const handleIncomesSave = (incomes: Income[]): void => {
        setEditIncome(undefined);
        incomes.sort((a, b) => a.startDate < b.startDate ? -1 : 1);
        props.onChange(incomes);
    }

    const renderItem = (income: Income): ReactElement => {
        return (
            <div className="x-incomeseditor">
                <div className="x-incomeseditor-left">
                    <div className="x-incomeseditor-name">{income.name}</div>
                    <div className="x-incomeseditor-bottom">{income.source}</div>
                </div>
                <div className="x-incomeseditor-right">
                    <div className="x-incomeseditor-top">${income.amount ? income.amount.toLocaleString("en-US") : 0}</div>
                    <div className="x-incomeseditor-bottom">{income.startDate} to {income.endDate ? income.endDate : "Present"}</div>
                </div>
            </div>
        )
    }

    const buildView = (): ReactElement => {
        return (
            <ListEditor
                formRef={props.formRef}
                id={props.id}
                name="Income"
                items={props.incomes}
                editItem={editIncome}
                render={renderItem}
                onEdit={(income) => handleIncomeEdit(income)}
                onSave={(incomes) => handleIncomesSave(incomes)}
            >
                <IncomeEditor
                    formRef={props.formRef}
                    id={props.id}
                    income={editIncome}
                    onChange={(income) => handleIncomeChange(income)}
                />
            </ListEditor>
        )
    }

    return (
        <>
            {props.withForm &&
                <Form ref={props.formRef}>
                    {buildView()}
                </Form>
            }
            {!props.withForm &&
                buildView()
            }
        </>
    )

}

IncomesEditor.defaultProps = defaultProps;
