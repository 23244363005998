import { Chapter } from 'components/Chapter/Chapter';
import { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DocFrame } from '../DocFrame/DocFrame';

export type GroupsDocProps = RouteComponentProps;

export const GroupsDoc = (props: GroupsDocProps): ReactElement => {

    return (
        <DocFrame title="Groups" {...props}>
            <Chapter>
                Groups represent a set of people within an organization. Each user by default is part
                of their own group of which they are the leader. However, other users can be invited
                to join the group so that they can work together. When users are part of the same group,
                they see the same data and can make changes to any components within the leaders group.
                For example, a group may represent a team within a company that is working on a task.
                Group members may be support staff or other resources that are assisting in creating
                the datasets, models, and workflows for that team.
            </Chapter>
        </DocFrame>
    );

}
