import { ReactElement } from 'react';
import { WeightType } from '@methodset/application-client-ts';
import { Button, Dropdown, Menu } from 'antd';
import { CoreUtils } from 'utils/CoreUtils';
import classNames from 'classnames';
import './WeightStyle.less';

export type ChangeCallback = (weight: WeightType) => void;

export type WeightStyleProps = {
    weight?: WeightType,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    weight: WeightType.NORMAL
}

export const WeightStyle = (props: WeightStyleProps): ReactElement => {

    const handleSelect = (info: any) => {
        props.onChange(info.key);
    }

    const menu = (
        <Menu onClick={handleSelect}>
            {Object.entries(WeightType).map(([key, value]) =>
                <Menu.Item key={key}>
                    {CoreUtils.toCapital(value)}
                </Menu.Item>
            )}
        </Menu>
    );

    return (
        <div className="x-weightstyle">
            <Dropdown
                overlay={menu}
                trigger={['click']}
            >
                <Button
                    className={classNames(
                        { [`x-widgetviewer-weight-${CoreUtils.toLower(props.weight)}`]: !!props.weight }
                    )}
                >
                    B
                </Button>
            </Dropdown>
        </div>

    )
}

WeightStyle.defaultProps = defaultProps;
