import React, { ReactElement, useState } from 'react';
import { FormInstance } from 'antd';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { WorkflowPicker } from './WorkflowPicker/WorkflowPicker';
import { Version } from '@methodset/commons-core-ts';
import { CoreUtils } from 'utils/CoreUtils';
import classNames from 'classnames';
import './WorkflowSelector.less';

export type ChangeCallback = (workflowId: string) => void;

export type WorkflowSelectorProps = {
    formRef: React.RefObject<FormInstance>,
    className?: string,
    workflowId?: string,
    label?: string,
    description?: string,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    label: "Workflow Source",
    description: "The workflow that generates the source data. Note that the workflow must be published or it will not be displayed in the dropdown."
}

export const WorkflowSelector = (props: WorkflowSelectorProps): ReactElement => {

    const [version, setVersion] = useState<number>(Version.EDITOR);

    const handleWorkflowChange = (workflowId: string, version: number): void => {
        setVersion(version);
        props.onChange(workflowId);
    }

    const extra = (
        <span className="x-workflowpicker-version">
            {CoreUtils.toVersion(version)}
        </span>
    )

    return (
        <div className={classNames('x-workflowselector', props.className)}>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label={props.label}
                name="workflow"
                info={props.description}
                valuePropName="workflowId"
                justification="center"
                extra={extra}
                rules={[{
                    required: true,
                    message: `Please select a workflow.`
                }]}
            >
                <WorkflowPicker
                    workflowId={props.workflowId}
                    onChange={handleWorkflowChange}
                />
            </FormItem>
        </div>
    );

}

WorkflowSelector.defaultProps = defaultProps;
