import { 
    BooleanConfigurationSpec,
    ConfigurationSpec,
    ConfigurationType,
    CredentialsConfigurationSpec,
    DateConfigurationSpec,
    DisplayType,
    IoType,
    MultiOptionConfigurationSpec,
    NumberConfigurationSpec,
    OptionConfigurationSpec, 
    OptionType,
    TextConfigurationSpec,
    TimeConfigurationSpec,
    TimeZoneConfigurationSpec,
    RequirementType
} from '@methodset/endpoint-client-ts';

export class ConfigurationUtils {

    /**
     * Creates a configuration spec.
     * 
     * @param type The configuration type.
     * @param key The configuration key.
     * @param name The configuration name.
     * @param description The configuration description.
     * @returns The configuration spec.
     */
    public static createConfigurationSpec(type: ConfigurationType, key: string, name: string, description?: string): ConfigurationSpec | null {
        // Create configuration spec from parameter and parameter spec.
        switch (type) {
            case ConfigurationType.TEXT:
                return {
                    type: ConfigurationType.TEXT,
                    ioType: IoType.TEXT,
                    key: key,
                    name: name,
                    description: description,
                    requirementType: RequirementType.REQUIRED
                } as TextConfigurationSpec;
            case ConfigurationType.NUMBER:
                return {
                    type: ConfigurationType.NUMBER,
                    ioType: IoType.NUMBER,
                    key: key,
                    name: name,
                    description: description,
                    requirementType: RequirementType.REQUIRED
                } as NumberConfigurationSpec;
            case ConfigurationType.BOOLEAN:
                return {
                    type: ConfigurationType.BOOLEAN,
                    ioType: IoType.BOOLEAN,
                    key: key,
                    name: name,
                    description: description,
                    requirementType: RequirementType.REQUIRED
                } as BooleanConfigurationSpec;
            case ConfigurationType.DATE:
                return {
                    type: ConfigurationType.DATE,
                    ioType: IoType.DATE,
                    key: key,
                    name: name,
                    description: description,
                    requirementType: RequirementType.REQUIRED
                } as DateConfigurationSpec;
            case ConfigurationType.TIME:
                return {
                    type: ConfigurationType.TIME,
                    ioType: IoType.TIME,
                    key: key,
                    name: name,
                    description: description,
                    requirementType: RequirementType.REQUIRED
                } as TimeConfigurationSpec;
            case ConfigurationType.TIME_ZONE:
                return {
                    type: ConfigurationType.TIME_ZONE,
                    ioType: IoType.TEXT,
                    key: key,
                    name: name,
                    description: description,
                    requirementType: RequirementType.REQUIRED
                } as TimeZoneConfigurationSpec;
            case ConfigurationType.OPTION:
                return {
                    type: ConfigurationType.OPTION,
                    ioType: IoType.TEXT,
                    key: key,
                    name: name,
                    description: description,
                    requirementType: RequirementType.REQUIRED,
                    displayType: DisplayType.SELECT,
                    optionType: OptionType.VALUE,
                    options: []
                } as OptionConfigurationSpec;
            case ConfigurationType.MULTI_OPTION:
                return {
                    type: ConfigurationType.MULTI_OPTION,
                    ioType: IoType.TEXT_ARRAY,
                    key: key,
                    name: name,
                    description: description,
                    requirementType: RequirementType.REQUIRED,
                    //optionType: IoType.ANY,
                    options: []
                } as MultiOptionConfigurationSpec;
            case ConfigurationType.CREDENTIALS:
                return {
                    type: ConfigurationType.CREDENTIALS,
                    ioType: IoType.CREDENTIALS,
                    key: key,
                    name: name,
                    description: description,
                    requirementType: RequirementType.REQUIRED
                } as CredentialsConfigurationSpec;
            default:
                return null;
        }
    }

}