import { ReactElement, useEffect, useState } from 'react';
import { RestUtils } from 'utils/RestUtils';
import { StatusType } from 'constants/StatusType';
import { Data } from '@methodset/endpoint-client-ts';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { DataViewer } from 'containers/Console/DataPreview/DataViewer/DataViewer';
import { NoData } from 'components/NoData/NoData';
import endpointService from 'services/EndpointService';
import axios from 'axios';
import classNames from 'classnames';
import './OutputView.less';

export type OutputViewProps = {
    className?: string,
    executionId: string,
    instanceId: string,
}

export const OutputView = (props: OutputViewProps): ReactElement => {

    const [status, setStatus] = useState<StatusType>(StatusType.INIT);
    const [data, setData] = useState<Data>();

    useEffect(() => {
        loadData();
    }, []);

    const handleRetryLoad = (): void => {
        loadData();
    }

    const readTestWriterOutputRequest = (): Promise<any> => {
        const request = {
            executionId: props.executionId,
            instanceId: props.instanceId
        };
        return endpointService.readTestWriterOutput(request,
            (response: any) => readTestWriterOutputResponse(response),
            undefined, true
        );
    }

    const readTestWriterOutputResponse = (response: any): void => {
        const output = response.data.output;
        const data = JSON.parse(output);
        setData(data);
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        return (
            <LoadSpinner
                className="x-outputview-loading"
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load outputs."
                onRetry={handleRetryLoad}
            />
        );
    }

    const buildOutputView = (): ReactElement | ReactElement[] => {
        if (data) {
            return (
                <DataViewer
                    className="x-outputview-data"
                    data={data}
                    showName={true}
                    enableRaw={true}
                />
            )
        } else {
            return (
                <NoData text="No outputs." />
            )
        }
    }

    const loadData = (): void => {
        const requests = [];
        requests.push(readTestWriterOutputRequest());
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((headers) => {
            if (RestUtils.isOk(headers)) {
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
    }

    let view;
    if (status === StatusType.LOADING) {
        view = buildLoadingView(true);
    } else if (status === StatusType.FAILED) {
        view = buildLoadingView(false);
    } else if (status === StatusType.READY) {
        view = buildOutputView();
    }

    return (
        <div className={classNames("x-outputview", props.className)}>
            {view}
        </div>
    );

}
