import { ChangeEvent, ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { Button, Divider, Form, FormInstance, Input, Select } from 'antd';
import { Globals } from 'constants/Globals';
import { ModelContext } from 'context/ModelContext';
import { Applet, Application, ApplicationType, CategoryType } from '@methodset/application-client-ts';
import { AppletEditor } from './AppletEditor/AppletEditor';
import { RouteComponentProps } from 'react-router-dom';
import { RouteBuilder } from 'utils/RouteBuilder';
import { AppletSync } from 'sync/AppletSync';
import { FormItem } from 'components/FormItem/FormItem';
import { Spacer } from 'components/Spacer/Spacer';
import { CoreUtils } from 'utils/CoreUtils';
import { Version } from '@methodset/commons-core-ts';
import { RestUtils } from 'utils/RestUtils';
import { TextMessage } from 'components/TextMessage/TextMessage';
import { Calculator } from '@methodset/calculator-ts';
import update from 'immutability-helper';
import applicationService from 'services/ApplicationService';
import modelService from 'services/ModelService';
import './ModelApplet.less';

type MatchParams = {
    modelId: string,
    applicationId: string
}

const NullApplet = {} as Applet;

export type ModelAppletProps = RouteComponentProps<MatchParams> & {
}

export const ModelApplet = (props: ModelAppletProps): ReactElement => {

    // The model context.
    const context = useContext(ModelContext);
    // The form reference.
    const formRef = useRef<FormInstance>(null);
    // This is a new applet.
    const isCreating = useRef<boolean>(false);
    // Monitor for url changes.
    const unmonitor = useRef<any>();
    // The state of exiting.
    const exiting = useRef<boolean>(false);
    // The applet being edited.
    const [applet, setApplet] = useState<Applet>(NullApplet);
    // Saving state.
    const [isSaving, setIsSaving] = useState<boolean>(false);
    // Error during save.
    const [error, setError] = useState<Error | undefined>();

    useEffect(() => {
        const applicationId = props.match.params.applicationId;
        const applications: Application[] = context.applications;
        const index = applications.findIndex(application => application.id === applicationId && application.type === ApplicationType.APPLET);
        let applet: Applet;
        if (context.application && context.application.id === applicationId) {
            applet = context.application as Applet;
        } else if (index !== -1) {
            applet = applications[index] as Applet;
        } else {
            const modelId = props.match.params.modelId;
            applet = {
                type: ApplicationType.APPLET,
                id: applicationId,
                version: Version.EDITOR,
                modelId: modelId,
                name: "New Applet",
                title: undefined as any,
                span: Globals.APPLET_DEFAULT_SPAN,
                widgets: [],
                panels: [],
                categoryType: CategoryType.GENERAL,
                menu: []
            }
            isCreating.current = true;
        }
        setApplet(applet);
        //context.saveApplication(undefined);
        //context.saveApplication(applet);
        //const store = useStore();
        // unmonitor.current = props.history.block(handleUrlChange);
        // unmonitor.current = props.history.block(() => {
        //     const store = useStore();
        //     const applet = store.getState().applet;
        //     if (applet !== NullApplet) {
        //         context.saveApplication(applet);
        //     }
        // });
        // return () => {
        //     unmonitor.current();
        // }
    }, []);

    useEffect(() => {
        unmonitor.current = props.history.block(handleUrlChange);
        return () => {
            unmonitor.current();
        }
    }, [applet]);

    const handleUrlChange = (): void => {
        if (exiting.current) {
            context.saveApplication(undefined);
        } else {
            context.saveApplication(applet);
        }
    }

    const handleNameBlur = (): void => {
        if (!applet.title) {
            const updated = update(applet, {
                title: { $set: applet.name }
            });
            setApplet(updated);
            //context.saveApplication(updated);
        }
    }

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const name = e.target.value;
        const updated = update(applet, {
            name: { $set: name }
        });
        setApplet(updated);
        //context.saveApplication(updated);
    }

    const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        const description = e.target.value;
        const updated = update(applet, {
            description: { $set: description }
        });
        setApplet(updated);
        //context.saveApplication(updated);
    }

    const handleCategoryChange = (categoryType: CategoryType): void => {
        const updated = update(applet, {
            categoryType: { $set: categoryType }
        });
        setApplet(updated);
        //context.saveApplication(updated);
    }

    const handleTitleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const title = e.target.value;
        const updated = update(applet, {
            title: { $set: title }
        });
        setApplet(updated);
        //context.saveApplication(updated);
        const registry = context.calculator!.registry;
        registry.register(applet.id, applet, AppletSync.parser, AppletSync.updater);
    }

    const handleSpanChange = (span: number): void => {
        const updated = update(applet, {
            span: { $set: span }
        });
        setApplet(updated);
        //context.saveApplication(updated);
    }

    const handleEditChange = (isEdit: boolean): void => {
        const modelId = props.match.params.modelId;
        const applicationId = props.match.params.applicationId;
        const path = `${applicationId}?edit=${isEdit ? "true" : "false"}`;
        props.history.push(RouteBuilder.applet(modelId, path));
    }

    const handleAppletChange = (applet: Applet): void => {
        setApplet(applet);
        //context.saveApplication(applet);
    }

    const handleAppletCancel = (): void => {
        const modelId = props.match.params.modelId;
        //setApplet(NullApplet);
        //context.saveApplication(undefined);
        exiting.current = true;
        props.history.push(RouteBuilder.applications(modelId));
    }

    const handleAppletSave = (): void => {
        formRef.current?.submit();
    }

    const handleFormFinish = (): void => {
        updateModelRequest();
    }

    const updateModelRequest = (): Promise<any> => {
        // TODO: check if needs updating
        setError(undefined);
        setIsSaving(true);
        const model = context.model!;
        const calculator = context.calculator!;
        const request = {
            modelId: model.id,
            name: model.name,
            description: model.description,
            keywords: model.keywords,
            autoSave: model.autoSave,
            calculator: Calculator.serialize(calculator)
        };
        return modelService.updateModel(request,
            (response: any) => updateModelResponse(response),
            (response: any) => updateException(response),
            true
        );
    }

    const updateModelResponse = (response: any): void => {
        if (isCreating.current) {
            createApplicationRequest();
        } else {
            updateApplicationRequest();
        }
    }

    const createApplicationRequest = (): Promise<any> => {
        //applet.id = undefined as any;
        const request = {
            application: applet
        };
        return applicationService.createApplication(request,
            (response: any) => createApplicationResponse(response),
            (response: any) => updateException(response),
            true
        );
    }

    const createApplicationResponse = (response: any): void => {
        isCreating.current = false;
        updateApplicationResponse(response);
    }

    const updateApplicationRequest = (): Promise<any> => {
        const request = {
            application: applet
        };
        return applicationService.updateApplication(request,
            (response: any) => updateApplicationResponse(response),
            (response: any) => updateException(response),
            true
        );
    }

    const updateApplicationResponse = (response: any): void => {
        const application = response.data.application;
        let applications = context.applications;
        const index = applications.findIndex(a => a.id === application.id);
        if (index === -1) {
            applications = update(applications, {
                $push: [application]
            })
        } else {
            const existing = applications[index];
            application.version = existing.version;
            applications = update(applications, {
                [index]: { $set: application }
            });
        }
        context.saveApplications(applications);
        //context.saveApplication(undefined);
        //setApplet(NullApplet);
        setIsSaving(false);
        exiting.current = true;
        props.history.push(RouteBuilder.applications(applet.modelId));
    }

    const updateException = (response: any): void => {
        const error = RestUtils.getError(response);
        setError(error);
        setIsSaving(false);
    }

    const actions = (
        <Spacer className="x-modelapplet-actions" justification="between">
            <TextMessage type="error" message={error?.message} fill />
            <Spacer justification="right">
                <Button onClick={handleAppletCancel}>Cancel</Button>
                <Button type="primary" loading={isSaving} onClick={handleAppletSave}>Done</Button>
            </Spacer>
        </Spacer>
    );
    return (
        <div id="modelapplet" className="x-modelapplet">
            <Spacer size="md" alignment="top">
                <Form
                    className="x-modelapplet-form"
                    ref={formRef}
                    onFinish={handleFormFinish}
                >
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={formRef}
                        label="Name"
                        name="name"
                        info="The name of the applet."
                        rules={[{
                            required: true,
                            message: 'Please enter a name.'
                        }]}
                    >
                        <Input
                            placeholder="Enter a name."
                            value={applet.name}
                            onBlur={handleNameBlur}
                            onChange={handleNameChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={formRef}
                        label="Description"
                        name="description"
                        info="The description of the applet."
                    >
                        <Input.TextArea
                            placeholder="Enter a description."
                            rows={3}
                            value={applet.description}
                            onChange={handleDescriptionChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={formRef}
                        label="Category"
                        name="category"
                        info="The category that describes the applet's purpose."
                        rules={[{
                            required: true,
                            message: 'Please select a category.'
                        }]}
                    >
                        <Select
                            allowClear={true}
                            value={applet.categoryType}
                            onChange={handleCategoryChange}
                        >
                            {CoreUtils.enumToKeys(CategoryType).map(key => (
                                <Select.Option key={key} value={key}>{CoreUtils.toProper(key, "_")}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={formRef}
                        label="Title"
                        name="title"
                        info="The title displayed on the applet header section and can contain variables. If not specified, the applet name will be used."
                        rules={[{
                            required: true,
                            message: 'Please enter a title.'
                        }]}
                    >
                        <Input
                            placeholder="Enter a title."
                            value={applet.title}
                            onChange={handleTitleChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={formRef}
                        label="Width"
                        name="span"
                        info={`The default number of columns the applet should span (1-${Globals.LAYOUT_COLUMNS}).`}
                        rules={[{
                            required: true,
                            message: "Please select a default column width."
                        }]}
                    >
                        <Select
                            value={applet.span}
                            onChange={handleSpanChange}
                        >
                            {Array.from(Array(Globals.LAYOUT_COLUMNS).keys()).map(index => (
                                <Select.Option key={index} value={index + 1}>{index + 1}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    {actions}
                </Form>
                <Divider className="x-appleteditor-div" type="vertical" />
                {applet !== NullApplet && context.model && context.calculator &&
                    <AppletEditor
                        {...props}
                        key={applet.id}
                        model={context.model}
                        applet={applet}
                        calculator={context.calculator}
                        onEdit={handleEditChange}
                        onChange={handleAppletChange}
                    />
                }
            </Spacer>
        </div>
    )

}
