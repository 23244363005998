import { ReactElement } from 'react';

export type ItalicProps = {
    className?: string,
    children: string | ReactElement
};

export const Italic = (props: ItalicProps): ReactElement => {

    return (
        <span className={props.className} style={{fontStyle: "italic"}}>{props.children}</span>
    )
}
