import { ReactElement, useEffect, useState } from 'react';
import { Button, Col, FormInstance, Row, Select } from 'antd';
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { LoadSkeleton, SkeletonSize } from 'components/LoadSkeleton/LoadSkeleton';
import { CoreUtils } from 'utils/CoreUtils';
import { Label } from 'components/Label/Label';
import { StatusType } from 'constants/StatusType';
import { AccessType, IdUtils } from '@methodset/commons-core-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { NoData } from 'components/NoData/NoData';
import axios from 'axios';
import './ItemSelect.less';

const UNCATEGORIZED_GROUP = "UNCATEGORIZED";

export interface SelectItem {
    id: string;
    name: string;
    type?: string;
}

export type SelectCallback<T> = (header: T) => void;
export interface ItemView<T> {
    index: number;
    header: T;
    onSelect: SelectCallback<T>;
}

export type LoadFunction<T> = (accessType: AccessType) => Promise<any>;
export type ChangeCallback<T> = (header: T) => void;

export type ItemSelectProps<T> = {
    formRef: React.RefObject<FormInstance>,
    // The selected id.
    selectedId?: string,
    // Number of columns to display results in.
    columns?: number,
    // True to group results by type, false otherwise.
    group?: boolean,
    // True to allow change of access, false otherwise.
    access?: boolean,
    // The label used in info messages.
    label: string,
    // The view to display one result.
    view: React.FC<ItemView<T>>,
    // The loading skeleton.
    skeleton?: SkeletonSize[],
    // The function that loads the items.
    loader: LoadFunction<T>,
    // Called when an item is selected.
    onChange: ChangeCallback<T>
} & typeof defaultProps;

const defaultProps = {
    columns: 2,
    group: false,
    access: true,
    skeleton: ["medium", "fill", "short", "short"]
}

export const ItemSelect = <T extends SelectItem>(props: ItemSelectProps<T>): ReactElement => {

    const [status, setStatus] = useState<StatusType>(StatusType.INIT);
    const [header, setHeader] = useState<T | undefined>();
    const [headers, setHeaders] = useState<T[]>([]);
    const [accessType, setAccessType] = useState<AccessType>(props.selectedId ? IdUtils.toAccessType(props.selectedId) : AccessType.UNPUBLISHED);
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        loadData(accessType);
    }, []);

    const handleRetryLoad = (): void => {
        loadData(accessType);
    }

    const handleAccessChange = (accessType: AccessType): void => {
        setAccessType(accessType);
        loadData(accessType);
    }

    const handleVisibleToggle = (visible: boolean): void => {
        setIsVisible(true);
    }

    const handleItemChange = (header: T): void => {
        props.onChange(header);
        setHeader(header);
        setIsVisible(false);
    }

    const handleHeaderCancel = (): void => {
        setIsVisible(false);
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        const rows = 2;
        const indices = Array.from(Array(props.columns * rows).keys());
        return (
            <div className="x-itemselect-wrap">
                <Row gutter={[Globals.GUTTER_SIZE, Globals.GUTTER_SIZE]}>
                    {indices.map(index => (
                        <Col
                            key={index}
                            md={{ span: 24 / props.columns }}
                        >
                            <div className="x-itemselect-item">
                                <LoadSkeleton
                                    direction="vertical"
                                    status={isLoading ? "loading" : "failed"}
                                    onRetry={() => handleRetryLoad()}
                                >
                                    {props.skeleton.map(size => (
                                        <LoadSkeleton.Input length={size} />
                                    ))}
                                </LoadSkeleton>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        );
    }

    const buildItemsView = (): ReactElement | ReactElement[] => {
        return (
            <div className="x-itemselect-wrap">
                {headers.length === 0 &&
                    buildNoItemsView()
                }
                {!props.group && headers.length > 0 &&
                    buildRowItemsView()
                }
                {props.group && headers.length > 0 &&
                    buildGroupedItemsView()
                }
            </div>
        )
    }

    const buildNoItemsView = (): ReactElement => {
        return (
            <Row>
                <div className="x-itemselect-empty">
                    <NoData text={`No ${props.label.toLowerCase()} available.`} />
                </div>
            </Row>
        )
    }

    const buildRowItemsView = (): ReactElement => {
        return (
            <Row className="x-itemselect-panel" gutter={[Globals.GUTTER_SIZE, Globals.GUTTER_SIZE]}>
                {headers.map((header, index) => (
                    <Col key={header.id} md={{ span: 24 / props.columns }}>
                        <props.view
                            key={header.id}
                            index={index}
                            header={header}
                            onSelect={handleItemChange}
                        />
                    </Col>
                ))}
            </Row>
        )
    }

    const buildGroupedItemsView = (): ReactElement => {
        const map: Map<string, T[]> = new Map();
        // Group by item type.
        headers.forEach(header => {
            let entry;
            const type = header.type ?? UNCATEGORIZED_GROUP;
            entry = map.get(type)
            if (!entry) {
                map.set(type, [header]);
            } else {
                entry.push(header);
            }
        });

        const keys = Array.from(map.keys());
        // If there is an uncategorized group, move to end of key list.
        const index = keys.findIndex(key => key === UNCATEGORIZED_GROUP);
        if (index !== -1) {
            keys.splice(index, 0);
            keys.push(UNCATEGORIZED_GROUP);
        }
        const elements: ReactElement[] = [];
        keys.map(key => {
            const headers = map.get(key)!;
            const element = (
                <div key={key} className="x-itemselect-section">
                    <div className="x-itemselect-label">{key}</div>
                    <Row gutter={[Globals.GUTTER_SIZE, Globals.GUTTER_SIZE]}>
                        {headers.map((header, index) => (
                            <Col md={{ span: 24 / props.columns }}>
                                <props.view
                                    key={header.id}
                                    index={index}
                                    header={header}
                                    onSelect={handleItemChange}
                                />
                            </Col>
                        ))}
                    </Row>
                </div>
            );
            elements.push(element);
        });
        return (
            <Spacer className="x-itemselect-panel" direction="vertical" size="xl">
                {elements}
            </Spacer>
        )
    }

    const buildDropdownView = (): ReactElement => {
        let queriesView;
        if (status === StatusType.LOADING) {
            queriesView = buildLoadingView(true);
        } else if (status === StatusType.FAILED) {
            queriesView = buildLoadingView(false);
        } else if (status === StatusType.READY) {
            queriesView = buildItemsView();
        } else {
            queriesView = <></>;
        }
        return (
            <div className="x-itemselect-dropdown">
                {props.access &&
                    <Row className="x-itemselect-header">
                        <Label className="x-itemselect-access" label="Access">
                            <Select
                                className="x-itemselect-select"
                                value={accessType}
                                onChange={(accessType) => handleAccessChange(accessType)}
                            >
                                {AccessType.array(false).map(type => (
                                    <Select.Option key={type} value={type}>{CoreUtils.toProper(type)}</Select.Option>
                                ))}
                            </Select>
                        </Label>
                    </Row>
                }
                {queriesView}
                <Row className="x-itemselect-footer" justify="end">
                    <Button type="primary" onClick={() => handleHeaderCancel()}>Cancel</Button>
                </Row>
            </div>
        )
    }

    const loadData = (accessType: AccessType): void => {
        const requests = [];
        requests.push(props.loader(accessType));
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((headers) => {
            if (RestUtils.isOk(headers)) {
                setHeaders(headers);
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
    }

    const loading = status === StatusType.LOADING;
    const instance = headers.find(header => header.id === props.selectedId);
    if (instance && instance !== header) {
        setHeader(instance);
    }

    return (
        <div className="x-itemselect">
            <Select
                placeholder={`Select a ${props.label.toLowerCase()}.`}
                value={loading ? undefined : props.selectedId}
                loading={loading}
                dropdownStyle={{ visibility: "hidden" }}
                onDropdownVisibleChange={handleVisibleToggle}
            >
                {header && <Select.Option key={header.id} value={header.id}>{header.name}</Select.Option>}
            </Select>
            {isVisible && buildDropdownView()}
        </div>
    );

}

ItemSelect.defaultProps = defaultProps;
