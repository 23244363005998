import { ReactElement, useEffect, useState } from 'react';
import { Button, Col, Form, FormInstance, Modal, Result, Row, Select } from 'antd';
import { Globals } from 'constants/Globals';
import { NoData } from 'components/NoData/NoData';
import { StatusType } from 'constants/StatusType';
import { AccessType, IdUtils, Version } from '@methodset/commons-core-ts';
import { RestUtils } from 'utils/RestUtils';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { Application, ApplicationHeader, ApplicationType, CategoryType } from '@methodset/application-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { Label } from 'components/Label/Label';
import { CoreUtils } from 'utils/CoreUtils';
import { Calculator } from '@methodset/calculator-ts';
import { Clickable } from 'components/Clickable/Clickable';
import { TextMessage } from 'components/TextMessage/TextMessage';
import { ApplicationView } from './ApplicationView/ApplicationView';
import { PackHeader } from '@methodset/library-client-ts';
import { v4 as uuid } from "uuid";
import axios from 'axios';
import applicationService from 'services/ApplicationService';
import libraryService from 'services/LibraryService';
import './ApplicationInstaller.less';

const ALL = "ALL" as CategoryType;
const COLUMN_COUNT = 2;

enum ViewType {
    SEARCH = 0,
    INSTALL = 1,
    CONFIGURE = 2,
    RESULT = 3
}

export interface ButtonSpec {
    label: string;
    primary: boolean;
    loading?: () => boolean;
    func: () => void;
}

export interface ScreenSpec {
    view: ReactElement;
    back: boolean;
    button: ButtonSpec;
}

export interface ConfigurationView {
    formRef: React.RefObject<FormInstance>;
    formBody: ReactElement;
}

//export type FilterFunction = (header: ApplicationHeader) => boolean;
export type LoadCallback = (application: Application, calculator: Calculator) => void;
export type InstallCallback = (applicationHeader: ApplicationHeader, packHeader: PackHeader) => Promise<any> | void;
export type CompleteCallback = (applicationHeader: ApplicationHeader, packHeader: PackHeader) => void;
export type CloseCallback = () => void;

export type ApplicationInstallerProps = {
    applicationType: ApplicationType,
    configureView?: ConfigurationView,
    moduleId?: string,
    accessType?: AccessType,
    //filter?: FilterFunction,
    onLoad: LoadCallback,
    onInstall: InstallCallback,
    onComplete: CompleteCallback,
    onClose: CloseCallback
}

export const ApplicationInstaller = (props: ApplicationInstallerProps): ReactElement => {

    const [status, setStatus] = useState<StatusType>(StatusType.INIT);
    const [viewType, setViewType] = useState<ViewType>(ViewType.SEARCH);
    const [error, setError] = useState<Error | undefined>();
    const [categoryType, setCategoryType] = useState<CategoryType>(ALL);
    const [accessType, setAccessType] = useState<AccessType>(props.accessType ?? AccessType.UNPUBLISHED);
    const [applicationHeaders, setApplicationHeaders] = useState<ApplicationHeader[]>([]);
    const [applicationHeader, setApplicationHeader] = useState<ApplicationHeader>();
    const [packHeader, setPackHeader] = useState<PackHeader>();
    const [isRunning, setIsRunning] = useState<boolean>(false);

    useEffect(() => {
        loadData(accessType);
    }, []);

    const handleRetryLoad = (): void => {
        loadData(accessType);
    }

    const handleCategoryChange = (categoryType: CategoryType): void => {
        setCategoryType(categoryType);
    }

    const handleAccessChange = (accessType: AccessType): void => {
        setAccessType(accessType);
        loadData(accessType);
    }

    const handleApplicationSelect = (header: ApplicationHeader): void => {
        setApplicationHeader(header);
        setViewType(ViewType.INSTALL);
    }

    const handleApplicationInstall = (): void => {
        setError(undefined);
        //loadApplicationRequest(applicationHeader!.id);
        loadApplication(applicationHeader!.id);
    }

    const handleConfigurationValidate = (): void => {
        props.configureView?.formRef.current!.submit();
    }

    const handleConfigurationComplete = (): void => {
        setIsRunning(true);
        setError(undefined);
        const result = props.onInstall(applicationHeader!, packHeader!);
        if (CoreUtils.isPromise(result)) {
            result.then(() => {
                setViewType(ViewType.RESULT);
                setIsRunning(false);
            }).catch((e: Error) => {
                setError(e);
                setIsRunning(false);
            });
        } else {
            setViewType(ViewType.RESULT);
            setIsRunning(false);
        }
    }

    const handleConfigurationError = (): void => {
        const error = new Error("Please fill all required fields.");
        setError(error);
    }

    const handlePreviousScreen = (viewType: ViewType): void => {
        if (viewType === ViewType.RESULT) {
            // Skip the configuration screen if there isn't one.
            viewType -= props.configureView ? 1 : 2;
        } else {
            viewType -= 1;
        }
        setError(undefined);
        setViewType(viewType);
    }

    const handleInstallComplete = (): void => {
        props.onComplete(applicationHeader!, packHeader!);
        props.onClose();
    }

    const loadApplication = (applicationId: string): void => {
        setIsRunning(true);
        setError(undefined);
        const requests = [];
        requests.push(readPackHeaderRequest(applicationId));
        requests.push(loadApplicationRequest(applicationId));
        axios.all(requests).then(axios.spread((r1, r2) => {
            if (RestUtils.isOk(r1, r2)) {
                if (props.configureView) {
                    setViewType(ViewType.CONFIGURE);
                    setIsRunning(false);
                } else {
                    handleConfigurationComplete();
                }
            } else {
                if (!RestUtils.isOk(r1)) {
                    setError(RestUtils.getError(r1));
                } else if (!RestUtils.isOk(r2)) {
                    setError(RestUtils.getError(r2));
                }
                setIsRunning(false);
            }
        }));
    }

    const readPackHeaderRequest = (packId: string): Promise<any> => {
        if (IdUtils.isEditId(packId)) {
            return Promise.resolve(undefined);
        }
        const request = {
            packId: packId,
            version: Version.LATEST
        }
        return libraryService.readPackHeader(request,
            (response: any) => readPackHeaderResponse(response),
            (response: any) => readPackHeaderException(response),
            true
        );
    }

    const readPackHeaderResponse = (response: any): void => {
        const header = response.data.header;
        setPackHeader(header);
    }

    const readPackHeaderException = (response: any): any => {
        return response;
    }

    const readApplicationHeadersRequest = (accessType: AccessType): Promise<any> => {
        const request = {
            accessType: accessType,
            applicationType: props.applicationType,
            moduleId: props.moduleId
        }
        return applicationService.readApplicationHeaders(request,
            (response: any) => readApplicationHeadersResponse(response),
            undefined, true
        );
    }

    const readApplicationHeadersResponse = (response: any): void => {
        let headers = response.data.headers;
        // if (props.filter) {
        //     headers = headers.filter(props.filter);
        // }
        setApplicationHeaders(headers);
    }

    const loadApplicationRequest = (applicationId: string): Promise<any> => {
        setIsRunning(true);
        setError(undefined);
        const request = {
            applicationId: applicationId,
            version: IdUtils.isEditId(applicationId) ? Version.EDITOR : Version.LATEST,
        };
        return applicationService.loadApplication(request,
            (response: any) => loadApplicationResponse(response),
            (response: any) => responseException(response),
            true
        );
    }

    const loadApplicationResponse = (response: any): void => {
        const application = response.data.application;
        const calculator = Calculator.deserialize(response.data.calculator);
        props.onLoad(application, calculator);
        // if (props.configureView) {
        //     setViewType(ViewType.CONFIGURE);
        //     setIsRunning(false);
        // } else {
        //     handleConfigurationComplete();
        // }
    }

    const responseException = (response: any): any => {
        const error = RestUtils.getError(response);
        setError(error);
        setIsRunning(false);
        return response;
    }

    const loadData = (accessType: AccessType): void => {
        const requests = [];
        requests.push(readApplicationHeadersRequest(accessType));
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
    }

    const buildLoadingView = (isLoading: boolean): ScreenSpec => {
        const indices = Array.from(Array(COLUMN_COUNT * 2).keys());
        const view = (
            <Row className="x-applicationinstaller-packs" gutter={[Globals.APPLET_GUTTER_COL, Globals.APPLET_GUTTER_ROW]}>
                {indices.map(index => (
                    <Col
                        key={index}
                        md={{ span: 24 / COLUMN_COUNT }}
                    >
                        <div className="x-applicationinstaller-item">
                            <LoadSkeleton
                                direction="vertical"
                                status={isLoading ? "loading" : "failed"}
                                failedMessage="Failed to load alerts."
                                onRetry={() => handleRetryLoad()}
                            >
                                <LoadSkeleton.Input length="medium" />
                                <LoadSkeleton.Input length="fill" />
                                <LoadSkeleton.Input length="short" />
                            </LoadSkeleton>
                        </div>
                    </Col>
                ))}
            </Row>
        );
        return {
            view: view,
            back: false,
            button: {
                label: "Close",
                primary: true,
                func: () => props.onClose()
            }
        }
    }

    const buildSearchView = (): ScreenSpec => {
        const headers = applicationHeaders.filter(header => {
            return categoryType === ALL || categoryType === header.categoryType;
        });
        const view = (
            <>
                <Spacer className="x-applicationinstaller-select" fill>
                    <Label className="x-applicationinstaller-category" placement="top" label="Category">
                        <Select
                            className="x-applicationinstaller-fill"
                            value={categoryType}
                            onChange={(categoryType) => handleCategoryChange(categoryType)}
                        >
                            <Select.Option value={ALL}>All</Select.Option>
                            {CoreUtils.enumToKeys(CategoryType).map(type => (
                                <Select.Option key={type} value={type}>{CoreUtils.toProper(type, "_")}</Select.Option>
                            ))}
                        </Select>
                    </Label>
                    <Label className="x-applicationinstaller-access" placement="top" label="Access">
                        <Select
                            className="x-applicationinstaller-fill"
                            value={accessType}
                            disabled={!!props.accessType}
                            onChange={(accessType) => handleAccessChange(accessType)}
                        >
                            {AccessType.array(false).map(type => (
                                <Select.Option key={type} value={type}>{CoreUtils.toProper(type)}</Select.Option>
                            ))}
                        </Select>
                    </Label>
                </Spacer>
                {headers.length === 0 &&
                    <div className="x-applicationinstaller-empty">
                        <NoData text={`No ${CoreUtils.toLower(props.applicationType)}s available.`} />
                    </div>
                }
                {headers.length > 0 &&
                    <Row className="x-applicationinstaller-packs" gutter={[Globals.APPLET_GUTTER_COL, Globals.APPLET_GUTTER_ROW]}>
                        {headers.map((header, index) => (
                            <Col
                                key={header.id}
                                lg={{ span: 24 / COLUMN_COUNT }}
                            >
                                <Clickable
                                    data={header}
                                    onClick={(header) => handleApplicationSelect(header)}
                                >
                                    <div className="x-applicationinstaller-pack">
                                        <ApplicationView type="summary" index={index} header={header} />
                                    </div>
                                </Clickable>
                            </Col>
                        ))}
                    </Row>
                }

            </>
        )
        return {
            view: view,
            back: false,
            button: {
                label: "Close",
                primary: true,
                func: () => props.onClose()
            }
        }
    }

    const buildInstallView = (): ScreenSpec => {
        const header = applicationHeader!;
        const view = (
            <ApplicationView type="full" header={header} />
        )
        return {
            view: view,
            back: true,
            button: {
                label: "Install",
                primary: true,
                func: () => handleApplicationInstall()
            }
        }
    }

    const buildConfigureView = (): ScreenSpec => {
        const view = (
            <Form
                ref={props.configureView!.formRef}
                onFinish={handleConfigurationComplete}
                onFinishFailed={handleConfigurationError}
            >
                {props.configureView!.formBody}
            </Form>
        )
        return {
            view: view,
            back: true,
            button: {
                label: "Configure",
                primary: true,
                func: () => handleConfigurationValidate()
            }
        }
    }

    const buildResultView = (): ScreenSpec => {
        const view = (
            <Result
                status="success"
                title={`${CoreUtils.toProper(props.applicationType)} installed successfully!`}
                subTitle={`'${applicationHeader!.name}' is now ready to use.`}
            />
        )
        return {
            view: view,
            back: false,
            button: {
                label: "Close",
                primary: true,
                func: () => handleInstallComplete()
            }
        }
    }

    const map = new Map([
        [ViewType.SEARCH, buildSearchView],
        [ViewType.INSTALL, buildInstallView],
        [ViewType.CONFIGURE, buildConfigureView],
        [ViewType.RESULT, buildResultView]
    ]);

    let screen: ScreenSpec;
    if (status === Globals.STATUS_LOADING) {
        screen = buildLoadingView(true);
    } else if (status === Globals.STATUS_FAILED) {
        screen = buildLoadingView(false);
    } else if (status === Globals.STATUS_READY) {
        const instance = map.get(viewType)!;
        screen = instance();
    } else {
        screen = {
            view: <div>{`Invalid view type ${viewType}.`}</div>,
            back: false,
            button: {
                label: "Close",
                primary: true,
                func: () => props.onClose()
            }
        }
    }

    return (
        <Modal
            className="x-applicationinstaller"
            centered
            title={`Install ${CoreUtils.toProper(props.applicationType)}`}
            width={Globals.DIALOG_WIDTH * COLUMN_COUNT}
            //width={Globals.DIALOG_WIDTH_STANDARD}
            visible={true}
            onCancel={props.onClose}
            footer={(
                <>
                    {screen.back &&
                        <Button
                            key="back"
                            onClick={() => handlePreviousScreen(viewType)}
                        >
                            Back
                        </Button>
                    }
                    {screen!.button &&
                        <Button
                            key={uuid()}
                            type={screen!.button.primary ? "primary" : "default"}
                            loading={screen!.button.primary ? isRunning : false}
                            onClick={screen!.button.func}
                        >
                            {screen!.button.label}
                        </Button>
                    }
                </>
            )}
        >
            <Spacer direction="vertical">
                <div className="x-applicationinstaller-view">
                    {screen!.view}
                </div>
                <TextMessage type="error" message={error?.message} />
            </Spacer>
        </Modal>
    )

}
