import { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import './Panel.less';

export type PanelProps = {
    className?: string,
    children?: any,
    inverse?: boolean,
    fill?: boolean,
    title?: ReactNode
} & typeof defaultProps;

const defaultProps = {
    inverse: false,
    fill: false
}

export const Panel = (props: PanelProps): ReactElement => {
    return (
        <div className={classNames("x-panel", props.className, {"x-panel-inverse": props.inverse}, {"x-panel-fill": props.fill})}>
            {props.title &&
                <div className="x-panel-title">{props.title}</div>
            }
            {props.children}
        </div>
    )
}

Panel.defaultProps = defaultProps;
