import { Bullets } from 'components/Bullets/Bullets';
import { Italic } from 'components/Italic/Italic';
import { Chapter } from 'components/Chapter/Chapter';
import { ReactElement } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { RouteBuilder } from 'utils/RouteBuilder';
import { DocFrame } from '../DocFrame/DocFrame';

export type WorkflowsDocProps = RouteComponentProps;

export const WorkflowsDoc = (props: WorkflowsDocProps): ReactElement => {

    return (
        <DocFrame title="Workflows" {...props}>
            <Chapter>
                <Chapter.Paragraph>
                    A <Italic>workflow</Italic> is a set of processing units that read data from a source, process
                    that data, and then send it to a final destination. They are in many ways similar to the construction
                    of <Link to={RouteBuilder.MAIN_DOCUMENTATION_DATASETS}>datasets</Link>, but can have more complicated
                    processing flows, including loops and branches. Such workflows are triggered by different types of events, 
                    such as a scheduled event (e.g., once a day at 6:00 pm PST), the receipt of an email, or a file upload.
                </Chapter.Paragraph>
                <Chapter.Paragraph>
                    Dataset creation automatically connects each processor with a <Italic>pipe</Italic> flow, which is a serial flow 
                    connector type. Workflows include the following flow types:
                </Chapter.Paragraph>
                <Bullets
                    type="unordered"
                    style="disc"
                    items={[
                        "Pipe",
                        "Parallel",
                        "For Loop",
                        "Else... If"
                    ]}
                />
                <Chapter.Paragraph>
                    <Italic>Pipe</Italic> flows execute processors one after the other. <Italic>Parallel</Italic> flows
                    allow for multiple processors to execute at the same time. <Italic>For Loop</Italic> flows
                    loop over values of a variable and execute a processor passing that value. <Italic>Else... If</Italic> flows
                    choose from a set of processors based on the value of a variable.
                </Chapter.Paragraph>
            </Chapter>
        </DocFrame>
    );

}
