import { Calculator } from '@methodset/calculator-ts';
import { ReactElement } from 'react';
import { Applet, PanelWidgetConfiguration } from '@methodset/application-client-ts';
import { Configuration } from '@methodset/endpoint-client-ts';
import { Card } from 'antd';
import { ConfigurationCallback } from '../WidgetViewer/WidgetViewer';
import { PanelViewer } from 'containers/Console/Dashboards/DashboardItem/AppletViewer/PanelViewer/PanelViewer';
import classNames from 'classnames';
import './PanelWidgetViewer.less';

export type PanelWidgetViewerProps = {
    calculator: Calculator,
    configuration: PanelWidgetConfiguration,
    applet: Applet,
    appletConfiguration: Configuration,
    onConfiguration: ConfigurationCallback
};

export const PanelWidgetViewer = (props: PanelWidgetViewerProps): ReactElement => {

    const buildAppletsView = (): ReactElement => {
        const panelId = props.configuration.panelId;
        const panel = props.applet.panels.find(panel => panel.id === panelId);
        if (!panel) {
            return (
                <div>Could not load panel.</div>
            )
        }
        // const installation: AppletComponent = {
        //     applet: props.applet,
        //     panel: panel,
        //     calculator: props.calculator,
        //     configuration: props.appletConfiguration
        // }
        const view = (
            <PanelViewer
                applet={props.applet}
                calculator={props.calculator}
                panel={panel}
                configuration={props.configuration}
                onConfiguration={props.onConfiguration}
            />
            // <AppletViewer
            //     key={panel.id}
            //     installation={installation}
            //     isEmbedded={true}
            //     onConfiguration={props.onUpdate}
            // />
        )
        if (props.configuration.title) {
            return (
                <Card
                    className={classNames({ "x-panelwidgetviewer-shadow": props.configuration.shadow })}
                    size="small"
                    bordered={true}
                    title={props.configuration.title}
                >
                    {view}
                </Card>
            )
        } else {
            return (
                <div className={classNames("x-panelwidgetviewer", { "x-panelwidgetviewer-shadow": props.configuration.shadow })}>
                    {view}
                </div>
            )
        }
    }

    return buildAppletsView();

}
