import React, { ReactElement, useRef } from 'react';
import { Col, FormInstance, Row, Select } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { Alert, Condition, Inputs, OpType } from '@methodset/application-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { ConditionEditor } from './ConditionEditor/ConditionEditor';
import { Calculator, Variable } from '@methodset/calculator-ts';
import { InputEditor } from 'containers/Components/Widgets/InputWidgetViewer/InputEditor/InputEditor';
import { CoreUtils } from 'utils/CoreUtils';
import { FormattedInput } from 'components/FormattedInput/FormattedInput';
import { FormValue } from 'components/FormValue/FormValue';
import { ConditionSection, InputSection, InstructionsUtils, Section, SectionType, TextSection } from './InstructionsUtils';
import { TextMessage } from 'components/TextMessage/TextMessage';
import update from 'immutability-helper';
import './AlertInputs.less';

interface Counts {
    variables: number;
    conditions: number;
}

export type ChangeCallback = (inputs: Inputs) => void;

export type AlertInputsProps = {
    formRef: React.RefObject<FormInstance>,
    alert: Alert,
    isBuild?: boolean,
    inputs?: Inputs,
    calculator: Calculator,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    isBuild: false,
    inputs: {
        configuration: {},
        conditions: [{
            variableId: undefined as any,
            opType: undefined as any,
            value: undefined as any
        }]
    } as Inputs
}

export const AlertInputs = (props: AlertInputsProps): ReactElement => {

    const counts = useRef<Counts>({
        variables: 0,
        conditions: 0
    });

    const handleConditionChange = (condition: Condition, index: number): void => {
        const inputs = update(props.inputs, {
            conditions: {
                [index]: { $set: condition }
            }
        });
        props.onChange(inputs);
    }

    const handleInputChange = (value: any, variable: Variable): void => {
        const inputs = update(props.inputs, {
            configuration: {
                [variable.id]: { $set: value }
            }
        });
        props.onChange(inputs);
    }

    const handleOpChange = (opType: OpType, index: number): void => {
        const inputs = update(props.inputs, {
            conditions: {
                [index]: {
                    opType: { $set: opType }
                }
            }
        });
        props.onChange(inputs);
    }

    const handleValueChange = (value: any, index: number, condition: Condition): void => {
        let inputs = props.inputs;
        if (!inputs.conditions[index]) {
            // Add the initialized user condition state.
            inputs = update(inputs, {
                conditions: {
                    [index]: { $set: condition }
                }
            });
        }
        inputs = update(inputs, {
            conditions: {
                [index]: {
                    value: { $set: value }
                }
            }
        });
        props.onChange(inputs);
    }

    const inputVariables = (): Variable[] => {
        return props.calculator.variables.filter(variable => props.alert.inputIds.includes(variable.id));
    }

    const inputConditions = (): Condition[] => {
        if (props.inputs.conditions && props.inputs.conditions.length === props.alert.conditions.length) {
            return props.inputs.conditions;
        } else {
            const conditions = [];
            for (const condition of props.alert.conditions) {
                conditions.push({
                    variableId: condition.variableId,
                    opType: condition.opType,
                    value: condition.value,
                    logicalType: condition.logicalType
                });
            }
            return conditions;
        }
    }

    const generateSpan = (text: string, key: string): ReactElement => {
        return (
            <span key={key}>
                {text}
            </span>
        )
    }

    const generateInput = (variableId: string, key: string): ReactElement => {
        return (
            <div className="x-alertinputs-input">
                <FormItem
                    formRef={props.formRef}
                    className="x-alertinputs-forminput"
                    name={key}
                    noError
                    rules={[{
                        required: true
                    }]}
                >
                    <InputEditor
                        key={key}
                        inputClassName="x-alertinputs-value"
                        calculator={props.calculator}
                        value={props.inputs.configuration[variableId]}
                        variableId={variableId}
                        useDefault={false}
                        doEmbed={true}
                        onChange={(value, variable) => handleInputChange(value, variable)}
                    />
                </FormItem>
            </div>
        )
    }

    const generateCondition = (condition: Condition, template: Condition, index: number, key: string): ReactElement => {
        return (
            <>
                {template?.opType &&
                    <span>
                        {OpType.label(template.opType)}
                    </span>
                }
                {!template?.opType &&
                    <div className="x-alertinputs-input">
                        <FormItem
                            formRef={props.formRef}
                            name={key}
                            noError
                            rules={[{
                                required: true
                            }]}
                        >
                            <Select
                                className="x-alertinputs-op"
                                allowClear={false}
                                value={condition.opType}
                                onChange={(opType) => handleOpChange(opType, index)}
                            >
                                {OpType.labels().map(([key, value]) =>
                                    <Select.Option key={key} value={key}>{value}</Select.Option>
                                )}
                            </Select>
                        </FormItem>
                    </div>
                }
                {(!condition.variableId || !CoreUtils.isEmpty(template?.value)) &&
                    <div className="x-alertinputs-input">
                        <FormItem
                            formRef={props.formRef}
                            className="x-alertinputs-forminput"
                            name={key}
                            noError
                            rules={[{
                                required: true
                            }]}
                        >
                            <FormattedInput
                                className="x-alertinputs-merge"
                                value={template?.value}
                                variableId={condition.variableId}
                                calculator={props.calculator}
                            />
                        </FormItem>
                    </div>
                }
                {condition.variableId && CoreUtils.isEmpty(template?.value) &&
                    <div className="x-alertinputs-input">
                        <FormItem
                            formRef={props.formRef}
                            className="x-alertinputs-forminput"
                            name={key}
                            noError
                            rules={[{
                                required: true
                            }]}
                        >
                            <InputEditor
                                inputClassName="x-alertinputs-value"
                                calculator={props.calculator}
                                value={condition.value}
                                variableId={condition.variableId}
                                useNull={false}
                                doEmbed={true}
                                onChange={(value) => handleValueChange(value, index, condition)}
                            />
                        </FormItem>
                    </div>
                }
            </>
        )
    }

    const instructionsView = (): ReactElement => {
        const conditions = props.alert.conditions;
        const elements: ReactElement[] = [];
        const instructions = props.alert.instructions!;
        const callback = (section: Section, index: number): void => {
            const key = `section-${index}`;
            let element;
            switch (section.type) {
                case SectionType.TEXT: {
                    const part = section as TextSection;
                    element = generateSpan(part.content, key);
                    break;
                }
                case SectionType.INPUT: {
                    const part = section as InputSection;
                    element = generateInput(part.variableId, key);
                    counts.current.variables++;
                    break;
                }
                case SectionType.CONDITION: {
                    const part = section as ConditionSection;
                    let index = conditions.findIndex(condition => condition.variableId === part.variableId && condition.opType === part.opType);
                    if (index !== -1) {
                        const template = conditions[index];
                        let condition = InstructionsUtils.findCondition(part.variableId, part.opType, props.inputs.conditions);
                        if (!condition) {
                            // Initialize the user condition state.
                            condition = {
                                variableId: template.variableId,
                                opType: template.opType,
                                value: template.value
                            } as Condition;
                        }
                        element = generateCondition(condition, template, index, key);
                        counts.current.conditions++;
                    }
                    break;
                }
                default:
            }
            if (element) {
                elements.push(element);
            }
        }
        InstructionsUtils.visitSections(instructions, callback);
        return (
            <div className="x-alertinputs-instruct">
                {elements}
            </div>
        )
    }

    // TODO: support alerts to email/sms/mobile
    const conditionsView = (): ReactElement => {
        return (
            <Spacer direction="vertical">
                {variables.length > 0 &&
                    <>
                        <div className="x-alertinputs-instruct">This alert requires the following inputs:</div>
                        <div className="x-alertinputs-fill">
                            <Row gutter={Globals.FORM_GUTTER_COL}>
                                {variables.map(variable => (
                                    <Col key={variable.id} span={12}>
                                        <FormItem
                                            {...Globals.FORM_LAYOUT}
                                            key={variable.id}
                                            formRef={props.formRef}
                                            label={variable.name}
                                            name={variable.id}
                                            info={variable.description}
                                            rules={[{
                                                required: variable.isRequired,
                                                message: "Enter a value."
                                            }]}
                                        >
                                            <InputEditor
                                                className="x-alertinputs-fill"
                                                calculator={props.calculator}
                                                value={props.inputs.configuration[variable.id]}
                                                variableId={variable.id}
                                                useDefault={false}
                                                doEmbed={true}
                                                onChange={(value, variable) => handleInputChange(value, variable)}
                                            />
                                        </FormItem>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                        <div className="x-alertinputs-instruct">And will be triggered:</div>
                    </>
                }
                {variables.length === 0 &&
                    <div className="x-alertinputs-instruct">This alert will be triggered:</div>
                }
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={props.formRef}
                    name="conditions"
                    info="The conditions that will decide if the alert should be triggered."
                    required={true}
                    rules={[{
                        validator: () => {
                            for (const condition of conditions) {
                                if (!condition.variableId || !condition.opType || CoreUtils.isEmpty(condition.value)) {
                                    return Promise.reject(`Please fill all conditions.`);
                                }
                            }
                            return Promise.resolve();
                        }
                    }]}
                >
                    <FormValue value={conditions}>
                        <Spacer direction="vertical" fill>
                            {conditions.map((condition: Condition, index: number) => (
                                <ConditionEditor
                                    key={index}
                                    index={index}
                                    condition={condition}
                                    template={props.alert.conditions[index]}
                                    calculator={props.calculator}
                                    onChange={handleConditionChange}
                                />
                            ))}
                        </Spacer>
                    </FormValue>
                </FormItem>
            </Spacer>
        )
    }

    const warningsView = (): ReactElement | undefined => {
        const warnings = [];
        if (counts.current.variables !== variables.length) {
            const s1 = variables.length === 1;
            const s2 = counts.current.variables === 1;
            const warning = (
                <TextMessage
                    type="error"
                    message={`${variables.length} variable${s1 ? "" : "s"} ${s1 ? "is" : "are"} defined, but ${counts.current.variables} ${s2 ? "is" : "are"} referenced.`}
                />
            )
            warnings.push(warning);
        }
        const conditions = props.alert.conditions;
        if (counts.current.conditions !== conditions.length) {
            const s1 = conditions.length === 1;
            const s2 = counts.current.conditions === 1;
            const warning = (
                <TextMessage
                    type="error"
                    message={`${conditions.length} condition${s1 ? "" : "s"} ${s1 ? "is" : "are"} defined, but ${counts.current.conditions} ${s2 ? "is" : "are"} referenced.`}
                />
            )
            warnings.push(warning);
        }
        counts.current.variables = 0;
        counts.current.conditions = 0;
        if (warnings.length === 0) {
            return undefined;
        } else {
            return (
                <div className="x-alertinputs-warnings">
                    {warnings}
                </div>
            )
        }
    }

    const variables = inputVariables();
    const conditions = inputConditions();

    return (
        <div className="x-alertinputs">
            <div className="x-alertinputs-header">
                <div className="x-alertinputs-name">{props.alert.name}</div>
                <div className="x-alertinputs-desc">{props.alert.description}</div>
            </div>
            {props.alert.instructions &&
                instructionsView()
            }
            {!props.alert.instructions &&
                conditionsView()
            }
            {props.isBuild &&
                warningsView()
            }
        </div>
    )

}

AlertInputs.defaultProps = defaultProps