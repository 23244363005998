import React, { ForwardedRef, ReactElement } from 'react';
import { Button, Form, FormInstance, PageHeader, Space } from 'antd';
import { Globals } from 'constants/Globals';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import classNames from 'classnames';
import './ItemEditor.less';

export type SaveCallback = (values?: any) => void;
export type CancelCallback = (event?: any) => void;
export type ErrorCallback = (error?: any) => void;
export type RetryCallback = () => void;

export type ItemEditorProps = {
    // Class name to apply.
    className?: string,
    // Children to render inside the editor.
    children: any,
    // The title to display for the editor.
    title?: string,
    // True to show a loading indicator, false otherwise.
    saving?: boolean,
    // True to disable the buttons.
    disabled?: boolean,
    // The text on the ok button.
    okText?: string,
    // An extra component to place into the header.
    extra?: object,
    // True to indicate this is an update, false for save.
    isUpdate?: boolean,
    // The status of the initial data load.
    status?: string,
    onSave?: SaveCallback,
    onCancel?: CancelCallback,
    onError?: ErrorCallback,
    onRetry?: RetryCallback
} & Partial<typeof defaultProps>;

const defaultProps = {
    saving: false,
    disabled: false,
    status: Globals.STATUS_READY,
    onSave: (value: any): void => { },
    onCancel: (event: any): void => { },
    onError: (error: any): void => { },
    onRetry: (): void => { }
}

export const ItemEditor = React.forwardRef<FormInstance, ItemEditorProps>((props: ItemEditorProps, ref?: ForwardedRef<FormInstance>) => {

    const buildFormView = (): ReactElement => {
        return (
            <Form ref={ref} onFinish={props.onSave} onFinishFailed={props.onError}>
                <div className="x-itemeditor-body">
                    {props.children}
                </div>
                <Space className="x-itemeditor-footer">
                    <Button
                        className="x-itemeditor-cancel"
                        onClick={props.onCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="x-itemeditor-save"
                        type="primary"
                        htmlType="submit"
                        loading={props.saving}
                    >
                        {props.okText ? props.okText : props.isUpdate ? 'Update' : 'Save'}
                    </Button>
                </Space>
            </Form>
        )
    }

    const buildBasicView = (): ReactElement => {
        return (
            <>
                <div className="x-itemeditor-body">
                    {props.children}
                </div>
                <Space className="x-itemeditor-footer">
                    <Button
                        className="x-itemeditor-cancel"
                        disabled={props.disabled}
                        onClick={props.onCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="x-itemeditor-save"
                        type="primary"
                        loading={props.saving}
                        disabled={props.disabled}
                        onClick={props.onSave}
                    >
                        {props.okText ? props.okText : props.isUpdate ? 'Update' : 'Save'}
                    </Button>
                </Space>
            </>
        )
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        return (
            <LoadSkeleton
                count={8}
                status={isLoading ? "loading" : "failed"}
                onRetry={props.onRetry}
            >
                <LoadSkeleton.Input length="fill" />
            </LoadSkeleton>
        );
    }

    let view;
    if (props.status === Globals.STATUS_LOADING) {
        view = buildLoadingView(true);
    } else if (props.status === Globals.STATUS_FAILED) {
        view = buildLoadingView(false);
    } else if (props.status === Globals.STATUS_READY) {
        view = ref ? buildFormView() : buildBasicView();
    }
    return (
        <div className="x-itemeditor">
            {(props.title || props.extra) &&
                <PageHeader
                    className="x-itemeditor-header"
                    title={props.title}
                    extra={props.extra}
                    onBack={() => window.history.back()}
                />
            }
            <div className={classNames("x-itemeditor-view", props.className)}>
                {view}
            </div>
        </div>
    );
});

ItemEditor.defaultProps = defaultProps;
