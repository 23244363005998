import { ReactElement } from 'react';
import { Col, FormInstance, Row, Select } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { Component, ConverterMap, Flow, FlowType, IoMap, Link, PipeFlow } from '@methodset/endpoint-client-ts';
import { ChangeCallback, OutputComponents } from '../FlowEditor';
import { IoMapEditor } from '../IoMapEditor/IoMapEditor';
import { ComponentMap } from '../../Flows';
import update from 'immutability-helper';
import './PipeEditor.less';

export type PipeEditorProps = {
    formRef: React.RefObject<FormInstance>,
    index: number,
    // Ids used as prevs.
    prevIds: string[],
    // Ids used as nexts.
    nextIds: string[],
    parentFlow?: Flow,
    flow: PipeFlow,
    components: Component[],
    componentMap: ComponentMap,
    outputComponents: OutputComponents,
    converterMap: ConverterMap,
    // Called when the flow has changed.
    onChange: ChangeCallback,
} & typeof defaultProps;

const defaultProps = {
}

const newFlow = (): PipeFlow => {
    return {
        type: FlowType.PIPE,
        prevId: undefined,
        taskLink: {
            ioMap: {} as IoMap
        } as Link
    } as PipeFlow;
}

export const PipeEditor = (props: PipeEditorProps): ReactElement => {

    const handleTaskChange = (componentId: string): void => {
        // The flow id equals the component id for pipe flows.
        const flow = update(props.flow, {
            id: { $set: componentId },
            taskLink: {
                id: { $set: componentId },
                ioMap: { $set: {} }
            }
        });
        props.onChange(flow);
    }

    const handleNextLinkChange = (taskLink: Link): void => {
        const flow = update(props.flow, {
            taskLink: { $set: taskLink }
        });
        props.onChange(flow);
    }

    return (
        <div className="x-pipeeditor">
            <Row gutter={Globals.FORM_GUTTER_COL}>
                <Col span={12}>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={props.formRef}
                        label="Processor"
                        name="processor"
                        info="The processor to execute."
                        rules={[{
                            required: true,
                            message: "Please select a processor."
                        }]}
                    >
                        <Select
                            placeholder="Select a processor."
                            value={props.flow.taskLink?.id}
                            onChange={(value) => handleTaskChange(value)}
                        >
                            {props.components.map(component => (
                                <Select.Option
                                    key={component.id}
                                    value={component.id}
                                    disabled={props.nextIds.includes(component.id) || props.flow.id === component.id}
                                >
                                    {component.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                </Col>
                <Col span={12}>
                    {props.outputComponents.components.length > 0 && !!props.flow.taskLink.id &&
                        <IoMapEditor
                            formRef={props.formRef}
                            visible={true}
                            parentFlow={props.parentFlow}
                            link={props.flow.taskLink}
                            componentMap={props.componentMap}
                            converterMap={props.converterMap}
                            outputComponents={props.outputComponents}
                            onChange={(taskLink) => handleNextLinkChange(taskLink)}
                        />
                    }
                </Col>
            </Row>
        </div>
    )
}

PipeEditor.defaultProps = defaultProps;
PipeEditor.newFlow = newFlow;
