import { Chapter } from 'components/Chapter/Chapter';
import { ReactElement } from 'react';
import { Italic } from 'components/Italic/Italic';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Mono } from 'components/Mono/Mono';
import { DocFrame } from '../DocFrame/DocFrame';

export type VersioningDocProps = RouteComponentProps;

export const VersioningDoc = (props: VersioningDocProps): ReactElement => {

    return (
        <DocFrame title="Versioning" {...props}>
            <Chapter>
                <Chapter.Paragraph>
                    <Italic>Versioning</Italic> of components locks an implementation so that references to the component
                    do not change (and possibly break) based on future updates. This creates stable processes where changes to 
                    one part of the system do not unwittingly affect other parts. This is standard practice in software development 
                    and the same concept is used here. The versioning system is simple, by automatically incrementing the version 
                    number each time a component is published, for example v1 is promoted to v2. Once a version is published, that 
                    version cannot change; future updates will be assigned the next version number.
                </Chapter.Paragraph>
                <Chapter.Paragraph>
                    Versions are used in
                    &nbsp;<Link to={RouteBuilder.MAIN_DOCUMENTATION_DATASETS}>datasets</Link>, 
                    &nbsp;<Link to={RouteBuilder.MAIN_DOCUMENTATION_MODELS}>models</Link>, and 
                    &nbsp;<Link to={RouteBuilder.MAIN_DOCUMENTATION_WORKFLOWS}>workflows</Link>.
                    Each time one of these components is referenced, a version must be selected to lock in the implementation.
                    Workflows are slightly different in that in addition to the published versions, there must be an "active" 
                    version, which is the version that will execute when the workflow is triggered.
                </Chapter.Paragraph>
                <Chapter.Paragraph>
                    After a version is published, any new changes to a component will be part of the "snapshot" version, which 
                    contains all the latest changes. It is possible to reference a snapshot version, but this should only 
                    be done during testing. Any components that are running in production should always refer to published versions.
                </Chapter.Paragraph>
                <Chapter.Paragraph>
                    Changes to a version can be undone in two ways; all edits since the last published version can be discarded,
                    or a previous version can be republished as a new version. The latter case can be used to roll-back a version
                    in which a bug has been discovered. For example, say v3 was published and a bug is found. The v3 cannot be simply 
                    removed since there may be components already referencing it. In order to go back to v2, a new version v4 must be 
                    published. In any of the dataset, model, or workflow listings, <Mono>Actions &gt; Versioning &gt; Revert version</Mono> can
                    be selected and the desired version to revert back to can be selected.
                </Chapter.Paragraph>
            </Chapter>
        </DocFrame>
    );

}
