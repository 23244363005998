import axios, { AxiosRequestConfig, Method } from 'axios';
import messageSpinner from 'components/MessageSpinner/MessageSpinner';
import { HttpHeaders, Headers } from '@methodset/commons-client-ts';
import { RouteBuilder } from 'utils/RouteBuilder';
import { CoreUtils } from './CoreUtils';
import authService from 'services/AuthService';

export interface Options {
    [key: string]: string | undefined;
}

interface Detail {
    code: string,
    key: string,
    message: string
}

export type SuccessHandler = (response: any) => any;
export type FailureHandler = (response: any) => any;

export class RestUtils {

    public static readonly OkStatus = "__OK_STATUS__";
    public static readonly ErrorStatus = "__ERROR_STATUS__";

    private constructor() { }

    /**
     * Gets the HTTP headers, resolving the access token if necessary.
     * 
     * @returns A function that return a promise to get the HTTP headers.
     */
    public static getHttpHeaders(): HttpHeaders {
        return async (): Promise<Headers> => {
            return await this.headers();
        }
    }

    public static async headers(): Promise<Headers> {
        let headers: Headers = {};
        const token = await authService.readAccessToken();
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
            headers['X-Time-Zone'] = CoreUtils.systemTimeZone()
        }
        return headers
    }

    /**
     * Checks if all responses that are defined are true.
     */
    public static isOk(...args: any[]): boolean {
        if (!args) {
            return false;
        }
        for (let i = 0; i < args.length; i++) {
            const response = args[i];
            if (!response) {
                continue;
            }
            if (response === RestUtils.ErrorStatus ||
                response instanceof Error ||
                (response.status && response.status / 100 !== 2)) {
                return false;
            }
        }
        return true;
    }

    /**
     * Extract the error message from the response.
     */
    public static getErrorMessage(response: any): string {
        let message;
        if (response.response && response.response.data) {
            const data = response.response.data;
            const status = data.resultStatus;
            message = status ? status.message : data.error;
            // if (!message) {
            //     message = response.response.statusText;
            // }
            message = message ?? response.message;
        } else if (response.message) {
            message = `${response.message}`;
        } else {
            message = "Error processing request.";
        }
        return message;
    }

    public static getError(response: any): Error {
        const message = RestUtils.getErrorMessage(response);
        return new Error(message);
    }

    public static checkAuthorization(response: any): boolean {
        // Check for HTTP unauthorized error.
        if (response.response && response.response.status === 401) {
            window.location.assign(RouteBuilder.MAIN_LOGIN);
            return false;
        }
        return true;
    }

    private static async execute(method: Method, url: string, data: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler,
        ownStatus?: boolean, message?: string, isProtected: boolean = true): Promise<any> {
        // If message === null, be silent (do not display any messages).
        // This can be used for e.g., health checks and warming requests.
        if (message) {
            RestUtils.logRequest(method, url);
        }
        if (message && !ownStatus) {
            messageSpinner.show(message);
        }
        let config: AxiosRequestConfig;
        if (isProtected) {
            // URL is protected, requires an access token.
            try {
                const headers = await this.headers();
                config = {
                    headers: headers
                };
            } catch (e) {
                // User not logged in, send to login page.
                window.location.assign(RouteBuilder.MAIN_LOGIN);
                return;
            }
        } else {
            config = {};
        }

        config.method = method;
        config.url = url;
        config.data = data;
        // Access token is valid if reaching this point. Make 
        // the call to the service. The success or failure of the call
        // will be returned in the callbacks (resolve or reject).
        return axios(config).then((response) => {
            if (message) {
                RestUtils.logResponse(method, url, response);
            }
            let result;
            if (onSuccess) {
                result = onSuccess(response);
            }
            if (message && !ownStatus) {
                messageSpinner.hide();
            }
            return result ?? RestUtils.OkStatus;
        }).catch((response) => {
            if (message) {
                RestUtils.logError(method, url, response);
            }
            if (!RestUtils.checkAuthorization(response)) {
                return false;
            }
            let error;
            if (onFailure) {
                // Callback can determine if this is actually an
                // error. For example, a 404 (NOT_FOUND) HTTP 
                // status may just mean that an element needs to
                // be created vs. updated.
                error = onFailure(response);
            }
            if (message && !ownStatus) {
                messageSpinner.error(RestUtils.getErrorMessage(response));
            }
            return error ?? RestUtils.ErrorStatus;
        });
    }

    public static get(url: string, onSuccess?: SuccessHandler, onFailure?: FailureHandler,
        ownStatus?: boolean, message?: string, isProtected?: boolean): Promise<any> {
        return RestUtils.execute('get', url, null, onSuccess, onFailure, ownStatus, message, isProtected);
    }

    public static put(url: string, data: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler,
        ownStatus?: boolean, message?: string, isProtected?: boolean): Promise<any> {
        return RestUtils.execute('put', url, data, onSuccess, onFailure, ownStatus, message, isProtected);
    }

    public static post(url: string, data: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler,
        ownStatus?: boolean, message?: string, isProtected?: boolean): Promise<any> {
        return RestUtils.execute('post', url, data, onSuccess, onFailure, ownStatus, message, isProtected);
    }

    public static delete(url: string, onSuccess?: SuccessHandler, onFailure?: FailureHandler,
        ownStatus?: boolean, message?: string, isProtected?: boolean): Promise<any> {
        return RestUtils.execute('delete', url, null, onSuccess, onFailure, ownStatus, message, isProtected);
    }

    public static buildQuery(options: Options, encode: boolean = true, addSeparator: boolean = true): string {
        let query = '';
        if (!options) {
            return query;
        }
        let delimiter;
        let i = 0;
        for (let key in options) {
            const value = options[key];
            if (!CoreUtils.isEmpty(value)) {
                delimiter = i === 0 ? addSeparator ? '?' : '' : '&';
                query = `${query}${delimiter}${key}=${encode ? encodeURIComponent(value!) : value}`;
                i++;
            }
        }
        return query ? query : '';
    }

    public static getQueryParameter(parameter: string): string | null {
        let query = window.location.search.substring(1);
        let params = query.split('&');
        for (let i = 0; i < params.length; i++) {
            let pair = params[i].split('=');
            if (decodeURIComponent(pair[0]) === parameter) {
                return decodeURIComponent(pair[1]);
            }
        }
        return null;
    }

    public static logRequest(method: string, url: string): void {
        console.log(`Request: ${method.toUpperCase()} ${url}`);
    }

    public static logResponse(method: string, url: string, response: any): void {
        if (response.data && response.data.resultStatus) {
            const resultStatus = response.data.resultStatus;
            console.log(`Response: ${method.toUpperCase()} ${url}, correlation id = ${resultStatus.correlationId}`);
        } else {
            console.log(`Response: ${method.toUpperCase()} ${url}`);
        }
    }

    public static logError(method: string, url: string, response: any): void {
        console.error(`Response: ${method.toUpperCase()} ${url}`);
        if (response.message) {
            console.error(`Error message: ${response.message}.`);
        }
        if (response.response) {
            const data = response.response.data;
            const resultStatus = data.resultStatus;
            if (resultStatus) {
                console.error(`Request id: ${resultStatus.requestId}`);
                console.error(`Correlation id: ${resultStatus.correlationId}`);
                console.error(`Exception id: ${resultStatus.exceptionId}`);
                console.error(`Error code: ${resultStatus.code}`);
                console.error(`Error message: ${resultStatus.message}`);
                if (resultStatus.details) {
                    resultStatus.details.forEach((detail: Detail) => {
                        console.error(`Code: ${detail.code}, key: ${detail.key}, detail message: ${detail.message}`);
                    });
                }
            } else {
                console.error(`Status: ${response.response.status}`);
                console.error(`Message: ${response.response.statusText}`);
            }
        }
    }

}
