import { ReactElement, useRef, useState } from 'react';
import { ApplicationHeader, Dashboard, DashboardApplet } from '@methodset/application-client-ts';
import { PackHeader } from '@methodset/library-client-ts';
import { Applet, Application, ApplicationType } from '@methodset/application-client-ts';
import { ApplicationInstaller } from '../ApplicationInstaller';
import { Calculator } from '@methodset/calculator-ts';
import { RestUtils } from 'utils/RestUtils';
import { Globals } from 'constants/Globals';
import update from 'immutability-helper';
import applicationService from 'services/ApplicationService';
import './AppletInstaller.less';

export type InstallCallback = (dashboard: Dashboard) => void;
export type CloseCallback = () => void;

export type AppletInstallerProps = {
    dashboard: Dashboard,
    columnSetups: DashboardApplet[][],
    onInstall: InstallCallback,
    onClose: CloseCallback
}

export const AppletInstaller = (props: AppletInstallerProps): ReactElement => {

    const dashboard = useRef<Dashboard>();
    const applet = useRef<Applet>();

    const handleLoad = (application: Application, calculator: Calculator): void => {
        applet.current = application as Applet;
    }

    const handleInstall = (applicationHeader: ApplicationHeader, packHeader: PackHeader): Promise<any> => {
        return installAppletRequest(applicationHeader, packHeader);
    }

    const handleComplete = (applicationHeader: ApplicationHeader, packHeader: PackHeader): void => {
        props.onInstall(dashboard.current!);
    }

    const findOpenPosition = (span: number): [number, number] => {
        let row = 0;
        let col = 0;
        let anchor = 0;
        let isEnd;
        const columnSetups = props.columnSetups;
        do {
            isEnd = true;
            while (col < Globals.LAYOUT_COLUMNS) {
                const columnSetup = columnSetups[col];
                const appletState = columnSetup[row];
                if (appletState) {
                    col += appletState.span;
                    anchor = col;
                    isEnd = false;
                } else {
                    col++;
                    if (span <= col - anchor) {
                        return [row, anchor];
                    }
                }
            }
            row++;
            col = 0;
            anchor = 0;
        } while (!isEnd);
        return [row - 1, 0];
    }

    const installAppletRequest = (applicationHeader: ApplicationHeader, packHeader: PackHeader): Promise<any> => {
        const span = applet.current!.span;
        const [row, col] = findOpenPosition(span);
        const appletState = {
            configuration: {},
            col: col,
            row: row,
            span: span
        }
        // TODO: remove all, but admissions, schedules?
        // const packInfo = packHeader ? {
        //     packId: packHeader.id,
        //     version: packHeader.version,
        //     moduleId: packHeader.moduleId,
        //     name: packHeader.name,
        //     description: packHeader.description,
        //     publisher: packHeader.publisher,
        //     accessType: packHeader.accessType,
        //     admissions: packHeader.admissions,
        //     schedules: packHeader.schedules
        // } : {};

        const request = {
            dashboardId: props.dashboard.id,
            applicationId: applicationHeader.id,
            moduleId: applicationHeader.moduleId,
            name: applicationHeader.name,
            description: applicationHeader.description,
            //packInfo: packInfo,
            admissions: packHeader ? packHeader.admissions : [],
            schedules: packHeader ? packHeader.schedules : [],
            appletState: appletState
        };
        return applicationService.installApplet(request,
            (response: any) => installAppletResponse(response),
            (response: any) => installAppletException(response),
            true
        );
    }

    const installAppletResponse = (response: any): void => {
        const dashboardApplet = response.data.applet;
        dashboard.current = update(props.dashboard, {
            applets: {
                $push: [dashboardApplet]
            }
        });
    }

    const installAppletException = (response: Response): void => {
        throw RestUtils.getError(response);
    }

    return (
        <ApplicationInstaller
            applicationType={ApplicationType.APPLET}
            onLoad={handleLoad}
            onInstall={handleInstall}
            onComplete={handleComplete}
            onClose={props.onClose}
        />
    )

}
