import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Form, FormInstance, Input, Result, Row } from 'antd';
import { RouteBuilder } from 'utils/RouteBuilder';
import { ResponsiveBox } from 'components/ResponsiveBox/ResponsiveBox';
import { FormFrame } from 'components/FormFrame/FormFrame';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { Justify } from 'components/Justify/Justify';
import { RestUtils } from 'utils/RestUtils';
import { RequestType } from '@methodset/entity-client-ts';
import update from 'immutability-helper';
import entityService from 'services/EntityService';
import './Request.less';

export interface FormData {
    requestType: RequestType,
    firstName?: string,
    lastName?: string,
    companyName?: string,
    emailAddress?: string,
    comments?: string
}

export type RequestProps = RouteComponentProps & {
    type: RequestType,
    requestText: string | ReactElement,
    responseText: string
}

export type RequestState = {
    error?: Error,
    isSubmitting: boolean
    formData: FormData
}

export class Request extends PureComponent<RequestProps, RequestState> {

    private formRef = React.createRef<FormInstance>();

    constructor(props: RequestProps) {
        super(props);
        this.state = {
            error: undefined,
            isSubmitting: false,
            formData: {
                requestType: props.type
            }
        };
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleCompanyNameChange = this.handleCompanyNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleCommentsChange = this.handleCommentsChange.bind(this);
        this.handleFormFinish = this.handleFormFinish.bind(this);
    }

    private handleFirstNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const firstName = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                firstName: { $set: firstName }
            })
        });
    }

    private handleLastNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const lastName = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                lastName: { $set: lastName }
            })
        });
    }

    private handleCompanyNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const companyName = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                companyName: { $set: companyName }
            })
        });
    }

    private handleEmailChange(e: ChangeEvent<HTMLInputElement>): void {
        const emailAddress = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                emailAddress: { $set: emailAddress }
            })
        });
    }

    private handleCommentsChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        const comments = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                comments: { $set: comments }
            })
        });
    }

    private handleFormFinish(): void {
        if (this.state.isSubmitting) {
            return;
        }
        this.saveDemoRequest();
    }

    private isSubmitted(): boolean {
        const query = this.props.location.search;
        const params = new URLSearchParams(query);
        const state = params.get("state");
        return state === "submitted";
    }

    private saveDemoRequest(): Promise<any> {
        this.setState({ isSubmitting: true });
        const request = {
            ...this.state.formData
        };
        return entityService.createRequest(request,
            (response: any) => this.createDemoResponse(response),
            (response: any) => this.createDemoException(response),
            true
        );
    }

    private createDemoResponse(response: any): void {
        this.setState({ isSubmitting: false });
        this.props.history.push(RouteBuilder.requestSubmitted(RouteBuilder.MAIN_DEMO));
    }

    private createDemoException(response: any): void {
        this.setState({
            error: new Error(RestUtils.getErrorMessage(response)),
            isSubmitting: false
        });
    }

    private buildSuccessView(): ReactElement {
        return (
            <Result
                status="success"
                title="Request Submitted"
                subTitle={this.props.responseText}
                extra={(
                    <Button
                        type="primary"
                        onClick={() => this.props.history.push(RouteBuilder.HOME)}
                    >
                        Return to Home
                    </Button>
                )}
            />
        )
    }

    private buildFormView(): ReactElement {
        return (
            <FormFrame
                className="x-request-form"
                title="Demo Request"
                error={this.state.error}
            >
                <Form
                    ref={this.formRef}
                    onFinish={this.handleFormFinish}
                >
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        label="First Name"
                        name="first-name"
                        rules={[{
                            required: true,
                            message: 'Please enter your first name.'
                        }]}
                    >
                        <Input
                            id="first"
                            placeholder="First name."
                            value={this.state.formData.firstName}
                            onChange={this.handleFirstNameChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        label="Last Name"
                        name="last-name"
                        rules={[{
                            required: true,
                            message: 'Please enter your last name.'
                        }]}
                    >
                        <Input
                            id="last"
                            placeholder="Last name."
                            value={this.state.formData.lastName}
                            onChange={this.handleLastNameChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        label="Company Name"
                        name="company-name"
                        rules={[{
                            required: true,
                            message: 'Please enter your company name.'
                        }]}
                    >
                        <Input
                            id="company"
                            placeholder="Company name."
                            value={this.state.formData.companyName}
                            onChange={this.handleCompanyNameChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        label="Email"
                        name="email"
                        rules={[{
                            required: true,
                            message: 'Please enter your email address.'
                        }, {
                            type: 'email',
                            message: 'The email address is invalid.'
                        }]}
                    >
                        <Input
                            id="email"
                            placeholder="Email address."
                            value={this.state.formData.emailAddress}
                            onChange={this.handleEmailChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        label="Comments"
                        name="comments"
                    >
                        <Input.TextArea
                            placeholder="Comments or questions."
                            rows={3}
                            value={this.state.formData.comments}
                            onChange={this.handleCommentsChange}
                        />
                    </FormItem>
                    <Justify className="x-request-footer" justification="right">
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={this.state.isSubmitting}
                        >
                            Submit
                        </Button>
                    </Justify>
                </Form>
            </FormFrame>
        )
    }

    public render(): ReactElement {
        return (
            <ResponsiveBox
                lg={{ className: 'x-request-lg' }}
                sm={{ className: 'x-request-sm' }}
                xs={{ className: 'x-request-xs' }}
            >
                <Row
                    justify="center"
                    align="top"
                >
                    <Col
                        className="x-request-content"
                        lg={{ span: 14 }}
                        xs={{ span: 24 }}
                    >
                        <p className="x-request-instruction">
                            {this.props.requestText}
                        </p>
                    </Col>
                    <Col
                        className="x-request-content"
                        lg={{ span: 10 }}
                        xs={{ span: 24 }}
                    >
                        {this.isSubmitted() &&
                            this.buildSuccessView()
                        }
                        {!this.isSubmitted() &&
                            this.buildFormView()
                        }
                    </Col>
                </Row>
            </ResponsiveBox>
        )
    }

}
