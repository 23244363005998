import { blue } from '@ant-design/colors';
import { CheckOutlined } from '@ant-design/icons';
import { Spacer } from 'components/Spacer/Spacer';
import { ReactElement } from 'react';
import classNames from 'classnames';
import './Pricing.less';

export type PricingProps = {
    className?: string,
    children?: ReactElement | ReactElement[],
    color?: string[],
    title?: string,
    radius?: number,
    width?: number,
    headerHeight?: number,
    bodyHeight?: number,
    price?: string,
    description?: string,
    bullets?: string[],
    shadow?: boolean
} & typeof defaultProps;

const defaultProps = {
    color: blue,
    radius: 16,
    width: 275,
    headerHeight: 150,
    bullets: [] as string[],
    shadow: false
}

export const Pricing = (props: PricingProps): ReactElement => {
    const radius = `${props.radius}px`;
    const panelStyle = {
        borderRadius: radius,
        border: `1px solid ${props.color[6]}`
    };
    const headerStyle = {
        width: `${props.width}px`,
        height: props.headerHeight ? `${props.headerHeight}px` : undefined,
        color: props.color[6],
        backgroundColor: `${props.color[1]}`,
        borderRadius: `${radius} ${radius} 0 0`
    };
    const dividerStyle = {
        width: `${props.width}px`,
        borderTop: `1px solid ${props.color[6]}`
    };
    const bodyStyle = {
        width: `${props.width}px`,
        height: props.bodyHeight ? `${props.bodyHeight}px` : undefined,
        borderRadius: `0 0 ${radius} ${radius}`,
    };
    return (
        <div className={classNames('x-pricing', props.className, { 'x-pricing-shadow': props.shadow })} style={panelStyle}>
            <div className="x-pricing-header" style={headerStyle}>
                <div className="x-pricing-title">{props.title}</div>
                <div className="x-pricing-price">{props.price}</div>
            </div>
            <div className="x-pricing-divider" style={dividerStyle}>
            </div>
            <div className="x-pricing-body" style={bodyStyle}>
                <div className="x-pricing-description">
                    {props.description}
                </div>
                {props.bullets.map((bullet, index) => (
                    <div key={index} className="x-pricing-bullet">
                        <Spacer alignment="top">
                            <CheckOutlined className="x-pricing-check" />
                            {bullet}
                        </Spacer>
                    </div>
                ))}
            </div>
            {props.children &&
                <div>{props.children}</div>
            }
        </div>
    )
}

Pricing.defaultProps = defaultProps;
