import { ReactElement } from 'react';
import { FormInstance, Input } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { ListLoop, Loop, LoopType, RangeDef, RangeLoop } from '@methodset/endpoint-client-ts';
import update from 'immutability-helper';
import { RangeSelector } from 'containers/Console/ConfigurationEditor/RangeSelector/RangeSelector';

export type ChangeCallback = (loop: Loop) => void;

export type RangeLoopEditorProps = {
    formRef: React.RefObject<FormInstance>,
    loop: RangeLoop,
    onChange: ChangeCallback
}

export const RangeLoopEditor = (props: RangeLoopEditorProps): ReactElement => {

    const handleRangeChange = (rangeDef: RangeDef): void => {
        const loop = update(props.loop, {
            modelId: { $set: rangeDef.modelId },
            modelVersion: { $set: rangeDef.version },
            sheetId: { $set: rangeDef.sheetId },
            rangeId: { $set: rangeDef.rangeId }
        });
        props.onChange(loop);
    }

    return (
        <RangeSelector
            formRef={props.formRef}
            rangeDef={{
                modelId: props.loop.modelId,
                version: props.loop.modelVersion,
                sheetId: props.loop.sheetId,
                rangeId: props.loop.rangeId,
                configuration: {}
            }}
            onChange={(def) => handleRangeChange(def)}
        />
    )

}
