import { PureComponent, ReactElement } from 'react';
import { ApiKey } from '@methodset/entity-client-ts';
import { Button } from 'antd';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { Globals } from 'constants/Globals';
import { ColumnsType } from 'antd/lib/table';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { DeleteOutlined } from '@ant-design/icons';
import entityService from 'services/EntityService';
import axios from 'axios';
import classNames from 'classnames';
import update from 'immutability-helper';
import './ApiKeys.less';
import { StatusType } from 'constants/StatusType';

export type ApiKeysState = {
    status: StatusType,
    error?: string,
    isSubmitting: boolean,
    apiKeys: ApiKey[]
}

export type ApiKeysProps = {
    className?: string
}

export class ApiKeys extends PureComponent<ApiKeysProps, ApiKeysState> {

    constructor(props: ApiKeysProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            error: undefined,
            isSubmitting: false,
            apiKeys: []
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleKeyCreate = this.handleKeyCreate.bind(this);
        this.handleKeyDelete = this.handleKeyDelete.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleKeyCreate(): void {
        this.createApiKeyRequest();
    }

    private handleKeyDelete(apiKey: any): void {
        const secretKey = apiKey.secretKey;
        this.deleteApiKeyRequest(secretKey);
    }

    private readApiKeysRequest(): Promise<any> {
        const request = {};
        return entityService.readApiKeys(request,
            (response: any) => this.readApiKeysResponse(response),
            undefined, true
        );
    }

    private readApiKeysResponse(response: any): void {
        const apiKeys = response.data.apiKeys;
        this.setState({ apiKeys: apiKeys });
    }

    private createApiKeyRequest(): Promise<any> {
        const request = {};
        return entityService.createApiKey(request,
            (response: any) => this.createApiKeyResponse(response),
            undefined, true
        );
    }

    private createApiKeyResponse(response: any): void {
        const apiKey = response.data.apiKey;
        const apiKeys = update(this.state.apiKeys, {
            $push: [apiKey]
        });
        this.setState({ apiKeys: apiKeys });
    }

    private deleteApiKeyRequest(secretKey: any): Promise<any> {
        const request = {
            secretKey: secretKey
        };
        return entityService.deleteApiKey(request,
            (response: any) => this.deleteApiKeyResponse(response),
            undefined, true
        );
    }

    private deleteApiKeyResponse(response: any): void {
        const secretKey = response.data.secretKey;
        const index = this.state.apiKeys.findIndex(key => key.secretKey === secretKey);
        if (index === -1) {
            return;
        }
        const apiKeys = update(this.state.apiKeys, {
            $splice: [[index, 1]]
        });
        this.setState({ apiKeys: apiKeys });
    }

    private buildColumns(): ColumnsType<any> {
        const columns: ColumnsType<any> = [{
            key: 'key',
            title: 'Key',
            dataIndex: 'secretKey',
            render: (value: string, record: any) => {
                return <span>{value}</span>;
            }
        }, {
            key: 'timezone',
            title: 'Time Zone',
            align: 'center',
            dataIndex: 'timeZone',
            render: (value: string, record: any) => {
                return <span>{CoreUtils.replace(value, "_", " ")}</span>;
            }
        }, {
            key: 'ctime',
            title: 'Issue Time',
            align: 'center',
            dataIndex: 'createTime',
            render: (value: string, record: any) => {
                return <span>{CoreUtils.toTime(value)}</span>;
            }
        }, {
            key: 'etime',
            title: 'Expiration Time',
            align: 'center',
            dataIndex: 'expireTime',
            render: (value: string, record: any) => {
                if (!value) {
                    return 'Never';
                } else {
                    return <span>{CoreUtils.toTime(value)}</span>;
                }
            }
        }];
        return columns;
    }

    private buildData(): any[] {
        return this.state.apiKeys;
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readApiKeysRequest());
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== StatusType.READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        const actions = [{
            icon: <DeleteOutlined />,
            label: "Delete API key",
            confirm: "Are you sure you want to delete the API key?",
            callback: this.handleKeyDelete
        }];
        const columns = this.buildColumns();
        const data = this.buildData();
        return (
            <ItemTable
                className={classNames('x-apikeys', this.props.className)}
                title="API Keys"
                rowKey="secretKey"
                status={this.state.status}
                columns={columns}
                items={data}
                extra={
                    <Button onClick={this.handleKeyCreate}>Create Key</Button>
                }
                actions={actions}
                onLoad={this.handleRetryLoad}
            />
        );
    }

}
