import React, { PureComponent, ReactElement } from 'react';
import { Checkbox, Col, FormInstance, Row, Select, Switch } from 'antd';
import { Calculator, RefType } from '@methodset/calculator-ts';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { CandleDataset, CandleWidgetConfiguration, ChartAxis, WidgetType } from '@methodset/application-client-ts';
import { RefEditor } from 'containers/Components/Widgets/RefEditor/RefEditor';
import { WidgetUtils } from 'utils/WidgetUtils';
import { Spacer } from 'components/Spacer/Spacer';
import { Info } from 'components/Info/Info';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import update from 'immutability-helper';
import './CandleEditor.less';

export type ChangeCallback = (configuration: CandleWidgetConfiguration) => void;

export type CandleEditorProps = typeof CandleEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    type?: WidgetType,
    defaultConfigurations: ReactElement,
    customConfigurations?: ReactElement,
    configuration?: CandleWidgetConfiguration,
    calculator: Calculator,
    onChange: ChangeCallback
}

export class CandleEditor extends PureComponent<CandleEditorProps> {

    static DefaultConfiguration = {
        type: WidgetType.CANDLESTICK_CHART,
        height: 300,
        xAxis: {} as ChartAxis,
        yAxis: {} as ChartAxis,
        dataset: {} as CandleDataset
    } as CandleWidgetConfiguration;

    static defaultProps = {
        configuration: CandleEditor.DefaultConfiguration
    }

    constructor(props: CandleEditorProps) {
        super(props);
        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.handleHeadersChange = this.handleHeadersChange.bind(this);
        this.handleOpenRangeChange = this.handleOpenRangeChange.bind(this);
        this.handleHighRangeChange = this.handleHighRangeChange.bind(this);
        this.handleLowRangeChange = this.handleLowRangeChange.bind(this);
        this.handleCloseRangeChange = this.handleCloseRangeChange.bind(this);
        this.handleTimeRangeChange = this.handleTimeRangeChange.bind(this);
    }

    private handleRangeChange(rangeId: string | undefined): void {
        const configuration = update(this.props.configuration, {
            dataset: {
                rangeId: { $set: rangeId as any }
            }
        });
        this.props.onChange(configuration);
    }

    private handleHeadersChange(e: CheckboxChangeEvent): void {
        const hasHeaders = e.target.checked;
        const configuration = update(this.props.configuration, {
            dataset: {
                hasHeaders: { $set: hasHeaders }
            }
        });
        this.props.onChange(configuration);
    }

    private handleTimeRangeChange(timeRangeId: string): void {
        const configuration = update(this.props.configuration, {
            dataset: {
                timeRangeId: { $set: timeRangeId }
            }
        });
        this.props.onChange(configuration);
    }

    private handleOpenRangeChange(openRangeId: string): void {
        const configuration = update(this.props.configuration, {
            dataset: {
                openRangeId: { $set: openRangeId }
            }
        });
        this.props.onChange(configuration);
    }

    private handleHighRangeChange(highRangeId: string): void {
        const configuration = update(this.props.configuration, {
            dataset: {
                highRangeId: { $set: highRangeId }
            }
        });
        this.props.onChange(configuration);
    }

    private handleLowRangeChange(lowRangeId: string): void {
        const configuration = update(this.props.configuration, {
            dataset: {
                lowRangeId: { $set: lowRangeId }
            }
        });
        this.props.onChange(configuration);
    }

    private handleCloseRangeChange(closeRangeId: string): void {
        const configuration = update(this.props.configuration, {
            dataset: {
                closeRangeId: { $set: closeRangeId }
            }
        });
        this.props.onChange(configuration);
    }

    public componentDidMount(): void {
        if (this.props.configuration === CandleEditor.DefaultConfiguration) {
            this.props.onChange(this.props.configuration);
        }
    }

    public render(): ReactElement {
        const vectors = WidgetUtils.rangeVectors(this.props.calculator, this.props.configuration.dataset.rangeId, this.props.configuration.dataset.hasHeaders);
        return (
            <Row gutter={Globals.FORM_GUTTER_ROW}>
                <Col span={12}>
                    {this.props.defaultConfigurations}
                </Col>
                <Col span={12}>
                    {this.props.customConfigurations}
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        required={true}
                        label="Data Range"
                        name="range"
                        info="The range that includes the data for the chart."
                    >
                        <RefEditor
                            formRef={this.props.formRef}
                            index={0}
                            calculator={this.props.calculator}
                            refTypes={[RefType.RANGE]}
                            refId={this.props.configuration.dataset.rangeId}
                            onChange={this.handleRangeChange}
                        />
                    </FormItem>
                    {/* <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Includes Headers"
                        name="headers"
                        info="True if the data range has header values in the first row. These will be used to help identify the column contents."
                        valuePropName="checked"
                        required={true}
                    >
                        <Switch
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={this.props.configuration.dataset.hasHeaders}
                            onChange={this.handleHeadersChange}
                        />
                    </FormItem> */}
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Time Range"
                        name="time-range"
                        info="The range that contains the time values."
                        rules={[{
                            required: true,
                            message: "Please select a range."
                        }]}
                    >
                        <Select
                            value={this.props.configuration.dataset.timeRangeId}
                            onChange={this.handleTimeRangeChange}
                        >
                            {vectors.map(column => (
                                <Select.Option key={column.id} value={column.id}>{column.label}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Open Range"
                        name="open-range"
                        info="The range that contains the open values."
                        rules={[{
                            required: true,
                            message: "Please select a range."
                        }]}
                    >
                        <Select
                            value={this.props.configuration.dataset.openRangeId}
                            onChange={this.handleOpenRangeChange}
                        >
                            {vectors.map(column => (
                                <Select.Option key={column.id} value={column.id}>{column.label}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="High Range"
                        name="high-range"
                        info="The range that contains the high values."
                        rules={[{
                            required: true,
                            message: "Please select a range."
                        }]}
                    >
                        <Select
                            value={this.props.configuration.dataset.highRangeId}
                            onChange={this.handleHighRangeChange}
                        >
                            {vectors.map(column => (
                                <Select.Option key={column.id} value={column.id}>{column.label}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Low Range"
                        name="low-range"
                        info="The range that contains the low values."
                        rules={[{
                            required: true,
                            message: "Please select a range."
                        }]}
                    >
                        <Select
                            value={this.props.configuration.dataset.lowRangeId}
                            onChange={this.handleLowRangeChange}
                        >
                            {vectors.map(column => (
                                <Select.Option key={column.id} value={column.id}>{column.label}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Close Range"
                        name="close-range"
                        info="The range that contains the close values."
                        rules={[{
                            required: true,
                            message: "Please select a range."
                        }]}
                    >
                        <Select
                            value={this.props.configuration.dataset.closeRangeId}
                            onChange={this.handleCloseRangeChange}
                        >
                            {vectors.map(column => (
                                <Select.Option key={column.id} value={column.id}>{column.label}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    <Spacer className="x-candleeditor-checkbox" direction="vertical">
                        <Checkbox
                            checked={this.props.configuration.dataset.hasHeaders}
                            onChange={this.handleHeadersChange}
                        >
                            <Info
                                label="Range Includes Headers"
                                info="True if the data range has header values in the first row."
                                bold={true}
                            />
                        </Checkbox>
                    </Spacer>
                </Col>
            </Row>
        );
    }

}
