import { ReactElement, useEffect, useState } from 'react';
import { FormInstance } from 'antd';
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { StatusType } from 'constants/StatusType';
import { Processor, ProcessorHeader, ProcessorType } from '@methodset/endpoint-client-ts';
import { FormItem } from 'components/FormItem/FormItem';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { ProcessorList } from './ProcessorList/ProcessorList';
import { CoreUtils } from 'utils/CoreUtils';
import endpointService from 'services/EndpointService';
import axios from 'axios';
import './ProcessorPicker.less';

export type ChangeCallback = (processorId: string, version: number) => void;
export type LoadCallback = (processor: Processor) => void;

export type ProcessorPickerProps = {
    formRef: React.RefObject<FormInstance>,
    // Types of processors to include in selection list.
    types?: ProcessorType[],
    // The selected processor.
    processorId?: string,
    // List of processor headers, optional if cached in parent.
    headers?: ProcessorHeader[],
    // The version of the selected processor.
    version?: number,
    // Called when a processor is selected.
    onChange: ChangeCallback,
    // Called when the selected processor is loaded.
    onLoad: LoadCallback
} & typeof defaultProps;

const defaultProps = {
}

export const ProcessorPicker = (props: ProcessorPickerProps): ReactElement => {

    const [status, setStatus] = useState<string>(StatusType.INIT);

    useEffect(() => {
        loadData(props.processorId, props.version);
    }, []);

    const handleRetryLoad = (): void => {
        loadData(props.processorId, props.version);
    }

    const handleProcessorSelect = (header: ProcessorHeader): void => {
        loadData(header.id, header.version);
        props.onChange(header.id, header.version);
    }

    const handleVersionChange = (version: number): void => {
        loadData(props.processorId, version);
        props.onChange(props.processorId!, version);
    }

    const readProcessorRequest = (id: string, version?: number): Promise<any> => {
        const request = {
            processorId: id,
            version: version
        };
        return endpointService.readProcessor(request,
            (response: any) => readProcessorResponse(response),
            undefined, true
        );
    }

    const readProcessorResponse = (response: any): Processor => {
        const processor = response.data.processor;
        return processor;
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        return (
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
            >
                <LoadSpinner
                    className="x-processorpicker-loading"
                    status={isLoading ? "loading" : "failed"}
                    loadingMessage="Loading processor..."
                    failedMessage="Failed to load processor."
                    onRetry={handleRetryLoad}
                />
            </FormItem>
        );
    }

    const loadData = (processorId: string | undefined, version: number | undefined): void => {
        if (!CoreUtils.isDefined(processorId, version)) {
            setStatus(StatusType.READY);
            return;
        }
        const requests = [];
        requests.push(readProcessorRequest(processorId!, version!));
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((processor) => {
            if (RestUtils.isOk(processor)) {
                props.onLoad(processor);
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
    }

    let loadingView;
    if (status === StatusType.LOADING) {
        loadingView = buildLoadingView(true);
    } else if (status === StatusType.FAILED) {
        loadingView = buildLoadingView(false);
    } else {
        loadingView = <></>;
    }

    return (
        <div className="x-processorpicker">
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Processor"
                name="processor"
                info="The component to process data."
                valuePropName="processorId"
                rules={[{
                    required: true,
                    message: 'Please select a processor.'
                }]}
            >
                <ProcessorList
                    formRef={props.formRef}
                    types={props.types}
                    headers={props.headers}
                    processorId={props.processorId}
                    onChange={handleProcessorSelect}
                />
            </FormItem>
            {loadingView}
        </div>
    );

}

ProcessorPicker.defaultProps = defaultProps;
