import { ReactElement } from 'react';
import { Button } from 'antd';
import { Spin } from 'antd';
import classNames from 'classnames';
import './LoadSpinner.less';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

export type RetryCallback = () => void;

export type LoadSpinnerProps = {
    className?: string,
    loadingMessage?: string,
    failedMessage?: string,
    failedLabel?: string
    placement?: "top" | "center" | "left",
    status?: "loading" | "failed",
    size?: "small" | "default" | "large",
    fullscreen?: boolean,
    onRetry?: RetryCallback
} & typeof defaultProps;

const defaultProps = {
    loadingMessage: "Loading...",
    failedMessage: "Failed to load data.",
    failedLabel: "Retry",
    placement: "top",
    status: "loading",
    size: "default",
    fullscreen: false
}

/**
 * Creates a component that shows a loading spinner and message.
 */
export const LoadSpinner = (props: LoadSpinnerProps): ReactElement => {

    const buttonSize = (): SizeType => {
        if (!props.size) {
            return undefined;
        }
        const map = {
            small: "small",
            default: "middle",
            large: "large"
        }
        return map[props.size] as SizeType;
    }

    let view;
    if (props.status === 'loading') {
        view = (
            <div className="x-loadspinner-loading">
                <Spin size={props.size} />
                <span className="x-loadspinner-message-load">{props.loadingMessage}</span>
            </div>
        );
    } else if (props.status === 'failed') {
        view = (
            <>
                <div className="x-loadspinner-message-fail">{props.failedMessage}</div>
                {props.onRetry &&
                    <Button type="primary" size={buttonSize()} onClick={props.onRetry}>{props.failedLabel}</Button>
                }
            </>
        );
    }
    return (
        <div className={classNames('x-loadspinner', { 'x-loadspinner-fullscreen': props.fullscreen === true }, props.className)}>
            <div className={`x-loadspinner-${props.placement}`}>
                {view}
            </div>
        </div>
    )
}

LoadSpinner.defaultProps = defaultProps;
