import { PureComponent, ReactElement } from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import classNames from 'classnames';
import './LabelCheckbox.less';

export type ChangeCallback = (checked: boolean, index: number) => void;

export type LabelCheckboxProps = typeof LabelCheckbox.defaultProps & {
    className?: string,
    index?: number,
    checked: boolean,
    label: string,
    onChange: ChangeCallback
}

export class LabelCheckbox extends PureComponent<LabelCheckboxProps> {

    static defaultProps = {
        index: -1
    }

    constructor(props: LabelCheckboxProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    private handleChange(e: CheckboxChangeEvent): void {
        this.props.onChange(e.target.checked, this.props.index);
    }

    public render(): ReactElement {
        return (
            <div className={classNames('x-labelcheckbox', this.props.className)}>
                <Checkbox checked={this.props.checked} onChange={this.handleChange} />
                <div className="x-labelcheckbox-label">{this.props.label}</div>
            </div>
        )
    }
}
