import { ReactElement, useEffect } from 'react';
import { Widget, Applet, WidgetType, AppletPanel } from '@methodset/application-client-ts';
import { Configuration } from '@methodset/model-client-ts';
import { ConfigurationCallback, WidgetViewer } from 'containers/Components/Widgets/WidgetViewer/WidgetViewer';
import { Calculator } from '@methodset/calculator-ts';
import { WidgetUtils } from 'utils/WidgetUtils';
import { ViewItem } from 'containers/Console/Models/ModelItem/ModelApplications/ApplicationItem/ModelApplet/AppletEditor/ItemLayout/ItemLayout';
import classNames from 'classnames';
import './PanelViewer.less';

export type PanelViewerProps = {
    applet: Applet,
    calculator: Calculator,
    panel: AppletPanel,
    configuration: Configuration,
    uneditable?: boolean,
    onConfiguration?: ConfigurationCallback
}

export const PanelViewer = (props: PanelViewerProps): ReactElement => {

    const handleConfiguration = (configuration: Configuration): void => {
        if (props.onConfiguration) {
            props.onConfiguration(configuration);
        }
    }

    const buildItems = (): ViewItem[] => {
        const widgets = findRootWidgets();
        const viewItems: ViewItem[] = [];
        widgets.forEach(widget => {
            // Check if the hide condition is satisfied.
            if (WidgetUtils.isHidden(props.calculator, widget)) {
                return;
            }
            const viewItem = {
                id: widget.id,
                row: widget.row,
                col: widget.col,
                span: widget.span,
                element: (
                    <WidgetViewer
                        key={widget.id}
                        applet={props.applet}
                        panel={props.panel}
                        widget={widget}
                        calculator={props.calculator}
                        configuration={props.configuration}
                        showHeader={false}
                        onUpdate={handleConfiguration}
                    />
                )
            }
            viewItems.push(viewItem);
        });
        return viewItems;
    }

    const findRootWidgets = (): Widget[] => {
        const childIds = new Set<string>();
        const widgets = props.panel.widgets;
        for (const widget of widgets) {
            const type = widget.configuration.type;
            // Find child widgets of row and columns.
            if (type === WidgetType.ROW || type === WidgetType.COLUMN) {
                const configuration = widget.configuration as any;
                const widgetIds = configuration.widgetIds;
                for (const widgetId of widgetIds) {
                    childIds.add(widgetId);
                }
            }
        }
        const rootWidgets = [];
        for (const widget of widgets) {
            if (!childIds.has(widget.id)) {
                rootWidgets.push(widget);
            }
        }
        return rootWidgets;
    }

    const items = buildItems();

    return (
        <div className="x-panelviewer">
            <div className={classNames({ "x-panelviewer-uneditable": !!props.uneditable })}>
                {items.map(item => item.element)}
            </div>
        </div >
    )

}
