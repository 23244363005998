import { FC, ReactElement, useEffect, useState } from 'react';
import { Label } from 'components/Label/Label';
import { PackHeader } from '@methodset/library-client-ts';
import { QueryHeader } from '@methodset/endpoint-client-ts';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { StatusType } from 'constants/StatusType';
import { RestUtils } from 'utils/RestUtils';
import axios from 'axios';
import endpointService from 'services/EndpointService';
import './QueryAbout.less';

export type QueryAboutProps = {
    packHeader: PackHeader,
    defaultView: ReactElement
}

export const QueryAbout: FC<QueryAboutProps> = (props: QueryAboutProps): ReactElement => {

    const [status, setStatus] = useState<StatusType>(StatusType.INIT);
    const [header, setHeader] = useState<QueryHeader>();

    useEffect(() => {
        loadData();
    }, []);

    const handleRetryLoad = (): void => {
        loadData();
    }

    const readQueryHeaderRequest = (): Promise<any> => {
        //const details = props.packHeader.details as QueryDetails;
        const header = props.packHeader;
        const request = {
            queryId: header.rootId,
            version: header.version
        };
        return endpointService.readQueryHeader(request,
            (response: any) => readQueryHeaderResponse(response),
            undefined, true
        );
    }

    const readQueryHeaderResponse = (response: any): void => {
        const header = response.data.header;
        setHeader(header);
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        return (
            <LoadSpinner
                className="x-queryabout-loading"
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load query information."
                onRetry={handleRetryLoad}
            />
        );
    }

    const loadData = (): void => {
        const requests = [];
        requests.push(readQueryHeaderRequest());
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
        
    }

    if (status === StatusType.LOADING) {
        return buildLoadingView(true);
    } else if (status === StatusType.FAILED) {
        return buildLoadingView(false);
    } else if (status === StatusType.READY) {
        return (
            <>
                {props.defaultView}
                <Label label="Provider" alignment="top">{header!.provider}</Label>
            </>
        )
    } else {
        return <></>
    }

}
