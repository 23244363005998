import React, { PureComponent, ReactElement } from 'react';
import { Button, FormInstance, Input, Modal } from 'antd';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { RestUtils } from 'utils/RestUtils';
import { EntityContext } from 'context/EntityContext';
import { Group } from '@methodset/entity-client-ts';
import entityService from 'services/EntityService';
import authService from 'services/AuthService';
import update from 'immutability-helper';
import './GroupEditor.less';

export type CancelCallback = () => void;
export type DoneCallback = (group: Group) => void;

export type GroupEditorProps = {
    group?: Group,
    onCancel: CancelCallback,
    onDone: DoneCallback
}

export type GroupEditorState = {
    group: Group,
    error: Error | undefined,
    isSaving: boolean
}

export class GroupEditor extends PureComponent<GroupEditorProps, GroupEditorState> {

    static contextType = EntityContext;

    private formRef = React.createRef<FormInstance>();

    constructor(props: GroupEditorProps) {
        super(props);
        this.state = {
            group: this.props.group ? this.props.group : {} as Group,
            error: undefined,
            isSaving: false
        };
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    }

    private handleSaveClick(): void {
        if (!this.state.group.id) {
            this.createGroupRequest();
        } else {
            this.updateGroupRequest();
        }
    }

    private handleCancelClick(): void {
        this.props.onCancel();
    }

    private handleNameChange(e: any): void {
        const name = e.target.value;
        const group = update(this.state.group, {
            name: { $set: name }
        })
        this.setState({ group: group });
    }

    private handleDescriptionChange(e: any): void {
        const description = e.target.value;
        const group = update(this.state.group, {
            description: { $set: description }
        })
        this.setState({ group: group });
    }

    private createGroupRequest(): Promise<any> {
        this.setState({
            error: undefined,
            isSaving: true
        });
        const request = {
            name: this.state.group.name,
            description: this.state.group.description
        }
        return entityService.createGroup(request,
            (response: any) => this.createGroupResponse(response),
            (response: any) => this.createGroupException(response),
            true
        );
    }

    private createGroupResponse(response: any): void {
        this.setState({
            error: undefined,
            isSaving: false
        });
        // When a group is created, the user will be added
        // to it. The user identity needs to be refreshed
        // to include the new Cognito group.
        authService.refreshUser().then((user) => {
            this.context.saveUser(user);
        }).catch((err) => {
            this.context.clearUser();
        });
        const group = response.data.group;
        //this.context.addGroup(group);
        this.props.onDone(group);
    }

    private createGroupException(error: any): void {
        this.setState({
            error: RestUtils.getError(error),
            isSaving: false
        });
    }

    private updateGroupRequest(): Promise<any> {
        this.setState({
            error: undefined,
            isSaving: true
        });
        const request = {
            groupId: this.state.group.id,
            name: this.state.group.name,
            description: this.state.group.description,
        }
        return entityService.updateGroup(request,
            (response: any) => this.updateGroupResponse(response),
            (error: any) => this.updateGroupException(error),
            true
        );
    }

    private updateGroupResponse(response: any): void {
        this.setState({
            error: undefined,
            isSaving: false
        });
        const group = response.data.group;
        //this.context.updateGroup(group);
        this.props.onDone(group);
    }

    private updateGroupException(error: any): void {
        this.setState({
            error: RestUtils.getError(error),
            isSaving: false
        });
    }

    public render(): ReactElement {
        return (
            <Modal
                centered
                title={`${!this.props.group ? "Create" : "Update"} Group`}
                width={Globals.DIALOG_WIDTH}
                onCancel={this.handleCancelClick}
                visible={true}
                footer={(
                    <>
                        <Button onClick={this.handleCancelClick}>Cancel</Button>
                        <Button type="primary" loading={this.state.isSaving} onClick={this.handleSaveClick}>
                            {!this.props.group ? "Create" : "Update"}
                        </Button>
                    </>
                )}
            >
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="Name"
                    name="name"
                    rules={[{
                        required: true,
                        message: 'Please enter group name.'
                    }]}
                >
                    <Input
                        id="name"
                        placeholder="Group name."
                        value={this.state.group.name}
                        onChange={this.handleNameChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="Description"
                    name="description"
                >
                    <Input.TextArea
                        id="description"
                        rows={3}
                        placeholder="Description."
                        value={this.state.group.description}
                        onChange={this.handleDescriptionChange}
                    />
                </FormItem>
                {this.state.error &&
                    <div className="x-groupeditor-error">{this.state.error.message}</div>
                }
            </Modal>
        );
    }

}
