import { ReactElement, Ref, useRef, useState } from 'react';
import { AlertDetails, ApplicationHeader, Installation } from '@methodset/application-client-ts';
import { PackHeader } from '@methodset/library-client-ts';
import { Alert, Application, ApplicationType, Inputs } from '@methodset/application-client-ts';
import { ApplicationInstaller } from '../ApplicationInstaller';
import { FormInstance } from 'antd';
import { AlertInputs } from 'containers/Console/Notifications/Alerts/AlertSetup/AlertInputs/AlertInputs';
import { Calculator } from '@methodset/calculator-ts';
import { RestUtils } from 'utils/RestUtils';
import { AccessType } from '@methodset/commons-core-ts';
import applicationService from 'services/ApplicationService';
import './AlertInstaller.less';

export type InstallCallback = (installation: Installation) => void;
export type CloseCallback = () => void;

export type AlertInstallerProps = {
    moduleId?: string,
    accessType?: AccessType,
    onInstall: InstallCallback,
    onClose: CloseCallback
}

export const AlertInstaller = (props: AlertInstallerProps): ReactElement => {

    const DefaultInputs: Inputs = {
        configuration: {},
        conditions: []
    }

    const formRef: Ref<FormInstance> = useRef(null);
    const installation = useRef<Installation>();

    const [inputs, setInputs] = useState<Inputs>(DefaultInputs);
    const [alert, setAlert] = useState<Alert>();
    const [calculator, setCalculator] = useState<Calculator>();

    const handleInputsChange = (inputs: Inputs): void => {
        setInputs(inputs);
    }

    const handleLoad = (application: Application, calculator: Calculator): void => {
        setAlert(application as Alert);
        setCalculator(calculator);
    }

    const handleInstall = (applicationHeader: ApplicationHeader, packHeader: PackHeader): Promise<any> => {
        return installAlertRequest(applicationHeader, packHeader);
    }

    const handleComplete = (applicationHeader: ApplicationHeader, packHeader: PackHeader): void => {
        props.onInstall(installation.current!);
    }

    const installAlertRequest = (applicationHeader: ApplicationHeader, packHeader: PackHeader): Promise<any> => {
        const alertState = {
            configuration: inputs.configuration,
            conditions: inputs.conditions,
            contactIds: []
        }
        //const details = packHeader.details as AlertDetails;
        // const applicationRef = {
        //     modelId: details.modelId,
        //     version: details.version,
        //     applicationId: details.applicationId
        // }
        // const packInfo = {
        //     packId: packHeader.id,
        //     version: packHeader.version,
        //     moduleId: packHeader.moduleId,
        //     name: packHeader.name,
        //     description: packHeader.description,
        //     publisher: packHeader.publisher,
        //     accessType: packHeader.accessType,
        //     admissions: packHeader.admissions,
        //     schedules: packHeader.schedules
        // }
        const request = {
            applicationId: applicationHeader.id,
            moduleId: applicationHeader.moduleId,
            name: applicationHeader.name,
            description: applicationHeader.description,
            //packInfo: packInfo,
            admissions: packHeader ? packHeader.admissions : [],
            schedules: packHeader ? packHeader.schedules : [],
            alertState: alertState
        };
        return applicationService.installAlert(request,
            (response: any) => installAlertResponse(response),
            (response: any) => installAlertException(response),
            true
        );
    }

    const installAlertResponse = (response: any): void => {
        installation.current = response.data.installation;
    }

    const installAlertException = (response: Response): void => {
        throw RestUtils.getError(response);
    }

    const formBody = (
        <AlertInputs
            formRef={formRef}
            inputs={inputs}
            alert={alert!}
            calculator={calculator!}
            onChange={handleInputsChange}
        />
    )

    const configureView = {
        formRef: formRef,
        formBody: formBody
    }

    // Only display alerts for component, otherwise show all.
    //const filter = props.moduleId ? (header: ApplicationHeader) => header.moduleId === props.moduleId : undefined;

    return (
        <ApplicationInstaller
            applicationType={ApplicationType.ALERT}
            configureView={configureView}
            //filter={filter}
            moduleId={props.moduleId}
            accessType={props.accessType}
            onLoad={handleLoad}
            onInstall={handleInstall}
            onComplete={handleComplete}
            onClose={props.onClose}
        />
    )

}
