import { ReactElement } from 'react';
import { Spacer } from 'components/Spacer/Spacer';
import { CheckSquareFilled } from '@ant-design/icons';
import classNames from 'classnames';
import './Bullets.less';

export type BulletType = "ordered" | "unordered" | "icon";
export type BulletColor = "blue" | "green" | "purple" | "black" | "gray";
export type BulletStyle = "none" | "circle" | "disc" | "square" | "number" | "upper-alpha" | "lower-alpha" | "upper-greek" | "lower-greek" | "upper-latin" | "lower-latin" | "upper-roman" | "lower-roman";

export type BulletsProps = {
    className?: string,
    type?: BulletType,
    items: (string | ReactElement)[],
    icon?: ReactElement,
    color?: BulletColor,
    style?: BulletStyle
} & typeof defaultProps;

const defaultProps = {
    type: "unordered",
    icon: <CheckSquareFilled />,
    color: "gray"
}

export const Bullets = (props: BulletsProps): ReactElement => {

    const orderedList = () => {
        const type = props.style ? props.style : "number";
        return (
            <ol style={{ listStyleType: type, marginBottom: 0 }}>
                {props.items.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ol>
        )
    }

    const unorderedList = () => {
        const type = props.style ? props.style : "disc";
        return (
            <ul style={{ listStyleType: type, marginBottom: 0 }}>
                {props.items.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
        )
    }

    const iconList = () => {
        return (
            <div>
                {props.items.map((item, index) => (
                    <Spacer key={index} className={classNames("x-bullets-icon", `x-bullets-${props.color}`)}>
                        {props.icon}
                        <span>{item}</span>
                    </Spacer>
                ))}

            </div>
        )
    }

    return (
        <Spacer className={classNames("x-bullets", props.className)}>
            {props.type === "ordered" && orderedList()}
            {props.type === "unordered" && unorderedList()}
            {props.type === "icon" && iconList()}
        </Spacer>
    )
}

Bullets.defaultProps = defaultProps;
