import { ReactElement } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { MenuItem, Applet } from '@methodset/application-client-ts';
import { Calculator } from '@methodset/calculator-ts';
import { WidgetUtils } from 'utils/WidgetUtils';
import { Spacer } from 'components/Spacer/Spacer';
import { MenuOutlined } from '@ant-design/icons';
import './AppletTitle.less';

export type SelectCallback = (menuItem: MenuItem) => void;

export type AppletTitleProps = {
    applet: Applet,
    calculator: Calculator,
    onSelect: SelectCallback
}

export const AppletTitle = (props: AppletTitleProps): ReactElement => {

    const view = (): ReactElement => {
        let title = WidgetUtils.replaceCellRefs(props.calculator, props.applet.title);
        let header;
        if (props.applet.menu && props.applet.menu.length > 0) {
            const menu = (
                <Menu>
                    {props.applet.menu.map((item: MenuItem) => (
                        <Menu.Item onClick={() => props.onSelect(item)}>
                            {item.label}
                        </Menu.Item>
                    ))}
                </Menu>
            )
            header = (
                <Spacer>
                    <Dropdown
                        overlay={menu}
                        trigger={['click']}
                    >
                        <Button className="x-applettitle" type="link">
                            <MenuOutlined />
                        </Button>
                    </Dropdown>
                    <span>
                        {title}
                    </span>
                </Spacer>
            )
        } else {
            header = <span>{title}</span>
        }
        return header;
    }

    return (
        view()
    )

}
