import { ReactElement } from 'react';
import { Variable, VariableList } from '@methodset/calculator-ts';
import { Select } from 'antd';
import classNames from 'classnames';
import update from 'immutability-helper';
import './VariableRefEditor.less';

export type VariableData = {
    variableId?: string
}
export type BlurCallback = () => void;
export type ChangeCallback = (value: VariableData, isCleared: boolean) => void;

export type VariableRefEditorProps = {
    className?: string,
    value: VariableData,
    variables?: VariableList,
    allowClear: boolean,
    internalOnly: boolean,
    onCommit: BlurCallback,
    onChange: ChangeCallback
};

export const VariableRefEditor = (props: VariableRefEditorProps): ReactElement => {

    const handleVariableChange = (variableId: string | undefined): void => {
        const value = update(props.value, {
            variableId: { $set: variableId }
        });
        props.onChange(value, false);
    }

    const handleClear = (): void => {
        const value = update(props.value, {
            variableId: { $set: undefined }
        });
        props.onChange(value, true);
    }

    return (
        <div className={classNames("x-variablerefeditor", props.className)}>
            <Select
                className="x-variablerefeditor-variable"
                placeholder="Select a variable."
                value={props.value.variableId}
                allowClear={props.allowClear}
                onBlur={() => props.onCommit()}
                onClear={() => handleClear()}
                onChange={(variableId) => handleVariableChange(variableId)}
            >
                {props.variables?.filter(variable => !props.internalOnly || !variable.isInternal).map((variable: Variable) => (
                    <Select.Option key={variable.id} value={variable.id}>
                        {variable.name}
                    </Select.Option>
                ))}
            </Select>
        </div>
    )
}
