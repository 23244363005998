import { ResponsiveBox, SizeProps } from 'components/ResponsiveBox/ResponsiveBox';
import { ReactElement } from 'react';
import './Display.less';

export type DisplayMode = "show" | "hide";

export type DisplayProps = {
    children: ReactElement,
    className?: string,
    xs?: DisplayMode,
    sm?: DisplayMode,
    md?: DisplayMode,
    lg?: DisplayMode,
    xl?: DisplayMode,
    xxl?: DisplayMode
}

export const Display = (props: DisplayProps): ReactElement => {

    const sizeProps = (mode: DisplayMode | undefined): SizeProps | undefined => {
        if (!mode) {
            return undefined;
        }
        return mode === "hide" ? {className: "x-display-hide"} : {className: "x-display-show"};
    }

    return (
        <ResponsiveBox
            className={props.className}
            xs={sizeProps(props.xs)}
            sm={sizeProps(props.sm)}
            md={sizeProps(props.md)}
            lg={sizeProps(props.lg)}
            xl={sizeProps(props.xl)}
            xxl={sizeProps(props.xxl)}
        >
            {props.children}
        </ResponsiveBox>
    )

};
