import { ReactElement } from 'react';
import { Spacer } from 'components/Spacer/Spacer';
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import './Alert.less';

export type AlertType = "info" | "ok" | "warn" | "error";

export type AlertProps = {
    className?: string,
    children: any,
    type: AlertType,
    noIcon: boolean
} & typeof defaultProps;

const defaultProps = {
    type: "error",
    noIcon: false
}

export const Alert = (props: AlertProps): ReactElement => {

    return (
        <Spacer className={classNames("x-alert", `x-alert-${props.type}`, props.className)} alignment="top" size="sm">
            {!props.noIcon && props.type === "info" && <InfoCircleOutlined />}
            {!props.noIcon && props.type === "ok" && <CheckCircleOutlined />}
            {!props.noIcon && props.type === "warn" && <WarningOutlined />}
            {!props.noIcon && props.type === "error" && <CloseCircleOutlined />}
            <div>
                {props.children}
            </div>
        </Spacer>
    )
}

Alert.defaultProps = defaultProps;
