import { FC, ReactElement, useEffect, useState } from 'react';
import { Label } from 'components/Label/Label';
import { CoreUtils } from 'utils/CoreUtils';
import { PackHeader } from '@methodset/library-client-ts';
import { WorkflowHeader } from '@methodset/workflow-client-ts';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { StatusType } from 'constants/StatusType';
import { RestUtils } from 'utils/RestUtils';
import axios from 'axios';
import workflowService from 'services/WorkflowService';
import './WorkflowAbout.less';

export type WorkflowAboutProps = {
    packHeader: PackHeader,
    defaultView: ReactElement
}

export const WorkflowAbout: FC<WorkflowAboutProps> = (props: WorkflowAboutProps): ReactElement => {

    const [status, setStatus] = useState<StatusType>(StatusType.INIT);
    const [header, setHeader] = useState<WorkflowHeader>();

    useEffect(() => {
        loadData();
    }, []);

    const handleRetryLoad = (): void => {
        loadData();
    }

    const readWorkflowHeaderRequest = (): Promise<any> => {
        //const details = props.packHeader.details as WorkflowDetails;
        const header = props.packHeader;
        const request = {
            workflowId: header.rootId,
            version: header.version
        };
        return workflowService.readWorkflowHeader(request,
            (response: any) => readWorkflowHeaderResponse(response),
            undefined, true
        );
    }

    const readWorkflowHeaderResponse = (response: any): void => {
        const header = response.data.header;
        setHeader(header);
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        return (
            <LoadSpinner
                className="x-workflowabout-loading"
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load workflow information."
                onRetry={handleRetryLoad}
            />
        );
    }

    const loadData = (): void => {
        const requests = [];
        requests.push(readWorkflowHeaderRequest());
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
    }

    if (status === StatusType.LOADING) {
        return buildLoadingView(true);
    } else if (status === StatusType.FAILED) {
        return  buildLoadingView(false);
    } else if (status === StatusType.READY) {
        return (
            <>
                {props.defaultView}
                <Label label="Is Enabled" alignment="top">{CoreUtils.toBoolean(header!.isEnabled)}</Label>
                <Label label="Single Instance" alignment="top">{CoreUtils.toBoolean(!!header!.delegate)}</Label>
            </>
        )
    } else {
        return <></>;
    }

}
