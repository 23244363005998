import { ReactElement } from 'react';
import { AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined } from '@ant-design/icons';
import { JustificationType } from '@methodset/application-client-ts';
import { Button, Dropdown, Menu } from 'antd';
import { CoreUtils } from 'utils/CoreUtils';
import './JustificationStyle.less';

export type ChangeCallback = (justification: JustificationType) => void;

export type JustificationStyleProps = {
    justification?: JustificationType,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    justification: JustificationType.LEFT
}

export const JustificationStyle = (props: JustificationStyleProps): ReactElement => {

    const handleSelect = (info: any) => {
        props.onChange(info.key);
    }

    const menu = (
        <Menu onClick={handleSelect}>
            {Object.entries(JustificationType).map(([key, value]) =>
                <Menu.Item key={key}>
                    {CoreUtils.toCapital(value)}
                </Menu.Item>
            )}
        </Menu>
    );

    const iconView = (): ReactElement | undefined => {
        switch (props.justification) {
            case JustificationType.LEFT:
                return <AlignLeftOutlined />
            case JustificationType.CENTER:
                return <AlignCenterOutlined />
            case JustificationType.RIGHT:
                return <AlignRightOutlined />
            default:
                return undefined;
        }
    }

    return (
        <div className="x-justificationstyle">
            <Dropdown
                overlay={menu}
                trigger={['click']}
            >
                <Button>
                    {iconView()}
                </Button>
            </Dropdown>
        </div>

    )
}

JustificationStyle.defaultProps = defaultProps;
