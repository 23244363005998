import { ReactElement } from 'react';
import classNames from 'classnames';
import './Fill.less';

export type FillProps = {
    className?: string,
    children: any,
}

export const Fill = (props: FillProps): ReactElement => {
    return (
        <div className={classNames("x-fill", props.className)}>
            {props.children}
        </div>
    )
}
