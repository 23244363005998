import { ChangeEvent, FC, ReactElement, Ref, useRef, useState } from 'react';
import { Form, FormInstance, Input, Modal, Select } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { AppletPanel } from '@methodset/application-client-ts';
import update from 'immutability-helper';
import './PanelProperties.less';

export type PanelPropertiesProps = {
    panel: AppletPanel,
    onChange: (panel: AppletPanel) => void,
    onCancel: () => void
}

export const PanelProperties: FC<PanelPropertiesProps> = (props: PanelPropertiesProps): ReactElement => {

    // The form reference.
    const formRef: Ref<FormInstance> = useRef(null);
    // The applet being edited.
    const [panel, setPanel] = useState<AppletPanel>(props.panel);

    const handleOk = (): void => {
        formRef.current?.submit();
    }

    const handleCancel = (): void => {
        props.onCancel();
    }

    const handleFormFinish = (): void => {
        props.onChange(panel);
    }

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const name = e.target.value;
        const updated = update(panel, {
            name: { $set: name }
        });
        setPanel(updated);
    }

    const handleSpanChange = (span: number): void => {
        const updated = update(panel, {
            span: { $set: span }
        });
        setPanel(updated);
    }

    return (
        <Modal
            className="x-panelproperties"
            centered
            title="Panel Properties"
            width={Globals.DIALOG_WIDTH}
            onOk={handleOk}
            onCancel={handleCancel}
            visible={true}
        >
            <Form ref={formRef} onFinish={handleFormFinish}>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={formRef}
                    label="Name"
                    name="name"
                    info="The name of the panel."
                    rules={[{
                        required: true,
                        message: 'Please enter a name.'
                    }]}
                >
                    <Input
                        placeholder="Enter a name."
                        value={panel.name}
                        onChange={handleNameChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={formRef}
                    label="Width"
                    name="span"
                    info={`The default number of columns the panel should span (1-${Globals.LAYOUT_COLUMNS}).`}
                    rules={[{
                        required: true,
                        message: "Please select a default column width."
                    }]}
                >
                    <Select
                        value={panel.span}
                        onChange={handleSpanChange}
                    >
                        {Array.from(Array(Globals.LAYOUT_COLUMNS).keys()).map(index => (
                            <Select.Option key={index} value={index + 1}>{index + 1}</Select.Option>
                        ))}
                    </Select>
                </FormItem>
            </Form>
        </Modal>
    )

}
