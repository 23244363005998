import { PureComponent, ReactElement } from 'react';
import { Button, Empty } from 'antd';
import { Coordinate, ElementChangeType, Parameter, ParametersChangeEvent, Sheet, Variable, VariableChangeEvent } from '@methodset/calculator-ts';
import { VariableEditor, VariableData } from './VariableEditor/VariableEditor';
import { ModelContext } from 'context/ModelContext';
import { ParameterItem } from './ParameterItem/ParameterItem';
//import classNames from 'classnames';
import './Parameters.less';
import { NoData } from 'components/NoData/NoData';

export type ParametersProps = {
    //className?: string,
    //formulaEditor?: FormulaEditor
}

export type ParametersState = {
    isEditing: boolean,
    isFormatting: boolean
}

export class Parameters extends PureComponent<ParametersProps, ParametersState> {

    static contextType = ModelContext;

    constructor(props: ParametersProps) {
        super(props);
        this.state = {
            isEditing: false,
            isFormatting: false
        };
        this.handleVariableChange = this.handleVariableChange.bind(this);
        this.handleParameterCreate = this.handleParameterCreate.bind(this);
        this.handleParameterCancel = this.handleParameterCancel.bind(this);
        this.handleParameterAdd = this.handleParameterAdd.bind(this);
        this.handleParameterRemove = this.handleParameterRemove.bind(this);
        this.handleParametersChange = this.handleParametersChange.bind(this);
    }

    private handleVariableChange(event: VariableChangeEvent): void {
        this.forceUpdate();
    }

    private handleParameterCreate(): void {
        this.setState({ isEditing: true });
    }

    private handleParameterCancel(): void {
        this.setState({ isEditing: false });
    }

    private handleParameterAdd(data: VariableData): void {
        // Addition of parameter will trigger a parameter list change event.
        const calculator = this.context.calculator;
        calculator.parameters.add(data.id, data.name, data.description, data.type, data.isRequired, data.isInternal, data.options);
    }

    private handleParameterRemove(parameter: Parameter): void {
        // Removal will trigger a parameter list event.
        const calculator = this.context.calculator;
        calculator.parameters.remove(parameter.variable!.id);
    }

    private handleParametersChange(event: ParametersChangeEvent) {
        // Called when the parameters list changes.
        const activeCell = this.context.active.cell;
        try {
            if (event.type === ElementChangeType.ADD) {
                const row = event.parameter.row;
                this.setState({ isEditing: false });
                this.focusParameter(row);
                event.parameter.variable!.addCallback("Change", this.handleVariableChange);
            } else if (event.type === ElementChangeType.REMOVE) {
                const calculator = this.context.calculator;
                if (calculator.parameters.length > 0 && activeCell && activeCell.sheet.id === Sheet.PARAMETERS_SHEET) {
                    let row = event.parameter.row;
                    let coord = Coordinate.fromCellId(activeCell.id);
                    if (coord.row > row) {
                        row = coord.row - 1;
                    } else {
                        row = coord.row;
                    }
                    this.forceUpdate();
                    this.focusParameter(row);
                    event.parameter.variable!.removeCallback("Change", this.handleVariableChange);
                } else {
                    // Delete, but non-parameter has current focus, so do not re-focus.
                    this.forceUpdate();
                }
            } else if (event.type === ElementChangeType.MOVE) {
                this.forceUpdate();
            }
        } catch (e) {
            return;
        }
    }

    private focusParameter(row: number): void {
        // Put the event on the queue so that it executes after
        // any DOM modifications.
        setTimeout(() => {
            const cellId = `A${row + 1}`;
            const el = document.getElementById(`parameter-${cellId}`);
            if (el) {
                el.focus();
            }
        }, 0);
    }

    public componentDidMount() {
        const parameters = this.context.calculator.parameters;
        parameters.addCallback("Change", this.handleParametersChange);
        parameters.forEach((parameter: Parameter) => {
            parameter.variable!.addCallback("Change", this.handleVariableChange);
        });
    }

    public componentWillUnmount() {
        const parameters = this.context.calculator.parameters;
        parameters.removeCallback("Change", this.handleParametersChange);
        parameters.forEach((parameter: Parameter) => {
            parameter.variable!.removeCallback("Change", this.handleVariableChange);
        });
    }

    public render(): ReactElement {
        let parameters = this.context.calculator.parameters;
        return (
            <div id="parameters" className="x-parameters">
                {parameters.length === 0 &&
                    <NoData text="No parameters." />
                }
                <div className="x-parameters-items">
                    {parameters.length > 0 && parameters.map((parameter: Parameter, index: number) =>
                        <ParameterItem
                            key={`${parameter.variable!.id}-${parameter.variable!.name}`}
                            index={index}
                            parameter={parameter}
                            onDelete={this.handleParameterRemove}
                        />
                    )}
                </div>
                <div className="x-parameters-add">
                    <Button onClick={this.handleParameterCreate}>
                        Add
                    </Button>
                </div>
                {this.state.isEditing &&
                    <VariableEditor
                        data={{} as VariableData}
                        title="Parameter Editor"
                        onChange={this.handleParameterAdd}
                        onCancel={this.handleParameterCancel}
                    />
                }
            </div>
        )
    }

}
