import { Chapter } from 'components/Chapter/Chapter';
import { ReactElement } from 'react';
import { Italic } from 'components/Italic/Italic';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Mono } from 'components/Mono/Mono';
import { DocMap } from '@methodset/calculator-ts';
import { DocFrame } from '../DocFrame/DocFrame';
import docs from "./function_docs.json";
import './FunctionsDoc.less';

export type FunctionsDocProps = RouteComponentProps;

export const FunctionsDoc = (props: FunctionsDocProps): ReactElement => {

    const categories = Object.entries(docs);

    const toMap = (categories: [string, DocMap][]): DocMap => {
        const map: DocMap = {};
        categories.forEach(([category, docs]) => {
            Object.values(docs).forEach(doc => {
                map[doc.fn] = doc;
            });
        });
        return map;
    }

    const map = toMap(categories);

    return (
        <DocFrame title="Functions" {...props}>
            <Chapter>
                <Chapter.Paragraph>
                    Models perform calculations using formulas, which are comprised of functions. Models support many
                    of the functions that are available in common spreadsheets, in addition to a few others to facilitate
                    working with datasets and dynamic data. Functions are case-insensitive, so they may be entered in upper
                    or lower case.
                </Chapter.Paragraph>
                <Chapter.Paragraph>
                    Models often use ranges in calculations, both <Italic>close-ended</Italic> and <Italic>open-ended</Italic>.
                    A close-ended range has a constant size, for example <Mono>A1:A100</Mono>. With open-ended ranges, the
                    size is not explicity known, but is determined by the available data within a sheet. For example,
                    to reference a range that includes all values in column <Mono>A</Mono>, the
                    range <Mono>A1:A?</Mono> can be used. This range will represent all cells starting at <Mono>A1</Mono> and
                    ending at the last row of column <Mono>A</Mono> that contains data.
                    Open-ended ranges with unknown row dimensions are also supported (e.g., <Mono>A1:?1</Mono>). When a range contains
                    a single <Mono>?</Mono>, it can dropped to simplify the syntax, for example <Mono>A1:A</Mono> or <Mono>A1:1</Mono>. 
                    Two-dimensional open-ended ranges are also supported, in which case <Mono>?</Mono> should
                    be used to denote both dimensions. For example, range <Mono>A1:??</Mono> would cover all cells in a sheet that contain data.
                    Open-ended ranges simplify working with datasets that may change in size when data is recalculated.
                </Chapter.Paragraph>
                <Chapter.Paragraph>
                    {categories.map(([category, docs]) => (
                        <div className="x-functionsdoc-category" key={category} id={category}>
                            <div>{category}</div>
                            {Object.values(docs).map(doc => (
                                <div key={doc.fn} id={doc.fn}>
                                    <Link
                                        className="x-functionsdoc-fn"
                                        to={{ pathname: RouteBuilder.functionDoc(doc.fn), state: { doc: doc, map: map } }}
                                    >
                                        <Mono>{doc.fn}</Mono>
                                    </Link>
                                    &nbsp;-&nbsp;
                                    <span className="x-functionsdoc-digest">{doc.digest}</span>
                                </div>
                            ))}
                        </div>
                    ))}
                </Chapter.Paragraph>
            </Chapter>
        </DocFrame>
    );

}
