import { ReactElement, useRef, useState } from 'react';
import { Table, Tag } from 'antd';
import { Data, Dataset, IoType, Records } from '@methodset/endpoint-client-ts';
import { ColumnsType } from 'antd/lib/table';
import { CoreUtils } from 'utils/CoreUtils';
import { Label } from 'components/Label/Label';
import { Link } from 'react-router-dom';
import { Spacer } from 'components/Spacer/Spacer';
import { Globals } from 'constants/Globals';
import classNames from 'classnames';
import './DataViewer.less';
import { NoData } from 'components/NoData/NoData';
import { Align } from 'components/Align/Align';
import { Justify } from 'components/Justify/Justify';

const COLOR_MAP = CoreUtils.toColormap(CoreUtils.enumToKeys(IoType));

export type CloseCallback = () => void;

export type DataViewerProps = {
    className?: string,
    data: Data,
    showName?: boolean,
    showNull?: boolean,
    showRaw?: boolean
    enableRaw: boolean,
} & typeof defaultProps;

const defaultProps = {
    showName: false,
    showNull: true,
    showRaw: false,
    enableRaw: false
}

export const DataViewer = (props: DataViewerProps): ReactElement => {

    const [showRaw, setShowRaw] = useState<boolean>(props.showRaw);

    const handleToggleRaw = (): void => {
        setShowRaw(!showRaw);
    }

    const buildRecords = (): ReactElement => {
        const records = props.data.value as Records;
        try {
            return buildTable(buildRecordsColumns(records), buildRecordsData(records));
        } catch (e) {
            return buildRaw();
        }
    }

    const buildRecordsColumns = (records: Records): ColumnsType<any> => {
        const columns = [];
        const keys = Object.keys(records.keys);
        let c = 0;
        for (const key of keys) {
            const column = {
                key: c,
                title: key,
                dataIndex: c,
                ellipsis: true,
                width: 200
            };
            columns.push(column);
            c++;
        }
        return columns;
    }

    const buildRecordsData = (records: Records): any[] => {
        const data: any[] = [];
        const entries = Object.entries(records.keys);
        const rows = records.rows;
        let numRows = rows.length;
        for (let r = 0; r < numRows; r++) {
            const row: any = { key: r };
            let c = 0;
            for (const [key, index] of entries) {
                let value = rows[r][index];
                if (isBoolean(value)) {
                    value = value ? "true" : "false";
                } else if (!isNative(value)) {
                    value = JSON.stringify(value);
                }
                row[c] = value === null && props.showNull ? "null" : value;
                c++;
            }
            data.push(row);
        }
        return data;
    }

    const buildDataset = (): ReactElement => {
        const dataset = props.data.value as Dataset;
        try {
            return buildTable(buildDatasetColumns(dataset), buildDatasetData(dataset));
        } catch (e) {
            return buildRaw();
        }
    }

    const buildDatasetColumns = (dataset: Dataset): ColumnsType<any> => {
        const columns = [];
        for (let c = 0; c < dataset.headers.length; c++) {
            const header = dataset.headers[c];
            const key = header.key;
            const column = {
                key: c,
                title: header.name,
                dataIndex: c,
                ellipsis: true,
                width: 200
            };
            columns.push(column);
        }
        return columns;
    }

    const buildDatasetData = (dataset: Dataset): any[] => {
        const rows: any[] = [];
        const matrix = dataset.matrix;
        const headers = dataset.headers;
        let numRows = matrix.length;
        let numCols = headers.length;
        for (let r = 0; r < numRows; r++) {
            const row: any = { key: r };
            for (let c = 0; c < numCols; c++) {
                let value = matrix[r][c];
                if (isBoolean(value)) {
                    value = value ? "true" : "false";
                } else if (!isNative(value)) {
                    value = JSON.stringify(value);
                }
                row[c] = value === null && props.showNull ? "null" : value;
            }
            rows.push(row);
        }
        return rows;
    }

    const buildTable = (columns: ColumnsType<any>, rows: any[]): ReactElement => {
        if (rows.length === 0) {
            return (
                <Align alignment="center">
                    <Justify justification="center">
                        <NoData text="No data." />
                    </Justify>
                </Align>
            );
        } else {
            return (
                <Table
                    bordered
                    pagination={false}
                    scroll={{ x: "max-content" }}
                    size="small"
                    columns={columns}
                    dataSource={rows}
                />
            );
        }
    }

    const buildRaw = (): ReactElement => {
        let content = props.data.value
        if (content) {
            content = JSON.stringify(content, undefined, 4);
        }
        return (
            <div><pre>{content}</pre></div>
        );
    }

    const isNative = (value: any): boolean => {
        return CoreUtils.isEmpty(value) || CoreUtils.isString(value) || CoreUtils.isNumber(value) || CoreUtils.isBoolean(value);
    }

    const isBoolean = (value: any): boolean => {
        return CoreUtils.isBoolean(value);
    }

    const buildExtra = (): ReactElement => {
        const tag = (
            <Tag color={COLOR_MAP[props.data.type]}>
                {props.data.type}
            </Tag>
        )
        if (!props.enableRaw) {
            return tag;
        } else {
            return (
                <Spacer>
                    <Link to="#" onClick={handleToggleRaw}>{showRaw ? "Show Formatted" : "Show Raw"}</Link>
                    {tag}
                </Spacer>
            )
        }
    }

    const buildLabel = (): ReactElement => {
        if (props.showName) {
            return (
                <span>{CoreUtils.toProper(props.data.type)}</span>
            )
        } else {
            return <></>;
        }
    }

    const buildView = (): ReactElement => {
        if (showRaw) {
            return buildRaw();
        } else {
            // TODO: add support for other io types
            if (props.data.type === IoType.RECORDS) {
                return buildRecords();
            } else if (props.data.type === IoType.DATASET) {
                return buildDataset();
            } else {
                return buildRaw();
            }
        }
    }

    return (
        <>
            {(props.showName || props.enableRaw) &&
                <Label className={classNames("x-dataviewer", props.className)} label={buildLabel()} extra={buildExtra()} placement="top" justification="left" colon={false}>
                    <div className="x-dataviewer-body">
                        {buildView()}
                    </div>
                </Label>
            }
            {!props.showName && !props.enableRaw &&
                <div className={classNames("x-dataviewer", props.className)}>
                    <div className="x-dataviewer-body">
                        {buildView()}
                    </div>
                </div>
            }
        </>
    );

}

DataViewer.defaultProps = defaultProps;
