import { PureComponent, ReactElement } from 'react';
import { EditOutlined, DeleteOutlined, CaretDownOutlined, FullscreenExitOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { Dropdown } from 'antd';
import { Menu } from 'antd';
import { ModelContext } from 'context/ModelContext';
import { ElementChangeType, Sheet, SheetChangeEvent } from '@methodset/calculator-ts';
import classNames from 'classnames';
import './SheetTab.less';

export enum DirectionType {
    LEFT,
    RIGHT
}
export type FitCallback = (sheet: Sheet) => void;
export type MoveCallback = (sheet: Sheet, direction: DirectionType) => void;
export type RenameCallback = (sheet: Sheet) => void;
export type DeleteCallback = (sheet: Sheet) => void;

export type SheetTabProps = {
    sheet: Sheet,
    className?: string,
    onFit: FitCallback,
    onMove: MoveCallback,
    onRename: RenameCallback,
    onDelete: DeleteCallback
}

export class SheetTab extends PureComponent<SheetTabProps> {

    static contextType = ModelContext;

    constructor(props: SheetTabProps) {
        super(props);
        // Allows context to be used in first render.
        this.buildMenu = this.buildMenu.bind(this);
        this.handleSheetChange = this.handleSheetChange.bind(this);
    }

    private handleFitClick(): void {
        this.props.onFit(this.props.sheet);
    }

    private handleMoveClick(direction: DirectionType): void {
        this.props.onMove(this.props.sheet, direction);
    }

    private handleRenameClick(): void {
        this.props.onRename(this.props.sheet);
    }

    private handleDeleteClick(): void {
        this.props.onDelete(this.props.sheet);
    }

    private handleSheetChange(event: SheetChangeEvent) {
        if (event.type === ElementChangeType.RENAME) {
            this.forceUpdate();
        }
    }

    private buildMenu() {
        const numTabs = this.context.calculator.sheets.length;
        const index = this.context.calculator.sheets.index(this.props.sheet.id);
        return (
            <Menu className="x-sheettab-menu">
                <Menu.Item key="rename" onClick={() => this.handleRenameClick()}>
                    <Space>
                        <EditOutlined />
                        <span>Rename sheet...</span>
                    </Space>
                </Menu.Item>
                <Menu.Item key="left" onClick={() => this.handleMoveClick(DirectionType.LEFT)} disabled={index === 0}>
                    <Space>
                        <ArrowLeftOutlined />
                        <span>Move left</span>
                    </Space>
                </Menu.Item>
                <Menu.Item key="right" onClick={() => this.handleMoveClick(DirectionType.RIGHT)} disabled={index === numTabs - 1}>
                    <Space>
                        <ArrowRightOutlined />
                        <span>Move right</span>
                    </Space>
                </Menu.Item>
                <Menu.Item key="delete" onClick={() => this.handleDeleteClick()} disabled={numTabs <= 1}>
                    <Space>
                        <DeleteOutlined />
                        <span>Delete sheet</span>
                    </Space>
                </Menu.Item>
                <Menu.Item key="fit" onClick={() => this.handleFitClick()}>
                    <Space>
                        <FullscreenExitOutlined />
                        <span>Fit cells</span>
                    </Space>
                </Menu.Item>
            </Menu>
        );
    }

    public componentDidMount(): void {
        this.props.sheet.addCallback("Change", this.handleSheetChange);
    }

    public componentWillUnmount(): void {
        this.props.sheet.removeCallback("Change", this.handleSheetChange);
    }

    public render(): ReactElement {
        return (
            <span className={classNames('x-sheettab', this.props.className)}>
                <span>{this.props.sheet.name}</span>
                <Dropdown
                    className="x-sheettab-action"
                    overlay={this.buildMenu}
                    trigger={['click']}
                    destroyPopupOnHide={true}
                >
                    <CaretDownOutlined />
                </Dropdown>
            </span>
        );
    }

}
