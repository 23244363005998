import { ReactElement, useEffect, useReducer, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Card } from 'antd';
import { Location } from 'history';
import { Widget, Applet, WidgetType, AppletPanel } from '@methodset/application-client-ts';
import { Globals } from 'constants/Globals';
import { Spacer } from 'components/Spacer/Spacer';
import { ArrowsAltOutlined, DownOutlined, PlusOutlined, ShrinkOutlined } from '@ant-design/icons';
import { WidgetViewer } from 'containers/Components/Widgets/WidgetViewer/WidgetViewer';
import { WidgetEditor } from 'containers/Components/Widgets/WidgetEditor/WidgetEditor';
import { ItemSpec, MenuButton } from 'components/MenuButton/MenuButton';
import { Calculator } from '@methodset/calculator-ts';
import { WidgetUtils } from 'utils/WidgetUtils';
import { WidgetSyncFactory } from 'sync/WidgetSyncFactory';
import { ViewItem } from '../../ModelApplet/AppletEditor/ItemLayout/ItemLayout';
import { ItemMenu } from '../../ModelApplet/AppletEditor/ItemMenu/ItemMenu';
import { NoData } from 'components/NoData/NoData';
import { Configuration, Model } from '@methodset/model-client-ts';
import classNames from 'classnames';
import update from 'immutability-helper';
import './PanelEditor.less';

export type EditCallback = (isEdit: boolean) => void;
export type UpdateCallback = (panel: AppletPanel) => void;
//export type RemoveCallback = (panel: AppletPanel) => void;

export type PanelEditorProps = RouteComponentProps & {
    className?: string,
    model: Model,
    applet: Applet,
    index: number,
    panel: AppletPanel,
    calculator: Calculator,
    onEdit: EditCallback,
    onUpdate: UpdateCallback
}

export const PanelEditor = (props: PanelEditorProps): ReactElement => {

    // Access to history.
    const history = useHistory();
    // Add ability to force update on change in cells.
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    // The widget that is being edited.
    const [widget, setWidget] = useState<Widget | undefined>(undefined);
    // A configuration for testing.
    const [configuration, setConfiguration] = useState<Configuration>({});
    // True if editing the panel, otherwise display preview.
    const [isPreviewing, setIsPreviewing] = useState<boolean>(true);
    // True if editing a widget.
    const [isEditingWidget, setIsEditingWidget] = useState<boolean>(false);

    const checkEdit = (location: Location<any>): false | void => {
        const params = new URLSearchParams(location.search);
        const edit = params.get("edit");
        const isPreviewing = !edit || edit === "false";
        setIsPreviewing(isPreviewing);
    }

    useEffect(() => {
        const unregister = history.listen(checkEdit);
        props.calculator.addCallback("ExecutionComplete", handleExecutionComplete);
        checkEdit(props.location);
        return () => {
            unregister();
            props.calculator.removeCallback("ExecutionComplete", handleExecutionComplete);
        }
    }, []);

    const handleWidgetAdd = (): void => {
        setWidget(undefined);
        setIsPreviewing(false);
        setIsEditingWidget(true);
    }

    const handleWidgetEdit = (widget: Widget): void => {
        setWidget(widget);
        setIsEditingWidget(true);
    }

    const handleWidgetChange = (widget: Widget): void => {
        const widgets = props.panel.widgets;
        const index = widgets.findIndex(w => w.id === widget.id);
        let panel;
        if (index !== -1) {
            panel = update(props.panel, {
                widgets: {
                    [index]: { $set: widget }
                }
            });
        } else {
            panel = update(props.panel, {
                widgets: {
                    $push: [widget]
                }
            });
            const widgetSync = WidgetSyncFactory.createSync(widget.configuration);
            if (widgetSync) {
                const registry = props.calculator.registry;
                registry.register(widget.id, widget, widgetSync.parser, widgetSync.updater);
            }
        }
        setWidget(undefined);
        setIsEditingWidget(false);
        props.onUpdate(panel);
    }

    const handleWidgetCancel = (): void => {
        setWidget(undefined);
        setIsEditingWidget(false);
    }

    const handleWidgetRemove = (widget: Widget): void => {
        let panel = AppletPanel.removeDependency(props.panel, widget.id);
        const widgets = props.panel.widgets;
        const index = widgets.findIndex(w => w.id === widget.id);
        if (index !== -1) {
            panel = update(panel, {
                widgets: {
                    $splice: [[index, 1]]
                }
            });
        }
        const registry = props.calculator.registry;
        registry.unregister(widget.id);
        props.onUpdate(panel);
    }

    const handleConfigurationChange = (configuration: Configuration): void => {
        setConfiguration(configuration);
    }

    const handleExpandToggle = (): void => {
        props.onEdit(isPreviewing);
    }

    const handleExecutionComplete = (): void => {
        forceUpdate();
    }

    const findRootWidgets = (): Widget[] => {
        const childIds = new Set<string>();
        const widgets = props.panel.widgets;
        for (const widget of widgets) {
            const type = widget.configuration.type;
            // Find child widgets of row and columns.
            if (type === WidgetType.ROW || type === WidgetType.COLUMN) {
                const configuration = widget.configuration as any;
                const widgetIds = configuration.widgetIds;
                for (const widgetId of widgetIds) {
                    childIds.add(widgetId);
                }
            }
        }
        const rootWidgets = [];
        for (const widget of widgets) {
            if (!childIds.has(widget.id)) {
                rootWidgets.push(widget);
            }
        }
        return rootWidgets;
    }

    const buildItems = (): ViewItem[] => {
        const calculator = props.calculator;
        const widgets = findRootWidgets();
        const viewItems: ViewItem[] = [];
        widgets.forEach(widget => {
            // Check if the hide condition is satisfied.
            if (isPreviewing && WidgetUtils.isHidden(calculator, widget)) {
                return;
            }
            const menu = (
                <ItemMenu
                    widget={widget}
                    onEdit={handleWidgetEdit}
                    onRemove={handleWidgetRemove}
                />
            )
            const viewItem = {
                id: widget.id,
                row: widget.row,
                col: widget.col,
                span: widget.span,
                element: (
                    <WidgetViewer
                        key={widget.id}
                        applet={props.applet}
                        panel={props.panel}
                        widget={widget}
                        calculator={calculator}
                        configuration={configuration}
                        extra={menu}
                        showHeader={!isPreviewing}
                        onEdit={handleWidgetEdit}
                        onRemove={handleWidgetRemove}
                        onChange={handleWidgetChange}
                        onUpdate={handleConfigurationChange}
                    />
                )
            }
            viewItems.push(viewItem);
        });
        return viewItems;
    }

    const buildWidgetsView = (): ReactElement => {
        const items = buildItems();
        return (
            <>
                {items.map(item => item.element)}
            </>
        )
    }

    const items: ItemSpec[] = [{
        icon: <PlusOutlined />,
        label: "Add widget...",
        onSelect: handleWidgetAdd
    }, {
        icon: !isPreviewing ? <ShrinkOutlined /> : <ArrowsAltOutlined />,
        label: !isPreviewing ? "Preview panel" : "Edit widgets",
        onSelect: handleExpandToggle
    }];
    const extra = (
        <Spacer>
            <MenuButton
                items={items}
                size={Globals.APPLET_MENU_SIZE}
                label="Edit"
                icon={<DownOutlined />}
            />
        </Spacer>
    );
    return (
        <>
            <Card
                className={classNames("x-paneleditor", props.className)}
                size="small"
                title="Alert Display"
                bordered={true}
                extra={extra}
            >
                {props.panel.widgets.length === 0 &&
                    <NoData text="No widgets added." />
                }
                {props.panel.widgets.length > 0 &&
                    buildWidgetsView()
                }
            </Card>
            {isEditingWidget &&
                <WidgetEditor
                    applet={props.applet}
                    panel={props.panel}
                    widget={widget}
                    calculator={props.calculator}
                    onChange={handleWidgetChange}
                    onCancel={handleWidgetCancel}
                />
            }
        </>
    );

}
