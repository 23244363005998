import React, { ReactElement, useEffect, useState } from 'react';
import { Form, FormInstance, Tag } from 'antd';
import { AddressContact, Contact, ContactType, PhoneContact } from '@methodset/entity-client-ts';
import { AddressEditor } from '../AddressEditor/AddressEditor';
import { PhoneEditor } from '../PhoneEditor/PhoneEditor';
import { CoreUtils } from 'utils/CoreUtils';
import { ListEditor } from '../ListEditor/ListEditor';
import './ContactEditor.less';

const COLOR_MAP = CoreUtils.toColormap(CoreUtils.enumToKeys(ContactType));

export type ChangeCallback = (contacts: Contact[]) => void;

export type ContactEditorProps = {
    formRef: React.RefObject<FormInstance>,
    withForm?: boolean,
    contacts?: Contact[],
    id: string,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    contacts: [] as Contact[]
}

export const ContactEditor = (props: ContactEditorProps): ReactElement => {

    const [editContact, setEditContact] = useState<Contact | undefined>();

    useEffect(() => {
        props.contacts.sort((a, b) => a.type < b.type ? -1 : 1);
    }, []);

    const handleAddressChange = (address: AddressContact): void => {
        setEditContact(address);
    }

    const handleContactEdit = (contact: Contact): void => {
        setEditContact(contact);
    }

    const handleContactsSave = (contact: Contact[]): void => {
        setEditContact(undefined);
        props.onChange(contact);
    }

    const handlePhoneChange = (phone: PhoneContact): void => {
        setEditContact(phone);
    }

    const renderItem = (contact: Contact): ReactElement => {
        return (
            <div className="x-contacteditor-item">
                <div>{Contact.name(contact)}</div>
                <Tag color={COLOR_MAP[contact.type]}>{CoreUtils.toProper(contact.type)}</Tag>
            </div>
        )
    }

    const buildView = (): ReactElement => {
        return (
            <ListEditor
                formRef={props.formRef}
                id={props.id}
                name="Contact"
                editors={["Address", "Phone"]}
                items={props.contacts}
                editItem={editContact}
                type={(item) => CoreUtils.toProper((item as Contact).type)}
                render={renderItem}
                onEdit={(contact) => handleContactEdit(contact)}
                onSave={(contacts) => handleContactsSave(contacts)}
            >
                <AddressEditor
                    formRef={props.formRef}
                    id={props.id}
                    address={editContact as AddressContact}
                    onChange={(address) => handleAddressChange(address)}
                />
                <PhoneEditor
                    formRef={props.formRef}
                    id={props.id}
                    phone={editContact as PhoneContact}
                    onChange={(phone) => handlePhoneChange(phone)}
                />
            </ListEditor>
        )
    }

    return (
        <>
            {props.withForm &&
                <Form ref={props.formRef}>
                    {buildView()}
                </Form>
            }
            {!props.withForm &&
                buildView()
            }
        </>
    )

}

ContactEditor.defaultProps = defaultProps;
