import React, { Component, ReactElement } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, FormInstance } from 'antd';
import { FormLabelAlign } from 'antd/lib/form/interface';
import { Tip } from 'components/Tip/Tip';
import { Alignment, Direction, Justification, Size, Spacer } from 'components/Spacer/Spacer';
import classNames from 'classnames';
import './FormItem.less';

type FormValueProps = {
    value: any,
    children: any
}

const FormValue = (props: FormValueProps): ReactElement => {

    return (
        <>
            {props.children}
        </>
    )

}

// Format the label based on its desired position (standard = left, stacked = top).
export type LabelFormat = "standard" | "stacked";
export type ValidateStatus = "success" | "warning" | "error" | "validating";
export type InfoLocation = "icon" | "under";

export type FormItemState = {
    initialized: boolean
}

export type FormItemProps = typeof FormItem.defaultProps & {
    // Reference to the parent Form.
    formRef: React.RefObject<FormInstance>,
    // Optional class.
    className?: string,
    // Optional style.
    style?: object,
    // Span the entire width.
    fill?: boolean,
    // The spacing size for sub-items.
    spacing?: Size,
    // The location of the label.
    labelFormat?: LabelFormat,
    // Alignment of the label.
    labelAlign?: FormLabelAlign,
    // Column labels.
    labelCol?: object,
    // Column descriptors.
    wrapperCol?: object,
    // Class to apply to label.
    labelClassName?: string,
    // Class to apply to the content (prefix, children, postfix).
    bodyClassName?: string,
    // Show the colon.
    colon?: boolean
    // The name of the form item field.
    name?: string,
    // Extra into to put into the name, right justified. For stacked format only.
    extra?: string | ReactElement,
    // The label on the item.
    label?: string | object,
    // Set to true to remove the label and the space it would occupy.
    noLabel?: boolean,
    // Removes styles from the form item.
    noStyle?: boolean,
    // True to display the child component with no label, error message, etc.
    noError?: boolean,
    // Force show the required mark, even if there is no require rule.
    required?: boolean,
    // Bold label.
    bold?: boolean,
    // Hide form item.
    hidden?: boolean,
    // Info text for item.
    info?: ReactElement | string,
    // The location of the info text.
    infoLocation?: InfoLocation,
    // Alignment of items.
    alignment?: Alignment,
    // Justification of items.
    justification?: Justification,
    // The direction of nested items.
    direction?: Direction,
    // Validation rules.
    rules?: any,
    // Manual validation status.
    validateStatus?: ValidateStatus,
    // Manual validation message.
    help?: string,
    // The child component that is the form item.
    // Should have value and onChange(), unless
    // valueName and/or triggerName are specified.
    children: any,
    // Element to put before child component.
    prefix?: ReactElement | boolean,
    // Element to put after child component.
    postfix?: ReactElement | boolean,
    // Alternate name of value property.
    valuePropName?: string
}

/**
 * Substitute for antd Form.Item. The antd Form manages state, which changes
 * the model that React uses. This wrapper replaces Form.Item and allows
 * components to manage their own state (i.e., change and pass in new values
 * as props). The Form.Item will maintain a copy of the component value, but
 * it is used only for validations purposes. See antd Form.Item for more details.
 * Set the value and/or onChange as usual on the child component. This component
 * acts as a proxy to the child component and will keep the value in sync with
 * the externally managed value.
 */
export class FormItem extends Component<FormItemProps, FormItemState> {

    static defaultProps = {
        labelFormat: "stacked",
        valuePropName: "value",
        infoLocation: "icon",
        direction: "horizontal",
        alignment: "middle",
        justification: "left",
        noLabel: false,
        noError: false,
        hidden: false,
    };

    constructor(props: FormItemProps) {
        super(props);
        this.state = {
            initialized: false
        };
    }

    public shouldComponentUpdate(nextProps: FormItemProps): boolean {
        if (!nextProps.formRef.current) {
            return true;
        }
        if (!nextProps.name) {
            return true;
        }
        if (Array.isArray(nextProps.children) && nextProps.children.length === 0) {
            return true;
        }
        if (Array.isArray(nextProps.children) && nextProps.children.length > 0 && nextProps.children[0].type === FormItem) {
            return true;
        }
        if (nextProps.hidden) {
            return true;
        }
        if (!this.state.initialized) {
            // Sync state to native form item component on first load (and formRef is complete).
            const itemValue = nextProps.children.props[nextProps.valuePropName];
            nextProps.formRef.current.setFieldsValue({ [nextProps.name]: itemValue });
        } else {
            const itemValue = nextProps.children.props[nextProps.valuePropName];
            const formValue = nextProps.formRef.current.getFieldValue(nextProps.name);
            if (itemValue !== formValue) {
                // Sync state to native form item component when value is updated.
                nextProps.formRef.current.setFieldsValue({ [nextProps.name]: itemValue });
            }
        }
        return true;
    }

    public componentDidMount(): void {
        // Force a refresh so that the formRef will have its "current" field 
        // value filled in by the parent. This can only be created once the 
        // parent has been rendered. Once this component is re-rendered, the
        // parent will have been completed.
        if (!this.state.initialized) {
            this.setState({ initialized: true });
        }
    }

    public render(): ReactElement {
        let {
            formRef, children, fill, spacing, label, extra, prefix, postfix, info,
            infoLocation, className, labelClassName, bodyClassName, noLabel,
            noError, valuePropName, direction, alignment, justification, bold,
            colon, labelAlign, labelFormat, ...rest
        } = this.props;

        bold = bold !== undefined ? bold : (this.props.labelFormat === "stacked" ? true : false);
        colon = colon !== undefined ? colon : (this.props.labelFormat === "stacked" ? false : true);
        labelAlign = labelAlign !== undefined ? labelAlign : (this.props.labelFormat === "stacked" ? "left" : "right");
        noLabel = this.props.labelFormat === "stacked" && !label ? true : noLabel;

        // alignment = alignment ? alignment : (direction === "horizontal" ? "middle" : "top");
        // justification = !justification ? justification : (direction === "horizontal" ? "left" : "center");

        let labelView;
        if (label) {
            labelView = (
                <Spacer className="x-formitem-label" justification="between">
                    <span className={
                        classNames(
                            labelClassName,
                            { 'x-formitem-bold': !!bold }
                        )
                    }>
                        {label}
                        {info && infoLocation === "icon" &&
                            <Tip className="x-formitem-info-icon" title={info} placement="topLeft">
                                <InfoCircleOutlined />
                            </Tip>
                        }
                    </span>
                    {this.props.extra && this.props.labelFormat === "stacked" &&
                        <span>
                            {this.props.extra}
                        </span>
                    }
                </Spacer>
            );
        } else {
            // Remove the label if empty.
            labelView = ' ';
        }
        type AnyMap = {
            [key: string]: any
        }
        const props = this.props as AnyMap;
        const value = props[this.props.valuePropName];
        return (
            <Form.Item
                {...rest}
                valuePropName="value"
                colon={colon}
                labelAlign={labelAlign}
                className={
                    classNames(
                        'x-formitem',
                        this.props.className,
                        { 'x-formitem-fill': fill },
                        { 'x-formitem-extra': extra },
                        { 'x-formitem-no-error': noError },
                        { 'x-formitem-no-label': noLabel },
                        { 'x-formitem-hide-label': !label }
                    )}
                label={labelView}
                validateTrigger="onSubmit"
            >
                <FormValue value={value}>
                    {info && infoLocation === "under" &&
                        <div className="x-formitem-info-under">{info}</div>
                    }
                    <Spacer
                        className={classNames("x-formitem-body", bodyClassName)}
                        size={spacing}
                        direction={direction}
                        alignment={alignment}
                        justification={justification}
                    >
                        {prefix}
                        {children}
                        {postfix}
                    </Spacer>
                </FormValue>
            </Form.Item>
        );
    }
}
