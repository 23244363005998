import { PureComponent, ReactElement } from 'react';
import { DeleteOutlined, EditOutlined, SyncOutlined } from '@ant-design/icons';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Space, Tag } from 'antd';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { AuthenticationHeader, CredentialsType, Provider } from '@methodset/endpoint-client-ts';
import { ColumnsType } from 'antd/lib/table';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { StatusType } from 'constants/StatusType';
import { AuthenticationItem } from './AuthenticationItem/AuthenticationItem';
import axios from 'axios';
import classNames from 'classnames';
import update from 'immutability-helper';
import endpointService from 'services/EndpointService';
import './Authentications.less';

export type ProviderMap = { [key: string]: Provider };

export type AuthenticationsProps = RouteComponentProps & {
    className?: string
}

export type AuthenticationsState = {
    status: StatusType,
    providers?: Provider[],
    headers: AuthenticationHeader[],
    isEditing: boolean,
    authenticationId?: string
}

export class Authentications extends PureComponent<AuthenticationsProps, AuthenticationsState> {

    private colorMap = CoreUtils.toColormap(CoreUtils.enumToKeys(CredentialsType));

    constructor(props: AuthenticationsProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            headers: [],
            //providers: [],
            isEditing: false
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
        this.handleEditChange = this.handleEditChange.bind(this);
        this.handleEditCancel = this.handleEditCancel.bind(this);
        this.handleAuthenticationCreate = this.handleAuthenticationCreate.bind(this);
        this.handleAuthenticationEdit = this.handleAuthenticationEdit.bind(this);
        this.handleAuthenticationDelete = this.handleAuthenticationDelete.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleRefreshClick(): void {
        this.loadData();
    }

    private handleEditChange(header: AuthenticationHeader): void {
        let headers;
        const index = this.state.headers.findIndex(hdr => hdr.id === header.id);
        if (index === -1) {
            headers = update(this.state.headers, {
                $push: [header]
            });
        } else {
            headers = update(this.state.headers, {
                [index]: { $set: header }
            });
        }
        this.setState({
            headers: headers,
            isEditing: false,
            authenticationId: undefined
        });
    }

    private handleEditCancel(): void {
        this.setState({
            isEditing: false,
            authenticationId: undefined
        });
    }

    private handleAuthenticationCreate(): void {
        this.setState({ isEditing: true });
    }

    private handleAuthenticationEdit(header: AuthenticationHeader): void {
        this.setState({
            isEditing: true,
            authenticationId: header.id
        });
    }

    private handleAuthenticationDelete(authentication: AuthenticationHeader): void {
        this.deleteAuthenticationRequest(authentication.id);
    }

    private readProvidersRequest(): Promise<any> {
        const request = {};
        return endpointService.readProviders(request,
            (response: any) => this.readProvidersResponse(response),
            undefined, true
        );
    }

    private readProvidersResponse(response: any): void {
        const providers = response.data.providers;
        this.setState({ providers: providers });
    }

    private readAuthenticationHeadersRequest(): Promise<any> {
        const request = {
            //credentialsTypes: [CredentialsType.API, CredentialsType.FTP, CredentialsType.WEB]
        };
        return endpointService.readAuthenticationHeaders(request,
            (response: any) => this.readAuthenticationHeadersResponse(response),
            undefined, true
        );
    }

    private readAuthenticationHeadersResponse(response: any): void {
        const headers = response.data.headers;
        this.setState({ headers: headers });
    }

    private deleteAuthenticationRequest(authenticationId: string): Promise<any> {
        const request = {
            authenticationId: authenticationId
        };
        return endpointService.deleteAuthentication(request,
            (response: any) => this.deleteAuthenticationResponse(response)
        );
    }

    private deleteAuthenticationResponse(response: any): void {
        const authenticationId = response.data.authenticationId;
        const index = this.state.headers.findIndex(header => header.id === authenticationId);
        this.setState({
            headers: update(this.state.headers, {
                $splice: [[index, 1]]
            })
        });
    }

    private buildColumns(): ColumnsType<any> {
        const providerMap = Provider.map(this.state.providers!);
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
            render: (value, record) => {
                return <Link to="#" onClick={() => this.handleAuthenticationEdit(record)}>{value}</Link>
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'provider',
            title: 'Provider',
            align: 'center',
            dataIndex: 'providerId',
            render: (value, record) => {
                return (
                    <span>
                        {/* {value ? SourceType.name(value) : "Custom"} */}
                        {value ? providerMap[value].name : providerMap[Provider.CUSTOM].name}
                    </span>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.providerId.name, b.providerId.name),
            sortDirections: ['ascend', 'descend']
        }, {
            key: 'type',
            title: 'Type',
            align: 'center',
            dataIndex: 'credentialsType',
            render: (value, record) => {
                return (
                    <Tag color={this.colorMap![value ? value : 'NONE']}>
                        {CredentialsType.name(value)}
                    </Tag>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.credentialsType, b.credentialsType),
            sortDirections: ['ascend', 'descend']
        }, {
            key: 'utime',
            title: 'Last Updated',
            align: 'center',
            dataIndex: 'updateTime',
            render: (value, record) => {
                return (
                    <span>
                        {CoreUtils.toTime(value)}
                    </span>
                );
            },
            sorter: (a, b) => CoreUtils.compareNumbers(a.updateTime, b.updateTime),
            sortDirections: ['descend', 'ascend']
        }];
        return columns;
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readProvidersRequest());
        requests.push(this.readAuthenticationHeadersRequest());
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1, r2) => {
            if (RestUtils.isOk(r1, r2)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== StatusType.READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        const actions = [{
            icon: <EditOutlined />,
            label: "Edit credential",
            callback: this.handleAuthenticationEdit
        }, {
            icon: <DeleteOutlined />,
            label: "Delete credential",
            confirm: "Are you sure you want to delete the credential?",
            callback: this.handleAuthenticationDelete
        }];
        const columns = this.buildColumns();
        const data = this.state.headers;
        return (
            <>
                <ItemTable
                    className={classNames('x-authentications', this.props.className)}
                    title="Credentials"
                    status={this.state.status}
                    columns={columns}
                    items={data}
                    extra={
                        <Space>
                            <Button onClick={this.handleAuthenticationCreate}>New Authentication</Button>
                            <Button icon={<SyncOutlined />} onClick={this.handleRefreshClick}></Button>
                        </Space>
                    }
                    actions={actions}
                    onLoad={this.handleRetryLoad}
                />
                {this.state.isEditing && this.state.providers &&
                    <AuthenticationItem
                        authenticationId={this.state.authenticationId}
                        providers={this.state.providers}
                        onChange={this.handleEditChange}
                        onCancel={this.handleEditCancel}
                    />
                }
            </>
        );
    }

}
