import React, { Component, ReactElement } from 'react';
import { FormInstance, Radio, RadioChangeEvent } from 'antd';
import { Calculator, CellComponents, Coordinate, VariableComponents, Range, RangeComponents, ReferenceParser, RefType } from '@methodset/calculator-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { CellData, CellRefEditor } from './CellRefEditor/CellRefEditor';
import { VariableData, VariableRefEditor } from './VariableRefEditor/VariableRefEditor';
import { RangeData, RangeRefEditor } from './RangeRefEditor/RangeRefEditor';
import { FormItem } from 'components/FormItem/FormItem';
import classNames from 'classnames';
import './RefEditor.less';

export type ChangeCallback = (refId: string | undefined) => void;

export type RefEditorProps = typeof RefEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    // Optional class.
    className?: string,
    // Index to differentiate refs in same form, use prefix to further segment.
    index?: number,
    // A unique prefix to add to the field name for validation.
    prefix?: string,
    // The value is required.
    required?: boolean,
    // Allow the value to be cleared.
    allowClear?: boolean,
    // Only show variables that are marked internal.
    internalOnly?: boolean,
    // True if the range should validate as a vector.
    isVector?: boolean,
    // The reference id, i.e., Sheet1!A1, Sheet1!A1:B2, ticker.
    refId?: string,
    // The types of references to allow.
    refTypes?: RefType[],
    // The calculator the reference is in.
    calculator?: Calculator,
    // Called when the ref changes.
    onChange: ChangeCallback
}

export type RefEditorState = {
    type?: RefType,
    sheetId?: string,
    cellId?: string,
    variableId?: string,
    rangeId?: string
}

export class RefEditor extends Component<RefEditorProps, RefEditorState> {

    static defaultProps = {
        index: 0,
        prefix: "",
        required: true,
        allowClear: true,
        internalOnly: false,
        vector: false,
        refTypes: [RefType.VARIABLE, RefType.CELL, RefType.RANGE]
    }

    constructor(props: RefEditorProps) {
        super(props);
        this.state = {
            type: props.refTypes.length > 0 ? props.refTypes[0] : undefined
        }
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleCellChange = this.handleCellChange.bind(this);
        this.handleVariableChange = this.handleVariableChange.bind(this);
        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.handleCellCommit = this.handleCellCommit.bind(this);
        this.handleVariableCommit = this.handleVariableCommit.bind(this);
        this.handleRangeCommit = this.handleRangeCommit.bind(this);
    }

    private handleCellCommit(): void {
        if (this.state.sheetId && this.state.cellId) {
            const refId = `${ReferenceParser.quote(this.state.sheetId)}!${this.state.cellId}`;
            this.props.onChange(refId);
        }
    }

    private handleVariableCommit(): void {
        this.props.onChange(this.state.variableId);
    }

    private handleRangeCommit(): void {
        if (this.state.sheetId && this.state.rangeId) {
            const refId = `${ReferenceParser.quote(this.state.sheetId)}!${this.state.rangeId}`;
            this.props.onChange(refId);
        }
    }

    private handleTypeChange(e: RadioChangeEvent): void {
        const type = e.target.value;
        this.setState({
            type: type,
            sheetId: undefined,
            cellId: undefined,
            variableId: undefined,
            rangeId: undefined
        });
    }

    private handleCellChange(data: CellData, isCleared: boolean): void {
        this.setState({
            sheetId: data.sheetId,
            cellId: data.cellId
        }, () => {
            if (isCleared) {
                this.handleCellCommit();
            }
        });
    }

    private handleVariableChange(data: VariableData, isCleared: boolean): void {
        this.setState({
            variableId: data.variableId
        }, () => {
            this.handleVariableCommit();
        });
    }

    private handleRangeChange(data: RangeData, isCleared: boolean): void {
        this.setState({
            sheetId: data.sheetId,
            rangeId: data.rangeId
        }, () => {
            if (isCleared) {
                this.handleRangeCommit();
            }
        });
    }

    private parseRef(refId: string | undefined): void {
        if (!refId) {
            this.setState({
                sheetId: undefined,
                cellId: undefined,
                variableId: undefined,
                rangeId: undefined
            });
            return;
        }
        const ref = ReferenceParser.parse(refId);
        if (ref) {
            if (ref.type === RefType.CELL) {
                const components = ref.components as CellComponents;
                this.setState({
                    type: RefType.CELL,
                    sheetId: components.sheetId,
                    cellId: components.cellId
                });
            } else if (ref.type === RefType.VARIABLE) {
                const components = ref.components as VariableComponents;
                this.setState({
                    type: RefType.VARIABLE,
                    variableId: components.variableId
                });
            } else if (ref.type === RefType.RANGE) {
                const components = ref.components as RangeComponents;
                this.setState({
                    type: RefType.RANGE,
                    sheetId: components.sheetId,
                    rangeId: components.rangeId
                });
            }
        }
    }

    public componentDidMount(): void {
        if (this.props.refId) {
            this.parseRef(this.props.refId);
        }
    }

    public shouldComponentUpdate(nextProps: RefEditorProps, nextState: RefEditorState): boolean {
        if (this.props.refId !== nextProps.refId) {
            this.parseRef(nextProps.refId);
            return false;
        }
        if (this.state.sheetId !== nextState.sheetId ||
            this.state.cellId !== nextState.cellId ||
            this.state.variableId !== nextState.variableId ||
            this.state.rangeId !== nextState.rangeId) {
            return true;
        }
        return true;
    }

    public render(): ReactElement {
        const sheets = this.props.calculator?.sheets;
        const variables = this.props.calculator?.variables;
        return (
            <Spacer
                className={classNames("x-refeditor", this.props.className)}
                direction="vertical"
                alignment="top"
                fill={true}
            >
                {this.props.refTypes.length > 1 &&
                    <Radio.Group
                        className="x-refeditor-type"
                        value={this.state.type}
                        onChange={this.handleTypeChange}
                    >
                        <Spacer direction="horizontal" justification="left" fill={true}>
                            {this.props.refTypes.includes(RefType.CELL) &&
                                <Radio value={RefType.CELL}>Cell</Radio>
                            }
                            {this.props.refTypes.includes(RefType.VARIABLE) &&
                                <Radio value={RefType.VARIABLE}>Variable</Radio>
                            }
                            {this.props.refTypes.includes(RefType.RANGE) &&
                                <Radio value={RefType.RANGE}>Range</Radio>
                            }
                        </Spacer>
                    </Radio.Group>
                }
                {this.state.type === RefType.CELL &&
                    <FormItem
                        formRef={this.props.formRef}
                        fill={true}
                        noLabel={true}
                        noStyle={true}
                        name={`cell-${this.props.prefix}${this.props.index}`}
                        required={this.props.required}
                        rules={[{
                            validator: (rule: any, data: CellData) => {
                                if (!this.props.required && (!data.sheetId && !data.cellId)) {
                                    return Promise.resolve();
                                } else if (!this.props.required && (!data.sheetId || !data.cellId)) {
                                    return Promise.reject(new Error('Please enter or remove the cell.'));
                                } else if (!data.sheetId) {
                                    return Promise.reject(new Error('Please select a sheet.'));
                                } else if (!data.cellId) {
                                    return Promise.reject(new Error('Please enter a cell.'));
                                }
                                try {
                                    Coordinate.validateId(data.cellId);
                                    return Promise.resolve();
                                } catch (e) {
                                    return Promise.reject(new Error('Invalid cell value.'));
                                }
                            }
                        }]}
                    >
                        <CellRefEditor
                            value={{ sheetId: this.state.sheetId, cellId: this.state.cellId } as CellData}
                            sheets={sheets}
                            allowClear={this.props.allowClear}
                            onCommit={this.handleCellCommit}
                            onChange={this.handleCellChange}
                        />
                    </FormItem>
                }
                {this.state.type === RefType.VARIABLE &&
                    <FormItem
                        formRef={this.props.formRef}
                        fill={true}
                        noLabel={true}
                        noStyle={true}
                        name={`variable-${this.props.prefix}${this.props.index}`}
                        required={this.props.required}
                        rules={[{
                            validator: (rule: any, data: VariableData) => {
                                if (this.props.required && !data.variableId) {
                                    return Promise.reject(new Error('Please enter a variable.'));
                                }
                                return Promise.resolve();
                            }
                        }]}
                    >
                        <VariableRefEditor
                            value={{ variableId: this.state.variableId } as VariableData}
                            variables={variables}
                            allowClear={this.props.allowClear}
                            internalOnly={this.props.internalOnly}
                            onCommit={this.handleVariableCommit}
                            onChange={this.handleVariableChange}
                        />
                    </FormItem>
                }
                {this.state.type === RefType.RANGE &&
                    <FormItem
                        formRef={this.props.formRef}
                        fill={true}
                        noLabel={true}
                        noStyle={true}
                        name={`range-${this.props.prefix}${this.props.index}`}
                        required={this.props.required}
                        rules={[{
                            validator: (rule: any, data: RangeData) => {
                                if (!this.props.required && (!data.sheetId && !data.rangeId)) {
                                    return Promise.resolve();
                                } else if (!this.props.required && (!data.sheetId || !data.rangeId)) {
                                    return Promise.reject(new Error('Please enter or remove the range.'));
                                } else if (!data.sheetId) {
                                    return Promise.reject(new Error('Please select a sheet.'));
                                } else if (!data.rangeId) {
                                    return Promise.reject(new Error('Please enter a range.'));
                                }
                                try {
                                    if (this.props.isVector) {
                                        if (!Range.isVector(data.rangeId)) {
                                            return Promise.reject(new Error('Range is not a row or column.'));
                                        } else {
                                            return Promise.resolve();
                                        }
                                    } else {
                                        Range.validateId(data.rangeId);
                                        return Promise.resolve();
                                    }
                                } catch (e) {
                                    return Promise.reject(new Error('Invalid range value.'));
                                }
                            }
                        }]}
                    >
                        <RangeRefEditor
                            value={{ sheetId: this.state.sheetId, rangeId: this.state.rangeId } as RangeData}
                            sheets={sheets}
                            allowClear={this.props.allowClear}
                            onCommit={this.handleRangeCommit}
                            onChange={this.handleRangeChange}
                        />
                    </FormItem>
                }
            </Spacer>
        );
    }

}
