import { PureComponent, ReactElement } from 'react';
import { Empty } from 'antd';
import { Cell, ElementChangeType, Parameter, Variable, VariableChangeEvent, VariablesChangeEvent } from '@methodset/calculator-ts';
import { ModelContext } from 'context/ModelContext';
import { VariableItem } from './VariableItem/VariableItem';
import { VariableEditor, VariableData } from '../Parameters/VariableEditor/VariableEditor';
import { ActionType, CellAction } from '../CellAction/CellAction';
import './Variables.less';
import { NoData } from 'components/NoData/NoData';

export type VariablesProps = {
}

export type VariablesState = {
    isEditing: boolean,
    isFormatting: boolean
}

export class Variables extends PureComponent<VariablesProps, VariablesState> {

    static contextType = ModelContext;

    private cell?: Cell;

    constructor(props: VariablesProps) {
        super(props);
        this.state = {
            isEditing: false,
            isFormatting: false
        };
        this.handleVariableAdd = this.handleVariableAdd.bind(this);
        this.handleVariableEdit = this.handleVariableEdit.bind(this);
        this.handleVariableCancel = this.handleVariableCancel.bind(this);
        this.handleVariableUpdate = this.handleVariableUpdate.bind(this);
        this.handleVariableChange = this.handleVariableChange.bind(this);
        this.handleVariableRemove = this.handleVariableRemove.bind(this);
        this.handleVariablesChange = this.handleVariablesChange.bind(this);
    }

    private handleVariableAdd(cell: Cell | undefined): void {
        this.cell = cell;
        this.setState({ isEditing: true });
    }

    private handleVariableEdit(cell: Cell): void {
        this.cell = cell;
        this.setState({ isEditing: true });
    }

    private handleVariableCancel(): void {
        this.cell = undefined;
        this.setState({ isEditing: false });
    }

    private handleVariableUpdate(data: VariableData): void {
        if (!this.cell) {
            const activeCell = this.context.active.cell;
            const sheet = activeCell.sheet;
            this.cell = sheet.getCell(activeCell.id);
        }
        // Add will trigger variable list change event.
        this.cell!.variable = Variable.to(data.id, data.name, data.description, 
            data.type, data.isRequired, data.isInternal, data.options);
        this.cell = undefined;
        this.setState({ isEditing: false });
    }

    private handleVariableRemove(cell: Cell): void {
        // Removal will trigger a variable list change event.
        cell.variable = undefined;
    }

    private handleVariablesChange(event: VariablesChangeEvent): void {
        if (event.type === ElementChangeType.ADD) {
            event.variable.addCallback("Change", this.handleVariableChange);
            this.forceUpdate();
        } else if (event.type === ElementChangeType.REMOVE) {
            event.variable.removeCallback("Change", this.handleVariableChange);
            this.forceUpdate();
        } else if (event.type === ElementChangeType.MOVE) {
            this.forceUpdate();
        } 
    }

    private handleVariableChange(event: VariableChangeEvent): void {
        this.forceUpdate();
    }

    public componentDidMount(): void {
        const variables = this.context.calculator.variables;
        variables.addCallback("Change", this.handleVariablesChange);
        variables.pure.forEach((variable: Variable) => {
            variable.addCallback("Change", this.handleVariableChange);
        });
    }

    public componentWillUnmount(): void {
        const variables = this.context.calculator.variables;
        variables.removeCallback("Change", this.handleVariablesChange);
        variables.pure.forEach((variable: Variable) => {
            variable.removeCallback("Change", this.handleVariableChange);
        });
    }

    public render(): ReactElement {
        const fn = (cell?: Cell): ActionType => {
            if (!cell) {
                return "add";
            } else if (Parameter.isParameterRef(cell)) {
                return "none";
            }
            return cell.variable ? "edit" : "add";
        }
        // Filter out the parameters, they are displayed separately.
        const variables = this.context.calculator.variables.pure;
        return (
            <div id="variables" className="x-variables">
                {variables.length === 0 &&
                    <NoData text="No variables." />
                }
                <div className="x-variables-items">
                    {variables.length > 0 && variables.map((variable: Variable, index: number) =>
                        <VariableItem
                            key={`${variable.id}-${variable.name}`}
                            cell={variable.cell}
                            calculator={this.context.calculator}
                            variables={variables}
                            index={index}
                            onEdit={this.handleVariableEdit}
                            onRemove={this.handleVariableRemove}
                        />
                    )}
                </div>
                <CellAction
                    fn={fn}
                    onAdd={this.handleVariableAdd}
                    onEdit={this.handleVariableEdit}
                />
                {this.state.isEditing &&
                    <VariableEditor
                        data={{
                            id: this.cell?.variable?.id,
                            name: this.cell?.variable?.name,
                            description: this.cell?.variable?.description,
                            type: this.cell?.variable?.type,
                            isRequired: this.cell?.variable?.isRequired,
                            isInternal: this.cell?.variable?.isInternal,
                            options: this.cell?.variable?.options
                        } as VariableData}
                        onChange={this.handleVariableUpdate}
                        onCancel={this.handleVariableCancel}
                    />
                }
            </div>
        )
    }

}
