import React, { ReactElement, useState } from 'react';
import { Button, Dropdown, Form, FormInstance, List, Menu, Modal } from 'antd';
import { ItemSpec, MenuButton } from 'components/MenuButton/MenuButton';
import { DeleteOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import { Globals } from 'constants/Globals';
import { CoreUtils } from 'utils/CoreUtils';
import update from 'immutability-helper';
import './ListEditor.less';

export type EditHandler = (item: any) => void;
export type DoneHandler = (items: any[]) => void;
export type RenderFunction = (item: any) => ReactElement;
export type TypeFunction = (item: any) => string;

export type ListEditorProps = {
    formRef: React.RefObject<FormInstance>,
    // Id for the list to make it unique.
    id: string,
    // The name to place on the button to add items.
    name: string,
    // Optional editor names, if more than one type
    // can be edited. If this is defined, the editor
    // names must match (number and order) of the 
    // children React elements.
    editors?: string[],
    // The items to display.
    items?: any[],
    // The item being edited.
    editItem?: any;
    // Editor elements.
    children: ReactElement | ReactElement[],
    // The function used to find the editor type for an item.
    // Required if the editors property exists. The returned
    // value from this function must match the editor value.
    type?: TypeFunction,
    // The function used to render an item.
    render: RenderFunction,
    // Called when an item is being edited.
    onEdit: EditHandler,
    // Called when item changes are complete.
    onSave: DoneHandler
} & typeof defaultProps;

const defaultProps = {
    items: [] as any[]
}

const NONE_INDEX = -1;
const ADD_INDEX = -2;

export const ListEditor = (props: ListEditorProps): ReactElement => {

    const [editIndex, setEditIndex] = useState<number>(NONE_INDEX);
    const [typeIndex, setTypeIndex] = useState<number | undefined>();

    const handleItemAdd = (index?: number): void => {
        setEditIndex(ADD_INDEX);
        setTypeIndex(index);
    }

    const handleItemEdit = (index: number): void => {
        const item = props.items[index];
        if (props.type && props.editors) {
            const editor = props.type(item);
            const pos = props.editors.findIndex(e => e === editor);
            if (pos !== -1) {
                setTypeIndex(pos);
            }
        }
        setEditIndex(index);
        props.onEdit(item);
    }

    const handleItemRemove = (index: number): void => {
        const items = update(props.items, {
            $splice: [[index, 1]]
        });
        props.onSave(items);
    }

    const handleItemCancel = (): void => {
        props.onEdit(undefined);
        setEditIndex(NONE_INDEX);
    }

    const handleItemSave = (): void => {
        props.formRef.current?.validateFields().then(values => {
            let items;
            if (editIndex === ADD_INDEX) {
                items = update(props.items, {
                    $push: [props.editItem!]
                });
            } else {
                items = update(props.items, {
                    [editIndex]: { $set: props.editItem }
                });
            }
            setEditIndex(NONE_INDEX);
            props.onSave(items);
        }).catch(e => {
        });
    }

    const editView = (): ReactElement => {
        return (
            <Modal
                centered
                width={Globals.DIALOG_WIDTH}
                title={`Edit ${props.name}`}
                onOk={handleItemSave}
                onCancel={handleItemCancel}
                visible={true}
            >
                {Array.isArray(props.children) && !CoreUtils.isEmpty(typeIndex) && typeIndex! < props.children.length &&
                    <>
                        {props.children[typeIndex!]}
                    </>
                }
                {!Array.isArray(props.children) &&
                    <>
                        {props.children}
                    </>
                }
            </Modal>
        )
    }

    const listView = (): ReactElement => {
        const items = (index: number): ItemSpec[] => {
            return [{
                icon: <EditOutlined />,
                label: "Edit",
                onSelect: () => handleItemEdit(index)
            }, {
                icon: <DeleteOutlined />,
                label: "Remove",
                onSelect: () => handleItemRemove(index)
            }];
        }
        return (
            <List
                dataSource={props.items}
                renderItem={(item, index) => (
                    <List.Item key={index}>
                        <div className="x-listeditor-item">
                            {props.render(item)}
                            <MenuButton
                                className="x-listeditor-menu"
                                items={items(index)}
                                index={index}
                            />
                        </div>
                    </List.Item>
                )}
            />
        )
    }

    const addButton = (): ReactElement => {
        if (props.editors) {
            const menu = (
                <Menu>
                    {props.editors.map((editor, index) => (
                        <Menu.Item key={editor} onClick={() => handleItemAdd(index)}>
                            <span>{editor}</span>
                        </Menu.Item>
                    ))}
                </Menu>
            )
            return (
                <Dropdown overlay={menu} trigger={['click']}>
                    <Button className="x-listeditor-add">
                        {`Add ${props.name}`} <DownOutlined />
                    </Button>
                </Dropdown>
            )
        } else {
            return (
                <Button
                    className="x-listeditor-add"
                    onClick={() => handleItemAdd()}
                >
                    {`Add ${props.name}`}
                </Button>
            )
        }
    }

    return (
        <>
            {(props.editItem || editIndex === ADD_INDEX) &&
                editView()
            }
            <div>
                {props.items.length > 0 &&
                    listView()
                }
                {addButton()}
            </div>
        </>
    )
}

ListEditor.defaultProps = defaultProps;
