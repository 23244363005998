import { ReactElement, useEffect, useState } from 'react';
import './InlineMessage.less';

// Types of messages.
export type MessageType = "info" | "subtle" | "warn" | "error";

// Message to display.
export interface OpMessage {
    text: string | ReactElement;
    type: MessageType;
}

export type InlineMessageProps = {
    // The message to display. If empty, clear.
    message?: OpMessage,
    // The amount of time (msec) to display an error.
    timeout?: number
}

const defaultProps = {
    timeout: 3000
}

export const InlineMessage = (props: InlineMessageProps): ReactElement => {

    const [message, setMessage] = useState<any>(props.message);

    useEffect(() => {
        setMessage(props.message);
        if (props.message && ["warn", "error"].includes(props.message.type)) {
            setTimeout(() => {
                setMessage(undefined);
            }, props.timeout);
        }
    }, [props.message]);

    return (
        <>
            {message &&
                <span className={`x-inlinemessage-${message.type}`}>
                    {message.text}
                </span>
            }
        </>
    )

}

InlineMessage.defaultProps = defaultProps;
