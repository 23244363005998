import { KeyboardEvent } from 'react';

export const enum KeyCode {
    NONE = "None",
    TAB = "Tab",
    ENTER = "Enter",
    DELETE = "Delete",
    ESCAPE = "Escape",
    ARROW_LEFT = "ArrowLeft",
    ARROW_UP = "ArrowUp",
    ARROW_RIGHT = "ArrowRight",
    ARROW_DOWN = "ArrowDown",
    SHIFT_LEFT = "ShiftLeft",
    SHIFT_RIGHT = "ShiftRight",
    CTRL_LEFT = "ControlLeft",
    CTRL_RIGHT = "ControlRight"
}

export class KeyUtils {

    public static is(e: KeyboardEvent, code: KeyCode): boolean {
        return e.code === code;
    }

    public static in(e: KeyboardEvent, ...codes: KeyCode[]): boolean {
        for (const code of codes) {
            if (e.code === code) {
                return true;
            }
        }
        return false;
    }

    public static isArrow(e: KeyboardEvent): boolean {
        return e.code === KeyCode.ARROW_LEFT || e.code === KeyCode.ARROW_UP || e.code === KeyCode.ARROW_RIGHT || e.code === KeyCode.ARROW_DOWN;
    }

    public static isCommit(e: KeyboardEvent): boolean {
        return e.code === KeyCode.ENTER || e.code === KeyCode.TAB;
    }

    public static isCancel(e: KeyboardEvent): boolean {
        return e.code === KeyCode.ESCAPE;
    }

    public static isExit(e: KeyboardEvent): boolean {
        return this.isCommit(e) || this.isCancel(e);
    }

    public static isDelete(e: KeyboardEvent): boolean {
        return e.code === KeyCode.DELETE;
    }

    public static isShift(e: KeyboardEvent): boolean {
        return e.code === KeyCode.SHIFT_LEFT || e.code === KeyCode.SHIFT_RIGHT;
    }

    public static isCtrl(e: KeyboardEvent): boolean {
        return e.code === KeyCode.CTRL_LEFT || e.code === KeyCode.CTRL_RIGHT;
    }

    public static isCopy(e: KeyboardEvent): boolean {
        return e.ctrlKey && e.code === "KeyC";
    }

    public static isPaste(e: KeyboardEvent): boolean {
        return e.ctrlKey && e.code === "KeyV";
    }

    public static isPrintable(e: KeyboardEvent): boolean {
        switch (e.code) {
            case "Digit0":
            case "Digit1":
            case "Digit2":
            case "Digit3":
            case "Digit4":
            case "Digit5":
            case "Digit6":
            case "Digit7":
            case "Digit8":
            case "Digit9":
            case "KeyA":
            case "KeyB":
            case "KeyC":
            case "KeyD":
            case "KeyE":
            case "KeyF":
            case "KeyG":
            case "KeyH":
            case "KeyI":
            case "KeyJ":
            case "KeyK":
            case "KeyL":
            case "KeyM":
            case "KeyN":
            case "KeyO":
            case "KeyP":
            case "KeyQ":
            case "KeyR":
            case "KeyS":
            case "KeyT":
            case "KeyU":
            case "KeyV":
            case "KeyW":
            case "KeyX":
            case "KeyY":
            case "KeyZ":
            case "Numpad0":
            case "Numpad1":
            case "Numpad2":
            case "Numpad3":
            case "Numpad4":
            case "Numpad5":
            case "Numpad6":
            case "Numpad7":
            case "Numpad8":
            case "Numpad9":
            case "Comma":
            case "Period":
            case "Semicolon":
            case "Quote":
            case "BracketLeft":
            case "BracketRight":
            case "BackQuote":
            case "BackSlash":
            case "Minus":
            case "Equal":
                return true;
            default:
                return false;
        }
    }

}