import React, { ChangeEvent, PureComponent } from 'react';
import { CloseCircleOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, FormInstance, Input, Modal, Select } from 'antd';
import { ReactElement } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CoreUtils } from 'utils/CoreUtils';
import { FieldStatus } from '../SchemaEditor';
import { IoType } from '@methodset/endpoint-client-ts';
import { FormItem } from 'components/FormItem/FormItem';
import { Spacer } from 'components/Spacer/Spacer';
import update from 'immutability-helper';
import './FieldItem.less';

export type AddCallback = (state: FieldStatus, index: number) => void;
export type RemoveCallback = (state: FieldStatus, index: number) => void;
export type ChangeCallback = (state: FieldStatus, index: number) => void;
export type MoveUpCallback = (state: FieldStatus, index: number) => void;
export type MoveDownCallback = (state: FieldStatus, index: number) => void;

export type FieldItemProps = typeof FieldItem.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    className?: string,
    fieldState: FieldStatus,
    index: number,
    size: number,
    onAdd: AddCallback,
    onRemove: RemoveCallback,
    onChange: ChangeCallback,
    onMoveUp: MoveUpCallback,
    onMoveDown: MoveDownCallback,
}

export class FieldItem extends PureComponent<FieldItemProps> {

    static defaultProps = {
    }

    private keyRef = React.createRef<Input>();
    private hasFocus = false;
    private hasModal = false;
    private isNewKey = !this.props.fieldState.field.key;

    constructor(props: FieldItemProps) {
        super(props);
        this.handleKeyFocus = this.handleKeyFocus.bind(this);
        this.handleKeyBlur = this.handleKeyBlur.bind(this);
        this.handleKeyChange = this.handleKeyChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleMoveUp = this.handleMoveUp.bind(this);
        this.handleMoveDown = this.handleMoveDown.bind(this);
    }

    private handleKeyFocus() {
        if (this.hasFocus || !this.keyRef.current) {
            return;
        }
        this.hasFocus = true;
        this.hasModal = true;
        const self = this;
        Modal.warn({
            title: "Schema Key Change",
            content: "References to this version of the dataset may no " +
                "longer work if the key is changed. Make sure that any " +
                "references are not impacted by changing the key.",
            onOk() {
                self.hasModal = false;
                self.keyRef.current!.focus();
            }
        });
    }

    private handleKeyBlur(): void {
        if (this.hasModal) {
            return;
        }
        this.hasFocus = false;
        this.hasModal = false;
    }

    private handleKeyChange(e: ChangeEvent<HTMLInputElement>): void {
        let key = e.target.value;
        key = CoreUtils.toCodeName(key, "_");
        const fieldState = update(this.props.fieldState, {
            field: {
                key: { $set: key }
            }
        });
        this.isNewKey = false;
        this.props.onChange(fieldState, this.props.index);
    }

    private handleNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const name = e.target.value;
        let fieldState = update(this.props.fieldState, {
            field: {
                name: { $set: name }
            }
        });
        if (this.isNewKey) {
            const key = CoreUtils.toCodeName(fieldState.field.name, "_");
            fieldState = update(fieldState, {
                field: {
                    key: { $set: key }
                }
            });
        }
        this.props.onChange(fieldState, this.props.index);
    }

    private handleDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        const description = e.target.value;
        let fieldState = update(this.props.fieldState, {
            field: {
                description: { $set: description }
            }
        });
        this.props.onChange(fieldState, this.props.index);
    }

    private handleTypeChange(type: IoType): void {
        const fieldState = update(this.props.fieldState, {
            field: {
                type: { $set: type }
            }
        });
        this.props.onChange(fieldState, this.props.index);
    }

    private handleSelectChange(e: CheckboxChangeEvent): void {
        const isSelected = e.target.checked;
        if (isSelected) {
            this.props.onAdd(this.props.fieldState, this.props.index);
        } else {
            this.props.onRemove(this.props.fieldState, this.props.index);
        }
    }

    private handleDeleteClick(): void {
        this.props.onRemove(this.props.fieldState, this.props.index);
    }

    private handleMoveUp(): void {
        this.props.onMoveUp(this.props.fieldState, this.props.index);
    }

    private handleMoveDown(): void {
        this.props.onMoveDown(this.props.fieldState, this.props.index);
    }

    public render(): ReactElement {
        return (
            <>
                <Col span={5}>
                    <Spacer>
                        {this.props.fieldState.isDeleted &&
                            <div className="x-fielditem-delete">
                                <CloseCircleOutlined onClick={this.handleDeleteClick} />
                            </div>
                        }
                        {!this.props.fieldState.isDeleted &&
                            <Checkbox
                                checked={this.props.fieldState.isSelected}
                                onChange={this.handleSelectChange}
                            />
                        }
                        <FormItem
                            formRef={this.props.formRef}
                            name={`key-${this.props.index}`}
                            noError={true}
                            noLabel={true}
                            rules={[{
                                required: true,
                                message: "Enter a key."
                            }]}
                        >
                            <Input
                                ref={this.keyRef}
                                value={this.props.fieldState.field.key}
                                onChange={this.handleKeyChange}
                                onFocus={this.handleKeyFocus}
                                onBlur={this.handleKeyBlur}
                            />
                        </FormItem>
                    </Spacer>
                </Col>
                <Col span={4}>
                    <FormItem
                        formRef={this.props.formRef}
                        name={`name-${this.props.index}`}
                        noError={true}
                        noLabel={true}
                        rules={[{
                            required: true,
                            message: "Select a type."
                        }]}
                    >
                        <Select
                            value={this.props.fieldState.field.type}
                            onChange={this.handleTypeChange}>
                            <Select.Option value={IoType.TEXT}>Text</Select.Option>
                            <Select.Option value={IoType.NUMBER}>Number</Select.Option>
                            <Select.Option value={IoType.BOOLEAN}>Boolean</Select.Option>
                            <Select.Option value={IoType.DATE}>Date</Select.Option>
                            <Select.Option value={IoType.TIME}>Time</Select.Option>
                        </Select>
                    </FormItem>
                </Col>
                <Col span={5}>
                    <FormItem
                        formRef={this.props.formRef}
                        name={`type-${this.props.index}`}
                        noError={true}
                        noLabel={true}
                        rules={[{
                            required: true,
                            message: "Enter a name."
                        }]}
                    >
                        <Input
                            value={this.props.fieldState.field.name}
                            onChange={this.handleNameChange}
                        />
                    </FormItem>
                </Col>
                <Col span={10}>
                    <Spacer fill>
                        <Input.TextArea
                            rows={1}
                            value={this.props.fieldState.field.description}
                            onChange={this.handleDescriptionChange}
                        />
                        <Button
                            disabled={this.props.index === 0}
                            icon={<UpOutlined />}
                            size="small"
                            onClick={this.handleMoveUp}
                        />
                        <Button
                            disabled={this.props.index === this.props.size - 1}
                            icon={<DownOutlined />}
                            size="small"
                            onClick={this.handleMoveDown}
                        />
                    </Spacer>
                </Col>
            </>
        );
    }
}
