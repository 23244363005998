import { ReactElement, useRef } from 'react';
import { Button } from 'antd';
import { DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { ItemSpec, MenuButton } from 'components/MenuButton/MenuButton';
import { Spacer } from 'components/Spacer/Spacer';
import { Calculator, Variable } from '@methodset/calculator-ts';
import { StaticInput } from 'components/StaticInput/StaticInput';
import { Fill } from 'components/Fill/Fill';
import update from 'immutability-helper';
import './VariablesSelector.less';

export type ChangeCallback = (variableIds: string[]) => void;

export type VariablesSelectorProps = {
    label: string,
    calculator: Calculator,
    variableIds?: string[],
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    variableIds: [] as string[]
    //usedIds: [] as string[]
}

export const VariablesSelector = (props: VariablesSelectorProps): ReactElement => {

    const handleVariableAdd = (variableId: string): void => {
        const variableIds = update(props.variableIds, {
            $push: [variableId]
        });
        props.onChange(variableIds);
    }

    const handleVariableRemove = (index: number): void => {
        const variableIds = update(props.variableIds, {
            $splice: [[index, 1]]
        });
        props.onChange(variableIds);
    }

    const items: ItemSpec[] = props.calculator.variables.map((variable: Variable) => {
        //const usedIds = [...props.variableIds, ...props.usedIds];
        return {
            label: variable.name,
            disabled: () => props.variableIds.includes(variable.id),
            onSelect: () => handleVariableAdd(variable.id)
        }
    });

    const variableName = (variableId: string): string => {
        const variable = props.calculator.variables.get(variableId, false);
        return variable ? variable.name : "<Variable not found>";
    }

    return (
        <Fill>
            <Spacer className="x-variablesselector" direction="vertical" alignment="top" fill>
                {props.variableIds.filter(variableId => !!variableId).map((variableId: string, index: number) => (
                    <Spacer key={variableId} fill>
                        <StaticInput
                            className="x-variablesselector-item"
                            value={variableName(variableId)}
                        />
                        <Button
                            className="x-variablesseletor-delete"
                            icon={<DeleteOutlined />}
                            onClick={() => handleVariableRemove(index)}
                        />
                    </Spacer>
                ))}
            </Spacer>
            <div className="x-variablesselector-add">
                <MenuButton
                    label={`Add ${props.label}`}
                    items={items}
                    icon={<DownOutlined />}
                />
            </div>
        </Fill>
    );
}

VariablesSelector.defaultProps = defaultProps;