import React, { Component as ReactComponent, ReactElement } from 'react';
import { Button, Checkbox, Col, Empty, FormInstance, Row, Space } from 'antd';
import { Globals } from 'constants/Globals';
import { FieldItem } from './FieldItem/FieldItem';
import { Label } from 'components/Label/Label';
import { AuthenticationHeader, Component, Configuration, ConfigurationSpec, Dataset, Field, IoType, Schema } from '@methodset/endpoint-client-ts';
import { DataPreview } from '../../../../DataPreview/DataPreview';
import { RestUtils } from 'utils/RestUtils';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ConfigurationDialog } from 'containers/Console/ConfigurationDialog/ConfigurationDialog';
import { Spacer } from 'components/Spacer/Spacer';
import { NoData } from 'components/NoData/NoData';
import endpointService from 'services/EndpointService';
import classNames from 'classnames';
import update from 'immutability-helper';
import './SchemaEditor.less';

export interface FieldStatus {
    field: Field,
    isSelected: boolean,
    isDeleted: boolean
}

// Map of schema field key to field.
type FieldMap = { [key: string]: Field };
// Map of schema field key to state for the field.
type StatusMap = { [key: string]: FieldStatus };

export type UpdateCallback = (configuration: Configuration) => void;
export type ChangeCallback = (schema: Schema) => void;

export type SchemaEditorProps = typeof SchemaEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    className?: string,
    components: Component[],
    configuration?: Configuration,
    configurationSpecs: ConfigurationSpec[],
    authentications: AuthenticationHeader[],
    schema: Schema,
    onUpdate: UpdateCallback,
    onChange: ChangeCallback
}

export type SchemaEditorState = {
    // Loading status.
    status: string,
    // If data schema is being loaded.
    isSchemaLoading: boolean,
    // If data dataset is being loaded.
    isDatasetLoading: boolean,
    // True when showing the query input dialog for schema.
    showSchemaQuery: boolean,
    // True when showing the query input dialog for dataset.
    showDatasetQuery: boolean,
    // A preview of the dataset based on the schema.
    previewDataset: Dataset | null,
    // State of schema fields.
    fieldStatuses: FieldStatus[],
    // Error message on failure.
    error: Error | null,
}

export class SchemaEditor extends ReactComponent<SchemaEditorProps, SchemaEditorState> {

    static defaultProps = {
        schema: {
            fields: [] as Field[]
        }
    }

    constructor(props: SchemaEditorProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            isSchemaLoading: false,
            isDatasetLoading: false,
            showSchemaQuery: false,
            showDatasetQuery: false,
            previewDataset: null,
            fieldStatuses: [],
            error: null,
        };
        this.handleQueryExecute = this.handleQueryExecute.bind(this);
        this.handleQueryCancel = this.handleQueryCancel.bind(this);
        this.handleSchemaLoad = this.handleSchemaLoad.bind(this);
        this.handleDataPreview = this.handleDataPreview.bind(this);
        this.handleFieldAdd = this.handleFieldAdd.bind(this);
        this.handleFieldRemove = this.handleFieldRemove.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleFieldMoveUp = this.handleFieldMoveUp.bind(this);
        this.handleFieldMoveDown = this.handleFieldMoveDown.bind(this);
        this.handlePreviewClose = this.handlePreviewClose.bind(this);
        this.handleSelectToggle = this.handleSelectToggle.bind(this);
    }

    private handleQueryExecute(configuration: Configuration): void {
        this.props.onUpdate(configuration);
        if (this.props.components.length === 0) {
            const errorMessage = "Please configure processors.";
            this.setState({
                showSchemaQuery: false,
                showDatasetQuery: false,
                error: new Error(errorMessage)
            });
            return;
        }
        if (this.state.showSchemaQuery) {
            this.loadTestSchemaRequest(configuration);
        } else {
            this.loadTestDatasetRequest(configuration);
        }
    }

    private handleQueryCancel(): void {
        this.setState({
            showSchemaQuery: false,
            showDatasetQuery: false
        });
    }

    private handleSchemaLoad(): void {
        if (this.props.configurationSpecs.length !== 0) {
            this.setState({ showSchemaQuery: true });
        } else {
            this.loadTestSchemaRequest({});
        }
    }

    private handleDataPreview(): void {
        if (this.props.configurationSpecs.length !== 0) {
            this.setState({ showDatasetQuery: true });
        } else {
            this.loadTestDatasetRequest({});
        }
    }

    private handleFieldAdd(fieldStatus: FieldStatus, index: number): void {
        let fieldStatuses;
        const isDuplicate = this.state.fieldStatuses.findIndex((fs, i) => fs.field.key === fieldStatus.field.key && fs.isSelected && index !== i) !== -1;
        if (isDuplicate) {
            fieldStatuses = update(this.state.fieldStatuses, {
                [index]: {
                    isDeleted: { $set: true }
                }
            });
        } else {
            fieldStatuses = update(this.state.fieldStatuses, {
                [index]: {
                    isSelected: { $set: true },
                    isDeleted: { $set: false }
                }
            });
        }
        this.setState({ fieldStatuses: fieldStatuses });
        const schema = this.generateSchema(fieldStatuses);
        this.props.onChange(schema);
    }

    private handleFieldRemove(fieldStatus: FieldStatus, index: number): void {
        let fieldStatuses;
        // This is a schema field, mark the field as not selected. User can re-select to add back.
        fieldStatuses = update(this.state.fieldStatuses, {
            [index]: {
                isSelected: { $set: false },
                isDeleted: { $set: false }
            }
        });
        this.setState({ fieldStatuses: fieldStatuses });
        const schema = this.generateSchema(fieldStatuses);
        this.props.onChange(schema);
    }

    private handleFieldChange(fieldStatus: FieldStatus, index: number): void {
        // Check if the key changed. If so, make sure that the key is unique.
        if (this.state.fieldStatuses[index].field.key !== fieldStatus.field.key) {
            // Check if field key is unique.
            const isDuplicate = this.state.fieldStatuses.findIndex((fs, i) => fs.field.key === fieldStatus.field.key && fs.isSelected && index !== i) !== -1;
            if (isDuplicate) {
                // If this key is a duplicate, mark it deleted so it is not added to the schema.
                // Duplicate keys must not be added to the schema.
                fieldStatus = update(fieldStatus, {
                    isDeleted: { $set: true }
                });
            } else if (fieldStatus.isDeleted) {
                // If the key was deleted, mark it not deleted.
                fieldStatus = update(fieldStatus, {
                    isDeleted: { $set: false }
                });
            }
        }
        const fieldStatuses = update(this.state.fieldStatuses, {
            [index]: { $set: fieldStatus }
        });
        this.setState({ fieldStatuses: fieldStatuses });
        const schema = this.generateSchema(fieldStatuses);
        this.props.onChange(schema);
    }

    private handleFieldMoveUp(fieldStatus: FieldStatus, index: number): void {
        const fieldStatuses = update(this.state.fieldStatuses, {
            $splice: [[index, 1], [index - 1, 0, fieldStatus]]
        });
        this.setState({ fieldStatuses: fieldStatuses });
        const schema = this.generateSchema(fieldStatuses);
        this.props.onChange(schema);
    }

    private handleFieldMoveDown(fieldStatus: FieldStatus, index: number): void {
        const fieldStatuses = update(this.state.fieldStatuses, {
            $splice: [[index, 1], [index + 1, 0, fieldStatus]]
        });
        this.setState({ fieldStatuses: fieldStatuses });
        const schema = this.generateSchema(fieldStatuses);
        this.props.onChange(schema);
    }

    private handlePreviewClose(): void {
        this.setState({ previewDataset: null });
    }

    private handleSelectToggle(e: CheckboxChangeEvent): void {
        const isSelected = e.target.checked;
        let fieldStatuses: FieldStatus[] = this.state.fieldStatuses;
        for (let i = 0; i < this.state.fieldStatuses.length; i++) {
            if (this.state.fieldStatuses[i].isSelected !== isSelected) {
                fieldStatuses = update(fieldStatuses, {
                    [i]: {
                        isSelected: { $set: isSelected }
                    }
                });
            }
        }
        this.setState({ fieldStatuses: fieldStatuses });
    }

    private loadTestDatasetRequest(configuration: Configuration): Promise<any> {
        this.setState({
            isDatasetLoading: true,
            showDatasetQuery: false,
            error: null
        });
        const request = {
            components: this.props.components,
            configurationSpecs: this.props.configurationSpecs,
            configuration: configuration,
            schema: this.props.schema
        };
        return endpointService.loadTestQueryDataset(request,
            (response: any) => this.loadTestDatasetResponse(response),
            (response: any) => this.loadTestDatasetException(response),
            true
        );
    }

    private loadTestDatasetResponse(response: any): void {
        const previewDataset = response.data.dataset;
        this.setState({
            isDatasetLoading: false,
            previewDataset: previewDataset,
            error: null
        });
    }

    private loadTestDatasetException(response: any): void {
        const error = RestUtils.getError(response);
        this.setState({
            isDatasetLoading: false,
            error: error
        });
    }

    private buildFieldStatuses(schema: Schema): FieldStatus[] {
        const statuses = this.state.fieldStatuses;
        const statusMap: StatusMap = {};
        const fieldMap: FieldMap = {};
        const fieldStatuses: FieldStatus[] = [];
        statuses.forEach(state => statusMap[state.field.reference] = state);
        schema.fields.forEach(field => fieldMap[field.reference] = field);

        statuses && statuses.forEach(status => {
            // Check if the field is still in the schema. If not, set to deleted.
            let field = fieldMap[status.field.reference];
            if (!field) {
                status = update(status, {
                    isDeleted: { $set: true }
                });
            }
            // Else if field still exists, leave as-is since the user may have changed it.
            fieldStatuses.push(status);
        });

        const isSelected = statuses.length === 0;
        schema.fields.forEach((field, index) => {
            let fieldStatus = statusMap[field.reference];
            // Check if a new field is in the schema. If so, add it as unselected,
            // so that the user can add it if they wish.
            if (!fieldStatus) {
                fieldStatus = {
                    field: field,
                    isSelected: isSelected,
                    isDeleted: false
                }
                //fieldStatuses.push(fieldStatus);
                fieldStatuses.splice(index, 0, fieldStatus);
            }
        });

        return fieldStatuses;
    }

    private generateSchema(fieldStatuses: FieldStatus[]): Schema {
        const fields = fieldStatuses.filter(state => state.isSelected && !state.isDeleted).map(state => state.field);
        const schema = update(this.props.schema, {
            fields: { $set: fields }
        });
        return schema;
    }

    private loadTestSchemaRequest(configuration: Configuration): Promise<any> {
        this.setState({
            isSchemaLoading: true,
            showSchemaQuery: false,
            error: null
        });
        const request = {
            components: this.props.components,
            configurationSpecs: this.props.configurationSpecs,
            configuration: configuration
        };
        return endpointService.loadTestSchema(request,
            (response: any) => this.loadTestSchemaResponse(response),
            (response: any) => this.loadTestSchemaException(response),
            true
        );
    }

    private loadTestSchemaResponse(response: any): void {
        let schema = response.data.schema;
        this.setState({
            isSchemaLoading: false,
            error: null
        });
        const fieldStates = this.buildFieldStatuses(schema);
        this.setState({ fieldStatuses: fieldStates });
        schema = this.generateSchema(fieldStates);
        this.props.onChange(schema);
    }

    private loadTestSchemaException(response: any): void {
        const error = RestUtils.getError(response);
        this.setState({
            isSchemaLoading: false,
            error: error
        });
    }

    private allSelected(): boolean {
        const total = this.state.fieldStatuses.reduce((sum, state) => state.isSelected ? sum + 1 : sum, 0);
        return total === this.state.fieldStatuses.length;
    }

    public componentDidMount(): void {
        const schema = this.props.schema;
        if (schema.fields.length > 0) {
            const fieldStates = this.buildFieldStatuses(schema);
            this.setState({ fieldStatuses: fieldStates });
        }
    }

    public render(): ReactElement {
        return (
            <div className={classNames('x-schemaeditor', this.props.className)}>
                <div>
                    {this.state.fieldStatuses.length > 0 &&
                        <>
                            <Row className="x-schemaeditor-hdr" gutter={8}>
                                <Col span={5}>
                                    <Spacer>
                                        <Checkbox
                                            checked={this.allSelected()}
                                            onChange={this.handleSelectToggle}
                                        />
                                        <Label
                                            placement="top"
                                            label="Key"
                                            info="The key of the dataset field. Must be unique."
                                            required={true}
                                        />
                                    </Spacer>
                                </Col>
                                <Col span={4}>
                                    <Label
                                        placement="top"
                                        label="Type"
                                        info="The type of the dataset field."
                                        required={true}
                                    />
                                </Col>
                                <Col span={5}>
                                    <Label
                                        placement="top"
                                        label="Name"
                                        info="The name of the dataset field."
                                        required={true}
                                    />
                                </Col>
                                <Col span={10}>
                                    <Label
                                        placement="top"
                                        label="Description"
                                        info="The description of the dataset field."
                                    />
                                </Col>
                            </Row>
                            <Row gutter={[8, 8]}>
                                {this.state.fieldStatuses.map((state, index) => (
                                    <FieldItem
                                        formRef={this.props.formRef}
                                        key={index}
                                        fieldState={state}
                                        index={index}
                                        size={this.state.fieldStatuses.length}
                                        onAdd={this.handleFieldAdd}
                                        onRemove={this.handleFieldRemove}
                                        onChange={this.handleFieldChange}
                                        onMoveUp={this.handleFieldMoveUp}
                                        onMoveDown={this.handleFieldMoveDown}
                                    />
                                ))}
                            </Row>
                        </>
                    }
                    {this.state.fieldStatuses.length === 0 &&
                        <NoData
                            className="x-schemaeditor-none"
                            text="No fields."
                        />
                    }
                    {(this.state.showSchemaQuery || this.state.showDatasetQuery) &&
                        <ConfigurationDialog
                            title="Input Variables"
                            configuration={this.props.configuration}
                            configurationSpecs={this.props.configurationSpecs}
                            authentications={this.props.authentications}
                            optionalLabel="Optional Variables"
                            onChange={this.handleQueryExecute}
                            onCancel={this.handleQueryCancel}
                        />
                    }
                    {this.state.previewDataset &&
                        <DataPreview
                            data={{ type: IoType.DATASET, value: this.state.previewDataset }}
                            onClose={this.handlePreviewClose}
                        />
                    }
                </div>
                <Row className="x-schemaeditor-actions" justify="start" align="top">
                    <Space>
                        <Button
                            loading={this.state.isSchemaLoading}
                            disabled={this.state.isDatasetLoading}
                            onClick={this.handleSchemaLoad}
                        >
                            {this.props.schema.fields.length === 0 ? "Load Schema" : "Sync Schema"}
                        </Button>
                        <Button
                            loading={this.state.isDatasetLoading}
                            disabled={this.state.isSchemaLoading || this.props.schema.fields.length === 0}
                            onClick={this.handleDataPreview}
                        >
                            Preview Dataset
                        </Button>
                        {this.state.error &&
                            <div className="x-schemaeditor-error">{this.state.error.message}</div>
                        }
                    </Space>
                </Row>
            </div>
        );
    }

}
