import { ReactElement } from 'react';
import { Spacer } from 'components/Spacer/Spacer';
import './Tutorial.less';

export type TutorialProps = {
    title: string,
    description: string,
    url: string,
    duration?: string,
    date?: string
}

export const Tutorial = (props: TutorialProps): ReactElement => {

    const fullscreenUrl = (url: string): string => {
        url = url.replace("/embed/", "/watch_popup?v=");
        return `${url}&autoplay=1`;
    }

    return (
        <div>
            <Spacer className="x-tutorial" alignment="top" size="lg">
                <a className="x-tutorial-wrapper" href={fullscreenUrl(props.url)} target="_blank">
                    <iframe
                        className="x-tutorial-iframe"
                        src={props.url}
                        title={props.title}
                    >
                    </iframe>
                    <div className="x-tutorial-overlay"></div>
                </a>
                <div>
                    <Spacer>
                        <div className="x-tutorial-title">{props.title}</div>
                        {props.duration &&
                            <div className="x-tutorial-duration">({props.duration})</div>
                        }
                    </Spacer>
                    {props.date &&
                        <div className="x-tutorial-date">{props.date}</div>
                    }
                    <div className="x-tutorial-description">{props.description}</div>
                </div>
            </Spacer>
        </div>

    );

}

