import { ReactElement, useContext, useEffect, useState } from 'react';
import { FormInstance, Input, Select, Timeline } from 'antd';
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { PackHeader, PackType } from '@methodset/library-client-ts';
import { StatusType } from 'constants/StatusType';
import { CoreUtils } from 'utils/CoreUtils';
import { AccessType, EnvironmentType } from '@methodset/commons-core-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { ArrowRightOutlined } from '@ant-design/icons';
import { FormItem } from 'components/FormItem/FormItem';
import { Alert } from 'components/Alert/Alert';
import { EntityContext } from 'context/EntityContext';
import entityService from 'services/EntityService';
import libraryService from 'services/LibraryService';
import axios from 'axios';
import './PackReleaser.less';

const colors: Map<EnvironmentType, string> = new Map([
    [EnvironmentType.DEVELOPMENT, "blue"],
    [EnvironmentType.TEST, "green"],
    [EnvironmentType.PRODUCTION, "purple"]
]);

export type ChangeCallback = (type: EnvironmentType) => void;

export type PackReleaserProps = {
    formRef: React.RefObject<FormInstance>,
    packHeader: PackHeader,
    onChange: ChangeCallback
}

export const PackReleaser = (props: PackReleaserProps): ReactElement => {

    // Status of loading data.
    const [status, setStatus] = useState<string>(StatusType.INIT);
    // The pack headers for each environment for the pack id.
    const [packHeaders, setPackHeaders] = useState<PackHeader[]>([]);
    // The environment types that the organization has setup.
    const [environmentTypes, setEnvironmentTypes] = useState<EnvironmentType[]>([]);
    // The environment type to release from.
    const [sourceEnvironmentType, setSourceEnvironmentType] = useState<EnvironmentType | undefined>();
    // The environment type to release to.
    const [targetEnvironmentType, setTargetEnvironmentType] = useState<EnvironmentType | undefined>();

    const context = useContext(EntityContext);

    useEffect(() => {
        if (status !== StatusType.READY) {
            setSourceEnvironmentType(context.user?.environmentType);
            loadData();
        }
    }, []);

    const handleRetryLoad = (): void => {
        loadData();
    }

    const handleToChange = (environmentType: EnvironmentType): void => {
        setTargetEnvironmentType(environmentType);
        props.onChange(environmentType!);
    }

    const readPackReleasesRequest = (): Promise<any> => {
        const request = {
            packId: props.packHeader.id,
            asAccess: false
        }
        return libraryService.readPackReleases(request,
            (response: any) => readPackReleasesResponse(response),
            undefined, true
        );
    }

    const readPackReleasesResponse = (response: any): void => {
        const headers = response.data.headers;
        setPackHeaders(headers);
    }

    const readEnvironmentTypesRequest = (): Promise<any> => {
        const request = {}
        return entityService.readEnvironmentTypes(request,
            (response: any) => readEnvironmentTypesResponse(response),
            undefined, true
        );
    }

    const readEnvironmentTypesResponse = (response: any): void => {
        const types = response.data.types;
        setEnvironmentTypes(types);
    }

    const findEnvironmentVersion = (type: EnvironmentType | undefined): number | undefined => {
        if (!type) {
            return undefined;
        }
        const header = packHeaders.find(header => header.environmentType === type);
        return !!header ? header.version : undefined;
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        return (
            <LoadSkeleton
                count={3}
                status={isLoading ? "loading" : "failed"}
                onRetry={() => handleRetryLoad()}
            >
                <LoadSkeleton.Checkbox />
                <LoadSkeleton.Input length="fill" />
            </LoadSkeleton>
        );
    }

    const isValid = (): boolean => {
        const sourceVersion = findEnvironmentVersion(sourceEnvironmentType);
        const targetVersion = findEnvironmentVersion(targetEnvironmentType);
        if (CoreUtils.isEmpty(sourceVersion) || CoreUtils.isEmpty(targetVersion)) {
            return true;
        }
        return sourceVersion! > targetVersion!;
    }

    const buildReleaseView = (): ReactElement => {
        const isInvalid = !isValid();
        const sourceLevel = EnvironmentType.level(sourceEnvironmentType!);
        return (
            <div>
                <Timeline className="x-packreleaser-timeline">
                    {environmentTypes.map(type => (
                        <Timeline.Item
                            key={type}
                            color={colors.get(type)}
                        >
                            <span className="x-packreleaser-env">{CoreUtils.toProper(type)}</span>
                            &nbsp;
                            <span className="x-packreleaser-version">({CoreUtils.toVersion(findEnvironmentVersion(type), "release")})</span>
                            {/* {type !== EnvironmentType.DEVELOPMENT &&
                                <span className="x-packreleaser-version"> ({CoreUtils.toVersion(findEnvironmentVersion(type), "release")})</span>
                            } */}
                        </Timeline.Item>
                    ))}
                </Timeline>
                <Spacer fill>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={props.formRef}
                        className="x-packreleaser-drop"
                        label="Source"
                        name="source"
                        info="The source environment where the package will be taken from."
                        rules={[{
                            required: true,
                            message: "Enter a source."
                        }]}
                    >
                        <Input value={CoreUtils.toProper(sourceEnvironmentType)} onFocus={(e) => { e.target.blur() }} />
                    </FormItem>
                    <ArrowRightOutlined className="x-packreleaser-arrow" />
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={props.formRef}
                        className="x-packreleaser-drop"
                        label="Target"
                        name="target"
                        info="The target environment where the package will be release to."
                        rules={[{
                            required: true,
                            message: "Enter a target."
                        }]}
                    >
                        <Select
                            className="x-packreleaser-select"
                            value={targetEnvironmentType}
                            onChange={handleToChange}
                        >
                            {environmentTypes.filter(type => EnvironmentType.level(type) > sourceLevel).map(type => (
                                <Select.Option key={type} value={type}>{CoreUtils.toProper(type)}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                </Spacer>
                {sourceEnvironmentType && targetEnvironmentType &&
                    <>
                        {isInvalid &&
                            <Alert type="warn">
                                {CoreUtils.toProper(targetEnvironmentType)} version {findEnvironmentVersion(sourceEnvironmentType)} has already been released.
                            </Alert>
                        }
                        {!isInvalid &&
                            <Alert type="info">
                                Ready to release {CoreUtils.toLower(sourceEnvironmentType)} version {findEnvironmentVersion(sourceEnvironmentType)} to
                                the {targetEnvironmentType === EnvironmentType.PUBLIC ? "public." : `${CoreUtils.toLower(targetEnvironmentType)} environment.`}
                            </Alert>
                        }
                    </>
                }
            </div>
        )
    }

    const loadData = (): void => {
        const requests = [];
        requests.push(readPackReleasesRequest());
        requests.push(readEnvironmentTypesRequest())
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((r1, r2) => {
            if (RestUtils.isOk(r1, r2)) {
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
    }

    let view;
    if (status === StatusType.LOADING) {
        view = buildLoadingView(true);
    } else if (status === StatusType.FAILED) {
        view = buildLoadingView(false);
    } else if (status === StatusType.READY) {
        view = buildReleaseView();
    }
    return (
        <div className="x-packreleaser">
            {view}
        </div>
    );

}
