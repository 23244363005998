import { ReactElement } from 'react';
import { Spacer } from 'components/Spacer/Spacer';
import { Widget } from '@methodset/application-client-ts';
import { ItemSpec, MenuButton } from 'components/MenuButton/MenuButton';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Globals } from 'constants/Globals';
import './ItemMenu.less';

export type WidgetCallback = (widget: Widget) => void;

export type ItemMenuProps = {
    className?: string,
    widget: Widget,
    extra?: ItemSpec[],
    onEdit: WidgetCallback,
    onRemove: WidgetCallback
};

export const ItemMenu = (props: ItemMenuProps): ReactElement => {

    const items = (): ItemSpec[] => {
        const items: ItemSpec[] = [{
            icon: <EditOutlined />,
            label: "Edit widget",
            onSelect: props.onEdit
        }];
        if (props.extra) {
            items.push(...props.extra);
        }
        items.push({
            icon: <DeleteOutlined />,
            label: "Remove widget",
            confirm: "Are you sure you want to remove the widget?",
            onSelect: props.onRemove
        });
        return items;
    }

    return (
        <Spacer>
            <MenuButton
                items={items()}
                data={props.widget}
                size={Globals.APPLET_MENU_SIZE}
                shape={Globals.APPLET_MENU_SHAPE}
            />
        </Spacer>
    )
}

