import { ReactElement, useState } from 'react';
import { Button, Checkbox, Modal } from 'antd';
import { Data, IoType } from '@methodset/endpoint-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { DataViewer } from './DataViewer/DataViewer';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import './DataPreview.less';

export type CloseCallback = () => void;

export type DataPreviewProps = {
    data: Data,
    onClose: CloseCallback
}

export const DataPreview = (props: DataPreviewProps): ReactElement => {

    const [showNull, setShowNull] = useState<any>(true);

    const handleNullChange = (e: CheckboxChangeEvent): void => {
        setShowNull(e.target.checked);
    }

    const isGrid = (): boolean => {
        return props.data.type === IoType.RECORDS || props.data.type === IoType.DATASET;
    }

    return (
        <Modal
            className="x-datapreview"
            centered
            title="Data Preview"
            visible={true}
            onCancel={props.onClose}
            footer={(
                <Spacer justification={isGrid() ? "between" : "right"}>
                    {isGrid() &&
                        <Checkbox
                            checked={showNull}
                            onChange={handleNullChange}
                        >
                            Show NULL values
                        </Checkbox>
                    }
                    <Button type="primary" onClick={props.onClose}>Close</Button>
                </Spacer>
            )}
        >
            <DataViewer
                className="x-datapreview-data"
                data={props.data}
                showNull={showNull}
                enableRaw={true}
            />
        </Modal>
    );

}
