import { FC, ReactElement } from "react"
import { Button, Select } from "antd";
import { Range } from '@methodset/calculator-ts';
import { Label } from "components/Label/Label";
import { SpecContent } from "../SpecContent/SpecContent";
import update from 'immutability-helper';
import './FilterEditor.less';

export enum FilterIndex {
    COLUMN_INDEX = 0,
    VALUES = 1
}

export type FilterSpec = [ number, any[] ];

export type ChangeCallback = (spec: FilterSpec) => void;
export type DeleteCallback = (spec: FilterSpec) => void;

type Props = {
    header: any,
    spec: FilterSpec
    range: Range
    onChange: ChangeCallback,
    onDelete: DeleteCallback
}

export const FilterEditor: FC<Props> = (props: Props): ReactElement => {

    const handleValuesChange = (values: any): void => {
        const spec = update(props.spec, {
            [FilterIndex.VALUES]: { $set: values }
        });
        props.onChange(spec);
    }

    const handleSpecDelete = (): void => {
        props.onDelete(props.spec);
    }

    const handleAllClick = (): void => {
        const values = props.range.uniqueValues(props.spec[FilterIndex.COLUMN_INDEX]);
        const spec = update(props.spec, {
            [FilterIndex.VALUES]: { $set: values }
        });
        props.onChange(spec);
    }

    return (
        <SpecContent
            header={props.header}
            onDelete={handleSpecDelete}
        >
            <div className="x-filtereditor-all">
                <Button
                    type="link"
                    onClick={handleAllClick}
                >
                    Select All
                </Button>
            </div>
            <Label
                label="Values"
                placement="left"
                bold={false}
            >
                <Select
                    className="x-filtereditor-values"
                    mode="multiple"
                    allowClear={true}
                    value={props.spec[FilterIndex.VALUES]}
                    onChange={handleValuesChange}
                >
                    {props.range.uniqueValues(props.spec[FilterIndex.COLUMN_INDEX]).map(value => (
                        <Select.Option key={value} value={value}>{value}</Select.Option>
                    ))}
                </Select>
            </Label>
        </SpecContent>
    )

}
