import { PureComponent, ReactElement } from 'react';
import { Select } from 'antd';
import { Spacer } from 'components/Spacer/Spacer';
import { WidgetUtils } from 'utils/WidgetUtils';
import { UsedMap } from '../../InputLinks';
import { ItemMap } from 'containers/Console/Dashboards/DashboardItem/DashboardItem';
import { InputKey } from '@methodset/application-client-ts';
import update from 'immutability-helper';
import './InputKeyEditor.less';
import { CoreUtils } from 'utils/CoreUtils';

export type ChangeCallback = (inputKey: InputKey, index: number) => void;

export type InputKeyEditorProps = typeof InputKeyEditor.defaultProps & {
    index: number,
    itemMap: ItemMap,
    inputKey: InputKey,
    usedVariables: UsedMap,
    onChange: ChangeCallback
}

export class InputKeyEditor extends PureComponent<InputKeyEditorProps> {

    static defaultProps = {
        index: 0
    }

    constructor(props: InputKeyEditorProps) {
        super(props);
        this.handleAppletChange = this.handleAppletChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    private handleAppletChange(key: string): void {
        const [installationId, version, variableId] = key.split(":");
        const inputKey = update(this.props.inputKey, {
            installationId: { $set: installationId },
            specKey: { $set: undefined as any }
        });
        this.props.onChange(inputKey, this.props.index);
    }

    private handleInputChange(specKey: string): void {
        const inputKey = update(this.props.inputKey, {
            specKey: { $set: specKey }
        });
        this.props.onChange(inputKey, this.props.index);
    }

    private buildApplets(): ReactElement[] | null {
        const options = [];
        let entries = Object.entries(this.props.itemMap);
        entries = entries.sort(([k1, v1], [k2, v2]) => CoreUtils.compareStrings(v1.applet.name, v2.applet.name));
        for (const [key, item] of entries) {
            const applet = item.applet;
            const calculator = item.calculator;
            if (calculator.variables.length > 0) {
                const option = (
                    <Select.Option
                        key={key}
                        value={key}
                    >
                        {applet.name}
                    </Select.Option>
                );
                options.push(option);
            }
        }
        return options;
    }

    private buildInputs(inputKey: InputKey): ReactElement[] | null {
        const installationId = inputKey.installationId;
        if (!installationId) {
            return null;
        }
        const item = this.props.itemMap[inputKey.installationId];
        const calculator = item.calculator;
        const variables = calculator.variables;
        const options: ReactElement[] = [];
        variables.forEach(variable => {
            if (!variable.isInternal) {
                const option = (
                    <Select.Option
                        key={variable.id}
                        value={variable.id}
                        disabled={this.isVariableUsed(inputKey, variable.id)}
                    >
                        {variable.name}
                    </Select.Option>
                )
                options.push(option);
            }
        });
        return options;
    }

    private isVariableUsed(inputKey: InputKey, variableId: string): boolean {
        const key = WidgetUtils.toKey(inputKey.installationId, 0, variableId);
        return !!this.props.usedVariables[key];
    }

    public render(): ReactElement {
        return (
            <Spacer direction="vertical" fill={true}>
                <Select
                    className="x-inputkeyeditor-fill"
                    placeholder="Select an applet."
                    value={this.props.inputKey.installationId}
                    onChange={this.handleAppletChange}
                >
                    {this.buildApplets()}
                </Select>
                <Select
                    className="x-inputkeyeditor-fill"
                    placeholder="Select an input."
                    value={this.props.inputKey.specKey}
                    onChange={this.handleInputChange}
                >
                    {this.buildInputs(this.props.inputKey)}
                </Select>
            </Spacer>
        );
    }

}
