import React, { PureComponent, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Input, PageHeader, Row, Space, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Options, RestUtils } from 'utils/RestUtils';
import { QueryBuilder } from 'containers/Console/Models/ModelItem/ModelCalculator/Queries/QueryDialog/QueryBuilder/QueryBuilder';
import { CoreUtils } from 'utils/CoreUtils';
import { Configuration, FormatType, Query } from '@methodset/endpoint-client-ts';
import { Globals } from 'constants/Globals';
import { Label } from 'components/Label/Label';
import { Pack } from '@methodset/library-client-ts';
import endpointService from 'services/EndpointService';
import classNames from 'classnames';
import './ApiItem.less';

type MatchParams = {
    queryId: string,
    version: string
}

export type ApiItemProps = RouteComponentProps<MatchParams> & {
    className?: string
}

export type ApiItemState = {
    errorMessage?: string,
    isSubmitting: boolean,
    isLoading: boolean,
    dataset?: any,
    url?: string,
    format?: string,
    query: Query
}

export class ApiItem extends PureComponent<ApiItemProps, ApiItemState> {

    private builderRef = React.createRef<QueryBuilder>();
    private queryId: string;
    private version: number;

    constructor(props: ApiItemProps) {
        super(props);
        this.state = {
            errorMessage: undefined,
            isSubmitting: false,
            dataset: undefined,
            isLoading: false,
            query: {} as Query
        };
        this.queryId = this.props.match.params.queryId;
        this.version = parseInt(this.props.match.params.version);
        this.handleQueryLoad = this.handleQueryLoad.bind(this);
        this.handleQueryChange = this.handleQueryChange.bind(this);
        this.handleQueryTouch = this.handleQueryTouch.bind(this);
        this.handleLoadClick = this.handleLoadClick.bind(this);
        this.handleGenerateClick = this.handleGenerateClick.bind(this);
    }

    private handleQueryLoad(query: Query): void {
        this.setState({ query: query });
    }

    // TODO: change to use new query syntax
    //private handleQueryChange(item: QueryItem, configuration: Configuration, options: Options): void {
    private handleQueryChange(query: Query, pack: Pack, configuration: Configuration, options: Options): void {
            const id = `${this.state.query.serviceName}/${this.state.query.methodName}`;
        const configPart = this.generateConfigPart(configuration);
        const optionsPart = RestUtils.buildQuery(options, true, false);
        const path = `datasets/${id}/versions/${query.version}${configPart}${configPart ? ";" : "?"}${optionsPart}`;
        const url = CoreUtils.apiUrl('endpoint-service', 14011, 1, path);
        this.setState({
            url: url,
            format: options["format"]
        });
    }

    private generateConfigPart(configuration: Configuration): string {
        const entries = Object.entries(configuration);
        if (entries.length === 0) {
            return "";
        }
        // TODO: change to use new query syntax
        let params = ""
        let index = 0;
        for (const [key, data] of entries) {
            const value = data.formula ? data.formula : data.value;
            const parameter = `${key}:${encodeURIComponent(value)}`;
            params += `${index++ > 0 ? "," : ""}${parameter}`;
        }
        params = encodeURIComponent(params);
        return `?params=${params}`;
    }

    private handleQueryTouch(): void {
        this.setState({
            dataset: undefined,
            url: undefined,
            format: undefined,
            errorMessage: undefined
        });
    }

    private handleLoadClick(): void {
        if (this.state.url) {
            this.readDatasetRequest(this.state.url);
        }
    }

    private handleGenerateClick(): void {
        this.builderRef.current?.submit();
    }

    private readDatasetRequest(url: string): Promise<any> {
        this.setState({
            errorMessage: undefined,
            isLoading: true
        });
        const request = {
            url: url
        };
        return endpointService.readDataset(request,
            (response: any) => this.readDatasetResponse(response),
            (response: any) => this.readDatasetException(response),
            true
        );
    }

    private readDatasetResponse(response: any): void {
        this.setState({
            errorMessage: undefined,
            isLoading: false,
            dataset: response.data
        });
    }

    private readDatasetException(response: any): void {
        this.setState({
            isLoading: false,
            errorMessage: RestUtils.getErrorMessage(response)
        });
    }

    public render(): ReactElement {
        return (
            <div className={classNames('x-apiitem', this.props.className)}>
                <PageHeader
                    className="x-apiitem-header"
                    title="API"
                    subTitle={this.state.query?.name}
                    onBack={() => window.history.back()}
                />
                <Row className="x-apiitem-body" gutter={[Globals.FORM_GUTTER_COL, 0]}>
                    <Col span={9}>
                        <QueryBuilder
                            className="x-apiitem-query"
                            ref={this.builderRef}
                            result="url"
                            queryId={this.queryId}
                            version={this.version}
                            excludes={["id"]}
                            timeSyntax="simple"
                            allowExpressions={false}
                            onChange={this.handleQueryChange}
                            onLoad={this.handleQueryLoad}
                            onTouch={this.handleQueryTouch}
                        />
                        <div className="x-apiitem-generate">
                            <Button type="primary" onClick={this.handleGenerateClick}>
                                Generate URL
                            </Button>
                        </div>
                    </Col>
                    <Col span={15}>
                        <Label
                            placement="top"
                            // alignment="top"
                            label="Generated URL"
                        >
                            <Input.TextArea
                                placeholder="Fill in the dataset query values to generate the URL."
                                rows={6}
                                value={this.state.url}
                                onKeyDown={(e) => e.preventDefault()}
                            />
                        </Label>
                        <div className="x-apiitem-error">{this.state.errorMessage}</div>
                        <Space className="x-apiitem-actions">
                            <Button
                                className="x-apiitem-load"
                                loading={this.state.isLoading}
                                disabled={!this.state.url}
                                onClick={this.handleLoadClick}
                            >
                                Test URL
                            </Button>
                            <CopyToClipboard text={this.state.url ? this.state.url : ""}
                                onCopy={() => message.success("URL Copied!", 2)}
                            >
                                <Button disabled={!this.state.url}>
                                    Copy URL
                                </Button>
                            </CopyToClipboard>
                        </Space>
                        {(this.state.format === FormatType.JSON.toLowerCase() || this.state.format === FormatType.EXCEL_JSON.toLowerCase()) &&
                            <div><pre>{JSON.stringify(this.state.dataset, null, 4)}</pre></div>
                        }
                        {this.state.format === FormatType.CSV.toLowerCase() &&
                            <div><pre>{this.state.dataset}</pre></div>
                        }
                    </Col>
                </Row>
            </div>
        );
    }

}
