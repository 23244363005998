import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { MailOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, Space } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { FormFrame } from 'components/FormFrame/FormFrame';
import { RouteBuilder } from 'utils/RouteBuilder';
import { RouteComponentProps } from 'react-router-dom';
import { RestUtils } from 'utils/RestUtils';
import authService from 'services/AuthService';
import update from 'immutability-helper';
import './ChangeEmail.less';

interface FormData {
    emailAddress?: string
}

export type ChangeEmailState = {
    error?: Error,
    isSubmitting: boolean,
    formData: FormData
}

export type ChangeEmailProps = RouteComponentProps & {}

export class ChangeEmail extends PureComponent<ChangeEmailProps, ChangeEmailState> {

    private formRef = React.createRef<FormInstance>();

    constructor(props: ChangeEmailProps) {
        super(props);
        this.state = {
            error: undefined,
            isSubmitting: false,
            formData: {} as FormData
        };
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleFormFinish = this.handleFormFinish.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
    }

    private handleEmailChange(e: ChangeEvent<HTMLInputElement>) {
        const emailAddress = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                emailAddress: { $set: emailAddress }
            })
        });
    }

    private handleFormFinish(): void {
        this.changeEmailRequest();
    }

    private handleCancelClick(): void {
        this.props.history.goBack();
    }

    private changeEmailRequest(): void {
        this.setState({
            error: undefined,
            isSubmitting: true
        });
        const emailAddress = this.state.formData.emailAddress;
        const attributes = {
            email: emailAddress
        };
        authService.updateAttributes(attributes,
            (response: any) => this.changeEmailResponse(response),
            (error: Error) => this.changeEmailException(error)
        );
    }

    private changeEmailResponse(response: any): void {
        this.setState({
            error: undefined,
            isSubmitting: false
        });
        this.props.history.push(RouteBuilder.USER_CHANGE_EMAIL_CONFIRM);
        //this.props.history.push(RouteBuilder.user(this.props, RouteBuilder.USER_CHANGE_EMAIL_CONFIRM));
    }

    private changeEmailException(error: Error): void {
        this.setState({
            error: error,
            isSubmitting: false
        });
    }

    public render(): ReactElement {
        return (
            <FormFrame
                ref={this.formRef}
                title="Change Email"
                description="Please enter your new email and a confirmation code will be sent to you in
                    order to verify your address. You will then be asked to enter the confirmation code."
                error={this.state.error}
                isLoading={this.state.isSubmitting}
                okLabel="Update"
                onCancel={this.handleCancelClick}
                onOk={this.handleFormFinish}
            >
                <FormItem
                    formRef={this.formRef}
                    name="email"
                    rules={[{
                        required: true,
                        message: 'Please enter your new email.'
                    }, {
                        type: 'email',
                        message: 'The email address is invalid.'
                    }]}
                >
                    <Input
                        id="email"
                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="New email address."
                        size="large"
                        value={this.state.formData.emailAddress}
                        onChange={this.handleEmailChange}
                    />
                </FormItem>
            </FormFrame>
        );
    }

}
