import { ReactElement, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { DeleteOutlined, PlayCircleOutlined, ReadOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Modal, Space, message } from 'antd';
import { Globals } from 'constants/Globals';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { RouteBuilder } from 'utils/RouteBuilder';
import { ColumnsType } from 'antd/lib/table';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { StatusType } from 'constants/StatusType';
import { NotificationHeader } from '@methodset/application-client-ts';
import { NotificationItem } from './NotificationItem/NotificationItem';
import { ButtonLink } from 'components/ButtonLink/ButtonLink';
import axios from 'axios';
import classNames from 'classnames';
import applicationService from 'services/ApplicationService';
import update from 'immutability-helper';
import './Notifications.less';

interface PageState {
    page: number;
    startKey: string | undefined;
    reverseScan: boolean;
    isLast: boolean;
}

export type NotificationsProps = RouteComponentProps & {}

const RECORDS_PER_PAGE = 10;

const FIRST_PAGE_STATE = {
    page: 1,
    startKey: undefined,
    reverseScan: true,
    isLast: false
}

export const Notifications = (props: NotificationsProps): ReactElement => {

    const history = useHistory();
    // The loading status of headers.
    const [status, setStatus] = useState<StatusType>(StatusType.INIT);
    // The notification headers to display.
    const [headers, setHeaders] = useState<NotificationHeader[]>([]);
    // The notification header being worked on (read).
    const [header, setHeader] = useState<NotificationHeader | undefined>();
    // The current paginate state.
    const [pageState, setPageState] = useState<PageState>(FIRST_PAGE_STATE);

    useEffect(() => {
        if (status !== StatusType.READY) {
            loadData(pageState);
        }
    }, []);

    const handleRetryLoad = (): void => {
        loadData(pageState);
    }

    const handleRefreshClick = (): void => {
        loadData(pageState);
    }

    const handleAlertsEdit = (): void => {
        history.push(RouteBuilder.CONSOLE_ALERTS);
    }

    const handlePageChange = (nextPage: number): void => {
        if (headers.length === 0) {
            return;
        }
        if (nextPage > pageState.page && !pageState.isLast) {
            // Next page.
            const nextState = {
                page: nextPage,
                startKey: headers[headers.length - 1].id,
                reverseScan: true,
                isLast: false
            }
            readNotificationHeadersRequest(nextState);
        } else if (nextPage < pageState.page && pageState.page > 1) {
            // Previous page.
            const nextState = {
                page: nextPage,
                startKey: headers[0].id,
                reverseScan: false,
                isLast: false
            }
            readNotificationHeadersRequest(nextState);
        }
    }

    const handleNotificationShow = (header: NotificationHeader): void => {
        if (!header.isRead) {
            updateNotificationStateRequest(header, true);
        }
        setHeader(header);
    }

    const handleNotificationClose = (): void => {
        setHeader(undefined);
    }

    const handleNotificationUnread = (header: NotificationHeader): void => {
        updateNotificationStateRequest(header, false);
    }

    const handleNotificationDelete = (header: NotificationHeader): void => {
        deleteNotificationRequest(header);
    }

    const updateNotificationStateRequest = (header: NotificationHeader, isRead: boolean): Promise<any> => {
        const request = {
            notificationId: header.id,
            isRead: isRead
        }
        return applicationService.updateNotificationState(request,
            (response: any) => updateNotificationStateResponse(response),
            (response: any) => updateNotificationStateException(response),
            true
        );
    }

    const updateNotificationStateResponse = (response: any): void => {
        const notificationId = response.data.notificationId;
        const index = headers.findIndex(header => header.id === notificationId);
        if (index !== -1) {
            const isRead = response.data.isRead;
            const updated = update(headers, {
                [index]: {
                    isRead: { $set: isRead }
                }
            });
            setHeaders(updated);
        }
    }

    const updateNotificationStateException = (header: NotificationHeader): void => {
        message.error("Failed to update notification.");
    }

    const readNotificationHeadersRequest = (pageState: PageState): Promise<any> => {
        setStatus(StatusType.LOADING);
        const request = {
            startKey: pageState.startKey,
            maxRecords: RECORDS_PER_PAGE + 1,
            reverseScan: pageState.reverseScan
        };
        return applicationService.readNotificationHeaders(request,
            (response: any) => readNotificationHeadersResponse(response, pageState),
            (response: any) => readNotificationHeadersException(response),
            true
        );
    }

    const readNotificationHeadersResponse = (response: any, state: PageState): void => {
        const headers = response.data.headers;
        if (headers.length === 0) {
            const updated = update(state, {
                isLast: { $set: true }
            });
            setPageState(updated);
            if (state.page === 1) {
                setHeaders(headers);
            }
        } else {
            let updated = state;
            if (state.page === 1) {
                updated = FIRST_PAGE_STATE;
            }
            // if (headers.length <= RECORDS_PER_PAGE) {
            //     updated = update(state, {
            //         isLast: { $set: true }
            //     });
            // }
            updated = update(state, {
                isLast: { $set: headers.length <= RECORDS_PER_PAGE }
            });
            setPageState(updated);
            setHeaders(headers);
        }
        setStatus(StatusType.READY);
    }

    const readNotificationHeadersException = (response: any): void => {
        setStatus(StatusType.FAILED);
    }

    const deleteNotificationRequest = (header: NotificationHeader): Promise<any> => {
        const request = {
            notificationId: header.id,
        };
        return applicationService.deleteNotification(request,
            (response: any) => deleteNotificationResponse(response),
            (response: any) => deleteNotificationException(response),
            true
        );
    }

    const deleteNotificationResponse = (response: any): void => {
        const notificationId = response.data.notificationId;
        const index = headers.findIndex(header => header.id === notificationId);
        if (index !== -1) {
            const updated = update(headers, {
                $splice: [[index, 1]]
            });
            setHeaders(updated);
        }
    }

    const deleteNotificationException = (response: any): void => {
        message.error("Failed to delete notification.");
    }

    const buildColumns = (): ColumnsType<any> => {
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            //width: Globals.TABLE_WIDTH_LARGE,
            render: (header) => {
                return (
                    <ButtonLink
                        className={classNames({ "x-notification-unread": !header.isRead })}
                        onClick={() => handleNotificationShow(header)}
                    >
                        {header.subject}
                    </ButtonLink>
                )
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.subject, b.subject),
            sortDirections: ['ascend', 'descend']
        }, {
            key: 'ctime',
            title: 'Create Time',
            width: Globals.TABLE_WIDTH_MEDIUM,
            align: 'center',
            render: (header) => {
                return (
                    <span className={classNames({ "x-notification-unread": !header.isRead })}>
                        {CoreUtils.toTime(header.createTime)}
                    </span>
                );
            },
            sorter: (a, b) => CoreUtils.compareNumbers(a.createTime, b.createTime),
            sortDirections: ['descend', 'ascend']
        }];
        return columns;
    }

    const buildData = (): any => {
        if (headers.length <= RECORDS_PER_PAGE) {
            // Return all values.
            return headers;
        } else if (pageState.reverseScan) {
            // Reverse scan, first entry is not shown.
            return update(headers, {
                $splice: [[headers.length - 1, 1]]
            });
        } else if (!pageState.reverseScan) {
            // Forward scan, last entry is not shown.
            return update(headers, {
                $splice: [[0, 1]]
            });
        }
        return headers;
    }

    const loadData = (pageState: PageState) => {
        const requests = [];
        requests.push(readNotificationHeadersRequest(pageState));
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
    }

    const columns = buildColumns();
    const data = buildData();
    const actions = [{
        icon: <PlayCircleOutlined />,
        label: "Open notification",
        callback: (header: NotificationHeader) => handleNotificationShow(header)
    }, {
        icon: <ReadOutlined />,
        label: "Mark as unread",
        disabled: (header: NotificationHeader) => !header.isRead,
        callback: (header: NotificationHeader) => handleNotificationUnread(header)
    }, {
        icon: <DeleteOutlined />,
        label: "Delete notification",
        callback: (header: NotificationHeader) => handleNotificationDelete(header)
    }];

    return (
        <>
            <ItemTable
                className="x-notifications"
                title="Notifications"
                status={status}
                columns={columns}
                showCount={false}
                items={data}
                pagination={{
                    page: pageState.page,
                    isLast: pageState.isLast,
                    onChange: handlePageChange
                }}
                actions={actions}
                extra={
                    <Space>
                        <Button onClick={handleAlertsEdit}>Alerts</Button>
                        <Button icon={<SyncOutlined />} onClick={handleRefreshClick}></Button>
                    </Space>
                }
                onLoad={handleRetryLoad}
            />
            {header &&
                <NotificationItem
                    header={header}
                    onClose={handleNotificationClose}
                />
            }
        </>
    );

}
