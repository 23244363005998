import { ReactElement, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Location } from 'history';
import { Menu } from 'antd';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Spacer } from 'components/Spacer/Spacer';
import './Documents.less';

export type SelectCallback = (url: string) => void;

export type DocumentProps = RouteComponentProps;

export const Documents = (props: DocumentProps): ReactElement => {

    const history = useHistory();
    const [key, setKey] = useState<string | undefined>();

    useEffect(() => {
        checkKey(history.location);
        const unregister = history.listen(checkKey);
        return () => {
            unregister();
        }
    }, []);

    const checkKey = (location: Location<any>): false | void => {
        setKey(findKey(location));
    }

    const findKey = (location: Location<any>): string | undefined => {
        const path = location.pathname;
        const expr = `${RouteBuilder.MAIN_DOCUMENTATION}/([^/]+)`;
        const regex = new RegExp(expr);
        const matches = path.match(regex);
        return !!matches ? matches[1] : undefined;
    }

    return (
        <Spacer className="x-documents" direction="vertical">
            <div className="x-documents-title">Documentation</div>
            <Menu
                mode="inline"
                selectedKeys={key ? [key] : undefined}
            >
                <Menu.Item
                    key="overview"
                    id="overview"
                    onClick={() => history.push(RouteBuilder.MAIN_DOCUMENTATION_OVERVIEW)}
                >
                    <span>Overview</span>
                </Menu.Item>
                {/* <Menu.Item
                    key="tutorials"
                    id="tutorials"
                    onClick={() => history.push(RouteBuilder.MAIN_DOCUMENTATION_TUTORIALS)}
                >
                    <span>Tutorials</span>
                </Menu.Item> */}
                <Menu.Item
                    key="functions"
                    id="functions"
                    onClick={() => history.push(RouteBuilder.MAIN_DOCUMENTATION_FUNCTIONS)}
                >
                    <span>Functions</span>
                </Menu.Item>
                <Menu.Item
                    key="versioning"
                    id="versioning"
                    onClick={() => history.push(RouteBuilder.MAIN_DOCUMENTATION_VERSIONING)}
                >
                    <span>Versioning</span>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                    key="models"
                    id="models"
                    onClick={() => history.push(RouteBuilder.MAIN_DOCUMENTATION_MODELS)}
                >
                    <span>Models</span>
                </Menu.Item>
                <Menu.Item
                    key="datasets"
                    id="datasets"
                    onClick={() => history.push(RouteBuilder.MAIN_DOCUMENTATION_DATASETS)}
                >
                    <span>Datasets</span>
                </Menu.Item>
                <Menu.Item
                    key="workflows"
                    id="workflows"
                    onClick={() => history.push(RouteBuilder.MAIN_DOCUMENTATION_WORKFLOWS)}
                >
                    <span>Workflows</span>
                </Menu.Item>
                <Menu.Item
                    key="dashboards"
                    id="dashboards"
                    onClick={() => history.push(RouteBuilder.MAIN_DOCUMENTATION_DASHBOARDS)}
                >
                    <span>Dashboards</span>
                </Menu.Item>
                <Menu.Item
                    key="apis"
                    id="apis"
                    onClick={() => history.push(RouteBuilder.MAIN_DOCUMENTATION_APIS)}
                >
                    <span>APIs</span>
                </Menu.Item>
                <Menu.Item
                    key="organizations"
                    id="organizations"
                    onClick={() => history.push(RouteBuilder.MAIN_DOCUMENTATION_ORGANIZATIONS)}
                >
                    <span>Organizations</span>
                </Menu.Item>
                <Menu.Item
                    key="groups"
                    id="groups"
                    onClick={() => history.push(RouteBuilder.MAIN_DOCUMENTATION_GROUPS)}
                >
                    <span>Groups</span>
                </Menu.Item>
                <Menu.Item
                    key="credentials"
                    id="credentials"
                    onClick={() => history.push(RouteBuilder.MAIN_DOCUMENTATION_CREDENTIALS)}
                >
                    <span>Credentials</span>
                </Menu.Item>
                <Menu.Item
                    key="providers"
                    id="providers"
                    onClick={() => history.push(RouteBuilder.MAIN_DOCUMENTATION_ACCOUNTS)}
                >
                    <span>Accounts</span>
                </Menu.Item>
            </Menu>
        </Spacer>
    );

}
