import { PureComponent, ReactElement } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Col, Row } from 'antd';
import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import { User } from './User/User';
import { PageNotFound } from 'containers/Components/PageNotFound/PageNotFound';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Demo } from './Home/Demo/Demo';
import { Services } from './Home/Services/Services';
import { Waitlist } from './Home/Waitlist/Waitlist';
import { Business } from './Home/Business/Business';
import { Personal } from './Home/Personal/Personal';
import { Documentation } from './Documentation/Documentation';
import './Main.less';

export type MainProps = RouteComponentProps;

export class Main extends PureComponent<MainProps> {

    public render(): ReactElement {
        return (
            <div className="x-main">
                <Row>
                    <Col
                        xxl={{ span: 16, offset: 4 }}
                        xl={{ span: 20, offset: 2 }}
                        xs={{ span: 24, offset: 0 }}
                    >
                        <Header {...this.props} />
                        <Switch>
                            <Route exact path={RouteBuilder.HOME} component={Business} />
                            <Route exact path={RouteBuilder.MAIN_BUSINESS} component={Business} />
                            <Route exact path={RouteBuilder.MAIN_PERSONAL} component={Personal} />
                            <Route exact path={RouteBuilder.MAIN_SERVICES} component={Services} />
                            <Route exact path={RouteBuilder.MAIN_WAITLIST} component={Waitlist} />
                            <Route exact path={RouteBuilder.MAIN_DEMO} component={Demo} />
                            <Route path={RouteBuilder.MAIN_DOCUMENTATION} component={Documentation} />
                            <Route path={RouteBuilder.MAIN_USER} component={User} />
                            <Route component={PageNotFound} />
                        </Switch>
                    </Col>
                </Row>
                <Row>
                    <Footer />
                </Row>
            </div>
        )
    }

}
