import { blue, green, purple } from '@ant-design/colors';
import { Col, Row } from 'antd';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { ReactElement } from 'react';
import classNames from 'classnames';
import './Splash.less';

export type SplashProps = {
    className?: string
}

export const Splash = (props: SplashProps): ReactElement => {
    return (
        <div className={classNames('x-splash', props.className)}>
            <div className="x-splash-inner">
                <div className="x-splash-text">
                    A platform to build financial applications.
                </div>
                <Row justify="center" gutter={64}>
                    <Col>
                        <InfoPanel
                            title="Process"
                            subtitle="Aggregate data"
                            body="Bring all your financial data sources together. Parse, extract, process, 
                                and store to create real-time and materialized views."
                            color={blue}
                            shadow={false}
                        />
                    </Col>
                    <Col>
                        <InfoPanel
                            title="Analyze"
                            subtitle="Perform calculations"
                            body="Model and analyze financial data with a powerful calculator. Use formulas and
                                functions to transform and prepare datasets."
                            color={green}
                            shadow={false}
                        />
                    </Col>
                    <Col>
                        <InfoPanel
                            title="Monitor"
                            subtitle="Track changes"
                            body="Stay on top of the latest financial data with dashboards and API access. Continually 
                                track changes with real-time updates."
                            color={purple}
                            shadow={false}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    )
}
