import React, { ReactElement, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { AppstoreOutlined, CalculatorOutlined, EditOutlined } from '@ant-design/icons';
import { Location } from 'history';
import { Button, Radio, RadioChangeEvent, Space } from 'antd';
import { InlineText } from 'components/InlineText/InlineText';
import { ModelProperties } from './ModelProperties/ModelProperties';
import { Tip } from 'components/Tip/Tip';
import { ModelContext } from 'context/ModelContext';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Model } from '@methodset/model-client-ts';
import classNames from 'classnames';
import './ModelHeading.less';

export type SaveCallback = () => void;
export type ChangeCallback = (model: Model) => void;

type HistoryMap = { [key: string]: string };

type MatchParams = {
    modelId: string
}

export type ModelHeadingProps = RouteComponentProps<MatchParams> & {
    actions?: ReactNode,
    className?: string,
    isSaving: boolean,
    onSave: SaveCallback,
    onChange: ChangeCallback
}

export type ModelHeadingState = {
    isEditing: boolean
}

export const ModelHeading = (props: ModelHeadingProps): ReactElement => {

    const findPathParts = (location: Location<any>): string[] => {
        const pattern = /(.*)\/(calculator|applications)(.*)/;
        const regex = pattern.exec(location.pathname);
        const params = location.search;
        return regex && regex.length > 2 ? [regex[2], `${regex[3]}${params}`] : [];
    }

    const context = useContext(ModelContext);
    const history = useHistory();
    const location = useLocation();
    const initView = useRef<string>(findPathParts(location)[0]);
    const historyMap = useRef<HistoryMap>({});

    const [isEditing, setIsEditing] = useState<boolean>(false);

    useEffect(() => {
        const unregister = props.history.listen((location) => handleLocationChange(location));
        return () => {
            unregister();
        }
    }, [location.search]);

    const handleLocationChange = (location: Location<any>): false | void => {
        const pathParts = findPathParts(location);
        const view = pathParts[0];
        const path = pathParts[1];
        historyMap.current[view] = path;
    }

    const handleViewChange = (e: RadioChangeEvent): void => {
        const modelId = props.match.params.modelId;
        const view = e.target.value;
        const last = historyMap.current[view];
        const path = last ? `${view}${last}` : view;
        history.push(RouteBuilder.model(modelId, path));

        // Pause the data refresh when editing data.
        const calculator = context.calculator!;
        if (view === "calculator") {
            calculator.timer.pause();
        } else if (view === "applications") {
            calculator.timer.resume();
        }
    }

    const handleNameChange = (name: string): void => {
        const model = context.model!;
        const calculator = context.calculator!;
        model.name = name;
        calculator.properties.name = name;
    }

    const handleModelEdit = (): void => {
        setIsEditing(true);
    }

    const handleModelChange = (model: Model): void => {
        const calculator = context.calculator!;
        calculator.properties.name = model.name;
        calculator.properties.description = model.description;
        context.saveModel(model);
        props.onChange(model);
        setIsEditing(false);
    }

    const handleModelCancel = (): void => {
        setIsEditing(false);
    }

    const buildButtons = (): ReactElement => {
        return (
            <React.Fragment>
                <Space>
                    {props.isSaving &&
                        <span className="x-modelheading-saving">Saving...</span>
                    }
                    <Tip title="Save model changes.">
                        <Button onClick={props.onSave}>Save</Button>
                    </Tip>
                    <Radio.Group buttonStyle="solid" defaultValue={initView.current} onChange={handleViewChange}>
                        <Radio.Button value="calculator">
                            <Space>
                                <CalculatorOutlined />
                                <span>Calculator</span>
                            </Space>
                        </Radio.Button>
                        <Radio.Button value="applications">
                            <Space>
                                <AppstoreOutlined />
                                <span>Applications</span>
                            </Space>
                        </Radio.Button>
                    </Radio.Group>
                    <Tip title="Edit model properties.">
                        <Button icon={<EditOutlined />} onClick={handleModelEdit} />
                    </Tip>
                </Space>
                {props.actions}
            </React.Fragment>
        )
    }

    return (
        <div className={classNames('x-modelheading', props.className)}>
            <div className="x-modelheading-title">
                <InlineText
                    className={classNames('x-modelheading-text')}
                    value={context.model?.name}
                    onChange={handleNameChange}
                />
            </div>
            <div className="x-modelheading-buttons">
                {buildButtons()}
            </div>
            {isEditing &&
                <ModelProperties
                    visible={isEditing}
                    model={context.model!}
                    onChange={handleModelChange}
                    onCancel={handleModelCancel}
                />
            }
        </div>
    )

}
