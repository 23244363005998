import { Modal } from "antd";
import { EntityState } from 'context/EntityContext';
import { PermissionsHelper } from '@methodset/entity-client-ts';

export class PermissionUtils {

    public static checkLimit(context: EntityState, key: string, name: string, count: number): boolean {
        const perms = PermissionsHelper.to(context.permissions);
        if (!perms.withinLimit(key, count)) {
            const limit = perms.get(key) as number;
            Modal.warning({
                title: "Limit Exceeded",
                content: `You have reached the limit of ${limit} ${name}s. Please upgrade your plan to increase your limit.`
            });
            return false;
        } else {
            return true;
        }
    }

}