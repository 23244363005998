import { PureComponent, ReactElement } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Button, Modal, Space } from 'antd';
import { FileUploader } from 'components/FileUploader/FileUploader';
import workflowService from 'services/WorkflowService';
import { RestUtils } from 'utils/RestUtils';
import './InitFile.less';

export type CloseCallback = () => void;

export type InitFileState = {
    status: string,
    url: string,
    data: any,
    errorMessage?: string
}

export type InitFileProps = {
    className?: string,
    name: string,
    workflowId: string,
    onClose: CloseCallback
}

export class InitFile extends PureComponent<InitFileProps, InitFileState> {

    constructor(props: InitFileProps) {
        super(props);
        this.state = {
            status: 'init',
            url: '',
            data: {},
            errorMessage: undefined
        };
        this.handleBeforeUpload = this.handleBeforeUpload.bind(this);
        this.handleUploadChange = this.handleUploadChange.bind(this);
    }

    private generateUploadUrlRequest(file: any): Promise<any> {
        const request = {
            workflowId: this.props.workflowId,
            fileName: file.name
        };
        return workflowService.generateUploadUrl(request,
            (response: any) => this.generateUploadUrlResponse(response),
            (response: any) => this.generateUploadUrlException(response),
            true
        );
    }

    private generateUploadUrlResponse(response: any): void {
        const data = response.data;
        this.setState({
            url: data.url,
            data: data.data
        });
    }

    private generateUploadUrlException(response: any): void {
        const errorMessage = RestUtils.getErrorMessage(response);
        this.setState({ errorMessage: errorMessage });
    }

    private handleBeforeUpload(file: any): Promise<any> {
        this.setState({ errorMessage: undefined });
        return this.generateUploadUrlRequest(file);
    }

    private handleUploadChange(info: any): void {
        const file = info.file;
        if (file.status === 'error') {
            let errorMessage;
            if (file.error && file.error.status !== 200 &&
                file.response && file.response.resultStatus) {
                errorMessage = file.response.resultStatus.message;
            } else {
                errorMessage = `Upload of file "${file.name}" failed.`;
            }
            this.setState({ errorMessage: errorMessage });
        } else if (file.status === 'uploading') {
            this.setState({ status: 'uploading' });
        } else if (file.status === 'done') {
            this.setState({ status: 'done' });
        }
    }

    public render(): ReactElement {
        return (
            <Modal
                centered
                title="Start Workflow"
                visible={true}
                width={720}
                onCancel={this.props.onClose}
                footer={(
                    <Button
                        type="primary"
                        disabled={this.state.status === 'uploading'}
                        onClick={this.props.onClose}
                    >
                        Close
                    </Button>
                )}
            >
                {this.state.status === 'done' &&
                    <Space className="x-initfile-success">
                        <CheckOutlined className="x-initfile-check" />
                        <span>The workflow has been started!</span>
                    </Space>
                }
                {this.state.status !== 'done' &&
                    <FileUploader
                        url={this.state.url}
                        data={this.state.data}
                        message={(
                            <span>
                                Initiate the "<strong>{this.props.name}</strong>" workflow by uploading a file.
                            </span>
                        )}
                        onBefore={this.handleBeforeUpload}
                        onChange={this.handleUploadChange}
                    />
                }
                {this.state.errorMessage &&
                    <div className="x-initfile-error">{this.state.errorMessage}</div>
                }
            </Modal>
        );
    }

}
