import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { Button, Checkbox, Col, Form, FormInstance, Input, Modal, Row } from 'antd';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { RestUtils } from 'utils/RestUtils';
import { EntityContext } from 'context/EntityContext';
import { AccessRules, Environment, Organization } from '@methodset/entity-client-ts';
import { StatusType } from 'constants/StatusType';
import { AccessRulesEditor } from './AccessRulesEditor/AccessRulesEditor';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { EnvironmentEditor } from './EnvironmentEditor/EnvironmentEditor';
import { Spacer } from 'components/Spacer/Spacer';
import entityService from 'services/EntityService';
import axios from 'axios';
import update from 'immutability-helper';
import './OrganizationEditor.less';

export type CloseCallback = () => void;

export type OrganizationDialogProps = {
    onClose: CloseCallback
}

export type OrganizationDialogState = {
    status: StatusType,
    organization: Organization,
    error?: Error,
    isLoading: boolean,
    isSaving: boolean
}

export class OrganizationDialog extends PureComponent<OrganizationDialogProps, OrganizationDialogState> {

    static contextType = EntityContext;

    private formRef = React.createRef<FormInstance>();

    constructor(props: OrganizationDialogProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            organization: {
                environments: [] as Environment[]
            } as Organization,
            error: undefined,
            isLoading: false,
            isSaving: false
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleRulesChange = this.handleRulesChange.bind(this);
        this.handleEnvironmentChange = this.handleEnvironmentChange.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleSaveClick(): void {
        this.formRef.current!.validateFields().then(() => {
            this.updateOrganizationRequest();
        }).catch(e => {
        });
    }

    private handleCancelClick(): void {
        this.props.onClose();
    }

    private handleNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const name = e.target.value;
        const organization = update(this.state.organization, {
            name: { $set: name }
        });
        this.setState({ organization: organization });
    }

    private handleDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        const description = e.target.value;
        const organization = update(this.state.organization, {
            description: { $set: description }
        });
        this.setState({ organization: organization });
    }

    private handleRulesChange(accessRules: AccessRules): void {
        const organization = update(this.state.organization, {
            accessRules: { $set: accessRules }
        });
        this.setState({ organization: organization });
    }

    private handleEnvironmentChange(environments: Environment[]): void {
        const organization = update(this.state.organization, {
            environments: { $set: environments }
        });
        this.setState({ organization: organization });
    }

    private readOrganizationRequest(): Promise<any> {
        this.setState({ isLoading: true });
        const request = {};
        return entityService.readOrganization(request,
            (response: any) => this.readOrganizationResponse(response),
            (response: any) => this.readOrganizationException(response),
            true
        );
    }

    private readOrganizationResponse(response: any): void {
        const organization = response.data.organization;
        this.setState({
            organization: organization,
            isLoading: false
        });
    }

    private readOrganizationException(response: any): void {
        const error = RestUtils.getError(response);
        this.setState({
            error: error,
            isLoading: false
        });
    }

    private updateOrganizationRequest(): Promise<any> {
        this.setState({
            error: undefined,
            isSaving: true
        });
        const request = {
            organizationId: this.state.organization.id,
            name: this.state.organization.name,
            description: this.state.organization.description,
            environments: this.state.organization.environments,
            accessRules: this.state.organization.accessRules
        };
        return entityService.updateOrganization(request,
            (response: any) => this.updateOrganizationResponse(response),
            (response: any) => this.updateOrganizationException(response),
            true
        );
    }

    private updateOrganizationResponse(response: any): void {
        this.props.onClose();
        this.setState({
            error: undefined,
            isSaving: false
        });
    }

    private updateOrganizationException(response: any): void {
        this.setState({
            error: RestUtils.getError(response),
            isSaving: false
        });
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readOrganizationRequest());
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSkeleton
                direction="vertical"
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load organization."
                onRetry={this.handleRetryLoad}
            >
                <Row className="x-organizationeditor-row" gutter={Globals.FORM_GUTTER_ROW}>
                    <Col span={12}>
                        <Spacer direction="vertical" size="lg" fill>
                            <LoadSkeleton.Input length="short" />
                            <LoadSkeleton.Input length="fill" />
                            <LoadSkeleton.Input length="short" />
                            <LoadSkeleton.Input length="fill" />
                            <LoadSkeleton.Input length="short" />
                            <LoadSkeleton.Input length="fill" />
                        </Spacer>
                    </Col>
                    <Col span={12}>
                        <Spacer direction="vertical" size="lg" fill>
                            <LoadSkeleton.Input length="short" />
                            <LoadSkeleton.Input length="fill" />
                            <LoadSkeleton.Input length="short" />
                            <LoadSkeleton.Input length="fill" />
                            <LoadSkeleton.Input length="short" />
                            <LoadSkeleton.Input length="fill" />
                        </Spacer>
                    </Col>
                </Row>
            </LoadSkeleton>
        );
    }

    private buildFormView() {
        return (
            <Form ref={this.formRef}>
                <Row gutter={Globals.FORM_GUTTER_ROW}>
                    <Col span={12}>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            label="Name"
                            name="name"
                            info="The name of the organization."
                            rules={[{
                                required: true,
                                message: 'Please enter a name.'
                            }]}
                        >
                            <Input
                                placeholder="Organization name."
                                value={this.state.organization.name}
                                onChange={this.handleNameChange}
                            />
                        </FormItem>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            label="Description"
                            name="description"
                            info="The description of the model."
                        >
                            <Input.TextArea
                                placeholder="Dashboard description."
                                rows={3}
                                value={this.state.organization.description}
                                onChange={this.handleDescriptionChange}
                            />
                        </FormItem>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            label="Environments"
                            name="environments"
                            info="The set of compartmented environments in which to build and test components."
                            valuePropName="environments"
                        >
                            <EnvironmentEditor
                                environments={this.state.organization.environments}
                                onChange={this.handleEnvironmentChange}
                            />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <AccessRulesEditor
                            formRef={this.formRef}
                            accessRules={this.state.organization.accessRules}
                            onChange={this.handleRulesChange}
                        />
                    </Col>
                </Row>
                {this.state.error &&
                    <div className="x-organizationeditor-error">{this.state.error.message}</div>
                }
            </Form>
        );
    }

    public componentDidMount(): void {
        if (this.state.status !== StatusType.READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === StatusType.LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === StatusType.FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === StatusType.READY) {
            view = this.buildFormView();
        }
        return (
            <Modal
                centered
                title="Edit Organization"
                visible={true}
                width={Globals.DIALOG_WIDTH_DOUBLE}
                onCancel={this.handleCancelClick}
                footer={(
                    <>
                        <Button onClick={this.handleCancelClick}>Cancel</Button>
                        <Button type="primary" loading={this.state.isSaving} onClick={this.handleSaveClick}>Save</Button>
                    </>
                )}
            >
                {view}
            </Modal>
        );
    }

}
