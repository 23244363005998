import { ReactElement } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Spacer } from 'components/Spacer/Spacer';
import classNames from 'classnames';
import './Pager.less';

export type ChangeCallback = (page: number) => void;

export type PagerProps = {
    className?: string,
    page: number,
    isLast?: boolean,
    onChange: ChangeCallback
}

export const Pager = (props: PagerProps): ReactElement => {

    const handlePrev = (): void => {
        if (props.page > 1) {
            props.onChange(props.page - 1);
        }
    }

    const handleNext = (): void => {
        if (!props.isLast) {
            props.onChange(props.page + 1);
        }
    }

    return (
        <Spacer className={classNames("x-pager", props.className)} justification="right">
            <div className={classNames("x-pager-button", { "x-pager-idle": props.page === 1 })} onClick={handlePrev}>
                <LeftOutlined className={classNames("x-pager-inner", { "x-pager-disabled": props.page === 1 })} />
            </div>
            <div className="x-pager-button x-pager-page">
                <span className="x-pager-inner">{props.page}</span>
            </div>
            <div className={classNames("x-pager-button", { "x-pager-idle": !!props.isLast })} onClick={handleNext}>
                <RightOutlined className={classNames("x-pager-inner", { "x-pager-disabled": !!props.isLast })} />
            </div>
        </Spacer>
    )

}
