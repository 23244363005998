import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Globals } from 'constants/Globals';
import { ItemEditor } from 'containers/Console/ItemEditor/ItemEditor';
import { WorkflowEditor } from './WorkflowEditor/WorkflowEditor';
import { Workflow } from '@methodset/workflow-client-ts';
import classNames from 'classnames';
import './WorkflowItem.less';

type MatchParams = {
    workflowId: string
}

export type WorkflowItemState = {
    status: string,
    errorMessage: string | null
    isSaving: boolean,
    isEditing: boolean
}

export type WorkflowItemProps = RouteComponentProps<MatchParams> & {
    className?: string
}

export class WorkflowItem extends PureComponent<WorkflowItemProps, WorkflowItemState> {

    private workflowRef = React.createRef<WorkflowEditor>();

    constructor(props: WorkflowItemProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            errorMessage: null,
            isSaving: false,
            isEditing: false
        };
        this.handleEditorSave = this.handleEditorSave.bind(this);
        this.handleEditorCancel = this.handleEditorCancel.bind(this);
        this.handleEditorError = this.handleEditorError.bind(this);
        this.handleWorkflowEdit = this.handleWorkflowEdit.bind(this);
        this.handleWorkflowSaving = this.handleWorkflowSaving.bind(this);
        this.handleWorkflowSave = this.handleWorkflowSave.bind(this);
        this.handleWorkflowTouch = this.handleWorkflowTouch.bind(this);
        this.handleWorkflowError = this.handleWorkflowError.bind(this);
    }

    private handleEditorSave(): void {
        // const callback = (): void => {
        //     this.setState({
        //         errorMessage: null,
        //         isSaving: true
        //     });
        // }
        this.workflowRef.current!.saveWorkflow(false);
    }

    private handleEditorCancel(): void {
        this.props.history.push(RouteBuilder.CONSOLE_WORKFLOWS);
        //window.history.back();
    }

    private handleEditorError(details: any): void {
        const errorMessage = 'Please fill in all required fields.';
        this.setState({ errorMessage: errorMessage });
    }

    private handleWorkflowEdit(isEditing: boolean): void {
        this.setState({ isEditing: isEditing });
    }

    private handleWorkflowSaving(workflow: Workflow, isTesting: boolean): void {
        this.setState({
            isSaving: true,
            errorMessage: null
        });
    }

    private handleWorkflowSave(workflow: Workflow, isTesting: boolean): void {
        this.setState({
            isSaving: false
        });
        if (!isTesting) {
            this.props.history.push(RouteBuilder.CONSOLE_WORKFLOWS);
            //window.history.back();
        }
    }

    private handleWorkflowTouch(): void {
        if (this.state.errorMessage) {
            this.setState({ errorMessage: null });
        }
    }

    private handleWorkflowError(error: Error): void {
        this.setState({
            isSaving: false,
            errorMessage: error.message
        });
    }

    render() {
        const workflowId = this.props.match.params.workflowId;
        return (
            <ItemEditor
                className={classNames('x-workflowitem', this.props.className)}
                title="Workflow"
                saving={this.state.isSaving}
                disabled={this.state.isEditing}
                onSave={this.handleEditorSave}
                onCancel={this.handleEditorCancel}
                onError={this.handleEditorError}
            >
                <WorkflowEditor
                    ref={this.workflowRef}
                    workflowId={workflowId}
                    onEdit={this.handleWorkflowEdit}
                    onSaving={this.handleWorkflowSaving}
                    onSave={this.handleWorkflowSave}
                    onError={this.handleWorkflowError}
                    onTouch={this.handleWorkflowTouch}
                />
                <div className="x-workflowitem-error">{this.state.errorMessage}</div>
            </ItemEditor>
        );
    }

}
