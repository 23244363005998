import { ReactElement, useEffect, useState } from 'react';
import { Select } from 'antd';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { StatusType } from 'constants/StatusType';
import { Version } from '@methodset/commons-core-ts';
import { LoadSelect } from 'components/LoadSelect/LoadSelect';
import classNames from 'classnames';
import axios from 'axios';
import modelService from 'services/ModelService';
import './ModelVersionPicker.less';

export type FilterFunction = (version: number, count: number) => boolean;
export type ChangeCallback = (version: number) => void;

export type ModelVersionPickerState = {
    status: StatusType,
    versions: number[]
}

export type ModelVersionPickerProps = {
    className?: string,
    modelId?: string,
    version?: number,
    filter?: FilterFunction,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
}

export const ModelVersionPicker = (props: ModelVersionPickerProps): ReactElement => {

    const [status, setStatus] = useState<StatusType>(StatusType.INIT);
    const [versions, setVersions] = useState<number[]>([]);

    useEffect(() => {
        // Load on start and when model id changes.
        if (props.modelId) {
            setStatus(StatusType.INIT);
            loadData();
        } else {
            setStatus(StatusType.READY);
        }
    }, [props.modelId]);

    const handleRetryLoad = (): void => {
        loadData();
    }

    const handleVersionChange = (version: number): void => {
        props.onChange(version);
    }

    const readModelVersionsRequest = (): Promise<any> => {
        if (!props.modelId) {
            return Promise.resolve(true);
        }
        const request = {
            modelId: props.modelId
        };
        return modelService.readModelVersions(request,
            (response: any) => readModelVersionsResponse(response),
            undefined, true
        );
    }

    const readModelVersionsResponse = (response: any): void => {
        let versions = response.data.versions;
        if (!versions || versions.length === 0) {
            versions = [Version.EDITOR];
        } else {
            versions.sort((a: number, b: number) => b - a);
        }
        setVersions(versions);
    }

    const loadData = (): void => {
        const requests = [];
        requests.push(readModelVersionsRequest());
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
    }

    return (
        <div className={classNames('x-modelversionpicker', props.className)}>
            <LoadSelect
                placeholder="Select a version."
                status={status}
                value={props.version}
                onChange={handleVersionChange}
                onRetry={handleRetryLoad}
            >
                {versions.filter((version) => props.filter ? props.filter(version, versions.length) : true).map(version => (
                    <Select.Option key={version} value={version}>{CoreUtils.toVersion(version)}</Select.Option>
                ))}
            </LoadSelect>
        </div>
    );

}

ModelVersionPicker.defaultProps = defaultProps;
