import { blue } from '@ant-design/colors';
import classNames from 'classnames';
import { ReactElement } from 'react';
import './InfoPanel.less';

export type InfoPanelProps = {
    className?: string,
    color?: string[],
    title?: string,
    radius?: number,
    width?: number,
    headerHeight?: number,
    bodyHeight?: number,
    subtitle?: string,
    body?: string,
    shadow?: boolean
} & typeof defaultProps;

const defaultProps = {
    color: blue,
    radius: 16,
    width: 275,
    headerHeight: 170,
    shadow: false
}

export const InfoPanel = (props: InfoPanelProps): ReactElement => {
    const radius = `${props.radius}px`;
    const panelStyle = {
        borderRadius: radius
    };
    const headerStyle = {
        width: `${props.width}px`,
        height: props.headerHeight ? `${props.headerHeight}px` : undefined,
        color: 'white',
        backgroundColor: props.color[7],
        borderRadius: `${radius} ${radius} 0 0`
    };
    const bodyStyle = {
        width: `${props.width}px`,
        height: props.bodyHeight ? `${props.bodyHeight}px` : undefined,
        color: props.color[8],
        backgroundColor: props.color[1],
        borderRadius: `0 0 ${radius} ${radius}`,
    };
    const triangleStyle = {
        borderTopColor: props.color[7]
    };
    return (
        <div className={classNames('x-infopanel', props.className, {'x-infopanel-shadow': props.shadow})} style={panelStyle}>
            <div className="x-infopanel-header" style={headerStyle}>
                <div className="x-infopanel-title">{props.title}</div>
                <div className="x-infopanel-subtitle">{props.subtitle}</div>
                <div className="x-infopanel-triangle" style={triangleStyle}></div>
            </div>
            <div className="x-infopanel-body" style={bodyStyle}>
                {props.body}
            </div>
        </div>
    )
}

InfoPanel.defaultProps = defaultProps;
