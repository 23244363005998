import { PureComponent, ReactElement } from 'react';
import { Divider } from 'antd';
import { LabelCheckbox } from 'components/LabelCheckbox/LabelCheckbox';
import { Constants } from 'components/Constants';
import update from 'immutability-helper';
import classNames from 'classnames';
import './DaysOfWeekSelector.less';

export type ChangeCallback = (days: number[]) => void;

export type DaysOfWeekSelectorProps = typeof DaysOfWeekSelector.defaultProps & {
    className?: string,
    value: number[],
    onChange: ChangeCallback
}

export class DaysOfWeekSelector extends PureComponent<DaysOfWeekSelectorProps> {

    static defaultProps = {
        value: [] as number[]
    }

    private indexes = [0, 1, 2, 3, 4, 5, 6];

    constructor(props: DaysOfWeekSelectorProps) {
        super(props);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.handleAllChange = this.handleAllChange.bind(this);
    }

    private handleDayChange(checked: boolean, index: number): void {
        let days;
        if (checked) {
            // Add index to list.
            days = update(this.props.value, {
                $push: [index + 1]
            });
        } else {
            // Remove index from list.
            const i = this.props.value.findIndex(day => day === index + 1);
            days = update(this.props.value, {
                $splice: [[i, 1]]
            });
        }
        days.sort((a, b) => a - b);
        this.props.onChange(days);
    }

    private handleAllChange(checked: boolean): void {
        const days = checked ? [1, 2, 3, 4, 5, 6, 7] : [];
        this.props.onChange(days);
    }

    private areAllChecked(): boolean {
        if (this.props.value.length !== 7) {
            return false;
        }
        for (let i = 0; i < this.props.value.length; i++) {
            const index = this.props.value[i];
            if (index !== i + 1) {
                return false;
            }
        }
        return true;
    }

    public render(): ReactElement {
        return (
            <div className={classNames('x-dowselector', this.props.className)}>
                {this.indexes.map((_, index) => (
                    <LabelCheckbox
                        key={index}
                        className="x-dowselector-day"
                        label={Constants.DAY_OF_WEEK_ABBREV[index]}
                        checked={this.props.value.includes(index + 1)}
                        index={index}
                        onChange={this.handleDayChange}
                    />
                ))}
                <Divider className="x-dowselector-divider" type="vertical" />
                <LabelCheckbox
                    key="all"
                    label='All'
                    className="x-dowselector-all"
                    checked={this.areAllChecked()}
                    onChange={this.handleAllChange}
                />
            </div>
        )
    }
}
