import { Ref, useRef } from 'react';
import { Calculator, Variable } from '@methodset/calculator-ts';
import { ReactElement, useState } from 'react';
import { InputPanelWidgetConfiguration } from '@methodset/application-client-ts';
import { Configuration } from '@methodset/model-client-ts';
import { Justification, Justify } from 'components/Justify/Justify';
import { Button, Form, FormInstance, Modal } from 'antd';
import { CoreUtils } from '@methodset/commons-core-ts';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { ConfigurationCallback } from '../WidgetViewer/WidgetViewer';
import { InputEditor } from '../InputWidgetViewer/InputEditor/InputEditor';
import { WidgetUtils } from 'utils/WidgetUtils';
import update from 'immutability-helper';
import './InputPanelWidgetViewer.less';

export type InputPanelWidgetViewerProps = {
    configuration: InputPanelWidgetConfiguration,
    calculator: Calculator,
    appletConfiguration: Configuration,
    onUpdate: ConfigurationCallback
} & typeof defaultProps;

const defaultProps = {
    appletConfiguration: {} as Configuration
}

export const InputPanelWidgetViewer = (props: InputPanelWidgetViewerProps): ReactElement => {

    const [configuration, setConfiguration] = useState<Configuration>(props.appletConfiguration);
    const [editing, setEditing] = useState<boolean>(false);

    const formRef: Ref<FormInstance> = useRef(null);

    const findVariables = (): Variable[] => {
        const variableIds = props.configuration.variableIds;
        const variables: Variable[] = [];
        variableIds.forEach((variableId: string) => {
            const variable = props.calculator.variables.get(variableId, false);
            if (variable) {
                variables.push(variable);
            }
        });
        return variables;
    }

    const handleInputsEdit = (): void => {
        setConfiguration(props.appletConfiguration);
        setEditing(true);
    }

    const handleEditCancel = (): void => {
        setEditing(false);
    }

    const handleEditOk = (): void => {
        const variableIds = props.configuration.variableIds;
        if (!variableIds) {
            return;
        }
        // Suspend calculator updates while setting variables.
        props.calculator.suspend();
        variableIds.forEach(variableId => {
            const variable = props.calculator.variables.get(variableId, false);
            if (variable && variable.cell) {
                let value = configuration[variable.id];
                if (!!props.configuration.useDefaults) {
                    if (value === null) {
                        // User cleared the value.
                        value = undefined;
                    } else if (value === undefined) {
                        // Use default value.
                        return;
                    }
                }
                const cell = variable.cell;
                if (CoreUtils.isFormula(value)) {
                    cell.formula = value;
                } else {
                    cell.value = value;
                }
            }
        });
        // Unsuspend and execute with the new variable values.
        props.calculator.execute();
        setEditing(false);
        props.onUpdate(configuration);
    }

    const handleInputChange = (value: any, variable: Variable): void => {
        let updated;
        if (CoreUtils.isEmpty(value)) {
            updated = update(configuration, {
                $unset: [variable.id]
            });
        } else {
            updated = update(configuration, {
                [variable.id]: { $set: value }
            });
        }
        setConfiguration(updated);
    }

    const buildInput = (variable: Variable): ReactElement => {
        let value = configuration[variable.id];
        value = WidgetUtils.valueOrDefault(value, props.calculator, variable.id, props.configuration.useDefaults);
        return (
            <InputEditor
                calculator={props.calculator}
                value={value}
                variableId={variable.id}
                useDefault={!!props.configuration.useDefaults}
                doEmbed={true}
                onChange={(value, variable) => handleInputChange(value, variable)}
            />
        )
    }

    const buildView = (): ReactElement => {
        const justification = props.configuration.justification ?
            props.configuration.justification.toLowerCase() as Justification :
            undefined;
        const label = props.configuration.label ? props.configuration.label : "Inputs";
        const variables = findVariables();
        return (
            <div>
                <Justify justification={justification}>
                    <Button onClick={() => handleInputsEdit()}>{label}</Button>
                </Justify>
                {editing &&
                    <Modal
                        centered
                        title="Inputs"
                        width={Globals.DIALOG_WIDTH}
                        onOk={() => handleEditOk()}
                        onCancel={() => handleEditCancel()}
                        visible={true}
                    >
                        {variables.length === 0 &&
                            <div>No variables configured as inputs.</div>
                        }
                        {variables.length > 0 &&
                            <Form ref={formRef}>
                                {variables.map(variable => (
                                    <FormItem
                                        {...Globals.FORM_LAYOUT}
                                        key={variable.id}
                                        formRef={formRef}
                                        label={variable.name}
                                        name={variable.id}
                                        info={variable.description}
                                        rules={[{
                                            required: variable.isRequired,
                                            message: "Please enter a value."
                                        }]}
                                    >
                                        {buildInput(variable)}
                                    </FormItem>
                                ))}
                            </Form>
                        }
                    </Modal>
                }
            </div>
        );
    }

    return buildView();

}

InputPanelWidgetViewer.defaultProps = defaultProps;
