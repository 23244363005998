import { PureComponent, ReactElement } from 'react';
import { Button } from 'antd';
import { Spacer } from 'components/Spacer/Spacer';
import { WidgetSelectorEditor } from './WidgetSelectorEditor/WidgetSelectorEditor';
import { AppletPanel, Widget } from '@methodset/application-client-ts';
import update from 'immutability-helper';
import './WidgetSelectors.less';

export type ChangeCallback = (widgetIds: string[]) => void;

export type WidgetSelectorsProps = typeof WidgetSelectors.defaultProps & {
    panel: AppletPanel,
    widget?: Widget,
    widgetIds?: string[],
    onChange: ChangeCallback
}

export class WidgetSelectors extends PureComponent<WidgetSelectorsProps> {

    static defaultProps = {
        widgetIds: [] as string[]
    }

    constructor(props: WidgetSelectorsProps) {
        super(props);
        this.handleWidgetAdd = this.handleWidgetAdd.bind(this);
        this.handleWidgetChange = this.handleWidgetChange.bind(this);
        this.handleWidgetRemove = this.handleWidgetRemove.bind(this);
    }

    private handleWidgetAdd(): void {
        const widgetIds = update(this.props.widgetIds!, {
            $push: [undefined as any]
        });
        this.props.onChange(widgetIds);
    }

    private handleWidgetRemove(index: number): void {
        const widgetIds = update(this.props.widgetIds!, {
            $splice: [[index, 1]]
        });
        this.props.onChange(widgetIds);
    }

    private handleWidgetChange(widgetId: string, index: number): void {
        const widgetIds = update(this.props.widgetIds!, {
            [index]: { $set: widgetId }
        });
        this.props.onChange(widgetIds);
    }

    public render(): ReactElement {
        return (
            <Spacer className="x-widgetselectors" direction="vertical" alignment="top" fill>
                {this.props.widgetIds.map((widgetId, index) => (
                    <WidgetSelectorEditor
                        key={index}
                        index={index}
                        panel={this.props.panel}
                        widget={this.props.widget}
                        widgetId={widgetId}
                        onChange={this.handleWidgetChange}
                        onRemove={this.handleWidgetRemove}
                    />
                ))}
                <div className="x-widgetselectors-add">
                    <Button onClick={this.handleWidgetAdd}>Add Widget</Button>
                </div>
            </Spacer>
        );
    }
}
