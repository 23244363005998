import { ReactElement } from 'react';
import { Empty } from 'antd';
import { Spacer } from 'components/Spacer/Spacer';
import { Clickable } from 'components/Clickable/Clickable';
import { DeleteOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import './Listing.less';
import { NoData } from 'components/NoData/NoData';

export type EditCallback = (item: ListingItem, index: number) => void;
export type DeleteCallback = (item: ListingItem, index: number) => void;

export interface ListingItem {
    name: string;
}

export type ListingProps = {
    className?: string,
    items: ListingItem[],
    label: string,
    onEdit: EditCallback,
    onDelete: DeleteCallback
}

export const Listing = (props: ListingProps): ReactElement => {

    return (
        <div className={classNames("x-listing", props.className)}>
            {props.items.length === 0 &&
                <NoData text="No datasets." />
            }
            {props.items.length > 0 &&
                props.items.map((item, index) => (
                    <div className="x-listing-item" key={index}>
                        <Spacer justification="between" fill>
                            <Clickable data={index} onClick={() => props.onEdit(item, index)}>
                                {item.name}
                            </Clickable>
                            <Clickable data={index} onClick={() => props.onDelete(item, index)}>
                                <DeleteOutlined />
                            </Clickable>
                        </Spacer>
                    </div>
                ))}
        </div>
    )

}
