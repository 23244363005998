import { ReactElement } from 'react';
import { FormInstance } from 'antd';
import { AccessType } from '@methodset/commons-core-ts';
import { QueryHeader } from '@methodset/endpoint-client-ts';
import { QueryView } from './QueryView/QueryView';
import { ItemSelect } from 'components/ItemSelect/ItemSelect';
import endpointService from 'services/EndpointService';
import './QueryList.less';

export type ChangeCallback = (header: QueryHeader) => void;

export type QueryListProps = {
    formRef: React.RefObject<FormInstance>,
    queryId?: string,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
}

export const QueryList = (props: QueryListProps): ReactElement => {

    const handleQueryChange = (header: QueryHeader): void => {
        props.onChange(header);
    }

    const readQueryHeadersRequest = (accessType: AccessType): Promise<any> => {
        // TODO: remove use latest, true if version === latest
        const request = {
            accessType: accessType,
            useLatest: accessType === AccessType.UNPUBLISHED ? false : true
        };
        return endpointService.readQueryHeaders(request,
            (response: any) => readQueryHeadersResponse(response),
            undefined, true
        );
    }

    const readQueryHeadersResponse = (response: any): QueryHeader[] => {
        const headers: QueryHeader[] = response.data.headers;
        headers.sort((a, b) => a.name.localeCompare(b.name));
        return headers;
    }

    return (
        <div className="x-querylist">
            <ItemSelect<QueryHeader>
                formRef={props.formRef}
                selectedId={props.queryId}
                label={"dataset"}
                view={QueryView}
                loader={readQueryHeadersRequest}
                onChange={handleQueryChange}
            />
        </div>
    );    

}

QueryList.defaultProps = defaultProps;
