import { ReactElement } from 'react';
import classNames from 'classnames';
import './Align.less';

export type Alignment = "top" | "center" | "bottom" | "baseline" | "stretch";
export type AlignProps = {
    className?: string,
    children: any,
    alignment: Alignment,
    fill?: boolean
} & typeof defaultProps;

const defaultProps = {
    alignment: "center",
    fill: false
}

export const Align = (props: AlignProps): ReactElement => {
    return (
        <div className={
            classNames(
                'x-align',
                `x-align-${props.alignment}`,
                {"x-align-fill": props.fill},
                props.className
            )}
        >
            {props.children}
        </div>
    )
}

Align.defaultProps = defaultProps;
