import React, { ChangeEvent, Component, KeyboardEvent, ReactElement } from 'react';
import { Input } from 'antd';
import { KeyCode, KeyUtils } from 'utils/KeyUtils';
import { EmitterEvent } from 'utils/EventEmitter';
import { CoreUtils } from 'utils/CoreUtils';
import { Cell } from '@methodset/calculator-ts';
import { ModelContext } from 'context/ModelContext';
import classNames from 'classnames';
import './ParameterEditor.less';

export interface EditorChangeEvent {
    value?: any,
    code: string,
}

export interface EditorCancelEvent {
    code: string
}

export type ChangeCallback = (event: EditorChangeEvent) => void;
export type CancelCallback = (event: EditorCancelEvent) => void;

export type ParameterEditorProps = {
    className?: string,
    cell: Cell,
    disabled?: boolean,
    eventKey?: string,
    charPress?: string,
    onChange: ChangeCallback,
    onCancel: CancelCallback,
}

export type ParameterEditorState = {
    value?: any
}

export class ParameterEditor extends Component<ParameterEditorProps, ParameterEditorState> {

    static contextType = ModelContext;

    private inputRef = React.createRef<any>();
    private isExiting = false;

    constructor(props: ParameterEditorProps) {
        super(props);
        this.state = {
            value: this.findValue(props.cell)
        };
        this.handleBlur = this.handleBlur.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFormulaChangeEvent = this.handleFormulaChangeEvent.bind(this);
    }

    public getValue(): any {
        return this.state.value;
    }

    private handleBlur(): void {
        // If the blur is after the value is committed/canceled, skip.
        if (this.isExiting) {
            return;
        }
        const event = {
            value: this.state.value,
            code: KeyCode.NONE
        }
        this.props.onChange(event);
    }

    private handleKeyDown(e: KeyboardEvent<HTMLInputElement>): void {
        if (KeyUtils.isCommit(e)) {
            e.preventDefault();
            e.stopPropagation();
            const event = {
                value: this.state.value,
                code: e.code
            }
            this.isExiting = true;
            this.props.onChange(event);
        } else if (KeyUtils.isCancel(e)) {
            e.preventDefault();
            e.stopPropagation();
            const event = {
                code: e.code
            }
            this.isExiting = true;
            this.props.onCancel(event);
        }
    }

    private handleChange(e: ChangeEvent<HTMLInputElement>): void {
        const value = e.target.value;
        this.setState({ value: value });
        const data = {
            value: value
        }
        this.context.sendEvent("CellEditChange", data);
    }

    private handleFormulaChangeEvent(event: EmitterEvent): void {
        const value = event.data.value;
        this.setState({ value: value });
    }

    private findValue(cell: Cell): any {
        // User started the editor by entering a key.
        if (this.props.eventKey) {
            return this.props.eventKey;
        }
        if (cell) {
            return cell.formula ? cell.formula : cell.value;
        } else {
            return undefined;
        }
    }

    public componentDidMount(): void {
        // If the editor started because the formula editor was activated,
        // do not focus this editor and allow the formula editor to continue
        // to have focus.
        if (this.props.charPress !== "FormulaEditor") {
            this.inputRef.current!.focus();
        }
        this.context.addCallback("FormulaEditChange", this.handleFormulaChangeEvent);
        const data = {
            value: this.state.value
        }
        this.context.sendEvent("CellEditChange", data)
    }

    public componentWillUnmount(): void {
        this.context.removeCallback("FormulaEditChange", this.handleFormulaChangeEvent);
    }

    public render(): ReactElement {
        const className = classNames(
            'x-parametereditor',
            this.props.className,
            { 'x-parametereditor-hilite': CoreUtils.isFormula(this.state.value) }
        );
        return (
            <div className={className}>
                <Input
                    ref={this.inputRef}
                    value={this.state.value}
                    autoComplete="off"
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    onBlur={this.handleBlur}
                />
            </div>
        );
    }

}
