import { PureComponent, ReactElement } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import { RouteBuilder } from 'utils/RouteBuilder';
import { ApplicationList } from './ApplicationList/ApplicationList';
import { ModelAlert } from './ApplicationItem/ModelAlert/ModelAlert';
import { ModelApplet } from './ApplicationItem/ModelApplet/ModelApplet';
import './ModelApplications.less';

export type ModelApplicationsProps = RouteComponentProps & {
}

export class ModelApplications extends PureComponent<ModelApplicationsProps> {

    constructor(props: ModelApplicationsProps) {
        super(props);
    }

    public render(): ReactElement {
        return (
            <Switch>
                <Route exact path={RouteBuilder.CONSOLE_MODEL_APPLICATIONS} component={ApplicationList} />
                <Route path={RouteBuilder.CONSOLE_MODEL_APPLET} component={ModelApplet} />
                <Route path={RouteBuilder.CONSOLE_MODEL_ALERT} component={ModelAlert} />
            </Switch>
        );
    }

}
