import { FC, ReactElement } from 'react';
import classNames from 'classnames';
import './FormulaCell.less';

export type FormulaCellProps = {
    className?: string,
    value: any,
    input?: boolean
}

export const FormulaCell: FC<FormulaCellProps> = (props: FormulaCellProps): ReactElement => {

    return (
        <div className={classNames("x-formulacell", props.className)}>
            {props.value}
            <div className="x-formulacell-mark"></div>
        </div>
    );

}
