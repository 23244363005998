import { MouseEvent, PureComponent, ReactElement } from 'react';
import { BookOutlined, DeleteOutlined, EditOutlined, SyncOutlined } from '@ant-design/icons';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Space, Tag, message } from 'antd';
import { Globals } from 'constants/Globals';
import { InitEmail } from './InitEmail/InitEmail';
import { InitFile } from './InitFile/InitFile';
import { ActionSpec, ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { RouteBuilder } from 'utils/RouteBuilder';
import { TriggerType, Workflow, WorkflowHeader } from '@methodset/workflow-client-ts';
import { ColumnsType } from 'antd/lib/table';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
//import { EditorMode } from '../VersionEditor-REMOVE/VersionEditor.tsx-REMOVE';
import { ModuleType, PackHeader } from '@methodset/library-client-ts';
import { StatusType } from 'constants/StatusType';
import { VersionPackager } from '../VersionEditor-REMOVE/VersionPackager/VersionPackager';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { EntityContext } from 'context/EntityContext';
import { AccessType, EnvironmentType, Version } from '@methodset/commons-core-ts';
import { PermissionUtils } from 'utils/PermissionUtils';
import messageSpinner from 'components/MessageSpinner/MessageSpinner';
import axios from 'axios';
import classNames from 'classnames';
import update from 'immutability-helper';
import workflowService from 'services/WorkflowService';
import './Workflows.less';

interface ModalData {
    email: {
        visible: boolean,
        name?: string,
        address?: string
    },
    file: {
        visible: boolean,
        name?: string,
        workflowId?: string
    }
}

//type EditMode = EditorMode | "none";

interface ProcessData {
    //mode: EditMode,
    workflow?: Workflow,
    error?: Error,
    isProcessing?: boolean
}

export type WorkflowsState = {
    status: StatusType,
    headers: WorkflowHeader[],
    modal: ModalData,
    process: ProcessData
}

export type WorkflowsProps = RouteComponentProps & {
    className?: string
}

export class Workflows extends PureComponent<WorkflowsProps, WorkflowsState> {

    static contextType = EntityContext;

    private colorMap = CoreUtils.toColormap(CoreUtils.enumToKeys(TriggerType));

    constructor(props: WorkflowsProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            headers: [],
            modal: {
                email: {
                    visible: false,
                    name: undefined,
                    address: undefined
                },
                file: {
                    visible: false,
                    name: undefined,
                    workflowId: undefined
                }
            } as ModalData,
            process: {
                mode: "none",
                isProcessing: false
            } as ProcessData
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
        this.handleEnableChange = this.handleEnableChange.bind(this);
        this.handleEmailClick = this.handleEmailClick.bind(this);
        this.handleEmailClose = this.handleEmailClose.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
        this.handleFileClose = this.handleFileClose.bind(this);
        this.handleWorkflowCreate = this.handleWorkflowCreate.bind(this);
        this.handleWorkflowEdit = this.handleWorkflowEdit.bind(this);
        this.handleWorkflowDelete = this.handleWorkflowDelete.bind(this);
        this.handleShowPackage = this.handleShowPackage.bind(this);
        //this.handleShowReset = this.handleShowReset.bind(this);
        this.handlePackageDone = this.handlePackageDone.bind(this);
        this.handleVersionReset = this.handleVersionReset.bind(this);
        this.handlePackageCancel = this.handlePackageCancel.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleRefreshClick(): void {
        this.loadData();
    }

    private handleEnableChange(e: CheckboxChangeEvent): void {
        const workflowId = e.target.id!;
        const isEnabled = e.target.checked;
        this.updateEnabledRequest(workflowId, isEnabled);
    }

    private handleEmailClick(e: MouseEvent<HTMLButtonElement>) {
        const workflowId = e.currentTarget.id;
        const header = this.state.headers.find(header => header.id === workflowId);
        const modal = update(this.state.modal, {
            email: {
                name: { $set: header!.name }
            }
        });
        this.setState({ modal: modal });
        this.readWorkflowEmailRequest(workflowId);
    }

    private handleEmailClose(): void {
        const modal = update(this.state.modal, {
            email: {
                visible: { $set: false },
                address: { $set: undefined },
                name: { $set: undefined }
            }
        });
        this.setState({ modal: modal });
    }

    private handleFileClick(e: MouseEvent<HTMLButtonElement>): void {
        const workflowId = e.currentTarget.id;
        const header = this.state.headers.find(header => header.id === workflowId);
        const modal = update(this.state.modal, {
            file: {
                visible: { $set: true },
                name: { $set: header!.name },
                workflowId: { $set: workflowId }
            }
        });
        this.setState({ modal: modal });
    }

    private handleFileClose(): void {
        const modal = update(this.state.modal, {
            file: {
                visible: { $set: false },
                name: { $set: undefined },
                workflowId: { $set: undefined }
            }
        });
        this.setState({ modal: modal });
    }

    private handleWorkflowCreate(): void {
        if (!PermissionUtils.checkLimit(this.context, "component.workflow.count", "workflow", this.state.headers.length)) {
            return;
        }
        const url = RouteBuilder.workflow('create');
        this.props.history.push(url);
    }

    private handleWorkflowEdit(workflow: WorkflowHeader): void {
        const url = RouteBuilder.workflow(workflow.id);
        this.props.history.push(url);
    }

    private handleWorkflowDelete(workflow: WorkflowHeader): void {
        this.deleteWorkflowRequest(workflow.id);
    }

    private handleShowPackage(workflow: Workflow): void {
        const process: ProcessData = {
            //mode: "publish",
            workflow: workflow
        }
        this.setState({ process: process });
    }

    // private handleShowReset(workflow: Workflow): void {
    //     const process = update(this.state.process, {
    //         mode: { $set: "reset" },
    //         workflow: { $set: workflow }
    //     });
    //     this.setState({ process: process });
    // }

    private handlePackageDone(header: PackHeader): void {
        const response = {
            data: {
                header: header
            }
        }
        this.versionResponse(response);
    }

    private handleVersionReset(): void {
        const workflow = this.state.process.workflow!;
        this.resetVersionRequest(workflow.id);
    }

    private handlePackageCancel(): void {
        const version = update(this.state.process, {
            //mode: { $set: "none" },
            workflow: { $set: undefined },
            isProcessing: { $set: false }
        });
        this.setState({ process: version });
    }

    private updateEnabledRequest(workflowId: string, isEnabled: boolean): Promise<any> {
        const message = !!isEnabled ? "Enabling workflow..." : "Disabling workflow...";
        messageSpinner.show(message);
        const request = {
            workflowId: workflowId,
            isEnabled: isEnabled
        };
        return workflowService.updateWorkflowEnabled(request,
            (response: any) => this.updateEnabledResponse(response),
            (response: any) => this.updateEnabledException(response),
            true
        );
    }

    private updateEnabledResponse(response: any): void {
        const workflowId = response.data.workflowId;
        const isEnabled = response.data.isEnabled;
        const index = this.state.headers.findIndex(header => header.id === workflowId);
        const headers = update(this.state.headers, {
            [index]: {
                isEnabled: { $set: isEnabled }
            }
        });
        this.setState({ headers: headers });
        messageSpinner.hide();
    }

    private updateEnabledException(response: any): void {
        messageSpinner.error(`${RestUtils.getErrorMessage(response)} Workflow has been disabled. Please try again.`);
    }

    private resetVersionRequest(workflowId: string): Promise<any> {
        const version = update(this.state.process, {
            error: { $set: undefined },
            isProcessing: { $set: true }
        });
        this.setState({ process: version });
        const request = {
            workflowId: workflowId
        };
        return workflowService.resetVersion(request,
            (response: any) => this.versionResponse(response),
            (response: any) => this.versionException(response),
            true
        );
    }

    private versionResponse(response: any): void {
        const header = response.data.header;
        const workflowId = header.moduleId;
        const index = this.state.headers.findIndex(workflow => workflow.id === workflowId);
        const headers = update(this.state.headers, {
            [index]: {
                version: { $set: header.version },
                updateTime: { $set: header.updateTime }
            }
        });
        const process = update(this.state.process, {
            //mode: { $set: "none" },
            workflow: { $set: undefined },
            isProcessing: { $set: false }
        });
        this.setState({
            headers: headers,
            process: process
        });
    }

    private versionException(response: any): void {
        const error = new Error(RestUtils.getErrorMessage(response));
        const process = update(this.state.process, {
            error: { $set: error },
            isProcessing: { $set: false }
        });
        this.setState({ process: process });
    }

    private readWorkflowEmailRequest(workflowId: string): Promise<any> {
        const request = {
            workflowId: workflowId
        };
        return workflowService.readWorkflowEmail(request,
            (response: any) => this.readWorkflowEmailResponse(response),
            (response: any) => this.readWorkflowEmailException(response),
            true
        );
    }

    private readWorkflowEmailResponse(response: any): void {
        const address = response.data.emailAddress;
        const modal = update(this.state.modal, {
            email: {
                visible: { $set: true },
                address: { $set: address }
            }
        });
        this.setState({ modal: modal });
    }

    private readWorkflowEmailException(response: any): void {
        const modal = update(this.state.modal, {
            email: {
                visible: { $set: false },
                address: { $set: undefined },
                name: { $set: undefined }
            }
        });
        this.setState({ modal: modal });
    }

    private readWorkflowHeadersRequest(): Promise<any> {
        const request = {
            accessType: AccessType.UNPUBLISHED,
            useLatest: true
        };
        return workflowService.readWorkflowHeaders(request,
            (response: any) => this.readWorkflowHeadersResponse(response),
            undefined, true
        );
    }

    private readWorkflowHeadersResponse(response: any): void {
        const headers = response.data.headers;
        this.setState({ headers: headers });
    }

    private deleteWorkflowRequest(workflowId: string): Promise<any> {
        const request = {
            workflowId: workflowId,
            version: Version.EDITOR
        };
        return workflowService.archiveWorkflow(request,
            (response: any) => this.deleteWorkflowResponse(response)
        );
    }

    private deleteWorkflowResponse(response: any): void {
        const workflowId = response.data.workflowId;
        const index = this.state.headers.findIndex(header => header.id === workflowId);
        this.setState({
            headers: update(this.state.headers, {
                $splice: [[index, 1]]
            })
        });
    }

    private buildColumns(): ColumnsType<any> {
        const environmentType = this.context.user?.environmentType;
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            ellipsis: true,
            render: (workflow) => {
                return (
                    <div className="x-workflows-name">
                        {environmentType === EnvironmentType.DEVELOPMENT &&
                            <Link to={RouteBuilder.workflow(workflow.id)}>{workflow.name}</Link>
                        }
                        {environmentType !== EnvironmentType.DEVELOPMENT &&
                            <span>{workflow.name}</span>
                        }
                        {/* {record.triggerType === 'EMAIL' &&
                            <Button id={record.id} type="link" icon={<MailOutlined />} onClick={this.handleEmailClick}></Button>
                        }
                        {record.triggerType === 'FILE' &&
                            <Button id={record.id} type="link" icon={<CloudUploadOutlined />} onClick={this.handleFileClick}></Button>
                        } */}
                    </div>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'trigger',
            title: 'Trigger',
            align: 'center',
            width: Globals.TABLE_WIDTH_MEDIUM,
            render: (workflow) => {
                return (
                    <div>
                        {workflow.triggerType === TriggerType.EMAIL &&
                            <Button
                                id={workflow.id}
                                type="link"
                                onClick={this.handleEmailClick}
                            >
                                <Tag color={this.colorMap[workflow.triggerType]}>{CoreUtils.toProper(workflow.triggerType, '_', ' ')}</Tag>
                            </Button>
                        }
                        {workflow.triggerType === TriggerType.FILE &&
                            <Button
                                id={workflow.id}
                                type="link"
                                onClick={this.handleFileClick}
                            >
                                <Tag color={this.colorMap[workflow.triggerType]}>{CoreUtils.toProper(workflow.triggerType, '_', ' ')}</Tag>
                            </Button>
                        }
                        {workflow.triggerType === TriggerType.SCHEDULE &&
                            <Tag color={this.colorMap[workflow.triggerType]}>{CoreUtils.toProper(workflow.triggerType, '_', ' ')}</Tag>
                        }
                    </div>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.triggerType, b.triggerType),
            sortDirections: ['ascend', 'descend']
        }, {
            key: 'enabled',
            title: 'Enabled',
            align: 'center',
            width: Globals.TABLE_WIDTH_MEDIUM,
            render: (workflow) => {
                return (
                    <Checkbox
                        id={workflow.id}
                        //disabled={workflow.version === 0}
                        checked={workflow.isEnabled}
                        onChange={this.handleEnableChange}
                    />
                );
            }
        }, {
            key: 'version',
            title: 'Latest Version',
            align: 'center',
            width: Globals.TABLE_WIDTH_MEDIUM,
            render: (workflow) => {
                return (
                    <span>
                        {CoreUtils.toVersion(workflow.version)}
                    </span>
                );
            },
            sorter: (a: any, b: any) => CoreUtils.compareNumbers(a.latestVersion, b.latestVersion),
            sortDirections: ['descend', 'ascend']
        }, {
            key: 'update_time',
            title: 'Last Updated',
            align: 'center',
            width: Globals.TABLE_WIDTH_MEDIUM,
            render: (workflow) => {
                return (
                    <span>
                        {CoreUtils.toTime(workflow.updateTime)}
                    </span>
                );
            },
            sorter: (a, b) => CoreUtils.compareNumbers(a.updateTime, b.updateTime),
            sortDirections: ['descend', 'ascend']
        }];
        return columns;
    }

    private buildData(): WorkflowHeader[] {
        return this.state.headers;
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readWorkflowHeadersRequest());
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== StatusType.READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        const environmentType = this.context.user?.environmentType;
        const actions: ActionSpec[] = [{
            icon: <EditOutlined />,
            label: "Edit workflow",
            hidden: () => environmentType !== EnvironmentType.DEVELOPMENT,
            callback: this.handleWorkflowEdit
        }, {
            icon: <BookOutlined />,
            label: "Package version...",
            hidden: () => environmentType !== EnvironmentType.DEVELOPMENT,
            callback: this.handleShowPackage
            // }, {
            //     icon: <ScissorOutlined />,
            //     label: "Discard changes",
            //     hidden: () => environmentType !== EnvironmentType.DEVELOPMENT,
            //     callback: this.handleShowReset
        }, {
            icon: <DeleteOutlined />,
            label: "Delete workflow",
            confirm: "Are you sure you want to delete the workflow?",
            callback: this.handleWorkflowDelete
        }];
        const columns = this.buildColumns();
        const data = this.buildData();
        return (
            <>
                <ItemTable
                    className={classNames('x-workflows', this.props.className)}
                    title="Workflows"
                    status={this.state.status}
                    columns={columns}
                    items={data}
                    extra={
                        <Space>
                            <Button onClick={this.handleWorkflowCreate}>New Workflow</Button>
                            <Button icon={<SyncOutlined />} onClick={this.handleRefreshClick}></Button>
                        </Space>
                    }
                    actions={actions}
                    onLoad={this.handleRetryLoad}
                />
                {this.state.modal.email.visible &&
                    <InitEmail
                        name={this.state.modal.email.name!}
                        address={this.state.modal.email.address!}
                        onClose={this.handleEmailClose}
                    />
                }
                {this.state.modal.file.visible &&
                    <InitFile
                        name={this.state.modal.file.name!}
                        workflowId={this.state.modal.file.workflowId!}
                        onClose={this.handleFileClose}
                    />
                }
                {this.state.process.workflow &&
                    <VersionPackager
                        id={this.state.process.workflow.id}
                        name={this.state.process.workflow.name}
                        description={this.state.process.workflow.description}
                        moduleId={this.state.process.workflow.id}
                        moduleType={ModuleType.QUERY}
                        onPackage={this.handlePackageDone}
                        onCancel={this.handlePackageCancel}
                    />
                }
                {/* {EditorValues.includes(this.state.process.mode) && this.state.process.workflow &&
                    <VersionEditor
                        id={this.state.process.workflow.id}
                        type={PackType.WORKFLOW}
                        name={this.state.process.workflow.name}
                        description={this.state.process.workflow.description}
                        details={{
                            type: PackType.WORKFLOW,
                            workflowId: this.state.process.workflow.id,
                            version: 0
                        } as WorkflowDetails}
                        mode={this.state.process.mode as EditorMode}
                        version={this.state.process.workflow.version}
                        error={this.state.process.error}
                        isProcessing={this.state.process.isProcessing}
                        onPackage={this.handleVersionPackage}
                        onReset={this.handleVersionReset}
                        onCancel={this.handleVersionCancel}
                    />
                } */}
            </>
        );
    }

}
