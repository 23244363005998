import { ReactElement, useState } from 'react';
import { Table, Tag } from 'antd';
import { Configuration, IoType } from '@methodset/endpoint-client-ts';
import { ColumnsType } from 'antd/lib/table';
import { CoreUtils } from 'utils/CoreUtils';
import { Spacer } from 'components/Spacer/Spacer';
import { Link } from 'react-router-dom';
import { Globals } from 'constants/Globals';
import './ConfigurationViewer.less';

const COLOR_MAP = CoreUtils.toColormap(CoreUtils.enumToKeys(IoType));

export type CloseCallback = () => void;

export type ConfigurationViewerProps = {
    configuration: Configuration,
    showRaw?: boolean
    enableRaw: boolean,
} & typeof defaultProps;

const defaultProps = {
    showRaw: false,
    enableRaw: false
}

export const ConfigurationViewer = (props: ConfigurationViewerProps): ReactElement => {

    const [showRaw, setShowRaw] = useState<boolean>(props.showRaw);

    const handleToggleRaw = (): void => {
        setShowRaw(!showRaw);
    }

    const buildColumns = (): ColumnsType<any> => {
        const columns: ColumnsType<any> = [{
            title: "Name",
            dataIndex: "name",
            ellipsis: true,
            width: 200
        }, {
            title: "Type",
            dataIndex: "type",
            ellipsis: true,
            width: 200,
            align: "center",
            render: (type: IoType) => {
                return (
                    <Tag color={COLOR_MAP[type]}>
                        {type}
                    </Tag>
                );
            }
        }, {
            title: "Value",
            dataIndex: "value",
            ellipsis: true
        }];
        return columns;
    }

    const buildData = (): any[] => {
        const rows: any[] = [];
        const entries = Object.entries(props.configuration);
        let r = 0;
        for (const [key, value] of entries) {
            const row = {
                key: r++,
                name: key,
                type: value.type,
                value: formatValue(value.type, value.value)
            }
            rows.push(row);
        }
        return rows;
    }

    const formatValue = (type: IoType, value: any): any => {
        switch (type) {
            case IoType.TEXT:
            case IoType.NUMBER:
            case IoType.BOOLEAN:
                return value;
            default:
                return JSON.stringify(value);
        }
    }

    const buildRaw = (): ReactElement => {
        const content = JSON.stringify(props.configuration, undefined, 4);
        return (
            <div><pre>{content}</pre></div>
        );
    }

    const buildTable = (): ReactElement => {
        const columns = buildColumns();
        const rows = buildData();
        return (
            <Table
                bordered
                pagination={false}
                scroll={{ x: "max-content", y: Globals.DIALOG_HEIGHT_MAX }}
                size="small"
                columns={columns}
                dataSource={rows}
            />
        )
    }

    return (
        <div className="x-configurationviewer">
            <Spacer direction="vertical">
                <Spacer justification="right" fill>
                    <Link to="#" onClick={handleToggleRaw}>{showRaw ? "Show Formatted" : "Show Raw"}</Link>
                </Spacer>
                {showRaw &&
                    buildRaw()
                }
                {!showRaw &&
                    buildTable()
                }
            </Spacer>
        </div>
    );

}

ConfigurationViewer.defaultProps = defaultProps;
