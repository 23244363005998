import { ChangeEvent, PureComponent, ReactElement } from 'react';
import { Input } from 'antd';
import { Option } from '@methodset/endpoint-client-ts';
import { ItemSpec, MenuButton } from 'components/MenuButton/MenuButton';
import { Spacer } from 'components/Spacer/Spacer';
import update from 'immutability-helper';
import './OptionEditor.less';

export type ChangeCallback = (option: Option, index: number) => void;

export type OptionEditorProps = {
    index: number,
    items: ItemSpec[],
    option: Option,
    onChange: ChangeCallback
}

export class OptionEditor extends PureComponent<OptionEditorProps> {

    constructor(props: OptionEditorProps) {
        super(props);
        this.handleLabelChange = this.handleLabelChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
    }

    private handleLabelChange(e: ChangeEvent<HTMLInputElement>): void {
        const label = e.target.value;
        const option = update(this.props.option, {
            label: { $set: label }
        });
        this.props.onChange(option, this.props.index);
    }

    private handleValueChange(e: ChangeEvent<HTMLInputElement>): void {
        const value = e.target.value;
        const option = update(this.props.option, {
            value: { $set: value }
        });
        this.props.onChange(option, this.props.index);
    }

    public render(): ReactElement {
        return (
            <Spacer className="x-option">
                <Input
                    placeholder="Option label."
                    value={this.props.option.label}
                    onChange={this.handleLabelChange}
                />
                <Input
                    placeholder="Option value."
                    value={this.props.option.value}
                    onChange={this.handleValueChange}
                />
                <MenuButton 
                    data={this.props.option} 
                    items={this.props.items} 
                />
            </Spacer>
        );
    }

}
