import { PureComponent, ReactElement } from 'react';
import { ActiveCell, ModelContext } from 'context/ModelContext';
import { EmitterEvent } from 'utils/EventEmitter';
import { Input } from 'antd';
import './CellTracker.less';

export type CellTrackerProps = {};

export type CellTrackerState = {
    activeCell?: ActiveCell
}

export class CellTracker extends PureComponent<CellTrackerProps, CellTrackerState> {

    static contextType = ModelContext;

    constructor(props: CellTrackerProps) {
        super(props);
        this.state = {};
        this.handleCellFocusChangeEvent = this.handleCellFocusChangeEvent.bind(this);
    }

    private handleCellFocusChangeEvent(event: EmitterEvent): void {
        const activeCell = event.data as ActiveCell;
        this.setState({ activeCell: activeCell });
    }

    componentDidMount(): void {
        this.context.addCallback("CellFocusChange", this.handleCellFocusChangeEvent);
    }

    componentWillUnmount(): void {
        this.context.removeCallback("CellFocusChange", this.handleCellFocusChangeEvent);
    }

    public render(): ReactElement {
        const activeCell = this.state.activeCell!;
        let id;
        if (activeCell) {
            //id = activeCell.sheet.id === Sheet.PARAMETERS_SHEET ? activeCell.variableId : activeCell.id;
            id = activeCell.variableId ? activeCell.variableId : activeCell.id;
        }
        return (
            <div className="x-celltracker">
                <Input value={id} />
            </div>
        )
    }

}

