import React, { MouseEvent, ReactElement } from 'react';
import classNames from 'classnames';
import './Clickable.less';

export type ClickCallback = (data: any, event: MouseEvent<HTMLElement>) => void;

export type ClickableProps = {
    className?: string,
    data?: any,
    cursor?: string,
    children: any,
    disabled?: boolean,
    onClick: ClickCallback
} & typeof defaultProps;

const defaultProps = {
    cursor: "pointer",
    disabled: false
}

/**
 * Simple wrapper component that allows the props data
 * to be passed back in the click method. To use, move
 * the click handler from the wrapped component to the
 * clickable wrapper. Additionally, pass in a data
 * prop into the clickable. This can be any data that
 * will be passed back into the click handler.
 */
export const Clickable = (props: ClickableProps): ReactElement => {

    const handleClick = (e: MouseEvent<HTMLElement>): void => {
        if (props.disabled) {
            //e.stopPropagation();
            e.preventDefault();
        } else {
            props.onClick(props.data, e);
        }
    }

    const buildView = (): ReactElement => {
        if (props.children && props.children.props) {
            // Add a new click handler to the HTML component.
            const className = classNames("x-clickable", props.className, props.children.props.className);
            return React.cloneElement(props.children, { className: className, style: style, onClick: handleClick });
        } else {
            // No HTML component, create a div to capture clicks.
            return (
                <div className={classNames("x-clickable", props.className)} style={style} onClick={handleClick}>{props.children}</div>
            )
        }
    }

    const style = {
        cursor: props.disabled ? "not-allowed" : props.cursor,
        ...props.children?.props.style
    };

    return buildView();
}

Clickable.defaultProps = defaultProps;
