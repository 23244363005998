import { ChangeEvent, ReactElement } from 'react';
import { Button, Input } from 'antd';
import { Spacer } from 'components/Spacer/Spacer';
import { DeleteOutlined } from '@ant-design/icons';
import './ChangeEditor.less';

export type ChangeCallback = (text: string, index: number) => void;
export type RemoveCallback = (index: number) => void;

export type ChangeEditorProps = {
    index: number,
    text?: string,
    onChange: ChangeCallback,
    onRemove: RemoveCallback
}

export const ChangeEditor = (props: ChangeEditorProps): ReactElement => {

    const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        const text = e.target.value;
        props.onChange(text, props.index);
    }

    const handleChangeRemove = (): void => {
        props.onRemove(props.index);
    }

    return (
        <Spacer fill alignment="top">
            <Input.TextArea
                placeholder="Add a change message."
                rows={1}
                value={props.text}
                onChange={(e) => handleTextChange(e)}
            />
            <Button
                icon={<DeleteOutlined />}
                onClick={handleChangeRemove}
            >
            </Button>
        </Spacer>
    );

}
