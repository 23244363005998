import { JSXElementConstructor, ReactElement } from 'react';
import { Button, Select, SelectProps, Spin } from 'antd';
import { StatusType } from 'constants/StatusType';
import { Spacer } from 'components/Spacer/Spacer';
import './LoadSelect.less';

export type RetryHandler = () => void;

export type LoadSelectProps = Omit<SelectProps<any>, "loading" | "dropdownRender"> & {
    className?: string,
    children: ReactElement | ReactElement[],
    status: StatusType,
    loadMessage?: string,
    failMessage?: string,
    failLabel?: string,
    onRetry: RetryHandler
} & typeof defaultProps;

const defaultProps = {
    loadMessage: "Loading...",
    failMessage: "Failed to load options.",
    failLabel: "Retry"
}

export const LoadSelect = (props: LoadSelectProps): ReactElement => {

    const buildOptions = (menu: ReactElement<any, string | JSXElementConstructor<any>>): ReactElement<any, string | JSXElementConstructor<any>> => {
        if (props.status === StatusType.LOADING) {
            return (
                <div className="x-loadselect-load">
                    <Spacer>
                        <Spin />
                        <span className="x-loadselect-message">{props.loadMessage}</span>
                    </Spacer>
                </div>
            )
        } else if (props.status === StatusType.FAILED) {
            return (
                <div className="x-loadselect-fail">
                    <div className="x-loadselect-message">{props.failMessage}</div>
                    <Button type="primary" onClick={props.onRetry}>{props.failLabel}</Button>
                </div>
            )
        } else {
            return menu;
        }
    }

    const { children, value, status, loadMessage, failMessage, failLabel, onRetry, ...rest } = props;
    const loading = props.status === StatusType.LOADING;

    return (
        <Select
            {...rest}
            loading={loading}
            value={loading ? undefined : props.value}
            dropdownRender={menu => buildOptions(menu)}
        >
            {props.children}
        </Select>
    )
}

LoadSelect.defaultProps = defaultProps;
