import { ChangeEvent, ReactElement, useRef, useState } from 'react';
import { Alert, Condition, OpType } from '@methodset/application-client-ts';
import { CoreUtils } from 'utils/CoreUtils';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { Button, Input, Select } from 'antd';
import { Calculator, Variable } from '@methodset/calculator-ts';
import { Spacer } from 'components/Spacer/Spacer';
import './InstructionsEditor.less';
import { FormattedInput } from 'components/FormattedInput/FormattedInput';

type TextType = "input" | "condition";

export type ChangeCallback = (instructions: string) => void;

export type InstructionEditorProps = {
    alert: Alert,
    instructions?: string,
    calculator: Calculator,
    inputIds: string[],
    conditions: Condition[],
    onChange: ChangeCallback
}

export const InstructionsEditor = (props: InstructionEditorProps): ReactElement => {

    const inputRef = useRef<TextAreaRef>(null);

    const [type, setType] = useState<TextType>(props.inputIds.length > 0 ? "input" : "condition");
    const [value, setValue] = useState<string | number | undefined>();

    // Add variable ids to widget sync

    const handleInstructionsChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        const instructions = e.target.value;
        props.onChange(instructions);
    }

    const handleTypeChange = (type: TextType): void => {
        setType(type);
        setValue(undefined);
    }

    const handleValueChange = (value: string | number | undefined): void => {
        setValue(value);
    }

    const handleTextAdd = (): void => {
        if (CoreUtils.isEmpty(value)) {
            return;
        }
        let insert;
        if (type === "input") {
            insert = `input.${value as string}`;
        } else if (type === "condition") {
            const index = value as number;
            const condition = props.conditions[index];
            insert = `condition.${condition.variableId}`;
            if (condition.opType) {
                insert = `${insert}.${condition.opType}`;
            }
        } else {
            return;
        }
        const textArea = inputRef.current!.resizableTextArea!.textArea;
        const start = textArea.selectionStart;
        let pos = start ?? 0;
        let curr = textArea.value;
        let instructions = `${curr.slice(0, pos)}\${${insert}}${curr.slice(pos)}`;
        textArea.value = instructions;
        pos += insert.length + 3;
        textArea.setSelectionRange(pos, pos);
        props.onChange(instructions);
        inputRef.current?.focus();
    }

    const findVariable = (variableId: string): Variable | undefined => {
        return props.calculator.variables.get(variableId, false);
    }

    const buildSample = (): ReactElement => {
        const defaultValue = 100;
        if (type === "input") {
            const variableId = value as string;
            return (
                <FormattedInput
                    value={defaultValue}
                    variableId={variableId}
                    calculator={props.calculator}
                    useVariableValue={true}
                />
            )
        } else if (type === "condition") {
            const index = value as number;
            const condition = props.conditions[index];
            const variableId = condition.variableId;
            return (
                <>
                    {condition.opType &&
                        <span>{OpType.label(condition.opType)}&nbsp;</span>
                    }
                    <FormattedInput
                        value={defaultValue}
                        variableId={variableId}
                        calculator={props.calculator}
                        useVariableValue={true}
                    />
                </>
            )
        } else {
            return <></>;
        }
    }

    return (
        <div className="x-instructionseditor">
            <Input.TextArea
                ref={inputRef}
                rows={3}
                value={props.instructions}
                onChange={handleInstructionsChange}
            />
            <Spacer className="x-instructionseditor-insert">
                <span>Insert input:</span>
                <Select
                    className="x-instructionseditor-type"
                    placeholder="Text type."
                    value={type}
                    onChange={handleTypeChange}
                >
                    <Select.Option key="input" value="input">Variable</Select.Option>
                    <Select.Option key="condition" value="condition">Condition</Select.Option>
                </Select>
                {type === "input" &&
                    <Select
                        placeholder="Select a variable."
                        value={value as string}
                        onChange={handleValueChange}
                    >
                        {props.inputIds.map(inputId => {
                            const variable = findVariable(inputId);
                            if (variable) {
                                return (
                                    <Select.Option key={variable.id} value={variable.id}>{variable.name}</Select.Option>
                                )
                            } else {
                                return undefined;
                            }
                        })}
                    </Select>
                }
                {type === "condition" &&
                    <Select
                        placeholder="Select a condition."
                        value={value as number}
                        onChange={handleValueChange}
                    >
                        {props.conditions.map((condition, index) => {
                            const variableId = condition.variableId;
                            const variable = findVariable(variableId);
                            if (variable) {
                                let name = variable.name;
                                if (condition.opType) {
                                    name = `${name} ${OpType.symbol(condition.opType)}`;
                                }
                                return (
                                    <Select.Option key={index} value={index}>{name}</Select.Option>
                                )
                            } else {
                                return undefined;
                            }
                        })}
                    </Select>
                }
                <Button onClick={handleTextAdd}>Add</Button>
            </Spacer>
            {type && !CoreUtils.isEmpty(value) &&
                <div className="x-instructionseditor-example">
                    Sample text: "{buildSample()}"
                </div>
            }
        </div>
    )

}
