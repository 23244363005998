import { ReactElement } from 'react';
import { ExecutionError } from '@methodset/workflow-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { Label } from 'components/Label/Label';
import { Collapse } from 'antd';
import classNames from 'classnames';
import './ErrorView.less';

export type ErrorViewProps = {
    className?: string,
    error: ExecutionError
}

export const ErrorView = (props: ErrorViewProps): ReactElement => {

    const buildPanels = (error: ExecutionError | undefined, index: number, panels: ReactElement[] = []): ReactElement[] => {
        if (error) {
            const panel = (
                <Collapse.Panel header="Cause Details" key={index}>
                    {buildView(error, false)}
                </Collapse.Panel>
            );
            panels.push(panel);
            buildPanels(error.cause, index + 1, panels);
        }
        return panels;
    }

    const buildView = (error: ExecutionError, recurse: boolean): ReactElement => {
        return (
            <Spacer className="x-errorview-panels" direction="vertical" size="lg">
                <Label
                    label="Message"
                    placement="top"
                >
                    {error.message}
                </Label>
                <Label
                    label="Type"
                    placement="top"
                >
                    {error.type}
                </Label>
                <Label
                    label="Stack Trace"
                    placement="top"
                >
                    {error.stackTrace && error.stackTrace.map(message => (
                        <span>{message}</span>
                    ))}
                </Label>
                {error.cause && recurse &&
                    <Collapse className="x-errorview-causes">
                        {buildPanels(error.cause, 1)}
                    </Collapse>
                }
            </Spacer>
        )
    }

    return (
        <div className={classNames("x-errorview", props.className)}>
            {buildView(props.error, true)}
        </div>
    );

}
