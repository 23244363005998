import React, { Component, ReactElement } from 'react';
import { Checkbox, FormInstance, Radio, RadioChangeEvent } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { AreaChartWidgetConfiguration, LineType } from '@methodset/application-client-ts';
import { Calculator } from '@methodset/calculator-ts';
import { Info } from 'components/Info/Info';
import { Spacer } from 'components/Spacer/Spacer';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { CoreUtils } from 'utils/CoreUtils';
import update from 'immutability-helper';
import './AreaChartEditor.less';

export type DefaultCallback = (configuration: AreaChartWidgetConfiguration) => void;
export type ChangeCallback = (configuration: AreaChartWidgetConfiguration) => void;

export type AreaChartEditorProps = {
    formRef: React.RefObject<FormInstance>,
    configuration: AreaChartWidgetConfiguration,
    calculator: Calculator,
    onDefault: DefaultCallback,
    onChange: ChangeCallback
}

export class AreaChartEditor extends Component<AreaChartEditorProps> {

    static DefaultConfiguration = {
        lineType: LineType.STRAIGHT
    } as AreaChartWidgetConfiguration;

    constructor(props: AreaChartEditorProps) {
        super(props);
        this.handleLineChange = this.handleLineChange.bind(this);
        this.handleStackedChange = this.handleStackedChange.bind(this);
    }

    private handleLineChange(e: RadioChangeEvent): void {
        const lineType = e.target.value;
        const configuration = update(this.props.configuration, {
            lineType: { $set: lineType }
        });
        this.props.onChange(configuration);
    }

    private handleStackedChange(e: CheckboxChangeEvent): void {
        const isStacked = e.target.checked;
        const configuration = update(this.props.configuration, {
            isStacked: { $set: isStacked }
        });
        this.props.onChange(configuration);
    }

    public componentDidMount(): void {
        this.props.onDefault(AreaChartEditor.DefaultConfiguration);
    }

    public render(): ReactElement {
        return (
            <Spacer direction="vertical">
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    required={true}
                    label="Line Type"
                    name="line"
                    info="The type of line to connect data points."
                >
                    <Radio.Group
                        buttonStyle="solid"
                        value={this.props.configuration.lineType}
                        onChange={this.handleLineChange}
                    >
                        {LineType.array().map(type => (
                            <Radio value={type}>{CoreUtils.toProper(type)}</Radio>
                        ))}
                    </Radio.Group>
                </FormItem>
                <Checkbox
                    checked={this.props.configuration.isStacked}
                    onChange={this.handleStackedChange}
                >
                    <Info
                        label="Stack Datasets"
                        info="True to stack datasets and accumulate values."
                        bold={true}
                    />
                </Checkbox>
            </Spacer>
        );
    }

}
