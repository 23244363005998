import { ReferenceParser } from "@methodset/calculator-ts";
import { Alert } from '@methodset/application-client-ts';

export class AlertSync {

    public static parser = (alert: Alert): string[] => {
        const refs: string[] = [];
        refs.push(...ReferenceParser.extract(alert.message));
        for (const inputId of alert.inputIds) {
            refs.push(...ReferenceParser.extract(inputId, true));
        }
        for (const condition of alert.conditions) {
            refs.push(...ReferenceParser.extract(condition.variableId, true));
        }
        return refs;
    }

    public static updater = (alert: Alert, target: string, replacement: string): void => {
        alert.message = ReferenceParser.update(alert.message, target, replacement);
        for (let i = 0; i < alert.inputIds.length; i++) {
            alert.inputIds[i] = ReferenceParser.update(alert.inputIds[i], target, replacement, true);
        }
        for (let i = 0; i < alert.conditions.length; i++) {
            const condition = alert.conditions[i];
            condition.variableId = ReferenceParser.update(condition.variableId, target, replacement, true);
            alert.conditions[i] = condition;
        }
        const testInputs = alert.testInputs;
        for (let i = 0; i < testInputs.conditions.length; i++) {
            const condition = testInputs.conditions[i];
            condition.variableId = ReferenceParser.update(condition.variableId, target, replacement, true);
            testInputs.conditions[i] = condition;
        }
        alert.instructions = AlertSync.update(alert.instructions, target, replacement);
    }

    public static update = (text: string | undefined, target: string, replacement: string): string | undefined => {
        if (!text) {
            return text;
        }
        const regex = new RegExp(`\\$\\{(input|condition)\\.(${target})(\\.[a-zA-Z]*)?\\}`, "g");
        const replace = `$\${$1.${replacement}$3}`;
        return text.replaceAll(regex, replace);
    }

}
