import { Chapter } from 'components/Chapter/Chapter';
import { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DocFrame } from '../DocFrame/DocFrame';

export type OrganizationsDocProps = RouteComponentProps;

export const OrganizationsDoc = (props: OrganizationsDocProps): ReactElement => {

    return (
        <DocFrame title="Organizations" {...props}>
            <Chapter>
                <Chapter.Paragraph>
                    Organizations represent a working unit comprised of a set of people that are related in some way.
                    This may be a company or enterprise that are working on a common goal.
                    Organizations create a security boundary around activities and data used to ensure that
                    members of the organization can not gain access to those in another organization.
                </Chapter.Paragraph>
                <Chapter.Paragraph>
                    A person is only included in a single organization at a time. When first creating an account,
                    you were asked to either create a new organization or to join an existing organization.
                    However, afterwards you are able to join another organization, for example if you are a consultant
                    working with several companies that have separate organizations setup. While you can
                    switch between organizations if you have been given permissions, you are only part of
                    one organization at a time.
                </Chapter.Paragraph>
            </Chapter>
        </DocFrame>
    );

}
