import React, { PureComponent, ReactElement } from 'react';
import { FormInstance } from 'antd';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { Configuration, ConfigurationSpec, RangeDef } from '@methodset/endpoint-client-ts';
import { Model } from '@methodset/model-client-ts';
import { ModelPicker } from './ModelPicker/ModelPicker';
import { OverridesConfiguration } from './OverridesConfiguration/OverridesConfiguration';
import { RefEditor } from 'containers/Components/Widgets/RefEditor/RefEditor';
import { Calculator, CoreUtils, RangeComponents, ReferenceParser, RefType } from '@methodset/calculator-ts';
import classNames from 'classnames';
import update from 'immutability-helper';
import './RangeSelector.less';

export type ChangeCallback = (rangeDef: RangeDef) => void;

export type RangeSelectorProps = typeof RangeSelector.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    className?: string,
    rangeDef?: RangeDef,
    variables?: ConfigurationSpec[],
    label?: string,
    description?: string,
    onChange: ChangeCallback
}

export type RangeSelectorState = {
    model?: Model,
    calculator?: Calculator
}

export class RangeSelector extends PureComponent<RangeSelectorProps, RangeSelectorState> {

    static defaultProps = {
        rangeDef: {
            configuration: {}
        },
        label: "Range",
        description: "The data range that contains the desired data, i.e., A1:C5."
    }

    constructor(props: RangeSelectorProps) {
        super(props);
        this.state = {}
        this.handleModelChange = this.handleModelChange.bind(this);
        this.handleModelLoad = this.handleModelLoad.bind(this);
        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.handleConfigurationChange = this.handleConfigurationChange.bind(this);
    }

    private handleModelChange(modelId: string, version: number): void {
        const rangeDef = update(this.props.rangeDef, {
            modelId: { $set: modelId },
            version: { $set: version },
            sheetId: { $set: undefined as any },
            rangeId: { $set: undefined as any },
            configuration: { $set: {} }
        });
        this.props.onChange(rangeDef);
    }

    private handleModelLoad(model: Model): void {
        const calculator = Calculator.deserialize(model.calculator!);
        this.setState({
            model: model,
            calculator: calculator
        });
    }

    private handleRangeChange(refId: string | undefined): void {
        let rangeDef;
        if (!refId) {
            rangeDef = update(this.props.rangeDef, {
                sheetId: { $set: undefined as any },
                rangeId: { $set: undefined as any }
            });
        } else {
            // TODO: keep sheet/range together?
            const ref = ReferenceParser.parse(refId, false);
            if (ref && ref.type === RefType.RANGE) {
                const components = ref!.components as RangeComponents;
                rangeDef = update(this.props.rangeDef, {
                    sheetId: { $set: components.sheetId },
                    rangeId: { $set: components.rangeId }
                });
            } else {
                // Invalid range, validate will alert user.
                return;
            }
        }
        this.props.onChange(rangeDef);
    }

    private handleConfigurationChange(configuration: Configuration): void {
        const rangeRef = update(this.props.rangeDef, {
            configuration: { $set: configuration }
        });
        this.props.onChange(rangeRef);
    }

    private toRefId(): string | undefined {
        const sheetId = this.props.rangeDef.sheetId;
        const rangeId = this.props.rangeDef.rangeId;
        return !sheetId || !rangeId ? undefined : `${CoreUtils.sheetLabel(sheetId)}${rangeId}`;
    }

    public render(): ReactElement {
        const refId = this.toRefId();
        return (
            <div className={classNames('x-rangeselector', this.props.className)}>
                <ModelPicker
                    formRef={this.props.formRef}
                    modelId={this.props.rangeDef.modelId}
                    version={this.props.rangeDef.version}
                    onChange={this.handleModelChange}
                    onLoad={this.handleModelLoad}
                />
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Range"
                    name="range"
                    info="The range that contains the desired data."
                    valuePropName="refId"
                    required={true}
                >
                    <RefEditor
                        formRef={this.props.formRef}
                        refId={refId}
                        refTypes={[RefType.RANGE]}
                        allowClear={false}
                        calculator={this.state.calculator}
                        onChange={this.handleRangeChange}
                    />
                </FormItem>
                {this.state.model && this.state.calculator && this.props.variables &&
                    <OverridesConfiguration
                        formRef={this.props.formRef}
                        configuration={this.props.rangeDef.configuration}
                        variableSpecs={this.props.variables}
                        variables={this.state.calculator.variables}
                        onChange={this.handleConfigurationChange}
                    />
                }
            </div>
        );
    }

}
