import { ReactElement, useState } from 'react';
import { Spacer } from 'components/Spacer/Spacer';
import { Link } from 'react-router-dom';
import { ProcessorHeader } from '@methodset/endpoint-client-ts';
import './ProcessorView.less';
import { Clickable } from 'components/Clickable/Clickable';

export type SelectCallback = (header: ProcessorHeader) => void;

export type ProcessorViewProps = {
    index?: number,
    header: ProcessorHeader,
    onSelect: SelectCallback
}

export const ProcessorView = (props: ProcessorViewProps): ReactElement => {

    const handleClick = (): void => {
        props.onSelect(props.header);
    }

    return (
        <Clickable className="x-processorview" onClick={handleClick}>
            <div className="x-processorview-wrap">
                <div className="x-processorview-name">
                    {props.header.name}
                </div>
                <div className="x-processorview-description">
                    {props.header.description}
                </div>
            </div>
        </Clickable>
    )
}
