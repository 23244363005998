import { ReactElement } from 'react';
import classNames from 'classnames';
import './Frame.less';

export type FrameProps = {
    className?: string,
    children?: any,
    title?: string | ReactElement,
    description?: string,
    border?: boolean,
    extra?: ReactElement
} & typeof defaultProps;

const defaultProps = {
    border: true
}

export const Frame = (props: FrameProps): ReactElement => {

    return (
        <div className={classNames('x-frame', props.className)}>
            <div className="x-frame-top">
                <div className="x-frame-title">{props.title}</div>
                <div className="x-frame-extra">{props.extra}</div>
            </div>
            <div className={classNames("x-frame-body", {"x-frame-body-border": props.border})}>
                {props.description &&
                    <div className="x-frame-desc">{props.description}</div>
                }
                {props.children}
            </div>
        </div>
    )
}

Frame.defaultProps = defaultProps;
