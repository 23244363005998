import { ReactElement } from 'react';
import { Chapter } from 'components/Chapter/Chapter';
import { Italic } from 'components/Italic/Italic';
import { DocFrame } from '../DocFrame/DocFrame';
import { RouteComponentProps } from 'react-router-dom';
import './OverviewDoc.less';

export type OverviewDocProps = RouteComponentProps;

export const OverviewDoc = (props: OverviewDocProps): ReactElement => {

    return (
        <DocFrame title="Overview" {...props}>
            <Chapter>
                <Chapter.Paragraph>
                    MethodSet is a platform to manage financial data, create analytical models, and
                    track and monitor data in real-time. Data is updated automatically and
                    views can be created to stay up-to-date with the latest changes. The main
                    building blocks to the platform 
                    are <Italic>datasets</Italic>, <Italic>models</Italic>, <Italic>workflows</Italic>, and <Italic>dashboards</Italic>. Each 
                    of these components is used to build on each other so that processes can be constructed
                    to implement and support financial backend systems. Models are similar to spreadsheets
                    and contain the processing logic when working with data. <Italic>Applets</Italic> are 
                    composite views of the data that allow parts of your data model to be exposed to the user 
                    so that they may easily and quickly understand the meaning of the data. 
                    <Italic>Versioning</Italic> is used throughout the system to lock down implementation 
                    state so that references to a component remain stable.
                </Chapter.Paragraph>
                <Chapter.Paragraph>
                    The documentation discusses details on each of the major components, how they are used, 
                    and examples of how to build your own implementations. Please select an item in the menu
                    to get more information on a topic.
                </Chapter.Paragraph>
                {/* <Chapter.Paragraph>
                    Check out the overview demo below to see the main features in action.
                </Chapter.Paragraph>
                <div className="x-overviewdoc-video">
                    <iframe src="https://www.youtube.com/embed/OVYlyhyT8BU" title="Overview Demo" allow="fullscreen"></iframe>
                </div> */}
            </Chapter>
        </DocFrame>
    );

}
