import { Globals } from 'constants/Globals';
import { CoreUtils } from 'utils/CoreUtils';
import { FailureHandler, RestUtils, SuccessHandler } from 'utils/RestUtils';

class ModelService {

    public baseUrl = CoreUtils.apiUrl('model-service', 14005);

    public warm(): Promise<any> {
        const url = `${this.baseUrl}/warm`;
        return RestUtils.get(url, undefined, undefined, undefined, undefined, false);
    }

    public readModelHeaders(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const parameters = {
            access: CoreUtils.toLower(request.accessType),
            latest: request.useLatest,
            key: request.startKey,
            limit: request.maxRecords
        };
        const url = `${this.baseUrl}/models${RestUtils.buildQuery(parameters)}`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public createModel(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/models`;
        return RestUtils.post(url, request, onSuccess, onFailure, ownStatus, Globals.MESSAGE_CREATING);
    }

    public readModel(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/models/${request.modelId}/versions/${request.version}`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public readModelHeader(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/models/${request.modelId}/versions/${request.version}/header`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    public updateModel(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/models/${request.modelId}`;
        return RestUtils.put(url, request, onSuccess, onFailure, ownStatus, Globals.MESSAGE_SAVING);
    }

    public archiveModel(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/models/${request.modelId}`;
        return RestUtils.delete(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_DELETING);
    }

    public resetVersion(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/models/${request.modelId}/versions`;
        return RestUtils.put(url, request, onSuccess, onFailure, ownStatus, "Resetting version.");
    }

    public readModelVersions(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
        const url = `${this.baseUrl}/models/${request.modelId}/versions`;
        return RestUtils.get(url, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    }

    // public executeModel(request: any, onSuccess?: SuccessHandler, onFailure?: FailureHandler, ownStatus?: boolean): Promise<any> {
    //     const parameters = {
    //         version: request.modelVersion
    //     };
    //     const url = `${this.baseUrl}/models/${request.modelId}/execute${RestUtils.buildQuery(parameters)}`;
    //     return RestUtils.post(url, request, onSuccess, onFailure, ownStatus, Globals.MESSAGE_LOADING);
    // }

}

export default new ModelService();
