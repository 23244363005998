import { Component, ReactElement } from 'react';
import { Globals } from 'constants/Globals';
import { Processor } from '@methodset/endpoint-client-ts';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { RestUtils } from 'utils/RestUtils';
import { Justify } from 'components/Justify/Justify';
import endpointService from 'services/EndpointService';
import axios from 'axios';
import './ProcessorLoader.less';

export type LoadedCallback = (processor: Processor) => void;

export type ProcessorLoaderProps = {
    processorId: string,
    version: number | undefined
    onLoaded: LoadedCallback
}

export type ProcessorLoaderState = {
    // Status of loading data.
    status: string,
    // True if loading data.
    loading: boolean
}

export class ProcessorLoader extends Component<ProcessorLoaderProps, ProcessorLoaderState> {

    constructor(props: ProcessorLoaderProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            loading: false,
        }
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData(this.props.processorId, this.props.version);
    }

    private readProcessorRequest(id: string, version?: number): Promise<any> {
        const request = {
            processorId: id,
            version: version
        };
        return endpointService.readProcessor(request,
            (response: any) => this.readProcessorResponse(response),
            undefined, true
        );
    }

    private readProcessorResponse(response: any): void {
        const processor = response.data.processor;
        this.props.onLoaded(processor);
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSpinner
                className="x-processorloader-loading"
                status={isLoading ? "loading" : "failed"}
                onRetry={this.handleRetryLoad}
            />
        );
    }

    private loadData(id: string, version?: number) {
        const requests = [];
        requests.push(this.readProcessorRequest(id, version));
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    public componentDidMount() {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData(this.props.processorId, this.props.version);
        }
    }

    public shouldComponentUpdate(nextProps: ProcessorLoaderProps): boolean {
        if (nextProps.processorId !== this.props.processorId || nextProps.version !== this.props.version) {
            this.loadData(nextProps.processorId, nextProps.version);
            return false;
        } else {
            return true;
        }
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === Globals.STATUS_READY) {
            view = null;
        }
        return (
            <Justify justification="center">
                {view}
            </Justify>
        );
    }

}
