import { ReferenceParser } from "@methodset/calculator-ts";
import { WatchlistWidgetConfiguration, Widget } from '@methodset/application-client-ts';

export class WatchlistWidgetSync {

    public static parser = (widget: Widget): string[] => {
        const configuration = widget.configuration as WatchlistWidgetConfiguration;
        const refs: string[] = [];
        refs.push(...ReferenceParser.extract(configuration.hideId, true));
        refs.push(...ReferenceParser.extract(configuration.rangeId, true));
        refs.push(...ReferenceParser.extract(configuration.rowLink?.variableId, true));
        refs.push(...ReferenceParser.extract(configuration.rowLink?.rangeId, true));
        return refs;
    }

    public static updater = (widget: Widget, target: string, replacement: string): void => {
        const configuration = widget.configuration as WatchlistWidgetConfiguration;
        configuration.hideId = ReferenceParser.update(configuration.hideId, target, replacement, true);
        configuration.rangeId = ReferenceParser.update(configuration.rangeId, target, replacement, true);
        configuration.nameRangeId = ReferenceParser.update(configuration.nameRangeId, target, replacement, true);
        configuration.symbolRangeId = ReferenceParser.update(configuration.symbolRangeId, target, replacement, true);
        configuration.valueRangeId = ReferenceParser.update(configuration.valueRangeId, target, replacement, true);
        configuration.changeRangeId = ReferenceParser.update(configuration.changeRangeId, target, replacement, true);
        configuration.timeRangeId = ReferenceParser.update(configuration.timeRangeId, target, replacement, true);
        if (configuration.rowLink) {
            configuration.rowLink.variableId = ReferenceParser.update(configuration.rowLink.variableId, target, replacement, true);
            configuration.rowLink.variableId = ReferenceParser.update(configuration.rowLink.rangeId, target, replacement, true);
        }
    }

}
