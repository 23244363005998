import { ReactElement } from 'react';
import { Globals } from 'constants/Globals';
import { FormInstance, Input, Select } from 'antd';
import { ContactType, Phone, PhoneContact, PhoneType } from '@methodset/entity-client-ts';
import { FormItem } from 'components/FormItem/FormItem';
import { CoreUtils } from 'utils/CoreUtils';
import { Spacer } from 'components/Spacer/Spacer';
import { v4 as uuid } from "uuid";
import update from 'immutability-helper';
import './PhoneEditor.less';

export type ChangeCallback = (phone: PhoneContact) => void;

export type PhoneProps = {
    formRef: React.RefObject<FormInstance>,
    id: string,
    phone?: PhoneContact,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    phone: { 
        type: ContactType.PHONE,
        id: uuid() 
    } as PhoneContact
}

export const PhoneEditor = (props: PhoneProps): ReactElement => {

    const handleNumberChange = (number: string): void => {
        const phone = update(props.phone, {
            number: { $set: number }
        });
        props.onChange(phone);
    }

    const handleTypeChange = (phoneType: PhoneType): void => {
        const phone = update(props.phone, {
            phoneType: { $set: phoneType }
        });
        props.onChange(phone);
    }

    return (
        <Spacer>
            <FormItem
                {...Globals.FORM_LAYOUT}
                className="x-phoneeditor-number"
                formRef={props.formRef}
                label="Phone Number"
                name={`phone-number-${props.phone.id}`}
                rules={[{
                    required: true,
                    message: 'Enter a number.'
                }]}
            >
                <Input
                    placeholder="Phone number."
                    value={props.phone.number}
                    onChange={(e) => handleNumberChange(e.target.value)}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                className="x-phoneeditor-type"
                formRef={props.formRef}
                label="Type"
                name={`phone-type-${props.phone.id}`}
                rules={[{
                    required: true,
                    message: 'Enter a type.'
                }]}
            >
                <Select
                    value={props.phone.phoneType}
                    onChange={(phoneType) => handleTypeChange(phoneType)}
                >
                    {CoreUtils.enumToKeys(PhoneType).map(key => (
                        <Select.Option key={key} value={key}>{CoreUtils.toProper(key)}</Select.Option>
                    ))}
                </Select>
            </FormItem>
        </Spacer>
    )
}

PhoneEditor.defaultProps = defaultProps;
