import { PureComponent, ReactElement } from 'react';
import { Radio, RadioChangeEvent, Select, Space } from 'antd';
import classNames from 'classnames';
import './DaysOfMonthSelector.less';
import { Spacer } from 'components/Spacer/Spacer';

export type ChangeCallback = (days: number[]) => void;

export type DaysOfMonthSelectorState = {
    days: number[]
}

export type DaysOfMonthSelectorProps = typeof DaysOfMonthSelector.defaultProps & {
    className?: string,
    value?: number[],
    onChange: ChangeCallback
}

// NOTE: currently only supports selection of a single value.
export class DaysOfMonthSelector extends PureComponent<DaysOfMonthSelectorProps, DaysOfMonthSelectorState> {

    static defaultProps = {
        value: [1]
    }

    // TODO: support multiple days of month
    constructor(props: DaysOfMonthSelectorProps) {
        super(props);
        this.state = {
            days: this.props.value
        };
        this.handleDayChange = this.handleDayChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
    }

    private handleTypeChange(e: RadioChangeEvent): void {
        const type = e.target.value;
        if (type === 'last') {
            this.props.onChange([0]);
        } else {
            this.props.onChange(this.state.days);
        }
    }

    private handleDayChange(day: number): void {
        if (this.dayType() === 'day') {
            this.props.onChange([day]);
        }
        this.setState({ days: [day] });
    }

    private dayType() {
        return (this.props.value.length === 1 && this.props.value[0] === 0) ? 'last' : 'day';
    }

    private buildDayOptions(start: number, max: number, skip: number): ReactElement[] {
        let options = [];
        for (let i = start; i <= max; i += skip) {
            options.push(
                <Select.Option key={i} value={i}>{i}</Select.Option>
            );
        }
        return options;
    }

    public render(): ReactElement {
        const type = this.dayType();
        const days = type === 'last' ? this.state.days : this.props.value;
        return (
            <div className={classNames('x-domselector', this.props.className)}>
                <Radio.Group
                    value={type}
                    onChange={this.handleTypeChange}
                >
                    <Spacer direction="vertical">
                        <div>
                            <Radio
                                className="x-domselector-radio"
                                value="day"
                            >
                                Day of month
                            </Radio>
                            <Select
                                className="x-domselector-day"
                                disabled={type === 'last'}
                                value={days[0]}
                                onChange={this.handleDayChange}>
                                {this.buildDayOptions(1, 31, 1)}
                            </Select>
                        </div>
                        <div>
                            <Radio
                                className="x-domselector-radio"
                                value="last"
                            >
                                Last day of month
                            </Radio>
                        </div>
                    </Spacer>
                </Radio.Group>
            </div>
        )
    }
}
