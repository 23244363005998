import React, { Component, KeyboardEvent, MouseEvent, ReactElement } from 'react';
import { Cell, CellChangeCallback, CellChangeEvent, Parameter } from '@methodset/calculator-ts';
import { ActiveCell, ModelContext } from 'context/ModelContext';
import { CoreUtils } from 'utils/CoreUtils';
import { KeyCode, KeyUtils } from 'utils/KeyUtils';
import { EditorCancelEvent, EditorChangeEvent, ParameterEditor } from './ParameterEditor/ParameterEditor';
import { EmitterEvent } from 'utils/EventEmitter';
import { ErrorCell } from '../../../Sheets/SheetItem/CellRenderer/ErrorCell/ErrorCell';
import { FormulaCell } from '../../../Sheets/SheetItem/CellRenderer/FormulaCell/FormulaCell';
import classNames from 'classnames';
import './ParameterCell.less';
import { Select } from 'antd';

export type ParameterCellProps = {
    className?: string,
    cell: Cell,
    onChange: CellChangeCallback,
}

export type ParameterCellState = {
    isEditing: boolean,
}

export class ParameterCell extends Component<ParameterCellProps, ParameterCellState> {

    static contextType = ModelContext;

    private editorRef = React.createRef<ParameterEditor>();
    private eventKey: string | undefined;
    private charPress: string | undefined;

    constructor(props: ParameterCellProps) {
        super(props);
        this.state = {
            isEditing: false
        }
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleDoubleClick = this.handleDoubleClick.bind(this);
        this.handleCellChange = this.handleCellChange.bind(this);
        this.handleEditChange = this.handleEditChange.bind(this);
        this.handleEditCancel = this.handleEditCancel.bind(this);
        this.handleFormulaChangeEvent = this.handleFormulaChangeEvent.bind(this);
        this.handleFormulaFinishEvent = this.handleFormulaFinishEvent.bind(this);
        this.handleCellFocusChangeEvent = this.handleCellFocusChangeEvent.bind(this);
        this.handleCellFocusChange = this.handleCellFocusChange.bind(this);
    }

    private handleKeyDown(e: KeyboardEvent<HTMLInputElement>): void {
        if (!KeyUtils.isCommit(e) && !KeyUtils.isArrow(e) && !KeyUtils.isCancel(e)) {
            // Not a navigation key, check if for special cases.
            if (!this.state.isEditing) {
                if (KeyUtils.isPrintable(e)) {
                    // Start the editor on a key press.
                    this.eventKey = e.key;
                    this.setState({ isEditing: true });
                    e.preventDefault();
                    e.stopPropagation();
                } else if (KeyUtils.isDelete(e)) {
                    // Delete cell value even if not in edit mode.
                    this.props.cell.clear();
                    const data = {
                        value: undefined
                    }
                    this.context.sendEvent("CellEditChange", data);
                }
            }
            return;
        }
        if (e.code == KeyCode.ARROW_LEFT || e.code == KeyCode.ARROW_RIGHT) {
            return;
        }
        this.navigate(e);
        e.preventDefault();
        e.stopPropagation();
    }

    private navigate(e: KeyboardEvent): void {
        if (KeyUtils.is(e, KeyCode.NONE)) {
        //if (e.code === KeyCode.NONE) {
            return;
        }
        const parameters = this.context.calculator.parameters;
        const count = parameters.length;
        if (count === 1) {
            return;
        }
        let row = this.props.cell.row;
        if (KeyUtils.in(e, KeyCode.ENTER, KeyCode.TAB, KeyCode.ARROW_DOWN)) {
        //if (e.code === KeyCode.ENTER || e.code === KeyCode.TAB || e.code === KeyCode.ARROW_DOWN) {
            // Move to cell below.
            row += 1;
            if (row > count - 1) {
                row = 0;
            }
        } else if (KeyUtils.is(e, KeyCode.ARROW_UP)) {
            //} else if (e.code === KeyCode.ARROW_UP) {
            row -= 1;
            // Move to cell up.
            if (row < 0) {
                row = count - 1;
            }
        } else if (KeyUtils.is(e, KeyCode.ESCAPE)) {
        //} else if (e.code === KeyCode.ESCAPE) {
            // Keep in same position.
        } else {
            return;
        }
        const param = parameters.get(row);
        this.focusParameter(param.id);
    }

    private focusParameter(cellId: string): void {
        const el = document.getElementById(`parameter-${cellId}`);
        if (el) {
            el.focus();
        }
    }

    private syncCellWithEditor(): void {
        const cell = this.props.cell;
        const value = CoreUtils.toEditValue(cell);
        const data = {
            value: value
        }
        this.context.sendEvent("CellEditChange", data);
    }

    private handleDoubleClick(e: MouseEvent<HTMLInputElement>): void {
        this.setState({ isEditing: true });
    }

    private handleCellChange(event: CellChangeEvent): void {
        // Cell value was updated.
        this.props.onChange(event);
    }

    private handleEditChange(event: EditorChangeEvent): void {
        const cell = this.props.cell;
        const value = event.value;
        this.setCellValue(cell, value);
        this.setState({ isEditing: false });
        //this.navigate(event.key);
        const e = { code: event.code } as KeyboardEvent;
        this.navigate(e);
    }

    private handleEditCancel(event: EditorCancelEvent): void {
        this.setState({ isEditing: false });
        this.focusParameter(this.props.cell.id);
    }

    private handleFormulaChangeEvent(event: EmitterEvent): void {
        // Input change event from formula editor.
        if (!this.state.isEditing) {
            this.eventKey = event.data.value;
            this.charPress = "FormulaEditor";
            this.setState({ isEditing: true });
        }
    }

    private handleFormulaFinishEvent(event: EmitterEvent): void {
        const code = event.data.code;
        const e = { code: code } as KeyboardEvent;
        if (KeyUtils.isCommit(e)) {
            const value = this.editorRef.current?.getValue();
            this.setCellValue(this.props.cell, value);
        } else if (KeyUtils.isCancel(e)) {
            const cell = this.props.cell;
            const value = cell.formula ? cell.formula : cell.value;
            const data = {
                value: value
            }
            this.context.sendEvent("CellEditChange", data);
        }
        this.setState({ isEditing: false });
        this.navigate(e);
    }

    private handleCellFocusChangeEvent(event: EmitterEvent): void {
        // console.info(`Parameter focus event: ${event.data.id}`);
        const parameter = this.props.cell as Parameter;
        const activeCell = event.data as ActiveCell;
        if (activeCell.prev?.variableId === parameter.variable!.id && activeCell.prev.sheet === parameter.sheet &&
            (activeCell.variableId !== parameter.variable!.id || activeCell.sheet !== parameter.sheet)) {
            // Another cell has gained focus, stop editing.
            if (this.state.isEditing) {
                const value = this.editorRef.current?.getValue();
                this.setCellValue(this.props.cell, value);
                this.setState({ isEditing: false });
            }
            // console.info(`Parameter remove edit handler: ${this.props.cell.id}`);
            this.context.removeCallback("FormulaEditChange", this.handleFormulaChangeEvent);
            this.context.removeCallback("FormulaEditFinish", this.handleFormulaFinishEvent);
        } else if (activeCell.variableId === parameter.variable!.id && activeCell.sheet === parameter.sheet &&
            (activeCell.prev?.variableId !== parameter.variable!.id || activeCell.prev.sheet !== parameter.sheet)) {
            // console.log(`Parameter add edit handler: ${this.props.cell.id}`);
            this.context.addCallback("FormulaEditChange", this.handleFormulaChangeEvent);
            this.context.addCallback("FormulaEditFinish", this.handleFormulaFinishEvent);
        }
    }

    private handleCellFocusChange(): void {
        // console.log(`Parameter focus: ${this.props.cell.id}`);
        const parameter = this.props.cell as Parameter;
        this.context.setFocusedCell(parameter.sheet, parameter.id, parameter.variable!.id);
        this.syncCellWithEditor();
    }

    private setCellValue(cell: Cell, value: any): void {
        if (CoreUtils.isFormula(value)) {
            cell.formula = value;
        } else if (!value) {
            cell.value = undefined;
        } else {
            cell.value = value;
        }
    }

    private buildView(): ReactElement {
        if (this.state.isEditing) {
            // Prevent editing of special functions that have custom editors.
            const queryCell = this.props.cell?.sheet.tracker.fnCell(this.props.cell, "QUERY");
            const pivotCell = this.props.cell?.sheet.tracker.fnCell(this.props.cell, "PIVOT");
            const hasQuery = queryCell && queryCell.primary ? true : false;
            const hasPivot = !!pivotCell;

            const eventKey = this.eventKey;
            const charPress = this.charPress;
            this.eventKey = undefined;
            this.charPress = undefined;

            return (
                <ParameterEditor
                    ref={this.editorRef}
                    eventKey={eventKey}
                    charPress={charPress}
                    disabled={hasQuery || hasPivot}
                    cell={this.props.cell}
                    onChange={this.handleEditChange}
                    onCancel={this.handleEditCancel}
                />
            )
        } else {
            let view;
            const value = CoreUtils.toDisplayValue(this.props.cell, true);
            if (this.props.cell.hasError()) {
                view = <ErrorCell className="x-parametercell-value x-parametercell-mark" value={value} cell={this.props.cell} />;
            } else if (this.props.cell.hasFormula()) {
                view = <FormulaCell className="x-parametercell-value x-parametercell-mark" value={value} />;
            } else if (this.props.cell.variable?.options) {
                return (
                    <Select
                        className="x-parametercell-options"
                        value={value}
                        onChange={(value) => this.props.cell.value = value}
                    >
                        {this.props.cell.variable.options.map(option => (
                            <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                        ))}
                    </Select>
                )
            } else {
                view = <div className="x-parametercell-value">{value}</div>;
            }
            return (
                <div
                    className="x-parametercell-noedit"
                    onDoubleClick={this.handleDoubleClick}
                >
                    {view}
                </div>
            )
        }
    }

    public componentDidMount(): void {
        this.props.cell.addCallback("Change", this.handleCellChange);
        this.context.addCallback("CellFocusChange", this.handleCellFocusChangeEvent);
    }

    public componentWillUnmount(): void {
        this.props.cell.removeCallback("Change", this.handleCellChange);
        this.context.removeCallback("CellFocusChange", this.handleCellFocusChangeEvent);
    }

    public render(): ReactElement {
        return (
            <div
                className={classNames(
                    "x-parametercell",
                    { "x-parametercell-edit": this.state.isEditing }
                )}
                id={`parameter-${this.props.cell.id}`}
                tabIndex={0}
                onKeyDown={this.handleKeyDown}
                onFocus={this.handleCellFocusChange}
            >
                {this.buildView()}
            </div>
        )
    }

}
