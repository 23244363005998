import { PureComponent, ReactElement } from 'react';
import { Button, FormInstance } from 'antd';
import { Spacer } from 'components/Spacer/Spacer';
import { InputVariableEditor } from './InputVariableEditor/InputVariableEditor';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { Calculator } from '@methodset/calculator-ts';
import update from 'immutability-helper';
import './InputVariables.less';

export type ChangeCallback = (variables: string[]) => void;

export type InputVariablesProps = typeof InputVariables.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    calculator: Calculator,
    variableIds?: string[],
    onChange: ChangeCallback
}

export class InputVariables extends PureComponent<InputVariablesProps> {

    static defaultProps = {
        variableIds: [] as string[]
    }

    constructor(props: InputVariablesProps) {
        super(props);
        this.handleVariableAdd = this.handleVariableAdd.bind(this);
        this.handleVariableChange = this.handleVariableChange.bind(this);
        this.handleVariableRemove = this.handleVariableRemove.bind(this);
    }

    private handleVariableAdd(): void {
        const variables = update(this.props.variableIds, {
            $push: [null as any]
        });
        this.props.onChange(variables);
    }

    private handleVariableRemove(index: number): void {
        const options = update(this.props.variableIds, {
            $splice: [[index, 1]]
        });
        this.props.onChange(options);
    }

    private handleVariableChange(variableId: string, index: number): void {
        const variableIds = update(this.props.variableIds, {
            [index]: { $set: variableId }
        });
        this.props.onChange(variableIds);
    }

    public render(): ReactElement {
        return (
            <Spacer className="x-inputvariables" direction="vertical" alignment="top" fill>
                {this.props.variableIds.map((variableId, index) => (
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        key={index}
                        label={`Variable ${index + 1}`}
                        name={`flow-variable-${index + 1}`}
                        info="The variable to include as an input."
                        valuePropName="variableId"
                        rules={[{
                            required: true,
                            message: ""
                        }]}
                    >
                        <InputVariableEditor
                            formRef={this.props.formRef}
                            index={index}
                            calculator={this.props.calculator}
                            variableId={variableId}
                            onChange={this.handleVariableChange}
                            onRemove={this.handleVariableRemove}
                        />
                    </FormItem>
                ))}
                <div className="x-inputvariables-add">
                    <Button onClick={this.handleVariableAdd}>Add Variable</Button>
                </div>
            </Spacer>
        );
    }
}
