import { FC, ReactElement } from "react"
import { DownOutlined } from "@ant-design/icons"
import { Button, Dropdown, Menu } from "antd"
import { CoreUtils } from "utils/CoreUtils"
import { RowEditor, RowSpec, SortOrder } from "./RowEditor/RowEditor";
import { ValueEditor, ValueSpec } from "./ValueEditor/ValueEditor";
import { FilterEditor, FilterSpec } from "./FilterEditor/FilterEditor";
import { ColumnEditor, ColumnSpec } from "./ColumnEditor/ColumnEditor";
import { Range } from '@methodset/calculator-ts';
import update from 'immutability-helper';
import './SpecsEditor.less';

export type ChangeCallback = (type: SpecType, specs: Spec[]) => void;

export enum SpecType {
    ROWS = "rows",
    COLUMNS = "columns",
    VALUES = "values",
    FILTERS = "filters"
}

export type Spec = any[];

export enum SpecIndex {
    COLUMN_INDEX = 0
}

type Props = {
    type: SpecType,
    headers: string[],
    specs: Spec[],
    range?: Range,
    onChange: ChangeCallback
}

export const SpecsEditor: FC<Props> = (props: Props): ReactElement => {

    const handleHeaderSelect = (header: any, index: number): void => {
        // Default values for each spec on initialization.
        let spec: any[];
        if (props.type === SpecType.ROWS) {
            spec = [index, SortOrder.ASCENDING, false, false];
        } else if (props.type === SpecType.COLUMNS) {
            spec = [index, SortOrder.ASCENDING, false];
        } else if (props.type === SpecType.VALUES) {
            spec = [index, "COUNTA"];
        } else if (props.type === SpecType.FILTERS) {
            spec = [index, props.range ? props.range.uniqueValues(index) : []];
        } else {
            return;
        }
        const specs = update(props.specs, {
            $push: [spec]
        });
        props.onChange(props.type, specs);
    }

    const handleSpecChange = (spec: Spec): void => {
        const index = props.specs.findIndex(s => s[SpecIndex.COLUMN_INDEX] === spec[SpecIndex.COLUMN_INDEX]);
        if (index === -1) {
            return;
        }
        const specs = update(props.specs, {
            [index]: { $set: spec }
        });
        props.onChange(props.type, specs);
    }

    const handleSpecDelete = (spec: Spec): void => {
        const index = props.specs.findIndex(s => s[SpecIndex.COLUMN_INDEX] === spec[SpecIndex.COLUMN_INDEX]);
        if (index === -1) {
            return;
        }
        const specs = update(props.specs, {
            $splice: [[index, 1]]
        });
        props.onChange(props.type, specs);
    }

    const isUsed = (index: number): boolean => {
        return props.specs.findIndex(spec => spec[SpecIndex.COLUMN_INDEX] === index) !== -1;
    }

    const menu = (): ReactElement => {
        return (
            <Menu className="x-specseditor-menu">
                {props.headers.map((header, index) => (
                    <Menu.Item 
                        key={header} 
                        disabled={isUsed(index)}
                        onClick={(e) => handleHeaderSelect(e.key, index)}
                    >
                        {header}
                    </Menu.Item>
                ))}
            </Menu>
        );
    }

    return (
        <div className="x-specseditor">
            <div className="x-specseditor-header">
                <span className="x-specseditor-label">
                    {CoreUtils.toCapital(props.type)}
                </span>
                <Dropdown overlay={menu} trigger={['click']} disabled={props.headers.length === 0}>
                    <Button>
                        Add
                        <DownOutlined />
                    </Button>
                </Dropdown>
            </div>
            <div className="x-specseditor-body">
                {props.range && props.type === SpecType.ROWS && props.specs.map((spec, index) => (
                    <RowEditor
                        key={props.headers[spec[SpecIndex.COLUMN_INDEX]]}
                        header={props.headers[spec[SpecIndex.COLUMN_INDEX]]}
                        spec={spec as RowSpec}
                        isLeaf={index === props.specs.length - 1}
                        onChange={handleSpecChange}
                        onDelete={handleSpecDelete}
                    />
                ))}
                {props.range && props.type === SpecType.COLUMNS && props.specs.map((spec, index) => (
                    <ColumnEditor
                        key={props.headers[spec[SpecIndex.COLUMN_INDEX]]}
                        header={props.headers[spec[SpecIndex.COLUMN_INDEX]]}
                        spec={spec as ColumnSpec}
                        isLeaf={index === props.specs.length - 1}
                        onChange={handleSpecChange}
                        onDelete={handleSpecDelete}
                    />
                ))}
                {props.range && props.type === SpecType.VALUES && props.specs.map((spec) => (
                    <ValueEditor
                        key={props.headers[spec[SpecIndex.COLUMN_INDEX]]}
                        header={props.headers[spec[SpecIndex.COLUMN_INDEX]]}
                        spec={spec as ValueSpec}
                        onChange={handleSpecChange}
                        onDelete={handleSpecDelete}
                    />
                ))}
                {props.range && props.type === SpecType.FILTERS && props.specs.map((spec) => (
                    <FilterEditor
                        key={props.headers[spec[SpecIndex.COLUMN_INDEX]]}
                        header={props.headers[spec[SpecIndex.COLUMN_INDEX]]}
                        spec={spec as FilterSpec}
                        range={props.range!}
                        onChange={handleSpecChange}
                        onDelete={handleSpecDelete}
                    />
                ))}
            </div>
        </div>
    )

}
