import { ReactElement, useState } from 'react';
import { FormInstance, Input, Select } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Loop, RecordsLoop } from '@methodset/endpoint-client-ts';
import { IntegerInput } from 'components/IntegerInput/IntegerInput';
import update from 'immutability-helper';
import './RecordsLoopEditor.less';

type ColumnType = "FIELD" | "INDEX";

export type ChangeCallback = (loop: Loop) => void;

export type RecordsLoopEditorProps = {
    formRef: React.RefObject<FormInstance>,
    loop: RecordsLoop,
    onChange: ChangeCallback
}

export const RecordsLoopEditor = (props: RecordsLoopEditorProps): ReactElement => {

    const isNumber = (value: any): boolean => {
        const integer = parseInt(value);
        return !isNaN(integer);
    }

    const [columnType, setColumnType] = useState<ColumnType>(isNumber(props.loop.reference) ? "INDEX" : "FIELD");

    const handleColumnTypeChange = (columnType: ColumnType): void => {
        setColumnType(columnType);
        const loop = update(props.loop, {
            $unset: ["reference"]
        });
        props.onChange(loop);
    }

    const handleReferenceChange = (reference: number | string): void => {
        const loop = update(props.loop, {
            reference: { $set: reference as any }
        });
        props.onChange(loop);
    }

    return (
        <div className="x-recordsloopeditor">
            <FormItem
                formRef={props.formRef}
                className="x-recordsloopeditor-column"
                name="column"
                label="Column"
                labelFormat="standard"
                bold={true}
                rules={[{
                    required: true,
                    message: "Select a column type."
                }]}
            >
                <Select
                    defaultValue="FIELD"
                    value={columnType}
                    onChange={(value) => handleColumnTypeChange(value)}
                >
                    <Select.Option key="FIELD" value="FIELD">Field</Select.Option>
                    <Select.Option key="INDEX" value="INDEX">Index</Select.Option>
                </Select>
            </FormItem>
            {columnType === "FIELD" &&
                <FormItem
                    formRef={props.formRef}
                    name="field"
                    label="Field"
                    labelFormat="standard"
                    bold={true}
                    rules={[{
                        required: true,
                        message: "Column field."
                    }]}
                >
                    <Input
                        placeholder="Column name."
                        value={props.loop.reference as string}
                        onChange={(e) => handleReferenceChange(e.target.value)}
                    />
                </FormItem>
            }
            {columnType === "INDEX" &&
                <FormItem
                    formRef={props.formRef}
                    name="index"
                    label="Index"
                    labelFormat="standard"
                    bold={true}
                    rules={[{
                        required: true,
                        message: "Column index."
                    }]}
                >
                    <IntegerInput
                        natural={true}
                        value={props.loop.reference as number}
                        onChange={(value) => handleReferenceChange(value)}
                    />
                </FormItem>
            }
        </div>
    )
}
