import { ReactElement } from 'react';
import { Select } from 'antd';
import { LocationType } from '@methodset/application-client-ts';

export type ChangeCallback = (value: LocationType) => void;

export type LocationSelectorProps = {
    value?: LocationType,
    //defaultValue?: LocationType,
    includes?: LocationType[],
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    includes: [LocationType.LEFT, LocationType.RIGHT, LocationType.TOP, LocationType.BOTTOM]
}

export const LocationSelector = (props: LocationSelectorProps): ReactElement => {
    return (
        <Select
            allowClear={true}
            placeholder="Select a location."
            value={props.value}
            //value={props.value ? props.value.toUpperCase() as LocationType : undefined}
            onChange={(value) => props.onChange(value)}
        >
            {props.includes.includes(LocationType.LEFT) &&
                <Select.Option key="left" value={LocationType.LEFT}>Left</Select.Option>
            }
            {props.includes.includes(LocationType.RIGHT) &&
                <Select.Option key="right" value={LocationType.RIGHT}>Right</Select.Option>
            }
            {props.includes.includes(LocationType.TOP) &&
                <Select.Option key="top" value={LocationType.TOP}>Top</Select.Option>
            }
            {props.includes.includes(LocationType.BOTTOM) &&
                <Select.Option key="bottom" value={LocationType.BOTTOM}>Bottom</Select.Option>
            }
        </Select>
    )
}

LocationSelector.defaultProps = defaultProps;
