import { ReactElement } from 'react';
import { FormInstance } from 'antd';
import { ModelHeader } from '@methodset/model-client-ts';
import { AccessType } from '@methodset/commons-core-ts';
import { ItemSelect } from 'components/ItemSelect/ItemSelect';
import { ModelView } from './ModelView/ModelView';
import modelService from 'services/ModelService';
import './ModelList.less';

export type ChangeCallback = (header: ModelHeader) => void;

export type ModelListProps = {
    formRef: React.RefObject<FormInstance>,
    modelId?: string,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
}

export const ModelList = (props: ModelListProps): ReactElement => {

    const handleModelChange = (header: ModelHeader): void => {
        props.onChange(header);
    }

    const readModelHeadersRequest = (accessType: AccessType): Promise<any> => {
        // TODO: remove use latest, true if version === latest
        const request = {
            accessType: accessType,
            useLatest: accessType === AccessType.UNPUBLISHED ? false : true
        };
        return modelService.readModelHeaders(request,
            (response: any) => readModelHeadersResponse(response),
            undefined, true
        );
    }

    const readModelHeadersResponse = (response: any): ModelHeader[] => {
        const headers: ModelHeader[] = response.data.headers;
        headers.sort((a, b) => a.name.localeCompare(b.name));
        return headers;
    }

    return (
        <div className="x-modellist">
            <ItemSelect<ModelHeader>
                formRef={props.formRef}
                selectedId={props.modelId}
                label={"model"}
                view={ModelView}
                loader={readModelHeadersRequest}
                onChange={handleModelChange}
            />
        </div>
    );

}

ModelList.defaultProps = defaultProps;
