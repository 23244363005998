import { ChangeEvent, ReactElement, useState } from 'react';
import { FormInstance, Input, Switch } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { AccessRules } from '@methodset/entity-client-ts';
import update from 'immutability-helper';
import './AccessRulesEditor.less';

interface FormData {
    isEnabled: boolean,
    domains?: string;
    whitelist?: string;
    blacklist?: string;
}

export type ChangeCallback = (rules: AccessRules) => void;

export type RulesEditorProps = {
    formRef: React.RefObject<FormInstance>,
    accessRules: AccessRules,
    onChange: ChangeCallback
}

export const AccessRulesEditor = (props: RulesEditorProps): ReactElement => {

    const toList = (value: string | undefined): string[] => {
        return value ? value.split(/[ ,;]+/) : [];
    }

    const toString = (values: string[]): string | undefined => {
        return values && values.length > 0 ? values.join(", ") : undefined;
    }

    const [data, setData] = useState<FormData>({
        isEnabled: props.accessRules.isEnabled,
        domains: toString(props.accessRules.domains),
        whitelist: toString(props.accessRules.whitelist),
        blacklist: toString(props.accessRules.blacklist)
    });

    const handleBlur = () => {
        const accessRules: AccessRules = {
            isEnabled: data.isEnabled,
            domains: toList(data.domains),
            whitelist: toList(data.whitelist),
            blacklist: toList(data.blacklist)
        }
        props.onChange(accessRules);
    }

    const handleEnableChange = (isEnabled: boolean): void => {
        const updated = update(data, {
            isEnabled: { $set: isEnabled }
        });
        setData(updated);
    }

    const handleDomainsChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const domains = e.target.value;
        const updated = update(data, {
            domains: { $set: domains }
        });
        setData(updated);
    }

    const handleWhitelistChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const whitelist = e.target.value;
        const updated = update(data, {
            whitelist: { $set: whitelist }
        });
        setData(updated);
    }

    const handleBlacklistChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const blacklist = e.target.value;
        const updated = update(data, {
            blacklist: { $set: blacklist }
        });
        setData(updated);
    }

    return (
        <>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Enable Registration"
                name="enable"
                info="Enables the ability of users to join the organization."
                valuePropName="checked"
            >
                <Switch
                    checked={data.isEnabled}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={handleEnableChange}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Domains"
                name="domains"
                info="A comma separated list of email domains for the organization, i.e., 'example.com'. Any user that registers with an email address that matches one of the domains will be allowed to join the organization."
                rules={[{
                    validator: (rule: any, value: string) => {
                        const domains = toList(value);
                        for (const domain of domains) {
                            return !domain || domain.includes(".") ? Promise.resolve() : Promise.reject("Invalid domain format.");
                        }
                    }
                }]}
            >
                <Input
                    id="domain"
                    placeholder="Organization domain."
                    value={data.domains}
                    onBlur={handleBlur}
                    onChange={handleDomainsChange}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Whitelist"
                name="whitelist"
                info="A comma separated list of user email addresses that are allowed to join the organization."
                rules={[{
                    validator: (rule: any, value: string) => {
                        const addresses = toList(value)
                        for (const address of addresses) {
                            if (!address.includes("@")) {
                                return Promise.reject(`Address '${address}' is invalid.`);
                            }
                        }
                        return Promise.resolve();
                    }
                }]}
            >
                <Input
                    id="whitelist"
                    placeholder="Allowed email addresses."
                    value={data.whitelist}
                    onBlur={handleBlur}
                    onChange={handleWhitelistChange}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Blacklist"
                name="blacklist"
                info="A comma separated list of user email addresses that are not allowed to join the organization."
                rules={[{
                    validator: (rule: any, value: string) => {
                        const addresses = toList(value)
                        for (const address of addresses) {
                            if (!address.includes("@")) {
                                return Promise.reject(`Address '${address}' is invalid.`);
                            }
                        }
                        return Promise.resolve();
                    }
                }]}
            >
                <Input
                    id="blacklist"
                    placeholder="Disallowed email addresses."
                    value={data.blacklist}
                    onBlur={handleBlur}
                    onChange={handleBlacklistChange}
                />
            </FormItem>
        </>
    )
}
