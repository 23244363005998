import { FC, ReactElement } from "react"
import { Checkbox, Select } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Label } from "components/Label/Label";
import { SpecContent } from "../SpecContent/SpecContent";
import update from 'immutability-helper';
import './ColumnEditor.less';

export enum ColumnIndex {
    COLUMN_INDEX = 0,
    SORT_ORDER = 1,
    SHOW_TOTALS = 2
}

export enum SortOrder {
    ASCENDING = "asc",
    DESCENDING = "desc"
}

export type ColumnSpec = [ number, SortOrder, boolean ];

export type ChangeCallback = (spec: ColumnSpec) => void;
export type DeleteCallback = (spec: ColumnSpec) => void;

type Props = {
    header: any,
    spec: ColumnSpec
    isLeaf: boolean,
    onChange: ChangeCallback,
    onDelete: DeleteCallback
}

export const ColumnEditor: FC<Props> = (props: Props): ReactElement => {

    const handleTotalsToggle = (e: CheckboxChangeEvent): void => {
        const checked = e.target.checked;
        const spec = update(props.spec, {
            [ColumnIndex.SHOW_TOTALS]: { $set: checked }
        });
        props.onChange(spec);
    }

    const handleOrderChange = (sortOrder: SortOrder): void => {
        const spec = update(props.spec, {
            [ColumnIndex.SORT_ORDER]: { $set: sortOrder }
        });
        props.onChange(spec);
    }

    const handleSpecDelete = (): void => {
        props.onDelete(props.spec);
    }

    return (
        <SpecContent
            header={props.header}
            onDelete={handleSpecDelete}
        >
            <div>
                <Checkbox
                    key="totals"
                    checked={props.spec[ColumnIndex.SHOW_TOTALS]}
                    onChange={handleTotalsToggle}
                >
                    Show totals
                </Checkbox>
            </div>
            <div>
                <Label
                    label="Order"
                    placement="left"
                    bold={false}
                >
                    <Select
                        className="x-columneditor-order"
                        value={props.spec[ColumnIndex.SORT_ORDER]}
                        onChange={handleOrderChange}
                    >
                        <Select.Option key="asc" value="asc">Ascending</Select.Option>
                        <Select.Option key="desc" value="desc">Descending</Select.Option>
                    </Select>

                </Label>
            </div>
        </SpecContent>
    )

}
