import { ReactElement } from 'react';
import { Button, Modal, Space, Tabs } from 'antd';
import { EventType, ExecutionState } from '@methodset/workflow-client-ts';
import { ConfigurationViewer } from './ConfigurationViewer/ConfigurationViewer';
import { Configuration, TypedMap } from '@methodset/endpoint-client-ts';
import { ErrorView } from './ErrorView/ErrorView';
import { DataViewer } from 'containers/Console/DataPreview/DataViewer/DataViewer';
import { OutputView } from './OutputView/OutputView';
import { Spacer } from 'components/Spacer/Spacer';
import { NoData } from 'components/NoData/NoData';
import './StateView.less';

export type CloseCallback = () => void;

export type StateViewProps = {
    executionId: string,
    state: ExecutionState,
    onClose: CloseCallback
}

export const StateView = (props: StateViewProps): ReactElement => {

    const buildContent = (type: string, map: TypedMap, isProcessor: boolean): ReactElement => {
        const entries = Object.entries(map);
        if (entries.length > 0) {
            return (
                <Spacer direction="vertical" size="xl">
                    {entries.map(([key, value]) => (
                        <DataViewer
                            className="x-stateview-data"
                            key={key}
                            data={value}
                            showName={true}
                            enableRaw={true}
                        />
                    ))}
                </Spacer>
            )
        } else if (isProcessor && !props.state.error) {
            return (
                <OutputView
                    executionId={props.executionId}
                    instanceId={props.state.instanceId}
                />
            )
        } else {
            return (
                <NoData text={`No ${type}.`} />
            )
        }
    }

    const buildConfiguration = (type: string, configuration: Configuration): ReactElement => {
        const keys = Object.keys(configuration);
        if (configuration && keys.length > 0) {
            return (
                <ConfigurationViewer
                    configuration={configuration}
                />
            )
        } else {
            return (
                <NoData text={`No ${type}.`} />
            )
        }
    }

    return (
        <Modal
            className="x-stateview"
            centered
            title="Execution State"
            visible={true}
            onCancel={props.onClose}
            footer={(
                <Space>
                    <Button type="primary" onClick={props.onClose}>Close</Button>
                </Space>
            )}
        >
            <Tabs
                className="x-stateview-tabs"
                defaultActiveKey="configuration"
            >
                <Tabs.TabPane
                    tab="Configuration"
                    key="configuration"
                    forceRender={true}
                >
                    <div className="x-stateview-body">
                        {buildConfiguration("configuration", props.state.configuration)}
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab="Inputs"
                    key="inputs"
                    forceRender={true}
                >
                    <div className="x-stateview-body">
                        {buildContent("inputs", props.state.inputs, false)}
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab="Outputs"
                    key="outputs"
                    forceRender={true}
                >
                    <div className="x-stateview-body">
                        {buildContent("outputs", props.state.outputs, props.state.eventType === EventType.PROCESSOR)}
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab="Variables"
                    key="variables"
                    forceRender={true}
                >
                    <div className="x-stateview-body">
                        {buildConfiguration("variables", props.state.variables)}
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab="Error"
                    key="error"
                    forceRender={true}
                >
                    {props.state.error &&
                        <ErrorView
                            className="x-stateview-body"
                            error={props.state.error}
                        />
                    }
                    {!props.state.error &&
                        <div className="x-stateview-body">
                            <NoData text="No error." />
                        </div>
                    }
                </Tabs.TabPane>
            </Tabs>
        </Modal>
    );

}
