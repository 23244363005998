import { FC, ReactElement, ReactNode } from "react"
import { CloseOutlined } from "@ant-design/icons"
import { Clickable } from "components/Clickable/Clickable";
import './SpecContent.less';

export type DeleteCallback = () => void;

export type SpecContentProps = {
    header: any
    children: ReactNode,
    onDelete: DeleteCallback
}

export const SpecContent: FC<SpecContentProps> = (props: SpecContentProps): ReactElement => {

    return (
        <div className="x-speccontent">
            <span className="x-speccontent-hdr">
                {props.header}
                <Clickable onClick={props.onDelete}>
                    <CloseOutlined />
                </Clickable>
            </span>
            {props.children}
        </div>
    )

}
