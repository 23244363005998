import { ReactElement, useEffect, useState } from 'react';
import { FormInstance } from 'antd';
import { Globals } from 'constants/Globals';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { RestUtils } from 'utils/RestUtils';
import { Model, ModelHeader } from '@methodset/model-client-ts';
import { FormItem } from 'components/FormItem/FormItem';
import { ModelVersionPicker } from '../ModelVersionPicker/ModelVersionPicker';
import { StatusType } from 'constants/StatusType';
import { ModelList } from './ModelList/ModelList';
import { CoreUtils } from 'utils/CoreUtils';
import axios from 'axios';
import classNames from 'classnames';
import modelService from 'services/ModelService';
import './ModelPicker.less';
import { IdUtils } from '@methodset/commons-core-ts';

export type ChangeCallback = (modelId: string, version: number) => void;
export type LoadCallback = (model: Model) => void;

export type ModelPickerState = {
    status: StatusType
}

export type ModelPickerProps = {
    className?: string,
    formRef: React.RefObject<FormInstance>,
    modelId?: string,
    version?: number,
    onChange: ChangeCallback,
    onLoad: LoadCallback
}

export const ModelPicker = (props: ModelPickerProps): ReactElement => {

    const [status, setStatus] = useState<string>(StatusType.INIT);

    useEffect(() => {
        loadData(props.modelId, props.version);
    }, []);

    const handleRetryLoad = (): void => {
        loadData(props.modelId, props.version!);
    }

    const handleModelChange = (header: ModelHeader): void => {
        loadData(header.id, header.version);
        props.onChange(header.id, header.version);
    }

    const handleVersionChange = (version: number): void => {
        loadData(props.modelId, version);
        props.onChange(props.modelId!, version);
    }

    const readModelRequest = (modelId: string, version: number): Promise<any> => {
        const request = {
            modelId: modelId,
            version: version
        };
        return modelService.readModel(request,
            (response: any) => readModelResponse(response),
            undefined, true
        );
    }

    const readModelResponse = (response: any): void => {
        const model = response.data.model;
        props.onLoad(model);
    }

    const loadData = (modelId: string | undefined, version: number | undefined): void => {
        if (!modelId || CoreUtils.isEmpty(version)) {
            setStatus(StatusType.READY);
            return;
        }
        const requests = [];
        requests.push(readModelRequest(modelId, version!));
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        return (
            <LoadSpinner
                className="x-modelpicker-loading"
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load models."
                onRetry={handleRetryLoad}
            />
        );
    }

    let loadingView;
    if (status === StatusType.LOADING) {
        loadingView = buildLoadingView(true);
    } else if (status === StatusType.FAILED) {
        loadingView = buildLoadingView(false);
    } else if (status === StatusType.READY) {
        loadingView = <></>;
    }

    const accessType = IdUtils.toAccessType(props.modelId);
    const extra = accessType ? (
        <span className="x-modelpicker-access">
            {CoreUtils.toProper(accessType)}
        </span>
    ) : undefined;
    
    return (
        <div className={classNames('x-modelpicker', props.className)}>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Model"
                name="model"
                info="The model that contains the desired data."
                extra={extra}
                valuePropName="modelId"
                justification="center"
                rules={[{
                    required: true,
                    message: `Please select a model.`
                }]}
            >
                <ModelList
                    formRef={props.formRef}
                    modelId={props.modelId}
                    onChange={handleModelChange}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Version"
                name="version"
                info="The model version."
                valuePropName="version"
                rules={[{
                    required: true,
                    message: `Please select a version.`
                }]}
            >
                <ModelVersionPicker
                    modelId={props.modelId}
                    version={props.version}
                    onChange={handleVersionChange}
                />
            </FormItem>
            {loadingView}
        </div>
    )

}
