import React, { ChangeEvent, Component, ReactElement } from 'react';
import { Button, Checkbox, Col, Empty, FormInstance, Input, List, Row, Select } from 'antd';
import { FormItem, ValidateStatus } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { AreaChartWidgetConfiguration, BarChartWidgetConfiguration, CartesianWidgetConfiguration, ChartAxis, ChartDataset, ColumnChartWidgetConfiguration, LineChartWidgetConfiguration, WidgetType } from '@methodset/application-client-ts';
import { Calculator, Range, Vector, RefType, ReferenceParser, RangeComponents } from '@methodset/calculator-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { Justify } from 'components/Justify/Justify';
import { RefEditor } from 'containers/Components/Widgets/RefEditor/RefEditor';
import { WidgetUtils } from 'utils/WidgetUtils';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Info } from 'components/Info/Info';
import { AreaChartEditor } from '../AreaChartView/AreaChartEditor/AreaChartEditor';
import { BarChartEditor } from '../BarChartView/BarChartEditor/BarChartEditor';
import { ColumnChartEditor } from '../ColumnChartView/ColumnChartEditor/ColumnChartEditor';
import { LineChartEditor } from '../LineChartView/LineChartEditor/LineChartEditor';
import { Listing, ListingItem } from 'components/Listing/Listing';
import { FormGroup } from 'components/FormGroup/FormGroup';
import update from 'immutability-helper';
import './CartesianEditor.less';

type FormError = {
    validateStatus: ValidateStatus,
    help: string
};

export type EditCallback = (isEditing: boolean) => void;
export type ChangeCallback = (configuration: CartesianWidgetConfiguration, isEditing?: boolean) => void;

export type CartesianEditorProps = typeof CartesianEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    type?: WidgetType,
    defaultConfigurations: ReactElement,
    configuration?: CartesianWidgetConfiguration,
    calculator: Calculator,
    onEdit?: EditCallback,
    onChange: ChangeCallback
}

export type CartesianEditorState = {
    editIndex?: number,
    editDataset?: ChartDataset,
    multiView: boolean
    nameError?: FormError
    labelError?: FormError,
    valueError?: FormError,
}

export class CartesianEditor extends Component<CartesianEditorProps, CartesianEditorState> {

    static DefaultConfiguration = {
        type: WidgetType.LINE_CHART,
        height: 300,
        xAxis: {} as ChartAxis,
        yAxis: {} as ChartAxis,
        datasets: [] as ChartDataset[]
    } as CartesianWidgetConfiguration;

    static defaultProps = {
        configuration: CartesianEditor.DefaultConfiguration
    }

    private customConfiguration: CartesianWidgetConfiguration | undefined;

    constructor(props: CartesianEditorProps) {
        super(props);
        this.state = {
            editIndex: undefined,
            editDataset: undefined,
            multiView: false
        }
        this.handleXLabelChange = this.handleXLabelChange.bind(this);
        this.handleYLabelChange = this.handleYLabelChange.bind(this);
        this.handleMultiChange = this.handleMultiChange.bind(this);
        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.handleHeadersChange = this.handleHeadersChange.bind(this);
        this.handleLayoutChange = this.handleLayoutChange.bind(this);
        this.handleLabelChange = this.handleLabelChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDatasetSave = this.handleDatasetSave.bind(this);
        this.handleDatasetAdd = this.handleDatasetAdd.bind(this);
        this.handleDatasetEdit = this.handleDatasetEdit.bind(this);
        this.handleDatasetRemove = this.handleDatasetRemove.bind(this);
        this.handleDatasetCancel = this.handleDatasetCancel.bind(this);
        this.handleConfigurationDefault = this.handleConfigurationDefault.bind(this);
    }

    private handleXLabelChange(e: ChangeEvent<HTMLInputElement>): void {
        const label = e.target.value;
        const configuration = update(this.props.configuration, {
            xAxis: {
                label: { $set: label }
            }
        });
        this.props.onChange(configuration);
    }

    private handleYLabelChange(e: ChangeEvent<HTMLInputElement>): void {
        const label = e.target.value;
        const configuration = update(this.props.configuration, {
            yAxis: {
                label: { $set: label }
            }
        });
        this.props.onChange(configuration);
    }

    private handleRangeChange(rangeId: string | undefined): void {
        let editDataset = update(this.state.editDataset, {
            rangeId: { $set: rangeId as any }
        });
        if (rangeId !== this.state.editDataset?.rangeId) {
            editDataset = update(editDataset, {
                labelRangeId: { $set: undefined as any },
                valueRangeId: { $set: undefined as any }
            });
        }
        this.setState({ editDataset: editDataset });
    }

    private handleMultiChange(e: CheckboxChangeEvent): void {
        const multiView = e.target.checked;
        const hasHeaders = !!multiView;
        const editDataset = update(this.state.editDataset, {
            name: { $set: undefined as any },
            rangeId: { $set: undefined as any },
            hasHeaders: { $set: hasHeaders },
            labelRangeId: { $set: undefined as any },
            valueRangeId: { $set: undefined as any }
        });
        this.setState({
            editDataset: editDataset,
            multiView: multiView
        });
    }

    private handleHeadersChange(e: CheckboxChangeEvent): void {
        const hasHeaders = e.target.checked;
        const editDataset = update(this.state.editDataset, {
            hasHeaders: { $set: hasHeaders }
        });
        this.setState({ editDataset: editDataset });
    }

    private handleLayoutChange(e: CheckboxChangeEvent): void {
        const rowLayout = e.target.checked;
        const editDataset = update(this.state.editDataset, {
            rowLayout: { $set: rowLayout }
        });
        this.setState({ editDataset: editDataset });
    }

    private handleNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const name = e.target.value;
        const editDataset = update(this.state.editDataset, {
            name: { $set: name }
        });
        this.setState({
            editDataset: editDataset,
            nameError: undefined
        });
    }

    private handleLabelChange(labelRangeId: string | undefined): void {
        const editDataset = update(this.state.editDataset, {
            labelRangeId: { $set: labelRangeId! }
        });
        this.setState({
            editDataset: editDataset,
            labelError: undefined
        });
    }

    private handleValueChange(valueRangeId: string | undefined): void {
        const name = this.findRangeHeader(valueRangeId, this.state.editDataset!);
        const editDataset = update(this.state.editDataset, {
            name: { $set: name as string },
            valueRangeId: { $set: valueRangeId! }
        });
        this.setState({
            editDataset: editDataset,
            valueError: undefined
        });
    }

    private findRangeHeader(rangeId: string | undefined, dataset: ChartDataset): string | undefined {
        if (!rangeId || !dataset.hasHeaders || dataset.name) {
            return dataset.name;
        }
        const range = Range.fromUid(this.props.calculator, rangeId);
        const col = range.upper.col;
        const row = range.upper.row;
        let header;
        if (!dataset.rowLayout) {
            const cols = range.sheet.columns;
            if (cols) {
                cols.forEach(col => {
                    return col.cells.forEach(cell => {
                        header = cell.displayValue;
                        return true;
                    }, row, row);
                }, col, col);
            }
        } else {
            const rows = range.sheet.rows;
            if (rows) {
                rows.forEach(row => {
                    return row.cells.forEach(cell => {
                        header = cell.displayValue;
                        return true;
                    }, col, col);
                }, row, row);
            }
        }
        return header;
    }

    private handleDatasetSave(): void {
        let nameError: FormError | undefined = undefined;
        let labelError: FormError | undefined = undefined;
        let valueError: FormError | undefined = undefined;
        const dataset = this.state.editDataset!;

        if (!dataset.name) {
            nameError = {
                validateStatus: "error",
                help: "Please enter a dataset name."
            }
        }

        let labelDimension;
        let labelRange: Range | undefined = undefined;
        const labelRef = ReferenceParser.parse(dataset.labelRangeId);
        if (labelRef) {
            const components = labelRef.components as RangeComponents;
            if (!components.sheetId) {
                labelError = {
                    validateStatus: "error",
                    help: "Please select a label sheet."
                }
            } else {
                const sheet = this.props.calculator.sheets.get(components.sheetId, false);
                if (!sheet) {
                    labelError = {
                        validateStatus: "error",
                        help: "Label sheet does not exist."
                    }
                } else {
                    try {
                        labelRange = Range.fromId(sheet, components.rangeId);
                        labelDimension = Vector.findDimension(labelRange);
                    } catch (e) {
                        labelError = {
                            validateStatus: "error",
                            help: "Invalid range specification."
                        }
                    }
                }
            }
        } else {
            labelError = {
                validateStatus: "error",
                help: "Please enter a complete label range."
            }
        }
        // let valueDimension;
        let valueRange: Range | undefined = undefined;
        const valueRef = ReferenceParser.parse(dataset.valueRangeId);
        if (valueRef) {
            const components = valueRef.components as RangeComponents;
            if (!components.sheetId) {
                valueError = {
                    validateStatus: "error",
                    help: "Please select a value sheet."
                }
            } else {
                const sheet = this.props.calculator.sheets.get(components.sheetId, false);
                if (!sheet) {
                    valueError = {
                        validateStatus: "error",
                        help: "Value sheet does not exist."
                    }
                } else {
                    try {
                        valueRange = Range.fromId(sheet, components.rangeId);
                        //valueDimension = Vector.findDimension(valueRange);
                    } catch (e) {
                        valueError = {
                            validateStatus: "error",
                            help: "Invalid range specification."
                        }
                    }
                }
            }
        } else {
            valueError = {
                validateStatus: "error",
                help: "Please enter a valid value range."
            }
        }

        if (nameError || labelError || valueError) {
            this.setState({
                nameError: nameError,
                labelError: labelError,
                valueError: valueError
            });
            return;
        }

        let configuration;
        if (this.state.editIndex === -1) {
            // Add a new dataset.
            configuration = update(this.props.configuration, {
                datasets: {
                    $push: [this.state.editDataset!]
                }
            });
        } else {
            // Update an existing dataset.
            configuration = update(this.props.configuration, {
                datasets: {
                    [this.state.editIndex!]: { $set: this.state.editDataset! }
                }
            });
        }
        this.setState({
            editIndex: undefined,
            editDataset: undefined
        });
        this.props.onChange(configuration, false);
    }

    private handleDatasetAdd(): void {
        let rangeId;
        let labelRangeId;
        let hasHeaders;
        const datasets = this.props.configuration.datasets;
        if (datasets.length > 0) {
            const prev = datasets[datasets.length - 1];
            rangeId = prev.rangeId;
            labelRangeId = prev.labelRangeId;
            hasHeaders = prev.hasHeaders;
        }
        const dataset: ChartDataset = {
            name: undefined as any,
            rangeId: rangeId as string,
            labelRangeId: labelRangeId as string,
            hasHeaders: hasHeaders,
            valueRangeId: undefined as any
        }
        this.setState({
            editIndex: -1,
            editDataset: dataset,
            multiView: false,
            nameError: undefined,
            labelError: undefined,
            valueError: undefined
        });
        if (this.props.onEdit) {
            this.props.onEdit(true);
        }
    }

    private handleDatasetEdit(item: ListingItem, index: number): void {
        //const dataset = this.props.configuration.datasets[index];
        const dataset = item as ChartDataset;
        const multiView = !dataset.rangeId;
        this.setState({
            editIndex: index,
            editDataset: dataset,
            multiView: multiView,
            nameError: undefined,
            labelError: undefined,
            valueError: undefined
        });
        if (this.props.onEdit) {
            this.props.onEdit(true);
        }
    }

    private handleDatasetRemove(item: ListingItem, index: number): void {
        const configuration = update(this.props.configuration, {
            datasets: {
                $splice: [[index, 1]]
            }
        });
        this.setState({ editIndex: -1 });
        this.props.onChange(configuration);
    }

    private handleDatasetCancel(): void {
        this.setState({
            editIndex: undefined,
            editDataset: undefined,
            multiView: false
        });
        if (this.props.onEdit) {
            this.props.onEdit(false);
        }
    }

    private handleConfigurationDefault(configuration: CartesianWidgetConfiguration): void {
        this.customConfiguration = configuration;
    }

    private buildCustomConfigurations(type: WidgetType | undefined): ReactElement | undefined {
        switch (type) {
            case WidgetType.AREA_CHART:
                return <AreaChartEditor
                    formRef={this.props.formRef}
                    configuration={this.props.configuration as AreaChartWidgetConfiguration}
                    calculator={this.props.calculator}
                    onDefault={this.handleConfigurationDefault}
                    onChange={this.props.onChange}
                />
            case WidgetType.BAR_CHART:
                return <BarChartEditor
                    formRef={this.props.formRef}
                    configuration={this.props.configuration as BarChartWidgetConfiguration}
                    calculator={this.props.calculator}
                    onChange={this.props.onChange}
                />
            case WidgetType.COLUMN_CHART:
                return <ColumnChartEditor
                    formRef={this.props.formRef}
                    configuration={this.props.configuration as ColumnChartWidgetConfiguration}
                    calculator={this.props.calculator}
                    onChange={this.props.onChange}
                />
            case WidgetType.LINE_CHART:
                return <LineChartEditor
                    formRef={this.props.formRef}
                    configuration={this.props.configuration as LineChartWidgetConfiguration}
                    calculator={this.props.calculator}
                    onDefault={this.handleConfigurationDefault}
                    onChange={this.props.onChange}
                />
            default:
                return undefined;
        }
    }

    public componentDidMount(): void {
        if (this.props.configuration === CartesianEditor.DefaultConfiguration) {
            let configuration = this.props.configuration;
            if (this.customConfiguration) {
                configuration = update(configuration, {
                    $merge: this.customConfiguration
                });
            }
            if (this.props.type) {
                configuration = update(configuration, {
                    type: { $set: this.props.type }
                });
            }
            this.props.onChange(configuration);
        }
    }

    public render(): ReactElement {
        const vectors = this.state.editDataset ? WidgetUtils.rangeVectors(this.props.calculator, this.state.editDataset.rangeId, this.state.editDataset.hasHeaders, this.state.editDataset.rowLayout) : [];
        const customConfigurations = this.buildCustomConfigurations(this.props.type);
        return (
            <Row gutter={Globals.FORM_GUTTER_ROW}>
                <Col span={8}>
                    {this.props.defaultConfigurations}
                </Col>
                <Col span={8}>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="X-Axis Label"
                        name="x-axis-label"
                        info="The label for the x-axis"
                        rules={[{
                            required: false,
                            message: 'Please enter an x-axis label.'
                        }]}
                    >
                        <Input
                            placeholder="Enter the x-axis label."
                            value={this.props.configuration.xAxis.label}
                            onChange={this.handleXLabelChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Y-Axis Label"
                        name="y-axis-label"
                        info="The label for the y-axis"
                        rules={[{
                            required: false,
                            message: 'Please enter a y-axis label.'
                        }]}
                    >
                        <Input
                            placeholder="Enter the y-axis label."
                            value={this.props.configuration.yAxis.label}
                            onChange={this.handleYLabelChange}
                        />
                    </FormItem>
                    {customConfigurations}
                </Col>
                <Col span={8}>
                    {!this.state.editDataset &&
                        <>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.props.formRef}
                                label="Datasets"
                                name="datasets"
                                required={true}
                                info="The datasets to use to generate the chart."
                                rules={[{
                                    required: true,
                                    validator: () => {
                                        return this.props.configuration.datasets.length === 0 ?
                                            Promise.reject('Please configure at least one dataset.') :
                                            Promise.resolve();
                                    }
                                }]}
                            >
                                <Listing
                                    items={this.props.configuration.datasets}
                                    label="datasets"
                                    onEdit={this.handleDatasetEdit}
                                    onDelete={this.handleDatasetRemove}
                                />
                            </FormItem>
                            <Justify justification="right">
                                <Button onClick={this.handleDatasetAdd}>
                                    Add Dataset
                                </Button>
                            </Justify>
                        </>
                    }
                    {this.state.editDataset &&
                        <>
                            {!this.state.multiView &&
                                <>
                                    <FormItem
                                        {...Globals.FORM_LAYOUT}
                                        formRef={this.props.formRef}
                                        required={true}
                                        label="Data Range"
                                        name="range"
                                        info="The range that includes the data that can be added to the dataset."
                                    >
                                        <RefEditor
                                            formRef={this.props.formRef}
                                            index={0}
                                            calculator={this.props.calculator}
                                            refTypes={[RefType.RANGE]}
                                            refId={this.state.editDataset.rangeId}
                                            onChange={this.handleRangeChange}
                                        />
                                    </FormItem>
                                    <FormItem
                                        {...Globals.FORM_LAYOUT}
                                        formRef={this.props.formRef}
                                        required={true}
                                        label="Label Range"
                                        name="label"
                                        info="The range for the labels. Must be one dimensional range, i.e., a column or row."
                                        validateStatus={this.state.labelError?.validateStatus}
                                        help={this.state.labelError?.help}
                                    >
                                        <Select
                                            value={this.state.editDataset.labelRangeId}
                                            allowClear={true}
                                            onChange={this.handleLabelChange}
                                        >
                                            {vectors.map(vector => (
                                                <Select.Option key={vector.id} value={vector.id}>{vector.label}</Select.Option>
                                            ))}
                                        </Select>
                                    </FormItem>
                                    <FormItem
                                        {...Globals.FORM_LAYOUT}
                                        formRef={this.props.formRef}
                                        required={true}
                                        label="Value Range"
                                        name="value"
                                        info="The range for the values. Must be one dimensional range, i.e., a column or row."
                                        validateStatus={this.state.valueError?.validateStatus}
                                        help={this.state.valueError?.help}
                                    >
                                        <Select
                                            value={this.state.editDataset.valueRangeId}
                                            allowClear={true}
                                            onChange={this.handleValueChange}
                                        >
                                            {vectors.map(vector => (
                                                <Select.Option key={vector.id} value={vector.id}>{vector.label}</Select.Option>
                                            ))}
                                        </Select>
                                    </FormItem>
                                    <FormItem
                                        {...Globals.FORM_LAYOUT}
                                        formRef={this.props.formRef}
                                        label="Dataset Name"
                                        name="name"
                                        required={true}
                                        validateStatus={this.state.nameError?.validateStatus}
                                        help={this.state.nameError?.help}
                                    >
                                        <Input
                                            placeholder="Enter a dataset name."
                                            value={this.state.editDataset.name}
                                            onChange={this.handleNameChange}
                                        />
                                    </FormItem>
                                </>
                            }
                            {this.state.multiView &&
                                <>
                                    <FormItem
                                        {...Globals.FORM_LAYOUT}
                                        formRef={this.props.formRef}
                                        required={true}
                                        label="Label Range"
                                        name="label"
                                        info="The range for the labels. Must be one dimensional range, i.e., a column or row."
                                        validateStatus={this.state.labelError?.validateStatus}
                                        help={this.state.labelError?.help}
                                    >
                                        <RefEditor
                                            formRef={this.props.formRef}
                                            index={0}
                                            //noError={true}
                                            calculator={this.props.calculator}
                                            refTypes={[RefType.RANGE]}
                                            refId={this.state.editDataset.labelRangeId}
                                            onChange={this.handleLabelChange}
                                        />
                                    </FormItem>
                                    <FormItem
                                        {...Globals.FORM_LAYOUT}
                                        formRef={this.props.formRef}
                                        required={true}
                                        label="Value Range"
                                        name="value"
                                        info="The range for the values. Must be one dimensional range, i.e., a column or row."
                                        validateStatus={this.state.valueError?.validateStatus}
                                        help={this.state.valueError?.help}
                                    >
                                        <RefEditor
                                            formRef={this.props.formRef}
                                            index={1}
                                            //noError={true}
                                            calculator={this.props.calculator}
                                            refTypes={[RefType.RANGE]}
                                            refId={this.state.editDataset.valueRangeId}
                                            onChange={this.handleValueChange}
                                        />
                                    </FormItem>
                                </>
                            }
                            <FormGroup>
                                <Checkbox
                                    checked={this.state.multiView}
                                    onChange={this.handleMultiChange}
                                >
                                    <Info
                                        label="Multi-Series Range"
                                        info="True to define a range with more than one row or column. This is usually used when the number of datasets to be charted is is unknown before the data is generated. In this case, use a range with an open-ended range, for example B1:?? specifies data starting at cell B1 and extending as many columns and rows as are available."
                                        bold={true}
                                    />
                                </Checkbox>
                                <Checkbox
                                    checked={this.state.editDataset.hasHeaders}
                                    disabled={this.state.multiView}
                                    onChange={this.handleHeadersChange}
                                >
                                    <Info
                                        label="Range Includes Headers"
                                        info="True if the data range has header values in the first row or column. These will be used to label the value datasets."
                                        bold={true}
                                    />
                                </Checkbox>
                                <Checkbox
                                    checked={this.state.editDataset.rowLayout}
                                    onChange={this.handleLayoutChange}
                                >
                                    <Info
                                        label="Range Layout as Rows"
                                        info="True if the data is layed out in rows, false if the data is layed out in columns."
                                        bold={true}
                                    />
                                </Checkbox>
                            </FormGroup>
                            <Justify className="x-cartesianeditor-action" justification="right">
                                <Spacer>
                                    <Button onClick={this.handleDatasetCancel}>Cancel Edit</Button>
                                    <Button type="primary" onClick={this.handleDatasetSave}>Save Dataset</Button>
                                </Spacer>
                            </Justify>
                        </>
                    }
                </Col>
            </Row>
        );
    }

}
