import { ChangeEvent, ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { ModelContext } from 'context/ModelContext';
import { Alert, Applet, AppletPanel, Application, ApplicationType, CategoryType, Condition, Inputs, BlackoutInterval, LogicalType, UnitType } from '@methodset/application-client-ts';
import { RouteComponentProps } from 'react-router-dom';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Button, Col, Divider, Form, FormInstance, Input, Row, Select } from 'antd';
import { Spacer } from 'components/Spacer/Spacer';
import { FormItem } from 'components/FormItem/FormItem';
import { VariablesSelector } from './VariablesSelector/VariablesSelector';
import { BlackoutEditor } from './BlackoutEditor/BlackoutEditor';
import { FormValue } from 'components/FormValue/FormValue';
import { ConditionEditor } from '../../../../../Notifications/Alerts/AlertSetup/AlertInputs/ConditionEditor/ConditionEditor';
import { PanelEditor } from './PanelEditor/PanelEditor';
import { ItemSpec, MenuButton } from 'components/MenuButton/MenuButton';
import { ScheduleEditor } from 'containers/Console/Workflows/WorkflowItem/WorkflowEditor/TriggerEditor/ScheduleEditor/ScheduleEditor';
import { DownOutlined } from '@ant-design/icons';
import { AlertSetup, ButtonSpec } from '../../../../../Notifications/Alerts/AlertSetup/AlertSetup';
import { InstructionsEditor } from './InstructionsEditor/InstructionsEditor';
import { CoreUtils } from 'utils/CoreUtils';
import { InstructionsUtils } from 'containers/Console/Notifications/Alerts/AlertSetup/AlertInputs/InstructionsUtils';
import { AlertSync } from 'sync/AlertSync';
import { Cron } from '@methodset/schedule-client-ts';
import { Globals } from 'constants/Globals';
import { MessageEditor } from './MessageEditor/MessageEditor';
import { Info } from 'components/Info/Info';
import { Version } from '@methodset/commons-core-ts';
import { RestUtils } from 'utils/RestUtils';
import { TextMessage } from 'components/TextMessage/TextMessage';
import { Calculator } from '@methodset/calculator-ts';
import { v4 as uuid } from "uuid";
import update from 'immutability-helper';
import applicationService from 'services/ApplicationService';
import modelService from 'services/ModelService';
import './ModelAlert.less';

type MatchParams = {
    modelId: string,
    applicationId: string
}

const NullAlert = {} as Alert;

type ModalType = "none" | "test";

export type ModelAlertProps = RouteComponentProps<MatchParams> & {}

export const ModelAlert = (props: ModelAlertProps): ReactElement => {

    // The model context.
    const context = useContext(ModelContext);
    // The form reference.
    const formRef = useRef<FormInstance>(null);
    // This is a new alert.
    const isCreating = useRef<boolean>(false);
    // Monitor for url changes.
    const unmonitor = useRef<any>();
    // The state of exiting.
    const exiting = useRef<boolean>(false);
    // The alert being edited.
    const [alert, setAlert] = useState<Alert>(NullAlert);
    // Edit modal type.
    const [modalType, setModalType] = useState<ModalType>("none");
    // Saving state.
    const [isSaving, setIsSaving] = useState<boolean>(false);
    // Error during save.
    const [error, setError] = useState<Error | undefined>();

    useEffect(() => {
        const applicationId = props.match.params.applicationId;
        const applications: Application[] = context.applications;
        const index = applications.findIndex(application => application.id === applicationId && application.type === ApplicationType.ALERT);
        let alert: Alert;
        if (context.application && context.application.id === applicationId) {
            alert = context.application as Alert;
        } else if (index !== -1) {
            alert = applications[index] as Alert;
        } else {
            const modelId = props.match.params.modelId;
            alert = {
                type: ApplicationType.ALERT,
                id: applicationId,
                version: Version.EDITOR,
                modelId: modelId,
                name: "New Alert",
                message: undefined as any,
                inputIds: [],
                conditions: [{
                    variableId: undefined as any,
                    opType: undefined as any,
                    value: undefined as any
                }],
                schedule: {
                    //cron: {} as Cron
                    cron: undefined as any
                },
                interval: {
                    duration: 1,
                    unitType: UnitType.DAY
                },
                testInputs: {
                    configuration: {},
                    conditions: []
                },
                panel: {
                    id: uuid(),
                    name: "Alert Panel",
                    span: Globals.LAYOUT_COLUMNS,
                    widgets: []
                },
                categoryType: CategoryType.GENERAL,
            }
            isCreating.current = true;
        }
        setAlert(alert);
        //context.saveApplication(alert);
    }, []);

    useEffect(() => {
        unmonitor.current = props.history.block(handleUrlChange);
        return () => {
            unmonitor.current();
        }
    }, [alert]);

    const handleUrlChange = (): void => {
        if (exiting.current) {
            context.saveApplication(undefined);
        } else {
            context.saveApplication(alert);
        }
    }

    const handleTestClose = (): void => {
        setModalType("none");
    }

    const handleInputsChange = (inputs: Inputs): void => {
        const updated = update(alert, {
            testInputs: { $set: inputs }
        });
        setAlert(updated);
        //context.saveApplication(updated);
    }

    const handleAlertCancel = (): void => {
        const modelId = props.match.params.modelId;
        exiting.current = true;
        //context.saveApplication(undefined);
        props.history.push(RouteBuilder.applications(modelId));
    }

    const handleAlertSave = (): void => {
        formRef.current?.submit();
    }

    const handleFormFinish = (): void => {
        updateModelRequest();
    }

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const name = e.target.value;
        const updated = update(alert, {
            name: { $set: name }
        });
        setAlert(updated);
        //context.saveApplication(updated);
    }

    const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        const description = e.target.value;
        const updated = update(alert, {
            description: { $set: description }
        });
        setAlert(updated);
        //context.saveApplication(updated);
    }

    const handleCategoryChange = (categoryType: CategoryType): void => {
        const updated = update(alert, {
            categoryType: { $set: categoryType }
        });
        setAlert(updated);
        //context.saveApplication(updated);
    }

    const handleCronChange = (cron: Cron): void => {
        const updated = update(alert, {
            schedule: {
                cron: { $set: cron }
            }
        });
        setAlert(updated);
        //context.saveApplication(updated);
    }

    const handleIntervalChange = (interval?: BlackoutInterval): void => {
        const updated = update(alert, {
            interval: { $set: interval }
        });
        setAlert(updated);
        //context.saveApplication(updated);
    }

    const handleInputIdsChange = (inputIds: string[]): void => {
        let updated = update(alert, {
            inputIds: { $set: inputIds },
            testInputs: {
                configuration: { $set: {} }
            }
        });
        const instructions = InstructionsUtils.validateInstructions(alert!.instructions, updated!.inputIds, updated!.conditions);
        updated = update(updated, {
            instructions: { $set: instructions }
        });
        setAlert(updated);
        //context.saveApplication(updated);
        const registry = context.calculator!.registry;
        registry.register(alert!.id, alert!, AlertSync.parser, AlertSync.updater);
    }

    const handleConditionChange = (condition: Condition, index: number): void => {
        let updated = update(alert, {
            conditions: {
                [index]: { $set: condition }
            }
        });
        const conditions = [];
        for (const condition of updated!.conditions) {
            conditions.push({
                variableId: condition.variableId,
                opType: condition.opType,
                value: condition.value
            });
        }
        const instructions = InstructionsUtils.validateInstructions(alert!.instructions, updated!.inputIds, updated!.conditions);
        updated = update(updated, {
            instructions: { $set: instructions },
            testInputs: {
                conditions: { $set: conditions }
            }
        });
        setAlert(updated);
        //context.saveApplication(updated);
        const registry = context.calculator!.registry;
        registry.register(alert!.id, alert!, AlertSync.parser, AlertSync.updater);
    }

    const handleConditionRemove = (index: number): void => {
        let updated = update(alert, {
            conditions: {
                $splice: [[index, 1]]
            }
        });
        const conditions = [];
        for (const condition of updated!.conditions) {
            conditions.push({
                variableId: condition.variableId,
                opType: condition.opType,
                value: condition.value
            });
        }
        const instructions = InstructionsUtils.validateInstructions(alert!.instructions, updated!.inputIds, updated!.conditions);
        updated = update(updated, {
            instructions: { $set: instructions },
            testInputs: {
                conditions: { $set: conditions }
            }
        });
        setAlert(updated);
        //context.saveApplication(updated);
        const registry = context.calculator!.registry;
        registry.register(alert!.id, alert!, AlertSync.parser, AlertSync.updater);
    }

    const handleConditionAdd = (logicalType: LogicalType): void => {
        const condition: Condition = {
            variableId: undefined as any,
            opType: undefined as any,
            value: undefined as any,
            logicalType: logicalType
        }
        const updated = update(alert, {
            conditions: {
                $push: [condition]
            }
        });
        setAlert(updated);
        //context.saveApplication(updated);
    }

    const handleInstructionsChange = (instructions: string): void => {
        const updated = update(alert, {
            instructions: { $set: instructions }
        });
        setAlert(updated);
        //context.saveApplication(updated);
    }

    const handleEditChange = (isEdit: boolean): void => {
        const modelId = props.match.params.modelId;
        const applicationId = props.match.params.applicationId;
        const path = `${applicationId}?edit=${isEdit ? "true" : "false"}`;
        props.history.push(RouteBuilder.alert(modelId, path));
    }

    const handlePanelUpdate = (panel: AppletPanel): void => {
        const updated = update(alert, {
            panel: { $set: panel }
        });
        setAlert(updated);
        //context.saveApplication(updated);
    }

    const handleMessageChange = (message: string): void => {
        const updated = update(alert, {
            message: { $set: message }
        });
        setAlert(updated);
        //context.saveApplication(updated);
    }

    const handleAlertTest = (): void => {
        formRef.current?.validateFields().then(values => {
            setModalType("test");
        }).catch(e => {
        });
    }

    const updateModelRequest = (): Promise<any> => {
        // TODO: check if needs updating
        setError(undefined);
        setIsSaving(true);
        const model = context.model!;
        const calculator = context.calculator!;
        const request = {
            modelId: model.id,
            name: model.name,
            description: model.description,
            keywords: model.keywords,
            autoSave: model.autoSave,
            calculator: Calculator.serialize(calculator)
        };
        return modelService.updateModel(request,
            (response: any) => updateModelResponse(response),
            (response: any) => updateException(response),
            true
        );
    }

    const updateModelResponse = (response: any): void => {
        if (isCreating.current) {
            createApplicationRequest();
        } else {
            updateApplicationRequest();
        }
    }

    const createApplicationRequest = (): Promise<any> => {
        //alert.id = undefined as any;
        const request = {
            application: alert
        };
        return applicationService.createApplication(request,
            (response: any) => createApplicationResponse(response),
            (response: any) => updateException(response),
            true
        );
    }

    const createApplicationResponse = (response: any): void => {
        isCreating.current = false;
        updateApplicationResponse(response);
    }

    const updateApplicationRequest = (): Promise<any> => {
        const request = {
            application: alert
        };
        return applicationService.updateApplication(request,
            (response: any) => updateApplicationResponse(response),
            (response: any) => updateException(response),
            true
        );
    }

    const updateApplicationResponse = (response: any): void => {
        const application = response.data.application;
        let applications = context.applications;
        const index = applications.findIndex(a => a.id === application.id);
        if (index === -1) {
            applications = update(applications, {
                $push: [application]
            });
        } else {
            const existing = applications[index];
            application.version = existing.version;
            applications = update(applications, {
                [index]: { $set: application }
            });
        }
        context.saveApplications(applications);
        //context.saveApplication(undefined);
        setIsSaving(false);
        exiting.current = true;
        props.history.push(RouteBuilder.applications(alert.modelId));
    }

    const updateException = (response: any): void => {
        const error = RestUtils.getError(response);
        setError(error);
        setIsSaving(false);
    }

    const items: ItemSpec[] = [{
        label: LogicalType.name(LogicalType.AND),
        onSelect: () => handleConditionAdd(LogicalType.AND)
    }, {
        label: LogicalType.name(LogicalType.OR),
        onSelect: () => handleConditionAdd(LogicalType.OR)
    }];

    const actions = (
        <Spacer className="x-modelalert-actions" justification="between">
            <TextMessage type="error" message={error?.message} fill />
            <Spacer justification="right">
                <Button onClick={handleAlertCancel}>Cancel</Button>
                <Button type="primary" loading={isSaving} onClick={handleAlertSave}>Done</Button>
            </Spacer>
        </Spacer>
    );

    const buildLoadView = (): ReactElement => {
        return <></>;
    }

    const buildAlertView = (alert: Alert): ReactElement => {
        return (
            <Form
                className="x-modelalert-form"
                ref={formRef}
                onFinish={handleFormFinish}
            >
                <Spacer size="md" alignment="top" fill>
                    <Row className="x-modalalert-left" gutter={[Globals.FORM_GUTTER_COL, 0]}>
                        <Col span={12}>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={formRef}
                                label="Name"
                                name="name"
                                info="The name of the alert."
                                rules={[{
                                    required: true,
                                    message: 'Enter a name.'
                                }]}
                            >
                                <Input
                                    placeholder="Enter a name."
                                    value={alert.name}
                                    onChange={handleNameChange}
                                />
                            </FormItem>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={formRef}
                                label="Description"
                                name="description"
                                info="The description of the alert."
                            >
                                <Input.TextArea
                                    placeholder="Enter a description."
                                    rows={3}
                                    value={alert.description}
                                    onChange={handleDescriptionChange}
                                />
                            </FormItem>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={formRef}
                                label="Category"
                                name="category"
                                info="The category that describes the applet's purpose."
                                rules={[{
                                    required: true,
                                    message: 'Select a category.'
                                }]}
                            >
                                <Select
                                    allowClear={true}
                                    value={alert.categoryType}
                                    onChange={handleCategoryChange}
                                >
                                    {CoreUtils.enumToKeys(CategoryType).map(key => (
                                        <Select.Option value={key}>{CoreUtils.toProper(key, "_")}</Select.Option>
                                    ))}
                                </Select>
                            </FormItem>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={formRef}
                                label="Input Variables"
                                name="inputs"
                                info="The input variables to the model that can be changed."
                                valuePropName="variableIds"
                            >
                                <VariablesSelector
                                    label="Input Variable"
                                    calculator={context.calculator!}
                                    variableIds={alert.inputIds}
                                    onChange={handleInputIdsChange}
                                />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={formRef}
                                label="Blackout Interval"
                                name="interval"
                                info="The amount of time after a notification is sent that needs to pass before another notification can be sent. This limits the number of notifications that will be triggered if the alert is scheduled to check frequently."
                                valuePropName="interval"
                                // rules={[{
                                //     required: false,
                                //     message: 'Fill in the minimum interval.'
                                // }]}
                                rules={[{
                                    validator: () => {
                                        if (!alert.interval || (!CoreUtils.isEmpty(alert.interval.duration) && alert.interval.unitType)) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject(`Fill in the blackout interval.`);
                                        }
                                    }
                                }]}
                            >
                                <BlackoutEditor
                                    interval={alert?.interval}
                                    onChange={handleIntervalChange}
                                />
                            </FormItem>
                            <ScheduleEditor
                                formRef={formRef}
                                label="Conditions Check Frequency"
                                cron={alert.schedule.cron}
                                excludes={["start_time", "end_time"]}
                                onChange={handleCronChange}
                            />
                        </Col>
                        <Col span={24}>
                            {alert.conditions &&
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={formRef}
                                    label="Trigger Conditions"
                                    name="conditions"
                                    info="The conditions that will decide if the alert should be triggered. Leave fields empty that the user should customize and fill in."
                                    required={true}
                                    rules={[{
                                        validator: () => {
                                            for (const condition of alert.conditions) {
                                                if (!condition.variableId) {
                                                    return Promise.reject(`Select a variable in each condition.`);
                                                }
                                            }
                                            return Promise.resolve();
                                        }
                                    }]}
                                >
                                    <FormValue value={alert.conditions}>
                                        <Spacer direction="vertical" fill>
                                            {alert.conditions.map((condition: Condition, index: number) => (
                                                <ConditionEditor
                                                    key={index}
                                                    index={index}
                                                    condition={condition}
                                                    calculator={context.calculator!}
                                                    onChange={handleConditionChange}
                                                    onRemove={handleConditionRemove}
                                                />
                                            ))}
                                        </Spacer>
                                    </FormValue>
                                </FormItem>
                            }
                            <MenuButton
                                className="x-modelalert-condition"
                                label="Add Condition"
                                items={items}
                                icon={<DownOutlined />}
                            />
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={formRef}
                                label="Setup Instructions"
                                name="instructions"
                                info="A set of instructions for the user that describes how to setup the alert conditions. If no instructions are specified, a default interface will be generated for the user."
                                rules={[{
                                    required: false,
                                    message: 'Enter the instructions.'
                                }]}
                            >
                                <InstructionsEditor
                                    alert={alert}
                                    instructions={alert.instructions}
                                    calculator={context.calculator!}
                                    inputIds={alert.inputIds}
                                    conditions={alert.conditions}
                                    onChange={handleInstructionsChange}
                                />
                            </FormItem>
                            {actions}
                        </Col>
                    </Row>
                    <Divider className="x-modelalert-div" type="vertical" />
                    <div className="x-modelalert-right">
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={formRef}
                            label="Notification Message"
                            name="message"
                            info="The text message that will be sent when the alert is triggered."
                            valuePropName="message"
                            rules={[{
                                required: true,
                                message: 'Enter a notification message.'
                            }]}
                        >
                            <MessageEditor
                                message={alert.message}
                                calculator={context.calculator!}
                                onChange={handleMessageChange}
                            />
                        </FormItem>
                        {alert.panel &&
                            <>
                                <Info
                                    className="x-modelalert-visual"
                                    label="Notification Visualization"
                                    bold={true}
                                    info="A visualization that will accompany the notification message if the delivery method supports visual presentation."
                                />
                                <PanelEditor
                                    {...props}
                                    key={alert.id}
                                    model={context.model!}
                                    applet={alert.panel as Applet}
                                    index={0}
                                    panel={alert.panel}
                                    calculator={context.calculator!}
                                    onEdit={handleEditChange}
                                    onUpdate={handlePanelUpdate}
                                />
                            </>
                        }
                        <Button className="x-modelalert-test" onClick={handleAlertTest}>
                            Test Alert
                        </Button>
                    </div >
                </Spacer>
            </Form>
        )
    }

    const buildTestView = (alert: Alert): ReactElement => {
        const buttons: ButtonSpec[] = [{
            role: "close",
        }, {
            role: "test",
            label: "Run",
            primary: true
        }, {
            role: "done"
        }];
        return (
            <AlertSetup
                title="Alert Test"
                alert={alert}
                calculator={context.calculator!}
                inputs={alert.testInputs}
                buttons={buttons}
                onChange={handleInputsChange}
                onClose={handleTestClose}
            />
        )
    }

    return (
        <div id="modelalert" className="x-modelalert">
            {alert === NullAlert && buildLoadView()}
            {alert !== NullAlert && buildAlertView(alert)}
            {alert !== NullAlert && modalType === "test" &&
                buildTestView(alert)
            }
        </div>
    )

}
