import { FC, ReactElement } from 'react';
import { Applet, AppletPanel } from '@methodset/application-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { PanelViewer } from 'containers/Console/Dashboards/DashboardItem/AppletViewer/PanelViewer/PanelViewer';
import { Panel } from 'components/Panel/Panel';
import { Calculator } from '@methodset/calculator-ts';
import { CoreUtils } from 'utils/CoreUtils';
import './NotificationViewer.less';

export type NotificationViewerProps = {
    message: string,
    panel?: AppletPanel,
    calculator?: Calculator
} 

export const NotificationViewer: FC<NotificationViewerProps> = (props: NotificationViewerProps): ReactElement => {

    return (
        <Spacer direction="vertical" size="lg">
            <div className="x-notificationviewer-message">
                {props.message}
            </div>
            {props.panel && CoreUtils.hasSize(props.panel.widgets) && props.calculator &&
                <Panel fill>
                    <PanelViewer
                        applet={props.panel as Applet}
                        panel={props.panel}
                        calculator={props.calculator!}
                        configuration={{}}
                        uneditable={true}
                    />
                </Panel>
            }
        </Spacer>
    );

}
