import { KeyboardEvent, PureComponent, ReactElement } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { CellEditRequestEvent, CellFocusedEvent, CellKeyDownEvent, ColDef, Column, GridApi, GridReadyEvent, RowNode } from 'ag-grid-community';
import { BlockChangeEvent, Cell, CellChangeEvent, ColumnChangeEvent, Coordinate, DimensionType, ElementChangeType, Range, RowChangeEvent, Sheet, SheetChangeEvent, StopWatch } from '@methodset/calculator-ts';
import { KeyCode, KeyUtils } from 'utils/KeyUtils';
import { ActiveCell, ModelContext } from 'context/ModelContext';
import { CoreUtils } from 'utils/CoreUtils';
import { CellEditor } from './CellEditor/CellEditor';
import { ColumnHeader } from './ColumnHeader/ColumnHeader';
import { RowHeader } from './RowHeader/RowHeader';
import { EmitterEvent } from 'utils/EventEmitter';
import { CellRenderer } from './CellRenderer/CellRenderer';
import { message } from 'antd';
import update from 'immutability-helper';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './SheetItem.less';

export type MountCallback = (table: SheetItem, sheet: Sheet) => void;
export type UnmountCallback = (table: SheetItem, sheet: Sheet) => void;

type RowData = { [k: string]: any };
type CellData = {
    cell: Cell,
    value: any
}

// Initial dimensions of a sheet.
const INITIAL_ROWS = 100;
const INITIAL_COLUMNS = 26;
// const INITIAL_ROWS = 5;
// const INITIAL_COLUMNS = 5;

export type SheetItemProps = {
    sheet: Sheet,
    onMount: MountCallback,
    onUnmount: UnmountCallback,
}

export class SheetItem extends PureComponent<SheetItemProps> {

    static contextType = ModelContext;

    private gridApi: GridApi<any> | undefined;

    private isEditing: boolean = false;
    private maxRowIndex: number;
    private maxColIndex: number;

    constructor(props: SheetItemProps) {
        super(props);
        this.maxRowIndex = Math.max(this.props.sheet.maxRowIndex, INITIAL_ROWS - 1);
        this.maxColIndex = Math.max(this.props.sheet.maxColumnIndex, INITIAL_COLUMNS - 1);
        this.handleBlockChange = this.handleBlockChange.bind(this);
        this.handleCellChange = this.handleCellChange.bind(this);
        this.handleSheetFit = this.handleSheetFit.bind(this);
        this.handleRowChange = this.handleRowChange.bind(this);
        this.handleColumnChange = this.handleColumnChange.bind(this);
        this.updateCell = this.updateCell.bind(this);
        this.handleCellEdit = this.handleCellEdit.bind(this);
        this.handleFormulaChangeEvent = this.handleFormulaChangeEvent.bind(this);
        this.handleFormulaFinishEvent = this.handleFormulaFinishEvent.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleEditingStart = this.handleEditingStart.bind(this);
        this.handleEditingStop = this.handleEditingStop.bind(this);
        this.handleCellFocusChangeEvent = this.handleCellFocusChangeEvent.bind(this);
        this.handleCellFocusChange = this.handleCellFocusChange.bind(this);
        this.handleGridReady = this.handleGridReady.bind(this);
    }

    private handleBlockChange(event: BlockChangeEvent): void {
        const api = this.gridApi!;
        //const sw = new StopWatch();
        //sw.start();
        if (event.type === ElementChangeType.ADD) {
            const block = event.block;
            const range = block.range;
            this.addBlock(api, range);
        } else if (event.type === ElementChangeType.REMOVE) {
            const block = event.block;
            const range = block.range;
            this.removeBlock(api, range);
        } else if (event.type === ElementChangeType.UPDATE) {
            const oldRange = event.prev!.range;
            const newRange = event.block.range;
            this.updateBlock(api, oldRange, newRange);
        }
        //sw.stop();
        // console.info(`Block ${event.type.toLowerCase()}: ${sw.diff()} ms`);
        this.syncActiveWithEditor(api);
        // this.printData();
    }

    private syncActiveWithEditor(api: GridApi<any>): void {
        const cell = this.context.active.cell;
        if (cell.sheet === this.props.sheet) {
            this.syncCellWithEditor(cell.id);
        }
    }

    private getActiveCell(api: GridApi<any>): string | null {
        const position = api.getFocusedCell();
        if (!position) {
            return null;
        }
        return `${position.column.getColId()}${position.rowIndex + 1}`;
    }

    private addBlock(api: GridApi<any>, range: Range): void {
        const upper = range.upper;
        const lower = range.lower;
        // Add columns that are outside the existing grid.
        if (lower.col > this.maxColIndex) {
            this.addColumns(api, lower);
        }
        this.adjustRowWidth(api, lower.row);

        let updateData, addData;

        // Update rows that are inside the current grid bounds.
        // For example, block = A1:B3,
        //     A   B
        //    --- ---
        // 1 | x | x |
        //    --- ---
        // 2 | x | x |
        //    --- ---   <-- bounds of old grid
        // 3 |   |   |
        //    --- ---
        const row = Math.min(lower.row, this.maxRowIndex);
        const lower1 = Coordinate.fromRowCol(row, lower.col);
        const range1 = Range.fromCoordinates(this.props.sheet, upper, lower1);
        updateData = this.updateRowsData(api, range1);

        if (lower.row > this.maxRowIndex) {
            // Add rows that are outside the current bounds.
            // For example, block = A1:B3,
            //     A   B
            //    --- ---
            // 1 |   |   |
            //    --- ---
            // 2 |   |   |
            //    --- ---   <-- bounds of old grid
            // 3 | x | x |
            //    --- ---
            const upper2 = Coordinate.fromRowCol(row + 1, upper.col);
            const range2 = Range.fromCoordinates(this.props.sheet, upper2, lower);
            addData = this.addRowsData(range2);
        }
        api.applyTransaction({
            update: updateData,
            add: addData
        });
    }

    private removeBlock(api: GridApi<any>, range: Range): void {
        const updateData = this.clearRowsData(api, range, true);
        api.applyTransaction({
            update: updateData
        });
    }

    private updateBlock(api: GridApi<any>, oldRange: Range, newRange: Range): void {
        const oldUpper = oldRange.upper;
        const oldLower = oldRange.lower;
        const newUpper = newRange.upper;
        const newLower = newRange.lower;

        // Add columns that are outside the existing grid.
        if (newLower.col > this.maxColIndex) {
            this.addColumns(api, newLower);
        }

        // Update cells that overlap both the old and new block.
        // For example, old block = A1:A1, new block = A1:B2,
        //     A   B
        //    --- ---
        // 1 | x |   |
        //    --- ---
        // 2 |   |   |
        //    --- ---
        const row = Math.min(newLower.row, oldLower.row);
        const col = Math.min(newLower.col, oldLower.col);
        const coord = Coordinate.fromRowCol(row, col);
        const range = Range.fromCoordinates(this.props.sheet, newUpper, coord);
        let updateData = this.updateRowsData(api, range);

        // New block has fewer columns than the old block.
        if (newLower.col < oldLower.col) {
            // Clear data in columns that are next to the old range.
            // For example, old block = A1:B2, new block = A1:A1,
            //     A   B
            //    --- ---
            // 1 |   | x |
            //    --- ---
            // 2 |   |   |
            //    --- ---
            const upper = Coordinate.fromRowCol(oldUpper.row, newLower.col + 1);
            const lower = Coordinate.fromRowCol(newLower.row, oldLower.col);
            const range = Range.fromCoordinates(this.props.sheet, upper, lower);
            const updateData1 = this.clearRowsData(api, range, false);
            // Overwrites rows that were already updated.
            updateData = [...updateData, ...updateData1];
        }

        // New block has fewer rows than the old block.
        if (newLower.row < oldLower.row) {
            // Clear data in rows that are no longer in the block.
            // For example, old block = A1:B2, new block = A1:A1,
            //     A   B
            //    --- ---
            // 1 |   |   |
            //    --- ---
            // 2 | x | x |
            //    --- ---
            const upper = Coordinate.fromRowCol(newLower.row + 1, oldUpper.col);
            const lower = Coordinate.fromRowCol(oldLower.row, oldLower.col);
            const range = Range.fromCoordinates(this.props.sheet, upper, lower);
            const updateData1 = this.clearRowsData(api, range, false);
            // Updates different set of rows, add to existing set.
            updateData = [...updateData, ...updateData1];
        }

        // New block has more columns than the old block.
        if (newLower.col > oldLower.col) {
            // Add data in columns in new range that are next to old range.
            // For example, old block = A1:A1, new block = A1:B2,
            //     A   B
            //    --- ---
            // 1 |   | x |
            //    --- ---
            // 2 |   |   |
            //    --- ---
            const upper = Coordinate.fromRowCol(newUpper.row, oldLower.col + 1);
            const lower = Coordinate.fromRowCol(oldLower.row, newLower.col);
            const range = Range.fromCoordinates(this.props.sheet, upper, lower);
            const updateData1 = this.updateRowsData(api, range);
            updateData = [...updateData, ...updateData1];
        }

        // New block has more rows than the old block.
        if (newLower.row > oldLower.row) {
            // Add data in rows in new range that are below the old range,
            // but that do not expand the grid. Those are added next.
            // For example, old block = A1:A1, new block = A1:B3,
            //     A   B
            //    --- ---
            // 1 |   |   |
            //    --- ---
            // 2 | x | x |
            //    --- ---   <-- bounds of old grid
            // 3 |   |   |
            //    --- ---
            const row = Math.min(newLower.row, this.maxRowIndex);
            const upper = Coordinate.fromRowCol(oldLower.row + 1, newUpper.col);
            const lower = Coordinate.fromRowCol(row, newLower.col);
            const range = Range.fromCoordinates(this.props.sheet, upper, lower);
            const updateData1 = this.updateRowsData(api, range);
            updateData = [...updateData, ...updateData1];
        }

        // Add rows that are below the current grid bounds.
        // For example, old block = A1:A1, new block = A1:B3,
        //     A   B
        //    --- ---
        // 1 |   |   |
        //    --- ---
        // 2 |   |   |
        //    --- ---   <-- bounds of old grid
        // 3 | x | x |
        //    --- ---
        let addData: RowData[] | undefined;
        if (newLower.row > this.maxRowIndex) {
            const upper = Coordinate.fromRowCol(this.maxRowIndex + 1, newUpper.col);
            const range = Range.fromCoordinates(this.props.sheet, upper, newLower);
            addData = this.addRowsData(range);
        }
        api.applyTransaction({
            update: updateData,
            add: addData
        });
    }

    private addColumns(api: GridApi<any>, lower: Coordinate): void {
        let columnDefs = api.getColumnDefs()!;
        for (let c = this.maxColIndex + 1; c <= lower.col; c++) {
            const colId = Coordinate.toColumnId(c);
            const columnDef = this.buildColumnDef(colId);
            columnDefs.push(columnDef);
            this.maxColIndex++;
        }
        api.setColumnDefs(columnDefs);
        this.maxColIndex = lower.col;
    }

    private updateRowsData(api: GridApi<any>, range: Range): RowData[] {
        const upper = range.upper;
        const lower = range.lower;
        // Update rows that are inside the current bounds.
        const lower1 = Coordinate.fromRowCol(this.maxRowIndex, lower.col);
        const range1 = Range.fromCoordinates(this.props.sheet, upper, lower1);
        const rowsData: RowData[] = [];
        let rowData: RowData;
        let prevRow = -1;
        range1.forEachCell((cell, index) => {
            if (prevRow !== cell.row) {
                rowData = api.getRowNode(cell.rowId)!.data;
                prevRow = cell.row;
                rowsData.push(rowData);
            }
            const data = {
                cell: cell,
                value: CoreUtils.toDisplayValue(cell)
            } as CellData;
            rowData[cell.colId] = data;
        }, DimensionType.ROW);
        return rowsData;
    }

    private addRowsData(range: Range): RowData[] {
        const upper = range.upper;
        const lower = range.lower;
        // Add rows that are outside the current bounds.
        const rowsData: RowData[] = [];
        let rowData: RowData;
        let prevRow = -1;
        range.forEachCell((cell, index) => {
            if (prevRow !== cell.row) {
                rowData = {
                    row: cell.rowId
                }
                prevRow = cell.row;
                rowsData.push(rowData);
            }
            const data = {
                cell: cell,
                value: CoreUtils.toDisplayValue(cell)
            } as CellData;
            rowData[cell.colId] = data;
        }, DimensionType.ROW);
        this.maxRowIndex = lower.row;
        return rowsData;
    }

    private clearRowsData(api: GridApi<any>, range: Range, hasRoot: boolean): RowData[] {
        const rowsData: RowData[] = [];
        let rowData: RowData;
        let prevRow = -1;
        range.forEach((cell, row, col, index) => {
            if (prevRow !== row) {
                const rowId = Coordinate.toRowId(row);
                rowData = api.getRowNode(rowId)!.data;
                prevRow = row;
                rowsData.push(rowData);
            }
            // Root cell will be removed via a cell update.
            if (hasRoot && index === 0) {
                return;
            }
            const colId = Coordinate.toColumnId(col);
            const data = rowData[colId];
            if (!data) {
                return;
            }
            delete rowData[colId];
        }, DimensionType.ROW);
        return rowsData;
    }

    private handleCellChange(event: CellChangeEvent): void {
        const api = this.gridApi!;
        const cell = event.cell;
        const rowNode = api.getRowNode(cell.rowId);
        if (!rowNode) {
            // Sanity check.
            return;
        }
        if (event.type === ElementChangeType.ADD) {
            // A cell was added to the sheet.
            this.addCell(rowNode, event.cell);
        } else if (event.type === ElementChangeType.REMOVE) {
            // A cell was removed from the sheet.
            this.removeCell(rowNode, cell);
        } else if (event.type === ElementChangeType.UPDATE) {
            // A cell value changed.
            this.updateCell(rowNode, cell);
        }
        //this.printData();
    }

    private addCell(rowNode: RowNode<any>, cell: Cell): void {
        const data = {
            cell: cell,
            value: CoreUtils.toDisplayValue(cell)
        }
        rowNode.setDataValue(cell.colId, data);
    }

    private removeCell(rowNode: RowNode<any>, cell: Cell): void {
        const api = this.gridApi!;
        const rowsData: RowData[] = [];
        let rowData = rowNode.data;
        delete rowData[cell.colId];
        rowsData.push(rowData);
        api.applyTransaction({
            update: rowsData
        });
    }

    private updateCell(rowNode: RowNode<any>, cell: Cell): void {
        const data = {
            cell: cell,
            value: CoreUtils.toDisplayValue(cell)
        }
        rowNode.setDataValue(cell.colId, data);
    }

    private handleKeyDown(e: CellKeyDownEvent): void {
        const api = this.gridApi!;
        const row = e.rowIndex!;
        const colId = e.column.getColId();
        const cellId = `${colId}${row + 1}`;
        const event = e.event as any;
        //const event = e.event as KeyboardEvent;
        //const code = event.code;

        // Permissions prevent the user from reading from the native clipboard.
        // Use the built-in clipboard. However, try to copy to the native clipboard
        // and allow native paste into browser inputs. This is a temporary hack until
        // true cell/range/row/column copy/paste is implemented.
        if (KeyUtils.isCopy(event)) {
            const cell = this.props.sheet.getCell(cellId, false);
            if (cell) {
                const value = cell.formula ?? cell.value;
                try {
                    navigator.clipboard.writeText(value);
                } catch (e) {
                    // Could not copy to clipboard as backup, skip.
                }
                this.context.writeClipboard(value);
                console.log(`Copy to clipboard: ${value}`);
            }
            return;
        } else if (KeyUtils.isPaste(event)) {
            event.preventDefault();
            //const value = navigator.clipboard.readText();
            const value = this.context.readClipboard();
            if (!CoreUtils.isEmpty(value)) {
                console.log(`Paste from clipboard: ${value}`);
                api.startEditingCell({ rowIndex: row, colKey: colId, key: value });
            }
            return;
        }

        if (!KeyUtils.isCommit(event) && !KeyUtils.isArrow(event) && !KeyUtils.isCancel(event)) {
            // Not a navigation key, check if for special cases.
            if (!this.isEditing) {
                if (KeyUtils.isPrintable(event)) {
                    // Start editor and initialize with the key pressed if a printable character.
                    api.startEditingCell({ rowIndex: row, colKey: colId, key: event.key });
                } else if (KeyUtils.isDelete(event)) {
                    // Delete cell value even if not in edit mode.
                    const cell = this.props.sheet.getCell(cellId, false);
                    if (cell) {
                        cell.clear();
                        const data = {
                            value: undefined
                        }
                        this.context.sendEvent("CellEditChange", data);
                    }
                }
            }
            return;
        }
        // This is a navigation key.
        if (this.isEditing) {
            if (KeyUtils.isCancel(event)) {
                api.stopEditing(true);
                api.setFocusedCell(row, colId);
                return;
            } else if (KeyUtils.isCommit(event)) {
                api.stopEditing(false);
            } else {
                return;
            }
        }
        let coord = Coordinate.fromCellId(cellId);
        this.navigate(event, coord);
    }

    private navigate(e: KeyboardEvent, coord: Coordinate): void {
        let row, col: number;
        if (KeyUtils.in(e, KeyCode.ENTER, KeyCode.ARROW_DOWN)) {
        //if (e.code === KeyCode.ENTER || e.code === KeyCode.ARROW_DOWN) {
            // Move to cell below.
            row = coord.row + 1;
            col = coord.col;
            coord = Coordinate.fromRowCol(row, col);
            // Add a new row if past last row.
            if (coord.row > this.maxRowIndex) {
                this.addRow();
            }
        } else if (KeyUtils.in(e, KeyCode.TAB, KeyCode.ARROW_RIGHT)) {
            //} else if (e.code === KeyCode.TAB || e.code === KeyCode.ARROW_RIGHT) {
            // Move to cell to right.
            row = coord.row;
            col = coord.col + 1;
            coord = Coordinate.fromRowCol(row, col);
            // Add a new column if past last column.
            if (coord.col > this.maxColIndex) {
                this.addLastColumn();
            }
        } else if (KeyUtils.is(e, KeyCode.ARROW_UP)) {
            //} else if (e.code === KeyCode.ARROW_UP) {
            // Move to cell up.
            if (coord.row === 0) {
                return;
            }
            row = coord.row - 1;
            col = coord.col;
            coord = Coordinate.fromRowCol(row, col);
        } else if (KeyUtils.is(e, KeyCode.ARROW_LEFT)) {
            //} else if (e.code === KeyCode.ARROW_LEFT) {
            // Move to cell left.
            if (coord.col === 0) {
                return;
            }
            row = coord.row;
            col = coord.col - 1;
            coord = Coordinate.fromRowCol(row, col);
        } else {
            return;
        }
        const api = this.gridApi!;
        api.setFocusedCell(row, coord.colId);
    }

    private adjustRowWidth(api: GridApi<any>, row: number): void {
        const oldWidth = this.calcWidth(this.maxRowIndex + 1);
        const newWidth = this.calcWidth(row + 1);
        if (oldWidth < newWidth) {
            let columnDefs = api.getColumnDefs()!;
            columnDefs = update(columnDefs, {
                [0]: {
                    width: { $set: newWidth }
                }
            });
            api.setColumnDefs(columnDefs);
        }
    }

    private addRow(): void {
        const api = this.gridApi!;
        const rowId = Coordinate.toRowId(this.maxRowIndex + 1);
        const rowsData: RowData[] = [];
        const rowData: RowData = {
            row: rowId
        };
        rowsData.push(rowData);
        api.applyTransaction({
            add: rowsData
        });
        this.adjustRowWidth(api, this.maxRowIndex + 1);
        this.maxRowIndex++;
    }

    private addLastColumn(): void {
        const api = this.gridApi!;
        const colId = Coordinate.toColumnId(this.maxColIndex + 1);
        const columnDef = this.buildColumnDef(colId);
        let columnDefs = api.getColumnDefs()!;
        columnDefs = update(columnDefs, {
            $push: [columnDef]
        });
        api.setColumnDefs(columnDefs);
        this.maxColIndex++;
    }

    private removeLastColumn(): void {
        const api = this.gridApi!;
        let columnDefs = api.getColumnDefs()!;
        const index = columnDefs.length - 1;
        columnDefs = update(columnDefs, {
            $splice: [[index, 1]]
        });
        api.setColumnDefs(columnDefs);
        this.maxColIndex--;
    }

    private handleEditingStart(): void {
        this.isEditing = true;
    }

    private handleEditingStop(): void {
        this.isEditing = false;
    }

    private handleCellFocusChangeEvent(event: EmitterEvent): void {
        //console.info(`Cell focus event: ${event.data.id}`);
        const activeCell = event.data as ActiveCell;
        if (activeCell.sheet !== this.props.sheet && activeCell.prev?.sheet === this.props.sheet) {
            //console.info(`Cell remove edit handler: ${this.props.sheet.id}`);
            this.context.removeCallback("FormulaEditChange", this.handleFormulaChangeEvent);
            this.context.removeCallback("FormulaEditFinish", this.handleFormulaFinishEvent);
        } else if (activeCell.sheet === this.props.sheet && activeCell.prev?.sheet !== this.props.sheet) {
            //console.info(`Cell add edit handler: ${this.props.sheet.id}`);
            this.context.addCallback("FormulaEditChange", this.handleFormulaChangeEvent);
            this.context.addCallback("FormulaEditFinish", this.handleFormulaFinishEvent);
        }
        if (!this.isEditing) {
            return;
        }
        const api = this.gridApi!;
        const positions = api.getEditingCells();
        if (positions.length === 0) {
            return;
        }
        for (let position of positions) {
            const cellId = `${position.column.getColId()}${position.rowIndex + 1}`;
            // A another cell or parameter has gained focus, commit the edit value.
            if (activeCell.id !== cellId || activeCell.sheet !== this.props.sheet) {
                api.stopEditing(false);
            }
        }
    }

    private handleCellFocusChange(event: CellFocusedEvent): void {
        const column = event.column as Column;
        if (!column) {
            return;
        }
        const colId = column.getColId();
        if (colId === "row") {
            return;
        }
        const rowIdx = event.rowIndex;
        if (rowIdx === null) {
            return;
        }
        const cellId = `${colId}${rowIdx + 1}`;
        const cell = this.props.sheet.getCell(cellId, false, false, false);
        let variableId;
        if (cell && cell.variable) {
            variableId = cell.variable.id;
        }
        this.context.setFocusedCell(this.props.sheet, cellId, variableId);
        this.syncCellWithEditor(cellId);
    }

    private syncCellWithEditor(cellId: string): void {
        const cell = this.props.sheet.getCell(cellId, false);
        const value = CoreUtils.toEditValue(cell);
        const data = {
            value: value
        }
        this.context.sendEvent("CellEditChange", data);
    }

    public handleSheetFit(): void {
        const api = this.gridApi!;
        const maxRowIndex = Math.max(this.props.sheet.maxRowIndex, INITIAL_ROWS - 1);
        const maxColIndex = Math.max(this.props.sheet.maxColumnIndex, INITIAL_COLUMNS - 1);
        this.removeExcessRows(api, maxRowIndex);
        this.removeExcessColumns(api, maxColIndex);
        api.setFocusedCell(0, "A");
    }

    private removeExcessRows(api: GridApi<any>, maxRowIndex: number): void {
        if (maxRowIndex < this.maxRowIndex) {
            const rowsData: RowData[] = [];
            for (let r = maxRowIndex + 1; r <= this.maxRowIndex; r++) {
                const rowId = Coordinate.toRowId(r);
                const rowData = {
                    row: rowId
                }
                rowsData.push(rowData);
            }
            api.applyTransaction({
                remove: rowsData
            });
            this.maxRowIndex = maxRowIndex;
        }
    }

    private removeExcessColumns(api: GridApi<any>, maxColIndex: number): void {
        if (maxColIndex < this.maxColIndex) {
            // Offset one extra to compensate for first column being the row index.
            let columnDefs = api.getColumnDefs()!
            columnDefs.splice(maxColIndex + 2, this.maxColIndex - maxColIndex);
            api.setColumnDefs(columnDefs);
            this.maxColIndex = maxColIndex;
        }
    }

    public onSheetActivate(): void {
        const api = this.gridApi;
        if (!api) {
            // API initializing, not ready.
            return;
        }
        const cellId = this.getActiveCell(api);
        if (!cellId) {
            // No cell has focus yet.
            return;
        }
        const coord = Coordinate.fromCellId(cellId);
        api.setFocusedCell(coord.row, coord.colId);
    }

    private printData() {
        const api = this.gridApi!;
        const calculator = this.context.calculator;
        calculator.printState(true);
        const model = api.getModel();
        const count = model.getRowCount();
        for (let i = 0; i < count; i++) {
            const row = model.getRow(i);
            console.log(row!.data);
        }
        console.log(`Rows: ${this.maxRowIndex + 1}, columns: ${this.maxColIndex + 1}`);
    }

    private handleRowChange(event: RowChangeEvent): void {
        const api = this.gridApi!;
        if (event.type === ElementChangeType.ADD) {
            this.insertRow(api, event.rowId);
        } else if (event.type === ElementChangeType.REMOVE) {
            this.deleteRow(api, event.rowId);
        }
        //this.printRowsData();
    }

    private handleColumnChange(event: ColumnChangeEvent): void {
        const api = this.gridApi!;
        if (event.type === ElementChangeType.ADD) {
            this.insertColumn(api, event.columnId);
        } else if (event.type === ElementChangeType.REMOVE) {
            this.deleteColumn(api, event.columnId);
        }
        //this.printRowsData();
    }

    private insertRow(api: GridApi<any>, rowId: string): void {
        const addData: RowData[] = [];
        const updateData: RowData[] = [];

        const insertRow = Coordinate.toRowIndex(rowId);
        if (insertRow > this.maxRowIndex) {
            // Adding a row to the bottom.
            const rowData = {
                row: rowId
            }
            addData.push(rowData);
        } else {
            // Shift the last row at the bottom of the grid.
            const lastRowId = Coordinate.toRowId(this.maxRowIndex);
            let rowData = api.getRowNode(lastRowId)!.data;
            rowData["row"] = Coordinate.toRowId(this.maxRowIndex + 1);
            addData.push(rowData);

            // Shift the rows down below the insertion point.
            for (let r = this.maxRowIndex - 1; r >= insertRow; r--) {
                const shiftRowId = Coordinate.toRowId(r);
                const rowData = api.getRowNode(shiftRowId)!.data;
                rowData["row"] = Coordinate.toRowId(r + 1);
                updateData.push(rowData);
            }

            // Insert an empty row at the insertion point.
            rowData = {
                row: rowId
            }
            updateData.push(rowData);
        }

        api.applyTransaction({
            update: updateData,
            add: addData
        });

        this.maxRowIndex++;

        // Set the focus cell.
        const activeCell = this.context.active.cell;
        const coord = Coordinate.fromCellId(activeCell.id);
        if (coord.row >= insertRow && coord.row < this.maxRowIndex) {
            api.setFocusedCell(coord.row + 1, coord.colId);
        }
    }

    private deleteRow(api: GridApi<any>, rowId: string): void {
        // Do not remove only row.
        if (this.maxRowIndex === 0) {
            message.error("Grid must have at least one row.");
            return;
        }

        const updateData: RowData[] = [];
        const removeData: RowData[] = [];

        const removeRow = Coordinate.toRowIndex(rowId);
        for (let r = removeRow + 1; r <= this.maxRowIndex; r++) {
            const rowId = Coordinate.toRowId(r);
            const rowData = api.getRowNode(rowId)!.data;
            rowData["row"] = Coordinate.toRowId(r - 1);
            updateData.push(rowData);
        }

        // Remove the bottom row.
        const rowData = {
            row: Coordinate.toRowId(this.maxRowIndex)
        }
        removeData.push(rowData);

        api.applyTransaction({
            update: updateData,
            remove: removeData
        });

        this.maxRowIndex--;

        // Set the focus cell.
        const activeCell = this.context.active.cell;
        const coord = Coordinate.fromCellId(activeCell.id);
        if (coord.row >= removeRow && coord.row > this.maxRowIndex) {
            api.setFocusedCell(coord.row - 1, coord.colId);
        } else {
            api.setFocusedCell(coord.row, coord.colId);
        }
    }

    private insertColumn(api: GridApi<any>, colId: string): void {
        this.addLastColumn();

        // Find the range that needs to be shifted right.
        const upperRow = 0;
        const upperCol = Coordinate.toColumnIndex(colId);
        const lowerRow = this.maxRowIndex;
        const lowerCol = this.maxColIndex;
        const upper = Coordinate.fromRowCol(upperRow, upperCol);
        const lower = Coordinate.fromRowCol(lowerRow, lowerCol);
        const range = Range.fromCoordinates(this.props.sheet, upper, lower);

        const map = new Map<string, RowData>();

        // Shift the cell values.
        let prevData: CellData | null = null;

        range.forEachCell((cell) => {
            const sourceId = Coordinate.toColumnId(cell.col);
            const targetId = Coordinate.toColumnId(cell.col + 1);
            const rowId = cell.rowId;
            let rowData = map.get(rowId);
            if (!rowData) {
                // Start of a new row.
                rowData = api.getRowNode(rowId)!.data;
                if (!rowData) {
                    return;
                }
                map.set(rowId, rowData);
                prevData = rowData[sourceId];
                delete rowData[sourceId];
            }
            const nextData = rowData[targetId];
            rowData[targetId] = prevData;
            prevData = nextData;
        }, DimensionType.ROW);

        const rowsData = [...map.values()];
        api.applyTransaction({
            update: rowsData
        });

        // Set the focus cell.
        const colIndex = Coordinate.toColumnIndex(colId);
        const activeCell = this.context.active.cell;
        const coord = Coordinate.fromCellId(activeCell.id);
        if (coord.col >= colIndex && coord.col < this.maxColIndex) {
            const colId = Coordinate.toColumnId(coord.col + 1);
            api.setFocusedCell(coord.row, colId);
        }
    }

    private deleteColumn(api: GridApi<any>, colId: string): void {
        // Do not remove only column.
        if (this.maxColIndex === 0) {
            message.error("Grid must have at least one column.");
            return;
        }
        this.removeLastColumn();

        // Remove the cells in the deleted column.
        const rowsData: RowData[] = [];
        for (let r = 0; r <= this.maxRowIndex; r++) {
            const rowId = Coordinate.toRowId(r);
            let rowData = api.getRowNode(rowId)!.data;
            delete rowData[colId];
            rowsData.push(rowData);
        }

        // Calculate the range that needs to be shifted left.
        const upperRow = 0;
        const upperCol = Coordinate.toColumnIndex(colId);
        const lowerRow = this.maxRowIndex;
        const lowerCol = this.maxColIndex;
        const upper = Coordinate.fromRowCol(upperRow, upperCol);
        const lower = Coordinate.fromRowCol(lowerRow, lowerCol);
        const range = Range.fromCoordinates(this.props.sheet, upper, lower);

        // Shift the cell values.
        range.forEachCell((cell) => {
            const rowData = rowsData[cell.row];
            const targetId = Coordinate.toColumnId(cell.col - 1);
            const sourceId = Coordinate.toColumnId(cell.col);
            rowData[targetId] = rowData[sourceId];
            delete rowData[sourceId];
        }, DimensionType.ROW);

        api.applyTransaction({
            update: rowsData
        });

        // Set the focus cell.
        const colIndex = Coordinate.toColumnIndex(colId);
        const activeCell = this.context.active.cell;
        const coord = Coordinate.fromCellId(activeCell.id);
        if (coord.col >= colIndex && coord.col > this.maxColIndex) {
            const colId = Coordinate.toColumnId(coord.col - 1);
            api.setFocusedCell(coord.row, colId);
        } else {
            api.setFocusedCell(coord.row, coord.colId);
        }
    }

    private handleGridReady(e: GridReadyEvent<any>): void {
        this.gridApi = e.api;
        this.gridApi.setFocusedCell(0, "A");
    }

    private calcWidth(numRows: number): number {
        let places = 0;
        while (numRows > 0) {
            numRows = Math.floor(numRows / 10);
            places++;
        }
        return (places * 15) + 20;
    }

    private buildColumnDefs(): ColDef[] {
        const numRows = Math.max(this.maxRowIndex, this.props.sheet.maxRowIndex) + 1;
        const width = this.calcWidth(numRows);
        const columnDefs: ColDef[] = [{
            field: "row",
            pinned: "left",
            lockPinned: true,
            suppressMovable: true,
            width: width,
            headerName: "",
            cellClass: "x-sheetitem-row",
            cellRenderer: RowHeader,
            cellRendererParams: {
                sheet: this.props.sheet
            }
        }];
        const numCols = Math.max(this.maxColIndex, this.props.sheet.maxColumnIndex) + 1;
        for (let c = 0; c < numCols; c++) {
            const colId = Coordinate.toColumnId(c);
            const columnDef = this.buildColumnDef(colId);
            columnDefs.push(columnDef);
        }
        return columnDefs;
    }

    private buildColumnDef(colId: string): ColDef {
        const columnDef: ColDef = {
            field: colId,
            editable: true,
            resizable: true,
            width: 150,
            suppressMovable: true,
            headerName: colId,
            cellClass: "x-sheetitem-cell",
            cellEditor: CellEditor,
            cellEditorParams: {},
            headerComponent: ColumnHeader,
            headerComponentParams: {
                id: colId,
                sheet: this.props.sheet
            },
            cellRenderer: CellRenderer,
            suppressHeaderKeyboardEvent: () => true,
            suppressKeyboardEvent: () => true
        }
        return columnDef;
    }

    private buildRowsData(): any[] {
        let rowsData = [];
        const numRows = Math.max(this.maxRowIndex, this.props.sheet.maxRowIndex) + 1;
        const rows = this.props.sheet.rows;
        for (let r = 0; r < numRows; r++) {
            let rowData: RowData = {
                row: `${r + 1}`
            };
            const row = rows.get(r);
            if (row) {
                // Iterate each actual cell in the row.
                row.cells.forEach((cell: Cell) => {
                    rowData[cell.colId] = {
                        cell: cell,
                        value: CoreUtils.toDisplayValue(cell)
                    } as CellData;
                });
            }
            rowsData.push(rowData);
        }
        return rowsData;
    }

    public handleCellEdit(event: CellEditRequestEvent): void {
        // Cell editor complete, set value into cell.
        const colId = event.column.getColId();
        const data = event.data[colId];
        const value = event.newValue;
        let cell: Cell = data ? data.cell : undefined;
        if (!cell && value) {
            // Create a cell if not created.
            const row = event.rowIndex!;
            const cellId = `${colId}${row + 1}`;
            cell = this.props.sheet.getCell(cellId);
            this.setCellValue(cell, value);
        } else if (cell) {
            this.setCellValue(cell, value);
        }
    }

    private handleFormulaChangeEvent(event: EmitterEvent): void {
        // Input change event from formula editor.
        if (this.isEditing) {
            // Cell editor will handle changes if it is active.
            return;
        } else {
            // Cell editor is not active, activate it, but let
            // the formula editor to continue to control editing.
            const api = this.gridApi!;
            const position = api.getFocusedCell();
            if (!position) {
                return;
            }
            const row = position.rowIndex;
            const colId = position.column.getColId();
            const value = event.data.value;
            api.startEditingCell({
                rowIndex: row,
                colKey: colId,
                key: value,
                charPress: "FormulaEditor"
            });
        }
    }

    private handleFormulaFinishEvent(event: EmitterEvent): void {
        // Editing finished event from formula editor.
        const api = this.gridApi!;
        //console.log(`Key from external: ${event.data.key}`);
        const position = api.getFocusedCell();
        if (!position) {
            // Sanity check.
            return;
        }
        const cellId = `${position.column.getColId()}${position.rowIndex + 1}`;
        const coord = Coordinate.fromCellId(cellId);
        const code = event.data.code;
        const e = { code: code } as KeyboardEvent;
        if (!KeyUtils.isCommit(e)) {
            // Not committing the value, restore the original cell value.
            const rowId = `${position.rowIndex + 1}`;
            const rowNode = api.getRowNode(rowId);
            if (!rowNode) {
                return;
            }
            //const cell = this.props.sheet.getCell(cellId);
            let cell = this.props.sheet.getCell(cellId, false);
            if (!cell) {
                // Sanity check.
                return;
            }
            const colId = position.column.getColId();
            let data = rowNode.data[colId];
            data = update(data, {
                value: { $set: CoreUtils.toDisplayValue(cell) }
            });
            rowNode.setDataValue(colId, data);
            if (KeyUtils.isCancel(e)) {
                // If canceling the edit, send the original value back to editor.
                const value = cell ? cell.value : undefined;
                const data = {
                    value: value
                }
                this.context.sendEvent("CellEditChange", data);
                if (this.isEditing) {
                    // Stop the editor.
                    api.stopEditing(true);
                    api.setFocusedCell(coord.row, coord.colId);
                    return;
                }
            }
        }
        // Navigation will stop the editor which will force the cell 
        // edit event to be sent and its value to be updated.
        this.navigate(e, coord);
    }

    private setCellValue(cell: Cell, value: any): void {
        if (CoreUtils.isFormula(value)) {
            cell.formula = value;
        } else if (!value) {
            cell.value = undefined;
        } else {
            cell.value = value;
        }
    }

    public componentDidMount(): void {
        this.context.addCallback("CellFocusChange", this.handleCellFocusChangeEvent);
        this.props.sheet.addCallback("CellChange", this.handleCellChange);
        this.props.sheet.addCallback("RowChange", this.handleRowChange);
        this.props.sheet.addCallback("ColumnChange", this.handleColumnChange);
        this.props.sheet.addCallback("BlockChange", this.handleBlockChange);
        this.props.onMount(this, this.props.sheet);
    }

    public componentWillUnmount(): void {
        this.context.removeCallback("CellFocusChange", this.handleCellFocusChangeEvent);
        this.props.sheet.removeCallback("CellChange", this.handleCellChange);
        this.props.sheet.removeCallback("RowChange", this.handleRowChange);
        this.props.sheet.removeCallback("ColumnChange", this.handleColumnChange);
        this.props.sheet.removeCallback("BlockChange", this.handleBlockChange);
        this.props.onUnmount(this, this.props.sheet);
    }

    public render(): ReactElement {
        // Grid will render once on start-up. Add modifications will
        // be handled internally and will not trigger a re-render.
        const rowsData = this.buildRowsData()
        const columnDefs = this.buildColumnDefs()
        return (
            <div
                id={`worksheet-${this.props.sheet.uuid}`}
                className="ag-theme-alpine x-sheetitem"
            >
                <AgGridReact
                    rowData={rowsData}
                    columnDefs={columnDefs}
                    readOnlyEdit={true}
                    getRowId={params => params.data.row}
                    suppressChangeDetection={true}
                    onCellEditRequest={this.handleCellEdit}
                    onCellKeyDown={this.handleKeyDown}
                    onCellEditingStarted={this.handleEditingStart}
                    onCellEditingStopped={this.handleEditingStop}
                    onCellFocused={this.handleCellFocusChange}
                    onGridReady={this.handleGridReady}
                />
            </div>
        )
    }
}
