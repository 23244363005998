import { FC, ReactElement } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { ErrorCell } from './ErrorCell/ErrorCell';
import { FormulaCell } from './FormulaCell/FormulaCell';
import { BlockCell } from './BlockCell/BlockCell';
import { CalculatorError } from '@methodset/calculator-ts';

export const CellRenderer: FC<ICellRendererParams> = (props: ICellRendererParams): ReactElement => {

    const view = (): ReactElement => {
        const cell = props.value ? props.value.cell : undefined;
        if (!cell) {
            return <></>;
        }
        const value = props.value ? props.value.value : undefined;
        if (CalculatorError.isError(value)) {
            return <>{value.type}</>;
        } else if (cell.hasError()) {
            return <ErrorCell value={value} cell={cell} />;
        } else if (cell.isBlockMember()) {
            return <BlockCell value={value} />;
        } else if (cell.hasFormula()) {
            return <FormulaCell value={value} />;
        } else {
            return <>{value}</>;
        }
    }

    return (
        view()
    )
}