import { ReactElement } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Request } from '../Request/Request';
import { RequestType } from '@methodset/entity-client-ts';
import { RouteBuilder } from 'utils/RouteBuilder';

export type DemoProps = RouteComponentProps;

export const Demo = (props: DemoProps): ReactElement => {

    return (
        <Request
            {...props}
            type={RequestType.DEMO}
            requestText={
                <p>
                    Thank you for your interest in the platform. Please fill out the form if you
                    would like a personal live demo where you can see the different features of the platform
                    and ask any questions you may have. You can also check out the
                    &nbsp;<Link to={RouteBuilder.MAIN_REGISTER}>tutorials</Link>&nbsp;
                    if you would like to see how different parts of the system work.
                </p>
            }
            responseText="Thank you for submitting your request. A member of our team will reach out to you."
        />
    )

}
