import { PureComponent, ReactElement } from 'react';
import { Globals } from 'constants/Globals';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import load from 'load-script';
import './FastLink.less';

export type SuccessCallback = (data: any) => void;
export type ErrorCallback = (data: any) => void;
export type CloseCallback = (data: any) => void;
export type EventCallback = (data: any) => void;

export type FastLinkProps = typeof FastLink.defaultProps & {
    // Class to style component.
    className?: string,
    // Url to UI.
    fastLinkURL: string,
    // Bearer access token.
    accessToken: string,
    // Provider id if refreshing account link.
    providerAccountId?: number,
    // Type of configuration.
    configName?: string,
    onSuccess: SuccessCallback,
    onError: ErrorCallback,
    onClose: CloseCallback,
}

export type FastLinkState = {
    status: string
}

export class FastLink extends PureComponent<FastLinkProps, FastLinkState> {

    static defaultProps = {
        configName: "Aggregation"
    }

    private initializeUrl = 'https://cdn.yodlee.com/fastlink/v4/initialize.js';

    constructor(props: FastLinkProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT
        }
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private loadFastLink(): void {
        let params;
        if (this.props.providerAccountId) {
            // Edit existing provider credentials.
            params = {
                providerAccountId: this.props.providerAccountId,
                flow: 'edit',
                configName: this.props.configName
            };
        } else {
            // Add new provider credentials.
            params = {
                configName: this.props.configName
            };
        }
        const wnd = window as any;
        wnd.fastlink.open({
            fastLinkURL: this.props.fastLinkURL,
            accessToken: `Bearer ${this.props.accessToken}`,
            params: params,
            onSuccess: this.handleSuccess,
            onError: this.handleError,
            onClose: this.handleClose,
            onEvent: this.handleEvent
        }, 'x-fastlink-id');
    }

    private handleSuccess(data: any): void {
        this.props.onSuccess(data);
    }

    private handleError(data: any): void {
        this.props.onError(data);
    }

    private handleClose(data: any): void {
        this.props.onClose(data);
    }

    private handleEvent(data: any): void {
        console.log(data);
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSpinner
                className="x-fastlink-loading"
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load account linker."
                onRetry={this.handleRetryLoad}
            />
        );
    }

    private loadData(): void {
        load(this.initializeUrl, (err, script) => {
            if (err) {
                this.setState({ status: Globals.STATUS_FAILED });
            } else {
                this.loadFastLink();
                this.setState({ status: Globals.STATUS_READY });
            }
        });
    }

    public componentDidMount(): void {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData();
        }
    }

    public componentWillUnmount(): void {
        let wnd = window as any;
        if (wnd.fastlink) {
            wnd.fastlink.close();
            wnd.fastlink = null;
        }
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        }
        return (
            <div id="x-fastlink-id" className={this.props.className}>
                {view}
            </div>
        );
    }

}

