import { CoreUtils } from 'utils/CoreUtils';
import { RestUtils } from 'utils/RestUtils';

class TimeseriesService {

    public baseUrl = CoreUtils.apiUrl('timeseries-service', 14003);

    public warm(): Promise<any> {
        const url = `${this.baseUrl}/warm`;
        return RestUtils.get(url, undefined, undefined, undefined, undefined, false);
    }

}

export default new TimeseriesService();
