import { Chapter } from 'components/Chapter/Chapter';
import { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DocFrame } from '../DocFrame/DocFrame';
import { Italic } from 'components/Italic/Italic';

export type AccountDocsProps = RouteComponentProps;

export const AccountsDoc = (props: AccountDocsProps): ReactElement => {

    return (
        <DocFrame title="Accounts" {...props}>
            <Chapter>
                <Chapter.Paragraph>
                    <Italic>Providers</Italic> are financial institutions with which you have accounts, such as bank,
                    credit card, and investment accounts. You can link your provider accounts in order
                    to gain access to your data, which you can then import into models to analyze, visualize,
                    and create workflows to track historical data. For example, you can create models that
                    track balances, monthly spend, or recurring payments. Note that the linking of personal
                    accounts is handled by a trusted 3rd-party vender (Yodlee). None of your usernames or 
                    passwords are stored on our servers.
                </Chapter.Paragraph>
                <Chapter.Paragraph>
                    Pre-configured connectors exist to access the data from these accounts. When setting up
                    a query to retrieve data into a model, look under the "Public" datasets to find the one
                    that will get the data you want. For example, there are datasets for balances, transactions,
                    and investments. These connectors will securely fetch data from the 3rd-party, which maintains
                    all the connections to your financial institutions. All data is read-only, so no actions 
                    can be taken to change any aspect of the accounts.
                </Chapter.Paragraph>
            </Chapter>
        </DocFrame>
    );

}
