import { CandleWidgetConfiguration, CartesianWidgetConfiguration, ChartAxis, PolarWidgetConfiguration } from "@methodset/application-client-ts";
import { CandleData, CartesianData, PolarData } from "./ChartWidgetViewer";
import { Globals } from "constants/Globals";
import { Format, Formatter } from "@methodset/calculator-ts";
import ReactApexChart from 'react-apexcharts';

export type ApexChartType = typeof ReactApexChart.prototype.props.type;

/**
 * Default chart options for ApexCharts.
 */
export class ApexChartUtils {

    private static xFormatter(xFormat: Format | undefined): any {
        return xFormat ? (value: any) => Formatter.format(value, xFormat) : undefined;
    }

    private static yFormatter(yFormat: Format | undefined): any {
        return yFormat ? (value: any) => Formatter.format(value, yFormat) : undefined;
    }

    private static baseOptions(type: ApexChartType, height: number | undefined, xFormat: Format | undefined, yFormat: Format | undefined, xAxis: ChartAxis, yAxis: ChartAxis): ApexCharts.ApexOptions {
        const options: ApexCharts.ApexOptions = {
            chart: {
                toolbar: {
                    show: false
                },
                height: height,
                type: type
            },
            colors: Globals.CHART_COLORS,
            dataLabels: {
                enabled: false
            },
            tooltip: {
                enabled: true,
                x: {
                    show: true
                },
                y: {
                    formatter: ApexChartUtils.yFormatter(yFormat)
                }
            },
            legend: {
                position: "top"
            },
            xaxis: {
                title: {
                    text: xAxis.label,
                    style: {
                        fontSize: Globals.CHART_FONT_SIZE
                    },
                    offsetY: Globals.CHART_LABEL_OFFSET
                },
                labels: {
                    formatter: ApexChartUtils.xFormatter(xFormat)
                }
            },
            yaxis: {
                title: {
                    text: yAxis.label,
                    style: {
                        fontSize: Globals.CHART_FONT_SIZE
                    },
                    offsetX: -Globals.CHART_LABEL_OFFSET
                },
                labels: {
                    formatter: ApexChartUtils.yFormatter(yFormat)
                }
            }
        }
        return options;
    }

    public static cartesianOptions(type: ApexChartType, data: CartesianData, configuration: CartesianWidgetConfiguration): ApexCharts.ApexOptions {
        const options = ApexChartUtils.baseOptions(type, configuration.height, data.xFormat, data.yFormat, configuration.xAxis, configuration.yAxis);
        options.xaxis!.type = data.labelType;
        return options;
    }

    public static candleOptions(type: ApexChartType, data: CandleData, configuration: CandleWidgetConfiguration): ApexCharts.ApexOptions {
        const options = ApexChartUtils.baseOptions(type, configuration.height, data.xFormat, data.yFormat, configuration.xAxis, configuration.yAxis);
        options.xaxis!.type = "datetime";
        return options;
    }

    public static polarOptions(type: ApexChartType, data: PolarData, configuration: PolarWidgetConfiguration): ApexCharts.ApexOptions {
        const labels = (): string[] => {
            const series = data.series;
            return series.data.map(entry => entry.x);
        }
        const options: ApexCharts.ApexOptions = {
            chart: {
                height: configuration.height,
                type: type
            },
            labels: labels(),
            colors: Globals.CHART_COLORS,
            legend: {
                position: "right"
            },
            tooltip: {
                enabled: true,
                x: {
                    show: true
                },
                y: {
                    formatter: ApexChartUtils.yFormatter(data.yFormat)
                }
            },
            dataLabels: {
                enabled: true,
                formatter: (value: any): any => {
                    return Formatter.format(value, Globals.CHART_PERCENT_FORMAT);
                }
            }
        }
        return options;
    }

    public static gridOptions(): any {
        return {
            row: {
                colors: ['#fafafa', 'transparent'],
                opacity: 0.5
            }
        }
    }

    public static strokeOptions(): any {
        return {
            curve: 'straight',
            width: Globals.CHART_LINE_WIDTH
        }
    }

}