import { NumberFormat, Rule } from "@methodset/calculator-ts";
import { SpacingType } from "@methodset/application-client-ts";

export class Globals {

    public static readonly COMPANY_NAME = 'MethodSet, Inc.';
    public static readonly DOMAIN_NAME = 'methodset.com';

    // Google Analytics tracking.
    public static readonly GOOGLE_ANALYTICS_TRACKING_ID = 'G-JP684LJ9P2';

    // Cognito ids.
    public static readonly DEV_COGNITO_USER_POOL_ID = "us-west-2_7aesj9VHx";
    public static readonly DEV_COGNITO_APP_CLIENT_ID = "2pskihaag3veehc4k5apnkanpk";
    public static readonly PROD_COGNITO_USER_POOL_ID = "us-west-2_RC1xO6Qno";
    public static readonly PROD_COGNITO_APP_CLIENT_ID = "77qi9a6ku1hda6t8hnoqdu5qmr";

    public static readonly GUTTER_SIZE = 10;
    public static readonly APPLET_MENU_SIZE = "small";
    public static readonly APPLET_MENU_SHAPE = "circle";
    public static readonly APPLET_GUTTER_ROW = Globals.GUTTER_SIZE;
    public static readonly APPLET_GUTTER_COL = Globals.GUTTER_SIZE;
    public static readonly APPLET_DEFAULT_SPAN = 6;
    public static readonly FORM_WIDTH_LEFT = 14;
    public static readonly FORM_WIDTH_RIGHT = 10;
    public static readonly FORM_GUTTER_ROW = 24;
    public static readonly FORM_GUTTER_COL = 32;
    public static readonly WIDGET_GUTTER_ROW = 5;
    public static readonly WIDGET_GUTTER_COL = 5;

    public static readonly EMPTY_FIELD = "---";
    public static readonly DIALOG_HEIGHT_MAX = 550;
    public static readonly DIALOG_WIDTH_STANDARD = 475;
    public static readonly DIALOG_WIDTH_STACKED = 350;
    public static readonly DIALOG_WIDTH = Globals.DIALOG_WIDTH_STACKED;
    public static readonly DIALOG_WIDTH_DOUBLE = (2 * Globals.DIALOG_WIDTH_STACKED) + Globals.FORM_GUTTER_COL;

    public static readonly TABLE_NAME_WIDTH = 300;
    public static readonly TABLE_DATE_WIDTH = 200;
    public static readonly TABLE_VERSION_WIDTH = 200;

    public static readonly TABLE_WIDTH_SMALL = 100;
    public static readonly TABLE_WIDTH_MEDIUM = 200;
    public static readonly TABLE_WIDTH_LARGE = 300;

    public static readonly SNAPSHOT_VERSION = 0;

    // Number of columns in applet/widget layout.
    public static readonly LAYOUT_COLUMNS = 12;
    // Maximum number of rows in layout.
    public static readonly LAYOUT_ROWS = 24;
    // The column scale factor between the native grid (24 columns) and the layout.
    public static readonly LAYOUT_SCALE = 24 / Globals.LAYOUT_COLUMNS;

    // Converts a spacing size to spacing value.
    public static spacing(spacing: SpacingType | undefined): number {
        switch (spacing) {
            case SpacingType.XXS:
                return 1;
            case SpacingType.XS:
                return 3;
            case SpacingType.SM:
                return 5;
            case SpacingType.MED:
                return 8;
            case SpacingType.LG:
                return 12;
            case SpacingType.XL:
                return 16;
            case SpacingType.XXL:
                return 20;
            default:
                return 0;
        }
    }

    // Standard layout (label to the left of the form).
    public static readonly FORM_LAYOUT_STANDARD = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 }
        }
    };

    // Stacked layout (label above form item).
    public static readonly FORM_LAYOUT_STACKED = {
        labelCol: {
            xs: { span: 24 }
        },
        wrapperCol: {
            xs: { span: 24 }
        }
    };

    // Label/value layout for equal column width forms.
    public static readonly FORM_LAYOUT = Globals.FORM_LAYOUT_STACKED;

    public static readonly WIDE_FORM_LAYOUT = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 21 }
        }
    };

    // Label/value layout for 3 column forms where one takes 2 column widths.
    public static readonly FORM_LAYOUT_2 = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 21 }
        }
    };
    public static readonly ENVIRONMENT = 'dev';
    public static readonly MODEL_EXECUTION_QUEUE = 'model-execution-queue';
    public static readonly PROCESS_EXECUTION_QUEUE = 'process-execution-queue';
    public static readonly DATE_TIME_FORMAT = 'YYYY-MM-DD h =mm =ss A';
    public static readonly DATE_FORMAT = 'YYYY-MM-DD';
    public static readonly TIME_FORMAT = 'h =mm =ss A';

    // Loading status.
    public static readonly STATUS_INIT = 'INIT';
    public static readonly STATUS_LOADING = 'LOADING';
    public static readonly STATUS_READY = 'READY';
    public static readonly STATUS_FAILED = 'FAILED';
    public static readonly STATUS_ERROR = 'ERROR';

    // Spinner messages.
    public static readonly MESSAGE_CREATING = 'Creating...';
    public static readonly MESSAGE_LOADING = 'Loading...';
    public static readonly MESSAGE_SAVING = 'Saving...';
    public static readonly MESSAGE_DELETING = 'Deleting...';
    public static readonly MESSAGE_CALCULATING = 'Calculating...';
    public static readonly MESSAGE_ADDING = 'Adding...';
    public static readonly MESSAGE_REMOVING = 'Removing...';
    public static readonly MESSAGE_DOWNLOADING = 'Downloading...';
    public static readonly MESSAGE_PROCESSING = 'Processing...';

    // Chart and widget values.
    public static readonly CHART_LABEL_OFFSET = 5;
    public static readonly CHART_LINE_WIDTH = 3.0;
    public static readonly CHART_FONT_SIZE = "14px";
    public static readonly CHART_LABEL_SIZE = 16;
    public static readonly CHART_MARGINS = {
        top: 10,
        right: 20,
        left: 20,
        bottom: 30
    };
    public static readonly CHART_PERCENT_FORMAT: NumberFormat = {
        type: "number",
        name: "percent",
        rule: Rule.POSITIVE,
        decimals: 2,
        multiplier: 1,
        postfix: "%"
    };

    public static color(names: string[], index: number): string {
        return names[index % names.length];
    }

    // Tag colors.
    public static readonly TAG_COLORS = [
        "blue",
        "green",
        "purple",
        "orange",
        "geekblue",
        "red",
        "gold",
        "lime",
        "volcano",
        "magenta",
        "cyan"
    ];

    public static readonly STANDARD_COLORS = [
        "blue",
        "green",
        "purple",
        "red",
        "orange",
        "pink",
        "yellow",
        "cyan"
    ];

    public static readonly BOLD_COLORS = [
        '#108ee9',  // blue-6
        '#00a854',  // green-6
        '#7265e6',  // purple-6
        '#f56a00',  // orange-6
        '#00a2ae',  // cyan-6
        '#f5317f',  // pink-6
        '#ffbf00',  // yellow-6
        '#f04134',  // red-6
        '#09488a',  // blue-9
        '#00643b',  // green-9
        '#42299a',  // purple-9
        '#991b00',  // orange-9
        '#005667',  // cyan-9
        '#a71278',  // pink-9
        '#a04f00',  // yellow-9
        '#a31837',  // red-9
    ];

    public static readonly CHART_COLORS = [
        '#108ee9',  // blue-6
        '#00a854',  // green-6
        '#7265e6',  // purple-6
        '#f56a00',  // orange-6
        '#00a2ae',  // cyan-6
        '#f5317f',  // pink-6
        '#ffbf00',  // yellow-6
        '#f04134',  // red-6
        '#09488a',  // blue-9
        '#00643b',  // green-9
        '#42299a',  // purple-9
        '#991b00',  // orange-9
        '#005667',  // cyan-9
        '#a71278',  // pink-9
        '#a04f00',  // yellow-9
        '#a31837',  // red-9
        '#add8f7',  // blue-3
        '#a7e1c4',  // green-3
        '#cfcaf6',  // purple-3
        '#fccca7',  // orange-3
        '#a7dfe3',  // cyan-3
        '#fa90ba',  // pink-3
        '#ffe9a7',  // yellow-3
        '#fabeb9',  // red-3
    ];

    // Service names.
    public static readonly SERVICE_APPLICATION = "application";
    public static readonly SERVICE_BROWSER = "browser";
    public static readonly SERVICE_ENDPOINT = "endpoint";
    public static readonly SERVICE_ENTITY = "entity";
    public static readonly SERVICE_LIBRARY = "library";
    public static readonly SERVICE_MODEL = "model";
    public static readonly SERVICE_SCHEDULE = "schedule";
    public static readonly SERVICE_TIMESERIES = "timeseries";
    public static readonly SERVICE_WORKFLOW = "workflow";

};
