import { ReactElement } from 'react';
import { Spacer } from 'components/Spacer/Spacer';
import classNames from 'classnames';
import './FormGroup.less';

export type FormGroupProps = {
    className?: string,
    children?: any,
    errorSpace?: boolean
} & typeof defaultProps;

const defaultProps = {
    errorSpace: true
}

export const FormGroup = (props: FormGroupProps): ReactElement => {

    return (
        <Spacer className={classNames("x-formgroup", props.className, {"x-formgroup-space": props.errorSpace})} direction="vertical" size="lg">
            {props.children}
        </Spacer>
    )
}

FormGroup.defaultProps = defaultProps;
