import { PureComponent, ReactElement } from 'react';
import { LabelCheckbox } from 'components/LabelCheckbox/LabelCheckbox';
import { Constants } from 'components/Constants';
import { FrequencyType } from '@methodset/schedule-client-ts';
//import { FrequencyType } from 'types/schedule/model/FrequencyType';
import classNames from 'classnames';
import './MonthsSelector.less';

export type MonthPeriod = FrequencyType.YEAR | FrequencyType.BIANNUAL | FrequencyType.QUARTER | FrequencyType.MONTH;

export type ChangeCallback = (months: number[]) => void;

export type MonthsSelectorProps = typeof MonthsSelector.defaultProps & {
    className?: string,
    period: MonthPeriod,
    value: number[],
    onChange: ChangeCallback
}

export class MonthsSelector extends PureComponent<MonthsSelectorProps> {

    static defaultProps = {
        value: [] as number[],
        period: "month"
    }

    // Array representing 6 months.
    private indexes = [0, 1, 2, 3, 4, 5];

    constructor(props: MonthsSelectorProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    private handleChange(checked: boolean, index: number): void {
        let months: number[] | undefined;
        if (this.props.period === FrequencyType.MONTH) {
            months = this.checkBoxes(checked, index, 1);
        } else if (this.props.period === FrequencyType.QUARTER) {
            months = this.checkBoxes(checked, index, 3);
        } else if (this.props.period === FrequencyType.BIANNUAL) {
            months = this.checkBoxes(checked, index, 6);
        } else if (this.props.period === FrequencyType.YEAR) {
            months = this.checkBoxes(checked, index, 12);
        }
        if (months) {
            months.sort((a: number, b: number) => a - b);
            this.props.onChange(months);
        }
    }

    private checkBoxes(checked: boolean, index: number, interval: number): number[] {
        let months: number[] = [];
        if (!checked) {
            return months;
        }
        const count = 12 / interval;
        for (let i = 0; i < count; i++) {
            let pos = (index + (interval * i)) % 12;
            months.push(pos + 1);
        }
        return months;
    }

    public render(): ReactElement {
        const offset = this.indexes.length;
        return (
            <div className={classNames('x-monthsselector', this.props.className)}>
                <div className="x-monthsselector-months">
                    {this.indexes.map((_, index) => (
                        <LabelCheckbox
                            key={index}
                            className="x-monthsselector-month"
                            label={Constants.MONTH_ABBREV[index]}
                            checked={this.props.value.includes(index + 1)}
                            index={index}
                            onChange={this.handleChange}
                        />
                    ))}
                </div>
                <div className="x-monthsselector-months">
                    {this.indexes.map((_, index) => (
                        <LabelCheckbox
                            key={index + offset}
                            className="x-monthsselector-month"
                            label={Constants.MONTH_ABBREV[index + offset]}
                            checked={this.props.value.includes(index + offset + 1)}
                            index={index + offset}
                            onChange={this.handleChange}
                        />
                    ))}
                </div>
            </div>
        )
    }
}
