import { ReactElement } from 'react';
import './FormValue.less';

/**
 * Wrapper for children in a form item that passes its value. Use
 * in a FormItem that has multiple children that should be treated
 * as a single value.
 */
export type FormValueProps = {
    value: any,
    children: any
};

export const FormValue = (props: FormValueProps): ReactElement => {
    return (
        <>
            {props.children}
        </>
    )
}
