import { Calculator, Variable } from '@methodset/calculator-ts';
import { ReactElement } from 'react';
import { InputWidgetConfiguration } from '@methodset/application-client-ts';
import { Configuration } from '@methodset/model-client-ts';
import { Justification, Justify } from 'components/Justify/Justify';
import { Align } from 'components/Align/Align';
import { Label, Placement } from 'components/Label/Label';
import { CoreUtils } from '@methodset/commons-core-ts';
import { ConfigurationCallback } from '../WidgetViewer/WidgetViewer';
import { InputEditor } from './InputEditor/InputEditor';
import { WidgetUtils } from 'utils/WidgetUtils';
import update from 'immutability-helper';
import './InputWidgetViewer.less';

export type InputWidgetViewerProps = {
    configuration: InputWidgetConfiguration,
    calculator: Calculator,
    // modelId: string,
    // version?: number,
    appletConfiguration: Configuration,
    onUpdate: ConfigurationCallback
} & typeof defaultProps;

const defaultProps = {
    appletConfiguration: {} as Configuration
}

export const InputWidgetViewer = (props: InputWidgetViewerProps): ReactElement => {

    const findVariable = (): Variable | undefined => {
        const variableId = props.configuration.variableId;
        return props.calculator.variables.get(variableId, false);
    }

    const handleInputChange = (value: any, variable: Variable): void => {
        if (!!props.configuration.useDefault) {
            if (value === null) {
                // User cleared the value.
                value = undefined;
            } else if (value === undefined) {
                // Use default value.
                return;
            }
        }
        if (variable && variable.cell) {
            const cell = variable.cell;
            if (CoreUtils.isFormula(value)) {
                cell.formula = value;
            } else {
                cell.value = value;
            }
        }
        let configuration;
        if (CoreUtils.isEmpty(value)) {
            configuration = update(props.appletConfiguration, {
                $unset: [variable.id]
            });
        } else {
            configuration = update(props.appletConfiguration, {
                [variable.id]: { $set: value }
            });
        }
        props.onUpdate(configuration);
    }

    const buildInput = (): ReactElement => {
        const variableId = props.configuration.variableId;
        const original = props.appletConfiguration[variableId];
        const value = WidgetUtils.valueOrDefault(original, props.calculator, variableId, props.configuration.useDefault);
        return (
            <InputEditor
                calculator={props.calculator}
                value={value}
                variableId={variableId}
                useDefault={!!props.configuration.useDefault}
                doEmbed={false}
                onChange={(value, variable) => handleInputChange(value, variable)}
            />
        )
    }

    const buildView = (): ReactElement => {
        const variable = findVariable();
        const location = props.configuration.labelLocation ?
            props.configuration.labelLocation.toLowerCase() as Placement :
            undefined;
        const justification = props.configuration.justification ?
            props.configuration.justification.toLowerCase() as Justification :
            undefined;
        return (
            <Justify justification={justification}>
                <Align className="x-inputwidgetviewer-width" alignment="center">
                    {props.configuration.labelLocation && variable?.name &&
                        <Label
                            className="x-inputwidgetviewer-width"
                            placement={location}
                            label={variable?.name}
                            info={variable?.description}
                        >
                            {buildInput()}
                        </Label>
                    }
                    {!props.configuration.labelLocation &&
                        buildInput()
                    }
                </Align>
            </Justify>
        );
    }

    return buildView();

}

InputWidgetViewer.defaultProps = defaultProps;
