import { ReactElement } from 'react';
import { CartesianData } from '../ChartWidgetViewer';
import { BarChartWidgetConfiguration } from '@methodset/application-client-ts';
import { ApexChartUtils } from '../ApexChartsUtils';
import ReactApexChart from 'react-apexcharts';
import classNames from 'classnames';
import './BarChartView.less';

export type BarChartViewProps = {
    className?: string,
    data: CartesianData,
    configuration: BarChartWidgetConfiguration
};

export const BarChartView = (props: BarChartViewProps): ReactElement => {

    const series = (): ApexAxisChartSeries => {
        return props.data.seriesList;
    }

    const options = (): ApexCharts.ApexOptions => {
        const options = ApexChartUtils.cartesianOptions("bar", props.data, props.configuration);
        options.chart!.stacked = !!props.configuration.isStacked;
        options.plotOptions = {
            bar: {
                horizontal: true
            }
        }
        return options;
    }

    return (
        <div className={classNames('x-barchartview', props.className)}>
            <ReactApexChart
                options={options()}
                series={series()}
                type="bar"
                height={props.configuration.height}
            />
        </div>
    )
}


