import { ReactElement } from 'react';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Doc, DocMap } from '@methodset/calculator-ts';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Mono } from 'components/Mono/Mono';
import { Spacer } from 'components/Spacer/Spacer';
import { DocFrame } from '../../DocFrame/DocFrame';
import './FunctionDoc.less';

type StateMap = {
    doc: Doc,
    map: DocMap
}

export type FunctionDocProps = RouteComponentProps;

export const FunctionDoc = (props: FunctionDocProps): ReactElement => {

    const location = useLocation();
    const state = location.state as StateMap;
    const doc = state.doc as Doc;
    const map = state.map as DocMap;

    return (
        <DocFrame title="Function" {...props}>
            <Spacer justification="between">
                <div className="x-functiondoc-fn">{doc.fn}</div>
                <HashLink to={RouteBuilder.functionCategory(doc.category)}>
                    <div className="x-functiondoc-category">{doc.category}</div>
                </HashLink>
            </Spacer>
            <div dangerouslySetInnerHTML={{ __html: doc.description }} />
            <div>
                <div className="x-functiondoc-title">Usage</div>
                {doc.usages.map((usage, index) => (
                    <div key={index} className="x-functiondoc-code"><Mono>{usage}</Mono></div>
                ))}
            </div>
            <div>
                <div className="x-functiondoc-title">Syntax</div>
                <div className="x-functiondoc-code"><Mono>{doc.syntax}</Mono></div>
            </div>
            {doc.params.length > 0 &&
                <ul className="x-functiondoc-params">
                    {doc.params.map((param, index) => (
                        <li key={index}>
                            <Mono className="x-functiondoc-code">{param.name}</Mono>
                            &nbsp;-&nbsp;
                            <span dangerouslySetInnerHTML={{ __html: param.description }} />
                        </li>
                    ))}
                </ul>
            }
            <div>
                <div className="x-functiondoc-title">Returns</div>
                <div>{doc.returns}</div>
            </div>
            {doc.notes.length > 0 &&
                <div>
                    <div className="x-functiondoc-title">Notes</div>
                    <ul>
                        {doc.notes.map((note, index) => (
                            <li key={index} dangerouslySetInnerHTML={{ __html: note }} />
                        ))}
                    </ul>
                </div>
            }
            {doc.relations.length > 0 &&
                <div>
                    <div className="x-functiondoc-title">Related</div>
                    <ul>
                        {doc.relations.map((related, index) => (
                            <>
                                {!!map[related] &&
                                    <li key={index}>
                                        <Link
                                            to={{ pathname: RouteBuilder.functionDoc(related), state: { doc: map[related], map: map } }}
                                        >
                                            <Mono>{related}</Mono>
                                        </Link>
                                        &nbsp;-&nbsp;
                                        <span>{map[related].digest}</span>
                                    </li>
                                }
                            </>
                        ))}
                    </ul>
                </div>
            }
        </DocFrame>
    );

}

