import { ReferenceParser } from "@methodset/calculator-ts";
import { FilterWidgetConfiguration, Widget } from '@methodset/application-client-ts';

export class FilterWidgetSync {

    public static parser = (widget: Widget): string[] => {
        const configuration = widget.configuration as FilterWidgetConfiguration;
        const refs: string[] = [];
        refs.push(...ReferenceParser.extract(configuration.rangeId, true));
        refs.push(...ReferenceParser.extract(configuration.labelRangeId, true));
        refs.push(...ReferenceParser.extract(configuration.valueRangeId, true));
        refs.push(...ReferenceParser.extract(configuration.variableId, true));
        return refs;
    }

    public static updater = (widget: Widget, target: string, replacement: string): void => {
        const configuration = widget.configuration as FilterWidgetConfiguration;
        configuration.rangeId = ReferenceParser.update(configuration.rangeId, target, replacement, true);
        configuration.labelRangeId = ReferenceParser.update(configuration.labelRangeId, target, replacement, true);
        configuration.valueRangeId = ReferenceParser.update(configuration.valueRangeId, target, replacement, true);
        configuration.variableId = ReferenceParser.update(configuration.variableId, target, replacement, true);
    }

}
