import { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Spacer } from 'components/Spacer/Spacer';
import { Button, Dropdown } from 'antd';
import { Documents } from '../../Documents';
import { MenuOutlined } from '@ant-design/icons';
import { Frame } from 'components/Frame/Frame';
import { Display } from 'components/Display/Display';
import './DocFrame.less';

export type DocFrameProps = RouteComponentProps & {
    title: string,
    children: any
}

export const DocFrame = (props: DocFrameProps): ReactElement => {

    const dropMenu = (): ReactElement => {
        const { title, ...rest } = props;
        return (
            <div className="x-docframe-menu">
                <Documents {...rest} />
            </div>
        )
    }

    const frameTitle = (): ReactElement => {
        return (
            <Spacer>
                <Display md="hide">
                    <Dropdown overlay={dropMenu()} trigger={['click']}>
                        <Button shape="circle" size="middle">
                            <MenuOutlined />
                        </Button>
                    </Dropdown>
                </Display>
                {props.title}
            </Spacer>
        )
    }

    return (
        <Frame title={frameTitle()}>
            {props.children}
        </Frame>
    );

}