import { ReactElement } from 'react';
import { Button, ButtonProps } from 'antd';
import classNames from 'classnames';
import './ButtonLink.less';

/**
 * A button that behaves like a link with a click handler. This will also not push
 * a URL in the history stack, which is useful in cases where clicking a link would
 * push a duplicate link not wanted for back navigation.
 * 
 * @param props Standard button props.
 * @returns The link button instance.
 */
export const ButtonLink = (props: ButtonProps): ReactElement => {
    const { className, ...rest } = props;
    return (
        <Button className={classNames("x-buttonlink", className)} type="link" {...rest}>
            {props.children}
        </Button>
    )
}
