import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { Col, FormInstance, Input, Row, Switch } from 'antd';
import { Calculator } from '@methodset/calculator-ts';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { InputFlowWidgetConfiguration, JustificationType, WidgetType } from '@methodset/application-client-ts';
import { JustificationSelector } from 'containers/Components/Widgets/Selectors/JustificationSelector';
import { InputVariables } from './InputVariables/InputVariables';
import update from 'immutability-helper';
import './InputFlowWidgetEditor.less';

export type ChangeCallback = (configuration: InputFlowWidgetConfiguration) => void;

export type InputFlowWidgetEditorProps = typeof InputFlowWidgetEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    defaults: ReactElement,
    calculator: Calculator,
    configuration?: InputFlowWidgetConfiguration,
    onChange: ChangeCallback
}

export class InputFlowWidgetEditor extends PureComponent<InputFlowWidgetEditorProps> {

    static DefaultConfiguration = {
        type: WidgetType.INPUT_FLOW,
        variableIds: [],
        justification: JustificationType.LEFT
    } as InputFlowWidgetConfiguration;

    static defaultProps = {
        configuration: InputFlowWidgetEditor.DefaultConfiguration
    }

    constructor(props: InputFlowWidgetEditorProps) {
        super(props);
        this.handleVariablesChange = this.handleVariablesChange.bind(this);
        this.handleLabelChange = this.handleLabelChange.bind(this);
        this.handleJustificationChange = this.handleJustificationChange.bind(this);
        this.handleDefaultsChange = this.handleDefaultsChange.bind(this);
    }

    private handleVariablesChange(variableIds: string[]): void {
        const configuration = update(this.props.configuration, {
            variableIds: { $set: variableIds }
        });
        this.props.onChange(configuration);
    }

    private handleLabelChange(e: ChangeEvent<HTMLInputElement>): void {
        const label = e.target.value;
        const configuration = update(this.props.configuration, {
            label: { $set: label }
        });
        this.props.onChange(configuration);
    }

    private handleJustificationChange(justification: JustificationType): void {
        const configuration = update(this.props.configuration, {
            justification: { $set: justification }
        });
        this.props.onChange(configuration);
    }

    private handleDefaultsChange(useDefaults: boolean): void {
        const configuration = update(this.props.configuration, {
            useDefaults: { $set: useDefaults }
        });
        this.props.onChange(configuration);
    }

    public componentDidMount(): void {
        if (this.props.configuration === InputFlowWidgetEditor.DefaultConfiguration) {
            this.props.onChange(this.props.configuration);
        }
    }

    public render(): ReactElement {
        return (
            <Row gutter={Globals.FORM_GUTTER_ROW}>
                <Col span={12}>
                    {this.props.defaults}
                </Col>
                <Col span={12}>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Button Label"
                        name="label"
                        info="Label to place on the input button."
                    >
                        <Input
                            value={this.props.configuration.label}
                            onChange={this.handleLabelChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Justification"
                        name="justification"
                        info="Justification of the widget."
                    >
                        <JustificationSelector
                            value={this.props.configuration.justification}
                            onChange={this.handleJustificationChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Use Default Value"
                        name="default"
                        info="If the input values are not set, use the values already in the variable cells."
                        valuePropName="checked"
                    >
                        <Switch
                            checked={!!this.props.configuration.useDefaults}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            onChange={this.handleDefaultsChange}
                        />
                    </FormItem>
                    <InputVariables
                        formRef={this.props.formRef}
                        calculator={this.props.calculator}
                        variableIds={this.props.configuration.variableIds}
                        onChange={this.handleVariablesChange}
                    />
                </Col>
            </Row>
        );
    }

}
