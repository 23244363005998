import { PureComponent, ReactElement } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Button, message, Modal } from 'antd';
import { CalculatorReadyEvent, ExecutionCompleteEvent, Calculator, StatusType as ExecutionType, CalculatorDestroyEvent } from '@methodset/calculator-ts';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner'
import { ModelHeading } from './ModelHeading/ModelHeading';
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { ModelContext } from 'context/ModelContext';
import { Alert, Applet, AppletPanel, Application, ApplicationType } from '@methodset/application-client-ts';
import { Model } from '@methodset/model-client-ts';
import { RouteBuilder } from 'utils/RouteBuilder';
import { ModelCalculator } from './ModelCalculator/ModelCalculator';
import { Location } from 'history';
import { WidgetSyncFactory } from 'sync/WidgetSyncFactory';
import { AppletSync } from 'sync/AppletSync';
import { KeyConverter, Profile } from '@methodset/entity-client-ts';
import { Md5 } from 'ts-md5';
import { StatusType } from 'constants/StatusType';
import { ModelApplications } from './ModelApplications/ModelApplications';
import { AlertSync } from 'sync/AlertSync';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import axios from 'axios';
import classNames from 'classnames';
import applicationService from 'services/ApplicationService';
import modelService from 'services/ModelService';
import entityService from 'services/EntityService';
import './ModelItem.less';

type Digest = string | Int32Array | undefined;

// The state of exiting. Since the model may need to be saved
// before exiting, the state must be tracked to allow the save
// to complete before exiting.
type ExitState = "init" | "failed" | "started" | "pending" | "finished";

type MatchParams = {
    modelId: string
}

// Auto-save interval in seconds.
const SAVE_INTERVAL = 60 * 1000;

export type ModelItemProps = RouteComponentProps<MatchParams> & {
    className?: string
}

export type ModelItemState = {
    status: StatusType,
    isSaving: boolean,
    exitState: ExitState
}

export class ModelItem extends PureComponent<ModelItemProps, ModelItemState> {

    static contextType = ModelContext;

    private digest: Digest;
    private timerId: NodeJS.Timeout | null = null;
    private nextPathname: string | null = null;
    private unmonitor: any;
    private profile: Profile | undefined;

    constructor(props: ModelItemProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            isSaving: false,
            exitState: "init"
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleModelSave = this.handleModelSave.bind(this);
        this.handleModelChange = this.handleModelChange.bind(this);
        this.handleExitCheck = this.handleExitCheck.bind(this);
        this.handleExitWithSave = this.handleExitWithSave.bind(this);
        this.handleExitNoSave = this.handleExitNoSave.bind(this);
        this.handleExitCancel = this.handleExitCancel.bind(this);
    }

    private handleRetryLoad(): void {
        const modelId = this.props.match.params.modelId;
        this.loadData(modelId);
    }

    private handleModelSave(): void {
        this.updateModelRequest(false, true);
    }

    private handleModelChange(model: Model): void {
        if (model.autoSave) {
            this.startAutoSave();
        } else {
            this.stopAutoSave();
        }
    }

    private handleExitCheck(location: Location<any>): false | void {
        if (this.state.exitState === "finished") {
            return;
        }
        // Check if navigating away from the model item editor.
        // If so, save the model.
        const modelId = this.props.match.params.modelId;
        const url = RouteBuilder.CONSOLE_MODEL.replace(":modelId", modelId);
        if (!location.pathname.startsWith(url)) {
            this.nextPathname = `${location.pathname}${location.search}`;
            if (this.context.application) {
                // Display message to save application.
                this.setState({ exitState: "pending" });
            } else if (this.context.model.autoSave) {
                // Save the model automatically.
                this.updateModelRequest(true, false);
            } else {
                // Display a message to save model.
                this.setState({ exitState: "started" });
            }
            return false;
        }
    }

    private handleExitWithSave(): void {
        this.updateModelRequest(true, false);
    }

    private handleExitNoSave(): void {
        this.setState({ exitState: "finished" }, () => {
            this.props.history.push(this.nextPathname!);
        });
    }

    private handleExitCancel(): void {
        this.setState({ exitState: "init" });
    }

    private readProfileRequest(): Promise<any> {
        const request = {
            asOwner: false
        };
        return entityService.readProfile(request,
            (response: any) => this.readProfileResponse(response),
            undefined, true
        );
    }

    private readProfileResponse(response: any): void {
        const profile = response.data.profile;
        this.profile = KeyConverter.camelToSnake(profile);
    }

    private initModelRequest(modelId: string): Promise<any> {
        if (modelId === "create") {
            return this.createModelRequest();
        } else {
            return this.readModelRequest(modelId);
        }
    }

    private createModelRequest(): Promise<any> {
        const request = {
            name: "New Model"
        };
        return modelService.createModel(request,
            (response: any) => this.createModelResponse(response),
            undefined, true
        );
    }

    private createModelResponse(response: any): void {
        const model = response.data.model;
        this.setupModel(model);
        // Change the URL to include the new model id.
        this.props.history.push(RouteBuilder.model(model.id, "calculator"));
    }

    private readModelRequest(modelId: string): Promise<any> {
        // Version will default to "snapshot" since it is the only one that can be edited.
        const request = {
            modelId: modelId,
            version: 0
        };
        return modelService.readModel(request,
            (response: any) => this.readModelResponse(response),
            undefined, true
        );
    }

    private readModelResponse(response: any): void {
        const model = response.data.model;
        this.setupModel(model);
    }

    private updateModelRequest(isExiting: boolean, forceSave: boolean): Promise<any> {
        if (this.state.isSaving) {
            return Promise.resolve();
        }
        // Check if anything has changed.
        const model = this.context.model;
        const calculator = this.context.calculator;

        if (!isExiting && !forceSave) {
            // If nothing has changed, do not save the model and calculator.
            const digest = this.generateDigest();
            if (digest === this.digest) {
                return Promise.resolve();
            }
        }

        this.stopAutoSave();
        this.setState({ isSaving: true });

        const request = {
            modelId: model.id,
            name: model.name,
            description: model.description,
            keywords: model.keywords,
            autoSave: model.autoSave,
            //applications: model.applications,
            calculator: Calculator.serialize(calculator)
        };
        return modelService.updateModel(request,
            (response: any) => this.updateModelResponse(response, isExiting),
            (response: any) => this.updateModelException(response, isExiting),
            true
        );
    }

    private updateModelResponse(response: any, isExiting: boolean): void {
        if (!isExiting) {
            this.digest = this.generateDigest();
            this.setState({ isSaving: false });
            this.startAutoSave();
        } else {
            // Save is complete, now exit.
            this.context.saveModel(undefined);
            this.context.saveApplication(undefined);
            this.context.saveApplications([]);
            this.setState({ exitState: "finished" });
            this.props.history.push(this.nextPathname!);
        }
    }

    private updateModelException(response: any, isExiting: boolean): void {
        console.log(`Error saving model: ${RestUtils.getErrorMessage(response)}`);
        if (isExiting) {
            if (this.context.model.autoSave) {
                // Auto-save failed, need to give the user a way to exit.
                // They can choose to try to save again or exit without saving.
                this.setState({ exitState: "failed" });
            } else {
                // Let the user try again.
                this.setState({ exitState: "init" });
            }
        }
        this.setState({ isSaving: false });
        this.startAutoSave();
        if (!isExiting) {
            message.error("Error saving model.");
        }
    }

    private readApplicationsRequest(modelId: string): Promise<any> {
        const request = {
            modelId: modelId
        };
        return applicationService.readModelApplications(request,
            (response: any) => this.readApplicationsResponse(response),
            undefined, true
        );
    }

    private readApplicationsResponse(response: any): void {
        const applications = response.data.applications;
        this.context.saveApplications(applications);
    }

    private generateDigest(): Digest {
        const model = this.context.model;
        const calculator = this.context.calculator;

        const jsonModel = JSON.stringify(model);
        const jsonCalculator = Calculator.serialize(calculator);

        const md5 = new Md5();
        return md5.appendStr(jsonModel).appendStr(jsonCalculator).end();
    }

    private setupModel(model: Model): void {
        const calculator = Calculator.deserialize(model.calculator!);
        calculator.httpHeaders = RestUtils.getHttpHeaders();
        // Fix the request key since this is a single-user environment.
        calculator.context.requestKey = 0;
        calculator.addCallback("CalculatorReady", (event: CalculatorReadyEvent) => {
            // Generate the digest to check for changes.
            // TODO: reset digest when model saved on save of application (AppletModel, AlertModel)
            this.digest = this.generateDigest();
            // Show the calculated sheets on startup.
            this.startAutoSave();
            this.setState({ status: StatusType.READY });
            // Start monitoring URL changes to watch for exit.
            this.unmonitor = this.props.history.block(this.handleExitCheck);
        });
        calculator.addCallback("CalculatorDestroy", (event: CalculatorDestroyEvent) => {
            // Stop monitoring URL changes.
            if (this.unmonitor) {
                this.unmonitor();
            }
        });
        calculator.addCallback("ExecutionComplete", (event: ExecutionCompleteEvent) => {
            if (event.status.code === ExecutionType.ERROR) {
                message.error(event.status.message);
            }
            //calculator.printState(true);
        });
        // Clear out the json calculator to save memory.
        model.calculator = undefined as any;
        this.context.saveModel(model);
        this.context.saveCalculator(calculator);
    }

    private loadData(modelId: string): void {
        const requests = [];
        requests.push(this.initModelRequest(modelId));
        requests.push(this.readApplicationsRequest(modelId));
        requests.push(this.readProfileRequest());
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1, r2, r3) => {
            if (RestUtils.isOk(r1, r2, r3)) {
                // Execute the calculations and continue in the complete callback.
                // When execution is complete, status will changed to ready.
                const calculator = this.context.calculator;
                this.registerApplications(calculator);
                calculator.setProfile(this.profile);
                calculator.execute();
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSpinner
                className="x-modelitem-loading"
                loadingMessage="Loading model..."
                status={isLoading ? "loading" : "failed"}
                onRetry={this.handleRetryLoad}
            />
        )
    }

    private buildModelView(): ReactElement {
        return (
            <div>
                <ModelHeading
                    isSaving={this.state.isSaving}
                    onSave={this.handleModelSave}
                    onChange={this.handleModelChange}
                    {...this.props}
                />
                <div className="x-modelitem-body">
                    <Switch>
                        <Route
                            //when="always"
                            path={RouteBuilder.CONSOLE_MODEL_CALCULATOR}
                            render={(props) => <ModelCalculator {...props} />}
                        />
                        <Route
                            //when="always"
                            path={RouteBuilder.CONSOLE_MODEL_APPLICATIONS}
                            render={(props) => <ModelApplications {...props} />}
                        />
                    </Switch>
                </div>
                {(this.state.exitState === "failed" || this.state.exitState === "started") &&
                    <Modal
                        centered
                        title="Save Model"
                        visible={true}
                        width={Globals.DIALOG_WIDTH}
                        onCancel={this.handleExitCancel}
                        footer={(
                            <>
                                <Button onClick={this.handleExitNoSave}>No</Button>
                                <Button type="primary" onClick={this.handleExitWithSave}>Yes</Button>
                            </>
                        )}
                    >
                        <span>
                            Do you want to save the model before exiting?
                        </span>
                    </Modal>
                }
                {(this.state.exitState === "pending") &&
                    <Modal
                        centered
                        title="Save Application"
                        visible={true}
                        width={Globals.DIALOG_WIDTH}
                        onCancel={this.handleExitCancel}
                        footer={(
                            <>
                                <Button onClick={this.handleExitCancel}>No</Button>
                                <Button type="primary" onClick={this.handleExitNoSave}>Yes</Button>
                            </>
                        )}
                    >
                        <span>
                            The active application may have changes that were not saved. Do you want to exit without saving those changes?
                        </span>
                    </Modal>
                }
            </div>
        )
    }

    private stopAutoSave(): void {
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
    }

    private startAutoSave(): void {
        if (this.context.model.autoSave && !this.timerId) {
            this.timerId = setTimeout(() => this.updateModelRequest(false, false), SAVE_INTERVAL);
        }
    }

    private findPanels(): AppletPanel[] {
        const panels = [];
        const applets = this.context.applications.filter((application: Application) => application.type === ApplicationType.APPLET);
        for (const applet of applets) {
            panels.push(applet);
            for (const panel of applet.panels) {
                panels.push(panel);
            }
        }
        const alerts = this.context.applications.filter((application: Application) => application.type === ApplicationType.ALERT);
        for (const alert of alerts) {
            panels.push(alert.panel);
        }
        return panels;
    }

    private registerApplications(calculator: Calculator): void {
        // Register all applications and widgets to get updated when calculator components (i.e., sheet name) change.
        const registry = calculator.registry;
        const panels = this.findPanels();
        for (const panel of panels) {
            const widgets = panel.widgets;
            for (const widget of widgets) {
                const widgetSync = WidgetSyncFactory.createSync(widget.configuration);
                if (widgetSync) {
                    registry.register(widget.id, widget, widgetSync.parser, widgetSync.updater);
                }
            }
            //registry.register(panel.id, panel, AppletSync.parser, AppletSync.updater);
        }
        const applications = this.context.applications;
        for (const application of applications) {
            if (application.type === ApplicationType.APPLET) {
                const applet = application as Applet;
                registry.register(applet.id, applet, AppletSync.parser, AppletSync.updater);
            } else if (application.type === ApplicationType.ALERT) {
                const alert = application as Alert;
                registry.register(alert.id, alert, AlertSync.parser, AlertSync.updater);
            }
        }
    }

    private unregisterApplications(calculator: Calculator): void {
        const registry = calculator.registry;
        const panels = this.findPanels();
        for (const panel of panels) {
            const widgets = panel.widgets;
            for (const widget of widgets) {
                registry.unregister(widget.id);
            }
            //registry.unregister(panel.id);
        }
        const applications = this.context.applications;
        for (const application of applications) {
            registry.unregister(application.id);
        }
    }

    public componentDidMount(): void {
        if (this.state.status !== StatusType.READY) {
            this.loadData(this.props.match.params.modelId);
        }
    }

    public componentWillUnmount(): void {
        // Stop auto-save.
        this.stopAutoSave();
        // Close calculator.
        const calculator = this.context.calculator;
        if (calculator) {
            this.unregisterApplications(calculator);
            calculator.close();
        }
        // Stop monitoring URL changes.
        if (this.unmonitor) {
            this.unmonitor();
        }
        // Remove state.
        this.context.clear();
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === StatusType.LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === StatusType.FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === StatusType.READY) {
            view = this.buildModelView();
        }
        return (
            <div className={classNames('x-modelitem', this.props.className)}>
                {view}
            </div>
        )
    }

}
