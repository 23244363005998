import { Italic } from 'components/Italic/Italic';
import { Chapter } from 'components/Chapter/Chapter';
import { ReactElement } from 'react';
import { Bullets } from 'components/Bullets/Bullets';
import { Mono } from 'components/Mono/Mono';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Link, RouteComponentProps } from 'react-router-dom';
import { DocFrame } from '../DocFrame/DocFrame';

export type ModelsDocProps = RouteComponentProps;

export const ModelsDoc = (props: ModelsDocProps): ReactElement => {

    return (
        <DocFrame title="Models" {...props}>
            <Chapter>
                <Chapter.Paragraph>
                    Models are the logic component for working with the data used in your applets. They are
                    similar to a spreadsheet in that they support functions and formulas to calculate values and 
                    datasets, but models are fully integrated into the platform. For example, they can easily import
                    external datasets and they can be used as part of a workflow to automate the generation of computed
                    data. Models support most functions that are available in stand-alone spreadsheets, such as
                    Excel and Google Sheets, but include a few more that make working with dynamic datasets
                    easy. Each model can be given a name, descriptions and can be set to auto-save results periodically.
                    If that setting is not turned on, the model will need to be saved manually to persist any edits;
                    however, the user will be asked if they wish to save edits when exiting the model editor.
                </Chapter.Paragraph>
                <Chapter.Section title="Calculator">
                    <Chapter.Paragraph>
                        Each model is made up of one or more <Italic>sheets</Italic>. A <Italic>sheet</Italic> contains
                        rows and columns of cells. Rows are named starting at "1" and incrementally increase, while
                        columns are named starting at "A" and increase to "B", "C", etc. Each cell can contain a constant
                        value (e.g., 100) or a value calculated from a formula (e.g., <Mono>=1+2</Mono>). Sheets are used to work with
                        related data or can be used to prepare data for use in another sheet. Sheets use the same syntax as
                        common spreadsheets with cell references taking the form &lt;Column&gt;&lt;Row&gt;, for 
                        example <Mono>A1</Mono> references the value in the first row and first column.
                    </Chapter.Paragraph>
                </Chapter.Section>
                <Chapter.Section title="Formulas">
                    <Chapter.Paragraph>
                        <Italic>Formulas</Italic> are composed of one or more <Link to={RouteBuilder.MAIN_DOCUMENTATION_FUNCTIONS}>functions</Link> and 
                        are used to calculate a value. Some formulas can generate blocks of cells, such as a query, which will place the range of rows and
                        columns rooted at the cell location. Formulas are differentiated from constant values by using an
                        equals sign (<Mono>=</Mono>) as the first character. Formulas are used as they would be in a common spreadsheet.
                    </Chapter.Paragraph>
                </Chapter.Section>
                <Chapter.Section title="Variables">
                    <Chapter.Paragraph>
                        <Italic>Variables</Italic> are symbols that can be associated to a cell and are assigned the value
                        of the cell. These variables can be used to give a friendly name to a cell, which in turn can be used
                        in formulas to make them more readable. For example, say cell <Mono>A1</Mono> contains an opening stock price and
                        cell <Mono>B1</Mono> contains the closing price. If variables are associated to each, say "<Mono>open</Mono>" and 
                        "<Mono>close</Mono>", respectively, the spread could be calculated as "<Mono>=close-open</Mono>". This is especially 
                        useful if the variables are used in several different formulas and can make a formula easier to understand.
                    </Chapter.Paragraph>
                    <Chapter.Paragraph>
                        To associate a variable to a cell, select the cell, open the "Variables" tab to the right of the sheet,
                        and press the "Edit" button. A dialog will be displayed that allows for various properties to be set,
                        such as the variable symbol, name, description, and type. Several other options can be enabled:
                    </Chapter.Paragraph>
                    <Bullets
                        items={[
                            "Value Required - the variable must have a cell value and will report an error if a value is not present",
                            "Internal Only - the variable value is restricted to use inside the model only, and cannot be overridden from an external input such as a dashboard applet",
                            "Use Options - the variable value is selected from a set of options and does not require the user to type in a value",
                            "Sync Options - the variable options are derived from a dataset input configuration and do not need to be configured manually"
                        ]}
                    />
                    <Chapter.Paragraph>
                        Internal variables cannot be overridden. Applets can include input widgets that allow the user to select or
                        enter a value which will override a model variable. However, some variable should not be overridden, either
                        because they contain a formula or should not be changed because they are vital to the internal logic of a model.
                        There are also times when an input parameter to a query should be exposed through an applet. In this case, a
                        query input can be copied to the variable so that they do not need to be manually duplicated. For example, if
                        a query allows the user to select from a set of categories when fetching some data and those categories should
                        be exposed as inputs that a user can select from in an applet, these options can be easily synced to export
                        those options.
                    </Chapter.Paragraph>
                </Chapter.Section>
                <Chapter.Section title="Parameters">
                    <Chapter.Paragraph>
                        <Italic>Parameters</Italic> are a type of variable, but are not associated to a cell in a sheet. Parameters are
                        values separate from any sheet and allow a model to defined some named values without the need of creating a
                        separate sheet to maintain them. This can simplify a model if there are a small number of variables that can be
                        maintained on their own. Parameters otherwise are identical to variables and can be referenced in any formula
                        and have the same properties.
                    </Chapter.Paragraph>
                </Chapter.Section>
                <Chapter.Section title="Queries">
                    <Chapter.Paragraph>
                        A <Italic>query</Italic> is a function that can be used to fetch data from a source and import it into a sheet.
                        Since the <Mono>query()</Mono> function can be quite complicated to construct, a query builder is available to
                        simplify this task. To import data into sheet at a particular location, select the cell where you would like
                        the dataset to be located, open the "Queries" tab to the right of the sheet, and press the "Add" button ("Edit"
                        if you would like to change an existing query). A dialog box will open that will allow for the selection of a dataset
                        query and several other options including the columns to import, the maximum number rows, and the row order.
                    </Chapter.Paragraph>
                </Chapter.Section>
                <Chapter.Section title="Pivots">
                    <Chapter.Paragraph>
                        A <Italic>pivot</Italic> is a function that can be used to create a pivot table. Since the <Mono>pivot()</Mono> function
                        can be quite completed to construct, a pivot builder is available to simplify this task. To add a pivot table
                        to a sheet, select a cell where you would like the pivot table to be located, open the "Pivots" tab to the right
                        of the sheet, and press the "Add" button ("Edit" if you would like to change an existing pivot table). A dialog box
                        will open that will allow for the selection of the source cell range and options on how to create the pivot.
                    </Chapter.Paragraph>
                </Chapter.Section>
                <Chapter.Section title="Applets">
                    <Chapter.Paragraph>
                        <Italic>Applets</Italic> are the visual representation of the data in a model. An applet can display numerical and
                        textural data using a variety of <Italic>widgets</Italic>, which are the core building blocks to constructing an
                        applet. The widgets are layed out in rows and columns within the applet to position them in the desired location.
                        Widgets are available to display charts and graphs, text and numbers, dividers, lists, inputs, and more.
                    </Chapter.Paragraph>
                    <Chapter.Paragraph>
                        A widget input allows a value to be changed. Such inputs are linked to an underlying model variable or parameter, 
                        which can be used in a formula to affect a calculation. Since the input can be accessed from an applet on a dashboard,
                        any change to an input by a user can change the data that is displayed in a applet. For example, a variable may be 
                        used in a query to import data to a model. Depending on the of the variable, different data may be retrieved, so that
                        once an input has been linked to the variable, a change of that variable via an input will trigger a change in the 
                        underlying data and therefore the applet will be updated, thereby enabling an interactive view.
                    </Chapter.Paragraph>
                    <Chapter.Paragraph>
                        Applets are also able to include nested applets, for example when there is a choice of data to display and the 
                        user selects one of the options. Each dataset may be visualized by its own applet, which are then embedded within
                        a parent applet. 
                    </Chapter.Paragraph>
                    <Chapter.Paragraph>
                        Once an applet has been finalized, it can be published, which makes it available for inclusion on a dashboard.
                        Note that even though a model may have multiple applets, they do not all need to be published. For example, if 
                        there is a parent applet with several nested child applets, only the parent applet would be published since the
                        other applets merely makeup the main applet.
                    </Chapter.Paragraph>
                </Chapter.Section>
            </Chapter>
        </DocFrame>
    );

}
