import { ReactElement, useState } from 'react';
import { Spacer } from 'components/Spacer/Spacer';
import { Select } from 'antd';
import { CoreUtils } from 'utils/CoreUtils';
import { Date } from '@methodset/commons-core-ts';
import moment from 'moment';
import './DateSelector.less';

export type ChangeCallback = (value: Date | undefined) => void;

export type DateSelectorProps = {
    value?: Date,
    onChange: ChangeCallback
};

export const DateSelector = (props: DateSelectorProps): ReactElement => {

    const [year, setYear] = useState<number|undefined>(props.value ? props.value.year() : undefined);
    const [month, setMonth] = useState<number|undefined>(props.value ? props.value.month() : undefined);
    const [day, setDay] = useState<number|undefined>(props.value ? props.value.day() : undefined);

    const endOfMonth = (month: number | undefined): number | undefined => {
        if (!month) {
            return undefined;
        }
        const feb = isLeapYear(year) ? 29 : 28;
        const last = [31, feb, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        return last[month - 1];
    }

    const buildDays = (): number[] => {
        const days: number[] = [];
        const eom = endOfMonth(month);
        if (!eom) {
            return days;
        }
        for (let i = 1; i <= eom; i++) {
            days.push(i)
        }
        return days;
    }

    const buildYears = (): number[] => {
        let year = moment().year();
        const years = [];
        for (let i = 100; i >= 0; i--) {
            years.push(year--);
        }
        return years;
    }

    const isLeapYear = (year: number | undefined): boolean => {
        return year ? moment([year]).isLeapYear() : false;
    }

    const handleYearChange = (year: number): void => {
        if (month === 2 && day === 29 && !isLeapYear(year)) {
            setDay(28);
        }
        setYear(year);
        sendUpdate(year, month, day);
    }

    const handleMonthChange = (month: number): void => {
        if (day) {
            const eom = endOfMonth(month);
            if (eom && day > eom) {
                setDay(eom);
            }
        }
        setMonth(month);
        sendUpdate(year, month, day);
    }

    const handleDayChange = (day: number): void => {
        setDay(day);
        sendUpdate(year, month, day);
    }

    const sendUpdate = (year: number | undefined, month: number | undefined, day: number | undefined): void => {
        let date;
        if (CoreUtils.isNumber(year) && CoreUtils.isNumber(month) && CoreUtils.isNumber(day)) {
            date = Date.fromYmd(year, month, day);
        }
        props.onChange(date);
    }

    return (
        <Spacer fill>
            <Select
                className="x-dateselector-month"
                placeholder="Month"
                allowClear={true}
                value={month}
                onChange={handleMonthChange}
            >
                <Select.Option key={1} value={1}>January</Select.Option>
                <Select.Option key={2} value={2}>February</Select.Option>
                <Select.Option key={3} value={3}>March</Select.Option>
                <Select.Option key={4} value={4}>April</Select.Option>
                <Select.Option key={5} value={5}>May</Select.Option>
                <Select.Option key={6} value={6}>June</Select.Option>
                <Select.Option key={7} value={7}>July</Select.Option>
                <Select.Option key={8} value={8}>August</Select.Option>
                <Select.Option key={9} value={9}>September</Select.Option>
                <Select.Option key={10} value={10}>October</Select.Option>
                <Select.Option key={11} value={11}>November</Select.Option>
                <Select.Option key={12} value={12}>December</Select.Option>
            </Select>
            <Select
                className="x-dateselector-day"
                placeholder="Day"
                allowClear={true}
                value={day}
                onChange={handleDayChange}
            >
                {buildDays().map(day => (
                    <Select.Option key={day} value={day}>{day}</Select.Option>
                ))}
            </Select>
            <Select
                className="x-dateselector-year"
                placeholder="Year"
                allowClear={true}
                value={year}
                onChange={handleYearChange}
            >
                {buildYears().map(year => (
                    <Select.Option key={year} value={year}>{year}</Select.Option>
                ))}
            </Select>
        </Spacer>
    )
}
