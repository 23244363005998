import React, { PureComponent, ReactElement } from 'react';
import { Button, Checkbox, Collapse, Form, FormInstance, Radio, RadioChangeEvent, Space } from 'antd';
import { FormFrame } from 'components/FormFrame/FormFrame';
import { RouteComponentProps } from 'react-router-dom';
import { EntityContext } from 'context/EntityContext';
import { Address, AddressContact, Contact, Income, Pension, Person, PersonType, PersonaType, PersonalPersona, Profile, Retirement } from '@methodset/entity-client-ts';
import { PersonEditor } from './PersonEditor/PersonEditor';
import { ChildrenEditor } from './ChildrenEditor/ChildrenEditor';
import { RealEstateEditor } from './RealEstateEditor/RealEstateEditor';
import { IncomesEditor } from './IncomesEditor/IncomesEditor';
import { RetirementEditor } from './RetirementEditor/RetirementEditor';
import { ContactEditor } from './ContactEditor/ContactEditor';
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { PensionsEditor } from './PensionsEditor/PensionsEditor';
import { SpouseEditor } from './SpouseEditor/SpouseEditor';
import { Spacer } from 'components/Spacer/Spacer';
import { CoreUtils } from 'utils/CoreUtils';
import { Label } from 'components/Label/Label';
import axios from 'axios';
import entityService from 'services/EntityService';
import update from 'immutability-helper';
import './EditProfile.less';

export type EditProfileProps = RouteComponentProps & {}

export type EditProfileState = {
    status: string,
    error?: Error,
    isEditing: boolean,
    isSubmitting: boolean,
    hasSpouse: boolean,
    profile: Profile
    personaType: PersonaType,
    panelKey?: string,
}

export class EditProfile extends PureComponent<EditProfileProps, EditProfileState> {

    static contextType = EntityContext;

    private formRefs = new Map<string, React.RefObject<FormInstance>>();

    constructor(props: EditProfileProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            isEditing: false,
            isSubmitting: false,
            hasSpouse: false,
            profile: {} as Profile,
            personaType: PersonaType.BASIC
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handlePanelChange = this.handlePanelChange.bind(this);
        this.handleSelfChange = this.handleSelfChange.bind(this);
        this.handleContactsChange = this.handleContactsChange.bind(this);
        this.handleSpouseToggle = this.handleSpouseToggle.bind(this);
        this.handleSpouseChange = this.handleSpouseChange.bind(this);
        this.handleChildrenChange = this.handleChildrenChange.bind(this);
        this.handleIncomesChange = this.handleIncomesChange.bind(this);
        this.handlePensionsChange = this.handlePensionsChange.bind(this);
        this.handleRealEstateChange = this.handleRealEstateChange.bind(this);
        this.handleRetirementChange = this.handleRetirementChange.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleUpdateClick = this.handleUpdateClick.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleTypeChange(e: RadioChangeEvent): void {
        const personaType = e.target.value;
        this.setState({
            personaType: personaType,
            panelKey: undefined
        });
    }

    private handlePanelChange(panelKey: string | string[]): void {
        if (this.state.panelKey) {
            const formRef = this.findFormRef(this.state.panelKey);
            formRef.current?.validateFields().then(values => {
                this.setState({ panelKey: Array.isArray(panelKey) ? panelKey[0] : panelKey });
            }).catch(e => {
            });
        } else {
            this.setState({ panelKey: Array.isArray(panelKey) ? panelKey[0] : panelKey });
        }
    }

    private handleSelfChange(self: Person): void {
        const profile = update(this.state.profile, {
            self: { $set: self }
        });
        this.setState({ profile: profile });
    }

    private handleContactsChange(contacts: Contact[]): void {
        const profile = update(this.state.profile, {
            contacts: { $set: contacts }
        });
        this.setState({ profile: profile });
    }

    private handleSpouseToggle(e: CheckboxChangeEvent): void {
        const hasSpouse = e.target.checked;
        this.setState({ hasSpouse: hasSpouse });
        if (!hasSpouse) {
            let persona: PersonalPersona = this.state.profile.personas["PERSONAL"] ?? { type: "PERSONAL" };
            persona = update(persona, {
                spouse: { $set: undefined }
            });
            const profile = update(this.state.profile, {
                personas: {
                    PERSONAL: { $set: persona }
                }
            });
            this.setState({ profile: profile });
        }
    }

    private handleSpouseChange(spouse: Person): void {
        let persona: PersonalPersona = this.state.profile.personas["PERSONAL"] ?? { type: "PERSONAL" };
        persona = update(persona, {
            spouse: { $set: spouse }
        });
        const profile = update(this.state.profile, {
            personas: {
                PERSONAL: { $set: persona }
            }
        });
        this.setState({ profile: profile });
    }

    private handleChildrenChange(children: Person[]): void {
        let persona: PersonalPersona = this.state.profile.personas["PERSONAL"] ?? { type: "PERSONAL" };
        persona = update(persona, {
            children: { $set: children }
        });
        const profile = update(this.state.profile, {
            personas: {
                PERSONAL: { $set: persona }
            }
        });
        this.setState({ profile: profile });
    }

    private handleIncomesChange(incomes: Income[]): void {
        let persona: PersonalPersona = this.state.profile.personas["PERSONAL"] ?? { type: "PERSONAL" };
        persona = update(persona, {
            incomes: { $set: incomes }
        });
        const profile = update(this.state.profile, {
            personas: {
                PERSONAL: { $set: persona }
            }
        });
        this.setState({ profile: profile });
    }

    private handlePensionsChange(pensions: Pension[]): void {
        let persona: PersonalPersona = this.state.profile.personas["PERSONAL"] ?? { type: "PERSONAL" };
        persona = update(persona, {
            pensions: { $set: pensions }
        });
        const profile = update(this.state.profile, {
            personas: {
                PERSONAL: { $set: persona }
            }
        });
        this.setState({ profile: profile });
    }

    private handleRealEstateChange(realEstate: AddressContact[]): void {
        let persona: PersonalPersona = this.state.profile.personas["PERSONAL"] ?? { type: "PERSONAL" };
        persona = update(persona, {
            realEstate: { $set: realEstate }
        });
        const profile = update(this.state.profile, {
            personas: {
                PERSONAL: { $set: persona }
            }
        });
        this.setState({ profile: profile });
    }

    private handleRetirementChange(retirement: Retirement): void {
        let persona: PersonalPersona = this.state.profile.personas["PERSONAL"] ?? { type: "PERSONAL" };
        persona = update(persona, {
            retirement: { $set: retirement }
        });
        const profile = update(this.state.profile, {
            personas: {
                PERSONAL: { $set: persona }
            }
        });
        this.setState({ profile: profile });
    }

    private handleCancelClick(): void {
        this.props.history.goBack();
    }

    private handleUpdateClick(): void {
        if (this.state.panelKey) {
            const formRef = this.findFormRef(this.state.panelKey);
            formRef.current?.validateFields().then(values => {
                this.updateProfileRequest();
            }).catch(e => {
            });
        } else {
            this.updateProfileRequest();
        }
    }

    private readProfileRequest(): Promise<any> {
        const request = {
            asOwner: true
        };
        return entityService.readProfile(request,
            (response: any) => this.readProfileResponse(response),
            undefined, true
        );
    }

    private readProfileResponse(response: any): void {
        const profile = response.data.profile;
        const hasSpouse = !!profile.personas.PERSONAL.spouse;
        this.setState({
            profile: profile,
            hasSpouse: hasSpouse
        });
    }

    private updateProfileRequest(): Promise<any> {
        this.setState({
            isSubmitting: true,
            error: undefined
        });
        const request = {
            profile: this.state.profile
        };
        return entityService.updateProfile(request,
            (response: any) => this.updateProfileResponse(response),
            (response: any) => this.updateProfileException(response),
            true
        );
    }

    private updateProfileResponse(response: any): void {
        this.setState({ isSubmitting: false });
        this.props.history.goBack();
    }

    private updateProfileException(response: any): void {
        const error = new Error(RestUtils.getErrorMessage(response));
        this.setState({
            isSubmitting: false,
            error: error
        });
    }

    private loadData() {
        const requests = [];
        requests.push(this.readProfileRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSkeleton
                count={8}
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load profile."
                onRetry={this.handleRetryLoad}
            >
                <LoadSkeleton.Input length="fill" />
            </LoadSkeleton>
        );
    }

    private findFormRef(key: string): React.RefObject<FormInstance> {
        let formRef = this.formRefs.get(key);
        if (!formRef) {
            formRef = React.createRef<FormInstance>();
            this.formRefs.set(key, formRef);
        }
        return formRef;
    }

    private buildFormView(): ReactElement {
        const panelKey = this.state.panelKey ? [this.state.panelKey] : [];
        return (
            <div>
                <Label className="x-editprofile-persona" label="Profile Type" placement="top">
                    <Radio.Group
                        value={this.state.personaType}
                        onChange={this.handleTypeChange}
                    >
                        <Spacer fill={true}>
                            {CoreUtils.enumToKeys(PersonaType).map(type => (
                                <Radio key={type} value={type}>{CoreUtils.toProper(type)}</Radio>
                            ))}
                        </Spacer>
                    </Radio.Group>
                </Label>
                <Collapse activeKey={panelKey} accordion={true} onChange={this.handlePanelChange}>
                    <Collapse.Panel key="general" header="General" forceRender={true}>
                        <div className="x-editprofile-info">
                            Enter your personal information.
                        </div>
                        <PersonEditor
                            formRef={this.findFormRef("general")}
                            withForm={true}
                            id="self"
                            type={PersonType.SELF}
                            person={this.state.profile.self}
                            onChange={this.handleSelfChange}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel key="contacts" header="Contacts" forceRender={true}>
                        <div className="x-editprofile-info">
                            Enter your contact information.
                        </div>
                        <ContactEditor
                            formRef={this.findFormRef("contacts")}
                            withForm={true}
                            id="self"
                            contacts={this.state.profile.contacts}
                            onChange={this.handleContactsChange}
                        />
                    </Collapse.Panel>
                    {this.state.personaType === PersonaType.PERSONAL &&
                        <>
                            <Collapse.Panel key="spouse" header="Spouse" forceRender={true}>
                                <div className="x-editprofile-info">
                                    Enter information about your spouse.
                                </div>
                                <Checkbox
                                    className="x-editprofile-spouse"
                                    checked={this.state.hasSpouse}
                                    onChange={this.handleSpouseToggle}
                                >
                                    I have a spouse.
                                </Checkbox>
                                {this.state.hasSpouse &&
                                    <SpouseEditor
                                        formRef={this.findFormRef("spouse")}
                                        withForm={true}
                                        id="spouse"
                                        spouse={(this.state.profile.personas.PERSONAL as PersonalPersona).spouse}
                                        onChange={this.handleSpouseChange}
                                    />
                                }
                            </Collapse.Panel>
                            <Collapse.Panel key="children" header="Children" forceRender={true}>
                                <div className="x-editprofile-info">
                                    Enter information about your children.
                                </div>
                                <ChildrenEditor
                                    formRef={this.findFormRef("children")}
                                    withForm={true}
                                    id="children"
                                    children={(this.state.profile.personas.PERSONAL as PersonalPersona).children}
                                    onChange={this.handleChildrenChange}
                                />
                            </Collapse.Panel>
                            <Collapse.Panel key="income" header="Income" forceRender={true}>
                                <div className="x-editprofile-info">
                                    Enter current and historical information about your income.
                                </div>
                                <IncomesEditor
                                    formRef={this.findFormRef("income")}
                                    withForm={true}
                                    id="income"
                                    incomes={(this.state.profile.personas.PERSONAL as PersonalPersona).incomes}
                                    onChange={this.handleIncomesChange}
                                />
                            </Collapse.Panel>
                            <Collapse.Panel key="pensions" header="Pensions" forceRender={true}>
                                <div className="x-editprofile-info">
                                    Enter information about your pensions.
                                </div>
                                <PensionsEditor
                                    formRef={this.findFormRef("pensions")}
                                    withForm={true}
                                    id="pensions"
                                    pensions={(this.state.profile.personas.PERSONAL as PersonalPersona).pensions}
                                    onChange={this.handlePensionsChange}
                                />
                            </Collapse.Panel>
                            <Collapse.Panel key="real-estate" header="Real Estate" forceRender={true}>
                                <div className="x-editprofile-info">
                                    Enter the addresses of the real read estate you own.
                                </div>
                                <RealEstateEditor
                                    formRef={this.findFormRef("real-estate")}
                                    withForm={true}
                                    id="real-estate"
                                    addresses={(this.state.profile.personas.PERSONAL as PersonalPersona).realEstate}
                                    onChange={this.handleRealEstateChange}
                                />
                            </Collapse.Panel>
                            <Collapse.Panel key="retirement" header="Retirement" forceRender={true}>
                                <div className="x-editprofile-info">
                                    Enter information about your retirement.
                                </div>
                                <RetirementEditor
                                    formRef={this.findFormRef("retirement")}
                                    withForm={true}
                                    id="retirement"
                                    retirement={(this.state.profile.personas.PERSONAL as PersonalPersona).retirement}
                                    onChange={this.handleRetirementChange}
                                />
                            </Collapse.Panel>
                        </>
                    }
                </Collapse>
                <Space className="x-editprofile-footer">
                    <Button
                        disabled={this.state.isEditing}
                        onClick={this.handleCancelClick}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        disabled={this.state.isEditing}
                        loading={this.state.isSubmitting}
                        onClick={this.handleUpdateClick}
                    >
                        Update
                    </Button>
                </Space>
            </div>
        );
    }

    public componentDidMount(): void {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === Globals.STATUS_READY) {
            view = this.buildFormView();
        }
        return (
            <FormFrame
                className="x-editprofile"
                title="Update Profile"
                removeForm={true}
            >
                {view}
            </FormFrame>
        );
    }

}
